import { AxiosProgressEvent } from 'axios';
import { useCallback, useState } from 'react';

export const useFileUploadProgress = () => {
  const [progress, setProgress] = useState(0);
  const [isUploading, setIsUploading] = useState(false);

  const onUploadProgress = useCallback((progressEvent: AxiosProgressEvent) => {
    if (!progressEvent.total) return;
    const currentProgress = Math.round((progressEvent.loaded * 100) / progressEvent.total);
    setProgress(currentProgress);
  }, []);

  return {
    isUploading,
    setIsUploading,
    progress,
    onUploadProgress,
  };
};
