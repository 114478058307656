import PrivateIcon from 'assets/icons/eye-off.svg';
import PublicIcon from 'assets/icons/eye.svg';
import MembersIcon from 'assets/icons/members.svg';
import OpenIcon from 'assets/icons/open.svg';
import Avatar from 'components/memberAndProfile/Avatar';
import Tag from 'components/tag/Tag';
import BodyText from 'components/typography/BodyText';
import Heading from 'components/typography/Heading';
import { commonCover, orgLogo } from 'constants/common';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { MemberRoleType } from 'types/apis/common';
import { canManageCommunity } from 'utils/helper';

interface ICard {
  to: string;
  role?: null | MemberRoleType;
  title: string;
  members?: number;
  coverImage: string | null;
  visibility?: string;
  organizationLogo?: string | null;
  hasMenu?: boolean;
  menuElement?: React.ReactNode;
  organizationName?: string;
  guestMode?: boolean;
}

function Card({
  to,
  role,
  title,
  members,
  visibility,
  coverImage,
  organizationLogo,
  organizationName,
  hasMenu = false,
  menuElement = null,
  guestMode,
}: ICard) {
  const { t } = useTranslation();

  return (
    <Link
      to={to}
      className='group w-full rounded-2xl bg-neutral-200 border border-neutral-300 p-2 text-left shadow shadow-neutral-300 hover:border-primary-500 hover:shadow-xl active:border-primary-200'
    >
      <div className='relative mb-4'>
        <div className='overflow-hidden rounded-2xl'>
          <div
            style={{
              backgroundImage: `url(${coverImage || commonCover})`,
            }}
            className='h-[120px] w-full rounded-2xl bg-cover bg-center object-cover transition-all duration-500 group-hover:scale-110'
          ></div>
        </div>
        {organizationLogo && (
          <div className='absolute -bottom-3 left-4 rounded-full border-2 border-primary-300 shadow-md shadow-neutral-300'>
            <Avatar size={45} src={organizationLogo || orgLogo} />
          </div>
        )}
      </div>
      <div className='mb-2 flex items-center gap-4 px-2'>
        <Heading variant='h3' className='mr-auto text-neutral-500'>
          {organizationName}
        </Heading>
        {hasMenu && menuElement}
      </div>

      <Heading variant='h2' className='mb-2 line-clamp-2 font-semibold text-neutral-600 px-2'>
        {title}
      </Heading>
      {!guestMode && (
        <div className='flex items-center justify-between px-2'>
          {members && members > 0 && (
            <div className='flex items-center gap-1'>
              <MembersIcon className='h-4 w-4 text-neutral-500' />
              <BodyText variant='sm' className='font-medium text-neutral-500'>
                {members} {t('members')}
              </BodyText>
            </div>
          )}

          {visibility && (
            <div className='flex items-center gap-1 rounded bg-neutral-200 px-2 py-1'>
              {visibility === 'open' ? (
                <OpenIcon className='h-4 w-4 text-neutral-500' />
              ) : visibility === 'private' ? (
                <PrivateIcon className='h-4 w-4 text-neutral-500' />
              ) : (
                <PublicIcon className='h-4 w-4 text-neutral-500' />
              )}
              <BodyText variant='sm' className='font-medium capitalize text-neutral-500'>
                {t(visibility)}
              </BodyText>
            </div>
          )}
          {role && canManageCommunity(role) && (
            <Tag
              type='rounded'
              className=' bg-functional-success-light text-xs text-functional-success-dark'
            >
              {t(`member.role.${role}`)}
            </Tag>
          )}
        </div>
      )}
    </Link>
  );
}

export default Card;
