import { AxiosProgressEvent } from 'axios';
import Loading from 'components/Loading';
import AddEditRemarkModal from 'components/modal/AddEditRemarkModal';
import ConfirmationModal from 'components/modal/ConfirmationModal';
import RemarksModal from 'components/modal/RemarksModal';
import SubmitTaskModal from 'components/modal/SubmitTaskModal';
import NavigateTo404 from 'components/NavigateTo404';
import { useCommunity } from 'hooks/api/community/useCommunity';
import { useCommunityRemarks } from 'hooks/api/remark/useCommunityRemarks';
import { useCommunityTaskSubmissions } from 'hooks/api/task/useCommunityTaskSubmissions';
import { useInvalidateQueries } from 'hooks/common/useInvalidatequeries';
import useIsLoggedIn from 'hooks/api/auth/useIsLoggedIn';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams, useLocation } from 'react-router-dom';
import { useCommunityTask } from 'hooks/api/task/useCommunityTask';
import {
  addCommunitySubmissionRemark,
  updateCommunitySubmissionRemark,
  deleteCommunitySubmissionRemark,
} from 'utils/api/remark';
import {
  submitCommunityTask,
  updateCommunityTaskSubmission,
  deleteCommunityTaskSubmission,
} from 'utils/api/task';
import { notifySuccess, notifyTranslatedError } from 'utils/notify';
import TaskSubmissions from '../task/TaskSubmissions';

function useQuery() {
  return new URLSearchParams(useLocation().search);
}
const CommunityTaskSubmissionsPage = () => {
  const { t } = useTranslation();
  const { communityId: originId, taskId } = useParams();
  const query = useQuery();
  const taskName = query.get('taskName');
  const deadline = query.get('deadline');
  // states
  const [openAddSubmissionModal, setOpenAddSubmissionModal] = useState(false);
  const [openEditSubmissionModal, setOpenEditSubmissionModal] = useState(false);
  const [selectedSubmissionId, setSelectedSubmissionId] = useState<string | null>(null);
  const [selectedSubmissionTitle, setSelectedSubmissionTitle] = useState<string | null>(null);
  const [selectedSubmissionNote, setSelectedSubmissionNote] = useState<string | null>(null);
  const [deleteSubmissionConfirmation, setDeleteSubmissionConfirmation] = useState(false);
  const [openSubmissionRemarks, setOpenSubmissionRemarks] = useState(false);
  const [selectedRemarkId, setSelectedRemarkId] = useState<string | null>(null);
  const [selectedRemarkValue, setSelectedRemarkValue] = useState<string | null>(null);
  const [openAddRemarkModal, setOpenAddRemarkModal] = useState(false);
  const [openEditRemarkModal, setOpenEditRemarkModal] = useState(false);
  const [openDeleteRemarkConfirmation, setOpenDeleteRemarkConfirmation] = useState(false);

  // custom hooks
  const { data: community, isLoading: isCommunityLoading } = useCommunity(originId || '');
  const { data: userInfo, isLoading: isUserInfoLoading } = useIsLoggedIn();
  const {
    data: task,
    refetch: refetchSingleTask,
    isLoading: isCommunityTaskLoading,
  } = useCommunityTask(originId || '', taskId || '');
  const { submissions, canManage, isSubmissionsLoading } = useCommunityTaskSubmissions(
    originId || '',
    taskId || '',
  );
  const { data: remarks } = useCommunityRemarks(
    originId || '',
    selectedSubmissionId || '',
    openSubmissionRemarks,
  );
  const {
    invalidateSingleCommunityTask,
    invalidateCommunityTaskSubmissions,
    invalidateCommunityTaskRemarks,
  } = useInvalidateQueries();

  if (isSubmissionsLoading) return <Loading />;
  if (!submissions) return <NavigateTo404 />;
  // add submission handler
  const handleOpenAddSubmissionModal = () => {
    setOpenAddSubmissionModal(true);
  };

  const handleCloseAddSubmissionModal = () => {
    setOpenAddSubmissionModal(false);
  };

  const handleAddSubmission = (
    data: FormData,
    onUploadProgress: (progressEvent: AxiosProgressEvent) => void,
    setIsUploading: React.Dispatch<React.SetStateAction<boolean>>,
  ) => {
    if (!originId || !taskId) return;
    submitCommunityTask(originId, taskId, data, { onUploadProgress })
      .then(() => {
        setIsUploading(false);
        handleCloseAddSubmissionModal();
        notifySuccess(t('success.api.submitted_task'));
        invalidateSingleCommunityTask(originId, taskId);
        invalidateCommunityTaskSubmissions(originId, taskId);
      })
      .catch((error) => {
        setIsUploading(false);
        notifyTranslatedError(t, error.response.data?.errorCode);
        notifyTranslatedError(t, error.response.data?.message);
      });
  };

  // edit submission handler
  const handleOpenEditSubmissionModal = (
    submissionId: string,
    submissionTitle: string,
    submissionNote?: string,
  ) => {
    setOpenEditSubmissionModal(true);
    setSelectedSubmissionId(submissionId);
    setSelectedSubmissionTitle(submissionTitle);
    setSelectedSubmissionNote(submissionNote || null);
  };

  const handleCloseEditSubmissionModal = () => {
    setOpenEditSubmissionModal(false);
    setSelectedSubmissionId(null);
    setSelectedSubmissionTitle(null);
    setSelectedSubmissionNote(null);
  };

  const handleEditSubmission = (
    data: { title: string; note: string },
    onUploadProgress: (progressEvent: AxiosProgressEvent) => void,
    setIsUploading: React.Dispatch<React.SetStateAction<boolean>>,
  ) => {
    if (!originId || !taskId || !selectedSubmissionId) return;
    updateCommunityTaskSubmission(originId, selectedSubmissionId, data, { onUploadProgress })
      .then(() => {
        setIsUploading(false);
        handleCloseEditSubmissionModal();
        notifySuccess(t('success.api.updated_task'));
        invalidateSingleCommunityTask(originId, taskId);
        invalidateCommunityTaskSubmissions(originId, taskId);
      })
      .catch((error) => {
        setIsUploading(false);
        notifyTranslatedError(t, error.response.data?.errorCode);
      });
  };

  // delete submission handler
  const handleOpenDeleteSubmissionConfirmation = (submissionId: string) => {
    setSelectedSubmissionId(submissionId);
    setDeleteSubmissionConfirmation(true);
  };

  const handleCloseDeleteSubmissionConfirmation = () => {
    setSelectedSubmissionId(null);
    setDeleteSubmissionConfirmation(false);
  };

  const handleDeleteSubmission = () => {
    if (!originId || !taskId || !selectedSubmissionId) return;
    deleteCommunityTaskSubmission(originId, selectedSubmissionId)
      .then(() => {
        handleCloseDeleteSubmissionConfirmation();
        invalidateSingleCommunityTask(originId, taskId);
        invalidateCommunityTaskSubmissions(originId, taskId);
      })
      .catch((error) => {
        notifyTranslatedError(t, error.response.data?.errorCode);
      });
  };

  // remarks handler
  const handleOpenSubmissionRemarks = (submissionId: string) => {
    setSelectedSubmissionId(submissionId);
    setOpenSubmissionRemarks(true);
  };

  const handleCloseSubmissionRemarks = () => {
    setSelectedSubmissionId(null);
    setOpenSubmissionRemarks(false);
  };

  // add remark handler
  const handleCloseAddRemarkModal = () => {
    setSelectedSubmissionId(null);
    setOpenAddRemarkModal(false);
  };

  const handleAddSubmissionRemark = (data: { remark: string }) => {
    if (!originId || !taskId || !selectedSubmissionId) return;
    addCommunitySubmissionRemark(originId, selectedSubmissionId, data)
      .then(() => {
        invalidateCommunityTaskSubmissions(originId, taskId);
        invalidateCommunityTaskRemarks(originId, selectedSubmissionId);
        notifySuccess(t('success.api.remark_updated'));
      })
      .catch((error) => {
        notifyTranslatedError(t, error.response.data?.errorCode);
      });
  };

  // edit remark handler
  const handleOpenEditRemarkModal = (remarkId: string, remarkValue: string) => {
    setSelectedRemarkId(remarkId);
    setSelectedRemarkValue(remarkValue);
    setOpenEditRemarkModal(true);
    setOpenSubmissionRemarks(false);
  };

  const handleCloseEditRemarkModal = () => {
    setSelectedRemarkId(null);
    setSelectedRemarkValue(null);
    setOpenEditRemarkModal(false);
  };

  const handleEditSubmissionRemark = (data: { remark: string }) => {
    if (!originId || !taskId || !selectedRemarkId || !selectedSubmissionId) return;
    updateCommunitySubmissionRemark(originId, selectedRemarkId, data)
      .then(() => {
        invalidateCommunityTaskSubmissions(originId, taskId);
        invalidateCommunityTaskRemarks(originId, selectedSubmissionId);
        notifySuccess(t('success.api.remark_added'));
      })
      .catch((error) => {
        notifyTranslatedError(t, error.response.data?.errorCode);
      });
  };

  // delete remark handler
  const handleOpenDeleteRemarkConfirmation = (remarkId: string) => {
    setSelectedRemarkId(remarkId);
    setOpenDeleteRemarkConfirmation(true);
  };

  const handleCloseDeleteRemarkConfirmation = () => {
    setSelectedRemarkId(null);
    setOpenDeleteRemarkConfirmation(false);
  };

  const handleDeleteSubmissionRemark = () => {
    if (!originId || !taskId || !selectedRemarkId || !selectedSubmissionId) return;
    deleteCommunitySubmissionRemark(originId, selectedRemarkId)
      .then(() => {
        invalidateCommunityTaskRemarks(originId, selectedSubmissionId);
        invalidateCommunityTaskSubmissions(originId, taskId);
        handleCloseDeleteRemarkConfirmation();
      })
      .catch((error) => {
        notifyTranslatedError(t, error.response.data?.errorCode);
      });
  };

  return (
    <div>
      <TaskSubmissions
        canManage={canManage || !!task?.task.memberVisibility}
        isGroup={community?.isGroup}
        submissions={submissions}
        taskName={taskName}
        deadline={deadline}
        currentUserId={userInfo?.id}
        handleAddSubmission={handleOpenAddSubmissionModal}
        handleEditSubmission={handleOpenEditSubmissionModal}
        handleDeleteSubmission={handleOpenDeleteSubmissionConfirmation}
        handleOpenSubmissionRemarks={handleOpenSubmissionRemarks}
      />

      {/* add submission modal */}
      {openAddSubmissionModal && (
        <SubmitTaskModal
          isOpen={openAddSubmissionModal}
          onClose={handleCloseAddSubmissionModal}
          handleSubmitTask={handleAddSubmission as $TSFix}
        />
      )}

      {/* edit submission modal */}
      {openEditSubmissionModal && selectedSubmissionTitle && (
        <SubmitTaskModal
          isEditing
          note={selectedSubmissionNote}
          title={selectedSubmissionTitle}
          isOpen={openEditSubmissionModal}
          onClose={handleCloseEditSubmissionModal}
          handleSubmitTask={handleEditSubmission as $TSFix}
        />
      )}

      {/* delete submission confiramtion */}
      {deleteSubmissionConfirmation && (
        <ConfirmationModal
          label={t('delete_submission')}
          onConfirm={handleDeleteSubmission}
          isOpen={deleteSubmissionConfirmation}
          title={t('delete_submission_confirmation_title')}
          onClose={handleCloseDeleteSubmissionConfirmation}
          description={
            t('delete_submission_confirmation_description') ||
            'This submission will be deleted permanently!'
          }
        />
      )}

      {/* submission remarks */}
      {openSubmissionRemarks && (
        <RemarksModal
          canManage={canManage}
          currentUserId={userInfo?.id}
          remarks={remarks ?? []}
          isOpen={openSubmissionRemarks}
          onClose={handleCloseSubmissionRemarks}
          handleEditRemark={handleOpenEditRemarkModal}
          handleDeleteRemark={handleOpenDeleteRemarkConfirmation}
          handleSubmitRemark={handleAddSubmissionRemark}
        />
      )}

      {/* add submission remark */}
      {openAddRemarkModal && (
        <AddEditRemarkModal
          isOpen={openAddRemarkModal}
          onClose={handleCloseAddRemarkModal}
          handleSubmitRemark={handleAddSubmissionRemark}
        />
      )}

      {/* edit submission remark */}
      {openEditRemarkModal && selectedRemarkValue && (
        <AddEditRemarkModal
          isEditing
          remark={selectedRemarkValue}
          isOpen={openEditRemarkModal}
          onClose={handleCloseEditRemarkModal}
          handleSubmitRemark={handleEditSubmissionRemark}
        />
      )}

      {/* delete submission remark */}
      {openDeleteRemarkConfirmation && (
        <ConfirmationModal
          label={t('delete_remark')}
          isOpen={openDeleteRemarkConfirmation}
          onConfirm={handleDeleteSubmissionRemark}
          onClose={handleCloseDeleteRemarkConfirmation}
          description={
            t('delete_remark_confirmation_description') ||
            'This remark will be paramanently removed!'
          }
          title={t('delete_remark_confirmation_title')}
        />
      )}
    </div>
  );
};

export default CommunityTaskSubmissionsPage;
