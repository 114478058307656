import LoadingIcon from 'assets/icons/loading.svg';
import SavedPostsDropdown from 'components/common/SavedPostsDropdown';
import ProgramPost from 'components/post/ProgramPost';
import Heading from 'components/typography/Heading';
import { userAvatar } from 'constants/common';
import useProgramPosts from 'hooks/api/post/useProgramPosts';
import { useProgramSavedPosts } from 'hooks/api/post/userProgramSavedPosts';
import React, { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useInView } from 'react-intersection-observer';
import { handleLikePost } from 'utils/post';

interface IProgramPosts {
  programId: string;
  canManage: boolean;
}

const ProgramPosts = ({ programId, canManage }: IProgramPosts) => {
  const { t } = useTranslation();

  const { ref: observerRef, inView } = useInView({
    threshold: 0.25,
  });
  const { posts, isPostsLoading, isFetchingNextPosts, fetchNextPosts, hasNextPosts } =
    useProgramPosts(programId);
  const { data: savedPosts = [] } = useProgramSavedPosts(programId);

  const feedposts = useMemo(() => {
    return posts.map((post) => ({
      ...post,
      savedPostId: savedPosts.find((savedPost) => savedPost.post.id === post.id)?.id,
    }));
  }, [posts, savedPosts]);

  
  useEffect(() => {
    if (inView) {
      if (!isPostsLoading && !isFetchingNextPosts && hasNextPosts) {
        fetchNextPosts();
      }
    }
  }, [inView]);

  return (
    <section className='space-y-4'>
      <div className='flex items-center justify-between'>
        <Heading variant='h2' className='font-semibold text-neutral-600'>
          {t('recent_posts')}
        </Heading>

        <SavedPostsDropdown originType='program' originId={programId} />
      </div>

      {feedposts.map(
        ({
          id,
          authorType,
          user,
          commentCount,
          content,
          createdAt,
          embedType,
          embedId,
          embedMeta,
          file,
          isAuthor,
          isPinned,
          likeCount,
          liked,
          viewCount,
          savedPostId,
        }) => (
          <ProgramPost
            key={id}
            postId={id}
            file={file}
            programId={programId}
            isAuthor={isAuthor}
            authorId={user.id}
            authorLogo={user.profilePicture || userAvatar}
            authorName={`${user.firstName} ${user.lastName}`}
            authorHeadline={user.headline || undefined}
            authorRole={authorType}
            createdAt={createdAt}
            isPostLiked={liked}
            postCommentCount={commentCount}
            postContent={content}
            postLikeCount={likeCount}
            postViewCount={viewCount}
            embedType={embedType}
            embedId={embedId}
            embedMeta={embedMeta}
            canManage={canManage}
            isPostPinned={isPinned}
            handleLikePost={handleLikePost}
            savedPostId={savedPostId}
          />
        ),
      )}

      <div ref={observerRef}>
        <LoadingIcon
          className={`h-6 w-6 ${isFetchingNextPosts || isPostsLoading ? 'block' : 'hidden'}`}
        />
      </div>
    </section>
  );
};

export default ProgramPosts;
