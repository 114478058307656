import CloseIcon from 'assets/icons/close.svg';
import EmojiIcon from 'assets/icons/emoji.svg';
import Button from 'components/button/Button';
import EmojiSelector from 'components/common/EmojiSelector';
import MentionTextarea from 'components/common/MentionTextarea';
import BodyText from 'components/typography/BodyText';
import Heading from 'components/typography/Heading';
import { convertFromRaw, convertToRaw, EditorState, ContentState } from 'draft-js';
import { useCommunitySinglePost } from 'hooks/api/post/useCommunitySinglePost';
import { useProgramSinglePost } from 'hooks/api/post/useProgramSinglePost';
import { useInvalidateQueries } from 'hooks/common/useInvalidatequeries';
import React, { useEffect, useRef, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { PostOrigin } from 'types/apis/common';
import { CommunityPostFormInputType } from 'types/components/form';
import { updateCommunityPost, updateProgramPost } from 'utils/api/post';
import { insertTextInCurrentPosition, isJSON } from 'utils/helper';
import { notifySuccess, notifyTranslatedError } from 'utils/notify';
import ModalContainer from './ModalContainer';

interface IUpdatePostModal {
  originId: string;
  postId: string;
  isOpen: boolean;
  onClose: () => void;
  originType: 'community' | 'program';
}

const UpdatePostModal = ({ originId, postId, isOpen, onClose, originType }: IUpdatePostModal) => {
  const { t } = useTranslation();
  const emojiContainerRef = useRef<HTMLDivElement>(null);

  const [showEmoji, setShowEmoji] = useState(false);
  const [editorState, setEditorState] = useState(() => EditorState.createEmpty());

  const { data: programPost } = useProgramSinglePost(originId, postId, originType === 'program');
  const { data: communityPost } = useCommunitySinglePost(
    originId,
    postId,
    originType === 'community',
  );

  const {
    invalidateAllPosts,
    invalidateProgramPosts,
    invalidateCommunityPosts,
    invalidateSinglePost,
  } = useInvalidateQueries();

  const {
    setValue,
    setError,
    clearErrors,
    handleSubmit,
    formState: { errors },
  } = useForm<CommunityPostFormInputType>();

  const onSubmit: SubmitHandler<CommunityPostFormInputType> = (data) => {
    const currentContent = editorState.getCurrentContent();
    const rawContent = convertToRaw(currentContent);
    const flatText = rawContent.blocks.map((block) => block.text).join('');

    if (flatText.length === 0) {
      setError('content', { type: 'minLength' });
      return;
    }

    if (originType === 'community') {
      updateCommunityPost(originId, postId, data)
        .then(() => {
          onClose();
          invalidateAllPosts();
          invalidateCommunityPosts(originId);
          invalidateSinglePost(PostOrigin.community, originId, postId);
          notifySuccess(t('post_updated'));
        })
        .catch((error) => {
          notifyTranslatedError(t, error.response.data?.errorCode);
        });
    }
    if (originType === 'program') {
      updateProgramPost(originId, postId, data)
        .then(() => {
          onClose();
          invalidateAllPosts();
          invalidateProgramPosts(originId);
          invalidateSinglePost(PostOrigin.program, originId, postId);
          notifySuccess(t('post_updated'));
        })
        .catch((error) => {
          notifyTranslatedError(t, error.response.data?.errorCode);
        });
    }
  };

  // Set content to editorstate
  useEffect(() => {
    if (originType === 'community' && communityPost) {
      // is content json
      if (isJSON(communityPost.content)) {
        setEditorState(() =>
          EditorState.createWithContent(convertFromRaw(JSON.parse(communityPost.content))),
        );
      } else {
        setEditorState(() =>
          EditorState.createWithContent(ContentState.createFromText(communityPost.content)),
        );
      }
    }

    if (originType === 'program' && programPost) {
      if (isJSON(programPost.content)) {
        setEditorState(() =>
          EditorState.createWithContent(convertFromRaw(JSON.parse(programPost.content))),
        );
      } else {
        setEditorState(() =>
          EditorState.createWithContent(ContentState.createFromText(programPost.content)),
        );
      }
    }
  }, [programPost, communityPost]);

  useEffect(() => {
    clearErrors('content');
    const currentContent = editorState.getCurrentContent();
    const rawContent = convertToRaw(currentContent);
    const content = JSON.stringify(rawContent);
    setValue('content', content);
  }, [editorState]);

  return (
    <ModalContainer title={t('update_post')} isOpen={isOpen} onClose={onClose}>
      <form onSubmit={handleSubmit(onSubmit)} className='space-y-5'>
        <MentionTextarea
          originId={originId}
          originType={originType}
          editorState={editorState}
          setEditorState={setEditorState}
        />
        {errors.content && (
          <BodyText variant='xs' className='text-functional-danger-400'>
            {t('required_error_message')}
          </BodyText>
        )}

        <div className='mt-5 flex items-center justify-between gap-3 border-t border-neutral-200 pt-5'>
          <div ref={emojiContainerRef}>
            <EmojiSelector
              open={showEmoji}
              close={() => {
                setShowEmoji(false);
              }}
              setOpen={setShowEmoji}
              element={
                <>
                  <EmojiIcon className='h-5 w-5 text-neutral-500' />{' '}
                  <BodyText variant='sm' className='hidden text-neutral-400 md:block'>
                    {t('emoji')}
                  </BodyText>
                </>
              }
              elementClassName='btn p-0'
              onEmojiSelect={(emoji) =>
                setEditorState(insertTextInCurrentPosition(editorState, emoji))
              }
            />
          </div>

          <div className='flex items-center gap-3'>
            <Button type='button' size='small' variant='outlined' onClick={onClose}>
              {t('cancel')}
            </Button>
            <Button type='submit' size='small' variant='secondary'>
              {t('update_post')}
            </Button>
          </div>
        </div>
      </form>
    </ModalContainer>
  );
};

export default UpdatePostModal;
