import ArchiveIcon from 'assets/icons/archive.svg';
import GroupCard from 'components/card/GroupCard';
import { ContextMenu } from 'components/common/ContextMenu';
import ConfirmationModal from 'components/modal/ConfirmationModal';
import { useArchivedProgramGroups } from 'hooks/api/group/useArchivedProgramGroups';
import { useInvalidateQueries } from 'hooks/common/useInvalidatequeries';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { MemberRoleType } from 'types/apis/common';
import { restoreProgramGroup } from 'utils/api/group';
import { notifyTranslatedError } from 'utils/notify';

interface IArchivedGroups {
  programId: string;
}

const ArchivedGroups = ({ programId }: IArchivedGroups) => {
  const { t } = useTranslation();

  const [selectedGroupId, setSelectedGroupId] = useState<null | string>(null);
  const [showRestoreConfirmation, setShowRestoreConfirmation] = useState(false);

  const { groups } = useArchivedProgramGroups(programId);
  const { invalidateSingleCommunity, invalidateProgramGroups, invalidateArchivedProgramGroups } =
    useInvalidateQueries();

  const handleOpenRestoreConfirmation = (groupId: string) => {
    setSelectedGroupId(groupId);
    setShowRestoreConfirmation(true);
  };

  const handleCloseRestoreConfirmation = () => {
    setSelectedGroupId(null);
    setShowRestoreConfirmation(false);
  };

  const handleRestoreGroup = () => {
    if (!selectedGroupId) return;
    restoreProgramGroup(programId, selectedGroupId)
      .then(() => {
        handleCloseRestoreConfirmation();
        invalidateSingleCommunity(selectedGroupId);
        invalidateProgramGroups(programId);
        invalidateArchivedProgramGroups(programId);
      })
      .catch((error) => {
        notifyTranslatedError(t, error.response.data?.errorCode);
      });
  };

  return (
    <>
      {groups.map(({ id, name, description, memberCount }) => (
        <GroupCard
          key={id}
          groupId={id}
          groupName={name}
          groupRole={MemberRoleType.manager}
          groupMembers={memberCount}
          groupDescription={description}
          menuItems={
            <ContextMenu
              menuItems={[
                {
                  key: 'restore',
                  label: t('restore'),
                  icon: ArchiveIcon,
                  callback: () => handleOpenRestoreConfirmation(id),
                },
              ]}
            />
          }
        />
      ))}

      {showRestoreConfirmation && (
        <ConfirmationModal
          label={t('restore-group')}
          onConfirm={handleRestoreGroup}
          isOpen={showRestoreConfirmation}
          title={t('restore-confirm-title')}
          onClose={handleCloseRestoreConfirmation}
        />
      )}
    </>
  );
};

export default ArchivedGroups;
