import { AxiosProgressEvent } from 'axios';
import { useCommunity } from 'hooks/api/community/useCommunity';
import { useInvalidateQueries } from 'hooks/common/useInvalidatequeries';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Visibility } from 'types/apis/common';
import { duplicateCommunity } from 'utils/api/community';
import { notifySuccess, notifyTranslatedError } from 'utils/notify';
import CreationModal from './CreationModal';

interface IDuplicateCommunityModal {
  open: boolean;
  communityId: string;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const DuplicateCommunityModal = ({ open, setOpen, communityId }: IDuplicateCommunityModal) => {
  const { t } = useTranslation();

  const { data } = useCommunity(communityId);
  const { invalidateMyCommunities, invalidateOrgCommunities } = useInvalidateQueries();

  const defaultValues = useMemo(() => {
    if (!data) {
      return {
        name: '',
        cover: null,
        description: '',
        visibility: 'open' as Visibility,
        allowGuest: 'false',
      };
    }

    const { name, description, visibility, cover, allowGuest } = data;

    return {
      name,
      cover,
      visibility,
      description,
      allowGuest: allowGuest ? 'true' : 'false',
      copyMembers: 'true',
      copyManagers: 'true',
      copyResources: 'true',
      copyEvents: 'true',
      copyTasks: 'true',
    };
  }, [data]);

  const onFormSubmit = (
    data: FormData,
    onUploadProgress: (progressEvent: AxiosProgressEvent) => void,
    setIsUploading: React.Dispatch<React.SetStateAction<boolean>>,
  ) => {
    duplicateCommunity(communityId, data, { onUploadProgress })
      .then(() => {
        setOpen(false);
        setIsUploading(false);
        invalidateMyCommunities();
        invalidateOrgCommunities();
        notifySuccess(t('success.api.community_duplicated'));
      })
      .catch((error) => {
        setIsUploading(false);
        notifyTranslatedError(t, error.response.data?.errorCode);
        notifyTranslatedError(t, error.response.data?.message);
      });
  };

  if (!data) return null;

  return (
    <CreationModal
      isOpen={open}
      isDuplicating
      isCommunityDuplicating
      onFormSubmit={onFormSubmit}
      defaultValues={defaultValues}
      onClose={() => setOpen(false)}
      ctaText={t('duplicate') || 'Duplicate'}
      modalTitle={`${t('duplicate')} ${data.name}`}
    />
  );
};

export default DuplicateCommunityModal;
