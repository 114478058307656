import Button from 'components/button/Button';
import Heading from 'components/typography/Heading';
import React from 'react';
import { useNavigate } from 'react-router-dom';

const NotFoundPage = () => {
  const navigate = useNavigate();

  return (
    <div className='w-full h-screen flex items-center justify-center'>
      <div className='bg-neutral-200 w-full md:w-[400px] flex flex-col items-center border border-neutral-300 shadow-2xl shadow-neutral-300 rounded-md px-5 py-10 m-5 space-y-5'>
        <Heading variant='h1' className='text-8xl leading-none font-bold text-neutral-300'>
          404
        </Heading>
        <Heading variant='h3' className='font-semibold text-neutral-400'>
          PAGE NOT FOUND
        </Heading>
        <Button size='default' variant='secondary' onClick={() => navigate('/')}>
          Return To Home
        </Button>
      </div>
    </div>
  );
};

export default NotFoundPage;
