import { AxiosRequestConfig } from 'axios';
import { API_ROOT } from 'constants/config';
import { CalendarResponseType } from 'types/apis/response';
import { parseGET } from 'utils/rest';

export const eventAddToCalendar = (
  communityId: string,
  eventId: string,
  options?: AxiosRequestConfig,
) =>
  parseGET<string>(`${API_ROOT}/community/${communityId}/events/${eventId}/add-to-calendar`, {
    withCredentials: true,
    ...options,
  });

export const programEventAddToCalendar = (
  programId: string,
  eventId: string,
  options?: AxiosRequestConfig,
) =>
  parseGET<string>(`${API_ROOT}/program/${programId}/events/${eventId}/add-to-calendar`, {
    withCredentials: true,
    ...options,
  });

export const taskAddToCalendar = (
  communityId: string,
  taskId: string,
  options?: AxiosRequestConfig,
) =>
  parseGET<string>(`${API_ROOT}/community/${communityId}/tasks/${taskId}/add-to-calendar`, {
    withCredentials: true,
    ...options,
  });

export const programTaskAddToCalendar = (
  programId: string,
  taskId: string,
  options?: AxiosRequestConfig,
) =>
  parseGET<string>(`${API_ROOT}/program/${programId}/tasks/${taskId}/add-to-calendar`, {
    withCredentials: true,
    ...options,
  });

export const getCalendar = (month: number, year: number, options?: AxiosRequestConfig) => {
  return parseGET<CalendarResponseType>(`${API_ROOT}/calendar?month=${month}&year=${year}`, {
    withCredentials: true,
    ...options,
  });
};
