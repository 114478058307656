import AddIcon from 'assets/icons/add.svg';
import DeleteIcon from 'assets/icons/delete.svg';
import DownloadIcon from 'assets/icons/download.svg';
import PinIcon from 'assets/icons/pin.svg';
import EditIcon from 'assets/icons/edit.svg';
import LinkIcon from 'assets/icons/link.svg';
import NoteIcon from 'assets/icons/note.svg';
import Button from 'components/button/Button';
import SearchInput from 'components/form/SearchInput';
import SortIcon from 'assets/icons/sort.svg';
import AddPublicResourceModal from 'components/modal/AddPublicResourceModal';
import ConfirmationModal from 'components/modal/ConfirmationModal';
import EditPublicResourceModal from 'components/modal/EditPublicResourceModal';
import { SingleCommunityResourceType } from 'types/apis/common';
import BodyText from 'components/typography/BodyText';
import dayjs from 'dayjs';
import { useSingleProgram } from 'hooks/api/program/useSingleProgram';
import { useProgramPublicResources } from 'hooks/api/resource/useProgramPublicResources';
import debounce from 'lodash/debounce';
import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import {
  deleteProgramPublicResource,
  updateProgramPublicResource,
  pinProgramPublicResource,
} from 'utils/api/publicResource';
import { bytesToMegaBytes } from 'utils/helper';
import { notifySuccess, notifyTranslatedError } from 'utils/notify';
import MaterialIcon from 'components/common/MaterialIcon';
import ResourceViewerDrawer from 'components/drawer/ResourceViewerDrawer';
import { isViewableFile } from 'utils/helper';
import MemberButton from 'components/memberAndProfile/MemberButton';

const ProgramPublicFolder = () => {
  const { t } = useTranslation();
  const { programId } = useParams();
  const [searchString, setSearchString] = useState('');
  const [openConfirmation, setOpenConfirmation] = useState(false);
  const [selectedResourceId, setSelectedResourceId] = useState('');
  const [selectedResourceTitle, setSelectedResourceTitle] = useState('');
  const [openAddResourceModal, setOpenAddResourceModal] = useState(false);
  const [openEditResourceModal, setOpenEditResourceModal] = useState(false);
  const [selectedResourceUrl, setSelectedResourceUrl] = useState<null | string>(null);
  const [showResourceViewer, setShowResourceViewer] = useState(false);
  const [fileUrl, setFileUrl] = useState('');
  const [uploaderName, setUploaderName] = useState('');
  const [uploadedBy, setUploadedBy] = useState('');
  const [originId, setOriginId] = useState('');
  const [originType, setOriginType] = useState('');
  const { data: Program } = useSingleProgram(programId || '');

  if (!Program) return null;

  const { data: resources, refetch } = useProgramPublicResources(Program.id);

  // Search related code:
  const filteredResources = useMemo(() => {
    return (
      resources?.resources.filter((resource) => {
        const searchLower = searchString.toLowerCase();
        const titleMatch = resource.title
          ? resource.title.toLowerCase().includes(searchLower)
          : false;
        const fileNameMatch = resource.file?.fileName
          ? resource.file.fileName.toLowerCase().includes(searchLower)
          : false;
        const pageTitleMatch = resource.page?.title
          ? resource.page.title.toLowerCase().includes(searchLower)
          : false;
        return titleMatch || fileNameMatch || pageTitleMatch;
      }) || []
    );
  }, [resources, searchString]);

  const handleSearch = (value: string) => {
    setSearchString(value.toLowerCase());
  };
  const debounceSearch = useCallback(debounce(handleSearch, 300), []);
  // search related code ends here

  // sorting related code:
  const [sortConfig, setSortConfig] = useState<{
    key: 'createdAt' | 'title' | 'uploader' | 'isPinned';
    direction: 'ascending' | 'descending';
  }>({ key: 'isPinned', direction: 'descending' });

  const getTitle = (resource: SingleCommunityResourceType) => {
    return resource.type === 'page' ? resource.page?.title || '' : resource.title || '';
  };

  const sortedResources = useMemo(() => {
    let filtered = filteredResources;

    if (sortConfig.key) {
      filtered = [...filteredResources].sort((a, b) => {
        let aValue, bValue;
        if (sortConfig.key === 'isPinned') {
          aValue = a.isPinned ? 1 : 0; // Convert boolean to number for comparison
          bValue = b.isPinned ? 1 : 0;
        } else if (sortConfig.key === 'createdAt') {
          aValue = new Date(a.createdAt).getTime();
          bValue = new Date(b.createdAt).getTime();
        } else if (sortConfig.key === 'title') {
          aValue = getTitle(a);
          bValue = getTitle(b);
        } else if (sortConfig.key === 'uploader') {
          aValue = a.uploader.firstName;
          bValue = b.uploader.firstName;
        } else {
          aValue = a[sortConfig.key];
          bValue = b[sortConfig.key];
        }
        if (aValue < bValue) {
          return sortConfig.direction === 'ascending' ? -1 : 1;
        }
        if (aValue > bValue) {
          return sortConfig.direction === 'ascending' ? 1 : -1;
        }
        return 0;
      });
    }
    return filtered;
  }, [filteredResources, sortConfig]);

  const handleSort = (key: 'title' | 'createdAt' | 'uploader' | 'isPinned') => {
    let direction: 'ascending' | 'descending' = 'ascending';
    if (sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }
    setSortConfig({ key, direction });
  };
  // sorting related code ends here

  const handleOpenEditResourceModal = (resourceId: string, title: string, url: null | string) => {
    setSelectedResourceUrl(url);
    setOpenEditResourceModal(true);
    setSelectedResourceTitle(title);
    setSelectedResourceId(resourceId);
  };

  const handleCloseEditResourceModal = () => {
    setOpenEditResourceModal(false);
    setSelectedResourceId('');
  };

  const handleOpenConfirmation = (resourceId: string) => {
    setOpenConfirmation(true);
    setSelectedResourceId(resourceId);
  };

  const handleDeleteResource = () => {
    deleteProgramPublicResource(Program.id, selectedResourceId)
      .then(() => {
        refetch();
        setOpenConfirmation(false);
        notifySuccess(t('success.api.resource_deleted'));
      })
      .catch((error) => {
        notifyTranslatedError(t, error.response.data?.errorCode);
      });
  };

  const handleEditResource = (data: { title: string; url: string }) => {
    updateProgramPublicResource(Program.id, selectedResourceId, data)
      .then(() => {
        refetch();
        handleCloseEditResourceModal();
        notifySuccess(t('success.api.resource_updated'));
      })
      .catch((error) => {
        notifyTranslatedError(t, error.response.data?.errorCode);
      });
  };

  const handlePin = (resourceId: string) => {
    pinProgramPublicResource(Program.id, resourceId)
      .then(() => {
        refetch();
        notifySuccess(t('action-success'));
      })
      .catch((error) => {
        notifyTranslatedError(t, error.response.data?.errorCode);
        notifyTranslatedError(t, error.response.data?.message);
      });
  };

  return (
    <>
      <div className='flex flex-row items-center gap-2 mb-4 px-1 justify-between'>
        <SearchInput onSearch={debounceSearch} />
        <BodyText variant='base' className='mr-auto text-neutral-500 hidden md:block'>
          <span className='text-secondary-500 font-bold'>{resources?.totalResources}</span>{' '}
          {t('items')}
        </BodyText>
        <Button
          size='small'
          variant='secondary-outlined'
          onClick={() => setOpenAddResourceModal(true)}
          tooltip={t('add-item')}
        >
          <AddIcon className='h-5 w-5' />
        </Button>
      </div>

      <div className='table-container'>
        <table>
          <thead>
            <tr>
              <th>
                <div className='flex items-center gap-1'>
                  <BodyText variant='sm' className='font-medium'>
                    {t('resource_name')}
                  </BodyText>
                  <button
                    className='neutral-button rounded-full p-1'
                    onClick={() => handleSort('title')}
                  >
                    <SortIcon className='h-4 w-4' />
                  </button>
                </div>
              </th>
              <th>
                <div className='flex items-center gap-1'>
                  <BodyText variant='sm' className='font-medium'>
                    {t('date')}
                  </BodyText>
                  <button
                    className='neutral-button rounded-full p-1'
                    onClick={() => handleSort('createdAt')}
                  >
                    <SortIcon className='h-4 w-4' />
                  </button>
                </div>
              </th>
              <th>
                <div className='flex items-center gap-1'>
                  <BodyText variant='sm' className='font-medium'>
                    {t('created-by')}
                  </BodyText>
                  <button
                    className='neutral-button rounded-full p-1'
                    onClick={() => handleSort('uploader')}
                  >
                    <SortIcon className='h-4 w-4' />
                  </button>
                </div>
              </th>
              <th>
                <BodyText variant='sm' className='font-medium'>
                  {t('actions')}
                </BodyText>
              </th>
            </tr>
          </thead>
          <tbody>
            {sortedResources.map(
              ({
                id,
                createdAt,
                uploadId,
                file,
                url,
                title,
                uploader,
                isMine,
                type,
                page,
                isPinned,
              }) => (
                <tr key={id} className='group'>
                  {((type === 'file' && file) || type === 'page') && (
                    <td>
                      <button
                        className='line-clamp-3 flex w-full max-w-[30rem] items-center gap-2 break-all rounded-lg p-1 text-left text-neutral-500 hover:underline'
                        onClick={() => {
                          if (type === 'page') {
                            setFileUrl('');
                            setOriginId(page?.id || '');
                            setOriginType('page');
                            setUploadedBy(uploader?.id);
                            setUploaderName(uploader?.firstName + ' ' + uploader?.lastName);
                            setShowResourceViewer(true);
                          } else if (isViewableFile(file?.directory || '')) {
                            setFileUrl(file?.directory || '');
                            setOriginId(uploadId);
                            setUploaderName(uploader?.firstName + ' ' + uploader?.lastName);
                            setUploadedBy(uploader?.id);
                            setOriginType('upload');
                            setShowResourceViewer(true);
                          } else {
                            window.location.href = file?.directory || '';
                          }
                        }}
                      >
                        {type === 'file' && file ? (
                          <div className='line-clamp-3 flex  max-w-[30rem] items-center gap-2 break-all rounded-lg p-1 text-left text-neutral-500'>
                            <MaterialIcon
                              fileInput={file.directory + ''}
                              className='h-5 w-5 flex-shrink-0'
                            />
                            <div>
                              <BodyText variant='sm' className='text-left'>
                                {title}
                              </BodyText>
                              <BodyText variant='xs' className='text-left text-neutral-400'>
                                {bytesToMegaBytes(file.fileSize)}
                              </BodyText>
                            </div>
                          </div>
                        ) : (
                          <div className='line-clamp-3 flex   max-w-[30rem] items-center gap-2 break-all rounded-lg p-1 text-left text-neutral-500'>
                            <NoteIcon className='h-5 w-5 flex-shrink-0' />
                            <BodyText variant='sm'>{page?.title}</BodyText>
                          </div>
                        )}
                        {isPinned && (
                          <div className='flex h-4 w-4 items-center justify-center rounded-full bg-functional-info-light text-functional-info-dark'>
                            {' '}
                            <PinIcon className='h-3 w-3' />
                          </div>
                        )}
                      </button>
                    </td>
                  )}
                  {type === 'url' && url && (
                    <td>
                      <a href={url} target='blank'>
                        <div className='line-clamp-3 flex w-full max-w-[30rem] items-center gap-2 break-all rounded-lg p-1 text-left text-neutral-500 hover:underline'>
                          <LinkIcon className='h-5 w-5 flex-shrink-0' />
                          <BodyText variant='sm'>{title}</BodyText>
                          {isPinned && (
                            <div className='flex h-4 w-4 items-center justify-center rounded-full bg-functional-info-light text-functional-info-dark'>
                              {' '}
                              <PinIcon className='h-3 w-3' />
                            </div>
                          )}
                        </div>
                      </a>
                    </td>
                  )}
                  <td>
                    <BodyText variant='sm' className='text-neutral-500'>
                      {dayjs(createdAt).format('ddd, D MMM YYYY, h:mm A')}
                    </BodyText>
                  </td>
                  <td>
                    <MemberButton
                      userId={uploader.id}
                      isCreator={false}
                      profilePicture={uploader.profilePicture || ''}
                      userName={uploader.firstName + ' ' + uploader.lastName}
                      chat={false}
                      size='small'
                    />
                  </td>
                  <td>
                    <div className='flex items-center gap-3 lg:opacity-0 lg:group-hover:opacity-100'>
                      {(isMine || Program.canManage) && (
                        <button
                          className='flex h-6 w-6 items-center justify-center rounded-full hover:bg-neutral-300 active:bg-neutral-400'
                          onClick={() => handleOpenConfirmation(id)}
                        >
                          <DeleteIcon className='h-5 w-5 text-neutral-400 hover:text-functional-danger-dark active:text-functional-danger-dark' />
                        </button>
                      )}
                      {Program.canManage && (
                        <button
                          className='neutral-button flex h-6 w-6 items-center justify-center rounded-full text-neutral-400 '
                          onClick={() => handlePin(id)}
                        >
                          <PinIcon className='h-4 w-4' />
                        </button>
                      )}
                      {isMine && type !== 'page' && (
                        <button
                          className='flex h-6 w-6 items-center justify-center rounded-full hover:bg-neutral-300 active:bg-neutral-400'
                          onClick={() => handleOpenEditResourceModal(id, title, url)}
                        >
                          <EditIcon className='h-5 w-5 text-neutral-400 hover:text-functional-info-dark active:text-functional-info-dark' />
                        </button>
                      )}
                      {type === 'file' && (
                        <button
                          className='flex h-6 w-6 items-center justify-center rounded-full hover:bg-neutral-300 active:bg-neutral-400'
                          onClick={() => {
                            window.open(file?.directory, '_blank');
                          }}
                        >
                          <DownloadIcon className='h-5 w-5 text-functional-info-dark' />
                        </button>
                      )}
                    </div>
                  </td>
                </tr>
              ),
            )}
          </tbody>
        </table>
      </div>

      {openAddResourceModal && (
        <AddPublicResourceModal
          isOpen={openAddResourceModal}
          onClose={() => setOpenAddResourceModal(false)}
          originId={programId || ''}
          originType={'program'}
          refetch={refetch}
        />
      )}

      {openEditResourceModal && (
        <EditPublicResourceModal
          url={selectedResourceUrl}
          title={selectedResourceTitle}
          isOpen={openEditResourceModal}
          onClose={handleCloseEditResourceModal}
          handleUpdateResource={handleEditResource}
        />
      )}

      {openConfirmation && (
        <ConfirmationModal
          isOpen={openConfirmation}
          label={t('delete_resource')}
          title={t('delete_resource_confirmation_title')}
          description={
            t('delete_resource_confirmation_description') ||
            'This resource will be deleted permanently?'
          }
          onClose={() => setOpenConfirmation(false)}
          onConfirm={handleDeleteResource}
        />
      )}
      {(isViewableFile(fileUrl) || originType === 'page') && (
        <ResourceViewerDrawer
          isOpen={showResourceViewer}
          setIsOpen={setShowResourceViewer}
          fileUrl={fileUrl}
          uploaderId={uploadedBy}
          uploadedBy={uploaderName}
          originId={originId}
          originType={originType}
        />
      )}
    </>
  );
};

export default ProgramPublicFolder;
