import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { AppRoutes } from 'AppRoutes';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { ToastContainer } from 'react-toastify';
import { ThemeProvider } from 'components/layout/ThemeContext';
import dayjs from 'dayjs';
import 'react-toastify/dist/ReactToastify.css';

const locales = {
  de: () => import('dayjs/locale/de'),
  en: () => import('dayjs/locale/en'),
  zh: () => import('dayjs/locale/zh-cn'),
};

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 30 * 60 * 1000,
      retry: 3,
    },
  },
});

export const App: React.FC = () => {
  const { i18n } = useTranslation();
  useEffect(() => {
    const { language } = i18n;
    if (!['de', 'en', 'zh'].includes(language)) return;
    locales[language as 'de' | 'en' | 'zh']().then((locale) => dayjs.locale(locale));
  }, [i18n.language]);

  return (
    <QueryClientProvider client={queryClient}>
      <ThemeProvider>
        <div>
          <AppRoutes />
          <ReactQueryDevtools initialIsOpen={false} />
          <ToastContainer />
        </div>
      </ThemeProvider>
    </QueryClientProvider>
  );
};

export default App;
