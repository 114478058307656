import Button from 'components/button/Button';
import ResetPassCard from 'components/card/ResetPassCard';
import TextInput from 'components/form/TextInput';
import BodyText from 'components/typography/BodyText';
import Heading from 'components/typography/Heading';
import React from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { ResetPasswordFormType } from 'types/components/form';
import { resetPassword } from 'utils/api/auth';
import { notifySuccess, notifyTranslatedError } from 'utils/notify';

function NewPasswordPage() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { state } = useLocation();

  const {
    watch,
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<ResetPasswordFormType>();

  const onSubmit: SubmitHandler<ResetPasswordFormType> = (data) => {
    const { type, email, code } = state;
    if (!type || !email || !code) return;

    resetPassword({ code, email, ...data })
      .then(() => {
        notifySuccess(t('success.api.password_updated'));
        navigate('/reset-success');
      })
      .catch((error) => {
        notifyTranslatedError(t, error.response.data?.errorCode);
      });
  };

  return (
    <main className='flex h-screen items-center justify-center px-4'>
      <ResetPassCard>
        <Heading variant='h2' className='mb-3 text-center font-bold'>
          {t('create_new_password')}
        </Heading>
        <BodyText variant='base' className='mb-6 text-center text-neutral-400'>
          {t('create_new_password_subtitle')}
        </BodyText>

        <form onSubmit={handleSubmit(onSubmit)} className='mb-6 space-y-6'>
          <div>
            <Controller
              name='password'
              control={control}
              rules={{
                required: {
                  value: true,
                  message: t('required_error_message'),
                },
                minLength: {
                  value: 6,
                  message: t('password_length_error_message', { length: 6 }),
                },
                pattern: {
                  value: /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{6,})/,
                  message: t('password_pattern_error_message'),
                },
              }}
              render={({ field }) => (
                <TextInput
                  type='password'
                  placeholder={t('new-password') || 'New password'}
                  {...field}
                />
              )}
            />
            {errors.password && (
              <BodyText variant='xs' className='text-functional-danger-400'>
                {errors.password?.message}
              </BodyText>
            )}
          </div>

          <div>
            <Controller
              name='confirmPassword'
              control={control}
              rules={{
                required: { value: true, message: t('required_error_message') },
                // eslint-disable-next-line consistent-return
                validate: (val: string) => {
                  if (watch('password') !== val) {
                    return t('ERROR.PASSWORD_MISMATCH') || 'Passwords do not match';
                  }
                },
              }}
              render={({ field }) => (
                <TextInput
                  type='password'
                  placeholder={t('repeat-password') || 'Repeat password'}
                  {...field}
                />
              )}
            />
            {errors.confirmPassword && (
              <BodyText variant='xs' className='text-functional-danger-400'>
                {errors.confirmPassword?.message}
              </BodyText>
            )}
          </div>

          <Button size='default' variant='secondary' className='w-full'>
            {t('submit')}
          </Button>
        </form>

        <BodyText variant='base' className='text-center font-medium text-functional-info-dark'>
          <Link to='/login'>{t('back_to_signin')}</Link>
        </BodyText>
      </ResetPassCard>
    </main>
  );
}

export default NewPasswordPage;
