import { TFunction } from 'i18next';
import { toast } from 'react-toastify';

export const notifySuccess = (message: string, onClose?: () => void) => {
  toast.success(message, {
    onClose,
    autoClose: 2000,
    theme: 'colored',
    pauseOnHover: false,
    hideProgressBar: true,
    position: 'bottom-center',
  });
};

export const notifyWarning = (message: string, onClose?: () => void) => {
  toast.warn(message, {
    onClose,
    autoClose: 2000,
    theme: 'colored',
    pauseOnHover: false,
    hideProgressBar: true,
    position: 'bottom-center',
  });
};

export const notifyInfo = (message: string, onClose?: () => void) => {
  toast.info(message, {
    onClose,
    autoClose: 2000,
    theme: 'colored',
    pauseOnHover: false,
    hideProgressBar: true,
    position: 'bottom-center',
  });
};

export const notifyError = (message: string, onClose?: () => void) => {
  toast.error(message, {
    onClose,
    autoClose: 2000,
    theme: 'colored',
    pauseOnHover: false,
    hideProgressBar: true,
    position: 'bottom-center',
  });
};

export const notifyTranslatedError = (t: TFunction, errorcode: string) => {
  notifyError(t(errorcode ? `ERROR.${errorcode}` : 'ERROR.UNEXPECTED'));
};
