import React from 'react';
import USFlagIcon from 'assets/flags/us.svg';
import CNFlagIcon from 'assets/flags/cn.svg';
import DEFlagIcon from 'assets/flags/de.svg';

export const languages = [
  {
    code: 'en',
    label: 'English',
    icon: <USFlagIcon className='h-5 w-6' />,
  },
  {
    code: 'de',
    label: 'Deutsch',
    icon: <DEFlagIcon className='h-5 w-6' />,
  },
  {
    code: 'zh',
    label: '简体中文',
    icon: <CNFlagIcon className='h-5 w-6' />,
  },
];
