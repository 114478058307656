import useIsLoggedIn from './api/auth/useIsLoggedIn';

export const useUser = () => {
  const { data } = useIsLoggedIn();
  if (!data) return null;
  const { id, firstName, lastName, primaryOrganizationId } = data;

  return {
    id,
    firstName,
    lastName,
    primaryOrganizationId,
  };
};
