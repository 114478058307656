import { useInvalidateQueries } from 'hooks/common/useInvalidatequeries';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { createProgramEvent } from 'utils/api/event';
import { notifySuccess, notifyTranslatedError } from 'utils/notify';
import CreateEventForm from '../event/CreateEventForm';
import { generateProgramLink } from 'utils/url';

const CreateProgramEventPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { programId } = useParams();

  const { invalidateProgramEvents, invalidateUpcomingProgramEvents } = useInvalidateQueries();

  const handleCreateEvent = (eventData: FormData) => {
    if (!programId) return;

    createProgramEvent(programId, eventData)
      .then(() => {
        navigate(`${generateProgramLink(programId)}/events`);
        notifySuccess(t('success.api.event_created'));
        invalidateProgramEvents(programId);
        invalidateUpcomingProgramEvents(programId);
      })
      .catch((error) => {
        notifyTranslatedError(t, error.response.data?.errorCode);
      });
  };

  return <CreateEventForm handleCreateEvent={handleCreateEvent} />;
};

export default CreateProgramEventPage;
