import React from 'react';
import LoadingIcon from '../assets/icons/loading.svg';

const Loading = () => {
  return (
    <div className='flex h-screen w-screen items-center justify-center bg-neutral-100 bg-opacity-65'>
      <LoadingIcon className='h-10 w-10 text-secondary-500' />
    </div>
  );
};

export default Loading;
