import { Switch } from '@headlessui/react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import ModalContainer from './ModalContainer';
import BodyText from 'components/typography/BodyText';
import Button from 'components/button/Button';
import { updatePageComment } from 'utils/api/pages';
import { useSingelPage } from 'hooks/api/pages/usePages';
import { notifySuccess, notifyTranslatedError } from 'utils/notify';

interface IEmbedConversationSettingModal {
  isOpen: boolean;
  onClose: () => void;
  pageId: string;
  allowComment?: boolean;
}

const EmbedConversationSettingModal = ({
  isOpen,
  pageId,
  allowComment,
  onClose,
}: IEmbedConversationSettingModal) => {
  const { t } = useTranslation();
  const { data: page, isLoading, refetch } = useSingelPage(pageId);

  const handleUpdateAccess = (enabled: boolean) => {
    const body = { allowComment: enabled };
    updatePageComment(pageId, body)
      .then(() => {
        refetch();
      })
      .catch((error) => {
        notifyTranslatedError(t, error.response.data?.errorCode);
      });
  };

  return (
    <ModalContainer title={t('comment_thread_settings')} isOpen={isOpen} onClose={onClose}>
      <div className='flex items-center justify-between py-3'>
        <BodyText variant='base' className='font-medium'>
          {t('allow_other_comment')}
        </BodyText>
        <Switch
          checked={!!page?.allowComment}
          onChange={(value: boolean) => handleUpdateAccess(value)}
          className={`${
            page?.allowComment ? 'bg-functional-success-dark' : 'bg-functional-danger-dark'
          } relative inline-flex h-5 w-[44px] items-center rounded-full`}
        >
          <span
            className={`${
              page?.allowComment
                ? 'translate-x-[3px] bg-functional-success-light'
                : 'translate-x-[21px] bg-functional-danger-light'
            } inline-block h-[20px] w-[20px] transform rounded-full transition`}
          />
        </Switch>
      </div>
      <div className='mt-5 flex justify-end gap-3 border-t border-neutral-200 pt-5'>
        <Button type='button' size='small' variant='secondary' onClick={onClose}>
          {t('close')}
        </Button>
      </div>
    </ModalContainer>
  );
};

export default EmbedConversationSettingModal;
