import { useQuery } from '@tanstack/react-query';
import { getUnread } from 'utils/api/message';
import { SIXTY_SECONDS } from 'utils/time';

export const useHasUnread = () => {
  const { data } = useQuery({
    queryKey: ['hasUnread'] as const,
    queryFn: () => getUnread(),
    refetchInterval: SIXTY_SECONDS,
  });
  return {
    unreadCount: data?.data?.unreadCount ?? 0,
  };
};
