import { Tab } from '@headlessui/react';
import DetailsBanner from 'components/common/DetailsBanner';
import Layout from 'components/layout/Layout';
import Loading from 'components/Loading';
import NavigateTo404 from 'components/NavigateTo404';
import { communityTabItems } from 'config/tabs';
import { useCommunity } from 'hooks/api/community/useCommunity';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Outlet, useLocation, useNavigate, useParams } from 'react-router-dom';
import { generateCommunityLink } from 'utils/url';
import RelatedCommunities from './segment/communities/RelatedCommunities';

const CommunityPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const { communityId } = useParams();
  let tabName = location.pathname.split('/').pop();
  if (location.pathname.includes('tasks/submissions')) {
    tabName = 'tasks';
  }
  const currentTabIndexFromRouter = communityTabItems.findIndex((item) => item.path === tabName);

  const [showShortBanner, setShowShortBanner] = useState(currentTabIndexFromRouter > 0);

  const { data: community, isLoading: isCommuntiyLoading } = useCommunity(communityId || '');

  if (isCommuntiyLoading) return <Loading />;

  if (!community) return <NavigateTo404 />;

  const {
    id,
    name,
    cover,
    logo,
    archived,
    isMember,
    communityRole,
    visibility,
    memberCount,
    description,
    archiveDate,
    joinRequested,
    updatedAt,
    organization,
    isGroup,
    groupParent,
  } = community;

  return (
    <Layout>
      <div>
        <DetailsBanner
          id={id}
          name={name}
          cover={cover}
          variant='community'
          archived={archived}
          isMember={isMember}
          role={communityRole}
          visibility={visibility}
          memberCount={memberCount}
          description={description}
          archiveDate={archiveDate}
          joinRequested={joinRequested}
          updatedAt={updatedAt}
          organizationName={organization.name}
          organizationLogo={logo || organization.logo}
          organizationId={organization.id}
          isGroup={isGroup}
          groupParent={groupParent}
          allowGuest={community.allowGuest}
          guestViewCount={community.guestViewCount}
        />

        {community.isMember ? (
          <Tab.Group
            onChange={(value) => {
              navigate(`${generateCommunityLink(community.id)}/${communityTabItems[value].path}`);
              setShowShortBanner(value !== 0);
            }}
            selectedIndex={tabName ? currentTabIndexFromRouter : 0}
          >
            <Tab.List
              className={`tab-list-parent z-20 shrink-0 overflow-x-auto overflow-y-hidden bg-secondary-100 lg:sticky lg:top-[85px]`}
            >
              {communityTabItems.map(({ label }) => (
                <Tab key={label} className='outline-0'>
                  {({ selected }) => (
                    <div
                      className={`nonactive-tab ${
                        selected ? 'active-tab text-secondary-500' : 'border-neutral-300'
                      }`}
                    >
                      {t(label)}
                    </div>
                  )}
                </Tab>
              ))}
            </Tab.List>

            <Tab.Panels>
              {communityTabItems.map(({ path }) => (
                <Tab.Panel key={path} className='outline-none p-2 lg:py-4 lg:px-5'>
                  <Outlet />
                </Tab.Panel>
              ))}
            </Tab.Panels>
          </Tab.Group>
        ) : (
          <RelatedCommunities communityId={community.id} />
        )}
      </div>
    </Layout>
  );
};

export default CommunityPage;
