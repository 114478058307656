import { AxiosRequestConfig } from 'axios';
import { API_ROOT } from 'constants/config';
import {
  ConversationCreationResponse,
  GetEmbedConversationListResponse,
} from 'types/apis/response';
import {
  GetEmbedMessagesResponseType,
  SingleEmbedConversationResponseType,
  LastReadMessageResponseType,
} from 'types/apis/response';
import { parseGET, parsePOST, generateQueryParam, parsePUT, parseDELETE } from 'utils/rest';

export const startEmbedConversation = (
  body: {
    conversationType: string;
    originType: string;
    originId: string;
    alias: string;
  },
  options?: AxiosRequestConfig,
) => {
  return parsePOST<typeof body, ConversationCreationResponse>(
    `${API_ROOT}/embedConversation/createConversation`,
    body,
    {
      withCredentials: true,
      ...options,
    },
  );
};

export const getPageEmbedConversationList = (
  originType: string,
  originId: string,
  options?: AxiosRequestConfig,
) => {
  return parseGET<GetEmbedConversationListResponse>(
    `${API_ROOT}/embedConversation/list/${originType}/${originId}`,
    {
      withCredentials: true,
      ...options,
    },
  );
};

export const getEmbedMessages = (
  conversationId: string,
  limit = 20,
  cursor?: string,
  options?: AxiosRequestConfig,
) => {
  const queryParam = generateQueryParam({ limit, cursor });
  return parseGET<GetEmbedMessagesResponseType>(
    `${API_ROOT}/embedConversation/${conversationId}/messages${queryParam}`,
    {
      withCredentials: true,
      ...options,
    },
  );
};

export const getLastRead = (conversationId: string, options?: AxiosRequestConfig) => {
  return parseGET<LastReadMessageResponseType>(
    `${API_ROOT}/embedConversation/${conversationId}/lastread`,
    {
      withCredentials: true,
      ...options,
    },
  );
};

export const sendEmbedMessage = (
  conversationId: string,
  body: FormData,
  options?: AxiosRequestConfig,
) => {
  return parsePOST(`${API_ROOT}/embedConversation/${conversationId}/message`, body, {
    withCredentials: true,
    ...options,
  });
};

export const deleteEmbedConversation = (conversationId: string, options?: AxiosRequestConfig) => {
  return parseDELETE(`${API_ROOT}/embedConversation/delete/${conversationId}`, {
    withCredentials: true,
    ...options,
  });
};

export const editEmbedConversation = (
  conversationId: string,
  body: { newAlias: string; newConversationType: string },
  options?: AxiosRequestConfig,
) => {
  return parsePUT(`${API_ROOT}/embedConversation/edit/${conversationId}`, body, {
    withCredentials: true,
    ...options,
  });
};

export const getSingleEmbedConversation = (
  conversationId: string,
  options?: AxiosRequestConfig,
) => {
  return parseGET<SingleEmbedConversationResponseType>(
    `${API_ROOT}/embedConversation/${conversationId}/singleConversation`,
    {
      withCredentials: true,
      ...options,
    },
  );
};
