import { TFunction } from 'i18next';
import { PostOrigin } from 'types/apis/common';
import {
  dislikeCommunityPost,
  dislikeProgramPost,
  likeCommunityPost,
  likeProgramPost,
} from './api/post';
import { notifyTranslatedError } from './notify';

export const handleLikePost = (
  isLiked: boolean,
  postId: string,
  originId: string,
  orginType: PostOrigin,
  inValidatePosts: () => void,
  t: TFunction,
) => {
  if (isLiked) {
    if (orginType === 'community') {
      dislikeCommunityPost(originId, postId)
        .then(() => {
          inValidatePosts();
        })
        .catch((error) => {
          notifyTranslatedError(t, error.response.data?.errorCode);
        });
    } else if (orginType === 'program') {
      dislikeProgramPost(originId, postId)
        .then(() => {
          inValidatePosts();
        })
        .catch((error) => {
          notifyTranslatedError(t, error.response.data?.errorCode);
        });
    }
    return;
  }

  if (orginType === 'community') {
    likeCommunityPost(originId, postId)
      .then(() => {
        inValidatePosts();
      })
      .catch((error) => {
        notifyTranslatedError(t, error.response.data?.errorCode);
      });
  } else if (orginType === 'program') {
    likeProgramPost(originId, postId)
      .then(() => {
        inValidatePosts();
      })
      .catch((error) => {
        notifyTranslatedError(t, error.response.data?.errorCode);
      });
  }
};
