import { AxiosProgressEvent } from 'axios';
import Button from 'components/button/Button';
import FileIcon from 'assets/icons/upload.svg';
import SearchIcon from 'assets/icons/search.svg';
import EditIcon from 'assets/icons/edit.svg';
import RichTextEditor from 'components/common/RichTextEditor';
import TextInput from 'components/form/TextInput';
import BodyText from 'components/typography/BodyText';
import Uploading from 'components/Uploading';
import Avatar from 'components/memberAndProfile/Avatar';
import { useFileUploadProgress } from 'hooks/common/useFileUploadProgress';
import React, { useRef, useState } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { objToFormData } from 'utils/rest';
import ModalContainer from './ModalContainer';
import { OutputData } from '@editorjs/editorjs';
import ImageIcon from 'assets/icons/image.svg';
import { commonCover } from 'constants/common';
import ImageSearchModal from 'components/modal/ImageSearchModal';
import { DropdownMenu } from 'components/common/DropdownMenu';
import { useHashtags } from 'hooks/api/program/useMyPrograms';

type FormDefaultValues = {
  name: string;
  description: string;
  cover: null | string;
  visibility: 'open' | 'public' | 'private';
  allowGuest: string;
  logo?: string;
  hashtags?: string;
};

interface ICreationModal {
  isOpen: boolean;
  ctaText?: string;
  isGroup?: boolean;
  modalTitle: string;
  onClose: () => void;
  isDuplicating?: boolean;
  isProgramDuplicating?: boolean;
  isCommunityDuplicating?: boolean;
  defaultValues: FormDefaultValues;
  onFormSubmit: (
    data: FormData,
    onUploadProgress: (progressEvent: AxiosProgressEvent) => void,
    setIsUploading: React.Dispatch<React.SetStateAction<boolean>>,
  ) => void;
}

type CreationFormInputType = {
  name: string;
  visibility: string;
  description: string;
  allowGuest: string;
  cover?: null | string | File;
  logo?: null | string | File;
  coverImage?: string;
  hashtags?: string;

  // duplication fields
  copyMembers?: 'true' | 'false';
  copyManagers?: 'true' | 'false';
  copyResources?: 'true' | 'false';
  copyEvents?: 'true' | 'false';
  copyTasks?: 'true' | 'false';
  copyMaterials?: 'true' | 'false';
};

function CreationModal({
  isOpen,
  onClose,
  isGroup,
  ctaText,
  modalTitle,
  onFormSubmit,
  defaultValues,
  isDuplicating = false,
  isProgramDuplicating = false,
  isCommunityDuplicating = false,
}: ICreationModal) {
  const { hashtags, refetchHashtags } = useHashtags();
  const { t } = useTranslation();
  const coverInputRef = useRef<HTMLInputElement>(null);
  const logoInputRef = React.useRef<HTMLInputElement>(null);
  const { isUploading, setIsUploading, progress, onUploadProgress } = useFileUploadProgress();
  const [aboutText, setAboutText] = useState<OutputData | null>(null);
  const [showImageSearchModal, setShowImageSearchModal] = useState(false);
  const [onSelectImage, setOnSelectImage] = useState<string | null>(null);
  const [shareToWeb, setShareToWeb] = useState(defaultValues.allowGuest === 'true' ? true : false);
  const [hashtag, setHashtag] = useState(defaultValues.hashtags || '');
  const [selectedHashtag, setSelectedHashtag] = useState('');
  const [suggestedHashtags, setSuggestedHashtags] = useState<string[]>([]);

  const {
    watch,
    control,
    register,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm<CreationFormInputType>({ defaultValues });
  const onSubmit: SubmitHandler<CreationFormInputType> = (data) => {
    const { cover, logo, ...rest } = data;
    const formData = objToFormData({ ...rest });
    formData.append(
      'description',
      aboutText ? JSON.stringify(aboutText) : defaultValues.description,
    );
    formData.append('allowGuest', shareToWeb.toString());
    if (cover && typeof cover !== 'string') {
      formData.append('cover', cover);
    } else if (onSelectImage) {
      formData.append('coverImage', onSelectImage);
    }
    if (logo && typeof logo !== 'string') {
      formData.append('logo', logo);
    }
    // Format and validate the hashtag
    if (!hashtag.trim() && defaultValues.hashtags) {
      formData.append('hashtags', '');
    } else if (hashtag.trim()) {
      // Format and validate the hashtag
      let formattedHashtag = hashtag.trim().startsWith('#') ? hashtag.trim() : `#${hashtag.trim()}`;
      formData.append('hashtags', formattedHashtag);
    }

    setIsUploading(true);
    onFormSubmit(formData, onUploadProgress, setIsUploading);
    //reset file input
    if (coverInputRef.current) {
      coverInputRef.current.value = '';
    }
    if (logoInputRef.current) {
      logoInputRef.current.value = '';
    }
  };

  // Event handler for cover image upload
  const handleCoverUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files ? e.target.files[0] : null;
    if (file) {
      setValue('cover', file);
      setOnSelectImage(null); // Make sure this doesn't affect the logo state
    }
  };

  // Event handler for logo upload
  const handleLogoUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files ? e.target.files[0] : null;
    if (file) {
      setValue('logo', file);
    }
  };

  const handleHashtagChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = event.target.value;
    setHashtag(inputValue);
    setSuggestedHashtags(
      (hashtags || []).filter((tag) =>
        String(tag).toLowerCase().includes(inputValue.toLowerCase()),
      ),
    );
  };

  const handleHashtagSelect = (tag: string) => {
    setHashtag(tag);
    setSelectedHashtag(JSON.stringify({ hashtag: tag }));
    setSuggestedHashtags([]);
  };

  return (
    <ModalContainer title={modalTitle} isOpen={isOpen} onClose={onClose}>
      <form onSubmit={handleSubmit(onSubmit)} className='space-y-4'>
        {/* cover */}
        {!isGroup && (
          <div className='space-y-1 relative '>
            <BodyText
              variant='sm'
              className={`font-medium ${
                errors.cover ? 'text-functional-danger-400' : 'text-neutral-500'
              }`}
            >
              {t('cover')} & Logo
            </BodyText>
            <div className='relative'>
              {watch('cover') ? (
                // already has a cover
                typeof watch('cover') === 'string' ? (
                  <img
                    alt=''
                    src={watch('cover') as string}
                    className='h-[220px] w-full rounded-lg object-cover'
                  />
                ) : (
                  // locally selected cover
                  <img
                    alt=''
                    className='h-[220px] w-full rounded-lg object-cover'
                    src={URL.createObjectURL(watch('cover') as File)}
                  />
                )
              ) : (
                // stock or default event cover
                <img
                  src={onSelectImage ? onSelectImage : commonCover}
                  alt=''
                  className='h-[220px] w-full rounded-lg object-cover'
                />
              )}
              <DropdownMenu
                menuButton={
                  <Button
                    size='small'
                    type='button'
                    variant='primary'
                    className='absolute bottom-4 right-4'
                  >
                    <ImageIcon className='h-4 w-4' /> {t('change_cover')}
                  </Button>
                }
                menuItems={[
                  {
                    key: 'upload_cover',
                    label: t('upload-image'),
                    icon: FileIcon,
                    callback: () => coverInputRef.current?.click(),
                  },
                  {
                    key: 'share_to_web',
                    label: t('search-stock-image'),
                    icon: SearchIcon,
                    callback: () => setShowImageSearchModal(true),
                  },
                ]}
              />
              <input
                type='file'
                className='hidden'
                onChange={handleCoverUpload}
                ref={coverInputRef}
                accept='image/png, image/jpeg'
              />
            </div>
            <div className='absolute -bottom-5 left-0 ml-4 w-fit rounded-full border-4 border-primary-500'>
              {/* profile picture already exist */}
              {watch('logo') && typeof watch('logo') === 'string' && (
                <Avatar className='z-10' size={80} src={watch('logo') as string} />
              )}

              {/* locally selected profile picture */}
              {watch('logo') && typeof watch('logo') !== 'string' && (
                <Avatar
                  className='z-10'
                  size={80}
                  src={URL.createObjectURL(watch('logo') as File)}
                />
              )}

              <Button
                type='button'
                className='absolute bottom-1 -right-3 rounded-full bg-neutral-600 p-1 '
                onClick={() => logoInputRef.current?.click()}
              >
                <EditIcon className='h-5 w-5 text-neutral-100' />
              </Button>
            </div>

            <input
              type='file'
              className='hidden'
              onChange={handleLogoUpload}
              ref={logoInputRef}
              accept='image/png, image/jpeg'
            />
          </div>
        )}
        <div className='space-y-1 mt-4'>
          <BodyText
            variant='sm'
            className={`font-medium ${
              errors.name ? 'text-functional-danger-400' : 'text-neutral-500'
            }`}
          >
            {t('name')}
          </BodyText>
          <Controller
            name='name'
            control={control}
            rules={{ required: true }}
            render={({ field }) => (
              <TextInput
                {...field}
                type='text'
                placeholder={t('name-placeholder') || 'Write name here...'}
                className={errors.name ? 'border-functional-danger-400' : ''}
                onChange={(e) => field.onChange((e.target as HTMLInputElement).value)}
              />
            )}
          />
        </div>
        {!isGroup && (
          <div className='space-y-1'>
            <BodyText
              variant='sm'
              className={`font-medium ${
                errors.hashtags ? 'text-functional-danger-400' : 'text-neutral-500'
              }`}
            >
              {t('hashtag')}
            </BodyText>
            <TextInput
              type='text'
              placeholder={t('hashtag-placeholder') || 'Add a hashtag...'}
              value={hashtag}
              onChange={handleHashtagChange}
              className='text-input-class'
            />
            {suggestedHashtags.length > 0 && (
              <ul className='border border-neutral-300 mt-1 max-h-40 overflow-auto'>
                {suggestedHashtags.map((tag, index) => (
                  <li
                    key={index}
                    onClick={() => handleHashtagSelect(tag)}
                    className='p-2 hover:bg-neutral-100 cursor-pointer'
                  >
                    {tag}
                  </li>
                ))}
              </ul>
            )}
          </div>
        )}
        <div className='space-y-1'>
          <BodyText variant='sm' className={`font-medium `}>
            {t('description')}
          </BodyText>
          <div className='ml-7'>
            <RichTextEditor
              initialContent={defaultValues.description}
              handleOnChange={(content) => setAboutText(content)}
            />
          </div>
        </div>

        {!isGroup && (
          <div>
            <div className='mb-4 flex items-center gap-5'>
              <BodyText variant='sm' className='font-medium'>
                {t('visibility')}
              </BodyText>

              <div className='flex items-center gap-4'>
                <Button
                  size='small'
                  type='button'
                  variant={watch('visibility') === 'open' ? 'primary' : 'outlined'}
                  onClick={() => setValue('visibility', 'open')}
                >
                  {t('open')}
                </Button>
                <Button
                  size='small'
                  type='button'
                  variant={watch('visibility') === 'public' ? 'primary' : 'outlined'}
                  onClick={() => setValue('visibility', 'public')}
                >
                  {t('public')}
                </Button>
                <Button
                  size='small'
                  type='button'
                  variant={watch('visibility') === 'private' ? 'primary' : 'outlined'}
                  onClick={() => setValue('visibility', 'private')}
                >
                  {t('private')}
                </Button>
              </div>
            </div>

            <BodyText variant='sm' className='ml-[78px] text-neutral-400'>
              {watch('visibility') === 'open' && t('open-space-description')}
              {watch('visibility') === 'public' && t('public-space-description')}
              {watch('visibility') === 'private' && t('private-space-description')}
            </BodyText>
          </div>
        )}
        {!isGroup && (
          <div className='flex items-center gap-3'>
            <input
              type='checkbox'
              id='softDeadline'
              checked={shareToWeb}
              onChange={(e) => {
                setShareToWeb(e.target.checked);
              }}
              className='h-5 w-5 rounded border-neutral-300 text-primary-500 focus:ring-primary-500'
            />
            <div>
              <BodyText variant='sm' className='font-medium text-neutral-500'>
                {t('share_to_web')}
              </BodyText>
              <BodyText variant='sm' className='text-neutral-400'>
                {t('share_to_web_tip')}
              </BodyText>
            </div>
          </div>
        )}
        {isDuplicating && (
          <>
            <hr className='border-neutral-300' />

            <div className='inline-grid grid-cols-2 gap-y-4'>
              {/* copy members */}
              <BodyText variant='sm' className='font-medium'>
                {t('members')}
              </BodyText>

              <div className='flex items-center gap-4'>
                <Button
                  size='small'
                  type='button'
                  className='min-w-[70px]'
                  variant={watch('copyMembers') === 'true' ? 'secondary' : 'outlined'}
                  onClick={() => setValue('copyMembers', 'true')}
                >
                  {t('yes')}
                </Button>
                <Button
                  size='small'
                  type='button'
                  className='min-w-[70px]'
                  variant={watch('copyMembers') === 'false' ? 'secondary' : 'outlined'}
                  onClick={() => setValue('copyMembers', 'false')}
                >
                  {t('no')}
                </Button>
              </div>

              {/* copy managers */}
              <BodyText variant='sm' className='font-medium'>
                {t('managers')}
              </BodyText>

              <div className='flex items-center gap-4'>
                <Button
                  size='small'
                  type='button'
                  className='min-w-[70px]'
                  variant={watch('copyManagers') === 'true' ? 'secondary' : 'outlined'}
                  onClick={() => setValue('copyManagers', 'true')}
                >
                  {t('yes')}
                </Button>
                <Button
                  size='small'
                  type='button'
                  className='min-w-[70px]'
                  variant={watch('copyManagers') === 'false' ? 'secondary' : 'outlined'}
                  onClick={() => setValue('copyManagers', 'false')}
                >
                  {t('no')}
                </Button>
              </div>

              {/* copy events */}
              <BodyText variant='sm' className='font-medium'>
                {t('events')}
              </BodyText>
              <div className='flex items-center gap-4'>
                <Button
                  size='small'
                  type='button'
                  className='min-w-[70px]'
                  variant={watch('copyEvents') === 'true' ? 'secondary' : 'outlined'}
                  onClick={() => setValue('copyEvents', 'true')}
                >
                  {t('yes')}
                </Button>
                <Button
                  size='small'
                  type='button'
                  className='min-w-[70px]'
                  variant={watch('copyEvents') === 'false' ? 'secondary' : 'outlined'}
                  onClick={() => setValue('copyEvents', 'false')}
                >
                  {t('no')}
                </Button>
              </div>
              {/* copy tasks */}
              <BodyText variant='sm' className='font-medium'>
                {t('tasks')}
              </BodyText>
              <div className='flex items-center gap-4'>
                <Button
                  size='small'
                  type='button'
                  className='min-w-[70px]'
                  variant={watch('copyTasks') === 'true' ? 'secondary' : 'outlined'}
                  onClick={() => setValue('copyTasks', 'true')}
                >
                  {t('yes')}
                </Button>
                <Button
                  size='small'
                  type='button'
                  className='min-w-[70px]'
                  variant={watch('copyTasks') === 'false' ? 'secondary' : 'outlined'}
                  onClick={() => setValue('copyTasks', 'false')}
                >
                  {t('no')}
                </Button>
              </div>

              {isCommunityDuplicating && (
                <>
                  {/* copy resources */}
                  <BodyText variant='sm' className='font-medium'>
                    {t('resources')}
                  </BodyText>
                  <div className='flex items-center gap-4'>
                    <Button
                      size='small'
                      type='button'
                      className='min-w-[70px]'
                      variant={watch('copyResources') === 'true' ? 'secondary' : 'outlined'}
                      onClick={() => setValue('copyResources', 'true')}
                    >
                      {t('yes')}
                    </Button>
                    <Button
                      size='small'
                      type='button'
                      className='min-w-[70px]'
                      variant={watch('copyResources') === 'false' ? 'secondary' : 'outlined'}
                      onClick={() => setValue('copyResources', 'false')}
                    >
                      {t('no')}
                    </Button>
                  </div>
                </>
              )}

              {isProgramDuplicating && (
                <>
                  <BodyText variant='sm' className='font-medium'>
                    {t('resources')}
                  </BodyText>
                  <div className='flex items-center gap-4'>
                    <Button
                      size='small'
                      type='button'
                      className='min-w-[70px]'
                      variant={watch('copyMaterials') === 'true' ? 'secondary' : 'outlined'}
                      onClick={() => setValue('copyMaterials', 'true')}
                    >
                      {t('yes')}
                    </Button>
                    <Button
                      size='small'
                      type='button'
                      className='min-w-[70px]'
                      variant={watch('copyMaterials') === 'false' ? 'secondary' : 'outlined'}
                      onClick={() => setValue('copyMaterials', 'false')}
                    >
                      {t('no')}
                    </Button>
                  </div>
                </>
              )}
            </div>
          </>
        )}

        {isUploading && <Uploading progress={progress} />}

        <div className='mt-5 flex items-center justify-end gap-3 border-t border-neutral-200 pt-5'>
          <Button
            type='button'
            size='small'
            variant='outlined'
            onClick={onClose}
            disabled={isUploading}
          >
            {t('cancel')}
          </Button>
          <Button type='submit' size='small' variant='secondary' disabled={isUploading}>
            {ctaText || t('save')}
          </Button>
        </div>
      </form>
      <ImageSearchModal
        isOpen={showImageSearchModal}
        onClose={() => {
          setShowImageSearchModal(false);
        }}
        onImageSelect={(url) => {
          setValue('cover', '');
          setOnSelectImage(url);
        }}
      />
    </ModalContainer>
  );
}

export default CreationModal;
