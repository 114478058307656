import React, { useState } from 'react';
import NoteIcon from 'assets/icons/note.svg';
import BodyText from 'components/typography/BodyText';
import ResourceViewerDrawer from 'components/drawer/ResourceViewerDrawer';
import PinIcon from 'assets/icons/pin.svg';
import MaterialIcon from 'components/common/MaterialIcon';
import { bytesToMegaBytes, isViewableFile } from 'utils/helper';

interface TitleResourceViewerButtonProps {
  title: string;
  directory?: string; // For file types
  uploaderId: string;
  firstName: string;
  lastName: string;
  isPinned?: boolean;
  originId: string;
  originType: string;
  fileSize?: number | null;
}

const ResourceButton: React.FC<TitleResourceViewerButtonProps> = ({
  title,
  directory,
  uploaderId,
  firstName,
  lastName,
  isPinned,
  originId,
  originType,
  fileSize,
}) => {
  const [uploaderName, setUploaderName] = useState('');
  const [uploadedBy, setUploadedBy] = useState('');
  const [showResourceViewer, setShowResourceViewer] = useState(false);
  return (
    <>
      <button
        className='line-clamp-3 flex w-full max-w-[30rem] items-center gap-2 break-all rounded-lg p-1 text-left text-neutral-500 hover:underline'
        onClick={() => {
          if (originType === 'page') {
            setUploadedBy(uploaderId);
            setUploaderName(firstName + ' ' + lastName);
            setShowResourceViewer(true);
          } else if (originType !== 'page' && isViewableFile(directory || '')) {
            setUploaderName(firstName + ' ' + lastName);
            setUploadedBy(uploaderId);
            setShowResourceViewer(true);
          } else {
            window.location.href = directory || '';
          }
        }}
      >
        {originType !== 'page' ? (
          <div className='line-clamp-3 flex  max-w-[30rem] items-center gap-2 break-all rounded-lg p-1 text-left text-neutral-500'>
            <MaterialIcon fileInput={directory + ''} className='h-5 w-5 flex-shrink-0' />
            <div>
              <BodyText variant='sm' className='text-left'>
                {title}
              </BodyText>
              {fileSize && (
                <BodyText variant='xs' className='text-left text-neutral-400'>
                  {bytesToMegaBytes(fileSize)}
                </BodyText>
              )}
            </div>
          </div>
        ) : (
          <div className='line-clamp-3 flex   max-w-[30rem] items-center gap-2 break-all rounded-lg p-1 text-left text-neutral-500'>
            <NoteIcon className='h-5 w-5 flex-shrink-0' />
            <BodyText variant='sm'>{title}</BodyText>
          </div>
        )}
        {isPinned && (
          <div className='flex h-4 w-4 items-center justify-center rounded-full bg-functional-info-light text-functional-info-dark'>
            {' '}
            <PinIcon className='h-3 w-3' />
          </div>
        )}
      </button>
      {(isViewableFile(directory || '') || originType === 'page') && (
        <ResourceViewerDrawer
          isOpen={showResourceViewer}
          setIsOpen={setShowResourceViewer}
          fileUrl={directory || ''}
          uploaderId={uploadedBy}
          uploadedBy={uploaderName}
          originId={originId}
          originType={originType}
          allowSideBar={originType === 'message' ? false : true}
        />
      )}
    </>
  );
};

export default ResourceButton;
