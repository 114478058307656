import { useQuery } from '@tanstack/react-query';
import { getSingleCommunityPost } from 'utils/api/post';

export const useCommunitySinglePost = (communityId: string, postId: string, enabled = true) => {
  return useQuery({
    queryKey: ['community-post', communityId, postId],
    queryFn: () => getSingleCommunityPost(communityId, postId),
    select: (data) => data.data.post,
    enabled,
  });
};
