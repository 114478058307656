import DeleteIcon from 'assets/icons/delete.svg';
import DocsIcon from 'assets/icons/document.svg';
import EmojiIcon from 'assets/icons/emoji.svg';
import PhotoIcon from 'assets/icons/image.svg';
import NoteIcon from 'assets/icons/note-filled.svg';
import Avatar from 'components/memberAndProfile/Avatar';
import Button from 'components/button/Button';
import BodyText from 'components/typography/BodyText';
import LoadingIcon from 'assets/icons/loading.svg';
import { userAvatar } from 'constants/common';
import { convertToRaw, EditorState } from 'draft-js';
import useIsLoggedIn from 'hooks/api/auth/useIsLoggedIn';
import { useFileUploadProgress } from 'hooks/common/useFileUploadProgress';
import { useInvalidateQueries } from 'hooks/common/useInvalidatequeries';
import React, { useEffect, useRef, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { CommunityPostFormInputType } from 'types/components/form';
import { createCommunityPost, createProgramPost } from 'utils/api/post';
import { insertTextInCurrentPosition } from 'utils/helper';
import { notifySuccess, notifyTranslatedError } from 'utils/notify';
import { objToFormData } from 'utils/rest';
import EmojiSelector from './EmojiSelector';
import MentionTextarea from './MentionTextarea';
import SelectPageModal from 'components/modal/SelectPageModal';

interface IPublishPost {
  originId: string;
  variant: 'community' | 'program';
}

function PublishPost({ originId, variant }: IPublishPost) {
  const { t } = useTranslation();
  const fileInput = useRef<HTMLInputElement>(null);
  const imageInput = useRef<HTMLInputElement>(null);
  const emojiContainerRef = useRef<HTMLDivElement>(null);
  const [showPageModal, setShowPageModal] = useState(false);
  const [showEmoji, setShowEmoji] = useState(false);
  const [embedType, setEmbedType] = useState<null | string>(null);
  const [editorState, setEditorState] = useState(() => EditorState.createEmpty());
  const [selectedPage, setSelectedPage] = useState<{ id: string; title: string } | undefined>();
  const { data: user } = useIsLoggedIn();
  const { invalidateAllPosts, invalidateCommunityPosts, invalidateProgramPosts } =
    useInvalidateQueries();
  const { isUploading, setIsUploading, progress, onUploadProgress } = useFileUploadProgress();

  const {
    watch,
    reset,
    setValue,
    setError,
    clearErrors,
    handleSubmit,
    formState: { errors },
  } = useForm<CommunityPostFormInputType>({ defaultValues: { content: '' } });

  const onSubmit: SubmitHandler<CommunityPostFormInputType> = (data) => {
    const currentContent = editorState.getCurrentContent();
    const rawContent = convertToRaw(currentContent);
    const flatText = rawContent.blocks.map((block) => block.text).join('');

    if (flatText.length === 0 && !data.file) {
      setError('content', { type: 'minLength' });
      return;
    }

    const { file, ...rest } = data;

    const formData = objToFormData({ ...rest });

    if (embedType) {
      formData.append('embedType', embedType);
      if (embedType === 'page' && selectedPage) {
        formData.append('embedMeta', selectedPage.title);
        formData.append('embedId', selectedPage.id);
      }
    }
    if (file) {
      formData.append('file', file[0]);
    }

    setIsUploading(true);

    if (variant === 'community') {
      createCommunityPost(originId, formData, { onUploadProgress })
        .then(() => {
          setIsUploading(false);
          setEmbedType(null);
          reset({ content: '' });
          invalidateAllPosts();
          invalidateCommunityPosts(originId);
          setEditorState(EditorState.createEmpty());
          notifySuccess(t('success.api.post_created'));
        })
        .catch((error) => {
          setIsUploading(false);
          notifyTranslatedError(t, error.response.data?.errorCode);
        });
    }

    if (variant === 'program') {
      createProgramPost(originId, formData, { onUploadProgress })
        .then(() => {
          setIsUploading(false);
          setEmbedType(null);
          reset({ content: '' });
          invalidateAllPosts();
          invalidateProgramPosts(originId);
          setEditorState(EditorState.createEmpty());
          notifySuccess(t('success.api.post_created'));
        })
        .catch((error) => {
          setIsUploading(false);
          notifyTranslatedError(t, error.response.data?.errorCode);
        });
    }
  };

  const handleActionClick = (type: 'file' | 'image') => {
    if (type === 'file') {
      fileInput.current?.click();
    }
    if (type === 'image') {
      imageInput.current?.click();
    }
  };

  const handlePageClick = () => {
    reset({ file: undefined });
    setEmbedType('page');
    setShowPageModal(false);
  };

  const handleSetEmbed = (e: React.ChangeEvent<HTMLInputElement>, type: 'file' | 'image') => {
    if (!e.target.files?.length) return;

    setEmbedType(type);
    setValue('file', e.target.files);
    clearErrors('content');
  };

  const handleRemoveEmbed = () => {
    reset({ file: undefined });
    setEmbedType(null);
  };

  useEffect(() => {
    clearErrors('content');
    const currentContent = editorState.getCurrentContent();
    const rawContent = convertToRaw(currentContent);
    const content = JSON.stringify(rawContent);
    setValue('content', content);
  }, [editorState]);

  return (
    <div className='flex items-start gap-6 overflow-hidden rounded-2xl bg-neutral-200 p-4 shadow shadow-neutral-300'>
      <Avatar
        size={64}
        src={user?.profilePicture || userAvatar}
        className='hidden shrink-0 md:block'
      />
      <form onSubmit={handleSubmit(onSubmit)} className='grow overflow-hidden'>
        <MentionTextarea
          originId={originId}
          originType={variant}
          editorState={editorState}
          setEditorState={setEditorState}
        />
        {errors.content && (
          <BodyText variant='xs' className='text-functional-danger-400'>
            {t('required_error_message')}
          </BodyText>
        )}

        {watch('file') && (
          <div className='mt-4 flex items-center gap-1 rounded-md border border-functional-info-dark bg-functional-info-light px-4 py-2'>
            {embedType === 'file' ? (
              <DocsIcon className='h-5 w-5 shrink-0 text-black' />
            ) : (
              <PhotoIcon className='h-5 w-5 shrink-0 text-black' />
            )}
            <div className='overflow-hidden'>
              <BodyText variant='sm' className='line-clamp-1 break-words font-semibold text-black'>
                {watch('file')[0].name}
              </BodyText>
              <BodyText variant='xs'>
                {(watch('file')[0].size / 1024 / 1024).toFixed(2)} MB
              </BodyText>
            </div>

            <button
              className='ml-auto flex h-7 w-7 shrink-0 items-center justify-center rounded-full text-functional-danger-dark hover:bg-neutral-300 active:bg-neutral-400'
              onClick={handleRemoveEmbed}
            >
              <DeleteIcon className='h-5 w-5' />
            </button>
          </div>
        )}

        {embedType === 'page' && selectedPage?.id && (
          <div className='mt-4 flex items-center gap-1 rounded-md border border-functional-info-dark bg-functional-info-light px-4 py-2'>
            <NoteIcon className='h-5 w-5 shrink-0 text-black' />
            <div className='overflow-hidden'>
              <BodyText variant='sm' className='line-clamp-1 break-words font-semibold text-black'>
                {selectedPage?.title}
              </BodyText>
            </div>

            <button
              className='ml-auto flex h-7 w-7 shrink-0 items-center justify-center rounded-full text-functional-danger-dark hover:bg-neutral-300 active:bg-neutral-400'
              onClick={handleRemoveEmbed}
            >
              <DeleteIcon className='h-5 w-5' />
            </button>
          </div>
        )}

        <div className='mt-4 flex items-center justify-between'>
          <div className='flex items-center'>
            <button
              type='button'
              className='flex items-center gap-1 rounded-md px-2 py-1 hover:bg-neutral-200'
              onClick={() => setShowPageModal(true)}
            >
              <NoteIcon className='h-5 w-5 text-neutral-500' />{' '}
              <BodyText variant='sm' className='hidden text-neutral-400 md:block'>
                {t('page')}
              </BodyText>
            </button>
            <button
              type='button'
              className='flex items-center gap-1 rounded-md px-2 py-1 hover:bg-neutral-200'
              onClick={() => handleActionClick('file')}
            >
              <DocsIcon className='h-5 w-5 text-neutral-500' />{' '}
              <BodyText variant='sm' className='hidden text-neutral-400 md:block'>
                {t('file')}
              </BodyText>
            </button>
            <button
              type='button'
              className='flex items-center gap-1 rounded-md px-2 py-1 hover:bg-neutral-200'
              onClick={() => handleActionClick('image')}
            >
              <PhotoIcon className='h-5 w-5 text-neutral-500' />{' '}
              <BodyText variant='sm' className='hidden text-neutral-400 md:block'>
                {t('photo')}
              </BodyText>
            </button>
            <span ref={emojiContainerRef}>
              <EmojiSelector
                open={showEmoji}
                close={() => {
                  setShowEmoji(false);
                }}
                setOpen={setShowEmoji}
                element={
                  <>
                    <EmojiIcon className='h-5 w-5 text-neutral-500' />{' '}
                    <BodyText variant='sm' className='hidden text-neutral-400 md:block'>
                      {t('emoji')}
                    </BodyText>
                  </>
                }
                elementClassName='flex items-center gap-1 rounded-md px-2 py-1 hover:bg-neutral-200'
                onEmojiSelect={(emoji) =>
                  setEditorState(insertTextInCurrentPosition(editorState, emoji))
                }
              />
            </span>
            <input
              ref={fileInput}
              type='file'
              className='hidden'
              onChange={(e) => handleSetEmbed(e, 'file')}
            />
            <input
              ref={imageInput}
              type='file'
              className='hidden'
              accept='image/png, image/jpeg'
              onChange={(e) => handleSetEmbed(e, 'image')}
            />
          </div>
          <Button
            size='small'
            variant='secondary'
            className='flex items-center flex-shrink-0'
            disabled={isUploading}
          >
            {!isUploading ? <span>{t('publish-post')}</span> : <LoadingIcon className='w-5 h-5' />}
          </Button>
        </div>
      </form>
      <SelectPageModal
        isOpen={showPageModal}
        onClose={() => setShowPageModal(false)}
        onConfirm={handlePageClick}
        setSelectedPage={setSelectedPage}
        selectedPage={selectedPage}
      />
    </div>
  );
}

export default PublishPost;
