import AddIcon from 'assets/icons/add.svg';
import DeleteIcon from 'assets/icons/delete.svg';
import CopyIcon from 'assets/icons/copy.svg';
import Tag from 'components/tag/Tag';
import EditIcon from 'assets/icons/edit.svg';
import LoadingIcon from 'assets/icons/loading.svg';
import Button from 'components/button/Button';
import Loading from 'components/Loading';
import SubmissionsIcon from 'assets/icons/documents.svg';
import AddTaskModal from 'components/modal/AddTaskModal';
import ConfirmationModal from 'components/modal/ConfirmationModal';
import UpdateTaskModal from 'components/modal/UpdateTaskModal';
import DuplicateTaskModal from 'components/modal/DuplicateTaskModal';
import NavigateTo404 from 'components/NavigateTo404';
import Task from 'components/task/Task';
import BodyText from 'components/typography/BodyText';
import { orgLogo } from 'constants/common';
import ViewToggle from 'components/common/ItemListViewToggle';
import { Link } from 'react-router-dom';
import { useCommunity } from 'hooks/api/community/useCommunity';
import { useCommunityTask } from 'hooks/api/task/useCommunityTask';
import { useCommunityTasks } from 'hooks/api/task/useCommunityTasks';
import { useInvalidateQueries } from 'hooks/common/useInvalidatequeries';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useInView } from 'react-intersection-observer';
import { useParams } from 'react-router-dom';
import { createCommunityTask, deleteCommunityTask, updateCommunityTask } from 'utils/api/task';
import { notifySuccess, notifyTranslatedError } from 'utils/notify';
import { generateTaskLink, generateCommunityLink } from 'utils/url';
import MemberButton from 'components/memberAndProfile/MemberButton';
import dayjs from 'dayjs';
import AssigneeInfoComponent from 'components/task/AssigneeInfoComponent';
import { debounce } from 'lodash';
import SearchInput from 'components/form/SearchInput';

const CommunityTasks = () => {
  const { t } = useTranslation();
  const { communityId } = useParams();

  // list view toggle
  const [listView, setListView] = useState(false); // Initialize state
  const handleToggleView = (isListView: boolean) => {
    setListView(isListView); // Update state based on the value from ViewToggle
  };

  const [openTaskModal, setOpenTaskModal] = useState(false);
  const [openUpdateTaskModal, setOpenUpdateTaskModal] = useState(false);
  const [openDuplicateTaskModal, setOpenDuplicateTaskModal] = useState(false);
  const [selectedTaskId, setSelectedTaskId] = useState<string | undefined>();
  const [openDeleteTaskConfirmation, setOpenDeleteTaskConfirmation] = useState(false);

  const { ref: observerRef, inView } = useInView({ threshold: 0.25 });
  const { data: community, isLoading: isCommunityLoading } = useCommunity(communityId || '');
  const [pageSize, setPageSize] = useState(200);
  const { tasks, fetchNextTasks, hasNextTasks, isTasksLoading, isFetchingNextTasks } =
    useCommunityTasks(communityId || '', pageSize, false);
  const { data: singleTask, refetch: refetchSingleTask } = useCommunityTask(
    communityId || '',
    selectedTaskId || '',
  );
  const { invalidateCommunityTasks, invalidateCommunityUpcomingTasks } = useInvalidateQueries();

  if (isCommunityLoading) return <Loading />;
  if (!community || !tasks) return <NavigateTo404 />;

  // Search related code:
  const [searchString, setSearchString] = useState('');
  const filteredTasks = useMemo(() => {
    return (
      tasks?.filter((task) => {
        const searchLower = searchString.toLowerCase();
        // Safely check if the title exists and convert it to lowercase
        const titleMatch = task.title ? task.title.toLowerCase().includes(searchLower) : false;
        return titleMatch;
      }) || []
    );
  }, [tasks, searchString]);

  const handleSearch = (value: string) => {
    setSearchString(value.toLowerCase());
  };
  const debounceSearch = useCallback(debounce(handleSearch, 300), []);
  // search related code ends here

  const tasksWithoutDeadline = filteredTasks.filter((task) => !task.hasDeadline);
  const tasksWithDeadline = filteredTasks.filter((task) => task.hasDeadline);

  const activeTasks = tasksWithDeadline.filter((task) => new Date() < new Date(task.deadline));
  const inActiveTasks = tasksWithDeadline
    .filter((task) => new Date() > new Date(task.deadline))
    .sort((a, b) => new Date(b.deadline).getTime() - new Date(a.deadline).getTime());

  useEffect(() => {
    if (inView) {
      if (!isTasksLoading && !isFetchingNextTasks && hasNextTasks) {
        fetchNextTasks();
      }
    }
  }, [inView]);
  // other code ends here
  // add task
  const handleAddTask = (taskData: {
    title: string;
    about: string;
    deadline: string;
    assignee: string | string[] | undefined;
  }) => {
    createCommunityTask(community.id, taskData)
      .then(() => {
        setOpenTaskModal(false);
        invalidateCommunityTasks(community.id);
        invalidateCommunityUpcomingTasks(community.id);
        notifySuccess(t('success.api.task_created'));
        setOpenUpdateTaskModal(false);
        setOpenDuplicateTaskModal(false);
      })
      .catch((error) => {
        notifyTranslatedError(t, error.response.data?.errorCode);
      });
  };

  // update task
  const handleOpenUpdateTaskModal = (taskId: string) => {
    setSelectedTaskId(taskId);
    setOpenUpdateTaskModal(true);
  };

  const handleOpenDuplicateTaskModal = (taskId: string) => {
    setSelectedTaskId(taskId);
    setOpenDuplicateTaskModal(true);
  };

  const handleCloseUpdateTaskModal = () => {
    setSelectedTaskId(undefined);
    setOpenUpdateTaskModal(false);
  };

  const handleUpdateTask = (taskData: {
    deadline: string;
    title: string;
    about: string;
    contactUserId: string | null | undefined;
    assignee: string | string[] | undefined;
  }) => {
    if (!selectedTaskId) return;
    updateCommunityTask(community.id, selectedTaskId, taskData)
      .then(() => {
        handleCloseUpdateTaskModal();
        refetchSingleTask();
        invalidateCommunityTasks(community.id);
        invalidateCommunityUpcomingTasks(community.id);
        notifySuccess(t('success.api.task_updated'));
        setOpenUpdateTaskModal(false);
      })
      .catch((error) => {
        notifyTranslatedError(t, error.response.data?.errorCode);
      });
  };

  // delete task
  const handleOpenDeleteTaskConfirmation = (taskId: string) => {
    setSelectedTaskId(taskId);
    setOpenDeleteTaskConfirmation(true);
  };

  const handleCloseDeleteTaskConfirmation = () => {
    setSelectedTaskId(undefined);
    setOpenDeleteTaskConfirmation(false);
  };

  const handleDeleteCommunityTask = () => {
    if (!selectedTaskId) return;
    deleteCommunityTask(community.id, selectedTaskId)
      .then(() => {
        invalidateCommunityTasks(community.id);
        notifySuccess(t('success.api.task_delete'));
      })
      .catch((error) => {
        notifyTranslatedError(t, error.response.data?.errorCode);
      });

    setSelectedTaskId(undefined);
    setOpenDeleteTaskConfirmation(false);
  };

  return (
    <>
      <div className='space-y-4'>
        <div className='flex items-center justify-between px-1 gap-2'>
          <div className='flex items-center gap-2'>
            <SearchInput onSearch={debounceSearch} />
            <BodyText
              variant='base'
              className='text-neutral-500 hidden md:flex flex items-center gap-1'
            >
              <span className='text-secondary-500 font-bold'>{tasks.length}</span> {t('tasks')}
            </BodyText>
          </div>
          <div className='flex items-center gap-3'>
            <ViewToggle onToggleView={handleToggleView} />
            <Button
              size='small'
              variant='secondary-outlined'
              tooltip={t('view_submissions')}
              onClick={() => {
                location.href = generateCommunityLink(community.id) + '/tasks/submissions';
              }}
            >
              <SubmissionsIcon className='h-5 w-5' />
            </Button>
            {(community.canManage || community.isGroup) && (
              <Button
                size='small'
                variant='secondary-outlined'
                tooltip={t('add_task')}
                onClick={() => setOpenTaskModal(true)}
              >
                <AddIcon className='h-5 w-5' />
              </Button>
            )}
          </div>
        </div>

        {!listView ? (
          <div className='space-y-4'>
            <div className='grid grid-cols-1 gap-[20px] md:grid-cols-2 xl:grid-cols-4'>
              {tasksWithoutDeadline.map((task) => {
                const {
                  id: taskId,
                  title,
                  submitted,
                  organization,
                  hasDeadline,
                  deadline,
                  creator,
                  taskContactUser,
                  softDeadline,
                } = task;
                return (
                  <Task
                    key={taskId}
                    taskId={taskId}
                    taskTitle={title}
                    taskDeadline={deadline}
                    taskSoftDeadline={softDeadline}
                    hasDeadline={hasDeadline}
                    canManage={community.canManage}
                    createdBy={creator.id}
                    isTaskSubmitted={submitted || false}
                    displayName={creator.firstName + ' ' + creator.lastName}
                    handleUpdateTask={handleOpenUpdateTaskModal}
                    handleDuplicateTask={handleOpenDuplicateTaskModal}
                    displayAvator={creator.profilePicture || orgLogo}
                    handleDeleteTask={handleOpenDeleteTaskConfirmation}
                    redirectTo={generateTaskLink('community', community.id, taskId)}
                  />
                );
              })}
            </div>
            <div className='grid grid-cols-1 gap-[20px] md:grid-cols-2 xl:grid-cols-4'>
              {[...activeTasks, ...inActiveTasks].map((task) => {
                const {
                  id: taskId,
                  title,
                  submitted,
                  organization,
                  hasDeadline,
                  deadline,
                  creator,
                  taskContactUser,
                  softDeadline,
                } = task;
                return (
                  <Task
                    key={taskId}
                    taskId={taskId}
                    taskTitle={title}
                    taskDeadline={deadline}
                    taskSoftDeadline={softDeadline}
                    hasDeadline={hasDeadline}
                    canManage={community.canManage}
                    createdBy={creator.id}
                    isTaskSubmitted={submitted || false}
                    displayName={creator.firstName + ' ' + creator.lastName}
                    handleUpdateTask={handleOpenUpdateTaskModal}
                    handleDuplicateTask={handleOpenDuplicateTaskModal}
                    displayAvator={creator.profilePicture || organization.logo || orgLogo}
                    handleDeleteTask={handleOpenDeleteTaskConfirmation}
                    redirectTo={generateTaskLink('community', community.id, taskId)}
                  />
                );
              })}
            </div>
          </div>
        ) : (
          <div className='table-container'>
            <table>
              <thead>
                <tr>
                  <th>{t('title')}</th>
                  <th>{t('deadline')}</th>
                  <th>{t('assigned_to')}</th>
                  <th>{t('status')}</th>
                  <th>{t('created-by')}</th>
                  <th>{t('actions')}</th>
                </tr>
              </thead>
              <tbody>
                {tasksWithoutDeadline.map((task) => {
                  const {
                    id: taskId,
                    title,
                    submitted,
                    hasDeadline,
                    deadline,
                    creator,
                    assigneeInfo,
                  } = task;
                  return (
                    <tr key={taskId} className='group'>
                      <td>
                        <div className='flex items-center gap-2'>
                          <Link to={generateTaskLink('community', community.id, taskId)}>
                            <button className='flex w-full max-w-[20rem] items-center text-neutral-500 hover:underline'>
                              <span className='ml-2 line-clamp-1 text-left'> {title}</span>
                            </button>
                          </Link>
                        </div>
                      </td>
                      <td>
                        {!hasDeadline ? (
                          <span>-</span>
                        ) : (
                          <div>
                            <BodyText variant='sm'>
                              <span className='text-functional-danger-dark'>
                                {dayjs(deadline).format('dddd, D MMM YYYY, h:mm A')}
                              </span>
                            </BodyText>
                          </div>
                        )}
                      </td>
                      <td>
                        <AssigneeInfoComponent assigneeInfo={task.assigneeInfo} />
                      </td>
                      <td>
                        {!hasDeadline ? (
                          <Tag
                            type='default'
                            className='bg-functional-success-light text-functional-success-dark'
                          >
                            {t('open_ended_task')}
                          </Tag>
                        ) : dayjs().isAfter(dayjs(deadline)) ? (
                          <Tag
                            type='default'
                            className='bg-functional-danger-light text-functional-danger-dark'
                          >
                            {t('closed')}
                          </Tag>
                        ) : (
                          <Tag
                            type='default'
                            className='bg-functional-success-light text-functional-success-dark'
                          >
                            {t('open')}
                          </Tag>
                        )}
                      </td>
                      <td>
                        <MemberButton
                          userId={creator.id}
                          isCreator={false}
                          profilePicture={creator.profilePicture || ''}
                          userName={creator.firstName + ' ' + creator.lastName}
                          chat={false}
                          size='small'
                        />
                      </td>
                      <td>
                        {community.canManage && (
                          <div className='flex items-center gap-3 hover:text-functional-info-dark active:text-functional-info-dark lg:opacity-0 lg:group-hover:opacity-100'>
                            <button
                              className='flex h-6 w-6 items-center justify-center rounded-full hover:bg-neutral-300 active:bg-neutral-400'
                              onClick={() => handleOpenUpdateTaskModal(taskId)}
                            >
                              <EditIcon className='h-5 w-5 text-neutral-400 hover:text-functional-info-dark active:text-functional-info-dark' />
                            </button>
                            <button
                              className='flex h-6 w-6 items-center justify-center rounded-full hover:bg-neutral-300 active:bg-neutral-400'
                              onClick={() => handleOpenDuplicateTaskModal(taskId)}
                            >
                              <CopyIcon className='h-5 w-5 text-neutral-400 hover:text-functional-info-dark active:text-functional-info-dark' />
                            </button>
                            <button
                              className='flex h-6 w-6 items-center justify-center rounded-full hover:bg-neutral-300 active:bg-neutral-400'
                              onClick={() => handleOpenDeleteTaskConfirmation(taskId)}
                            >
                              <DeleteIcon className='h-5 w-5 text-neutral-400 hover:text-functional-danger-dark active:text-functional-danger-dark' />
                            </button>
                          </div>
                        )}
                      </td>
                    </tr>
                  );
                })}
                {[...activeTasks, ...inActiveTasks].map((task) => {
                  const { id: taskId, title, submitted, hasDeadline, deadline, creator } = task;
                  return (
                    <tr key={taskId} className='group'>
                      <td>
                        <div className='flex items-center gap-2'>
                          <Link to={generateTaskLink('community', community.id, taskId)}>
                            <button className='flex w-full max-w-[20rem] items-center text-neutral-500 hover:underline'>
                              <span className='ml-2 line-clamp-1 text-left'> {title}</span>
                            </button>
                          </Link>
                        </div>
                      </td>
                      <td>
                        {!hasDeadline ? (
                          <span>-</span>
                        ) : (
                          <div>
                            <span
                              className={
                                dayjs().isAfter(dayjs(deadline))
                                  ? 'text-neutral-300'
                                  : dayjs().isBefore(dayjs(deadline)) &&
                                      dayjs().isAfter(dayjs(deadline).subtract(36, 'hour'))
                                    ? 'text-functional-danger-dark'
                                    : 'text-neutral-500'
                              }
                            >
                              {dayjs(deadline).format('dddd, D MMM YYYY, h:mm A')}
                            </span>
                          </div>
                        )}
                      </td>
                      <td>
                        <AssigneeInfoComponent assigneeInfo={task.assigneeInfo} />
                      </td>
                      <td>
                        {!hasDeadline ? (
                          <Tag
                            type='default'
                            className='bg-functional-success-light text-functional-success-dark'
                          >
                            {t('open_ended_task')}
                          </Tag>
                        ) : dayjs().isAfter(dayjs(deadline)) ? (
                          <Tag
                            type='default'
                            className='bg-functional-danger-light text-functional-danger-dark'
                          >
                            {t('closed')}
                          </Tag>
                        ) : (
                          <Tag
                            type='default'
                            className='bg-functional-success-light text-functional-success-dark'
                          >
                            {t('open')}
                          </Tag>
                        )}
                      </td>
                      <td>
                        <MemberButton
                          userId={creator.id}
                          isCreator={false}
                          profilePicture={creator.profilePicture || ''}
                          userName={creator.firstName + ' ' + creator.lastName}
                          chat={false}
                          size='small'
                        />
                      </td>
                      <td>
                        {community.canManage && (
                          <div className='flex items-center gap-3 lg:opacity-0 lg:group-hover:opacity-100'>
                            <button
                              className='flex h-6 w-6 items-center justify-center rounded-full hover:bg-neutral-300 active:bg-neutral-400'
                              onClick={() => handleOpenUpdateTaskModal(taskId)}
                            >
                              <EditIcon className='h-5 w-5 text-neutral-400 hover:text-functional-info-dark active:text-functional-info-dark' />
                            </button>
                            <button
                              className='flex h-6 w-6 items-center justify-center rounded-full hover:bg-neutral-300 active:bg-neutral-400'
                              onClick={() => handleOpenDuplicateTaskModal(taskId)}
                            >
                              <CopyIcon className='h-5 w-5 text-neutral-400 hover:text-functional-info-dark active:text-functional-info-dark' />
                            </button>
                            <button
                              className='flex h-6 w-6 items-center justify-center rounded-full hover:bg-neutral-300 active:bg-neutral-400'
                              onClick={() => handleOpenDeleteTaskConfirmation(taskId)}
                            >
                              <DeleteIcon className='h-5 w-5 text-neutral-400 hover:text-functional-danger-dark active:text-functional-danger-dark' />
                            </button>
                          </div>
                        )}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        )}
      </div>

      {hasNextTasks && (
        <div className='mb-11 mt-4 flex justify-center'>
          <Button variant='primary' onClick={() => fetchNextTasks()}>
            {isFetchingNextTasks ? <LoadingIcon className='h-5 w-5' /> : t('show_more')}
          </Button>
        </div>
      )}
      {openTaskModal && (
        <AddTaskModal
          originType='community'
          originId={communityId || ''}
          isOpen={openTaskModal}
          handleAddTask={handleAddTask}
          onClose={() => setOpenTaskModal(false)}
        />
      )}

      {openUpdateTaskModal && singleTask && (
        <UpdateTaskModal
          isOpen={openUpdateTaskModal}
          handleUpdateTask={handleUpdateTask}
          onClose={handleCloseUpdateTaskModal}
          originType='community'
          originId={communityId || ''}
          taskTitle={singleTask.task.title}
          taskDescription={singleTask.task.about}
          taskHasDeadline={singleTask.task.hasDeadline}
          taskDeadline={singleTask.task.deadline}
          taskAssignee={singleTask.assigneeInfo}
          taskContactUserAvatar={singleTask.task.taskContactUser?.profilePicture || ''}
          taskContactUserId={singleTask.task.taskContactUser?.id || ''}
          taskContactUserFirstName={singleTask.task.taskContactUser?.firstName || ''}
          taskContactUserLastName={singleTask.task.taskContactUser?.lastName || ''}
          taskSoftDeadline={singleTask.task.softDeadline}
          taskMemberVisibility={singleTask.task.memberVisibility}
        />
      )}

      {openDuplicateTaskModal && singleTask && (
        <DuplicateTaskModal
          isOpen={openDuplicateTaskModal}
          handleAddTask={handleAddTask}
          onClose={handleCloseUpdateTaskModal}
          originType='community'
          originId={communityId || ''}
          taskTitle={singleTask.task.title}
          taskDescription={singleTask.task.about}
          taskHasDeadline={singleTask.task.hasDeadline}
          taskDeadline={singleTask.task.deadline}
          taskSoftDeadline={singleTask.task.softDeadline}
        />
      )}

      {openDeleteTaskConfirmation && (
        <ConfirmationModal
          isOpen={openDeleteTaskConfirmation}
          label={t('delete_task')}
          title={t('delete-confirmation-modal-title')}
          description={
            t('delete_task_confirmation_description') ||
            'This task will be permanently removed and unrecoverable.'
          }
          onClose={handleCloseDeleteTaskConfirmation}
          onConfirm={handleDeleteCommunityTask}
        />
      )}
    </>
  );
};

export default CommunityTasks;
