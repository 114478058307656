import { useQuery } from '@tanstack/react-query';
import { isLoggedIn } from 'utils/api/auth';

const useIsLoggedIn = () =>
  useQuery({
    queryKey: ['is-logged-in'],
    queryFn: () => isLoggedIn(),
    refetchOnWindowFocus: false,
    select: (data) => data.data?.user,
    staleTime: Infinity
  });

export default useIsLoggedIn;
