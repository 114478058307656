import ArchiveIcon from 'assets/icons/archive.svg';
import DuplicateIcon from 'assets/icons/copy.svg';
import PrivateIcon from 'assets/icons/eye-off.svg';
import PublicIcon from 'assets/icons/eye.svg';
import EditIcon from 'assets/icons/edit.svg';
import OpenIcon from 'assets/icons/open.svg';
import { commonCover } from 'constants/common';
import dayjs from 'dayjs';
import { ContextMenu } from 'components/common/ContextMenu';
import ConfirmationModal from 'components/modal/ConfirmationModal';
import DuplicateCommunityModal from 'components/modal/DuplicateCommunityModal';
import EditCommunityModal from 'components/modal/EditCommunityModal';
import { useOrgCommunities } from 'hooks/api/community/useOrgCommunities';
import BodyText from 'components/typography/BodyText';
import { useInvalidateQueries } from 'hooks/common/useInvalidatequeries';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { archiveCommunity } from 'utils/api/community';
import { generateOriginLink } from 'utils/url';
import { notifySuccess, notifyTranslatedError } from 'utils/notify';

const OrgCommunities = () => {
  const { t } = useTranslation();

  const [communityId, setCommunityId] = useState('');
  const [openEditModal, setOpenEditModal] = useState(false);
  const [openDuplicateModal, setOpenDuplicateModal] = useState(false);
  const [toArchiveCommunityId, setToArchiveCommunityId] = useState('');
  const [openArchiveConfirmationModal, setOpenArchiveConfirmationModal] = useState(false);

  const { data } = useOrgCommunities();
  const {
    invalidateSingleCommunity,
    invalidateMyCommunities,
    invalidateOrgCommunities,
    invalidateArchivedCommunities,
  } = useInvalidateQueries();

  const handleArchiveCommunity = (communityId: string) => {
    archiveCommunity(communityId)
      .then(() => {
        invalidateSingleCommunity(communityId);
        invalidateMyCommunities();
        invalidateOrgCommunities();
        invalidateArchivedCommunities();
        notifySuccess(t('success.api.community_archived'));
      })
      .catch((error) => {
        notifyTranslatedError(t, error.response.data?.errorCode);
      });
    setOpenArchiveConfirmationModal(false);
  };

  const handleConfirmationModal = (communityId: string) => {
    setToArchiveCommunityId(communityId);
    setOpenArchiveConfirmationModal(true);
  };

  const closeConfirmationModal = () => {
    setToArchiveCommunityId('');
    setOpenArchiveConfirmationModal(false);
  };

  return (
    <>
      <div className='flex items-center justify-between mb-4'></div>
      <div className='mb-10 flex space-y-2'>
        <div className='flex overflow-x-auto rounded-2xl bg-neutral-200 px-4 py-5 pb-[120px] shadow shadow-neutral-300'>
          <table className='min-w-[600px] table-auto'>
            <thead className='bg-neutral-100'>
              <tr className='border-b-2 border-neutral-200'>
                <th className='px-4 py-2 text-left'>
                  <BodyText variant='sm' className='font-medium'>
                    {t('about')}
                  </BodyText>
                </th>
                <th className='px-4 py-2  text-left'>
                  <BodyText variant='sm' className='font-medium'>
                    {t('visibility')}
                  </BodyText>
                </th>
                <th className='px-4 py-2  text-left'>
                  <BodyText variant='sm' className=' font-medium'>
                    {t('members')}
                  </BodyText>
                </th>
                <th className='px-4 py-2  text-left'>
                  <BodyText variant='sm' className=' font-medium'>
                    {t('created_at')}
                  </BodyText>
                </th>
                <th className='px-4 py-2  text-left'>
                  <BodyText variant='sm' className=' font-medium'>
                    {t('actions')}
                  </BodyText>
                </th>
              </tr>
            </thead>

            <tbody>
              {data?.map(
                ({ id, name, visibility, cover, createdAt, memberCount, organization, logo }) => (
                  <tr key={id} className='border-b-2 border-neutral-200'>
                    <td className='p-2'>
                      <a href={generateOriginLink(id, 'community')}>
                        <button className='neutral-button flex w-full max-w-[40rem] items-center rounded-xl p-2 text-neutral-500'>
                          <img
                            src={cover || logo || organization.logo || commonCover}
                            alt={`Cover for ${name}`}
                            className='h-8 w-8 rounded-full object-cover'
                          />{' '}
                          <span className='ml-2 line-clamp-3 text-left'>{name}</span>
                        </button>
                      </a>
                    </td>
                    <td className='p-2'>
                      {' '}
                      {visibility && (
                        <div className='flex items-center gap-2 rounded bg-neutral-200 px-2 py-1'>
                          {visibility === 'open' ? (
                            <OpenIcon className='h-4 w-4 text-neutral-500' />
                          ) : visibility === 'private' ? (
                            <PrivateIcon className='h-4 w-4 text-neutral-500' />
                          ) : (
                            <PublicIcon className='h-4 w-4 text-neutral-500' />
                          )}
                          <BodyText
                            variant='sm'
                            className='font-medium capitalize text-neutral-500'
                          >
                            {t(visibility)}
                          </BodyText>
                        </div>
                      )}
                    </td>
                    <td className='p-2 px-4 text-center'>{memberCount}</td>
                    <td className='p-2 px-4 text-center'>
                      {' '}
                      <span className='text-sm text-neutral-500'>
                        {dayjs(createdAt).format(' D MMM YYYY')}
                      </span>
                    </td>
                    <td className='p-2 px-4 text-center'>
                      <ContextMenu
                        menuItems={[
                          {
                            key: 'edit',
                            label: t('edit'),
                            icon: EditIcon,
                            callback: () => {
                              setCommunityId(id);
                              setOpenEditModal(true);
                            },
                          },
                          {
                            key: 'duplicate',
                            label: t('duplicate'),
                            icon: DuplicateIcon,
                            callback: () => {
                              setCommunityId(id);
                              setOpenDuplicateModal(true);
                            },
                          },
                          {
                            key: 'archive',
                            label: t('archive'),
                            icon: ArchiveIcon,
                            callback: () => handleConfirmationModal(id),
                          },
                        ]}
                      />
                    </td>
                  </tr>
                ),
              )}
            </tbody>
          </table>
        </div>

        <EditCommunityModal
          open={openEditModal}
          communityId={communityId}
          setOpen={setOpenEditModal}
        />

        <DuplicateCommunityModal
          communityId={communityId}
          open={openDuplicateModal}
          setOpen={setOpenDuplicateModal}
        />

        {openArchiveConfirmationModal && (
          <ConfirmationModal
            isOpen={openArchiveConfirmationModal}
            label={t('archive_community')}
            title={t('confirmation-general')}
            description={
              t('archive_community_confirmation_description') ||
              'This community will invisible for all members.'
            }
            onClose={closeConfirmationModal}
            onConfirm={() => handleArchiveCommunity(toArchiveCommunityId)}
          />
        )}
      </div>
    </>
  );
};

export default OrgCommunities;
