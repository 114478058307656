import { Menu } from '@headlessui/react';
import { useQueryClient } from '@tanstack/react-query';
import ChevronDownIcon from 'assets/icons/chevron-down.svg';
import DarkIcon from 'assets/icons/darkmode.svg';
import LightIcon from 'assets/icons/lightmode.svg';
import LanguageIcon from 'assets/icons/language.svg';
import LogoutIcon from 'assets/icons/logout.svg';
import SettingIcon from 'assets/icons/setting.svg';
import ProfileIcon from 'assets/icons/user-setting.svg';
import Avatar from 'components/memberAndProfile/Avatar';
import BodyText from 'components/typography/BodyText';
import { userAvatar } from 'constants/common';
import useIsLoggedIn from 'hooks/api/auth/useIsLoggedIn';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import { logout } from 'utils/api/auth';
import { notifyTranslatedError } from 'utils/notify';
import { updateSetting } from 'utils/api/settings';
import MessageIcon from 'assets/icons/message.svg';
import LanguageSelector from 'components/layout/LanguageSelector';
import { useSettings } from 'hooks/api/settings/useSettings';

const ProfileDropdown = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { data } = useIsLoggedIn();
  const queryClient = useQueryClient();
  const { data: settingsInfo, refetch, isLoading } = useSettings();
  const [darkTheme, setDarkTheme] = useState(false);

  const handleLogout = async () => {
    try {
      await logout();
      queryClient.removeQueries();
      navigate('/login');
    } catch (error: $TSFix) {
      notifyTranslatedError(t, error.response.data?.errorCode);
    }
  };

  const handleDarkThemeChange = (value: boolean) => {
    setDarkTheme(value);
    updateSetting({
      darkTheme: value,
      emailNotification: settingsInfo?.settings?.emailNotification || false,
    })
      .then(() => {
        refetch();
      })
      .catch((error) => {
        notifyTranslatedError(t, error.response.data?.errorCode);
      });
  };

  return (
    <>
      {' '}
      <Menu as='div' className='relative'>
        <Menu.Button className='neutral-button flex items-center gap-1 rounded-md'>
          <Avatar src={data?.profilePicture || userAvatar} size={28} />

          <div className='hidden flex-col items-start xl:flex'>
            <BodyText variant='sm' className='font-medium capitalize'>
              {`${data?.firstName} ${data?.lastName}`}
            </BodyText>
          </div>

          <ChevronDownIcon className='hidden h-[20px] w-[20xp] xl:block' />
        </Menu.Button>

        <Menu.Items className='absolute right-0 border border-neutral-300 rounded-lg'>
          <div className='space-y-2 rounded-lg bg-neutral-100 p-2 shadow'>
            <div className='mt-2 space-y-1'>
              <BodyText variant='base' className='text-functional-info-dark '>
                <Link to='/profile'>
                  <div className='flex items-center gap-5 rounded-md p-2 hover:bg-neutral-300 active:bg-neutral-400'>
                    <ProfileIcon className='h-5 w-5' /> {t('profile')}
                  </div>
                </Link>
              </BodyText>

              <BodyText variant='base' className='text-functional-info-dark'>
                <Link to='/settings'>
                  <div className='flex w-full items-center gap-5 rounded-md p-2 hover:bg-neutral-300 active:bg-neutral-400'>
                    <SettingIcon className='h-5 w-5' /> {t('settings')}
                  </div>
                </Link>
              </BodyText>
              <BodyText variant='base' className='text-functional-info-dark'>
                <Link to='https://www.studycentral.eu/contact'>
                  <div className='flex w-full items-center gap-5 rounded-md p-2 hover:bg-neutral-300 active:bg-neutral-400'>
                    <MessageIcon className='h-5 w-5' /> {t('contact-us')}
                  </div>
                </Link>
              </BodyText>
            </div>

            <hr className='w-[180px] border-t border-neutral-300' />
            <div className={`flex w-full items-center gap-5 rounded-md px-2`}>
              <LanguageIcon className='h-5 w-5' />
              <LanguageSelector />
            </div>
            <button
              onClick={() => {
                handleDarkThemeChange(!settingsInfo?.settings?.darkTheme);
              }}
              className={`flex w-full items-center gap-5 rounded-md p-2 hover:bg-neutral-300 active:bg-neutral-400`}
            >
              {settingsInfo?.settings?.darkTheme ? (
                <LightIcon className='h-5 w-5' />
              ) : (
                <DarkIcon className='h-5 w-5' />
              )}
              <BodyText variant='base' className='text-functional-info-dark'>
                {settingsInfo?.settings?.darkTheme ? t('light-theme') : t('dark-theme')}
              </BodyText>
            </button>
            <BodyText variant='base' className='text-functional-info-dark '>
              <button
                onClick={handleLogout}
                className='w-full items-center gap-5 rounded-md p-2 hover:bg-neutral-300 active:bg-neutral-400'
              >
                {' '}
                <button className='flex items-center gap-5'>
                  <LogoutIcon className='h-5 w-5 text-functional-danger-dark' /> {t('logout')}
                </button>
              </button>
            </BodyText>
          </div>
        </Menu.Items>
      </Menu>
    </>
  );
};

export default ProfileDropdown;
