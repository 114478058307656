import { AxiosRequestConfig } from 'axios';
import { API_ROOT } from 'constants/config';
import { RemarksResponseType } from 'types/apis/response';
import { parseDELETE, parseGET, parsePOST, parsePUT } from 'utils/rest';

export const getCommunitySubmissionRemarks = (
  communityId: string,
  submissionId: string,
  options?: AxiosRequestConfig,
) =>
  parseGET<RemarksResponseType>(
    `${API_ROOT}/community/${communityId}/tasks/submission/${submissionId}/remarks`,
    {
      withCredentials: true,
      ...options,
    },
  );

export const getProgramSubmissionRemarks = (
  programId: string,
  submissionId: string,
  options?: AxiosRequestConfig,
) =>
  parseGET<RemarksResponseType>(
    `${API_ROOT}/program/${programId}/tasks/submission/${submissionId}/remarks`,
    {
      withCredentials: true,
      ...options,
    },
  );

export const addCommunitySubmissionRemark = (
  communityId: string,
  submissionId: string,
  body: { remark: string },
  options?: AxiosRequestConfig,
) =>
  parsePOST(`${API_ROOT}/community/${communityId}/tasks/submission/${submissionId}/remarks`, body, {
    withCredentials: true,
    ...options,
  });

export const addProgramSubmissionRemark = (
  programId: string,
  submissionId: string,
  body: { remark: string },
  options?: AxiosRequestConfig,
) =>
  parsePOST(`${API_ROOT}/program/${programId}/tasks/submission/${submissionId}/remarks`, body, {
    withCredentials: true,
    ...options,
  });

export const updateCommunitySubmissionRemark = (
  communityId: string,
  remarkId: string,
  body: { remark: string },
  options?: AxiosRequestConfig,
) =>
  parsePUT(`${API_ROOT}/community/${communityId}/tasks/submission/remarks/${remarkId}`, body, {
    withCredentials: true,
    ...options,
  });

export const updateProgramSubmissionRemark = (
  programId: string,
  remarkId: string,
  body: { remark: string },
  options?: AxiosRequestConfig,
) =>
  parsePUT(`${API_ROOT}/program/${programId}/tasks/submission/remarks/${remarkId}`, body, {
    withCredentials: true,
    ...options,
  });

export const deleteCommunitySubmissionRemark = (
  communityId: string,
  remarkId: string,
  options?: AxiosRequestConfig,
) =>
  parseDELETE(`${API_ROOT}/community/${communityId}/tasks/submission/remarks/${remarkId}`, {
    withCredentials: true,
    ...options,
  });

export const deleteProgramSubmissionRemark = (
  programId: string,
  remarkId: string,
  options?: AxiosRequestConfig,
) =>
  parseDELETE(`${API_ROOT}/program/${programId}/tasks/submission/remarks/${remarkId}`, {
    withCredentials: true,
    ...options,
  });
