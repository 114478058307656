import JSZip from 'jszip';
import { saveAs } from 'file-saver';

interface FileDetails {
  url: string;
  filename: string;
}

export async function downloadZip(filename: string, fileDetails: FileDetails[]): Promise<void> {
  const zip = new JSZip();
  const zipFilename = `${filename}.zip`;

  await Promise.all(
    fileDetails.map(async ({ url, filename }) => {
      // Fetching the data from url and converting it to Blob
      const response = await fetch(url);
      const data = await response.blob();

      zip.file(filename, data);
    }),
  );

  // Generate zip and start download
  zip.generateAsync({ type: 'blob' }).then(function (content: Blob) {
    saveAs(content, zipFilename);
  });
}
