import { AxiosRequestConfig } from 'axios';
import { API_ROOT } from 'constants/config';
import {
  CommentsResponseType,
  CommunityEventResponseType,
  CommunityEventsResponseType,
  ProgramEventResponseType,
  ProgramEventsResponseType,
} from 'types/apis/response';
import { generateQueryParam, parseDELETE, parseGET, parsePOST, parsePUT } from 'utils/rest';

export const getMyUpcomingEvents = () => {
  return parseGET<CommunityEventsResponseType>(`${API_ROOT}/events/my-upcoming-events?limit=3`, {
    withCredentials: true,
  });
};

export const getCommunityEvents = (
  communityId: string,
  limit = 200,
  cursor?: string,
  upcoming = false,
  options?: AxiosRequestConfig,
) => {
  const queryParams = generateQueryParam({ limit, cursor, upcoming });
  return parseGET<CommunityEventsResponseType>(
    `${API_ROOT}/community/${communityId}/events${queryParams}`,
    {
      withCredentials: true,
      ...options,
    },
  );
};

export const getProgramEvents = (
  programId: string,
  limit = 200,
  cursor?: string,
  upcoming = false,
  options?: AxiosRequestConfig,
) => {
  const queryParams = generateQueryParam({ limit, cursor, upcoming });
  return parseGET<ProgramEventsResponseType>(
    `${API_ROOT}/program/${programId}/events${queryParams}`,
    {
      withCredentials: true,
      ...options,
    },
  );
};

export const getMyUpcomingProgramEvents = () => {
  return parseGET<ProgramEventsResponseType>(
    `${API_ROOT}/program/events/my-upcoming-program-events?limit=3`,
    {
      withCredentials: true,
    },
  );
};

export const getSingleCommunityEvent = (
  communityId: string,
  eventId: string,
  options?: AxiosRequestConfig,
) =>
  parseGET<CommunityEventResponseType>(`${API_ROOT}/community/${communityId}/events/${eventId}`, {
    withCredentials: true,
    ...options,
  });

export const getSinglePrgoramEvent = (
  programId: string,
  eventId: string,
  options?: AxiosRequestConfig,
) =>
  parseGET<ProgramEventResponseType>(`${API_ROOT}/program/${programId}/events/${eventId}`, {
    withCredentials: true,
    ...options,
  });

export const createCommunityEvent = (
  communityId: string,
  body: FormData,
  options?: AxiosRequestConfig,
) =>
  parsePOST(`${API_ROOT}/community/${communityId}/events`, body, {
    withCredentials: true,
    ...options,
  });

export const createProgramEvent = (
  programId: string,
  body: FormData,
  options?: AxiosRequestConfig,
) =>
  parsePOST(`${API_ROOT}/program/${programId}/events`, body, {
    withCredentials: true,
    ...options,
  });

export const updateCommunityEvent = (
  communityId: string,
  eventId: string,
  body: FormData,
  options?: AxiosRequestConfig,
) =>
  parsePUT(`${API_ROOT}/community/${communityId}/events/${eventId}`, body, {
    withCredentials: true,
    ...options,
  });

export const updateProgramEvent = (
  programId: string,
  eventId: string,
  body: FormData,
  options?: AxiosRequestConfig,
) =>
  parsePUT(`${API_ROOT}/program/${programId}/events/${eventId}`, body, {
    withCredentials: true,
    ...options,
  });

export const deleteCommunityEvent = (
  communityId: string,
  eventId: string,
  options?: AxiosRequestConfig,
) =>
  parseDELETE(`${API_ROOT}/community/${communityId}/events/${eventId}`, {
    withCredentials: true,
    ...options,
  });

export const deleteProgramEvent = (
  programId: string,
  eventId: string,
  options?: AxiosRequestConfig,
) =>
  parseDELETE(`${API_ROOT}/program/${programId}/events/${eventId}`, {
    withCredentials: true,
    ...options,
  });

export const likeCommunityEvent = (
  communityId: string,
  eventId: string,
  options?: AxiosRequestConfig,
) =>
  parsePUT(
    `${API_ROOT}/community/${communityId}/events/${eventId}/like`,
    {},
    { ...options, withCredentials: true },
  );

export const likeProgramEvent = (
  programId: string,
  eventId: string,
  options?: AxiosRequestConfig,
) =>
  parsePUT(
    `${API_ROOT}/program/${programId}/events/${eventId}/like`,
    {},
    { ...options, withCredentials: true },
  );

export const dislikeCommunityEvent = (
  communityId: string,
  eventId: string,
  options?: AxiosRequestConfig,
) =>
  parsePUT(
    `${API_ROOT}/community/${communityId}/events/${eventId}/unlike`,
    {},
    { ...options, withCredentials: true },
  );

export const dislikeProgramEvent = (
  programId: string,
  eventId: string,
  options?: AxiosRequestConfig,
) =>
  parsePUT(
    `${API_ROOT}/program/${programId}/events/${eventId}/unlike`,
    {},
    { ...options, withCredentials: true },
  );

export const getCommunityEventComments = (
  communityId: string,
  eventId: string,
  limit = 2,
  cursor?: string,
  options?: AxiosRequestConfig,
) => {
  const queryParam = generateQueryParam({ limit, cursor });
  return parseGET<CommentsResponseType>(
    `${API_ROOT}/community/${communityId}/events/${eventId}/comments${queryParam}`,
    {
      withCredentials: true,
      ...options,
    },
  );
};

export const getProgramEventComments = (
  programId: string,
  eventId: string,
  limit = 2,
  cursor?: string,
  options?: AxiosRequestConfig,
) => {
  const queryParam = generateQueryParam({ limit, cursor });
  return parseGET<CommentsResponseType>(
    `${API_ROOT}/program/${programId}/events/${eventId}/comments${queryParam}`,
    {
      withCredentials: true,
      ...options,
    },
  );
};

export const addCommunityEventComment = (
  communityId: string,
  eventId: string,
  body: { comment: string },
  options?: AxiosRequestConfig,
) =>
  parsePOST(`${API_ROOT}/community/${communityId}/events/${eventId}/comments`, body, {
    withCredentials: true,
    ...options,
  });

export const addProgramEventComment = (
  programId: string,
  eventId: string,
  body: { comment: string },
  options?: AxiosRequestConfig,
) =>
  parsePOST(`${API_ROOT}/program/${programId}/events/${eventId}/comments`, body, {
    withCredentials: true,
    ...options,
  });

export const deleteCommunityEventComment = (
  communityId: string,
  commentId: string,
  options?: AxiosRequestConfig,
) =>
  parseDELETE(`${API_ROOT}/community/${communityId}/events/comments/${commentId}`, {
    withCredentials: true,
    ...options,
  });

export const deleteProgramEventComment = (
  programId: string,
  commentId: string,
  options?: AxiosRequestConfig,
) =>
  parseDELETE(`${API_ROOT}/program/${programId}/events/comments/${commentId}`, {
    withCredentials: true,
    ...options,
  });
