import React from 'react';
import { useTranslation } from 'react-i18next';
import Select from './Select';

interface ISelectMonth {
  onSelect: (value: number) => void;
}

function SelectMonth({ onSelect }: ISelectMonth) {
  const { t } = useTranslation();

  const options = [
    { value: 0, label: t('month.january') },
    { value: 1, label: t('month.february') },
    { value: 2, label: t('month.march') },
    { value: 3, label: t('month.april') },
    { value: 4, label: t('month.may') },
    { value: 5, label: t('month.june') },
    { value: 6, label: t('month.july') },
    { value: 7, label: t('month.august') },
    { value: 8, label: t('month.september') },
    { value: 9, label: t('month.october') },
    { value: 10, label: t('month.november') },
    { value: 11, label: t('month.december') },
  ];

  return (
    <Select
      options={options}
      className='w-auto'
      defaultValue={new Date().getMonth()}
      onChange={(e) => onSelect(Number(e.target.value))}
    />
  );
}

export default SelectMonth;
