import { useQuery } from '@tanstack/react-query';
import { getProgramSavedPosts } from 'utils/api/post';

export const useProgramSavedPosts = (programId: string) => {
  return useQuery({
    queryKey: ['program-saved-posts', programId],
    queryFn: () => getProgramSavedPosts(programId),
    select: (data) => data.data.savedPosts,
  });
};
