import NotificationIcon from 'assets/icons/notification.svg';
import MemberIcon from 'assets/icons/members.svg';
import FolderIcon from 'assets/icons/folder.svg';
import MessageIcon from 'assets/icons/message.svg';
import ClassIcon from 'assets/icons/class.svg';
import React, { useState } from 'react';
import { useUnseenNotifications } from 'hooks/api/notificaiton/useUnseenNotifications';
import { useNotifications } from 'hooks/api/notificaiton/useNotifications';
import { useNavigate, useLocation } from 'react-router-dom';
import { useHasUnread } from 'hooks/api/message/useHasUnread';

interface IBottomNav {
  className?: string;
}

const BottomNav = ({ className = '' }: IBottomNav) => {
  const navigate = useNavigate();
  const location = useLocation();
  const isActive = (path: string) => {
    return location.pathname.includes(path);
  };
  const [openNotificationDrawer, setOpenNotificationDrawer] = useState(false);

  const { unreadCount } = useHasUnread();
  const { data: unseenCount = 0, refetch: refetchNotificationCount } = useUnseenNotifications();
  const { refetch: refetchNotifications } = useNotifications(openNotificationDrawer);

  const handleOpenNofications = () => {
    refetchNotifications();
    refetchNotificationCount();
  };

  return (
    <nav
      className={`fixed bottom-0 z-30 flex w-full items-center justify-between border-t-2 border-neutral-400 bg-secondary-100 px-5 pb-2 pt-1 shadow-xl md:hidden ${className}`}
    >
      <button
        onClick={() => navigate('/programs')}
        className={`${isActive('/programs') ? 'text-functional-info-dark' : 'text-neutral-500'}`}
      >
        <ClassIcon className='h-5 w-5' />
      </button>
      <button
        onClick={() => navigate('/resources')}
        className={`${isActive('/resources') ? 'text-functional-info-dark' : 'text-neutral-500'}`}
      >
        <FolderIcon className='h-5 w-5' />
      </button>
      <button
        onClick={() => navigate('/members')}
        className={`${isActive('/members') ? 'text-functional-info-dark' : 'text-neutral-500'}`}
      >
        <MemberIcon className='h-5 w-5' />
      </button>
      <div className='relative mt-1 inline-block'>
        <button
          onClick={() => {
            handleOpenNofications();
            navigate('/mobilenotifications');
          }}
          className={`${
            isActive('/mobilenotifications') ? 'text-functional-info-dark' : 'text-neutral-500'
          } h-5 w-5`}
        >
          <NotificationIcon className='h-5 w-5' />
        </button>
        <div
          className={`absolute -right-2 -top-1 flex h-4 w-4 items-center justify-center rounded-full bg-functional-danger-400 text-xs text-white ${
            unseenCount ? '' : 'hidden'
          }`}
        >
          {unseenCount}
        </div>
      </div>
      <div className='relative mt-1 inline-block'>
        <button
          onClick={() => navigate('/mobilemessages')}
          className={`${
            isActive('/messages') || isActive('/mobilemessages')
              ? 'text-functional-info-dark'
              : 'text-neutral-500'
          } h-5 w-5`}
        >
          <MessageIcon className='h-5 w-5' />
        </button>
        <div
          className={`absolute -right-2 -top-1 flex h-4 w-4 items-center justify-center rounded-full bg-functional-danger-400 text-xs text-white ${
            unseenCount ? '' : 'hidden'
          }`}
        >
          {unreadCount}
        </div>
      </div>
    </nav>
  );
};

export default BottomNav;
