import CloseIcon from 'assets/icons/close.svg';
import Avatar from 'components/memberAndProfile/Avatar';
import BodyText from 'components/typography/BodyText';
import { userAvatar } from 'constants/common';
import { useSearchUser } from 'hooks/api/search/useSearchUser';
import { useOuterClick } from 'hooks/common/useOuterClick';
import debounce from 'lodash/debounce';
import React, { ChangeEvent, memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { notifyError } from 'utils/notify';
import { TagDetails } from 'types/components/form';

interface ISelectTag {
  isSingle?: boolean;
  className?: string;
  communityId?: string;
  programId?: string;
  onChange: (values: TagDetails[]) => void;
  onValueChange: (value: string) => void;
}

const SelectTag = ({
  isSingle = false,
  className = '',
  onChange,
  onValueChange,
  communityId,
  programId,
}: ISelectTag) => {
  const { t } = useTranslation();
  const containerRef = useRef<HTMLDivElement>(null);
  const [value, setValue] = useState('');
  const [tags, setTags] = useState<TagDetails[]>([]);
  const [searchString, setSearchString] = useState('');
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const { data: users, refetch: refetchUsers } = useSearchUser(
    searchString,
    communityId,
    programId,
  );

  const filteredUsers = useMemo(() => {
    if (!users) return [];
    return users.filter(({ email }) => !tags.find(({ value: tag }) => tag === email));
  }, [users, tags, searchString]);

  const debounceSearch = useCallback(
    debounce((searchValue) => setSearchString(searchValue), 600),
    [],
  );

  const handleInputValueChange = (e: ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value);
    debounceSearch(encodeURIComponent(e.target.value));
  };

  const handleSetTag = (email: string, userId: string, rawLabel?: React.ReactNode) => {
    const alreadyExists = tags.some((tag) => tag.email.toLowerCase() === email.toLowerCase());
    if (alreadyExists) {
      notifyError(t('ui.error.tag_already_exists'));
      return;
    }
    const label = rawLabel || email;
    const newTag = { label, email, userId: userId || '', value: email };

    if (isSingle) {
      setTags([newTag]); // Replace the existing tag(s) with the new one
    } else {
      setTags((currentTags) => [...currentTags, newTag]); // Add the new tag to the existing tags
    }

    setValue('');
    setSearchString('');
  };

  const handleAddTag = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      if (!value) {
        notifyError(t('ui.error.no_value_entered'));
        return;
      }
      const user = users?.find((user) => user.email.toLowerCase() === value.toLowerCase());
      if (user) {
        handleSetTag(user.email, user.id);
      } else {
        handleSetTag(value, '', value);
      }
    }
  };

  const handleRemoveTag = (selectedTag: string) => {
    setTags((oldTags) => oldTags.filter(({ value: tag }) => tag !== selectedTag));
  };

  useOuterClick(containerRef, () => setIsDropdownOpen(false));

  useEffect(() => {
    onChange(tags);
    onValueChange(value);
  }, [tags, value]);

  useEffect(() => {
    if (searchString === '') return;
    refetchUsers();
  }, [searchString]);

  return (
    <div className='relative' ref={containerRef}>
      <div
        className={`flex w-full flex-col gap-3 rounded-lg border  border-neutral-300 bg-neutral-200 px-[14px] py-[11px] text-base ${className}`}
      >
        {tags.length > 0 && (
          <ul className='flex flex-wrap items-center gap-3'>
            {tags.map(({ label, value: tag }) => (
              <li
                key={tag}
                className='flex items-center gap-2 rounded-md border border-neutral-300 bg-neutral-100 p-1'
              >
                {label}
                <button
                  className='relative flex h-4 w-4 items-center justify-center rounded-full text-functional-danger-400 hover:bg-neutral-300 active:bg-neutral-400'
                  onClick={() => handleRemoveTag(tag)}
                >
                  <CloseIcon className='h-2 w-2' />
                </button>
              </li>
            ))}
          </ul>
        )}
        <input
          value={value}
          type='text'
          placeholder={t('insert_tag_placeholder') || 'Press enter to add...'}
          className='w-full bg-transparent text-neutral-600 outline-none'
          onKeyUp={handleAddTag}
          onChange={handleInputValueChange}
          onFocus={() => setIsDropdownOpen(true)}
        />
      </div>

      {filteredUsers.length !== 0 && !!searchString && isDropdownOpen && (
        <div className='absolute w-full rounded-md bg-neutral-100 p-[6px] border border-neutral-300 z-30'>
          {filteredUsers.map(({ id, firstName, lastName, email, profilePicture }) => (
            <button
              key={id}
              className={`flex w-full items-center gap-4 px-3 py-2 text-left ${
                tags.findIndex((el) => el.value === email) !== -1
                  ? 'bg-neutral-200'
                  : 'hover:bg-neutral-200'
              }`}
              onClick={() =>
                handleSetTag(
                  email,
                  id,
                  <div className='flex items-center gap-1'>
                    <Avatar size={16} src={profilePicture || userAvatar} />
                    <BodyText variant='sm' className='font-medium text-neutral-500'>
                      {firstName}
                    </BodyText>
                  </div>,
                )
              }
            >
              <Avatar size={24} alt={firstName} src={profilePicture || userAvatar} />
              <BodyText
                variant='sm'
                className='font-medium text-neutral-500'
              >{`${firstName} ${lastName}`}</BodyText>
            </button>
          ))}
        </div>
      )}
    </div>
  );
};

export default memo(SelectTag);
