import SearchIcon from 'assets/icons/search.svg';
import Avatar from 'components/memberAndProfile/Avatar';
import BodyText from 'components/typography/BodyText';
import { useOuterClick } from 'hooks/common/useOuterClick';
import debounce from 'lodash/debounce';
import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

interface ISearchInput {
  className?: string;
  typeOptions: { value: string; label: string }[];
  onSearch: (resutl: { type: string; value: string }) => void;
  searchResult?: { id: string; logo: string; title: string; subTitle: string }[];
  onOptionClick: (id: string) => void;
}

const SearchInput = ({
  className = '',
  onSearch,
  typeOptions,
  searchResult,
  onOptionClick,
}: ISearchInput) => {
  const { t } = useTranslation();
  const selectContainerRef = React.useRef<HTMLDivElement | null>(null);

  const [searchValue, setSearchValue] = useState('');
  const [searchType, setSearchType] = useState(typeOptions[0].value);
  const [showSearchResult, setShowSearchResult] = useState(false);

  const handleSearch = ({ type, value }: { type: string; value: string }) => {
    onSearch({ type, value });
  };

  const debounceSearch = useCallback(
    debounce(handleSearch, 500, { leading: false, trailing: true }),
    [],
  );

  const handleSelectType = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setSearchValue('');
    setSearchType(e.target.value);
  };

  const handleInputValue = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(e.target.value);
    debounceSearch({ value: e.target.value, type: searchType });
  };

  useOuterClick(selectContainerRef, () => setShowSearchResult(false));

  return (
    <div ref={selectContainerRef} className='relative'>
      <div className={`flex items-center rounded-2xl bg-neutral-200 p-1 pl-4 ${className}`}>
        <div className='flex grow items-center gap-4'>
          <SearchIcon className='h-4 w-4 text-neutral-600' />
          <input
            type='text'
            value={searchValue}
            placeholder={t('search') || 'Search...'}
            onChange={handleInputValue}
            onFocus={() => setShowSearchResult(true)}
            className='grow bg-[transparent] text-sm outline-none'
          />
        </div>
        <select
          value={searchType}
          onChange={handleSelectType}
          className='rounded-[10px] bg-neutral-100 px-4 py-1 outline-none text-sm'
        >
          {typeOptions.map(({ value, label }) => (
            <option key={value} value={value}>
              {label}
            </option>
          ))}
        </select>
      </div>

      {searchResult && searchResult.length > 0 && showSearchResult && (
        <div className='absolute max-h-[300px] w-full overflow-y-auto rounded-md bg-neutral-100 pt-5 shadow shadow-neutral-300'>
          {searchResult.map(({ id, logo, title, subTitle }) => (
            <button
              key={id}
              onClick={() => onOptionClick(id)}
              className='flex w-full items-center gap-4 px-4 py-2 text-left hover:bg-neutral-300'
            >
              <Avatar size={32} alt={title} src={logo} />
              <div className='space-y-1'>
                <BodyText variant='sm' className='font-medium text-neutral-500'>
                  {title}
                </BodyText>
                <BodyText variant='xs' className='text-neutral-600'>
                  {subTitle}
                </BodyText>
              </div>
            </button>
          ))}
        </div>
      )}
    </div>
  );
};

export default SearchInput;
