import { useQuery } from '@tanstack/react-query';
import { getOrgCommunities } from 'utils/api/community';

export const useOrgCommunities = () =>
  useQuery({
    queryKey: ['org-communities'],
    queryFn: () => getOrgCommunities(),
    select: (data) => data.data.communities,
  });

