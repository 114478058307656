import UploadIcon from 'assets/icons/drop-docs.svg';
import BodyText from 'components/typography/BodyText';
import React from 'react';
import { useTranslation } from 'react-i18next';

interface IUploadFileAreaButton {
  title?: string;
  isError?: boolean;
  onClick: () => void;
  maxFileSize?: number;
}

const UploadFileAreaButton = ({
  title,
  isError,
  onClick,
  maxFileSize = 5,
}: IUploadFileAreaButton) => {
  const { t } = useTranslation();

  return (
    <button
      type='button'
      className={`w-full bg-neutral-200 flex flex-col items-center justify-center border border-dotted rounded-xl py-6 ${
        isError ? 'border-functional-danger-dark' : 'border-neutral-400'
      }`}
      onClick={onClick}
    >
      <UploadIcon className='w-10 h-10 text-secondary-600' />
      <BodyText variant='base' className='text-secondary-600 font-bold'>
        {title || t('upload-file-here')}
      </BodyText>
      <BodyText variant='sm' className='text-neutral-400'>
        {t('max-file-size', { value: maxFileSize })}
      </BodyText>
    </button>
  );
};

export default UploadFileAreaButton;
