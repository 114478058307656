import { Disclosure } from '@headlessui/react';
import AddIcon from 'assets/icons/add.svg';
import LinkIcon from 'assets/icons/link.svg';
import FolderIcon from 'assets/icons/folder.svg';
import UpIcon from 'assets/icons/chevron-up.svg';
import DownIcon from 'assets/icons/chevron-down.svg';
import ChevronDownIcon from 'assets/icons/chevron-down.svg';
import DeleteIcon from 'assets/icons/delete.svg';
import DownloadIcon from 'assets/icons/download.svg';
import NoteIcon from 'assets/icons/note.svg';
import EditIcon from 'assets/icons/edit.svg';
import EyeOffIcon from 'assets/icons/eye-off.svg';
import EyeIcon from 'assets/icons/eye.svg';
import Button from 'components/button/Button';
import MaterialIcon from 'components/common/MaterialIcon';
import AddMaterialModal from 'components/modal/AddMaterialModal';
import ConfirmationModal from 'components/modal/ConfirmationModal';
import MaterialGroupModal from 'components/modal/MaterialGroupModal';
import EditSingleMaterialModal from 'components/modal/EditSingleMaterialModal';
import BodyText from 'components/typography/BodyText';
import { useMaterials } from 'hooks/api/material/useMaterials';
import { useSingleProgram } from 'hooks/api/program/useSingleProgram';
import { useInvalidateQueries } from 'hooks/common/useInvalidatequeries';
import React, { useCallback, useState, useMemo } from 'react';
import SearchInput from 'components/form/SearchInput';
import debounce from 'lodash/debounce';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import {
  deleteMaterial,
  deleteMaterialGroup,
  hideMaterial,
  hideMaterialGroup,
  unhideMaterial,
  unhideMaterialGroup,
  changeOverallMaterialOrder,
  changeMaterialOrder,
} from 'utils/api/material';
import { notifySuccess, notifyTranslatedError } from 'utils/notify';
import ResourceViewerDrawer from 'components/drawer/ResourceViewerDrawer';
import { isViewableFile } from 'utils/helper';
import { ContextMenu } from 'components/common/ContextMenu';
import { DropdownMenu } from 'components/common/DropdownMenu';

const ProgramMaterials = () => {
  const { t } = useTranslation();
  const { programId } = useParams();

  const [groupName, setGroupName] = useState('');
  const [showGroupModal, setShowGroupModal] = useState(false);
  const [showAddMaterialModal, setShowAddMaterialModal] = useState(false);
  const [showEditMaterialModal, setShowEditMaterialModal] = useState(false);
  const [selectedGroupId, setSelectedGroupId] = useState<null | string>(null);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [selectedMaterialId, setSelectedMaterialId] = useState<null | string>(null);
  const [selectedMaterialTitle, setSelectedMaterialTitle] = useState<null | string>(null);
  const [selectedMaterialDesc, setSelectedMaterialDesc] = useState<null | string>(null);
  const [selectedMaterialType, setSelectedMaterialType] = useState<null | string>(null);
  const [originId, setOriginId] = useState('');
  const [originType, setOriginType] = useState('program_resource');
  const [showDeleteMaterialConfirmation, setShowDeleteMaterialConfirmation] = useState(false);
  const [showResourceViewer, setShowResourceViewer] = useState(false);
  const [fileUrl, setFileUrl] = useState('');
  const [uploaderName, setUploaderName] = useState('');
  const [uploadedBy, setUploadedBy] = useState('');
  const { data: program } = useSingleProgram(programId || '');

  if (!program) return null;

  const { unifiedMaterials, materialsCount, refetchMaterials } = useMaterials(program.id);
  const { invalidateProgramMaterialGroups } = useInvalidateQueries();

  // Search related code:
  const [searchString, setSearchString] = useState('');
  const filteredMaterials = useMemo(() => {
    // Check if the search string is empty
    if (!searchString.trim()) {
      // If the search string is empty, return the entire unifiedMaterials list
      return unifiedMaterials;
    }
    const searchLower = searchString.toLowerCase();
    // Perform search only when the search string is not empty
    return unifiedMaterials.filter((material) => {
      // Check if the material is of type 'group'
      if (material.materialType === 'group') {
        // Search within each file/page of the group
        return material.files.some((file) => {
          const fileTitleMatch = file.title && file.title.toLowerCase().includes(searchLower);
          const pageMatch =
            file.type === 'page' &&
            file.page?.title &&
            file.page.title.toLowerCase().includes(searchLower);
          const descriptionMatch =
            file.description && file.description.toLowerCase().includes(searchLower);
          return fileTitleMatch || pageMatch || descriptionMatch;
        });
      } else {
        // For individual materials (not in a group)
        const titleMatch = material.title && material.title.toLowerCase().includes(searchLower);
        const pageTitleMatch =
          material.type === 'page' &&
          material.page?.title &&
          material.page.title.toLowerCase().includes(searchLower);
        const descriptionMatch = material.description?.toLowerCase().includes(searchLower);
        return titleMatch || pageTitleMatch || descriptionMatch;
      }
    });
  }, [unifiedMaterials, searchString]);

  const handleSearch = (value: string) => {
    setSearchString(value.toLowerCase());
  };
  const debounceSearch = useCallback(debounce(handleSearch, 300), []);
  //end of search related code

  // material group edit
  const handleOpenEditGroupModal = (groupId: string, groupname: string) => {
    setSelectedGroupId(groupId);
    setGroupName(groupname);
    setShowGroupModal(true);
  };

  const handleCloseGroupModal = () => {
    setGroupName('');
    setSelectedGroupId(null);
    setShowGroupModal(false);
  };

  // material group delete
  const handleOpenDeleteConfirmation = (groupId: string) => {
    setSelectedGroupId(groupId);
    setShowDeleteConfirmation(true);
  };

  const handleCloseDeleteConfirmation = () => {
    setSelectedGroupId(null);
    setShowDeleteConfirmation(false);
  };

  const handleDeleteGroup = () => {
    if (!selectedGroupId) return;
    deleteMaterialGroup(program.id, selectedGroupId)
      .then(() => {
        refetchMaterials();
        setSelectedGroupId(null);
        setShowDeleteConfirmation(false);
        notifySuccess(t('material-category-deleted'));
        invalidateProgramMaterialGroups(program.id);
      })
      .catch((error) => {
        notifyTranslatedError(t, error.response.data?.errorCode);
      });
  };

  // material delete method
  const handleOpenMaterialDeleteConfirmation = (materialId: string) => {
    setSelectedMaterialId(materialId);
    setShowDeleteMaterialConfirmation(true);
  };

  const handleCloseMaterialDeleteConfirmation = () => {
    setSelectedMaterialId(null);
    setShowDeleteMaterialConfirmation(false);
  };

  const handleDeleteMaterial = () => {
    if (!selectedMaterialId) return;
    deleteMaterial(program.id, selectedMaterialId).then(() => {
      refetchMaterials();
      setSelectedMaterialId(null);
      setShowDeleteMaterialConfirmation(false);
      notifySuccess(t('success.api.resource_deleted'));
    });
  };

  const handleHideMaterialGroup = (isHidden: boolean, selectredId: string) => {
    if (isHidden) {
      unhideMaterialGroup(program.id, selectredId)
        .then(() => {
          refetchMaterials();
          notifySuccess(t('material-category-unhidden'));
        })
        .catch((error) => {
          notifyTranslatedError(t, error.response.data?.errorCode);
        });
    } else {
      hideMaterialGroup(program.id, selectredId)
        .then(() => {
          refetchMaterials();
          notifySuccess(t('material-category-hidden'));
        })
        .catch((error) => {
          notifyTranslatedError(t, error.response.data?.errorCode);
        });
    }
  };

  const handleHideMaterial = (hidden: boolean, materialId: string) => {
    if (hidden) {
      unhideMaterial(program.id, materialId)
        .then(() => {
          refetchMaterials();
        })
        .catch((error) => {
          notifyTranslatedError(t, error.response.data?.errorCode);
        });
    } else {
      hideMaterial(program.id, materialId)
        .then(() => {
          refetchMaterials();
        })
        .catch((error) => {
          notifyTranslatedError(t, error.response.data?.errorCode);
        });
    }
  };

  const handleChangeOverallOrder = (
    id: string,
    programId: string,
    direction: 'up' | 'down',
    type: 'material' | 'group',
  ) => {
    changeOverallMaterialOrder(id, programId, { direction, type })
      .then(() => {
        refetchMaterials();
      })
      .catch((error) => {
        notifyTranslatedError(t, error.response.data?.errorCode);
        notifyTranslatedError(t, error.response.data?.message);
      });
  };

  const handleChangeMaterialOrder = (
    id: string,
    programId: string,
    groupId: string,
    direction: 'up' | 'down',
  ) => {
    changeMaterialOrder(id, programId, groupId, { direction })
      .then(() => {
        refetchMaterials();
      })
      .catch((error) => {
        notifyTranslatedError(t, error.response.data?.errorCode);
        notifyTranslatedError(t, error.response.data?.message);
      });
  };

  const handleOpenEditMaterialModal = (
    materialId: string,
    title: string,
    description: string,
    type: string,
    groupId: string,
  ) => {
    setSelectedMaterialId(materialId);
    setSelectedMaterialTitle(title);
    setSelectedMaterialDesc(description);
    setSelectedMaterialType(type);
    setSelectedGroupId(groupId);
    setShowEditMaterialModal(true);
  };

  return (
    <div>
      <div className='mb-4 flex items-center justify-between gap-2 px-1'>
        <SearchInput onSearch={debounceSearch} />
        <BodyText variant='base' className='mr-auto text-neutral-500 hidden md:block'>
          <span className='text-secondary-500 font-bold'>{materialsCount}</span> {t('items')}
        </BodyText>

        {program.canManage && (
          <DropdownMenu
            menuButton={
              <div className=' flex items-center'>
                <Button variant='secondary-outlined' size='small' tooltip={t('add-item')}>
                  <AddIcon className='h-5 w-5' />
                </Button>
              </div>
            }
            menuItems={[
              {
                key: 'add-category',
                label: t('add-category'),
                icon: FolderIcon,
                callback: () => setShowGroupModal(true),
              },
              {
                key: 'add-item',
                label: t('add-item'),
                icon: AddIcon,
                callback: () => setShowAddMaterialModal(true),
              },
            ]}
          />
        )}
      </div>

      <div className='mb-7 space-y-4'>
        {filteredMaterials.length > 0 &&
          filteredMaterials.map((item) => {
            if (item.materialType === 'group') {
              return (
                <Disclosure key={item.groupId}>
                  {({ open }) => (
                    <div className='w-full bg-neutral-200 rounded-2xl shadow shadow-neutral-300'>
                      <Disclosure.Button
                        className={`flex w-full items-center gap-2 rounded-2xl px-4 py-4 shadow shadow-neutral-300 hover:bg-secondary-200 ${
                          open ? 'bg-secondary-100 text-functional-info-dark' : 'bg-neutral-100'
                        } ${item.hidden ? 'text-neutral-400' : 'text-secondary-600'} `}
                      >
                        <ChevronDownIcon
                          className={`h-5 w-5 transition-transform ${open ? '' : '-rotate-90'}`}
                        />

                        <BodyText
                          as='span'
                          variant='lg'
                          className={`mr-auto font-semibold capitalize`}
                        >
                          {item.name}
                        </BodyText>

                        {program.canManage && (
                          <div className=' flex items-center gap-4'>
                            {item.hidden && <EyeOffIcon className='h-5 w-5' />}
                            <ContextMenu
                              menuItems={[
                                {
                                  key: 'move-up',
                                  label: t('move-up'),
                                  icon: UpIcon,
                                  callback: () =>
                                    handleChangeOverallOrder(
                                      item.groupId,
                                      programId || '',
                                      'up',
                                      'group',
                                    ),
                                },
                                {
                                  key: 'move-down',
                                  label: t('move-down'),
                                  icon: DownIcon,
                                  callback: () =>
                                    handleChangeOverallOrder(
                                      item.groupId,
                                      programId || '',
                                      'down',
                                      'group',
                                    ),
                                },
                                {
                                  key: 'edit',
                                  label: t('edit'),
                                  icon: EditIcon,
                                  callback: () => handleOpenEditGroupModal(item.groupId, item.name),
                                },
                                {
                                  key: 'hide',
                                  label: item.hidden ? t('unhide') : t('hide'),
                                  icon: item.hidden ? EyeIcon : EyeOffIcon,
                                  callback: () =>
                                    handleHideMaterialGroup(item.hidden, item.groupId),
                                },
                                {
                                  key: 'delete',
                                  label: t('delete'),
                                  icon: DeleteIcon,
                                  callback: () => handleOpenDeleteConfirmation(item.groupId),
                                },
                              ]}
                            />
                          </div>
                        )}
                      </Disclosure.Button>

                      <Disclosure.Panel
                        className={`rounded-b-2xl ${item.files.length === 0 ? 'pt-4' : ''}`}
                      >
                        {item.files.map(
                          ({
                            fileId,
                            directory,
                            materialId,
                            title,
                            description,
                            type,
                            url,
                            page,
                            uploader,
                            hidden: materialHidden,
                          }) => (
                            <div
                              className={`neutral-button w-full rounded-2xl hover:bg-secondary-200 ${
                                materialHidden ? 'text-neutral-400' : 'text-neutral-500'
                              } `}
                            >
                              {type === 'url' && (
                                <div
                                  key={materialId}
                                  className='flex  items-center justify-between gap-4 px-4 py-3'
                                >
                                  <a
                                    href={url}
                                    target='_blank'
                                    rel='noreferrer'
                                    className='flex items-center gap-4'
                                  >
                                    <LinkIcon className='h-5 w-5 flex-shrink-0' />
                                    <div className={``}>
                                      <BodyText
                                        as='p'
                                        variant='base'
                                        className='mr-auto font-medium'
                                      >
                                        {title}
                                      </BodyText>
                                      <BodyText as='p' variant='xs' className='mr-auto font-medium'>
                                        {description}
                                      </BodyText>
                                    </div>
                                  </a>
                                  {program.canManage && (
                                    <div className='flex items-center gap-4'>
                                      {materialHidden && <EyeOffIcon className='h-5 w-5' />}
                                      <ContextMenu
                                        menuItems={[
                                          {
                                            key: 'move-up',
                                            label: t('move-up'),
                                            icon: UpIcon,
                                            callback: () =>
                                              handleChangeMaterialOrder(
                                                materialId,
                                                programId || '',
                                                item.groupId,
                                                'up',
                                              ),
                                          },
                                          {
                                            key: 'move-down',
                                            label: t('move-down'),
                                            icon: DownIcon,
                                            callback: () =>
                                              handleChangeMaterialOrder(
                                                materialId,
                                                programId || '',
                                                item.groupId,
                                                'down',
                                              ),
                                          },
                                          {
                                            key: 'edit',
                                            label: t('edit'),
                                            icon: EditIcon,
                                            callback: () =>
                                              handleOpenEditMaterialModal(
                                                materialId,
                                                title,
                                                description,
                                                type,
                                                item.groupId,
                                              ),
                                          },
                                          {
                                            key: 'hide',
                                            label: materialHidden ? t('unhide') : t('hide'),
                                            icon: materialHidden ? EyeIcon : EyeOffIcon,
                                            callback: () =>
                                              handleHideMaterial(materialHidden, materialId),
                                          },
                                          {
                                            key: 'delete',
                                            label: t('delete'),
                                            icon: DeleteIcon,
                                            callback: () =>
                                              handleOpenMaterialDeleteConfirmation(materialId),
                                          },
                                        ]}
                                      />
                                    </div>
                                  )}
                                </div>
                              )}
                              {type === 'file' && (
                                <div
                                  key={fileId}
                                  className='flex items-center justify-between gap-4 px-4 py-3'
                                >
                                  <button
                                    onClick={() => {
                                      if (isViewableFile(directory)) {
                                        setFileUrl(directory);
                                        setOriginId(materialId);
                                        setOriginType('program_resource');
                                        setUploaderName(
                                          uploader?.firstName + ' ' + uploader?.lastName,
                                        );
                                        setUploadedBy(uploader?.id);
                                        setShowResourceViewer(true);
                                      } else {
                                        window.location.href = directory;
                                      }
                                    }}
                                    className='flex items-center gap-4 text-left'
                                  >
                                    <MaterialIcon
                                      fileInput={directory + ''}
                                      className='h-5 w-5 flex-shrink-0'
                                    />
                                    <div>
                                      <BodyText
                                        as='p'
                                        variant='base'
                                        className='mr-auto font-medium'
                                      >
                                        {title}
                                      </BodyText>
                                      <BodyText as='p' variant='xs' className='mr-auto font-medium'>
                                        {description}
                                      </BodyText>
                                    </div>
                                  </button>

                                  <div className='flex items-center gap-4'>
                                    <a
                                      href={directory}
                                      target='_blank'
                                      rel='noreferrer'
                                      className='flex items-center gap-4'
                                    >
                                      <button className='neutral-button flex h-6 w-6 items-center justify-center rounded-full hover:text-functional-info-dark'>
                                        <DownloadIcon className='h-5 w-5' />
                                      </button>
                                    </a>
                                    {program.canManage && (
                                      <div className='flex items-center gap-4'>
                                        {materialHidden && <EyeOffIcon className='h-5 w-5' />}
                                        <ContextMenu
                                          menuItems={[
                                            {
                                              key: 'move-up',
                                              label: t('move-up'),
                                              icon: UpIcon,
                                              callback: () =>
                                                handleChangeMaterialOrder(
                                                  materialId,
                                                  programId || '',
                                                  item.groupId,
                                                  'up',
                                                ),
                                            },
                                            {
                                              key: 'move-down',
                                              label: t('move-down'),
                                              icon: DownIcon,
                                              callback: () =>
                                                handleChangeMaterialOrder(
                                                  materialId,
                                                  programId || '',
                                                  item.groupId,
                                                  'down',
                                                ),
                                            },
                                            {
                                              key: 'edit',
                                              label: t('edit'),
                                              icon: EditIcon,
                                              callback: () =>
                                                handleOpenEditMaterialModal(
                                                  materialId,
                                                  title,
                                                  description,
                                                  type,
                                                  item.groupId,
                                                ),
                                            },
                                            {
                                              key: 'hide',
                                              label: materialHidden ? t('unhide') : t('hide'),
                                              icon: materialHidden ? EyeIcon : EyeOffIcon,
                                              callback: () =>
                                                handleHideMaterial(materialHidden, materialId),
                                            },
                                            {
                                              key: 'delete',
                                              label: t('delete'),
                                              icon: DeleteIcon,
                                              callback: () =>
                                                handleOpenMaterialDeleteConfirmation(materialId),
                                            },
                                          ]}
                                        />
                                      </div>
                                    )}
                                  </div>
                                </div>
                              )}
                              {type === 'page' && (
                                <div
                                  key={materialId}
                                  className='flex items-center justify-between rounded-2xl px-4 py-3'
                                >
                                  <button
                                    onClick={() => {
                                      setFileUrl('');
                                      setUploaderName(
                                        uploader?.firstName + ' ' + uploader?.lastName,
                                      );
                                      setUploadedBy(uploader?.id);
                                      setOriginId(page.id);
                                      setOriginType('page');
                                      setShowResourceViewer(true);
                                    }}
                                    className='flex items-center gap-4 text-left'
                                  >
                                    <NoteIcon className='h-5 w-5 flex-shrink-0' />
                                    <div>
                                      <BodyText
                                        as='p'
                                        variant='base'
                                        className='mr-auto font-medium'
                                      >
                                        {page.title}
                                      </BodyText>
                                      <BodyText as='p' variant='xs' className='mr-auto font-medium'>
                                        {description}
                                      </BodyText>
                                    </div>
                                  </button>
                                  {program.canManage && (
                                    <div className='flex items-center gap-4'>
                                      {materialHidden && <EyeOffIcon className='h-5 w-5' />}
                                      <ContextMenu
                                        menuItems={[
                                          {
                                            key: 'move-up',
                                            label: t('move-up'),
                                            icon: UpIcon,
                                            callback: () =>
                                              handleChangeMaterialOrder(
                                                materialId,
                                                programId || '',
                                                item.groupId,
                                                'up',
                                              ),
                                          },
                                          {
                                            key: 'move-down',
                                            label: t('move-down'),
                                            icon: DownIcon,
                                            callback: () =>
                                              handleChangeMaterialOrder(
                                                materialId,
                                                programId || '',
                                                item.groupId,
                                                'down',
                                              ),
                                          },
                                          {
                                            key: 'edit',
                                            label: t('edit'),
                                            icon: EditIcon,
                                            callback: () =>
                                              handleOpenEditMaterialModal(
                                                materialId,
                                                title,
                                                description,
                                                type,
                                                item.groupId,
                                              ),
                                          },
                                          {
                                            key: 'hide',
                                            label: materialHidden ? t('unhide') : t('hide'),
                                            icon: materialHidden ? EyeIcon : EyeOffIcon,
                                            callback: () =>
                                              handleHideMaterial(materialHidden, materialId),
                                          },
                                          {
                                            key: 'delete',
                                            label: t('delete'),
                                            icon: DeleteIcon,
                                            callback: () =>
                                              handleOpenMaterialDeleteConfirmation(materialId),
                                          },
                                        ]}
                                      />
                                    </div>
                                  )}
                                </div>
                              )}
                            </div>
                          ),
                        )}
                      </Disclosure.Panel>
                    </div>
                  )}
                </Disclosure>
              );
            } else if (item.materialType === 'material') {
              return (
                <div
                  className={`neutral-button w-full rounded-2xl shadow shadow-neutral-300 hover:bg-secondary-200 ${
                    item.hidden ? 'text-neutral-400' : 'text-neutral-500'
                  } `}
                >
                  {item.type === 'url' && (
                    <div
                      key={item.materialId}
                      className='flex items-center justify-between gap-4 px-4 py-3'
                    >
                      <a
                        href={item.url}
                        target='_blank'
                        rel='noreferrer'
                        className='flex items-center gap-4'
                      >
                        <LinkIcon className='h-5 w-5 flex-shrink-0' />
                        <div>
                          <BodyText as='p' variant='base' className='mr-auto font-medium'>
                            {item.title}
                          </BodyText>
                          <BodyText as='p' variant='xs' className='mr-auto font-medium'>
                            {item.description}
                          </BodyText>
                        </div>
                      </a>
                      {program.canManage && (
                        <div className='flex items-center gap-4'>
                          {item.hidden && <EyeOffIcon className='h-5 w-5' />}
                          <ContextMenu
                            menuItems={[
                              {
                                key: 'move-up',
                                label: t('move-up'),
                                icon: UpIcon,
                                callback: () =>
                                  handleChangeOverallOrder(
                                    item.materialId,
                                    programId || '',
                                    'up',
                                    'material',
                                  ),
                              },
                              {
                                key: 'move-down',
                                label: t('move-down'),
                                icon: DownIcon,
                                callback: () =>
                                  handleChangeOverallOrder(
                                    item.materialId,
                                    programId || '',
                                    'down',
                                    'material',
                                  ),
                              },
                              {
                                key: 'edit',
                                label: t('edit'),
                                icon: EditIcon,
                                callback: () =>
                                  handleOpenEditMaterialModal(
                                    item.materialId,
                                    item.title || '',
                                    item.description || '',
                                    item.type,
                                    'no_category',
                                  ),
                              },
                              {
                                key: 'hide',
                                label: item.hidden ? t('unhide') : t('hide'),
                                icon: item.hidden ? EyeIcon : EyeOffIcon,
                                callback: () => handleHideMaterial(item.hidden, item.materialId),
                              },
                              {
                                key: 'delete',
                                label: t('delete'),
                                icon: DeleteIcon,
                                callback: () =>
                                  handleOpenMaterialDeleteConfirmation(item.materialId),
                              },
                            ]}
                          />
                        </div>
                      )}
                    </div>
                  )}
                  {item.type === 'file' && (
                    <div
                      key={item.materialId}
                      className='flex items-center justify-between gap-4 rounded-2xl px-4 py-3'
                    >
                      <button
                        onClick={() => {
                          if (isViewableFile(item.file.directory)) {
                            setFileUrl(item.file.directory);
                            setUploaderName(
                              item.uploader?.firstName + ' ' + item.uploader?.lastName,
                            );
                            setUploadedBy(item.uploader?.id);
                            setOriginId(item.materialId);
                            setShowResourceViewer(true);
                          } else {
                            window.location.href = item.file.directory;
                          }
                        }}
                        className='flex w-full items-center gap-4 text-left'
                      >
                        <MaterialIcon
                          fileInput={item.file.directory + ''}
                          className='h-5 w-5 flex-shrink-0'
                        />
                        <div>
                          <BodyText as='p' variant='base' className='mr-auto font-medium'>
                            {item.title}
                          </BodyText>
                          <BodyText as='p' variant='xs' className='mr-auto font-medium'>
                            {item.description}
                          </BodyText>
                        </div>
                      </button>

                      <div className='flex items-center gap-4'>
                        <a
                          href={item.file.directory}
                          target='_blank'
                          rel='noreferrer'
                          className='flex items-center gap-4'
                        >
                          <button className='neutral-button flex h-6 w-6 items-center justify-center rounded-full hover:text-functional-info-dark'>
                            <DownloadIcon className='h-5 w-5' />
                          </button>
                        </a>
                        {program.canManage && (
                          <div className='flex items-center gap-4'>
                            {item.hidden && <EyeOffIcon className='h-5 w-5' />}
                            <ContextMenu
                              menuItems={[
                                {
                                  key: 'move-up',
                                  label: t('move-up'),
                                  icon: UpIcon,
                                  callback: () =>
                                    handleChangeOverallOrder(
                                      item.materialId,
                                      programId || '',
                                      'up',
                                      'material',
                                    ),
                                },
                                {
                                  key: 'move-down',
                                  label: t('move-down'),
                                  icon: DownIcon,
                                  callback: () =>
                                    handleChangeOverallOrder(
                                      item.materialId,
                                      programId || '',
                                      'down',
                                      'material',
                                    ),
                                },
                                {
                                  key: 'edit',
                                  label: t('edit'),
                                  icon: EditIcon,
                                  callback: () =>
                                    handleOpenEditMaterialModal(
                                      item.materialId,
                                      item.title || '',
                                      item.description || '',
                                      item.type,
                                      'no_category',
                                    ),
                                },
                                {
                                  key: 'hide',
                                  label: item.hidden ? t('unhide') : t('hide'),
                                  icon: item.hidden ? EyeIcon : EyeOffIcon,
                                  callback: () => handleHideMaterial(item.hidden, item.materialId),
                                },
                                {
                                  key: 'delete',
                                  label: t('delete'),
                                  icon: DeleteIcon,
                                  callback: () =>
                                    handleOpenMaterialDeleteConfirmation(item.materialId),
                                },
                              ]}
                            />
                          </div>
                        )}
                      </div>
                    </div>
                  )}
                  {item.type === 'page' && (
                    <div
                      key={item.materialId}
                      className='flex items-center justify-between rounded-2xl px-4 py-3'
                    >
                      <button
                        onClick={() => {
                          setFileUrl('');
                          setUploaderName(item.uploader?.firstName + ' ' + item.uploader?.lastName);
                          setUploadedBy(item.uploader?.id);
                          setOriginId(item.page.id);
                          setOriginType('page');
                          setShowResourceViewer(true);
                        }}
                        className='flex items-center gap-4 text-left'
                      >
                        <NoteIcon className='h-5 w-5 flex-shrink-0' />
                        <div>
                          <BodyText as='p' variant='base' className='mr-auto font-medium'>
                            {item.page.title}
                          </BodyText>
                          <BodyText as='p' variant='xs' className='mr-auto font-medium'>
                            {item.description}
                          </BodyText>
                        </div>
                      </button>
                      {program.canManage && (
                        <div className='flex items-center gap-4'>
                          {item.hidden && <EyeOffIcon className='h-5 w-5' />}
                          <ContextMenu
                            menuItems={[
                              {
                                key: 'move-up',
                                label: t('move-up'),
                                icon: UpIcon,
                                callback: () =>
                                  handleChangeOverallOrder(
                                    item.materialId,
                                    programId || '',
                                    'up',
                                    'material',
                                  ),
                              },
                              {
                                key: 'move-down',
                                label: t('move-down'),
                                icon: DownIcon,
                                callback: () =>
                                  handleChangeOverallOrder(
                                    item.materialId,
                                    programId || '',
                                    'down',
                                    'material',
                                  ),
                              },
                              {
                                key: 'edit',
                                label: t('edit'),
                                icon: EditIcon,
                                callback: () =>
                                  handleOpenEditMaterialModal(
                                    item.materialId,
                                    item.title || '',
                                    item.description || '',
                                    item.type,
                                    'no_category',
                                  ),
                              },
                              {
                                key: 'hide',
                                label: item.hidden ? t('unhide') : t('hide'),
                                icon: item.hidden ? EyeIcon : EyeOffIcon,
                                callback: () => handleHideMaterial(item.hidden, item.materialId),
                              },
                              {
                                key: 'delete',
                                label: t('delete'),
                                icon: DeleteIcon,
                                callback: () =>
                                  handleOpenMaterialDeleteConfirmation(item.materialId),
                              },
                            ]}
                          />
                        </div>
                      )}
                    </div>
                  )}
                </div>
              );
            } else {
              return null;
            }
          })}
      </div>

      {showGroupModal && (
        <MaterialGroupModal
          programId={program.id}
          groupName={groupName}
          isOpen={showGroupModal}
          groupId={selectedGroupId}
          onClose={handleCloseGroupModal}
        />
      )}

      {showDeleteConfirmation && (
        <ConfirmationModal
          isOpen={showDeleteConfirmation}
          onClose={handleCloseDeleteConfirmation}
          onConfirm={handleDeleteGroup}
          label={t('delete-resource-category')}
          title={t('delete-confirmation-modal-title')}
        />
      )}

      {showDeleteMaterialConfirmation && (
        <ConfirmationModal
          isOpen={showDeleteMaterialConfirmation}
          onClose={handleCloseMaterialDeleteConfirmation}
          onConfirm={handleDeleteMaterial}
          label={t('delete-resource')}
          title={t('delete-confirmation-modal-title')}
        />
      )}

      {showEditMaterialModal && (
        <EditSingleMaterialModal
          isOpen={showEditMaterialModal}
          materialId={selectedMaterialId || ''}
          title={selectedMaterialTitle || ''}
          description={selectedMaterialDesc || ''}
          type={selectedMaterialType || ''}
          programId={program.id}
          groupId={selectedGroupId || ''}
          refetch={refetchMaterials}
          onClose={() => setShowEditMaterialModal(false)}
        />
      )}

      {showAddMaterialModal && (
        <AddMaterialModal
          programId={program.id}
          isOpen={showAddMaterialModal}
          onClose={() => setShowAddMaterialModal(false)}
        />
      )}
      {(isViewableFile(fileUrl) || originType === 'page') && (
        <ResourceViewerDrawer
          isOpen={showResourceViewer}
          setIsOpen={setShowResourceViewer}
          fileUrl={fileUrl}
          uploaderId={uploadedBy}
          uploadedBy={uploaderName}
          originId={originId}
          originType={originType}
        />
      )}
    </div>
  );
};

export default ProgramMaterials;
