import { Switch } from '@headlessui/react';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import ModalContainer from './ModalContainer';
import BodyText from 'components/typography/BodyText';
import Button from 'components/button/Button';
import { generateGuestAccessLink } from 'utils/url';
import { updatePageAcess } from 'utils/api/pages';
import { useSingelPage } from 'hooks/api/pages/usePages';
import { notifySuccess, notifyTranslatedError } from 'utils/notify';

interface IPageGuestAccessModal {
  isOpen: boolean;
  onClose: () => void;
  pageId: string;
  allowGuest?: boolean;
  guestViewCount?: number;
}

const PageGuestAccessModal = ({
  isOpen,
  pageId,
  allowGuest,
  guestViewCount,
  onClose,
}: IPageGuestAccessModal) => {
  const { t } = useTranslation();
  const inviteUrl = generateGuestAccessLink(pageId, 'pages');
  const iframeLink = `<iframe src="${inviteUrl}" name="SCIFrame" width="100%" height="600px" style="border: solid #808080; border-radius: 10px;"></iframe>`;
  const { data: page, isLoading, refetch } = useSingelPage(pageId.trim());
  const [copiedState, setCopiedState] = useState<Record<string, boolean>>({});
  const copyToClipboard = (text: string) => {
    setCopiedState({ ...copiedState, [text]: true });
    navigator.clipboard.writeText(text);
    notifySuccess(t('invite-copied'));
  };

  const handleUpdateAccess = (enabled: boolean) => {
    const body = { allowGuest: enabled };
    updatePageAcess(pageId, body)
      .then(() => {
        refetch();
      })
      .catch((error) => {
        notifyTranslatedError(t, error.response.data?.errorCode);
      });
  };

  return (
    <ModalContainer isOpen={isOpen} onClose={onClose}>
      <div className='flex items-center justify-between p-3'>
        <BodyText variant='base' className='font-medium'>
          {t('allow-guest-access')}
        </BodyText>
        <Switch
          checked={!!page?.allowGuest}
          onChange={(value: boolean) => handleUpdateAccess(value)}
          className={`${
            page?.allowGuest ? 'bg-functional-success-dark' : 'bg-functional-danger-dark'
          } relative inline-flex h-5 w-[44px] items-center rounded-full`}
        >
          <span
            className={`${
              page?.allowGuest
                ? 'translate-x-[3px] bg-functional-success-light'
                : 'translate-x-[21px] bg-functional-danger-light'
            } inline-block h-[20px] w-[20px] transform rounded-full transition`}
          />
        </Switch>
      </div>

      {allowGuest ? (
        <div>
          <table className='w-full table-auto items-center justify-center '>
            <thead>
              <tr>
                <th className='min-w-[100px] border-b px-4 py-2'>{t('url')}</th>
                <th className='border-b px-4 py-2'></th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className='px-4 py-2'>
                  <div className='flex flex-col '>
                    <BodyText variant='sm' className='font-bold text-secondary-500'>
                      <a href={inviteUrl} target='_blank' rel='noopener noreferrer'>
                        {t('invitation-link')}
                      </a>
                    </BodyText>
                  </div>
                </td>
                <td className=' px-4 py-2'>
                  <div className='flex justify-end'>
                    <Button
                      size='small'
                      variant='primary'
                      onClick={() => copyToClipboard(inviteUrl)}
                    >
                      {copiedState[inviteUrl] ? t('copied') : t('copy')}
                    </Button>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
          <table className='mt-4 w-full table-auto items-center justify-center '>
            <thead>
              <tr>
                <th className='min-w-[100px] border-b px-4 py-2'>{t('embed_iframe')}</th>
                <th className='border-b px-4 py-2'></th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className='px-4 py-2'>
                  <div className='flex flex-col '>
                    <BodyText variant='sm' className='font-bold text-secondary-500'>
                      <a href={iframeLink} target='_blank' rel='noopener noreferrer'>
                        iFrame Link
                      </a>
                    </BodyText>
                  </div>
                </td>
                <td className=' px-4 py-2'>
                  <div className='flex justify-end'>
                    <Button
                      size='small'
                      variant='primary'
                      onClick={() => copyToClipboard(iframeLink)}
                    >
                      {copiedState[iframeLink] ? t('copied') : t('copy')}
                    </Button>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      ) : (
        <div className='flex flex-col items-center justify-center'>
          <BodyText variant='base' className=' text-center  text-neutral-500'>
            {t('guest-access-disabled')}
          </BodyText>
        </div>
      )}
      <div className='mt-5 flex justify-end gap-3 border-t border-neutral-200 pt-5'>
        <Button type='button' size='small' variant='secondary' onClick={onClose}>
          {t('close')}
        </Button>
      </div>
    </ModalContainer>
  );
};

export default PageGuestAccessModal;
