import GuestLayOut from 'components/layout/GuestLayout';
import { Tab } from '@headlessui/react';
import GuestDetailsBanner from 'pages/segment/guest/GuestDetailsBanner';
import { guestTabItems } from 'config/tabs';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useSingleGuestCommunity, useSingleGuestProgram } from 'hooks/api/guest/guest';
import { generateGuestOriginLink, generateGuestDiscoverLink } from 'utils/url';
import { Outlet, useParams } from 'react-router';
import Loading from 'components/Loading';
import NavigateTo404 from 'components/NavigateTo404';

const GuestLandingPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { originType, originId } = useParams();
  const { data: program, isLoading: isProgramLoading } =
    originType === 'program' && originId
      ? useSingleGuestProgram(originId)
      : { data: null, isLoading: false };
  const { data: community, isLoading: isCommunityLoading } =
    originType === 'community' && originId
      ? useSingleGuestCommunity(originId)
      : { data: null, isLoading: false };
  const entity = originType === 'program' ? program : community;
  const tabName = location.pathname.split('/').pop();
  const currentTabIndexFromRouter = guestTabItems.findIndex((item) => item.path === tabName);

  if (isCommunityLoading || isProgramLoading) return <Loading />;
  if (!entity) return <NavigateTo404 />;

  return (
    <>
      <GuestLayOut
        originName={entity.name}
        originLink={`${originType}/${originId}`}
        orgName={entity.organization.name}
        orgId={entity.organization.id}
      >
        <div>
          <GuestDetailsBanner
            id={entity.id}
            variant={originType || ''}
            name={entity.name}
            cover={entity.cover}
            archived={entity.archived}
            discoverLink={generateGuestDiscoverLink(entity.id, originType || '')}
            archiveDate={entity.archiveDate}
            organizationName={entity.organization.name}
            organizationLogo={entity.organization.logo}
          />
          <Tab.Group
            onChange={(value) => {
              navigate(
                `${generateGuestOriginLink(entity.id, originType || '')}/${
                  guestTabItems[value].path
                }`,
              );
            }}
            selectedIndex={tabName ? currentTabIndexFromRouter : 0}
          >
            <Tab.List
              className={`tab-list-parent sticky top-[0px] z-20 mb-5  shrink-0 overflow-x-auto overflow-y-hidden border-t-2 bg-secondary-100  pt-2`}
            >
              {guestTabItems.map(({ label }) => (
                <Tab key={label} className='shrink-0 outline-0'>
                  {({ selected }) => (
                    <div
                      className={`hover:text-bold border-b-2 bg-secondary-100 text-neutral-400 hover:text-secondary-500 active:text-secondary-400 ${
                        selected
                          ? 'active-tab text-bold text-secondary-400'
                          : 'border-neutral-300 text-neutral-300'
                      } px-3 py-2 font-medium`}
                    >
                      {t(label)}
                    </div>
                  )}
                </Tab>
              ))}
            </Tab.List>

            <Tab.Panels>
              {guestTabItems.map(({ path }) => (
                <Tab.Panel key={path} className='outline-none'>
                  <Outlet />
                </Tab.Panel>
              ))}
            </Tab.Panels>
          </Tab.Group>
        </div>
      </GuestLayOut>
    </>
  );
};

export default GuestLandingPage;
