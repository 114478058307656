import Layout from 'components/layout/Layout';
import Heading from 'components/typography/Heading';
import React from 'react';
import { useTranslation } from 'react-i18next';
import NotificationComponent from 'components/notification/NotificationComponent';

const NotificationDrawer = () => {
  const { t } = useTranslation();

  return (
    <Layout>
      <div className='h-full w-full pb-7'>
        <div className='flex items-center justify-between border-b border-neutral-300 bg-secondary-100 p-2'>
          <Heading variant='h3' className='font-semibold text-secondary-500'>
            {t('notifications')}
          </Heading>
        </div>

        <NotificationComponent />
      </div>
    </Layout>
  );
};

export default NotificationDrawer;
