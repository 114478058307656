import { useInfiniteQuery } from '@tanstack/react-query';
import { getArchivedCommunities } from 'utils/api/community';

export const useArchivedCommunities = () => {
  const { data, hasNextPage, fetchNextPage, refetch, isFetchingNextPage, isLoading } =
    useInfiniteQuery({
      queryKey: ['archived-communities'],
      queryFn: ({ pageParam = '' }) => getArchivedCommunities(5, pageParam),
      getNextPageParam: (lastPage) => lastPage.data.cursor,
    });

  return {
    data: data?.pages.flatMap((item) => item.data.communities) ?? [],
    hasNextArchivedCommunities: hasNextPage,
    fetchNextArchivedCommunities: fetchNextPage,
    refetchArchivedCommunities: refetch,
    isFetchingNextArchivedCommunites: isFetchingNextPage,
    isArchivedCommunitiesLoading: isLoading,
  };
};
