import React from 'react';
import SuccessMessageIcon from 'assets/icons/success.svg';
import { Link } from 'react-router-dom';
import Heading from 'components/typography/Heading';
import BodyText from 'components/typography/BodyText';
import Button from 'components/button/Button';
import ResetPassCard from './ResetPassCard';

type ISuccessCard = {
  title: string;
  subtext: string;
  hasCta?: boolean;
  ctaText?: string;
  ctaLink?: string;
};

function SuccessCard({ hasCta, ctaLink, ctaText, title, subtext }: ISuccessCard) {
  return (
    <ResetPassCard className='flex flex-col items-center justify-center'>
      <SuccessMessageIcon className='h-[100px] mb-4' />
      <Heading variant='h2' className='font-semibold text-secondary-500 mb-4'>
        {title}
      </Heading>
      <BodyText variant='sm' className='text-center text-neutral-500 mb-6'>
        {subtext}
      </BodyText>
      {hasCta && ctaLink && ctaText && (
        <Link to={ctaLink} className='w-full'>
          <Button size='default' variant='secondary' className='w-full'>
            {ctaText}
          </Button>
        </Link>
      )}
    </ResetPassCard>
  );
}

export default SuccessCard;
