import Button from 'components/button/Button';
import TextInput from 'components/form/TextInput';
import BodyText from 'components/typography/BodyText';
import { createPage } from 'utils/api/pages';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { notifySuccess, notifyTranslatedError } from 'utils/notify';
import ModalContainer from './ModalContainer';

interface ICreatePageModal {
  isOpen: boolean;
  onClose: () => void;
  refetch: () => void;
}

const CreatePageModal = ({ isOpen, onClose, refetch }: ICreatePageModal) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [title, setTitle] = useState('');
  const createPageHandler = async () => {
    if (title.trim().length < 1) {
      notifyTranslatedError(t, 'Title must be at least 1 character long');
      return;
    }
    createPage(title)
      .then((response) => {
        refetch();
        navigate(`/pages/${response.data.pageId}`);
        notifySuccess(t('page-created'));
      })
      .catch((error) => {
        notifyTranslatedError(t, error.response.data?.message);
        notifyTranslatedError(t, error.response.data?.errorCode);
      });
  };

  return (
    <ModalContainer title={t('create-page')} isOpen={isOpen} onClose={onClose}>
      <div className='space-y-5'>
        <div className='space-y-1'>
          <BodyText variant='sm' className='font-medium text-neutral-500'>
            {t('title')}
          </BodyText>
          <TextInput
            type='text'
            placeholder={t('write-message-placeholder')}
            value={title}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setTitle(e.target.value)}
          />
        </div>
        <div className='mt-5 flex items-center justify-end gap-3 border-t border-neutral-200 pt-5'>
          <Button type='button' size='small' variant='outlined' onClick={onClose}>
            {t('cancel')}
          </Button>
          <Button size='small' variant='secondary' onClick={createPageHandler}>
            {t('create-page')}
          </Button>
        </div>
      </div>
    </ModalContainer>
  );
};

export default CreatePageModal;
