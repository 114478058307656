import ClockIcon from 'assets/icons/clock.svg';
import { Disclosure } from '@headlessui/react';
import BodyText from 'components/typography/BodyText';
import ChevronRightIcon from 'assets/icons/chevron-right.svg';
import dayjs from 'dayjs';
import { useCommunityEvents } from 'hooks/api/event/useCommunityEvents';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { generateEventLink } from 'utils/url';
import { Link } from 'react-router-dom';

interface IUpcomingEvents {
  originId: string;
}

const UpcomingEvents = ({ originId }: IUpcomingEvents) => {
  const { t } = useTranslation();

  const { events } = useCommunityEvents(originId, 3, true);

  if (!events.length) return null;
  return (
    <div className='rounded-2xl bg-neutral-200 p-2 shadow shadow-neutral-300'>
      <Disclosure defaultOpen={true}>
        {({ open }) => (
          <>
            <Disclosure.Button className='flex w-full items-center '>
              <ChevronRightIcon className={`h-5 w-5  ${open ? 'rotate-90 transform' : ''} `} />
              <BodyText variant='base' className='font-semibold text-secondary-500'>
                {t('upcoming_events')}
              </BodyText>
            </Disclosure.Button>
            <Disclosure.Panel>
              <div className='px-4 mt-2'>
                {events.map((event) => {
                  const { id: eventId, startTime, title } = event;

                  return (
                    <Link to={generateEventLink('community', originId, eventId)} key={eventId}>
                      <button className='rounded-lg neutral-button w-full text-left p-1'>
                        <BodyText variant='sm' className='font-medium'>
                          {title}
                        </BodyText>
                        <BodyText variant='xs' className='mt-1 flex items-center gap-2'>
                          <ClockIcon className='h-4 w-4' />
                          {t('starts_in')} {dayjs(startTime).fromNow()}
                        </BodyText>
                      </button>
                    </Link>
                  );
                })}
              </div>
            </Disclosure.Panel>
          </>
        )}
      </Disclosure>
    </div>
  );
};

export default UpcomingEvents;
