export type ThemeColors = {
  [colorName: string]: string;
};

const neutralColorsLight: ThemeColors = {
  '--color-neutral-100': '#FFFFFF',
  '--color-neutral-200': '#fafafa',
  '--color-neutral-300': '#dddddd',
  '--color-neutral-400': '#5d5d5d',
  '--color-neutral-500': '#222529',
  '--color-neutral-600': '#121212',
};

const neutralColorsDark: ThemeColors = {
  '--color-neutral-100': '#121212',
  '--color-neutral-200': '#222529',
  '--color-neutral-300': '#5d5d5d',
  '--color-neutral-400': '#dddddd',
  '--color-neutral-500': '#fafafa',
  '--color-neutral-600': '#FFFFFF',
};

const functionalColorsLight: ThemeColors = {
  '--color-info-light': '#DBE9FF',
  '--color-info-dark': '#1776F2',
  '--color-info-400': '#0099FF',
  '--color-warning-light': '#FFF9E7',
  '--color-warning-dark': '#F6B100',
  '--color-success-light': '#EAF7ED',
  '--color-success-dark': '#12B76A',
  '--color-success-400': '#00cc00',
  '--color-danger-light': '#FCEBED',
  '--color-danger-dark': '#DC3545',
  '--color-danger-400': '#ff3333',
};

const functionalColorsDark: ThemeColors = {
  '--color-info-light': '#1776F2',
  '--color-info-dark': '#DBE9FF',
  '--color-info-400': '#0099FF',
  '--color-warning-light': '#F6B100',
  '--color-warning-dark': '#FFF9E7',
  '--color-success-light': '#12B76A',
  '--color-success-dark': '#EAF7ED',
  '--color-success-400': '#00cc00',
  '--color-danger-light': '#DC3545',
  '--color-danger-dark': '#FCEBED',
  '--color-danger-400': '#ff3333',
};

const defaultPrimaryColorLight: ThemeColors = {
  '--color-primary-100': '#FFFEFA',
  '--color-primary-200': '#FFF3C4',
  '--color-primary-300': '#FFE686',
  '--color-primary-400': '#FFCD0D',
  '--color-primary-500': '#C89F00',
  '--color-primary-600': '#3B3300',
};
const defaultSecondaryColorLight: ThemeColors = {
  '--color-secondary-100': '#F2F4F8',
  '--color-secondary-200': '#CCD6E4',
  '--color-secondary-300': '#A2B3CE',
  '--color-secondary-400': '#4887E8',
  '--color-secondary-500': '#003079',
  '--color-secondary-600': '#071B3B',
};
const defaultPrimaryColorDark: ThemeColors = {
  '--color-primary-100': '#3B3300' /* Deepest gold */,
  '--color-primary-200': '#C89F00' /* Deep gold */,
  '--color-primary-300': '#FFCD0D' /* Darker gold */,
  '--color-primary-400': '#FFE686' /* Dark gold */,
  '--color-primary-500': '#FFF3C4' /* Dark mustard */,
  '--color-primary-600': '#FFFEFA' /* Dark beige */,
};
const defaultSecondaryColorDark: ThemeColors = {
  '--color-secondary-100': '#071B3B' /* Deepest blue */,
  '--color-secondary-200': '#003079' /* Darker blue */,
  '--color-secondary-300': '#4887E8' /* Dark blue */,
  '--color-secondary-400': '#A2B3CE' /* Even darker grey */,
  '--color-secondary-500': '#CCD6E4' /* Darker grey */,
  '--color-secondary-600': '#F2F4F8' /* Dark grey */,
};
const tuBerlinPrimaryColorLight: ThemeColors = {
  '--color-primary-100': '#f0eded',
  '--color-primary-200': '#c4bebe',
  '--color-primary-300': '#9c9595',
  '--color-primary-400': '#706c6c',
  '--color-primary-500': '#1a1a1a',
  '--color-primary-600': '#000000',
};
const tuBerlinSecondaryColorLight: ThemeColors = {
  '--color-secondary-100': '#f8d5d7',
  '--color-secondary-200': '#e7adb1',
  '--color-secondary-300': '#d6858b',
  '--color-secondary-400': '#c55d65',
  '--color-secondary-500': '#c50e1f',
  '--color-secondary-600': '#470107',
};
const tuBerlinPrimaryColorDark: ThemeColors = {
  '--color-primary-100': '#1a1a1a' /* Almost black */,
  '--color-primary-200': '#706c6c' /* Dark grey */,
  '--color-primary-300': '#9c9595' /* Medium grey */,
  '--color-primary-400': '#c4bebe' /* Light grey */,
  '--color-primary-500': '#f0eded' /* Very light grey */,
  '--color-primary-600': '#ffffff' /* White */,
};
const tuBerlinSecondaryColorDark: ThemeColors = {
  '--color-secondary-100': '#470107' /* Deepest red */,
  '--color-secondary-200': '#c50e1f' /* Dark red */,
  '--color-secondary-300': '#c55d65' /* Muted red */,
  '--color-secondary-400': '#d6858b' /* Lighter red */,
  '--color-secondary-500': '#e7adb1' /* Soft red */,
  '--color-secondary-600': '#f8d5d7' /* Very soft red */,
};

const konstanzPrimaryColorLight: ThemeColors = {
  '--color-primary-100': '#c0dfd3',
  '--color-primary-200': '#8ed5d2',
  '--color-primary-300': '#00e9da',
  '--color-primary-400': '#00c2b6',
  '--color-primary-500': '#009b91',
  '--color-primary-600': '#03464c',
};

const konstanzPrimaryColorDark: ThemeColors = {
  '--color-primary-100': '#03464c',
  '--color-primary-200': '#009b91',
  '--color-primary-300': '#00c2b6',
  '--color-primary-400': '#00e9da',
  '--color-primary-500': '#8ed5d2',
  '--color-primary-600': '#c0dfd3',
};
const konstanzSecondaryColorLight: ThemeColors = {
  '--color-secondary-100': '#f3f7f9',
  '--color-secondary-200': '#dde6ec',
  '--color-secondary-300': '#a5c1d2',
  '--color-secondary-400': '#475b72',
  '--color-secondary-500': '#334152',
  '--color-secondary-600': '#1c242e',
};
const konstanzSecondaryColorDark: ThemeColors = {
  '--color-secondary-100': '#1c242e',
  '--color-secondary-200': '#334152',
  '--color-secondary-300': '#475b72',
  '--color-secondary-400': '#a5c1d2',
  '--color-secondary-500': '#dde6ec',
  '--color-secondary-600': '#f3f7f9',
};
export const getThemeByOrganizationId = (
  organizationId: string,
  darkMode: boolean,
): ThemeColors => {
  // Check the organization ID and dark mode to determine which theme to use
  if (organizationId === 'clfcmyfb90217uut0cinyulvp') {
    // tuBerlin theme
    return darkMode
      ? {
          ...tuBerlinPrimaryColorDark,
          ...tuBerlinSecondaryColorDark,
          ...neutralColorsDark,
          ...functionalColorsDark,
        }
      : {
          ...tuBerlinPrimaryColorLight,
          ...tuBerlinSecondaryColorLight,
          ...neutralColorsLight,
          ...functionalColorsLight,
        };
  } else if (organizationId === 'clfcmye5v020kuut0iopzgxzr') {
    // konstanz theme
    return darkMode
      ? {
          ...konstanzPrimaryColorDark,
          ...konstanzSecondaryColorDark,
          ...neutralColorsDark,
          ...functionalColorsDark,
        }
      : {
          ...konstanzPrimaryColorLight,
          ...konstanzSecondaryColorLight,
          ...neutralColorsLight,
          ...functionalColorsLight,
        };
  } else {
    // Default theme
    return darkMode
      ? {
          ...defaultPrimaryColorDark,
          ...defaultSecondaryColorDark,
          ...neutralColorsDark,
          ...functionalColorsDark,
        }
      : {
          ...defaultPrimaryColorLight,
          ...defaultSecondaryColorLight,
          ...neutralColorsLight,
          ...functionalColorsLight,
        };
  }
};
