import AddIcon from 'assets/icons/add.svg';
import DeleteIcon from 'assets/icons/delete.svg';
import NoteIcon from 'assets/icons/note-filled.svg';
import EditIcon from 'assets/icons/edit.svg';
import Button from 'components/button/Button';
import DownloadIcon from 'assets/icons/download.svg';
import BodyText from 'components/typography/BodyText';
import { Link } from 'react-router-dom';
import dayjs from 'dayjs';
import React, { useState } from 'react';
import { SingleSubmissionType, SingleUserDetailsType } from 'types/apis/common';
import { bytesToMegaBytes } from 'utils/helper';
import Tag from 'components/tag/Tag';
import { useTranslation } from 'react-i18next';
import RichTextModal from 'components/modal/RichTextModal';
import { generateUserProfileLink, generateSinglePageLink } from 'utils/url';
import RichTextEditor from 'components/common/RichTextEditorWithUpload';
import ResourceViewerDrawer from 'components/drawer/ResourceViewerDrawer';
import { isViewableFile } from 'utils/helper';
import MemberButton from 'components/memberAndProfile/MemberButton';

interface IManagerSubmissionViewRow {
  currentUserId?: string | undefined;
  isGroup?: boolean | undefined;
  userDetails: SingleUserDetailsType;
  taskDeadline: string | undefined | null;
  userSubmissions: SingleSubmissionType[];
  handleEditSubmission: (
    submissionId: string,
    submissionTitle: string,
    submissionNote?: string,
  ) => void;
  handleOpenSubmissionRemarks: (submissionId: string) => void;
  handleDeleteSubmission: (submissionId: string) => void;
}

const ManagerSubmissionViewRow = ({
  currentUserId,
  isGroup,
  userDetails,
  taskDeadline,
  userSubmissions,
  handleDeleteSubmission,
  handleEditSubmission,
  handleOpenSubmissionRemarks,
}: IManagerSubmissionViewRow) => {
  const { t } = useTranslation();
  const [expandRows, setExpandRows] = useState(false);
  const [isRichTextModalOpen, setIsRichTextModalOpen] = useState(false);
  const [content, setContent] = useState('');
  const [showResourceViewer, setShowResourceViewer] = useState(false);
  const [fileUrl, setFileUrl] = useState('');
  const [uploaderName, setUploaderName] = useState('');
  const [uploadedBy, setUploadedBy] = useState('');
  const [originId, setOriginId] = useState('');
  const [originType, setOriginType] = useState('');

  function isValidJSON(str = '') {
    try {
      JSON.parse(str);
      return true;
    } catch (e) {
      return false;
    }
  }

  return (
    <>
      <tr className={`border-b-2 group ${expandRows ? 'bg-secondary-200' : 'border-neutral-200'}`}>
        <td>
          {userSubmissions[0].file?.fileName && userSubmissions[0].file?.fileSize && (
            <button
              className='hover:underline line-clamp-3 flex w-full max-w-[12rem] items-center break-all rounded-lg p-1 text-left text-neutral-500'
              onClick={() => {
                if (isViewableFile(userSubmissions[0].file?.directory || '')) {
                  setFileUrl(userSubmissions[0].file?.directory || '');
                  setOriginId(userSubmissions[0].id);
                  setUploaderName(userDetails?.firstName + ' ' + userDetails?.lastName);
                  setUploadedBy(userDetails?.id);
                  setOriginType('task_submission');
                  setShowResourceViewer(true);
                } else {
                  window.location.href = userSubmissions[0].file?.directory || '';
                }
              }}
            >
              <div>
                <BodyText variant='sm' className='font-medium'>
                  {userSubmissions[0].file.fileName}
                </BodyText>
                <BodyText variant='xs' className=''>
                  {bytesToMegaBytes(userSubmissions[0].file.fileSize)}
                </BodyText>
              </div>
            </button>
          )}
          {userSubmissions[0].page && userSubmissions[0].page.id && (
            <button
              className='hover:underline line-clamp-3 flex w-full max-w-[12rem] items-center break-all rounded-lg p-1 text-left text-neutral-500'
              onClick={() => {
                setFileUrl('');
                setOriginId(userSubmissions[0].page?.id || '');
                setUploaderName(userDetails?.firstName + ' ' + userDetails?.lastName);
                setUploadedBy(userDetails?.id);
                setOriginType('page');
                setShowResourceViewer(true);
              }}
            >
              <NoteIcon className='h-4 w-4 shrink-0' />
              <BodyText variant='sm' className='font-medium '>
                {userSubmissions[0].page.title}
              </BodyText>
            </button>
          )}
          {userSubmissions.length > 1 && (
            <button
              className='text-xs text-functional-info-dark'
              onClick={() => setExpandRows(!expandRows)}
            >
              {expandRows ? 'Show Less' : `Show ${userSubmissions.length - 1} more`}
            </button>
          )}
        </td>
        <td>
          <BodyText variant='sm' className='font-medium text-neutral-500'>
            {userSubmissions[0].title}
          </BodyText>
          {userSubmissions[0].note && (
            <button
              className='rounded-md bg-neutral-300 p-1 text-xs hover:bg-neutral-400 active:bg-neutral-500'
              onClick={() => {
                setIsRichTextModalOpen(true);
                setContent(userSubmissions[0].note);
              }}
            >
              <div>
                <div className='max-h-8 w-[12rem] overflow-hidden'>
                  <RichTextEditor
                    initialContent={
                      isValidJSON(userSubmissions[0].note)
                        ? JSON.stringify({
                            ...JSON.parse(userSubmissions[0].note),
                            blocks: [JSON.parse(userSubmissions[0].note).blocks[0]],
                          })
                        : userSubmissions[0].note
                    }
                    isReadOnly={true}
                  />
                </div>
                <BodyText variant='xs' className='text-left text-functional-info-400'>
                  {t('show_more')}...
                </BodyText>
              </div>
            </button>
          )}
        </td>
        <td>
          <div className='flex flex-col text-left'>
            <BodyText variant='sm' className='text-neutral-500'>
              {dayjs(userSubmissions[0].createdAt).format('ddd, D MMM YYYY, h:mm A')}
            </BodyText>
            {dayjs(taskDeadline).isBefore(dayjs(userSubmissions[0].createdAt)) && (
              <Tag
                type='default'
                className='w-10 bg-functional-danger-light capitalize text-functional-danger-dark'
              >
                {t('late')}
              </Tag>
            )}
          </div>
        </td>
        <td>
          <MemberButton
            userId={userDetails.id}
            isCreator={false}
            profilePicture={userDetails.profilePicture || ''}
            userName={userDetails.firstName + ' ' + userDetails.lastName}
            chat={false}
            size='small'
          />
        </td>
        <td>
          <div className='flex items-center'>
            {userSubmissions[0].remarks.length > 0 ? (
              <Button
                size='small'
                variant='outlined'
                className='group relative flex items-center justify-center rounded-full bg-neutral-100  text-secondary-400'
                onClick={() => handleOpenSubmissionRemarks(userSubmissions[0].id)}
              >
                <span className='text-sm font-semibold text-secondary-500'>
                  ({userSubmissions[0].remarks.length})
                </span>
                <span className='text-xs'> {t('comments')}</span>
              </Button>
            ) : (
              <Button
                size='small'
                variant='outlined'
                className='group relative flex items-center justify-center rounded-full bg-neutral-100  text-xs text-secondary-400'
                onClick={() => handleOpenSubmissionRemarks(userSubmissions[0].id)}
              >
                <AddIcon className='h-3 w-3' />
                {t('comment')}
              </Button>
            )}
          </div>
        </td>
        <td>
          <div className='flex items-center gap-2 lg:opacity-0 lg:group-hover:opacity-100'>
            {currentUserId === userSubmissions[0].userId && (
              <button
                className='flex h-6 w-6 items-center justify-center rounded-full hover:bg-neutral-300 active:bg-neutral-400'
                onClick={() =>
                  handleEditSubmission(
                    userSubmissions[0].id,
                    userSubmissions[0].title,
                    userSubmissions[0].note,
                  )
                }
              >
                <EditIcon className='h-5 w-5 text-neutral-400 hover:text-functional-info-dark active:text-functional-info-dark' />
              </button>
            )}
            {isGroup && currentUserId === userSubmissions[0].userId && (
              <button
                className='flex h-6 w-6 items-center justify-center rounded-full hover:bg-neutral-300 active:bg-neutral-400 '
                onClick={() => handleDeleteSubmission(userSubmissions[0].id)}
              >
                <DeleteIcon className='h-5 w-5 text-neutral-400 hover:text-functional-danger-dark active:text-functional-danger-dark' />
              </button>
            )}
            {!isGroup && (
              <button
                className='flex h-6 w-6 items-center justify-center rounded-full hover:bg-neutral-300 active:bg-neutral-400 '
                onClick={() => handleDeleteSubmission(userSubmissions[0].id)}
              >
                <DeleteIcon className='h-5 w-5 text-neutral-400 hover:text-functional-danger-dark active:text-functional-danger-dark' />
              </button>
            )}
            {userSubmissions[0].file?.directory &&
              userSubmissions[0].file?.directory.length > 0 && (
                <button
                  className='flex h-6 w-6 items-center justify-center rounded-full hover:bg-neutral-300 active:bg-neutral-400'
                  onClick={() => {
                    window.open(userSubmissions[0].file?.directory, '_blank');
                  }}
                >
                  <DownloadIcon className='h-5 w-5 text-neutral-400 hover:text-functional-info-dark' />
                </button>
              )}
          </div>
        </td>
      </tr>

      {expandRows &&
        userSubmissions.slice(1).map(({ id, file, title, note, page, remarks }) => (
          <tr
            key={id}
            className={`border-b-2 group ${expandRows ? 'bg-secondary-200' : 'border-neutral-200'}`}
          >
            <td>
              {file?.fileName && file?.fileSize && (
                <button
                  className='hover:underline line-clamp-3 flex w-full max-w-[12rem] items-center break-all rounded-lg p-1 text-left text-neutral-500'
                  onClick={() => {
                    if (isViewableFile(file?.directory || '')) {
                      setFileUrl(file?.directory || '');
                      setOriginId(id);
                      setOriginType('task_submission');
                      setUploaderName(userDetails?.firstName + ' ' + userDetails?.lastName);
                      setUploadedBy(userDetails?.id);
                      setShowResourceViewer(true);
                    } else {
                      window.location.href = userSubmissions[0].file?.directory || '';
                    }
                  }}
                >
                  <div>
                    <BodyText variant='sm' className='font-medium'>
                      {file.fileName}
                    </BodyText>
                    <BodyText variant='xs' className=''>
                      {bytesToMegaBytes(file.fileSize)}
                    </BodyText>
                  </div>
                </button>
              )}
              {page && page.id && (
                <button
                  className='hover:underline line-clamp-3 flex w-full max-w-[12rem] items-center break-all rounded-lg p-1 text-left text-neutral-500'
                  onClick={() => {
                    setFileUrl('');
                    setOriginId(page?.id || '');
                    setUploaderName(userDetails?.firstName + ' ' + userDetails?.lastName);
                    setUploadedBy(userDetails?.id);
                    setOriginType('page');
                    setShowResourceViewer(true);
                  }}
                >
                  <NoteIcon className='h-4 w-4 shrink-0' />
                  <BodyText variant='sm' className='font-medium '>
                    {page.title}
                  </BodyText>
                </button>
              )}
            </td>
            <td>
              <BodyText variant='sm' className='font-medium text-neutral-500'>
                {title}
              </BodyText>
              {note && (
                <button
                  className='rounded-md bg-neutral-300 p-1 text-xs hover:bg-neutral-400 active:bg-neutral-500'
                  onClick={() => {
                    setIsRichTextModalOpen(true);
                    setContent(note);
                  }}
                >
                  <div>
                    <div className='max-h-8 w-[12rem] overflow-hidden'>
                      <RichTextEditor
                        initialContent={
                          isValidJSON(note)
                            ? JSON.stringify({
                                ...JSON.parse(note),
                                blocks: [JSON.parse(note).blocks[0]],
                              })
                            : note
                        }
                        isReadOnly={true}
                      />
                    </div>
                    <BodyText variant='xs' className='text-left text-functional-info-400'>
                      {t('show_more')}...
                    </BodyText>
                  </div>
                </button>
              )}
            </td>
            <td>
              <div className='flex flex-col text-left'>
                <BodyText variant='sm' className='text-neutral-500'>
                  {dayjs(userSubmissions[0].createdAt).format('ddd, D MMM YYYY, h:mm A')}
                </BodyText>
                {dayjs(taskDeadline).isBefore(dayjs(userSubmissions[0].createdAt)) && (
                  <Tag
                    type='default'
                    className='w-10 bg-functional-danger-light capitalize text-functional-danger-dark'
                  >
                    {t('late')}
                  </Tag>
                )}
              </div>
            </td>
            <td>
              <MemberButton
                userId={userDetails.id}
                isCreator={false}
                profilePicture={userDetails.profilePicture || ''}
                userName={userDetails.firstName + ' ' + userDetails.lastName}
                chat={false}
                size='small'
              />
            </td>
            <td>
              <div className='flex items-center'>
                {remarks.length > 0 ? (
                  <Button
                    size='small'
                    variant='outlined'
                    className='group relative flex items-center justify-center rounded-full bg-neutral-100  text-secondary-400'
                    onClick={() => handleOpenSubmissionRemarks(id)}
                  >
                    <span className='text-sm font-semibold text-secondary-500'>
                      ({remarks.length})
                    </span>
                    <span className='text-xs'> {t('comments')}</span>
                  </Button>
                ) : (
                  <Button
                    size='small'
                    variant='outlined'
                    className='group relative flex items-center justify-center rounded-full bg-neutral-100  text-xs text-secondary-400'
                    onClick={() => handleOpenSubmissionRemarks(id)}
                  >
                    <AddIcon className='h-3 w-3' />
                    {t('comment')}
                  </Button>
                )}
              </div>
            </td>
            <td>
              <div className='flex items-center gap-3 lg:opacity-0 lg:group-hover:opacity-100'>
                {currentUserId === userSubmissions[0].userId && (
                  <button
                    className='flex h-6 w-6 items-center justify-center rounded-full hover:bg-neutral-300 active:bg-neutral-400'
                    onClick={() =>
                      handleEditSubmission(
                        userSubmissions[0].id,
                        userSubmissions[0].title,
                        userSubmissions[0].note,
                      )
                    }
                  >
                    <EditIcon className='h-5 w-5 text-neutral-400 hover:text-functional-info-dark active:text-functional-info-dark' />
                  </button>
                )}
                {isGroup && currentUserId === userSubmissions[0].userId && (
                  <button
                    className='flex h-6 w-6 items-center justify-center rounded-full hover:bg-neutral-300 active:bg-neutral-400 '
                    onClick={() => handleDeleteSubmission(userSubmissions[0].id)}
                  >
                    <DeleteIcon className='h-5 w-5 text-neutral-400 hover:text-functional-danger-dark active:text-functional-danger-dark' />
                  </button>
                )}
                {!isGroup && (
                  <button
                    className='flex h-6 w-6 items-center justify-center rounded-full hover:bg-neutral-300 active:bg-neutral-400 '
                    onClick={() => handleDeleteSubmission(userSubmissions[0].id)}
                  >
                    <DeleteIcon className='h-5 w-5 text-neutral-400 hover:text-functional-danger-dark active:text-functional-danger-dark' />
                  </button>
                )}
                {file?.directory && file?.directory.length > 0 && (
                  <button
                    className='flex h-6 w-6 items-center justify-center rounded-full hover:bg-neutral-300 active:bg-neutral-400'
                    onClick={() => {
                      window.open(file.directory, '_blank');
                    }}
                  >
                    <DownloadIcon className='h-5 w-5 text-neutral-400 hover:text-functional-info-dark active:text-functional-info-dark' />
                  </button>
                )}
              </div>
            </td>
          </tr>
        ))}
      <RichTextModal
        isOpen={isRichTextModalOpen}
        label={t('note')}
        description={content}
        onClose={() => setIsRichTextModalOpen(false)}
      />
      {(isViewableFile(fileUrl) || originType === 'page') && (
        <ResourceViewerDrawer
          isOpen={showResourceViewer}
          setIsOpen={setShowResourceViewer}
          fileUrl={fileUrl}
          uploaderId={uploadedBy}
          uploadedBy={uploaderName}
          originId={originId}
          originType={originType}
        />
      )}
    </>
  );
};

export default ManagerSubmissionViewRow;
