import Avatar from 'components/memberAndProfile/Avatar';
import BodyText from 'components/typography/BodyText';
import { userAvatar } from 'constants/common';
import React, { useEffect, useRef, useState } from 'react';

interface ISelectConversationRow {
  id: string;
  firstName: string;
  lastName: string;
  profilePicture: string | null;
  headline: string | null;
  onToggle: () => void;
  isSelected: boolean;
}

export const SelectConversationRow = ({
  id,
  firstName,
  lastName,
  profilePicture,
  headline,
  isSelected,
  onToggle,
}: ISelectConversationRow) => {
  return (
    <div
      onClick={onToggle}
      onKeyDown={(e) => {
        if (e.key === 'Enter' || e.key === ' ') {
          onToggle();
        }
      }}
      tabIndex={0}
      role='button'
      className='neutral-button flex items-center rounded-xl p-2'
    >
      <div className='mr-4 flex-shrink-0'>
        <input type='checkbox' checked={isSelected} onChange={onToggle} readOnly />
      </div>
      <div className='relative flex-shrink-0'>
        <Avatar size={38} src={profilePicture || userAvatar} />
      </div>
      <div className='ml-4 flex-1 overflow-hidden'>
        <BodyText as='div' variant='sm' className='truncate font-medium'>
          {firstName} {lastName}
        </BodyText>
        <BodyText as='div' variant='xs' className='truncate capitalize'>
          {headline}
        </BodyText>
      </div>
    </div>
  );
};
