import { useQuery } from '@tanstack/react-query';
import { getCommunitySubmissionRemarks } from 'utils/api/remark';

export const useCommunityRemarks = (communityId: string, submissionId: string, enabled = true) => {
  return useQuery({
    queryKey: ['community-remarks', communityId, submissionId],
    queryFn: () => getCommunitySubmissionRemarks(communityId, submissionId),
    select: (data) => data.data.remarks,
    enabled: !!communityId && !!submissionId && enabled,
  });
};
