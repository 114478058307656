import { useQuery } from '@tanstack/react-query';
import { getSingleProgramPost } from 'utils/api/post';

export const useProgramSinglePost = (programId: string, postId: string, enabled = true) => {
  return useQuery({
    queryKey: ['program-post', programId, postId],
    queryFn: () => getSingleProgramPost(programId, postId),
    select: (data) => data.data.post,
    enabled,
  });
};
