import { useQuery } from '@tanstack/react-query';
import { getSingleProgramTask } from 'utils/api/task';

export const useProgramTask = (programId: string, taskId: string) => {
  return useQuery({
    queryKey: ['program-task', programId, taskId],
    queryFn: () => getSingleProgramTask(programId, taskId),
    select: (data) => data.data,
    enabled: !!programId && !!taskId,
  });
};
