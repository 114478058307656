import PublishPost from 'components/common/PublishPost';
import { useSingleProgram } from 'hooks/api/program/useSingleProgram';
import React from 'react';
import { useParams } from 'react-router-dom';
import ProgramPosts from './ProgramPosts';
import ProgramSavedPosts from './ProgramSavedPosts';

const ProgramFeed = () => {
  const { programId } = useParams();

  const { data: program } = useSingleProgram(programId || '');

  if (!program) return null;

  return (
    <div className='relative mb-6 grid grid-cols-5 items-start gap-5'>
      <div className='col-span-5 space-y-6 xl:col-span-3'>
        <PublishPost originId={program.id} variant='program' />
        <ProgramPosts programId={program.id} canManage={program.canManage} />
      </div>

      <div className='sticky top-[140px] col-span-2 hidden space-y-6 xl:block'>
        <ProgramSavedPosts programId={program.id} />
      </div>
    </div>
  );
};

export default ProgramFeed;
