import React, { useState } from 'react';
import BodyText from 'components/typography/BodyText';
import DownIcon from 'assets/icons/chevron-down.svg';
import MemberButton from 'components/memberAndProfile/MemberButton';
import { useTranslation } from 'react-i18next';
import { AssigneeInfoType } from 'types/apis/common';

type AssigneeInfoProps = {
  assigneeInfo: AssigneeInfoType;
};

const AssigneeInfoComponent = ({ assigneeInfo }: AssigneeInfoProps) => {
  const { t } = useTranslation();

  // When assignee is 'all'
  if (assigneeInfo === 'all') {
    return (
      <BodyText variant='sm' className='ml-1'>
        {t('all_members')}
      </BodyText>
    );
  }

  // When there is a single assignee
  if (assigneeInfo.length === 1) {
    const assignee = assigneeInfo[0];
    return (
      <MemberButton
        userId={assignee.id || ''}
        isCreator={false}
        profilePicture={assignee.profilePicture || ''}
        userName={assignee.firstName + ' ' + assignee.lastName}
        chat={false}
        size='small'
      />
    );
  }

  // When there are multiple assignees
  if (assigneeInfo.length > 1) {
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const toggleDropdown = () => {
      setDropdownOpen(!dropdownOpen);
    };
    return (
      <div className='relative'>
        <button
          className='p-1 neutral-button flex items-center gap-1 rounded-xl'
          onClick={toggleDropdown}
        >
          <BodyText variant='sm'>
            {assigneeInfo.length} {t('members')}
          </BodyText>
          <DownIcon className={`h-4 w-4 transform ${dropdownOpen ? 'rotate-180' : ''}`} />
        </button>
        {dropdownOpen && (
          <div className='absolute w-fit z-10 bg-neutral-200 shadow-md rounded shadow-neutral-300'>
            {assigneeInfo.map((member) => (
              <MemberButton
                key={member.id}
                userId={member.id || ''}
                isCreator={false}
                profilePicture={member.profilePicture || ''}
                userName={member.firstName + ' ' + member.lastName}
                chat={false}
                size='small'
              />
            ))}
          </div>
        )}
      </div>
    );
  }

  return null; // Return null if no assignees
};

export default AssigneeInfoComponent;
