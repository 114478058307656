import SavedPost from 'components/post/SavedPost';
import Heading from 'components/typography/Heading';
import { useProgramSavedPosts } from 'hooks/api/post/userProgramSavedPosts';
import React from 'react';
import { useTranslation } from 'react-i18next';

interface IProgramSavedPosts {
  programId: string;
}

const ProgramSavedPosts = ({ programId }: IProgramSavedPosts) => {
  const { t } = useTranslation();

  const { data: savedPost } = useProgramSavedPosts(programId);

  if (!savedPost || savedPost.length < 1) return null;

  return (
    <div className='rounded-xl bg-neutral-200 px-4 py-5'>
      <Heading variant='h2' className='mb-5 font-semibold'>
        {t('saved_posts')}
      </Heading>

      <div className='space-y-4'>
        {savedPost.map(({ id, post }) => (
          <SavedPost key={id} savedPostid={id} post={post} id={programId} variant='program' />
        ))}
      </div>
    </div>
  );
};

export default ProgramSavedPosts;
