import { useQuery } from '@tanstack/react-query';
import { getSinglePrgoramEvent } from 'utils/api/event';

export const useProgramEvent = (programId: string, eventId: string) => {
  return useQuery({
    queryKey: ['program-event', programId, eventId],
    queryFn: () => getSinglePrgoramEvent(programId, eventId),
    select: (data) => data.data.event,
    enabled: !!programId && !!eventId,
  });
};
