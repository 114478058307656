import { Tab } from '@headlessui/react';
import Layout from 'components/layout/Layout';
import { adminTabItems } from 'config/tabs';
import { Outlet } from 'react-router';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useLocation } from 'react-router-dom';

const DashboardPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();

  const currentTabIndexFromRouter = adminTabItems.findIndex((item) => {
    return location.pathname.includes(item.path);
  });

  const tabName = location.pathname.split('/').pop();

  return (
    <Layout>
      <Tab.Group
        onChange={(value) => {
          navigate(`${adminTabItems[value].path}`);
        }}
        selectedIndex={tabName ? currentTabIndexFromRouter : 0}
      >
        <Tab.List
          className={`tab-list-parent sticky top-[0px] z-20  shrink-0 overflow-x-auto overflow-y-hidden bg-secondary-100`}
        >
          {adminTabItems.map(({ label }) => (
            <Tab key={label} className='shrink-0 outline-0'>
              {({ selected }) => (
                <div
                  className={`neutral-button border-b-1 bg-secondary-100 text-neutral-500 rounded-t-lg ${
                    selected ? 'active-tab text-secondary-500' : 'border-neutral-300'
                  } px-2 py-1 md:px-3 md:py-2`}
                >
                  {t(label)}
                </div>
              )}
            </Tab>
          ))}
        </Tab.List>

        <Tab.Panels>
          {adminTabItems.map(({ path }) => (
            <Tab.Panel
              key={path}
              className='outline-none py-1 px-1 md:py-2 md:px-3 lg:py-4 lg:px-5'
            >
              <Outlet />
            </Tab.Panel>
          ))}
        </Tab.Panels>
      </Tab.Group>
    </Layout>
  );
};

export default DashboardPage;
