import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';

dayjs.extend(relativeTime);

export const THIRTY_SECONDS = 30 * 1000;
export const SIXTY_SECONDS = 60 * 1000;
export const ONE_MINUTE = 60 * 1000;

export const selectYears = (from: string) => {
  const yearsArr: number[] = [];
  const startYear = new Date(from).getFullYear();
  let curYear = new Date().getFullYear();

  yearsArr.push(curYear);

  while (startYear <= curYear) {
    yearsArr.push((curYear -= 1));
  }

  return yearsArr;
};

export const timeSince = (date: Date) => dayjs(new Date(date)).fromNow();

export const formatDistanceToNow = (date: Date | string) => dayjs(new Date(date)).fromNow();
