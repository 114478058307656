import Avatar from 'components/memberAndProfile/Avatar';
import Button from 'components/button/Button';
import ConfirmationModal from 'components/modal/ConfirmationModal';
import ChevronRightIcon from 'assets/icons/chevron-right.svg';
import BodyText from 'components/typography/BodyText';
import { Disclosure } from '@headlessui/react';
import { userAvatar } from 'constants/common';
import { useProgramJoinRequests } from 'hooks/api/member/useProgramJoinRequests';
import { useInvalidateQueries } from 'hooks/common/useInvalidatequeries';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { acceptProgramJoinRequest, rejectProgramJoinRequest } from 'utils/api/member';
import { notifyTranslatedError } from 'utils/notify';

interface IJoinRequests {
  programId: string;
}

const JoinRequests = ({ programId }: IJoinRequests) => {
  const { t } = useTranslation();

  const [selectedRequestId, setSelectedRequestId] = useState('');
  const [openAcceptConfirmation, setOpenAcceptConfirmation] = useState(false);
  const [openRejectConfirmation, setOpenRejectConfirmation] = useState(false);

  const { requests, hasNextRequests } = useProgramJoinRequests(programId);
  const { invalidateProgramMembers, invalidateProgramJoinRequests } = useInvalidateQueries();

  const handleOpenAcceptConfirmation = (requestId: string) => {
    setSelectedRequestId(requestId);
    setOpenAcceptConfirmation(true);
  };

  const handleCloseAcceptConfirmation = () => {
    setSelectedRequestId('');
    setOpenAcceptConfirmation(false);
  };

  const handleOpenRejectConfirmation = (requestId: string) => {
    setSelectedRequestId(requestId);
    setOpenRejectConfirmation(true);
  };

  const handleCloseRejectConfirmation = () => {
    setSelectedRequestId('');
    setOpenRejectConfirmation(false);
  };

  const handleAcceptJoinRequest = () => {
    acceptProgramJoinRequest(programId, selectedRequestId)
      .then(() => {
        invalidateProgramMembers(programId);
        invalidateProgramJoinRequests(programId);
      })
      .catch((error) => {
        notifyTranslatedError(t, error.response.data?.errorCode);
      });
    setOpenAcceptConfirmation(false);
  };

  const handleRejectJoinRequest = () => {
    rejectProgramJoinRequest(programId, selectedRequestId)
      .then(() => {
        invalidateProgramMembers(programId);
        invalidateProgramJoinRequests(programId);
      })
      .catch((error) => {
        notifyTranslatedError(t, error.response.data?.errorCode);
      });
    setOpenRejectConfirmation(false);
  };

  if (!requests.length) return null;

  return (
    <div className='rounded-2xl bg-neutral-200 p-2 shadow shadow-neutral-300'>
      <Disclosure defaultOpen={true}>
        {({ open }) => (
          <>
            <Disclosure.Button className='flex w-full items-center '>
              <ChevronRightIcon className={`h-5 w-5  ${open ? 'rotate-90 transform' : ''} `} />
              <BodyText variant='base' className='font-semibold text-secondary-500'>
                {t('join_requests')}
              </BodyText>
            </Disclosure.Button>
            <Disclosure.Panel>
              <div className='space-y-2 px-4 mt-2'>
                {requests.map(({ id, user }) => (
                  <div key={id} className='flex items-center justify-between'>
                    <div className='flex items-center gap-4'>
                      <Avatar size={40} src={user.profilePicture || userAvatar} />
                      <div>
                        <BodyText variant='sm' className='font-medium'>
                          {`${user.firstName} ${user.lastName}`}
                        </BodyText>
                      </div>
                    </div>
                    <div className='flex items-center gap-3'>
                      <Button
                        size='small'
                        variant='outlined'
                        onClick={() => handleOpenRejectConfirmation(id)}
                      >
                        {t('reject')}
                      </Button>
                      <Button
                        size='small'
                        variant='secondary'
                        onClick={() => handleOpenAcceptConfirmation(id)}
                      >
                        {t('accept')}
                      </Button>
                    </div>
                  </div>
                ))}
              </div>

              {hasNextRequests && (
                <button className='relative'>
                  <Avatar size={40} src={userAvatar} alt='' />
                  <div className='absolute inset-0 rounded-full bg-neutral-600 bg-opacity-60' />
                  <BodyText
                    variant='base'
                    className='absolute inset-x-0 top-2/4 -translate-y-2/4 text-neutral-100'
                  >
                    2+
                  </BodyText>
                </button>
              )}
            </Disclosure.Panel>
          </>
        )}
      </Disclosure>

      {openAcceptConfirmation && (
        <ConfirmationModal
          isOpen={openAcceptConfirmation}
          label={t('accept_request')}
          title={t('accept_request_confirmation_title')}
          onClose={handleCloseAcceptConfirmation}
          onConfirm={handleAcceptJoinRequest}
        />
      )}

      {openRejectConfirmation && (
        <ConfirmationModal
          isOpen={openRejectConfirmation}
          label={t('reject_request')}
          title={t('reject_request_confirmation_title')}
          onClose={handleCloseRejectConfirmation}
          onConfirm={handleRejectJoinRequest}
        />
      )}
    </div>
  );
};

export default JoinRequests;
