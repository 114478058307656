import { FetchNextPageOptions, InfiniteQueryObserverResult } from '@tanstack/react-query';
import DeleteIcon from 'assets/icons/delete.svg';
import Avatar from 'components/memberAndProfile/Avatar';
import { ContextMenu } from 'components/common/ContextMenu';
import PublishComment from 'components/post/PublishComment';
import BodyText from 'components/typography/BodyText';
import Heading from 'components/typography/Heading';
import { userAvatar } from 'constants/common';
import { EditorState } from 'draft-js';
import React from 'react';
import { UseFormReset } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { SingleCommentType } from 'types/apis/common';
import { CommentsResponseType } from 'types/apis/response';
import { convertHtmlToLink, editorContentToText, sanitizeHtml } from 'utils/helper';
import { formatDistanceToNow } from 'utils/time';

interface IComments {
  originId: string;
  originType: 'program' | 'community';
  comments: SingleCommentType[];
  hasNextComments?: boolean;
  fetchNextComments: (
    options?: FetchNextPageOptions | undefined,
  ) => Promise<InfiniteQueryObserverResult<CommentsResponseType, unknown>>;
  handleDeleteComment: (commentId: string) => void;
  handlePublishComment: (
    data: { comment: string },
    reset: UseFormReset<{ comment: string }>,
    setEditorState: React.Dispatch<React.SetStateAction<EditorState>>,
  ) => void;
}

const Comments = ({
  originId,
  originType,
  comments,
  hasNextComments,
  fetchNextComments,
  handleDeleteComment,
  handlePublishComment,
}: IComments) => {
  const { t } = useTranslation();

  return (
    <div>
      <PublishComment
        originId={originId}
        originType={originType}
        onCommentPublish={handlePublishComment}
      />

      {comments.length ? (
        <>
          <Heading variant='h3' className='my-6 font-semibold text-neutral-400'>
            {t('comments')}
          </Heading>

          <div className='space-y-4'>
            {comments.map(({ id, comment, createdAt, user, myComment }) => (
              <div key={id} className='flex gap-4'>
                <Avatar size={32} src={user.profilePicture || userAvatar} />

                <div className='grow space-y-2 rounded-lg bg-neutral-100 px-4 py-2'>
                  <div className='flex items-center justify-between'>
                    <BodyText variant='sm' className='flex items-center gap-2 text-neutral-400'>
                      <span>{`${user.firstName} ${user.lastName}`}</span>
                      <span className='h-1 w-1 rounded-full bg-neutral-400' />
                      <span>{formatDistanceToNow(new Date(createdAt))}</span>
                    </BodyText>

                    {myComment && (
                      <ContextMenu
                        menuItems={[
                          {
                            key: 'delete-comment',
                            label: t('delete-comment'),
                            icon: DeleteIcon,
                            callback: () => handleDeleteComment(id),
                          },
                        ]}
                      />
                    )}
                  </div>
                  <BodyText variant='base' className='whitespace-pre-line text-neutral-500'>
                    <span
                      // eslint-disable-next-line react/no-danger
                      dangerouslySetInnerHTML={{
                        __html: sanitizeHtml(convertHtmlToLink(editorContentToText(comment))),
                      }}
                    />
                  </BodyText>
                </div>
              </div>
            ))}

            {hasNextComments && (
              <button
                className='text-sm text-functional-info-dark hover:underline'
                onClick={() => fetchNextComments()}
              >
                {t('more-comments')}
              </button>
            )}
          </div>
        </>
      ) : (
        <Heading variant='h3' className='mt-6 font-semibold text-functional-info-400'>
          {t('no_comments')}
        </Heading>
      )}
    </div>
  );
};

export default Comments;
