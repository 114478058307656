import Button from 'components/button/Button';
import TextInput from 'components/form/TextInput';
import BodyText from 'components/typography/BodyText';
import Heading from 'components/typography/Heading';
import { useInvalidateQueries } from 'hooks/common/useInvalidatequeries';
import React from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import { LoginFormInputsType } from 'types/components/form';
import { login } from 'utils/api/auth';
import { notifySuccess, notifyTranslatedError, notifyError } from 'utils/notify';

interface ILoginForm {
  originId: string;
  originType: string;
  inviteToken: string;
}

const LoginForm = ({ originId, originType, inviteToken }: ILoginForm) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { invalidateMe } = useInvalidateQueries();

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<LoginFormInputsType>({ defaultValues: { remember: false } });

  const onSubmit: SubmitHandler<LoginFormInputsType> = (data) => {
    data.originId = originId || undefined;
    data.originType = originType || undefined;
    data.inviteToken = inviteToken || undefined;
    login(data)
      .then(() => {
        invalidateMe();
        notifySuccess(t('success.api.invite_accepted'));
        navigate('/');
        navigate(0);
      })
      .catch((error) => {
        notifyTranslatedError(t, error.response.data?.errorCode);
        notifyTranslatedError(t, error.response.data?.message);
        notifySuccess(t('navigate_to_login'));
        setTimeout(() => {
          navigate('/login');
        }, 4000);
      });
  };

  return (
    <section className=' flex w-full flex-col'>
      <div className='grow items-center justify-center px-4 md:px-5 lg:flex xl:px-0'>
        <div className='w-full xl:w-3/5'>
          <Heading variant='h2' className='mb-3 font-bold'>
            {t('sign_in_title')}
          </Heading>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className='mb-4 space-y-1'>
              <BodyText variant='sm' className='font-medium text-neutral-500'>
                {t('email')}
              </BodyText>
              <Controller
                name='email'
                control={control}
                rules={{
                  required: { value: true, message: t('required_error_message') },
                }}
                render={({ field: { name, onBlur, onChange, value } }) => (
                  <TextInput
                    type='email'
                    placeholder={t('email') || 'Email'}
                    name={name}
                    onBlur={onBlur}
                    onChange={onChange}
                    value={value}
                  />
                )}
              />
              {errors.email && (
                <BodyText variant='xs' className='text-functional-danger-400'>
                  {errors.email?.message}
                </BodyText>
              )}
            </div>
            <div className='mb-2 space-y-1'>
              <BodyText variant='sm' className='font-medium text-neutral-500'>
                {t('password')}
              </BodyText>
              <Controller
                name='password'
                control={control}
                rules={{
                  required: { value: true, message: t('required_error_message') },
                }}
                render={({ field: { name, onBlur, onChange, value } }) => (
                  <TextInput
                    type='password'
                    autoComplete='password'
                    placeholder={t('password') || 'Password'}
                    name={name}
                    onBlur={onBlur}
                    onChange={onChange}
                    value={value}
                  />
                )}
              />
              {errors.password && (
                <BodyText variant='xs' className='text-functional-danger-400'>
                  {errors.password?.message}
                </BodyText>
              )}
            </div>
            <div className='mb-6 flex items-center justify-between'>
              <BodyText variant='sm' className='flex gap-1 text-neutral-400'>
                <Controller
                  name='remember'
                  control={control}
                  render={({ field: { value, ...field } }) => (
                    // eslint-disable-next-line jsx-a11y/label-has-associated-control
                    <label>
                      <input type='checkbox' checked={value} {...field} /> {t('remember_me')}
                    </label>
                  )}
                />
              </BodyText>
              <BodyText variant='sm' className='font-bold text-secondary-500'>
                <Link to='/reset_password'>{t('forgot_password')}</Link>
              </BodyText>
            </div>
            <Button type='submit' size='default' variant='secondary' className='w-full'>
              {t('log-in')}
            </Button>
          </form>
        </div>
      </div>
    </section>
  );
};

export default LoginForm;
