import { Popover as HeadlessPopover } from '@headlessui/react';
import React, { ReactElement, SVGProps } from 'react';

interface IPopover {
  className?: string;
  iconClassName?: string;
  children: React.ReactNode;
  containerClassName?: string;
  iconButtonClassName?: string;
  icon: (props: SVGProps<SVGElement>) => ReactElement;
}

const Popover = ({
  children,
  icon: Icon,
  className = '',
  iconClassName = '',
  iconButtonClassName = '',
  containerClassName = '',
}: IPopover) => {
  return (
    <HeadlessPopover className={`relative ${className}`}>
      <HeadlessPopover.Button className={iconButtonClassName}>
        <Icon className={iconClassName} />
      </HeadlessPopover.Button>

      <HeadlessPopover.Panel
        className={`absolute right-0 z-10 overflow-hidden rounded-md bg-neutral-100 shadow-md shadow-neutral-300 ${containerClassName}`}
      >
        {children}
      </HeadlessPopover.Panel>
    </HeadlessPopover>
  );
};

export default Popover;
