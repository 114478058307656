import { useQuery } from '@tanstack/react-query';
import { getProgramInviteLinks } from 'utils/api/program';

export const useProgramInviteLinks = (originId: string, originType: string, enabled = true) => {
  return useQuery({
    queryKey: ['invite-links', originId],
    queryFn: () => getProgramInviteLinks(originId),
    select: (data) => data.data.invitelinks,
    enabled: enabled && originType === 'program' && !!originId,
  });
};
