import { AxiosRequestConfig } from 'axios';
import { API_ROOT } from 'constants/config';
import { MaterialsResponseType, DiscoverResponseType } from 'types/apis/program/response';
import { generateQueryParam, parseGET, parsePOST, parsePUT } from 'utils/rest';
import {
  SinglePublicCommunityResponseType,
  CommunityResourcesResponseType,
} from 'types/apis/response';
import { PageResponseType } from 'types/apis/pages/response';
import { SingleProgramResponseType } from 'types/apis/program/response';

const API_GUEST_ROOT = `${API_ROOT}/guest`;

export const getGuestMaterials = (programId: string, options?: AxiosRequestConfig) => {
  return parseGET<MaterialsResponseType>(`${API_GUEST_ROOT}/${programId}/public-materials`, {
    withCredentials: true,
    ...options,
  });
};

export const getGuestDiscover = (
  originType: string,
  originId: string,
  options?: AxiosRequestConfig,
) => {
  return parseGET<DiscoverResponseType>(`${API_GUEST_ROOT}/discover/${originType}/${originId}`, {
    withCredentials: true,
    ...options,
  });
};

export const getSingleGuestCommunity = (id: string, options?: AxiosRequestConfig) =>
  parseGET<SinglePublicCommunityResponseType>(`${API_GUEST_ROOT}/community/${id}/single-public`, {
    ...options,
  });

export const getSingleGuestProgram = (programId: string, options?: AxiosRequestConfig) =>
  parseGET<SingleProgramResponseType>(`${API_GUEST_ROOT}/program/${programId}/single-public`, {
    withCredentials: true,
    ...options,
  });

export const getGuestResources = (
  communityId: string,
  limit = 100,
  cursor?: string,
  options?: AxiosRequestConfig,
) => {
  const queryParam = generateQueryParam({ limit, cursor });

  return parseGET<CommunityResourcesResponseType>(
    `${API_GUEST_ROOT}/${communityId}/public-resources${queryParam}`,
    {
      withCredentials: true,
      ...options,
    },
  );
};

export const getGuestPage = (pageId: string, options?: AxiosRequestConfig) => {
  return parseGET<PageResponseType>(`${API_GUEST_ROOT}/page/${pageId}`, {
    withCredentials: true,
    ...options,
  });
};
