import LeaveIcon from 'assets/icons/exit.svg';
import LeftIcon from 'assets/icons/chevron-left.svg';
import PrivateIcon from 'assets/icons/eye-off.svg';
import PublicIcon from 'assets/icons/eye.svg';
import ShareIcon from 'assets/icons/share-button.svg';
import DownIcon from 'assets/icons/chevron-down.svg';
import GlobeIcon from 'assets/icons/globe.svg';
import MessageIcon from 'assets/icons/message.svg';
import MembersIcon from 'assets/icons/members.svg';
import OpenIcon from 'assets/icons/open.svg';
import Avatar from 'components/memberAndProfile/Avatar';
import Button from 'components/button/Button';
import EditIcon from 'assets/icons/edit.svg';
import { DropdownMenu } from 'components/common/DropdownMenu';
import GuestAccessLinkModal from 'components/modal/GuestAccessLinkModal';
import { CreateGroupConversation } from 'components/message/CreateGroupConversation';
import ConfirmationModal from 'components/modal/ConfirmationModal';
import BodyText from 'components/typography/BodyText';
import Heading from 'components/typography/Heading';
import { commonCover, orgLogo } from 'constants/common';
import dayjs from 'dayjs';
import { useInvalidateQueries } from 'hooks/common/useInvalidatequeries';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { MemberRoleType } from 'types/apis/common';
import { joinCommunity, joinProgram, leaveCommunity, leaveProgram } from 'utils/api/member';
import { notifySuccess, notifyTranslatedError } from 'utils/notify';
import { generateOriginLink } from 'utils/url';
import { Link } from 'react-router-dom';
import RichTextEditor from './RichTextEditor';
import EditProgramModal from 'components/modal/EditProgramModal';
import EditCommunityModal from 'components/modal/EditCommunityModal';

interface IDetailsBanner {
  id: string;
  name: string;
  archived: boolean;
  isMember: boolean;
  memberCount: number;
  description: string;
  cover: string | null;
  joinRequested: boolean;
  organizationName: string;
  allowGuest?: boolean;
  guestViewCount?: number;
  isGroup?: boolean;
  groupParent?: { id: string; name: string } | undefined | null;
  archiveDate: null | string;
  role: null | MemberRoleType;
  organizationLogo: null | string;
  organizationId?: string;
  variant: 'community' | 'program';
  visibility: 'open' | 'public' | 'private';
  updatedAt: string;
}

const DetailsBanner = ({
  id,
  role,
  name,
  cover,
  variant,
  archived,
  isMember,
  visibility,
  archiveDate,
  memberCount,
  description,
  joinRequested,
  allowGuest,
  guestViewCount,
  organizationLogo,
  organizationId,
  organizationName,
  isGroup,
  groupParent,
  updatedAt,
}: IDetailsBanner) => {
  const { t } = useTranslation();
  const [openGuestAccessModal, setOpenGuestAccessModal] = useState(false);
  const [showLeaveConfirmation, setShowLeaveConfirmation] = useState(false);
  const [openEditProgramModal, setOpenEditProgramModal] = useState(false);
  const [openEditCommunityModal, setOpenEditCommunityModal] = useState(false);

  const [isChatOpen, setIsChatOpen] = useState(false);

  const {
    invalidateSingleCommunity,
    invalidateMyCommunities,
    invalidateOtherCommunities,
    invalidateRelatedCommunities,
    invalidateSingleProgram,
    invalidateMyPrograms,
    invalidateOtherPrograms,
    invalidateRelatedPrograms,
  } = useInvalidateQueries();

  const handleSendJoinRequest = () => {
    if (variant === 'community') {
      joinCommunity(id)
        .then(() => {
          invalidateSingleCommunity(id);
          invalidateMyCommunities();
          invalidateOtherCommunities();
          invalidateRelatedCommunities(id);
          notifySuccess(t('success.api.sent_join'));
        })
        .catch((error) => {
          notifyTranslatedError(t, error.response.data?.errorCode);
        });
    }
    if (variant === 'program') {
      joinProgram(id)
        .then(() => {
          invalidateSingleProgram(id);
          invalidateMyPrograms();
          invalidateOtherPrograms();
          invalidateRelatedPrograms(id);
          notifySuccess(t('success.api.sent_join'));
        })
        .catch((error) => {
          notifyTranslatedError(t, error.response.data?.errorCode);
        });
    }
  };

  const handleLeave = () => {
    if (variant === 'community') {
      leaveCommunity(id)
        .then(() => {
          invalidateSingleCommunity(id);
          invalidateMyCommunities();
          invalidateOtherCommunities();
          invalidateRelatedCommunities(id);
        })
        .catch((error) => {
          notifyTranslatedError(t, error.response.data?.errorCode);
        });
    }
    if (variant === 'program') {
      leaveProgram(id)
        .then(() => {
          invalidateSingleProgram(id);
          invalidateMyPrograms();
          invalidateOtherPrograms();
          invalidateRelatedPrograms(id);
        })
        .catch((error) => {
          notifyTranslatedError(t, error.response.data?.errorCode);
        });
    }
    setShowLeaveConfirmation(false);
  };

  const handleShare = async () => {
    try {
      await navigator.clipboard.writeText(window.location.href);
      notifySuccess(t('invite-copied'));
    } catch (error) {
      console.error('Failed to copy URL:', error);
    }
  };

  return (
    <>
      <section className={`z-40 border-neutral-300 border-b lg:sticky lg:top-[41px]`}>
        {isMember && (
          <div>
            <div className='z-20 bg-secondary-100 px-1 md:px-3 py-2 flex item-center'>
              <div className='flex items-center justify-between w-full'>
                <div className='flex items-center gap-1'>
                  {!isGroup && (
                    <Avatar
                      className='hidden border border-primary-400 lg:block'
                      size={28}
                      alt={organizationName}
                      src={organizationLogo || orgLogo}
                    />
                  )}
                  {isGroup && groupParent?.id && (
                    <Link to={generateOriginLink(groupParent?.id, 'program')}>
                      <LeftIcon className='w-5 h-5 neutral-button rounded-xl' />
                    </Link>
                  )}
                  <DropdownMenu
                    rightDirection={true}
                    menuButton={
                      <button className='flex items-center gap-1 neutral-button rounded-md'>
                        <BodyText variant='base' className='ml-1 line-clamp-1 font-bold'>
                          {name}{' '}
                          {archived &&
                            archiveDate &&
                            `(${t('archived_on')} ${dayjs(archiveDate).format('MMM D YYYY')})`}
                        </BodyText>

                        <DownIcon className='w-3 h-3'></DownIcon>
                      </button>
                    }
                    menuItems={[
                      ...(visibility !== 'private'
                        ? [
                            {
                              key: 'share_to_member',
                              label: t('share_to_member'),
                              icon: ShareIcon,
                              callback: () => handleShare(),
                            },
                          ]
                        : []),
                      {
                        key: 'share_to_web',
                        label: t('share_to_web'),
                        icon: GlobeIcon,
                        callback: () => setOpenGuestAccessModal(true),
                      },
                      ...(role !== MemberRoleType.admin
                        ? [
                            {
                              key: 'leave',
                              label: t('leave'),
                              icon: LeaveIcon,
                              callback: () => setShowLeaveConfirmation(true),
                            },
                          ]
                        : [
                            {
                              key: 'edit',
                              label:
                                t('edit') + ' ' + t(variant === 'community' ? 'group' : 'space'),
                              icon: EditIcon,
                              callback: () =>
                                variant === 'community'
                                  ? setOpenEditCommunityModal(true)
                                  : setOpenEditProgramModal(true),
                            },
                          ]),
                    ]}
                  />
                </div>
                <div className='flex items-center gap-3 z-40'>
                  <div className='text-neutral-400 flex items-center hidden md:flex'>
                    <Button
                      className='flex items-center text-sm'
                      tooltip={t('members') + ': ' + memberCount}
                    >
                      <MembersIcon className='h-4 w-4 ' />
                    </Button>
                    {visibility && (
                      <Button tooltip={t('visibility') + ': ' + visibility}>
                        {visibility === 'open' ? (
                          <OpenIcon className='h-4 w-4' />
                        ) : visibility === 'private' ? (
                          <PrivateIcon className='h-4 w-4' />
                        ) : (
                          <PublicIcon className='h-4 w-4' />
                        )}
                      </Button>
                    )}
                  </div>
                  <Button
                    size='small'
                    type='button'
                    onClick={() => {
                      setIsChatOpen(true);
                    }}
                    variant='secondary'
                    className='py-[1px]'
                  >
                    <MessageIcon className='h-5 w-5 md:h-4 md:w-4' />
                    <span className='text-sm hidden md:block'>{t('start-chat')}</span>
                  </Button>
                </div>
              </div>
            </div>
          </div>
        )}
        {!isMember && (
          <div>
            <div
              key={updatedAt}
              style={{
                backgroundImage: `url(${cover || commonCover})`,
              }}
              className='relative h-[115px] w-full bg-cover bg-center md:h-[200px]' // Add 'relative' class here
            >
              <div className='absolute bottom-0 mb-[25px] ml-5'>
                <Avatar
                  className='border border-primary-400'
                  size={80}
                  alt={organizationName}
                  src={organizationLogo || orgLogo}
                />
              </div>
            </div>
            <div className='bg-secondary-100 px-5 py-3'>
              <div className='flex w-full flex-col justify-end gap-3 text-center'>
                <div className='w-ful flex flex-wrap items-center justify-between'>
                  <Heading variant='h3' className='line-clamp-1 text-left'>
                    {organizationName}
                  </Heading>
                  <div className='flex items-center gap-3'>
                    <Button
                      variant='outlined'
                      size='small'
                      className='flex items-center gap-2 rounded-lg px-2'
                    >
                      <MembersIcon className='h-4 w-4 text-neutral-500' />
                      <BodyText variant='sm' className='font-medium text-neutral-500'>
                        {memberCount}
                      </BodyText>
                    </Button>
                    <Button
                      variant='outlined'
                      size='small'
                      className='flex items-center gap-2 rounded-lg px-2'
                    >
                      {visibility === 'open' ? (
                        <OpenIcon className='h-4 w-4 text-neutral-500' />
                      ) : visibility === 'private' ? (
                        <PrivateIcon className='h-4 w-4 text-neutral-500' />
                      ) : (
                        <PublicIcon className='h-4 w-4 text-neutral-500' />
                      )}
                      <BodyText variant='sm' className='font-medium capitalize text-neutral-500'>
                        {t(visibility)}
                      </BodyText>
                    </Button>
                    <div className='flex basis-full gap-3 md:basis-auto'>
                      {role !== MemberRoleType.admin &&
                        (joinRequested ? (
                          <Button
                            disabled
                            size='small'
                            variant='outlined'
                            className='w-full md:w-auto'
                          >
                            <span className='text-secondary-600'>{t('request_sent')}</span>
                          </Button>
                        ) : (
                          <div>
                            <Button
                              size='default'
                              variant='secondary'
                              className='w-10 md:w-auto animate-bounce'
                              onClick={handleSendJoinRequest}
                            >
                              {t('join')}
                            </Button>
                          </div>
                        ))}
                    </div>
                  </div>
                </div>

                <div className='flex flex-col items-start'>
                  <Heading variant='h1' className='text-left font-semibold'>
                    {name}{' '}
                    {archived &&
                      archiveDate &&
                      `(${t('archived_on')} ${dayjs(archiveDate).format('MMM D YYYY')})`}
                  </Heading>
                  <RichTextEditor initialContent={description} isReadOnly={true} />
                </div>
              </div>
            </div>
          </div>
        )}
      </section>
      {openGuestAccessModal && (
        <GuestAccessLinkModal
          originId={id}
          originType={variant}
          allowGuest={allowGuest}
          guestViewCount={guestViewCount || 0}
          orgId={organizationId}
          isOpen={openGuestAccessModal}
          onClose={() => setOpenGuestAccessModal(false)}
        />
      )}
      {showLeaveConfirmation && (
        <ConfirmationModal
          isOpen={showLeaveConfirmation}
          title={t('leave-community')}
          label={t('leave_community_confirmation_title')}
          description={
            t('leave_community_confirmation_description') ||
            'You will not a be part of this community after leaving!'
          }
          onClose={() => setShowLeaveConfirmation(false)}
          onConfirm={handleLeave}
        />
      )}
      <CreateGroupConversation
        originType={variant}
        originId={id}
        isOpen={isChatOpen}
        setIsOpen={setIsChatOpen}
      />
      <EditCommunityModal
        open={openEditCommunityModal}
        communityId={variant === 'community' ? id : ''}
        setOpen={setOpenEditCommunityModal}
      />
      <EditProgramModal
        open={openEditProgramModal}
        programId={variant === 'program' ? id : ''}
        setOpen={setOpenEditProgramModal}
      />
    </>
  );
};

export default DetailsBanner;
