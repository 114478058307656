import Loading from 'components/Loading';
import NavigateTo404 from 'components/NavigateTo404';
import { useSingleProgram } from 'hooks/api/program/useSingleProgram';
import React from 'react';
import { useParams } from 'react-router-dom';
import ManagersSubmissions from './ManagersSubmissions';
import MembersSubmissions from './MembersSubmissions';
import { useTranslation } from 'react-i18next';

const ProgramSubmissions = () => {
  const { programId } = useParams();
  const { t } = useTranslation();
  const { data: program, isLoading: isProgramLoading } = useSingleProgram(programId || '');

  if (isProgramLoading) return <Loading />;
  if (!program) return <NavigateTo404 />;

  const { id: programUid, canManage } = program;

  return canManage ? (
    <div>
      <ManagersSubmissions originId={programUid} />
    </div>
  ) : (
    <div>
      <MembersSubmissions originId={programUid} />
    </div>
  );
};

export default ProgramSubmissions;
