import React from 'react';

type IDateTimeInput = React.HTMLProps<HTMLInputElement> & {
  type: 'date' | 'time' | 'datetime-local';
};

const DateTimeInput = ({ className, type, ...props }: IDateTimeInput) => {
  if (type === 'time') {
    const handleHourChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
      const hour = event.target.value;
      const minute = props.value ? (props.value as string).split(':')[1] : '00';
      props.onChange?.({
        target: { value: `${hour}:${minute}` },
      } as React.ChangeEvent<HTMLInputElement>);
    };

    const handleMinuteChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
      const minute = event.target.value;
      const hour = props.value ? (props.value as string).split(':')[0] : '00';
      props.onChange?.({
        target: { value: `${hour}:${minute}` },
      } as React.ChangeEvent<HTMLInputElement>);
    };

    const hourOptions = [];
    for (let hour = 0; hour < 24; hour++) {
      const paddedHour = hour.toString().padStart(2, '0');
      hourOptions.push(<option key={paddedHour}>{paddedHour}</option>);
    }

    const minuteOptions = [];
    for (let minute = 0; minute < 60; minute += 15) {
      const paddedMinute = minute.toString().padStart(2, '0');
      minuteOptions.push(<option key={paddedMinute}>{paddedMinute}</option>);
    }

    return (
      <div className='flex items-center'>
        <select
          value={props.value ? (props.value as string).split(':')[0] : '00'}
          className={`w-20 cursor-pointer rounded-l-lg border border-neutral-300 bg-neutral-100 py-[11px] px-[14px] text-base text-neutral-600 outline-none ${className}`}
          onChange={handleHourChange}
        >
          {hourOptions}
        </select>
        <span className='px-2 font-medium text-neutral-600'>:</span>
        <select
          value={props.value ? (props.value as string).split(':')[1] : '00'}
          className={`w-20 cursor-pointer rounded-r-lg border border-neutral-300 bg-neutral-100 py-[11px] px-[14px] text-base text-neutral-600 outline-none ${className}`}
          onChange={handleMinuteChange}
        >
          {minuteOptions}
        </select>
      </div>
    );
  }

  return (
    <input
      type={type}
      className={`w-full cursor-pointer rounded-lg border border-neutral-300 bg-neutral-100 py-[11px] px-[14px] text-base text-neutral-600 outline-none ${className}`}
      {...props}
    />
  );
};

export default DateTimeInput;
