import axios, { AxiosRequestConfig, AxiosError } from 'axios';

export const parseGET = <Res>(url: string, options?: AxiosRequestConfig) =>
  axios
    .get<Res>(url, options)
    .then((response) => response.data)
    .catch((error: AxiosError<Res>) => {
      if (error.response?.status === 401) return error.response?.data;
      throw error;
    });

export const parsePOST = <Req, Res>(url: string, body: Req, options?: AxiosRequestConfig) =>
  axios
    .post<Res>(url, body, options)
    .then((response) => response.data)
    .catch((error: AxiosError<Res>) => {
      if (error.response?.status === 401) return error.response?.data;
      throw error;
    });

export const parsePUT = <Req, Res>(url: string, body: Req, options?: AxiosRequestConfig) =>
  axios
    .put<Res>(url, body, options)
    .then((response) => response.data)
    .catch((error: AxiosError<Res>) => {
      if (error.response?.status === 401) return error.response?.data;
      throw error;
    });

export const parseDELETE = <Res>(url: string, options?: AxiosRequestConfig) =>
  axios
    .delete<Res>(url, options)
    .then((response) => response.data)
    .catch((error: AxiosError<Res>) => {
      if (error.response?.status === 401) return error.response?.data;
      throw error;
    });

export const objToFormData = (obj: { [key: string]: string }) => {
  const formData = new FormData();
  Object.keys(obj).forEach((key) => formData.append(key, obj[key]));
  return formData;
};

export const generateQueryParam = (obj: {
  [key: string]: string | number | boolean | undefined;
}) => {
  const queryParams = Object.keys(obj)
    .filter((key) => obj[key])
    .map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(obj[key]!)}`)
    .join('&');

  return queryParams ? `?${queryParams}` : '';
};
