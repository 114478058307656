import AddMemberIcon from 'assets/icons/add-member.svg';
import AddIcon from 'assets/icons/add.svg';
import SortIcon from 'assets/icons/sort.svg';
import BodyText from 'components/typography/BodyText';
import DeleteIcon from 'assets/icons/delete.svg';
import ImportIcon from 'assets/icons/drop-docs.svg';
import ShareIcon from 'assets/icons/share-button.svg';
import Button from 'components/button/Button';
import SearchInput from 'components/form/SearchInput';
import { DropdownMenu } from 'components/common/DropdownMenu';
import { MemberRoleType } from 'types/apis/common';
import AddMemberModal from 'components/modal/AddMemberModal';
import ConfirmationModal from 'components/modal/ConfirmationModal';
import CsvAddMemberModal from 'components/modal/CsvAddMemberModal';
import InviteLinkModal from 'components/modal/InviteLinkModal';
import { userAvatar } from 'constants/common';
import { useCommunity } from 'hooks/api/community/useCommunity';
import useCommunityMembers from 'hooks/api/community/useCommunityMembers';
import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { removeCommunityMember } from 'utils/api/member';
import { notifySuccess, notifyTranslatedError } from 'utils/notify';
import { useNavigate } from 'react-router-dom';
import useIsLoggedIn from 'hooks/api/auth/useIsLoggedIn';
import { changeMemberRole } from 'utils/api/auth';
import MemberTile from 'components/memberAndProfile/MemberTile';
import { timeSince } from 'utils/time';
import ViewToggle from 'components/common/ItemListViewToggle';
import MemberButton from 'components/memberAndProfile/MemberButton';
import { ContextMenu } from 'components/common/ContextMenu';

const CommunityMembers = () => {
  const { t } = useTranslation();
  const { communityId } = useParams();
  const navigate = useNavigate();
  const { data: community } = useCommunity(communityId || '');

  if (!community) return null;

  // list view toggle
  const [listView, setListView] = useState(false); // Initialize state
  const handleToggleView = (isListView: boolean) => {
    setListView(isListView); // Update state based on the value from ViewToggle
  };
  const [selectedMemberId, setSelectedMemberId] = useState('');
  const [openAddMemberModal, setOpenAddMemberModal] = useState(false);
  const [openCsvAddMemberModal, setOpenCsvAddMemberModal] = useState(false);
  const [openRemoveMemberConfirmationModal, setOpenRemoveMemberConfirmationModal] = useState(false);
  const [openInviteLinksModal, setOpenInviteLinksModal] = useState(false);
  const { data: members, refetch: refetchMembers } = useCommunityMembers(community.id);
  const { data: userInfo, isLoading: isUserInfoLoading } = useIsLoggedIn();

  //filter code here
  const [searchString, setSearchString] = useState('');

  const filteredMembers = useMemo(
    () =>
      members
        ?.sort((a) => (a.role === 'manager' ? -1 : 0))
        .filter((member) =>
          `${member.firstName} ${member.lastName}`
            .toLowerCase()
            .includes(searchString.toLowerCase()),
        ) || [],
    [members, searchString],
  );
  //filter end here

  // sorting related code:
  const [sortConfig, setSortConfig] = useState<{
    key: 'createdAt' | 'firstName' | 'lastName' | 'role';
    direction: 'ascending' | 'descending';
  }>({ key: 'role', direction: 'ascending' });

  const sortedMembers = useMemo(() => {
    let filtered = filteredMembers;

    if (sortConfig.key) {
      filtered = [...filteredMembers].sort((a, b) => {
        let aValue, bValue;
        if (sortConfig.key === 'createdAt') {
          aValue = new Date(a.createdAt).getTime();
          bValue = new Date(b.createdAt).getTime();
        } else if (sortConfig.key === 'firstName') {
          aValue = a.firstName;
          bValue = b.firstName;
        } else if (sortConfig.key === 'lastName') {
          aValue = a.lastName;
          bValue = b.lastName;
        } else if (sortConfig.key === 'role') {
          aValue = a.role;
          bValue = b.role;
        } else {
          aValue = a[sortConfig.key];
          bValue = b[sortConfig.key];
        }
        if (aValue && bValue && aValue < bValue) {
          return sortConfig.direction === 'ascending' ? -1 : 1;
        }
        if (aValue && bValue && aValue > bValue) {
          return sortConfig.direction === 'ascending' ? 1 : -1;
        }
        return 0;
      });
    }
    return filtered;
  }, [filteredMembers, sortConfig]);

  const handleSort = (key: 'firstName' | 'createdAt' | 'lastName' | 'role') => {
    let direction: 'ascending' | 'descending' = 'ascending';
    if (sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }
    setSortConfig({ key, direction });
  };
  // sorting related code ends here

  const closeAddMemberModal = () => {
    setOpenAddMemberModal(false);
  };

  const handleOpenRemoveMemberConfirmationModal = (memberId: string) => {
    setSelectedMemberId(memberId);
    setOpenRemoveMemberConfirmationModal(true);
  };

  const handleCloseRemoveMemberConfirmationModal = () => {
    setSelectedMemberId('');
    setOpenRemoveMemberConfirmationModal(false);
  };

  const handleRemoveMember = () => {
    if (!community) return;
    removeCommunityMember(community.id, selectedMemberId)
      .then(() => {
        refetchMembers();
        notifySuccess(t('success.api.member_removed'));
      })
      .catch((error) => {
        notifyTranslatedError(t, error.response.data?.errorCode);
      });
    setOpenRemoveMemberConfirmationModal(false);
  };

  const handleMakeManager = (memberId: string) => {
    changeMemberRole({
      memberId,
      role: 'manager',
      originId: community.id,
      originType: 'community',
    })
      .then(() => {
        refetchMembers();
        notifySuccess(t('action-success'));
      })
      .catch((error) => {
        notifyTranslatedError(t, error.response.data?.errorCode);
      });
  };

  const handleMakeMember = (memberId: string) => {
    changeMemberRole({
      memberId,
      role: 'member',
      originId: community.id,
      originType: 'community',
    })
      .then(() => {
        notifySuccess(t('action-success'));
        refetchMembers();
      })
      .catch((error) => {
        notifyTranslatedError(t, error.response.data?.errorCode);
      });
  };

  return (
    <>
      <div className='mb-4 flex items-center justify-between px-1 gap-2 '>
        <SearchInput onSearch={(value) => setSearchString(value)} />
        <BodyText variant='base' className='text-neutral-500 hidden md:flex gap-1 w-10'>
          <span className='text-secondary-500 font-bold'>{members?.length}</span> {t('members')}
        </BodyText>
        <div className='flex items-center justify-end w-full gap-3'>
          <ViewToggle onToggleView={handleToggleView} />
          {community.canManage && (
            <div className='z-10 '>
              <DropdownMenu
                menuButton={
                  <Button size='small' variant='secondary-outlined' tooltip={t('add_member')}>
                    <AddIcon className='h-5 w-5' />
                  </Button>
                }
                menuItems={[
                  {
                    key: 'add_member',
                    label: t('add_member'),
                    icon: AddMemberIcon,
                    callback: () => setOpenAddMemberModal(true),
                  },
                  {
                    key: 'import_csv',
                    label: t('import_csv'),
                    icon: ImportIcon,
                    callback: () => setOpenCsvAddMemberModal(true),
                  },
                  {
                    key: 'invitation-link',
                    label: t('invitation-link'),
                    icon: ShareIcon,
                    callback: () => setOpenInviteLinksModal(true),
                  },
                ]}
              />
            </div>
          )}
        </div>
      </div>
      <div className='mb-5'>
        {!listView ? (
          <div className='grid grid-cols-1 gap-3 lg:grid-cols-2 xl:grid-cols-3 xxl:grid-cols-4'>
            {filteredMembers.map(
              ({ id: userId, headline, firstName, lastName, profilePicture, role, lastVisit }) => (
                <MemberTile
                  key={userId}
                  userId={userId}
                  headline={headline || ''}
                  firstName={firstName}
                  lastName={lastName}
                  lastVisit={lastVisit}
                  profilePicture={profilePicture || userAvatar}
                  role={role || 'Member'}
                  handleOpenRemoveMemberConfirmationModal={handleOpenRemoveMemberConfirmationModal}
                  handleMakeManager={handleMakeManager}
                  handleMakeMember={handleMakeMember}
                  userInfo={userInfo || null}
                  canManage={community.canManage}
                />
              ),
            )}
          </div>
        ) : (
          <div className='table-container'>
            <table>
              <thead>
                <tr>
                  <th>
                    {' '}
                    <div className='flex items-center gap-1 text-left'>
                      {t('name')}
                      <button
                        className='neutral-button rounded-full p-1'
                        onClick={() => handleSort('firstName')}
                      >
                        <SortIcon className='h-4 w-4' />
                      </button>
                    </div>
                  </th>
                  <th>
                    <div className='flex items-center gap-1 text-left'>
                      {t('role')}
                      <button
                        className='neutral-button rounded-full p-1'
                        onClick={() => handleSort('role')}
                      >
                        <SortIcon className='h-4 w-4' />
                      </button>
                    </div>
                  </th>
                  <th>{t('headline')}</th>
                  <th>{t('last_visit')}</th>
                  <th>{t('actions')}</th>
                </tr>
              </thead>
              <tbody>
                {sortedMembers.map(
                  ({
                    id: userId,
                    headline,
                    firstName,
                    lastName,
                    profilePicture,
                    role,
                    lastVisit,
                  }) => (
                    <tr key={userId} className='group'>
                      <td>
                        <MemberButton
                          userId={userId}
                          isCreator={false}
                          profilePicture={profilePicture || ''}
                          userName={firstName + ' ' + lastName}
                          chat={false}
                          size='small'
                        />
                      </td>
                      <td>
                        <BodyText
                          variant='base'
                          className={`capitalize ${
                            role === 'member' ? 'text-neutral-500' : 'text-functional-success-400'
                          }`}
                        >
                          {' '}
                          {t(`member.role.${role}`)}
                        </BodyText>
                      </td>
                      <td>
                        <BodyText variant='base' classID='line-clamp-1 max-w-[90px]'>
                          {headline}
                        </BodyText>
                      </td>
                      <td>
                        {lastVisit ? (
                          <BodyText variant='base' className='line-clamp-1'>
                            {timeSince(new Date(lastVisit))}
                          </BodyText>
                        ) : (
                          <div>-</div>
                        )}
                      </td>
                      <td className='text-left flex items-center gap-3 lg:opacity-0 lg:group-hover:opacity-100'>
                        {userInfo?.userType === 'organization_admin' && community.canManage && (
                          <ContextMenu
                            menuItems={[
                              {
                                key: 'remove',
                                label: t('remove_member'),
                                icon: DeleteIcon,
                                callback: () => handleOpenRemoveMemberConfirmationModal(userId),
                              },
                              ...(role === MemberRoleType.member
                                ? [
                                    {
                                      key: 'make_manager',
                                      label: t('make_manager'),
                                      icon: AddMemberIcon,
                                      callback: () => handleMakeManager(userId),
                                    },
                                  ]
                                : []),
                              ...(role === MemberRoleType.manager
                                ? [
                                    {
                                      key: 'make_member',
                                      label: t('make_member'),
                                      icon: AddMemberIcon,
                                      callback: () => handleMakeMember(userId),
                                    },
                                  ]
                                : []),
                            ]}
                          />
                        )}
                        {userInfo?.userType !== 'organization_admin' && community.canManage && (
                          <button
                            className='flex h-6 w-6 items-center justify-center rounded-full hover:bg-neutral-300 active:bg-neutral-400'
                            onClick={() => handleOpenRemoveMemberConfirmationModal(userId)}
                          >
                            <DeleteIcon className='h-5 w-5 text-neutral-400 hover:text-functional-danger-dark active:text-functional-danger-dark' />
                          </button>
                        )}
                      </td>
                    </tr>
                  ),
                )}
              </tbody>
            </table>
          </div>
        )}
      </div>

      {openAddMemberModal && (
        <AddMemberModal
          originId={community.id}
          originType='community'
          groupParentId={community.groupParent?.id ? community.groupParent.id : undefined}
          isOpen={openAddMemberModal}
          onClose={closeAddMemberModal}
        />
      )}

      {openCsvAddMemberModal && (
        <CsvAddMemberModal
          id={community.id}
          variant='community'
          isOpen={openCsvAddMemberModal}
          onClose={() => setOpenCsvAddMemberModal(false)}
        />
      )}

      {openInviteLinksModal && (
        <InviteLinkModal
          originId={community.id}
          originType='community'
          orgId={community.organization.id}
          isOpen={openInviteLinksModal}
          onClose={() => setOpenInviteLinksModal(false)}
        />
      )}

      {openRemoveMemberConfirmationModal && (
        <ConfirmationModal
          isOpen={openRemoveMemberConfirmationModal}
          label={t('remove_member')}
          title={t('remove_member_confirmation_title')}
          description={
            t('remove_member_confirmation_description') ||
            'This member can not interact in this community anymore!'
          }
          onClose={handleCloseRemoveMemberConfirmationModal}
          onConfirm={handleRemoveMember}
        />
      )}
    </>
  );
};

export default CommunityMembers;
