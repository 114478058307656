import { useQuery } from '@tanstack/react-query';
import { getConversationsList } from 'utils/api/message';

export const useConversationsList = (enabled = false) => {
  const {
    data: conversations = [],
    isLoading: isConversationsListLoading,
    refetch: refetchConversationsList,
  } = useQuery({
    queryKey: ['conversations-list'],
    queryFn: () => getConversationsList(),
    select: (data) => data.data.conversations,
    enabled,
  });

  return {
    conversations,
    isConversationsListLoading,
    refetchConversationsList,
  };
};
