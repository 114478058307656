import { useSingleProgram } from 'hooks/api/program/useSingleProgram';
import { useInvalidateQueries } from 'hooks/common/useInvalidatequeries';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Visibility } from 'types/apis/common';
import { updateProgram } from 'utils/api/program';
import { notifySuccess, notifyTranslatedError } from 'utils/notify';
import CreationModal from './CreationModal';
import { AxiosProgressEvent } from 'axios';
import useIsLoggedIn from 'hooks/api/auth/useIsLoggedIn';
import { useHashtags } from 'hooks/api/program/useMyPrograms';

interface IEditProgramModal {
  programId: string;
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const EditProgramModal = ({ programId, open, setOpen }: IEditProgramModal) => {
  const { t } = useTranslation();
  const { data: userInfo, isLoading: isUserInfoLoading } = useIsLoggedIn();
  const { data } = useSingleProgram(programId);
  const { invalidateSingleProgram, invalidateMyPrograms } = useInvalidateQueries();
  const { refetchHashtags } = useHashtags();

  const defaultValues = useMemo(() => {
    if (!data) {
      return {
        name: '',
        cover: null,
        description: '',
        visibility: 'open' as Visibility,
        allowGuest: 'false',
        logo: userInfo?.primaryOrganization?.logo || undefined,
      };
    }

    const { name, description, visibility, cover, allowGuest, logo, hashtags } = data;

    return {
      name,
      cover,
      visibility,
      description,
      hashtags: hashtags,
      allowGuest: allowGuest ? 'true' : 'false',
      logo: logo || userInfo?.primaryOrganization?.logo || undefined,
    };
  }, [data]);

  const onFormSubmit = (
    data: FormData,
    onUploadProgress: (progressEvent: AxiosProgressEvent) => void,
    setIsUploading: React.Dispatch<React.SetStateAction<boolean>>,
  ) => {
    updateProgram(programId, data)
      .then(() => {
        setOpen(false);
        invalidateSingleProgram(programId);
        invalidateMyPrograms();
        notifySuccess(t('success.api.space_updated'));
        refetchHashtags();
        setIsUploading(false);
      })
      .catch((error) => {
        notifyTranslatedError(t, error.response.data?.errorCode);
      });
  };

  if (!data) return null;
  return (
    <CreationModal
      isOpen={open}
      onFormSubmit={onFormSubmit}
      defaultValues={defaultValues}
      onClose={() => setOpen(false)}
      ctaText={t('save') || 'Save'}
      modalTitle={t('update-space')}
    />
  );
};

export default EditProgramModal;
