import { useInfiniteQuery } from '@tanstack/react-query';
import { getJoinRequests } from 'utils/api/member';

export const useJoinRequests = (communityId: string) => {
  const { data, hasNextPage, fetchNextPage, refetch } = useInfiniteQuery({
    queryKey: ['join-requests', communityId],
    queryFn: ({ pageParam = '' }) => getJoinRequests(communityId, 3, pageParam),
    getNextPageParam: (lastPage) => lastPage.data.cursor,
  });

  return {
    data: data?.pages.flatMap((item) => item.data.requests) ?? [],
    hasNextRequests: hasNextPage,
    fetchNextRequests: fetchNextPage,
    refetchRequests: refetch,
  };
};
