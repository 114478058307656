import LanguageSelector from 'components/layout/LanguageSelector';
import CampassIcon from 'assets/icons/compass.svg';
import SendIcon from 'assets/icons/send.svg';
import Avatar from 'components/memberAndProfile/Avatar';
import Button from 'components/button/Button';
import BodyText from 'components/typography/BodyText';
import Heading from 'components/typography/Heading';
import { commonCover, orgLogo } from 'constants/common';
import dayjs from 'dayjs';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { notifySuccess } from 'utils/notify';

interface IGuestDetailsBanner {
  id: string;
  name: string;
  archived: boolean;
  cover: string | null;
  organizationName: string;
  archiveDate: null | string;
  organizationLogo: null | string;
  variant: string;
  discoverLink: string;
}

const GuestDetailsBanner = ({
  id,
  name,
  cover,
  archived,
  archiveDate,
  organizationLogo,
  organizationName,
  discoverLink,
}: IGuestDetailsBanner) => {
  const { t } = useTranslation();

  const handleShare = async () => {
    try {
      await navigator.clipboard.writeText(window.location.href);
      notifySuccess(t('invite-copied'));
    } catch (error) {
      console.error('Failed to copy URL:', error);
    }
  };

  return (
    <>
      <section className={`z-40 bg-neutral-200 `}>
        <div>
          <div
            key={id}
            style={{
              backgroundImage: `url(${cover || commonCover})`,
            }}
            className='relative h-[115px] w-full rounded-t-2xl bg-cover bg-center object-cover md:h-[275px]'
          >
            <div className='absolute bottom-0 mb-[25px] ml-5'>
              <Avatar
                className='border border-4 border-white'
                size={80}
                alt={organizationName}
                src={organizationLogo || orgLogo}
              />
            </div>
            <div className='z-50 grid hidden grid-flow-col items-center justify-end gap-3 lg:absolute lg:right-0 lg:top-0 lg:mr-5 lg:mt-5 lg:grid'>
              <Button
                size='small'
                variant='primary'
                className='items-center gap-2 rounded-xl border-2 border-secondary-500 p-1 shadow-md shadow-neutral-300'
                onClick={handleShare}
              >
                <SendIcon className='h-4 w-4 ' />
                <BodyText variant='base' className='font-medium '>
                  {t('share')}
                </BodyText>
              </Button>
              <div className='rounded-xl border-2 border-secondary-500 shadow-md shadow-neutral-300'>
                <LanguageSelector />
              </div>
            </div>
          </div>
          <div className='bg-secondary-100 px-5 py-3'>
            <div className='flex flex-col gap-3 text-center'>
              <div className='space-y-2'>
                <div className='z-50 flex items-center gap-3 lg:hidden'>
                  <Button
                    size='small'
                    variant='secondary'
                    className='flex flex-grow items-center gap-2 rounded-xl p-2'
                    onClick={() => (location.href = discoverLink)}
                  >
                    <CampassIcon className='h-5 w-5 ' />
                    <BodyText variant='base' className='font-medium '>
                      {t('discover')}
                    </BodyText>
                  </Button>
                  <Button
                    size='small'
                    variant='primary'
                    className='flex flex-grow items-center gap-2 rounded-xl p-2'
                    onClick={handleShare}
                  >
                    <SendIcon className='h-5 w-5 ' />
                    <BodyText variant='base' className='font-medium '>
                      {t('share')}
                    </BodyText>
                  </Button>
                  <div className='flex-grow'>
                    <LanguageSelector />
                  </div>
                </div>
                <div>
                  <Heading variant='h3' className='mb-1 line-clamp-1 text-left'>
                    {organizationName}
                  </Heading>
                  <div className='flex flex-col items-start'>
                    <Heading variant='h1' className='mb-1 text-left font-semibold'>
                      {name}{' '}
                      {archived &&
                        archiveDate &&
                        `(${t('archived_on')} ${dayjs(archiveDate).format('MMM D YYYY')})`}
                    </Heading>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default GuestDetailsBanner;
