import CloseIcon from 'assets/icons/close.svg';
import Avatar from 'components/memberAndProfile/Avatar';
import Button from 'components/button/Button';
import Drawer from 'components/Drawer';
import SearchInput from 'components/form/SearchInput';
import BodyText from 'components/typography/BodyText';
import Heading from 'components/typography/Heading';
import { userAvatar } from 'constants/common';
import useCommunityMembers from 'hooks/api/community/useCommunityMembers';
import { useProgramMembers } from 'hooks/api/member/useProgramMembers';
import { useUser } from 'hooks/useUser';
import React, { useMemo, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { startConversation, updateConversation } from 'utils/api/message';
import { notifySuccess, notifyTranslatedError } from 'utils/notify';
import { generateConversationLink } from 'utils/url';
import { SelectConversationRow } from './SelectConversationRow';
import { ParticipantDetails } from 'types/apis/response';

type Member = {
  id: string;
  firstName: string;
  lastName: string;
  profilePicture: string | null;
  headline?: string | null;
  isChatAdmin?: boolean;
  conversationFlagged?: boolean;
  me?: boolean;
  userType?: string;
};

export const CreateGroupConversation = ({
  originType,
  originId,
  conversationId,
  participants,
  isUpdating,
  isOpen,
  setIsOpen,
  refetchConversation,
}: {
  originType: 'community' | 'program';
  originId: string;
  participants?: ParticipantDetails[];
  conversationId?: string;
  isUpdating?: boolean;
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  refetchConversation?: () => void;
}) => {
  const [selectedMembers, setSelectedMembers] = useState<Member[]>([]);

  // Use useEffect to update selectedMembers when participants change
  useEffect(() => {
    if (participants && participants.length > 0) {
      // Iterate through participants and add them to selectedMembers
      const convertedParticipants = participants.map((participant) => ({
        id: participant.id,
        firstName: participant.firstName,
        lastName: participant.lastName,
        profilePicture: participant.profilePicture,
        headline: null, // Set to null or some default value
      }));
      setSelectedMembers(convertedParticipants);
    }
  }, [participants]);

  const [search, setSearch] = useState('');
  const { t } = useTranslation();
  const { data: programMembers } = useProgramMembers(originId, isOpen && originType === 'program');
  const { data: communityMembers } = useCommunityMembers(
    originId,
    isOpen && originType === 'community',
  );
  const user = useUser();

  const members = programMembers || communityMembers || [];

  const navigate = useNavigate();

  const filteredMembers = useMemo(() => {
    if (!members || !user) return [];
    const membersWithoutMe = members.filter(({ id }) => id !== user.id);
    if (!search) return membersWithoutMe;
    return membersWithoutMe.filter(({ firstName, lastName }) =>
      `${firstName.toLowerCase()} ${lastName.toLowerCase()}`.includes(search.toLowerCase()),
    );
  }, [members, search]);

  const handleToggleAll = () => {
    if (selectedMembers.length === filteredMembers.length || selectedMembers.length > 0) {
      setSelectedMembers([]);
    } else {
      setSelectedMembers(filteredMembers);
    }
  };
  const handleMemberToggle = (member: Member) => {
    if (selectedMembers.some((m) => m.id === member.id)) {
      setSelectedMembers(selectedMembers.filter((m) => m.id !== member.id));
    } else {
      setSelectedMembers([...selectedMembers, member]);
    }
  };

  const isAnySelected = selectedMembers.length > 0;
  const isAllSelected =
    filteredMembers.length > 0 && selectedMembers.length === filteredMembers.length;

  const handleStartChat = () => {
    if (!selectedMembers.length) return;
    const conversationType = selectedMembers.length === 1 ? 'private' : 'group';
    startConversation({
      participants: selectedMembers.map(({ id }) => id),
      conversationType,
      ...(conversationType === 'group' &&
        (originType === 'program' ? { programId: originId } : { communityId: originId })),
    })
      .then((data) => {
        navigate(generateConversationLink(data.data.conversationId));
      })
      .catch((error) => {
        notifyTranslatedError(t, error.response.data?.errorCode);
        notifyTranslatedError(t, error.response.data?.message);
      });
  };

  const handleUpdateChat = () => {
    if (!selectedMembers.length || !conversationId || !refetchConversation) return;
    updateConversation(conversationId, {
      participants: selectedMembers.map(({ id }) => id),
      conversationType: 'group',
      ...(originType === 'program' ? { programId: originId } : { communityId: originId }),
    })
      .then((data) => {
        refetchConversation();
        notifySuccess(t('action-success'));
        setIsOpen(false);
      })
      .catch((error) => {
        notifyTranslatedError(t, error.response.data?.errorCode);
        notifyTranslatedError(t, error.response.data?.message);
      });
  };

  return (
    <Drawer isOpen={isOpen} setIsOpen={setIsOpen}>
      <div className='flex h-full w-full flex-col overflow-hidden rounded-lg'>
        <div className='flex flex-grow flex-col overflow-y-auto'>
          <div className=' w-full bg-secondary-100 p-2'>
            <div className='flex justify-between'>
              <Heading variant='h3' className='mb-2 font-semibold'>
                {t('members')}
                {members.length && (
                  <BodyText as='span' variant='sm' className='ml-1 font-medium text-secondary-400'>
                    ({members.length})
                  </BodyText>
                )}
              </Heading>
              <button
                onClick={() => {
                  setIsOpen(false);
                }}
                className='relative flex h-6 w-6 items-center justify-center rounded-full text-functional-danger-400 hover:bg-neutral-300 active:bg-neutral-400'
              >
                <CloseIcon className='h-4 w-4 ' />
              </button>
            </div>
            <SearchInput
              onSearch={(value) => {
                setSearch(value);
              }}
              className=' h-6 w-full rounded-lg'
            />
          </div>
          <div
            className='h-fit overflow-y-auto px-3'
            style={{
              height: 'calc(100vh - 200px)',
            }}
          >
            <button
              className='neutral-button flex w-full cursor-pointer items-center justify-start rounded-xl px-2 py-3'
              onClick={handleToggleAll}
            >
              <input type='checkbox' checked={isAllSelected} className='mr-4' readOnly />
              <label className='flex-grow cursor-pointer text-left'>
                {isAnySelected ? t('deselect-all') : t('select-all')}
              </label>
            </button>

            {filteredMembers.map(({ id, firstName, lastName, profilePicture, headline }) => (
              <SelectConversationRow
                key={id}
                id={id}
                firstName={firstName}
                lastName={lastName}
                profilePicture={profilePicture}
                headline={headline}
                onToggle={() =>
                  handleMemberToggle({ id, firstName, lastName, profilePicture, headline })
                }
                isSelected={selectedMembers.some((m) => m.id === id)}
              />
            ))}
          </div>
        </div>

        <div className='flex-shrink-0 bg-neutral-200 pb-3'>
          <div className='flex justify-between items-center px-3 gap-3  h-6'>
            <BodyText variant='sm' className='font-medium gap-2 flex items-center'>
              {selectedMembers.length} {t('members')}
            </BodyText>
            <div className='flex flex-shrink-0 -space-x-4 overflow-hidden'>
              {selectedMembers.map(({ id, profilePicture }) => (
                <div key={id}>
                  <Avatar
                    key={id}
                    size={32}
                    src={profilePicture || userAvatar}
                    className='border border-white'
                  />
                </div>
              ))}
            </div>
          </div>
          <div className='px-3 pb-3'>
            {!isUpdating ? (
              <Button
                size='small'
                variant='secondary'
                type='button'
                className='w-full'
                disabled={!selectedMembers.length}
                onClick={handleStartChat}
              >
                {t('start-chat')}
              </Button>
            ) : (
              <Button
                size='small'
                variant='secondary'
                type='button'
                className='w-full'
                disabled={!selectedMembers.length}
                onClick={handleUpdateChat}
              >
                {t('save')}
              </Button>
            )}
          </div>
        </div>
      </div>
    </Drawer>
  );
};
