import SortIcon from 'assets/icons/sort.svg';
import LoadingIcon from 'assets/icons/loading.svg';
import AddIcon from 'assets/icons/add.svg';
import Button from 'components/button/Button';
import NoteIcon from 'assets/icons/note-filled.svg';
import CreatePageModal from 'components/modal/CreatePageModal';
import { DropdownMenu } from 'components/common/DropdownMenu';
import SearchInput from 'components/form/SearchInput';
import BodyText from 'components/typography/BodyText';
import React, { useCallback, useMemo, useState } from 'react';
import debounce from 'lodash/debounce';
import { useTranslation } from 'react-i18next';
import { useMyResources } from 'hooks/api/resource/useAllResources';
import ResourceButton from 'components/common/ResourceButton';
import dayjs from 'dayjs';
import { generateOriginLink } from 'utils/url';
import useIsLoggedIn from 'hooks/api/auth/useIsLoggedIn';

const MyResources = () => {
  const { t } = useTranslation();
  const { data: resources, isLoading, refetch } = useMyResources();
  const [searchString, setSearchString] = useState('');
  const [showCreatePageModal, setShowCreatePageModal] = useState(false);
  const { data: user } = useIsLoggedIn();

  // Search related code:
  const filteredResources = useMemo(() => {
    return (
      resources?.resources.filter((resource) => {
        const searchLower = searchString.toLowerCase();
        const titleMatch = resource.title
          ? resource.title.toLowerCase().includes(searchLower)
          : false;
        const locationMatch = resource.locationName
          ? resource.locationName.toLowerCase().includes(searchLower)
          : false;
        return titleMatch || locationMatch;
      }) || []
    );
  }, [resources, searchString]);

  const handleSearch = (value: string) => {
    setSearchString(value.toLowerCase());
  };
  const debounceSearch = useCallback(debounce(handleSearch, 300), []);
  // search related code ends here

  // sorting related code:
  const [sortConfig, setSortConfig] = useState<{
    key: 'createdAt' | 'title';
    direction: 'ascending' | 'descending';
  }>({ key: 'createdAt', direction: 'descending' });

  const sortedResources = useMemo(() => {
    let filtered = filteredResources;
    if (sortConfig.key) {
      filtered = [...filteredResources].sort((a, b) => {
        let aValue, bValue;
        if (sortConfig.key === 'createdAt') {
          aValue = new Date(a.createdAt).getTime();
          bValue = new Date(b.createdAt).getTime();
        } else if (sortConfig.key === 'title') {
          aValue = a.title;
          bValue = b.title;
        } else {
          aValue = a[sortConfig.key];
          bValue = b[sortConfig.key];
        }
        if (aValue < bValue) {
          return sortConfig.direction === 'ascending' ? -1 : 1;
        }
        if (aValue > bValue) {
          return sortConfig.direction === 'ascending' ? 1 : -1;
        }
        return 0;
      });
    }
    return filtered;
  }, [filteredResources, sortConfig]);

  const handleSort = (key: 'title' | 'createdAt') => {
    let direction: 'ascending' | 'descending' = 'ascending';
    if (sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }
    setSortConfig({ key, direction });
  };
  // sorting related code ends here

  return (
    <div className='space-y-4'>
      <div className='flex flex-row items-center gap-2 mb-4 px-1 justify-between'>
        <SearchInput onSearch={debounceSearch} />
        <BodyText variant='base' className='mr-auto text-neutral-500 hidden md:block'>
          <span className='text-secondary-500 font-bold'>{resources?.totalResources}</span>{' '}
          {t('items')}
        </BodyText>
        <DropdownMenu
          menuButton={
            <div className=' flex items-center'>
              <Button variant='secondary-outlined' size='small' tooltip={t('add-item')}>
                <AddIcon className='h-5 w-5' />
              </Button>
            </div>
          }
          menuItems={[
            {
              key: 'create-page',
              label: t('create-page'),
              icon: NoteIcon,
              callback: () => setShowCreatePageModal(true),
            },
          ]}
        />
      </div>
      <div className='table-container'>
        <table>
          <thead>
            <tr>
              <th>
                <div className='flex items-center gap-1'>
                  <BodyText variant='sm' className='font-medium'>
                    {t('title')}
                  </BodyText>
                  <button
                    className='neutral-button rounded-full p-1'
                    onClick={() => handleSort('title')}
                  >
                    <SortIcon className='h-4 w-4' />
                  </button>
                </div>
              </th>
              <th>
                <div className='flex items-center gap-1'>
                  <BodyText variant='sm' className='font-medium'>
                    {t('date')}
                  </BodyText>
                  <button
                    className='neutral-button rounded-full p-1'
                    onClick={() => handleSort('createdAt')}
                  >
                    <SortIcon className='h-4 w-4' />
                  </button>
                </div>
              </th>
              <th>
                <div className='flex items-center gap-1 text-left'>
                  <BodyText variant='sm' className='font-medium'>
                    {t('location')}
                  </BodyText>
                </div>
              </th>
            </tr>
          </thead>

          <tbody>
            {sortedResources.map(
              ({
                itemId,
                title,
                createdAt,
                originId,
                originType,
                locationId,
                locationName,
                locationType,
                fileSize,
                directory,
              }) => (
                <tr key={itemId}>
                  <td>
                    <ResourceButton
                      title={title}
                      originId={originId}
                      originType={originType}
                      fileSize={fileSize}
                      directory={directory}
                      uploaderId={user?.id || ''}
                      firstName={user?.firstName || ''}
                      lastName={user?.lastName || ''}
                    />
                  </td>
                  <td>
                    <BodyText variant='sm' className='text-neutral-500'>
                      {dayjs(createdAt).format('ddd, D MMM YYYY, h:mm A')}
                    </BodyText>
                  </td>

                  <td>
                    <a href={generateOriginLink(locationId, locationType)} rel='noreferrer'>
                      <BodyText variant='sm' className='text-neutral-500 hover:underline'>
                        {locationName}
                      </BodyText>
                    </a>
                  </td>
                </tr>
              ),
            )}
          </tbody>
        </table>
        {isLoading && (
          <div className='mb-2 flex items-center'>
            <LoadingIcon className='h-5 w-5' />
          </div>
        )}
        {resources?.totalResources === 0 && !isLoading && (
          <div className='flex justify-center p-4 text-neutral-400'>{t('no_content_to_show')}</div>
        )}
      </div>
      {showCreatePageModal && (
        <CreatePageModal
          isOpen={showCreatePageModal}
          onClose={() => {
            setShowCreatePageModal(false);
          }}
          refetch={refetch}
        />
      )}
    </div>
  );
};

export default MyResources;
