import { useCommunity } from 'hooks/api/community/useCommunity';
import { useInvalidateQueries } from 'hooks/common/useInvalidatequeries';
import { AxiosProgressEvent } from 'axios';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Visibility } from 'types/apis/common';
import { editCommunity } from 'utils/api/community';
import { notifySuccess, notifyTranslatedError } from 'utils/notify';
import CreationModal from './CreationModal';
import useIsLoggedIn from 'hooks/api/auth/useIsLoggedIn';

interface IEditCommunityModal {
  communityId: string;
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const EditCommunityModal = ({ communityId, open, setOpen }: IEditCommunityModal) => {
  const { t } = useTranslation();
  const { data: userInfo, isLoading: isUserInfoLoading } = useIsLoggedIn();
  const { data } = useCommunity(communityId);
  const { invalidateSingleCommunity, invalidateMyCommunities, invalidateOrgCommunities } =
    useInvalidateQueries();

  const defaultValues = useMemo(() => {
    if (!data) {
      return {
        name: '',
        cover: null,
        description: '',
        visibility: 'open' as Visibility,
        allowGuest: 'false',
        logo: userInfo?.primaryOrganization?.logo || undefined,
      };
    }

    const { name, description, visibility, cover, allowGuest, logo } = data;

    return {
      name,
      cover,
      visibility,
      description,
      allowGuest: allowGuest ? 'true' : 'false',
      logo: logo || userInfo?.primaryOrganization?.logo || undefined,
    };
  }, [data, communityId]);

  const onFormSubmit = (
    data: FormData,
    onUploadProgress: (progressEvent: AxiosProgressEvent) => void,
    setIsUploading: React.Dispatch<React.SetStateAction<boolean>>,
  ) => {
    editCommunity(communityId, data)
      .then(() => {
        setOpen(false);
        invalidateSingleCommunity(communityId);
        invalidateMyCommunities();
        invalidateOrgCommunities();
        notifySuccess(t('success.api.community_updated'));
      })
      .catch((error) => {
        notifyTranslatedError(t, error.response.data?.errorCode);
      })
      .finally(() => {
        setIsUploading(false); // Reset uploading state once done, regardless of success or error
      });
  };

  if (!data) return null;

  return (
    <CreationModal
      isOpen={open}
      onFormSubmit={onFormSubmit}
      defaultValues={defaultValues}
      onClose={() => setOpen(false)}
      ctaText={t('save') || 'Save'}
      modalTitle={t('update-community')}
    />
  );
};

export default EditCommunityModal;
