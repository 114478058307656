import NavigateTo404 from 'components/NavigateTo404';
import Heading from 'components/typography/Heading';
import Loading from 'components/Loading';
import RichTextEditor from 'components/common/RichTextEditorWithUpload';
import React from 'react';
import { useSingelPage } from 'hooks/api/pages/usePages';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

interface ISinglePageView {
  pageId: string;
}

const SinglePageView = ({ pageId }: ISinglePageView) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  if (!pageId) return <NavigateTo404 />;

  const { data: page, isLoading, refetch } = useSingelPage(pageId.trim());

  return (
    <div className='z-20 flex w-full items-center justify-between py-1 lg:sticky lg:top-[51px]'>
      <div className={`mx-auto w-full max-w-[1700px] flex-col `}>
        {isLoading ? (
          <div className='flex items-center justify-center'>
            <Loading />
          </div>
        ) : (
          <div className='flex flex-col justify-center'>
            <div className='relative w-full'>
              <div className='relative'>
                {page?.banner ? (
                  // already has a cover
                  <img
                    alt=''
                    src={page?.banner}
                    className='h-[145px] w-full rounded-t-2xl bg-center object-cover object-cover md:h-[250px]'
                  />
                ) : (
                  <div></div>
                )}
              </div>
            </div>
            <div>
              <div className='px-10 py-2'>
                <Heading variant='h3' className='text-center font-semibold text-secondary-600'>
                  {page?.title}
                </Heading>
              </div>
              <div className='sm:p-3 md:px-6 md:py-4'>
                <RichTextEditor initialContent={page?.content} isReadOnly={true} />
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default SinglePageView;
