import { useQuery } from '@tanstack/react-query';
import { getSingleCommunityEvent } from 'utils/api/event';

export const useCommunityEvent = (communityId: string, eventId: string) => {
  return useQuery({
    queryKey: ['community-event', communityId, eventId],
    queryFn: () => getSingleCommunityEvent(communityId, eventId),
    select: (data) => data.data.event,
    enabled: !!communityId && !!eventId,
  });
};
