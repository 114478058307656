import { AxiosRequestConfig } from 'axios';
import { API_ROOT } from 'constants/config';
import {
  ArchivedCommunitiesResponseType,
  CommunitiesResponseType,
  SingleCommunityResponseType,
  SinglePublicCommunityResponseType,
  CommunityInviteLinksResponseType,
} from 'types/apis/response';
import { AcceptInvitationRequestType } from 'types/apis/request';
import { generateQueryParam, parseGET, parsePOST, parsePUT } from 'utils/rest';

export const getMyCommunities = (options?: AxiosRequestConfig) =>
  parseGET<CommunitiesResponseType>(`${API_ROOT}/community/my-communities`, {
    withCredentials: true,
    ...options,
  });

export const getOrgCommunities = (options?: AxiosRequestConfig) =>
  parseGET<CommunitiesResponseType>(`${API_ROOT}/org-admins/community`, {
    withCredentials: true,
    ...options,
  });

export const getRelatedCommunities = (communityId: string, options?: AxiosRequestConfig) =>
  parseGET<CommunitiesResponseType>(`${API_ROOT}/community/${communityId}/related-communities`, {
    withCredentials: true,
    ...options,
  });

export const getArchivedCommunities = (
  limit = 20,
  cursor?: string,
  options?: AxiosRequestConfig,
) => {
  const queryParam = generateQueryParam({ limit, cursor });
  return parseGET<ArchivedCommunitiesResponseType>(
    `${API_ROOT}/org-admin/community/archived${queryParam}`,
    {
      withCredentials: true,
      ...options,
    },
  );
};

export const getSingleCommunity = (id: string, options?: AxiosRequestConfig) =>
  parseGET<SingleCommunityResponseType>(`${API_ROOT}/community/${id}`, {
    withCredentials: true,
    ...options,
  });

export const getSinglePublicCommunity = (
  id: string,
  inviteToken?: string,
  options?: AxiosRequestConfig,
) =>
  parseGET<SinglePublicCommunityResponseType>(
    `${API_ROOT}/community/${id}/single-public${inviteToken ? `?inviteToken=${inviteToken}` : ''}`,
    {
      ...options,
    },
  );

export const addCommunity = (body: FormData, options?: AxiosRequestConfig) =>
  parsePOST(`${API_ROOT}/org-admin/community`, body, {
    withCredentials: true,
    ...options,
  });

export const editCommunity = (id: string, body: FormData, options?: AxiosRequestConfig) =>
  parsePUT(`${API_ROOT}/community/${id}`, body, {
    withCredentials: true,
    ...options,
  });

export const archiveCommunity = (communityId: string, options?: AxiosRequestConfig) =>
  parsePUT(
    `${API_ROOT}/org-admin/community/${communityId}/archive`,
    {},
    {
      withCredentials: true,
      ...options,
    },
  );

export const restoreCommunity = (communityId: string, options?: AxiosRequestConfig) =>
  parsePUT(
    `${API_ROOT}/org-admin/community/${communityId}/restore`,
    {},
    {
      withCredentials: true,
      ...options,
    },
  );

export const duplicateCommunity = (
  communityId: string,
  body: FormData,
  options?: AxiosRequestConfig,
) => {
  return parsePOST(`${API_ROOT}/community/${communityId}/duplicate`, body, {
    withCredentials: true,
    ...options,
  });
};

export const getCommunityInviteLinks = (communityId: string, options?: AxiosRequestConfig) =>
  parseGET<CommunityInviteLinksResponseType>(`${API_ROOT}/community/${communityId}/invite-links`, {
    withCredentials: true,
    ...options,
  });

export const acceptInvitation = (body: AcceptInvitationRequestType, options?: AxiosRequestConfig) =>
  parsePOST(`${API_ROOT}/accept-invitation`, body, {
    withCredentials: true,
    ...options,
  });
