import PublishPost from 'components/common/PublishPost';
import { useCommunity } from 'hooks/api/community/useCommunity';
import React from 'react';
import { useParams } from 'react-router-dom';
import CommunityPosts from './CommunityPosts';
import CommunitySavedPosts from './CommunitySavedPosts';

function CommunityFeed() {
  const { communityId } = useParams();
  const { data: community } = useCommunity(communityId || '');

  if (!community) return null;

  return (
    <div className='relative mb-6 grid grid-cols-5 items-start gap-5'>
      <div className='col-span-5 space-y-6 xl:col-span-3'>
        <PublishPost originId={community.id} variant='community' />
        <CommunityPosts community={community} />
      </div>

      <div className='sticky top-[140px] col-span-2 hidden space-y-6 xl:block'>
        <CommunitySavedPosts communityId={community.id} />
      </div>
    </div>
  );
}

export default CommunityFeed;
