import Button from 'components/button/Button';
import TextInput from 'components/form/TextInput';
import BodyText from 'components/typography/BodyText';
import React from 'react';
import { RenameConversationFormInputType } from 'types/components/form';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import ModalContainer from './ModalContainer';
import { renameConversation } from 'utils/api/message';
import { notifySuccess, notifyTranslatedError } from 'utils/notify';

interface IRenameConversationModal {
  isOpen: boolean;
  onClose: () => void;
  conversationId: string;
  refetchConversation: () => void;
}

const RenameConversationModal = ({
  isOpen,
  onClose,
  conversationId,
  refetchConversation,
}: IRenameConversationModal) => {
  const { t } = useTranslation();

  const handleRenameConversation = (formData: FormData) => {
    const newAlias = formData.get('newAlias');
    if (typeof newAlias === 'string') {
      const data = {
        newAlias: newAlias,
      };
      renameConversation(conversationId, data)
        .then(() => {
          refetchConversation();
          notifySuccess(t('action-success'));
          onClose();
        })
        .catch((error) => {
          notifyTranslatedError(t, error.response.data?.errorCode);
        });
    }
  };

  const {
    control,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm<RenameConversationFormInputType>();

  const onSubmit: SubmitHandler<RenameConversationFormInputType> = (data) => {
    const formData = new FormData();
    formData.append('newAlias', data.newAlias);
    handleRenameConversation(formData);
  };

  return (
    <ModalContainer title={t('rename-conversation')} isOpen={isOpen} onClose={onClose}>
      <form onSubmit={handleSubmit(onSubmit)} className='space-y-5'>
        <div className='space-y-1'>
          <BodyText
            variant='sm'
            className={`font-medium ${
              errors.newAlias ? 'text-functional-danger-400' : 'text-neutral-500'
            }`}
          >
            {t('title')}
          </BodyText>
          <Controller
            name='newAlias'
            control={control}
            rules={{ required: true }}
            render={({ field }) => (
              <TextInput
                {...field}
                placeholder={t('title') || 'Enter Name'}
                className={errors.newAlias && 'border-functional-danger-400'}
              />
            )}
          />
        </div>
        <div className='mt-5 flex items-center justify-end gap-3 border-t border-neutral-200 pt-5'>
          <Button type='button' size='small' variant='outlined' onClick={onClose}>
            {t('cancel')}
          </Button>
          <Button type='submit' size='small' variant='secondary'>
            {t('create')}
          </Button>
        </div>
      </form>
    </ModalContainer>
  );
};

export default RenameConversationModal;
