import Button from 'components/button/Button';
import BodyText from 'components/typography/BodyText';
import Heading from 'components/typography/Heading';
import React from 'react';
import { useTranslation } from 'react-i18next';
import ModalContainer from './ModalContainer';

interface IConfirmationModal {
  isOpen: boolean;
  label: string;
  title: string;
  description?: string;
  onClose: () => void;
  onConfirm: () => void;
}

const ConfirmationModal = ({
  isOpen,
  onClose,
  label,
  title,
  onConfirm,
  description,
}: IConfirmationModal) => {
  const { t } = useTranslation();

  return (
    <ModalContainer title={label} isOpen={isOpen} onClose={onClose}>
      <div className='space-y-4'>
        <Heading variant='h2' className='font-semibold text-neutral-500'>
          {title}
        </Heading>
        {description && (
          <BodyText variant='base' className='text-neutral-500'>
            {description}
          </BodyText>
        )}
      </div>

      <div className='mt-5 flex items-center justify-end gap-3 border-t border-neutral-200 pt-5'>
        <Button size='default' variant='outlined' onClick={onClose}>
          {t('cancel')}
        </Button>
        <Button size='default' variant='secondary' onClick={onConfirm}>
          {t('confirm')}
        </Button>
      </div>
    </ModalContainer>
  );
};

export default ConfirmationModal;
