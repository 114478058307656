import { useQuery } from '@tanstack/react-query';
import { getMemberProfileInfo } from 'utils/api/profile';

export const useMemberProfileInfo = (memberId: string) => {
  return useQuery({
    queryKey: ['profile-info', memberId],
    queryFn: () => getMemberProfileInfo(memberId),
    select: (data) => data.data.profile,
    staleTime: 0,
  });
};
