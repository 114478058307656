export const timezones = [
  {
    value: 'Etc/GMT+12',
    label: '(UTC-12:00) International Date Line West',
  },
  {
    value: 'Etc/GMT+11',
    label: '(UTC-11:00) Coordinated Universal Time-11',
  },
  {
    value: 'Etc/GMT+10',
    label: '(UTC-10:00) Hawaii',
  },
  {
    value: 'America/Anchorage',
    label: '(UTC-09:00) Alaska',
  },
  {
    value: 'America/Santa_Isabel',
    label: '(UTC-08:00) Baja California',
  },
  {
    value: 'America/Los_Angeles',
    label: '(UTC-07:00) Pacific Daylight Time (US & Canada)',
  },
  {
    value: 'America/Creston',
    label: '(UTC-07:00) Arizona',
  },
  {
    value: 'America/Chihuahua',
    label: '(UTC-07:00) Chihuahua, La Paz, Mazatlan',
  },
  {
    value: 'America/Boise',
    label: '(UTC-07:00) Mountain Time (US & Canada)',
  },
  {
    value: 'America/Belize',
    label: '(UTC-06:00) Central America',
  },
  {
    value: 'America/Chicago',
    label: '(UTC-06:00) Central Time (US & Canada)',
  },
  {
    value: 'America/Bahia_Banderas',
    label: '(UTC-06:00) Guadalajara, Mexico City, Monterrey',
  },
  {
    value: 'America/Regina',
    label: '(UTC-06:00) Saskatchewan',
  },
  {
    value: 'America/Bogota',
    label: '(UTC-05:00) Bogota, Lima, Quito',
  },
  {
    value: 'America/Detroit',
    label: '(UTC-05:00) Eastern Time (US & Canada)',
  },
  {
    value: 'America/Indiana/Marengo',
    label: '(UTC-05:00) Indiana (East)',
  },
  {
    value: 'America/Caracas',
    label: '(UTC-04:30) Caracas',
  },
  {
    value: 'America/Asuncion',
    label: '(UTC-04:00) Asuncion',
  },
  {
    value: 'America/Glace_Bay',
    label: '(UTC-04:00) Atlantic Time (Canada)',
  },
  {
    value: 'America/Campo_Grande',
    label: '(UTC-04:00) Cuiaba',
  },
  {
    value: 'America/Anguilla',
    label: '(UTC-04:00) Georgetown, La Paz, Manaus, San Juan',
  },
  {
    value: 'America/Santiago',
    label: '(UTC-04:00) Santiago',
  },
  {
    value: 'America/St_Johns',
    label: '(UTC-03:30) Newfoundland',
  },
  {
    value: 'America/Sao_Paulo',
    label: '(UTC-03:00) Brasilia',
  },
  {
    value: 'America/Argentina/La_Rioja',
    label: '(UTC-03:00) Buenos Aires',
  },
  {
    value: 'America/Araguaina',
    label: '(UTC-03:00) Cayenne, Fortaleza',
  },
  {
    value: 'America/Godthab',
    label: '(UTC-03:00) Greenland',
  },
  {
    value: 'America/Montevideo',
    label: '(UTC-03:00) Montevideo',
  },
  {
    value: 'America/Bahia',
    label: '(UTC-03:00) Salvador',
  },
  {
    value: 'America/Noronha',
    label: '(UTC-02:00) Coordinated Universal Time-02',
  },
  {
    value: 'America/Scoresbysund',
    label: '(UTC-01:00) Azores',
  },
  {
    value: 'Atlantic/Cape_Verde',
    label: '(UTC-01:00) Cape Verde Is.',
  },
  {
    value: 'Africa/Casablanca',
    label: '(UTC) Casablanca',
  },
  {
    value: 'America/Danmarkshavn',
    label: '(UTC) Coordinated Universal Time',
  },
  {
    value: 'Europe/Isle_of_Man',
    label: '(UTC) Edinburgh, London',
  },
  {
    value: 'Atlantic/Canary',
    label: '(UTC) Dublin, Lisbon',
  },
  {
    value: 'Africa/Abidjan',
    label: '(UTC) Monrovia, Reykjavik',
  },
  {
    value: 'Europe/Amsterdam',
    label: '(UTC+01:00) Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna',
  },
  {
    value: 'Europe/Belgrade',
    label: '(UTC+01:00) Belgrade, Bratislava, Budapest, Ljubljana, Prague',
  },
  {
    value: 'Africa/Ceuta',
    label: '(UTC+01:00) Brussels, Copenhagen, Madrid, Paris',
  },
  {
    value: 'Europe/Sarajevo',
    label: '(UTC+01:00) Sarajevo, Skopje, Warsaw, Zagreb',
  },
  {
    value: 'Africa/Algiers',
    label: '(UTC+01:00) West Central Africa',
  },
  {
    value: 'Africa/Windhoek',
    label: '(UTC+01:00) Windhoek',
  },
  {
    value: 'Asia/Nicosia',
    label: '(UTC+02:00) Athens, Bucharest',
  },
  {
    value: 'Asia/Beirut',
    label: '(UTC+02:00) Beirut',
  },
  {
    value: 'Africa/Cairo',
    label: '(UTC+02:00) Cairo',
  },
  {
    value: 'Asia/Damascus',
    label: '(UTC+02:00) Damascus',
  },
  {
    value: 'Africa/Blantyre',
    label: '(UTC+02:00) Harare, Pretoria',
  },
  {
    value: 'Europe/Helsinki',
    label: '(UTC+02:00) Helsinki, Kyiv, Riga, Sofia, Tallinn, Vilnius',
  },
  {
    value: 'Europe/Istanbul',
    label: '(UTC+03:00) Istanbul',
  },
  {
    value: 'Asia/Jerusalem',
    label: '(UTC+02:00) Jerusalem',
  },
  {
    value: 'Africa/Tripoli',
    label: '(UTC+02:00) Tripoli',
  },
  {
    value: 'Asia/Amman',
    label: '(UTC+03:00) Amman',
  },
  {
    value: 'Asia/Baghdad',
    label: '(UTC+03:00) Baghdad',
  },
  {
    value: 'Europe/Kaliningrad',
    label: '(UTC+02:00) Kaliningrad',
  },
  {
    value: 'Asia/Aden',
    label: '(UTC+03:00) Kuwait, Riyadh',
  },
  {
    value: 'Africa/Addis_Ababa',
    label: '(UTC+03:00) Nairobi',
  },
  {
    value: 'Europe/Kirov',
    label: '(UTC+03:00) Moscow, St. Petersburg, Volgograd, Minsk',
  },
  {
    value: 'Europe/Astrakhan',
    label: '(UTC+04:00) Samara, Ulyanovsk, Saratov',
  },
  {
    value: 'Asia/Tehran',
    label: '(UTC+03:30) Tehran',
  },
  {
    value: 'Asia/Dubai',
    label: '(UTC+04:00) Abu Dhabi, Muscat',
  },
  {
    value: 'Asia/Baku',
    label: '(UTC+04:00) Baku',
  },
  {
    value: 'Indian/Mahe',
    label: '(UTC+04:00) Port Louis',
  },
  {
    value: 'Asia/Tbilisi',
    label: '(UTC+04:00) Tbilisi',
  },
  {
    value: 'Asia/Yerevan',
    label: '(UTC+04:00) Yerevan',
  },
  {
    value: 'Asia/Kabul',
    label: '(UTC+04:30) Kabul',
  },
  {
    value: 'Antarctica/Mawson',
    label: '(UTC+05:00) Ashgabat, Tashkent',
  },
  {
    value: 'Asia/Yekaterinburg',
    label: '(UTC+05:00) Yekaterinburg',
  },
  {
    value: 'Asia/Karachi',
    label: '(UTC+05:00) Islamabad, Karachi',
  },
  {
    value: 'Asia/Kolkata',
    label: '(UTC+05:30) Chennai, Kolkata, Mumbai, New Delhi',
  },
  {
    value: 'Asia/Colombo',
    label: '(UTC+05:30) Sri Jayawardenepura',
  },
  {
    value: 'Asia/Kathmandu',
    label: '(UTC+05:45) Kathmandu',
  },
  {
    value: 'Antarctica/Vostok',
    label: '(UTC+06:00) Nur-Sultan (Astana)',
  },
  {
    value: 'Asia/Dhaka',
    label: '(UTC+06:00) Dhaka',
  },
  {
    value: 'Asia/Rangoon',
    label: '(UTC+06:30) Yangon (Rangoon)',
  },
  {
    value: 'Antarctica/Davis',
    label: '(UTC+07:00) Bangkok, Hanoi, Jakarta',
  },
  {
    value: 'Asia/Novokuznetsk',
    label: '(UTC+07:00) Novosibirsk',
  },
  {
    value: 'Asia/Hong_Kong',
    label: '(UTC+08:00) Beijing, Chongqing, Hong Kong, Urumqi',
  },
  {
    value: 'Asia/Krasnoyarsk',
    label: '(UTC+08:00) Krasnoyarsk',
  },
  {
    value: 'Asia/Brunei',
    label: '(UTC+08:00) Kuala Lumpur, Singapore',
  },
  {
    value: 'Antarctica/Casey',
    label: '(UTC+08:00) Perth',
  },
  {
    value: 'Asia/Taipei',
    label: '(UTC+08:00) Taipei',
  },
  {
    value: 'Asia/Choibalsan',
    label: '(UTC+08:00) Ulaanbaatar',
  },
  {
    value: 'Asia/Irkutsk',
    label: '(UTC+08:00) Irkutsk',
  },
  {
    value: 'Asia/Dili',
    label: '(UTC+09:00) Osaka, Sapporo, Tokyo',
  },
  {
    value: 'Asia/Pyongyang',
    label: '(UTC+09:00) Seoul',
  },
  {
    value: 'Australia/Adelaide',
    label: '(UTC+09:30) Adelaide',
  },
  {
    value: 'Australia/Darwin',
    label: '(UTC+09:30) Darwin',
  },
  {
    value: 'Australia/Brisbane',
    label: '(UTC+10:00) Brisbane',
  },
  {
    value: 'Australia/Melbourne',
    label: '(UTC+10:00) Canberra, Melbourne, Sydney',
  },
  {
    value: 'Antarctica/DumontDUrville',
    label: '(UTC+10:00) Guam, Port Moresby',
  },
  {
    value: 'Australia/Currie',
    label: '(UTC+10:00) Hobart',
  },
  {
    value: 'Asia/Chita',
    label: '(UTC+09:00) Yakutsk',
  },
  {
    value: 'Antarctica/Macquarie',
    label: '(UTC+11:00) Solomon Is., New Caledonia',
  },
  {
    value: 'Asia/Sakhalin',
    label: '(UTC+11:00) Vladivostok',
  },
  {
    value: 'Antarctica/McMurdo',
    label: '(UTC+12:00) Auckland, Wellington',
  },
  {
    value: 'Etc/GMT-12',
    label: '(UTC+12:00) Coordinated Universal Time+12',
  },
  {
    value: 'Pacific/Fiji',
    label: '(UTC+12:00) Fiji',
  },
  {
    value: 'Asia/Anadyr',
    label: '(UTC+12:00) Magadan',
  },
  {
    value: 'Asia/Kamchatka',
    label: '(UTC+12:00) Petropavlovsk-Kamchatsky - Old',
  },
  {
    value: 'Etc/GMT-13',
    // eslint-disable-next-line quotes
    label: "(UTC+13:00) Nuku'alofa",
  },
  {
    value: 'Pacific/Apia',
    label: '(UTC+13:00) Samoa',
  },
];
