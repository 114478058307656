import { useQuery } from '@tanstack/react-query';
import { getCommunityHomeBlock, getProgramHomeBlock } from 'utils/api/pages';

export const useCommunityHomeBlock = (communityId: string) => {
  return useQuery({
    queryKey: ['homeblock', communityId],
    queryFn: () => getCommunityHomeBlock(communityId),
    select: (data) => data?.data.block ?? [],
    enabled: !!communityId,
  });
};

export const useProgramHomeBlock = (programId: string) => {
  return useQuery({
    queryKey: ['homeblock', programId],
    queryFn: () => getProgramHomeBlock(programId),
    select: (data) => data?.data.block ?? [],
    enabled: !!programId,
  });
};
