import { useQuery } from '@tanstack/react-query';
import { getcommunityTaskSubmissions } from 'utils/api/task';

export const useCommunityTaskSubmissions = (communityId: string, taskId: string) => {
  const { data, refetch, isLoading } = useQuery({
    queryKey: ['community-task-submissions', communityId, taskId],
    queryFn: () => getcommunityTaskSubmissions(communityId, taskId),
    select: (response) => response.data,
    enabled: !!communityId && !!taskId,
  });

  return {
    submissions: data?.submissions,
    canManage: !!data?.canManage,
    refetchSubmissions: refetch,
    isSubmissionsLoading: isLoading,
  };
};
