import { useInfiniteQuery } from '@tanstack/react-query';
import { getProgramTaskComments } from 'utils/api/task';

export const useProgramTaskComments = (programId: string, taskId: string) => {
  const {
    data: comments,
    hasNextPage,
    fetchNextPage,
    refetch,
  } = useInfiniteQuery({
    queryKey: ['program-task-comments', programId, taskId],
    queryFn: ({ pageParam = '' }) => getProgramTaskComments(programId, taskId, 2, pageParam),
    getNextPageParam: (data) => data.data.cursor,
  });

  return {
    comments: comments?.pages.flatMap((item) => item.data.comments) ?? [],
    hasNextComments: hasNextPage,
    fetchNextComments: fetchNextPage,
    refetchComments: refetch,
  };
};
