import Layout from 'components/layout/Layout';
import CommonPost from 'components/post/CommonPost';
import { userAvatar } from 'constants/common';
import { useCommunityPostComments } from 'hooks/api/post/useCommunityPostComments';
import { useCommunitySavedPosts } from 'hooks/api/post/useCommunitySavedPosts';
import { useCommunitySinglePost } from 'hooks/api/post/useCommunitySinglePost';
import React, { useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { PostOrigin } from 'types/apis/common';
import { handleLikePost } from 'utils/post';

const SinglePostPage = () => {
  const { communityId, postId } = useParams();

  const [showComments, setShowComments] = useState(true);

  const { comments, hasNextComments, fetchNextComments } = useCommunityPostComments(
    communityId || '',
    postId || '',
  );
  const { data: savedPosts = [] } = useCommunitySavedPosts(communityId || '');
  const { data: post } = useCommunitySinglePost(communityId || '', postId || '');

  const singlePost = useMemo(() => {
    if (!post) return null;
    return {
      ...post,
      savedPostId: savedPosts.find((savedPost) => savedPost.post.id === post.id)?.id,
    };
  }, [postId, post, savedPosts]);

  if (!singlePost || !communityId) return null;

  const {
    id,
    content,
    authorType,
    commentCount,
    createdAt,
    user,
    embedType,
    file,
    isAuthor,
    isPinned,
    likeCount,
    liked,
    viewCount,
    savedPostId,
  } = singlePost;

  return (
    <Layout>
      <div className='mt-4 grid h-full grid-cols-5 gap-5 overflow-y-auto pb-7'>
        <div className='col-span-5 space-y-6 xl:col-span-3'>
          {singlePost && (
            <CommonPost
              postId={id}
              file={file}
              postOriginId={communityId}
              isAuthor={isAuthor}
              authorId={user.id}
              authorLogo={user.profilePicture || userAvatar}
              authorName={`${user.firstName} ${user.lastName}`}
              authorRole={authorType}
              createdAt={createdAt}
              isPostLiked={liked}
              postCommentCount={commentCount}
              postContent={content}
              postLikeCount={likeCount}
              postViewCount={viewCount}
              embedType={embedType}
              canManage={false}
              isPostPinned={isPinned}
              handleLikePost={handleLikePost}
              handlePinPost={() => {}}
              savedPostId={savedPostId}
              comments={comments}
              hasNextComments={hasNextComments || false}
              fetchNextComments={fetchNextComments}
              postOriginType={PostOrigin.community}
              showComments={showComments}
              setShowComments={setShowComments}
            />
          )}
        </div>
      </div>
    </Layout>
  );
};

export default SinglePostPage;
