import useIsLoggedIn from 'hooks/api/auth/useIsLoggedIn';
import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import LoadingIcon from '../assets/icons/loading.svg';

function RedirectIfAuthed() {
  const { data, isLoading } = useIsLoggedIn();

  if (isLoading)
    return (
      <div className='flex h-screen w-screen items-center justify-center bg-neutral-200 bg-opacity-65'>
        <LoadingIcon className='h-10 w-10 text-secondary-500' />
      </div>
    );

  return data?.id ? <Navigate to='/' /> : <Outlet />;
}

export default RedirectIfAuthed;
