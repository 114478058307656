import { useCommunity } from 'hooks/api/community/useCommunity';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import ManagersSubmissions from './ManagersSubmissions';
import MembersSubmissions from './MembersSubmissions';

const CommunitySubmissions = () => {
  const { communityId } = useParams();
  const { t } = useTranslation();
  const { data: community } = useCommunity(communityId || '');

  if (!community) return null;

  return community.canManage || community.isGroup ? (
    <div>
      <ManagersSubmissions community={community} />
    </div>
  ) : (
    <div>
      <MembersSubmissions community={community} />
    </div>
  );
};

export default CommunitySubmissions;
