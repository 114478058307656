import React from 'react';
import { selectYears } from 'utils/time';

interface ISelectYear extends React.HTMLAttributes<HTMLSelectElement> {
  from: string;
}

function SelectYear({ from, ...rest }: ISelectYear) {
  const years = selectYears(from);

  return (
    <select
      {...rest}
      className='bg-neutral-200 text-base text-neutral-600 border border-neutral-300 rounded-lg py-[11px] px-[14px] cursor-pointer outline-none'
    >
      {years.map((year) => (
        <option key={year} value={year}>
          {year}
        </option>
      ))}
    </select>
  );
}

export default SelectYear;
