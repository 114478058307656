import authCoverImg from 'assets/images/auth-cover.png';
import BodyText from 'components/typography/BodyText';
import Logo from 'assets/images/logo.svg';
import Heading from 'components/typography/Heading';
import React from 'react';
import { useTranslation, Trans } from 'react-i18next';

function AuthCover() {
  const { t } = useTranslation();

  return (
    <section className='col-span-3 hidden items-center justify-center bg-secondary-500 lg:flex'>
      <div className='flex h-full w-4/5 flex-col items-start justify-between py-[80px] xl:w-3/5'>
        <div className='flex items-center gap-1'>
          <div className='flex items-center rounded border border-primary-400'>
            <Logo className='h-[40px] w-[40px]' />
          </div>
          <a href='https://www.studycentral.eu/'>
            <div className='hidden lg:block'>
              <BodyText variant='sm' className='text-neutral-100'>
                STUDY<span className='font-bold'>CENTRAL</span>
              </BodyText>
            </div>
          </a>
        </div>

        <div>
          <Heading variant='h1' className='mb-4 text-5xl uppercase text-primary-400'>
            <Trans t={t} i18nKey='signup-heading'>
              Be a part of <strong>the future!</strong>
            </Trans>
          </Heading>
          <Heading variant='h3' className='mb-10 text-justify text-neutral-100'>
            {t('signup-description')}
          </Heading>
        </div>
        <div className='rounded-2xl border-4 border-primary-400'>
          <img
            src={authCoverImg}
            alt=''
            className='rounded-xl object-cover shadow-2xl shadow-neutral-300'
          />
        </div>
      </div>
    </section>
  );
}

export default AuthCover;
