import Avatar from 'components/memberAndProfile/Avatar';
import BodyText from 'components/typography/BodyText';
import NavigateTo404 from 'components/NavigateTo404';
import Heading from 'components/typography/Heading';
import Loading from 'components/Loading';
import dayjs from 'dayjs';
import RichTextEditor from 'components/common/RichTextEditorWithUpload';
import React, { useState, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { useGuestPage } from 'hooks/api/guest/guest';
import { useTranslation } from 'react-i18next';

interface GuestPageLandingPage {}

const GuestPageLandingPage = () => {
  const { t } = useTranslation();
  const { pageId } = useParams();
  if (!pageId) return <NavigateTo404 />;
  const { data: page, isLoading, refetch } = useGuestPage(pageId.trim());

  return (
    <div>
      <div className=' z-20 flex w-full items-center justify-between bg-neutral-200 py-2 lg:sticky'>
        <div className='flex items-center gap-2'>
          <button className='flex items-center gap-4 text-left lg:ml-10'>
            <div>
              <BodyText variant='xs' className=' text-neutral-500'>
                {t('last-update')}:
              </BodyText>
              <BodyText variant='sm' className='font-medium'>
                {dayjs(page?.updatedAt).format('ddd, D MMM YYYY')}
              </BodyText>
            </div>
          </button>
        </div>
        <div className='flex items-center gap-2'>
          <button className='flex items-center gap-4 text-left lg:mr-10'>
            {page?.user.profilePicture && <Avatar size={30} src={page?.user.profilePicture} />}
            <div>
              <BodyText variant='xs' className=' text-neutral-500'>
                {t('created-by')}:
              </BodyText>
              <BodyText variant='sm' className='font-medium'>
                {page?.user.firstName} {page?.user.lastName}
              </BodyText>
            </div>
          </button>
        </div>
      </div>

      <div className='mx-auto mb-6 flex max-w-[1000px] flex-col rounded-2xl bg-neutral-100 shadow shadow-neutral-300'>
        {isLoading ? (
          <Loading />
        ) : (
          <div>
            <div className='relative w-full '>
              <div className='relative'>
                {page?.banner ? (
                  <img
                    alt=''
                    src={page.banner}
                    className='h-[145px] w-full rounded-t-2xl bg-center object-cover object-cover md:h-[250px]'
                  />
                ) : (
                  <div></div>
                )}
              </div>
            </div>
            <div className='mb-3'>
              <div>
                <div className='px-10 lg:px-[150px]'>
                  <Heading
                    variant='h2'
                    className='p-2 text-center font-semibold text-secondary-600'
                  >
                    {page?.title}
                  </Heading>
                </div>
                <div className='px-6 py-4'>
                  <RichTextEditor initialContent={page?.content} isReadOnly={true} />
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default GuestPageLandingPage;
