import CloseIcon from 'assets/icons/close.svg';
import LeftIcon from 'assets/icons/chevron-left.svg';
import RightIcon from 'assets/icons/chevron-right.svg';
import FullScreenIcon from 'assets/icons/fullscreen.svg';
import ExitFullScreenIcon from 'assets/icons/exit-fullscreen.svg';
import Drawer from 'components/Drawer';
import BodyText from 'components/typography/BodyText';
import Heading from 'components/typography/Heading';
import DocViewer, { DocViewerRenderers } from '@cyntler/react-doc-viewer';
import Button from 'components/button/Button';
import ReactPlayer from 'react-player/lazy';
import React, { useState, useEffect } from 'react';
import useIsLoggedIn from 'hooks/api/auth/useIsLoggedIn';
import EmbedConversation from 'components/common/embedConversation';
import { useEmbedConversation } from 'hooks/api/general/useEmbedConversation';
import { useTranslation } from 'react-i18next';
import Cookies from 'js-cookie';
import { useNavigate } from 'react-router-dom';
import SinglePageView from 'pages/segment/pages/SinglePageView';

interface IResourceViewerDrawer {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  allowSideBar?: boolean;
  fileUrl: string;
  uploadedBy?: string;
  uploaderId: string;
  originType: string;
  originId: string;
}

const ResourceViewerDrawer = ({
  isOpen,
  setIsOpen,
  fileUrl,
  uploadedBy,
  uploaderId,
  originType,
  originId,
  allowSideBar = true,
}: IResourceViewerDrawer) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  let fileExtension;
  const isMediaFile = (ext: string | null | undefined): boolean => {
    const mediaExtensions = ['mp4', 'webm', 'ogv', 'mp3', 'm3u8', 'mpd', 'mov'];
    return mediaExtensions.includes(ext || '');
  };
  if (fileUrl) {
    const decodedFileName = decodeURIComponent(fileUrl);
    const cleanedFileName = decodedFileName.replace(/["]/g, '');
    const parts = cleanedFileName.split('.');
    fileExtension = parts.length > 1 ? parts.pop()?.toLowerCase() : null;
  }
  let docs;
  docs = [{ uri: fileUrl }];

  const { data: user } = useIsLoggedIn();
  const { conversationList, refetchConversationList, isConversationListLoading } =
    useEmbedConversation(originType, originId);
  const [showActionBox, setShowActionBox] = useState(false);

  const getFullScreenFromCookie = () => {
    const isFullScreenCookie = Cookies.get('isFullScreen');
    if (isFullScreenCookie !== undefined) {
      return isFullScreenCookie === 'true';
    }
    return true;
  };
  const [isFullScreen, setIsFullScreen] = useState(getFullScreenFromCookie());
  useEffect(() => {
    Cookies.set('isFullScreen', isFullScreen.toString());
  }, [isFullScreen]);
  useEffect(() => {
    const hasUnreadMessages = conversationList.some((conversation) => conversation.unread);
    if (hasUnreadMessages) {
      setShowActionBox(true);
    }
  }, [conversationList]);
  const toggleFullScreen = () => {
    const newFullScreenState = !isFullScreen;
    setIsFullScreen(newFullScreenState);
  };

  return (
    <Drawer
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      size={isFullScreen ? 'w-full' : 'w-full lg:w-[calc(90vw)]'}
    >
      <div
        className={`flex z-30 w-full items-center justify-between bg-secondary-100 p-2 lg:sticky lg:top-[0px] ${
          originType !== 'page' ? 'border-b border-neutral-300 pb-2' : ''
        } 
      `}
      >
        {uploaderId === user?.id && originType === 'page' ? (
          <Button size='small' variant='secondary' onClick={() => navigate(`/pages/${originId}`)}>
            {t('edit')}
          </Button>
        ) : (
          <div>
            <BodyText variant='xs' className=' text-neutral-500'>
              {t('created-by')}:
            </BodyText>
            <BodyText variant='sm' className='font-medium'>
              {uploadedBy}
            </BodyText>
          </div>
        )}
        <div className='item-center flex gap-3'>
          {allowSideBar && (
            <Button
              size='small'
              variant='outlined'
              onClick={() => setShowActionBox(showActionBox ? false : true)}
              className='gap-1'
            >
              {showActionBox && <span>{t('close')} </span>}
              {t('comments')}{' '}
              {!showActionBox && conversationList.length > 0 && (
                <span className='text-functional-info-dark'>{conversationList.length}</span>
              )}
            </Button>
          )}
          <button
            className='relative flex h-6 w-6 items-center justify-center rounded-full neutral-button hidden md:flex'
            onClick={toggleFullScreen}
          >
            {!isFullScreen ? (
              <FullScreenIcon className='h-5 w-5 ' />
            ) : (
              <ExitFullScreenIcon className='h-5 w-5 ' />
            )}
          </button>

          <button
            className='relative flex h-6 w-6 items-center justify-center rounded-full text-functional-danger-400 hover:bg-neutral-300 active:bg-neutral-400'
            onClick={() => setIsOpen(false)}
          >
            <CloseIcon className='h-4 w-4 ' />
          </button>
        </div>
      </div>

      <div className={`flex w-full px-3 ${showActionBox ? 'rounded-2xl shadow-lg' : ''}`}>
        <div
          className={`mx-auto max-w-[1600px] flex-col bg-neutral-100 ${
            showActionBox
              ? 'hidden md:flex md:w-3/4 rounded-bl-2xl rounded-tl-2xl'
              : 'w-full rounded-2xl shadow-lg'
          }`}
        >
          {originType !== 'page' ? (
            <div
              className='h-full w-full'
              style={{
                maxHeight: 'calc(100vh - 100px)',
              }}
            >
              {isMediaFile(fileExtension) ? (
                <ReactPlayer url={fileUrl} controls={true} width='100%' height='100%' />
              ) : (
                <DocViewer
                  config={{ header: { disableHeader: true, disableFileName: true } }}
                  documents={docs}
                  pluginRenderers={DocViewerRenderers}
                  prefetchMethod='GET'
                  style={{ width: '100%', height: 'calc(100vh - 100px)' }}
                />
              )}
            </div>
          ) : (
            <SinglePageView pageId={originId} />
          )}
        </div>
        {allowSideBar && (
          <div
            className={`group relative z-20 flex flex-col items-center justify-center  border border-r lg:sticky lg:top-[50px] hidden md:flex ${
              showActionBox ? 'w-0' : 'w-0'
            }`}
            style={{
              height: 'calc(100vh - 50px)',
            }}
          >
            <button
              onClick={() => setShowActionBox(showActionBox ? false : true)}
              className={`absolute  ${
                showActionBox ? 'left-3/4' : 'left-full'
              } neutral-button group flex h-6 w-6 -translate-x-1/2 -translate-y-1/2 transform items-center justify-center rounded-full border border-neutral-300 bg-neutral-200 transition-all duration-100 ease-in-out`}
            >
              {showActionBox ? (
                <RightIcon className='h-5 w-5' />
              ) : (
                <Heading variant='h2' className='font-bold '>
                  <LeftIcon className='h-5 w-5' />
                </Heading>
              )}
              <div className='absolute right-4 top-7 hidden max-w-[100px] overflow-hidden rounded bg-neutral-500 px-1 py-1 opacity-20 transition-opacity duration-100 ease-in-out group-hover:block group-hover:opacity-100'>
                <BodyText variant='xs' className='whitespace-nowrap text-neutral-100'>
                  {t('show_side_bar')}
                </BodyText>
              </div>
            </button>
          </div>
        )}
        {allowSideBar && (
          <>
            <div
              style={{
                height: 'calc(100vh - 80px)',
              }}
              className={`transition-box w-full space-y-2 overflow-y-auto p-2 lg:sticky lg:top-[40px] hidden md:flex ${
                showActionBox ? 'active' : ''
              }`}
            >
              {showActionBox && (
                <EmbedConversation
                  originType={originType}
                  originId={originId}
                  isOwner={user?.id === uploaderId ? true : false}
                  allowComment={true}
                  conversationList={conversationList}
                  refetchConversationList={refetchConversationList}
                  isConversationListLoading={isConversationListLoading}
                  onClose={() => setShowActionBox(false)}
                  onOpen={() => setShowActionBox(true)}
                />
              )}
            </div>
            {showActionBox && (
              <div
                style={{
                  height: 'calc(100vh - 80px)',
                }}
                className={`w-full space-y-2 overflow-y-auto p-2 lg:sticky lg:top-[40px] md:hidden`}
              >
                {showActionBox && (
                  <EmbedConversation
                    originType={originType}
                    originId={originId}
                    isOwner={user?.id === uploaderId ? true : false}
                    allowComment={true}
                    conversationList={conversationList}
                    refetchConversationList={refetchConversationList}
                    isConversationListLoading={isConversationListLoading}
                    onClose={() => setShowActionBox(false)}
                    onOpen={() => setShowActionBox(true)}
                  />
                )}
              </div>
            )}
          </>
        )}
      </div>
    </Drawer>
  );
};

export default ResourceViewerDrawer;
