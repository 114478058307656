import SuccessCard from 'components/card/SuccessCard';
import React from 'react';
import { useTranslation } from 'react-i18next';

const ResetSuccessPage = () => {
  const { t } = useTranslation();

  return (
    <div className='flex h-screen items-center justify-center px-4 md:px-0'>
      <SuccessCard
        hasCta
        ctaText={t('log-in') || 'Login'}
        ctaLink='/login'
        title={t('success.reset_password')}
        subtext={t('success.reset_password_subtitle')}
      />
    </div>
  );
};

export default ResetSuccessPage;
