import { useQuery } from '@tanstack/react-query';
import { getExpertises } from 'utils/api/profile';

export const useExpertise = () => {
  return useQuery({
    queryKey: ['expertise'],
    queryFn: () => getExpertises(),
    select: (data) => data.data.expertise,
  });
};
