import Editor from '@draft-js-plugins/editor';
import createMentionPlugin, { MentionData } from '@draft-js-plugins/mention';
import 'components/layout/style.css';
import { Tab } from '@headlessui/react';
import BodyText from 'components/typography/BodyText';
import { EditorState } from 'draft-js';
import 'draft-js/dist/Draft.css';
import debounce from 'lodash/debounce';
import React, {
  ReactElement,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useRef,
} from 'react';
import { useTranslation } from 'react-i18next';

export interface EntryComponentProps {
  id: string;
  role: string;
  className?: string;
  mention: MentionData;
  onMouseUp(event: MouseEvent): void;
  onMouseDown(event: MouseEvent): void;
  onMouseEnter(event: MouseEvent): void;
  'aria-selected'?: boolean | 'false' | 'true';
}

function Entry(props: EntryComponentProps): ReactElement {
  const {
    id,
    role,
    mention,
    onMouseUp,
    className,
    onMouseDown,
    onMouseEnter,
    'aria-selected': ariaSelected,
  } = props;

  return (
    // eslint-disable-next-line jsx-a11y/no-static-element-interactions
    <div
      id={id}
      role={role}
      onMouseUp={onMouseUp as any}
      className={className}
      onMouseDown={onMouseDown as any}
      onMouseEnter={onMouseEnter as any}
      aria-selected={ariaSelected}
    >
      <div className='flex items-center gap-3 w-full h-full'>
        {mention.logo && (
          <img
            alt={mention.name}
            src={mention.logo}
            role='presentation'
            className='h-6 w-6 rounded-full'
          />
        )}

        <div className='flex flex-col '>
          <BodyText variant='sm' className='font-medium '>
            {mention.name}
          </BodyText>
          <BodyText variant='xs' className='text-neutral-400'>
            {mention.subtitle}
          </BodyText>
        </div>
      </div>
    </div>
  );
}

const MentionEditor = ({
  mentions,
  searchString,
  setSearchString,
  selectedTypeIndex,
  setSelectedTypeIndex,
  mentionTabs,
  open,
  setOpen,
  editorState,
  setEditorState,
  isTopPosition,
}: {
  mentions: MentionData[];
  searchString: string;
  setSearchString: React.Dispatch<SetStateAction<string>>;
  selectedTypeIndex: number;
  setSelectedTypeIndex: React.Dispatch<SetStateAction<number>>;
  mentionTabs: { tabIndex: number; label: string }[];
  open: boolean;
  setOpen: React.Dispatch<SetStateAction<boolean>>;
  editorState: EditorState;
  setEditorState: React.Dispatch<SetStateAction<EditorState>>;
  isTopPosition?: boolean;
}) => {
  const { t } = useTranslation();
  const ref = useRef<Editor>(null);

  const { MentionSuggestions, plugins } = useMemo(() => {
    const mentionPlugin = createMentionPlugin({
      supportWhitespace: false,
    });
    const { MentionSuggestions } = mentionPlugin;
    const plugins = [mentionPlugin];
    return { plugins, MentionSuggestions };
  }, []);

  const onOpenChange = useCallback(
    (_open: boolean) => {
      setOpen(_open);
    },
    [open, setOpen],
  );

  const debounceSearch = useCallback(
    debounce((value) => setSearchString(value), 300),
    [],
  );

  const onSearchChange = useCallback(
    ({ value }: { value: string }) => {
      debounceSearch(value);
      // setSuggestions(defaultSuggestionsFilter(value, mentions));
    },
    [mentions],
  );

  useEffect(() => {
    onSearchChange({ value: searchString });
  }, [selectedTypeIndex]);

  return (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
    <div
      className={`${
        isTopPosition ? 'min-h-[30px]' : 'min-h-[60px]'
      }  rounded-md border border-neutral-300 bg-neutral-100 p-1`}
      onClick={() => {
        ref.current?.focus();
      }}
    >
      <Editor
        ref={ref}
        plugins={plugins}
        editorKey='editor'
        editorState={editorState}
        onChange={(editorState) => setEditorState(editorState)}
        placeholder={t('write-something-here') || 'Write something here'}
      />

      <MentionSuggestions
        open={open}
        renderEmptyPopup
        entryComponent={Entry as any}
        suggestions={mentions}
        onOpenChange={onOpenChange}
        onSearchChange={onSearchChange}
        // eslint-disable-next-line react/no-unstable-nested-components
        popoverContainer={({ children }) => (
          <div
            className={`absolute z-40 overflow-hidden rounded-xl border border-neutral-300 ${
              isTopPosition ? 'bottom-12' : ''
            }`}
          >
            <Tab.Group defaultIndex={selectedTypeIndex} onChange={setSelectedTypeIndex}>
              <Tab.List className='border-b-2 border-neutral-300 bg-neutral-200'>
                {mentionTabs.map(({ tabIndex, label }) => (
                  <Tab
                    key={tabIndex}
                    className='outline-none bg-neutral-100'
                    onClick={(e: { preventDefault: () => void }) => {
                      e.preventDefault();
                      setSelectedTypeIndex(tabIndex);
                    }}
                  >
                    {({ selected }) => (
                      <BodyText
                        variant='sm'
                        className={`block px-3 py-2  ${
                          selected ? 'bg-secondary-200 text-secondary-600 text-neutral-200' : ''
                        }`}
                      >
                        {t(label)}
                      </BodyText>
                    )}
                  </Tab>
                ))}
              </Tab.List>
              <div className='h-[200px] overflow-y-auto bg-neutral-200'>{children}</div>
            </Tab.Group>
          </div>
        )}
      />
    </div>
  );
};

export default MentionEditor;
