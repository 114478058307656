import CloseIcon from 'assets/icons/close.svg';
import Drawer from 'components/Drawer';
import Heading from 'components/typography/Heading';
import { Conversation } from 'components/message/Conversation';
import React from 'react';
import { useTranslation } from 'react-i18next';

type ISingleConversationDrawer = {
  conversationId: string;
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

const SingleConversationDrawer = ({
  conversationId,
  isOpen,
  setIsOpen,
}: ISingleConversationDrawer) => {
  const { t } = useTranslation();

  return (
    <Drawer isOpen={isOpen} setIsOpen={setIsOpen} size={`max-w-xl`}>
      <div className='flex items-center justify-between border-b-2 p-2'>
        <Heading variant='h3' className='font-semibold capitalize'>
          {t('conversation')}
        </Heading>
        <button
          onClick={() => {
            setIsOpen(false);
          }}
          className='relative flex h-6 w-6 items-center justify-center rounded-full text-functional-danger-400 hover:bg-neutral-300 active:bg-neutral-400'
        >
          <CloseIcon className='h-4 w-4 ' />
        </button>
      </div>
      <div className='h-[calc(100vh-90px)] flex-1 overflow-hidden pb-2 md:h-[calc(100vh-60px)]'>
        {conversationId && <Conversation conversationId={conversationId} />}
      </div>
    </Drawer>
  );
};

export default SingleConversationDrawer;
