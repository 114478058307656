import Loading from 'components/Loading';
import ConfirmationModal from 'components/modal/ConfirmationModal';
import { SubmitHandler, useForm } from 'react-hook-form';
import EditIcon from 'assets/icons/edit.svg';
import DeleteIcon from 'assets/icons/delete.svg';
import Button from 'components/button/Button';
import { OutputData } from '@editorjs/editorjs';
import { ContextMenu } from 'components/common/ContextMenu';
import NavigateTo404 from 'components/NavigateTo404';
import BodyText from 'components/typography/BodyText';
import Heading from 'components/typography/Heading';
import { useCommunity } from 'hooks/api/community/useCommunity';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import RichTextEditor from 'components/common/RichTextEditorWithUpload';
import JoinRequests from './JoinRequests';
import PendingInvites from './PendingInvites';
import UpcomingEvents from './UpcomingEvents';
import UpcomingTasks from './UpcomingTasks';
import { objToFormData } from 'utils/rest';
import { useCommunityHomeBlock } from 'hooks/api/pages/useHomeBlocks';
import { addCommunityHomeBlock, deleteCommunityHomeBlock } from 'utils/api/pages';
import { notifySuccess, notifyTranslatedError } from 'utils/notify';

const CommunityHome = () => {
  const { t } = useTranslation();
  const { communityId } = useParams();
  const { data: community, isLoading: isCommunityLoading } = useCommunity(communityId || '');
  const { data: block, refetch: refetchBlock } = useCommunityHomeBlock(communityId || '');
  const [showTextEditor, setShowTextEditor] = useState(false);
  const [showFullDescription, setShowFullDescription] = useState(false);
  const [openDeleteConfirmationModal, setOpenDeleteConfirmationModal] = useState(false);
  const [content, setContent] = useState<OutputData | null>(null);
  const [loading, setLoading] = useState(false);

  if (isCommunityLoading) return <Loading />;
  if (!community) return <NavigateTo404 />;

  const { id: communityUid, canManage: originalCanManage, description, isGroup } = community;
  const canManage = originalCanManage || isGroup;
  const blockContent = block?.content;
  const blockContentId = block?.id;

  //code for home block
  const {
    handleSubmit,
    formState: { errors },
  } = useForm<{ content: string }>();
  const onSubmit: SubmitHandler<{ content: string }> = (data) => {
    if (!communityId) return;
    const newData = {
      content: content ? JSON.stringify(content) : blockContent ? blockContent : '',
    };
    const formData = objToFormData(newData);
    addCommunityHomeBlock(communityId, formData)
      .then(() => {
        notifySuccess(t('success.api.content_saved'));
        refetchBlock();
        setShowTextEditor(false);
      })
      .catch((error) => {
        notifyTranslatedError(t, error.response.data?.message);
        notifyTranslatedError(t, error.response.data?.errorCode);
      });
  };

  const handleDeleteBlock = (blockId: string) => {
    if (!communityId) return;
    setOpenDeleteConfirmationModal(false);
    setLoading(true);
    deleteCommunityHomeBlock(blockId, communityId) // return the promise here
      .then(() => {
        notifySuccess(t('success.api.content_saved'));
        setLoading(false);
        refetchBlock();
      })
      .catch((error) => {
        notifyTranslatedError(t, error.response.data?.message);
        notifyTranslatedError(t, error.response.data?.errorCode);
      });
  };

  return (
    <div className='relative mb-7 grid grid-cols-5 items-start gap-3'>
      <div className='col-span-5 space-y-3  xl:col-span-3'>
        <div className='rounded-xl bg-neutral-200 shadow shadow-neutral-300'>
          {community.cover && (
            <div className='relative'>
              <div
                key={community.updatedAt}
                style={{
                  backgroundImage: `url(${community.cover})`,
                }}
                className='relative h-[115px] w-full rounded-t-2xl bg-cover bg-center object-cover md:h-[200px]'
              ></div>
            </div>
          )}
          <div className='space-y-2 p-4'>
            <BodyText variant='base' className='text-semibold'>
              {community.organization.name}
            </BodyText>
            <Heading variant='h2'>{community.name}</Heading>
            <div
              className={`text-neutral-500 ${
                showFullDescription ? '' : 'max-h-[200px] overflow-hidden'
              }`}
            >
              <RichTextEditor initialContent={description} isReadOnly={true} />
            </div>

            {description.length > 500 &&
              (showFullDescription ? (
                <button
                  className='text-right text-sm font-bold text-functional-info-400 hover:underline'
                  onClick={() => setShowFullDescription(false)}
                >
                  {t('show_less')}...
                </button>
              ) : (
                <button
                  className='text-right text-sm font-bold text-functional-info-400 hover:underline'
                  onClick={() => setShowFullDescription(true)}
                >
                  {t('show_more')}...
                </button>
              ))}
          </div>
        </div>

        {!showTextEditor && blockContent && (
          <div className='relative'>
            {canManage && (
              <div className='absolute right-0 top-0 z-10'>
                <ContextMenu
                  menuItems={[
                    {
                      key: 'edit',
                      label: t('edit'),
                      icon: EditIcon,
                      callback: () => setShowTextEditor(true),
                    },
                    {
                      key: 'delete',
                      label: t('delete'),
                      icon: DeleteIcon,
                      callback: () => setOpenDeleteConfirmationModal(true),
                    },
                  ]}
                />
              </div>
            )}
            <div className='flex flex-col rounded-xl bg-neutral-200 p-3 shadow shadow-neutral-300'>
              {loading ? (
                <div className='flex items-center justify-center text-center'>
                  <Loading />
                </div>
              ) : (
                <RichTextEditor initialContent={blockContent} isReadOnly={true} />
              )}
            </div>
          </div>
        )}

        {canManage && !showTextEditor && !blockContent && (
          <div className='flex w-full justify-center'>
            <Button
              size='small'
              variant='secondary-outlined'
              onClick={() => {
                setShowTextEditor(true);
              }}
            >
              <span className=''>{t('add-content')}</span>
              <h2 className='text-bold '> + </h2>
            </Button>
          </div>
        )}
        {canManage && showTextEditor && (
          <div className='rounded-xl bg-neutral-200 px-4 shadow shadow-neutral-300'>
            <form onSubmit={handleSubmit(onSubmit)} className='ml-7'>
              <RichTextEditor
                initialContent={blockContent}
                handleOnChange={(content) => setContent(content)}
                isReadOnly={false}
              />
              <div className='flex justify-end p-2'>
                <Button size='default' variant='secondary' type='submit'>
                  {t('save')}
                </Button>
              </div>
            </form>
          </div>
        )}
      </div>

      <div className='sticky top-0 col-span-5 space-y-2 xl:col-span-2'>
        {canManage && <PendingInvites communityId={communityUid} />}
        {canManage && <JoinRequests communityId={communityUid} />}
        <UpcomingEvents originId={communityUid} />
        <UpcomingTasks originId={communityUid} />
      </div>
      {openDeleteConfirmationModal && (
        <ConfirmationModal
          isOpen={openDeleteConfirmationModal}
          label={t('cancel') || 'Cancel'}
          title={t('confirmation-general')}
          onClose={() => setOpenDeleteConfirmationModal(false)}
          onConfirm={() => {
            handleDeleteBlock(blockContentId || '');
          }}
        />
      )}
    </div>
  );
};

export default CommunityHome;
