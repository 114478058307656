import DeleteIcon from 'assets/icons/delete.svg';
import Button from 'components/button/Button';
import UploadFileAreaButton from 'components/button/UploadFileAreaButton';
import Select from 'components/form/Select';
import { createPage, deletePage } from 'utils/api/pages';
import Textarea from 'components/form/Textarea';
import TextInput from 'components/form/TextInput';
import BodyText from 'components/typography/BodyText';
import Uploading from 'components/Uploading';
import { RESOURCE_FILE_SIZE_LIMIT } from 'constants/common';
import { useMaterialGroups } from 'hooks/api/material/useMaterialGroups';
import { useFileUploadProgress } from 'hooks/common/useFileUploadProgress';
import { useInvalidateQueries } from 'hooks/common/useInvalidatequeries';
import React, { useMemo, useRef, useEffect, useState } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { MaterialFormInputType } from 'types/components/form';
import { addMaterial, addMaterialToGroup } from 'utils/api/material';
import { notifyError, notifySuccess, notifyTranslatedError } from 'utils/notify';
import { objToFormData } from 'utils/rest';
import ModalContainer from './ModalContainer';
import { useNavigate } from 'react-router-dom';
import PageSelection from '../common/PageSelection';

interface IAddMaterialModal {
  isOpen: boolean;
  programId: string;
  onClose: () => void;
}

const AddMaterialModal = ({ isOpen, onClose, programId }: IAddMaterialModal) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [pageType, setPageType] = useState('new-page');
  const [pageTitle, setPageTitle] = useState('');
  const [selectedPage, setSelectedPage] = useState<{ id: string; title: string } | undefined>();
  const fileInputRef = useRef<HTMLInputElement>(null);
  const fileValidator = (file: FileList | null) => {
    if (watch('type') === 'file') {
      return file !== null ? true : t('required_error_message');
    }
    return true;
  };

  const urlValidator = (url: string) => {
    if (watch('type') === 'url') {
      return url.trim() !== '' ? true : t('required_error_message');
    }
    return true;
  };
  const { materialGroups } = useMaterialGroups(programId);
  const { invalidateProgramMaterials } = useInvalidateQueries();
  const { isUploading, setIsUploading, progress, onUploadProgress } = useFileUploadProgress();

  const groupSelectOptions = [
    { value: 'no_category', label: t('no-category') },
    ...useMemo(
      () => materialGroups?.map(({ id, name }) => ({ value: id, label: name })) ?? [],
      [materialGroups],
    ),
  ];

  const {
    watch,
    control,
    setValue,
    register,
    unregister,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<MaterialFormInputType>({ defaultValues: { group: 'no_category' } });

  useEffect(() => {
    if (watch('type') === 'url') {
      reset({ title: '', description: '', url: '', type: 'url', group: 'no_category' });
      unregister('file');
    } else if (watch('type') === 'file') {
      reset({ title: '', description: '', file: undefined, type: 'file', group: 'no_category' });
      register('file', { validate: fileValidator });
    } else if (watch('type') === 'page') {
      reset({ title: '', description: '', type: 'page', group: 'no_category' });
      unregister('file');
    }
  }, [watch('type')]);

  const onSubmit: SubmitHandler<MaterialFormInputType> = async (data) => {
    const { file, group, ...rest } = data;
    const formData = objToFormData({ ...rest });
    if (data.type === 'file' && file) {
      formData.append('file', file[0]);
    } else if (data.type === 'url') {
      formData.set('url', rest.url.trim());
    }
    if (data.type === 'page' && pageType === 'my-page') {
      if (!selectedPage?.id) {
        notifyError(t('select_page_error'));
        return;
      }
      formData.append('pageId', selectedPage.id);
    }
    setIsUploading(true);

    if (data.type === 'page' && pageType === 'new-page') {
      if (pageTitle.trim().length < 1) {
        notifyTranslatedError(t, 'Title must be at least 1 character long');
        return;
      }
      const response = await createPage(pageTitle); // Assuming createPage now accepts pageTitle as a parameter
      formData.append('pageId', response.data.pageId);
    }

    // no group
    if (group === 'no_category') {
      addMaterial(programId, formData, { onUploadProgress })
        .then(() => {
          onClose();
          setIsUploading(false);
          invalidateProgramMaterials(programId);
          notifySuccess(t('resource-added'));
          //if pageType is new-page navigate to new page
          if (data.type === 'page' && pageType === 'new-page') {
            navigate(`/pages/${formData.get('pageId')}`);
          }
        })
        .catch((error) => {
          setIsUploading(false);
          notifyTranslatedError(t, error.response.data?.errorCode);
          notifyTranslatedError(t, error.response.data?.message);
          if (data.type === 'page' && pageType === 'new-page') {
            deletePage(formData.get('pageId') as string);
          }
        });
    } else {
      addMaterialToGroup(programId, group, formData, { onUploadProgress })
        .then(() => {
          onClose();
          setIsUploading(false);
          invalidateProgramMaterials(programId);
          notifySuccess(t('resource-added'));
          //if pageType is new-page navigate to new page
          if (data.type === 'page' && pageType === 'new-page') {
            navigate(`/pages/${formData.get('pageId')}`);
          }
        })
        .catch((error) => {
          setIsUploading(false);
          notifyTranslatedError(t, error.response.data?.errorCode);
          notifyTranslatedError(t, error.response.data?.message);
          if (data.type === 'page' && pageType === 'new-page') {
            deletePage(formData.get('pageId') as string);
          }
        });
    }
  };

  const handleSelectFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files?.length) return;
    if (e.target.files[0].size > RESOURCE_FILE_SIZE_LIMIT) {
      notifyError(t('file-too-big'));
      return;
    }
    setValue('file', e.target.files);
  };

  const handleRemoveFile = () => {
    reset({ file: undefined, type: 'file' });
  };

  return (
    <ModalContainer title={t('add-item')} isOpen={isOpen} onClose={onClose}>
      <form onSubmit={handleSubmit(onSubmit)} className='space-y-3'>
        <div className='space-y-2'>
          <BodyText
            variant='sm'
            className={`font-medium ${
              errors.type ? 'text-functional-danger-400' : 'text-neutral-500'
            }`}
          >
            {t('resource_type')}
          </BodyText>
          <Controller
            name='type'
            control={control}
            rules={{
              required: {
                value: true,
                message: t('required_error_message'),
              },
            }}
            render={({ field: { value, ...props } }) => (
              <div className='flex items-center gap-2'>
                <button
                  type='button'
                  className='flex grow items-center gap-4 rounded-xl bg-neutral-100 p-4'
                  onClick={() => setValue('type', 'file')}
                >
                  <input type='radio' value='file' checked={watch('type') === 'file'} {...props} />
                  <BodyText variant='sm' className='font-medium uppercase text-neutral-400'>
                    {t('file')}
                  </BodyText>
                </button>
                <button
                  type='button'
                  className='flex grow items-center gap-4 rounded-xl bg-neutral-100 p-4'
                  onClick={() => setValue('type', 'url')}
                >
                  <input type='radio' value='url' checked={watch('type') === 'url'} {...props} />
                  <BodyText variant='sm' className='font-medium text-neutral-400'>
                    {t('url')}
                  </BodyText>
                </button>
                <button
                  type='button'
                  className='flex grow items-center gap-4 rounded-xl bg-neutral-100 p-4'
                  onClick={() => setValue('type', 'page')}
                >
                  <input type='radio' value='page' checked={watch('type') === 'page'} {...props} />
                  <BodyText variant='sm' className='font-medium text-neutral-400'>
                    {t('my-pages')}
                  </BodyText>
                </button>
              </div>
            )}
          />
          {errors.type && (
            <BodyText variant='xs' className='text-functional-danger-400'>
              {errors.type?.message}
            </BodyText>
          )}
        </div>
        <div className='space-y-2'>
          <BodyText
            variant='sm'
            className={`font-medium ${
              errors.group ? 'text-functional-danger-400' : 'text-neutral-500'
            }`}
          >
            {t('category')}
          </BodyText>

          <Controller
            name='group'
            control={control}
            rules={{ required: true }}
            render={({ field }) => (
              <Select
                {...field}
                options={groupSelectOptions}
                className={errors.group ? 'border-functional-danger-400' : ''}
              />
            )}
          />
        </div>
        {watch('type') !== 'page' && (
          <div className='space-y-2'>
            <BodyText
              variant='sm'
              className={`font-medium ${
                errors.title ? 'text-functional-danger-400' : 'text-neutral-500'
              }`}
            >
              {t('title')}
            </BodyText>

            <Controller
              name='title'
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <TextInput
                  {...field}
                  placeholder={t('title') || ''}
                  className={errors.title ? 'border-functional-danger-400' : ''}
                />
              )}
            />
          </div>
        )}
        <div className='space-y-2'>
          <BodyText
            variant='sm'
            className={`font-medium ${
              errors.description ? 'text-functional-danger-400' : 'text-neutral-500'
            }`}
          >
            {t('description')}
          </BodyText>

          <Controller
            name='description'
            control={control}
            rules={{ required: false }}
            render={({ field }) => (
              <Textarea
                {...field}
                placeholder={`${t('description')}...`}
                className={errors.description ? 'border-functional-danger-400' : ''}
              />
            )}
          />
        </div>
        <div className='space-y-1'>
          {watch('type') === 'file' && (
            <div className='space-y-1'>
              <BodyText
                variant='sm'
                className={`font-medium ${
                  errors.file ? 'text-functional-danger-400' : 'text-neutral-500'
                }`}
              >
                {t('upload-file')}
              </BodyText>

              {watch('file') ? (
                <div className='flex items-center gap-3 rounded-md border border-functional-info-dark bg-functional-info-light px-4 py-2'>
                  <div>
                    <BodyText variant='sm' className='font-semibold'>
                      {watch('file')[0].name}
                    </BodyText>
                    <BodyText variant='xs'>
                      {(watch('file')[0].size / 1024 / 1024).toFixed(2)} MB
                    </BodyText>
                  </div>

                  <button
                    className='ml-auto flex h-7 w-7 items-center justify-center rounded-full text-functional-danger-dark hover:bg-neutral-300 active:bg-neutral-400'
                    onClick={handleRemoveFile}
                  >
                    <DeleteIcon className='h-5 w-5' />
                  </button>
                </div>
              ) : (
                <UploadFileAreaButton
                  maxFileSize={500}
                  isError={!!errors.file}
                  onClick={() => fileInputRef.current?.click()}
                />
              )}

              <input
                type='file'
                className='hidden'
                {...register('file', {
                  validate: fileValidator,
                  onChange: (e) => handleSelectFile(e),
                })}
                ref={fileInputRef}
              />
            </div>
          )}

          {watch('type') === 'url' && (
            <div className='space-y-2'>
              <BodyText
                variant='sm'
                className={`font-medium ${
                  errors.url ? 'text-functional-danger-400' : 'text-neutral-500'
                }`}
              >
                {t('url')}
              </BodyText>
              <Controller
                name='url'
                control={control}
                rules={{ validate: urlValidator }}
                render={({ field }) => (
                  <TextInput
                    {...field}
                    placeholder={t('url') || 'https://google.com'}
                    className={errors.url ? 'border-functional-danger-400' : ''}
                  />
                )}
              />
            </div>
          )}
          {watch('type') === 'page' && (
            <div className='max-h-[350px] space-y-4 overflow-y-auto'>
              <div className='flex items-center gap-2'>
                <button
                  type='button'
                  className='flex grow items-center gap-4 rounded-xl bg-neutral-100 p-3'
                  onClick={() => setPageType('new-page')}
                >
                  <input type='radio' value='file' checked={pageType === 'new-page'} />
                  <BodyText variant='sm' className='font-medium text-neutral-400'>
                    {t('create-page')}
                  </BodyText>
                </button>

                <button
                  type='button'
                  className='flex grow items-center gap-4 rounded-xl bg-neutral-100 p-3'
                  onClick={() => setPageType('my-page')}
                >
                  <input type='radio' value='page' checked={pageType === 'my-page'} />
                  <BodyText variant='sm' className='font-medium text-neutral-400'>
                    {t('select-page')}
                  </BodyText>
                </button>
              </div>
              {pageType === 'new-page' && (
                <div>
                  {' '}
                  <BodyText variant='sm' className='font-medium text-neutral-500'>
                    {t('title')}
                  </BodyText>
                  <TextInput
                    type='text'
                    placeholder={t('write-message-placeholder')}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                      setPageTitle(e.target.value)
                    }
                  />
                </div>
              )}
              {pageType === 'my-page' && (
                <PageSelection setSelectedPage={setSelectedPage} selectedPage={selectedPage} />
              )}
            </div>
          )}
        </div>
        {isUploading && <Uploading progress={progress} />}
        <div className='mt-5 flex items-center justify-end gap-3 border-t border-neutral-200 pt-5'>
          <Button type='button' size='small' variant='outlined' onClick={onClose}>
            {t('cancel')}
          </Button>
          <Button type='submit' size='small' variant='secondary'>
            {t('add-item')}
          </Button>
        </div>
      </form>
    </ModalContainer>
  );
};

export default AddMaterialModal;
