import { useInfiniteQuery } from '@tanstack/react-query';
import { getMaterialGroups } from 'utils/api/material';

export const useMaterialGroups = (programId: string, limit?: number) => {
  const { data, isLoading, isFetching, refetch, hasNextPage, fetchNextPage } = useInfiniteQuery({
    queryKey: ['program-material-groups', programId],
    queryFn: ({ pageParam = '' }) => getMaterialGroups(programId, limit, pageParam),
    getNextPageParam: (res) => res.data.cursor,
  });

  return {
    refetchGroups: refetch,
    isGroupLoading: isLoading,
    hasNextGroups: hasNextPage,
    isGroupFetching: isFetching,
    fetchNextGroup: fetchNextPage,
    materialGroups: data?.pages.flatMap((page) => page.data.materialGroups),
  };
};
