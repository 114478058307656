import { useQuery } from '@tanstack/react-query';
import { getAdmins } from 'utils/api/admin';

export const useAdmins = () => {
  return useQuery({
    queryKey: ['admins'],
    queryFn: () => getAdmins(),
    select: (data) => data.data.admins,
  });
};
