import { WEB_ROOT } from 'constants/config';

export const generatePostLink = (
  originId: string,
  originType: 'community' | 'program',
  postId: string,
) => {
  if (originType === 'community') return `${WEB_ROOT}/communities/${originId}/feed/posts/${postId}`;
  if (originType === 'program') return `${WEB_ROOT}/programs/${originId}/feed/posts/${postId}`;
  return '';
};

export const generateInviteLink = (
  inviteToken: string,
  originId: string,
  originType: 'community' | 'program',
  orgId?: string,
) => {
  let baseUrl = `${WEB_ROOT}/invite?originId=${originId}&originType=${originType}&inviteToken=${inviteToken}`;
  if (orgId) {
    baseUrl += `&orgId=${orgId}`;
  }
  return baseUrl;
};

export const generateGuestAccessLink = (
  originId: string,
  originType: 'community' | 'program' | 'pages',
  orgId?: string,
) => {
  let link = `${WEB_ROOT}/guest/${originType}/${originId}`;
  if (orgId) {
    link += `?orgId=${orgId}`;
  }
  return link;
};

export const generateCommunityPostLink = (communityId: string, postId: string) =>
  `${WEB_ROOT}/communities/${communityId}/feed/posts/${postId}`;

export const generateProgramPostLink = (programId: string, postId: string) =>
  `${WEB_ROOT}/programs/${programId}/feed/posts/${postId}`;

export const generateCommunityTaskSubmissionLink = (
  communityId: string,
  taskId: string,
  taskName?: string,
  deadline?: string,
) =>
  `/communities/${communityId}/tasks/${taskId}/submissions?taskName=${taskName}&deadline=${deadline}`;
export const generateProgramTaskSubmissionLink = (
  programId: string,
  taskId: string,
  taskName?: string,
  deadline?: string,
) => `/programs/${programId}/tasks/${taskId}/submissions?taskName=${taskName}&deadline=${deadline}`;

export const generateTaskSubmissionLink = (
  originType: 'community' | 'program',
  originId: string,
  taskId: string,
  taskName?: string,
  deadline?: string,
) => {
  if (originType === 'community')
    return generateCommunityTaskSubmissionLink(originId, taskId, taskName, deadline);
  if (originType === 'program')
    return generateProgramTaskSubmissionLink(originId, taskId, taskName, deadline);
  return '';
};

export const generateCommunityEventLink = (communityId: string, eventId: string) =>
  `/communities/${communityId}/events/${eventId}`;

export const generateProgramEventLink = (programId: string, eventId: string) =>
  `/programs/${programId}/events/${eventId}`;

export const generateEventLink = (
  originType: 'community' | 'program',
  originId: string,
  taskId: string,
) => {
  if (originType === 'community') return generateCommunityEventLink(originId, taskId);
  if (originType === 'program') return generateProgramEventLink(originId, taskId);
  return '';
};

export const generateCommunityTaskLink = (communityId: string, taskId: string) =>
  `/communities/${communityId}/tasks/${taskId}`;

export const generateProgramTaskLink = (programId: string, taskId: string) =>
  `/programs/${programId}/tasks/${taskId}`;

export const generateTaskLink = (
  originType: 'community' | 'program',
  originId: string,
  taskId: string,
) => {
  if (originType === 'community') return generateCommunityTaskLink(originId, taskId);
  if (originType === 'program') return generateProgramTaskLink(originId, taskId);
  return '';
};

export const generateConversationLink = (conversationId: string) => `/messages/${conversationId}`;

export const generateUserProfileLink = (userId: string) => `/profile/${userId}`;

export const generateCommunityLink = (communityId: string) => `/communities/${communityId}`;

export const generateProgramLink = (programId: string) => `/programs/${programId}`;

export const generateGuestOriginLink = (originId: string, originType: string, orgId?: string) => {
  let link = `/guest/${originType}/${originId}`;
  if (orgId) {
    link += `?orgId=${orgId}`;
  }
  return link;
};

export const generateGuestDiscoverLink = (originId: string, originType: string) =>
  `/discover/${originType}/${originId}`;

export const generateSinglePostLink = (postId: string) => `posts/${postId}`;

export const generateOriginLink = (originId: string, originType: string) => {
  if (originType === 'community') return generateCommunityLink(originId);
  if (originType === 'program') return generateProgramLink(originId);
  return '';
};

export const stripTrailingSlash = (str: string) => {
  return str.endsWith('/') ? str.slice(0, -1) : str;
};

export const generateResourcePageLink = (communityId: string) =>
  `/communities/${communityId}/resources`;
export const generateMaterialPageLink = (programId: string) => `/programs/${programId}/materials`;

export const generateResourceMaterialPageLink = (
  originType: 'program' | 'community',
  originId: string,
) => {
  switch (originType) {
    case 'program':
      return generateMaterialPageLink(originId);
    case 'community':
      return generateResourcePageLink(originId);
    default:
      return undefined;
  }
};

export const generateSinglePageLink = (pageId: string) => `${WEB_ROOT}/pages/${pageId}`;
