import Avatar from 'components/memberAndProfile/Avatar';
import PinIcon from 'assets/icons/pin.svg';
import GroupIcon from 'assets/icons/community.svg';
import BodyText from 'components/typography/BodyText';
import { userAvatar } from 'constants/common';
import { timeSince } from 'utils/time';
import React from 'react';
import { Link } from 'react-router-dom';
import { GetConversationsListResponseType } from 'types/apis/response';
import { generateConversationLink } from 'utils/url';
import Tag from 'components/tag/Tag';
import { convertHtmlToLink, editorContentToText, sanitizeHtml } from 'utils/helper';

type ConversationType = GetConversationsListResponseType['data']['conversations'][0];

export const ConversationThumb = ({
  conversationType,
  onClick,
  currentConversation,
  id,
  alias,
  participants,
  conversationFlagged,
  community,
  program,
  lastMessage,
  unread,
  displayAvatar,
}: {
  conversationType: 'group' | 'private' | 'private_chatbot' | 'embed' | 'embed_self' | 'embed_all';
  onClick?: () => void;
  currentConversation?: boolean;
  id: string;
  alias?: string;
  conversationFlagged?: boolean;
  participants: ConversationType['participants'];
  community?: ConversationType['community'];
  program?: ConversationType['program'];
  lastMessage: ConversationType['lastMessage'];
  unread: boolean;
  displayAvatar: string | null;
}) => {
  const isGroup = conversationType === 'group';
  const groupAvatar = community?.cover ? community.cover : program?.cover;
  return (
    <Link
      onClick={onClick}
      to={generateConversationLink(id)}
      className={`mb-2 flex items-center rounded-xl p-1 hover:bg-secondary-400 hover:text-secondary-100 ${
        currentConversation ? 'bg-secondary-200' : ''
      }
      ${unread ? 'bg-primary-200 font-bold' : ''}
     `}
    >
      <div className='relative flex-shrink-0'>
        <Avatar size={40} src={isGroup ? groupAvatar || userAvatar : displayAvatar || userAvatar} />
        {isGroup && !groupAvatar && (
          <div className='absolute bottom-0 left-0 right-0 top-0 flex items-center justify-center rounded-full bg-neutral-500'>
            <GroupIcon className='h-5 w-5 text-neutral-100' />
          </div>
        )}
        {conversationFlagged && (
          <button className='absolute -bottom-1 -right-1 flex h-4 w-4 items-center justify-center rounded-full rounded-full bg-functional-info-light text-functional-info-dark'>
            <PinIcon className='h-3 w-3' />
          </button>
        )}
      </div>
      <div className='ml-2 flex-1 overflow-hidden'>
        <BodyText as='div' variant='base' className='truncate font-medium'>
          {alias && alias !== ''
            ? alias
            : conversationType === 'private'
              ? `${participants[0].firstName} ${participants[0].lastName}`
              : `${participants[0].firstName}${
                  isGroup ? ` & ${participants.length - 1} more` : ''
                }`}
        </BodyText>
        <BodyText as='div' variant='sm' className={`truncate ${unread ? 'text-neutral-600' : ''}`}>
          <span
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{
              __html: sanitizeHtml(
                convertHtmlToLink(editorContentToText(lastMessage?.message || '')),
              ),
            }}
          />
        </BodyText>
      </div>
      <div className='ml-4 flex-shrink-0'>
        <div className='flex justify-end gap-1'>
          {community && !program && (
            <Tag
              type='default'
              className='max-w-[6rem] overflow-hidden bg-functional-success-light justify-left text-functional-success-dark bg-opacity-50'
            >
              <span className='text-left text-xs truncate text-clip'>{community.name}</span>
            </Tag>
          )}
          {program && !community && (
            <Tag
              type='default'
              className='max-w-[6rem] overflow-hidden  bg-functional-info-light justify-left text-functional-info-dark bg-opacity-50'
            >
              <span className='text-left text-xs truncate text-clip'> {program.name}</span>
            </Tag>
          )}
          {program && community && (
            <Tag
              type='default'
              className='max-w-[8rem] overflow-hidden bg-functional-info-light justify-left text-functional-info-dark bg-opacity-50'
            >
              <span className='text-left text-xs truncate text-clip'>
                {community.name}/{program.name}
              </span>
            </Tag>
          )}
        </div>
        <div className='text-right'>
          {lastMessage && (
            <BodyText as='div' variant='xs'>
              {timeSince(new Date(lastMessage.createdAt))}
            </BodyText>
          )}
        </div>
      </div>
    </Link>
  );
};
