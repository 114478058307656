import SearchInput from 'components/form/SearchInput';
import Layout from 'components/layout/Layout';
import LeftIcon from 'assets/icons/chevron-left.svg';
import Heading from 'components/typography/Heading';
import { useFilteredConversationList } from 'hooks/common/useFilteredConversationList';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ConversationThumb } from './ConversationThumb';

const MobileMessagePage = () => {
  const { t } = useTranslation();

  const [showGroup, setShowGroup] = useState(false);
  const [search, setSearch] = useState<string | undefined>();

  const { conversations, filteredConversations } = useFilteredConversationList(
    true,
    showGroup,
    search,
  );

  return (
    <Layout>
      <div className='flex h-full w-full flex-col space-y-3 overflow-hidden pb-7'>
        <div className='flex w-full items-center justify-between gap-3 bg-secondary-100 p-3'>
          <div className='flex items-center'>
            <LeftIcon className='  h-5 w-5 cursor-pointer ' onClick={() => window.history.back()} />
            <Heading variant='h3' className='font-semibold text-secondary-500'>
              {t('chats')}
            </Heading>
          </div>
          <SearchInput
            onSearch={(value) => {
              setSearch(value);
            }}
            className='h-6 rounded-xl'
          />
        </div>
        <div className='h-full overflow-y-auto px-3 py-1'>
          {filteredConversations.map(
            ({
              id,
              participants,
              lastMessage,
              conversationType,
              conversationFlagged,
              alias,
              program,
              community,
              unread,
              displayAvatar,
            }) => (
              <ConversationThumb
                id={id}
                key={id}
                unread={unread}
                participants={participants}
                conversationFlagged={conversationFlagged}
                alias={alias}
                program={program}
                community={community}
                lastMessage={lastMessage}
                conversationType={conversationType}
                displayAvatar={displayAvatar}
              />
            ),
          )}
        </div>
      </div>
    </Layout>
  );
};

export default MobileMessagePage;
