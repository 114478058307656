import SearchInput from 'components/form/SearchInput';
import BodyText from 'components/typography/BodyText';
import Heading from 'components/typography/Heading';
import { useFilteredConversationList } from 'hooks/common/useFilteredConversationList';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ConversationThumb } from './ConversationThumb';

const ConversationList = ({ currentConversationId }: { currentConversationId?: string }) => {
  const { t } = useTranslation();

  const [showGroup, setShowGroup] = useState(false);
  const [search, setSearch] = useState<string | undefined>();

  const { conversations, filteredConversations } = useFilteredConversationList(
    true,
    showGroup,
    search,
  );

  return (
    <div className='hidden h-full w-[420px] flex-col space-y-4 overflow-hidden rounded-bl-lg bg-neutral-200 border-r border-neutral-300 p-3 xl:flex'>
      <div>
        <Heading variant='h3' className='mb-2 font-semibold'>
          {t('chats')}{' '}
          {conversations.length && (
            <BodyText as='span' variant='sm' className='font-medium text-secondary-400'>
              ({conversations.length})
            </BodyText>
          )}
        </Heading>
        <SearchInput
          onSearch={(value) => {
            setSearch(value);
          }}
          className='rounded-xl'
        />
      </div>
      <div className='h-full overflow-y-auto'>
        {filteredConversations.map(
          ({
            id,
            participants,
            alias,
            community,
            program,
            lastMessage,
            conversationFlagged,
            conversationType,
            unread,
            displayAvatar,
          }) => (
            <ConversationThumb
              conversationType={conversationType}
              key={id}
              id={id}
              community={community}
              program={program}
              alias={alias}
              conversationFlagged={conversationFlagged}
              participants={participants}
              lastMessage={lastMessage}
              currentConversation={id === currentConversationId}
              unread={unread}
              displayAvatar={displayAvatar}
            />
          ),
        )}
      </div>
    </div>
  );
};

export default ConversationList;
