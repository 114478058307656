import React, { Fragment } from 'react';
import { Dialog, Transition } from '@headlessui/react';

type DrawerProps = {
  size?: string;
  isOpen: boolean;
  children: React.ReactNode;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  modalOpen?: boolean;
};

export default function Drawer({
  size = 'max-w-md',
  children,
  isOpen,
  setIsOpen,
  modalOpen = false,
}: DrawerProps) {
  const handleClose = () => {
    if (!modalOpen) {
      setIsOpen(false);
    }
  };
  return (
    <Transition.Root show={isOpen} as={Fragment}>
      <Dialog as='div' className='relative z-50' onClose={handleClose}>
        <Transition.Child
          as={Fragment}
          enter='ease-in-out duration-600'
          enterFrom='opacity-0'
          enterTo='opacity-100'
          leave='ease-in-out duration-600'
          leaveFrom='opacity-100'
          leaveTo='opacity-0'
        >
          <div className='fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity' />
        </Transition.Child>

        <div className='fixed inset-0 overflow-hidden'>
          <div className='absolute inset-0 overflow-hidden'>
            <div className='pointer-events-none fixed inset-y-0 right-0 flex max-w-full'>
              <Transition.Child
                as={Fragment}
                enter='transform transition ease-in-out duration-500 sm:duration-700'
                enterFrom='translate-x-full'
                enterTo='translate-x-0'
                leave='transform transition ease-in-out duration-500 sm:duration-700'
                leaveFrom='translate-x-0'
                leaveTo='translate-x-full'
              >
                <Dialog.Panel className={`pointer-events-auto relative w-screen ${size}`}>
                  <div className='flex h-full flex-col overflow-y-auto bg-neutral-100 shadow-xl shadow-neutral-200'>
                    <div className='relative flex-1'>{children}</div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
