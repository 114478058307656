import React from 'react';
import GuestSidenav from './GuestSidenav';
import { useTranslation } from 'react-i18next';

interface iGuestLayout {
  originName: string;
  originLink: string;
  orgId: string;
  orgName: string;
  children: React.ReactNode;
}
function GuestLayout({ originName, originLink, orgName, orgId, children }: iGuestLayout) {
  const { t } = useTranslation();

  return (
    <main className='gap-2 bg-neutral-200 md:flex'>
      <GuestSidenav
        originName={originName}
        originLink={originLink}
        orgId={orgId}
        orgName={orgName}
      />
      <div className='h-full w-full flex-col md:flex md:w-[calc(100%-104px)] lg:w-[calc(100%-223.27px)]'>
        <div className='grow px-2 md:px-0'>{children}</div>
      </div>
    </main>
  );
}

export default GuestLayout;
