import Button from 'components/button/Button';
import TextInput from 'components/form/TextInput';
import BodyText from 'components/typography/BodyText';
import Textarea from 'components/form/Textarea';
import Select from 'components/form/Select';
import React, { useEffect, useMemo } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { updateSingleMaterial } from 'utils/api/material';
import { useMaterialGroups } from 'hooks/api/material/useMaterialGroups';
import { notifySuccess, notifyTranslatedError } from 'utils/notify';
import ModalContainer from './ModalContainer';

interface IEditSingleMaterialModal {
  isOpen: boolean;
  materialId: string;
  groupId: string;
  title?: string;
  description?: string;
  type: string;
  programId: string;
  refetch: () => void;
  onClose: () => void;
}

const EditSingleMaterialModal = ({
  isOpen,
  onClose,
  refetch,
  programId,
  materialId,
  groupId,
  type,
  title,
  description,
}: IEditSingleMaterialModal) => {
  const { t } = useTranslation();
  const { materialGroups } = useMaterialGroups(programId);

  const groupSelectOptions = [
    { value: 'no_category', label: t('no-category') },
    ...useMemo(
      () => materialGroups?.map(({ id, name }) => ({ value: id, label: name })) ?? [],
      [materialGroups],
    ),
  ];

  const {
    control,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm<{ title: string; description: string; groupId: string }>({
    defaultValues: {
      groupId: groupId || 'no_category',
    },
  });

  const onSubmit: SubmitHandler<{ title: string; description: string; groupId: string }> = (
    data,
  ) => {
    if (materialId) {
      updateSingleMaterial(materialId, programId, {
        title: data.title,
        description: data.description,
        groupId: data.groupId,
      })
        .then(() => {
          refetch();
          notifySuccess(t('success.api.resource_updated'));
          onClose();
        })
        .catch((error) => {
          notifyTranslatedError(t, error.response.data?.errorCode);
          notifyTranslatedError(t, error.response.data?.message);
        });
    }
  };

  useEffect(() => {
    if (title) setValue('title', title);
    if (description) setValue('description', description);
    if (groupId) {
      setValue('groupId', groupId);
    } else {
      setValue('groupId', 'no_category');
    }
  }, [title, description, type, groupId]);

  return (
    <ModalContainer title={t('edit')} isOpen={isOpen} onClose={onClose}>
      <form onSubmit={handleSubmit(onSubmit)} className='space-y-5'>
        <div className='space-y-2'>
          <BodyText
            variant='sm'
            className={`font-medium ${
              errors.groupId ? 'text-functional-danger-400' : 'text-neutral-500'
            }`}
          >
            {t('category')}
          </BodyText>

          <Controller
            name='groupId'
            control={control}
            rules={{ required: true }}
            render={({ field }) => (
              <Select
                {...field}
                options={groupSelectOptions}
                defaultValue={groupId}
                className={errors.groupId ? 'border-functional-danger-400' : ''}
              />
            )}
          />
        </div>
        {type !== 'page' && (
          <div className='space-y-2'>
            <BodyText
              variant='sm'
              className={`font-medium ${
                errors.title ? 'text-functional-danger-400' : 'text-neutral-500'
              }`}
            >
              {t('name')}
            </BodyText>

            <Controller
              name='title'
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <TextInput
                  {...field}
                  placeholder={t('title') || 'Resource name'}
                  className={errors.title ? 'border-functional-danger-400' : ''}
                />
              )}
            />
          </div>
        )}
        <div className='space-y-2'>
          <BodyText
            variant='sm'
            className={`font-medium ${
              errors.title ? 'text-functional-danger-400' : 'text-neutral-500'
            }`}
          >
            {t('description')}
          </BodyText>

          <Controller
            name='description'
            control={control}
            rules={{ required: false }}
            render={({ field }) => (
              <Textarea
                {...field}
                placeholder={`${t('description')}...`}
                className={errors.description ? 'border-functional-danger-400' : ''}
              />
            )}
          />
        </div>

        <div className='mt-5 flex items-center justify-end gap-3 border-t border-neutral-200 pt-5'>
          <Button type='button' size='small' variant='outlined' onClick={onClose}>
            {t('cancel')}
          </Button>
          <Button type='submit' size='small' variant='secondary'>
            {t('save')}
          </Button>
        </div>
      </form>
    </ModalContainer>
  );
};

export default EditSingleMaterialModal;
