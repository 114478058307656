import CloseIcon from 'assets/icons/close.svg';
import DeleteIcon from 'assets/icons/delete.svg';
import EditIcon from 'assets/icons/edit.svg';
import TextInput from 'components/form/TextInput';
import Avatar from 'components/memberAndProfile/Avatar';
import Button from 'components/button/Button';
import BodyText from 'components/typography/BodyText';
import Heading from 'components/typography/Heading';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { userAvatar } from 'constants/common';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { SingleRemarkType } from 'types/apis/common';
import ModalContainer from './ModalContainer';
import LinkifiedText from 'components/common/LinkifiedText';

interface IRemarksModal {
  isOpen: boolean;
  canManage: boolean;
  currentUserId?: string;
  onClose: () => void;
  taskNote?: string;
  remarks: SingleRemarkType[];
  handleEditRemark?: (remarkId: string, remarkText: string) => void;
  handleDeleteRemark?: (remarkId: string) => void;
  handleSubmitRemark: (data: { remark: string }) => void;
}

const RemarksModal = ({
  isOpen,
  taskNote,
  remarks,
  onClose,
  canManage,
  currentUserId,
  handleEditRemark,
  handleDeleteRemark,
  handleSubmitRemark,
}: IRemarksModal) => {
  const { t } = useTranslation();
  const {
    control,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm<{ remark: string }>();

  const onSubmit: SubmitHandler<{ remark: string }> = (data) => {
    handleSubmitRemark(data);
  };

  return (
    <ModalContainer title={t('comments')} isOpen={isOpen} onClose={onClose}>
      <div className='space-y-3'>
        {remarks?.map(({ id, user, remark }) => (
          <div
            key={id}
            className='flex items-center justify-between rounded-xl border border-neutral-300 p-3'
          >
            <div className='flex items-center gap-5'>
              <Avatar size={40} src={user.profilePicture || userAvatar} />
              <div>
                <BodyText
                  variant='sm'
                  className='font-medium'
                >{`${user.firstName} ${user.lastName}`}</BodyText>
                <BodyText variant='base' className='about-content'>
                  <LinkifiedText text={remark} />
                </BodyText>
              </div>
            </div>
            {(canManage || currentUserId === user.id) && (
              <div className='shrink-0'>
                <button onClick={() => handleEditRemark?.(id, remark)}>
                  <EditIcon className='h-5 w-5 text-neutral-400' />
                </button>
                <button onClick={() => handleDeleteRemark?.(id)}>
                  <DeleteIcon className='h-5 w-5 text-neutral-400' />
                </button>
              </div>
            )}
          </div>
        ))}
      </div>
      <form onSubmit={handleSubmit(onSubmit)} className='mt-5 rounded-xl bg-neutral-300 p-3'>
        <div>
          <BodyText
            variant='sm'
            className={`font-medium ${
              errors.remark ? 'text-functional-danger-400' : 'text-neutral-500'
            }`}
          >
            {t('add-remark')}
          </BodyText>

          <Controller
            name='remark'
            control={control}
            rules={{ required: true }}
            render={({ field }) => (
              <TextInput
                autoFocus
                {...field}
                placeholder={t('comment') || 'Add comment'}
                className={errors.remark ? 'border-functional-danger-400' : ''}
              />
            )}
          />
        </div>

        <div className='mt-2 flex items-center justify-end gap-3'>
          <Button type='button' size='small' variant='outlined' onClick={onClose}>
            {t('cancel')}
          </Button>
          <Button type='submit' size='small' variant='secondary'>
            {t('save')}
          </Button>
        </div>
      </form>
    </ModalContainer>
  );
};

export default RemarksModal;
