import Logo from 'assets/images/logo.svg';
import EyeIcon from 'assets/icons/eye.svg';
import EyeOffIcon from 'assets/icons/eye-off.svg';
import Button from 'components/button/Button';
import TextInput from 'components/form/TextInput';
import LanguageSelector from 'components/layout/LanguageSelector';
import BodyText from 'components/typography/BodyText';
import Heading from 'components/typography/Heading';
import { useInvalidateQueries } from 'hooks/common/useInvalidatequeries';
import React, { useState } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import { LoginFormInputsType } from 'types/components/form';
import { login } from 'utils/api/auth';
import { notifyTranslatedError } from 'utils/notify';
import AuthCover from './segment/AuthCover';

function LoginPage() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const togglePasswordVisibility = () => setShowPassword(!showPassword);
  const { invalidateMe } = useInvalidateQueries();

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<LoginFormInputsType>({ defaultValues: { remember: false } });

  const onSubmit: SubmitHandler<LoginFormInputsType> = (data) => {
    login(data)
      .then(() => {
        invalidateMe();
        navigate('/');
      })
      .catch((error) => {
        notifyTranslatedError(t, error.response.data?.errorCode);
      });
  };

  return (
    <main className='grid h-screen grid-cols-5'>
      <AuthCover />

      <section className='col-span-5 flex flex-col lg:col-span-2'>
        <div className='pb-7 flex items-center justify-between px-5 py-3 lg:mb-0 bg-neutral-200'>
          <div>
            <div className='flex items-center gap-1 lg:hidden'>
              <div className='flex items-center rounded p-1'>
                <Logo className='h-[20px] w-[20px]' />
              </div>
              <a href='https://www.studycentral.eu/'>
                <BodyText variant='sm' className='text-neutral-600'>
                  STUDY<span className='font-bold'>CENTRAL</span>
                </BodyText>
              </a>
            </div>
          </div>
          <LanguageSelector />
        </div>

        <div className='grow items-center justify-center px-4 md:px-5 lg:flex xl:px-0 bg-neutral-200'>
          <div className='w-full xl:w-3/5'>
            <Heading variant='h2' className='mb-3 font-bold'>
              {t('sign_in_title')}
            </Heading>
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className='mb-4 space-y-1'>
                <BodyText variant='sm' className='font-medium text-neutral-500'>
                  {t('email')}
                </BodyText>
                <Controller
                  name='email'
                  control={control}
                  rules={{
                    required: { value: true, message: t('required_error_message') },
                  }}
                  render={({ field: { name, onBlur, onChange, value } }) => (
                    <TextInput
                      type='email'
                      placeholder={t('email') || 'Email'}
                      name={name}
                      onBlur={onBlur}
                      onChange={onChange}
                      value={value}
                    />
                  )}
                />
                {errors.email && (
                  <BodyText variant='xs' className='text-functional-danger-400'>
                    {errors.email?.message}
                  </BodyText>
                )}
              </div>
              <div className='mb-2 space-y-1'>
                <BodyText variant='sm' className='font-medium text-neutral-500'>
                  {t('password')}
                </BodyText>
                <div className='flex items-center relative'>
                  <Controller
                    name='password'
                    control={control}
                    rules={{
                      required: { value: true, message: t('required_error_message') },
                    }}
                    render={({ field: { name, onBlur, onChange, value } }) => (
                      <TextInput
                        type={showPassword ? 'text' : 'password'}
                        autoComplete='password'
                        placeholder={t('password') || 'Password'}
                        name={name}
                        onBlur={onBlur}
                        onChange={onChange}
                        value={value}
                      />
                    )}
                  />
                  <button
                    type='button'
                    onClick={togglePasswordVisibility}
                    className='absolute inset-y-0 right-0 flex items-center pr-4 z-40 text-neutral-400'
                  >
                    {showPassword ? (
                      <EyeOffIcon className='h-5 w-5' />
                    ) : (
                      <EyeIcon className='h-5 w-5' />
                    )}
                  </button>
                </div>
                {errors.password && (
                  <BodyText variant='xs' className='text-functional-danger-400'>
                    {errors.password?.message}
                  </BodyText>
                )}
              </div>
              <div className='mb-6 flex items-center justify-between'>
                <BodyText variant='sm' className='flex gap-1 text-neutral-400'>
                  <Controller
                    name='remember'
                    control={control}
                    render={({ field: { value, ...field } }) => (
                      // eslint-disable-next-line jsx-a11y/label-has-associated-control
                      <label>
                        <input type='checkbox' checked={value} {...field} /> {t('remember_me')}
                      </label>
                    )}
                  />
                </BodyText>
                <BodyText variant='sm' className='font-bold text-secondary-500'>
                  <Link to='/reset_password'>{t('forgot_password')}</Link>
                </BodyText>
              </div>
              <Button type='submit' size='default' variant='secondary' className='w-full'>
                {t('log-in')}
              </Button>
            </form>
            <BodyText variant='sm' className='mb-5 mt-4 font-medium text-neutral-500'>
              {t('didnt_register')}{' '}
              <span className='text-secondary-500 underline'>
                <Link to='/signup'>{t('create_an_account')}</Link>
              </span>
            </BodyText>
            <BodyText variant='sm' className='mb-5 font-medium text-neutral-500'>
              {t('didnt_get_verificatin_email')}{' '}
              <span className='text-secondary-500 underline'>
                <Link to='/resend-verification-email'>{t('resend_verification_title')}</Link>
              </span>
            </BodyText>
          </div>
        </div>
      </section>
    </main>
  );
}

export default LoginPage;
