import { useQuery } from '@tanstack/react-query';
import { getRelatedPrograms } from 'utils/api/program';

export const useRelatedPrograms = (programId: string) => {
  return useQuery({
    queryKey: ['related-programs', programId],
    queryFn: () => getRelatedPrograms(programId),
    select: (data) => data.data.programs,
  });
};
