import Card from 'components/card/Card';
import SortIcon from 'assets/icons/sort.svg';
import BodyText from 'components/typography/BodyText';
import Heading from 'components/typography/Heading';
import { useMyCommunities } from 'hooks/api/community/useMyCommunities';
import { UserType } from 'types/apis/common';
import SearchInput from 'components/form/SearchInput';
import Tag from 'components/tag/Tag';
import React, { useCallback, useState, useMemo } from 'react';
import ViewToggle from 'components/common/ListViewToggle';
import { useTranslation } from 'react-i18next';
import debounce from 'lodash/debounce';
import { Link } from 'react-router-dom';
import useIsLoggedIn from 'hooks/api/auth/useIsLoggedIn';
import dayjs from 'dayjs';

function MyCommunities() {
  const { t } = useTranslation();
  const { data: user } = useIsLoggedIn();
  // list view toggle
  const [listView, setListView] = useState(false); // Initialize state
  const handleToggleView = (isListView: boolean) => {
    setListView(isListView); // Update state based on the value from ViewToggle
  };

  const { data: communities } = useMyCommunities();

  // search related code
  const [searchString, setSearchString] = useState('');

  const debounceSearch = useCallback(debounce(setSearchString, 300), []);
  const filteredCommunities = communities?.filter((community) =>
    community.name.toLowerCase().includes(searchString.toLowerCase()),
  );
  // end

  // sorting related code:
  const [sortConfig, setSortConfig] = useState<{
    key: 'createdAt' | 'name' | 'organization' | 'memberCount';
    direction: 'ascending' | 'descending';
  }>({ key: 'createdAt', direction: 'descending' });

  const sortedCommunities = useMemo(() => {
    let filtered = filteredCommunities;

    if (sortConfig.key) {
      filtered = [...(filteredCommunities ?? [])].sort((a, b) => {
        let aValue, bValue;
        if (sortConfig.key === 'memberCount') {
          aValue = a.memberCount;
          bValue = b.memberCount;
        } else if (sortConfig.key === 'createdAt') {
          aValue = new Date(a.createdAt).getTime();
          bValue = new Date(b.createdAt).getTime();
        } else if (sortConfig.key === 'name') {
          aValue = a.name;
          bValue = b.name;
        } else {
          aValue = a[sortConfig.key];
          bValue = b[sortConfig.key];
        }
        if (aValue < bValue) {
          return sortConfig.direction === 'ascending' ? -1 : 1;
        }
        if (aValue > bValue) {
          return sortConfig.direction === 'ascending' ? 1 : -1;
        }
        return 0;
      });
    }
    return filtered;
  }, [filteredCommunities, sortConfig]);

  const handleSort = (key: 'createdAt' | 'name' | 'organization' | 'memberCount') => {
    let direction: 'ascending' | 'descending' = 'ascending';
    if (sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }
    setSortConfig({ key, direction });
  };
  // sorting related code ends here

  return (
    <section className='space-y-4 sm:pb-9 md:pb-5'>
      <div className='mb-4 flex items-center justify-between px-1 gap-2 '>
        <SearchInput onSearch={debounceSearch} />
        <ViewToggle onToggleView={handleToggleView} />
      </div>

      {!communities?.length ? (
        <Heading variant='h1' className='text-center font-semibold'>
          {t('no_content_to_show')}
        </Heading>
      ) : listView ? (
        <div className='table-container shadow-md shadow-neutral-300'>
          <table>
            <thead>
              <tr>
                <th>
                  <div className='flex items-center gap-1'>
                    <BodyText variant='sm' className='font-medium'>
                      {t('title')}
                    </BodyText>
                    <button
                      className='neutral-button rounded-full p-1'
                      onClick={() => handleSort('name')}
                    >
                      <SortIcon className='h-4 w-4' />
                    </button>
                  </div>
                </th>
                <th>
                  <div className='flex items-center gap-1'>
                    <BodyText variant='sm' className='font-medium'>
                      {t('organization')}
                    </BodyText>
                    <button
                      className='neutral-button rounded-full p-1'
                      onClick={() => handleSort('organization')}
                    >
                      <SortIcon className='h-4 w-4' />
                    </button>
                  </div>
                </th>
                <th>
                  <div className='flex items-center gap-1'>
                    <BodyText variant='sm' className='font-medium'>
                      {t('members')}
                    </BodyText>
                    <button
                      className='neutral-button rounded-full p-1'
                      onClick={() => handleSort('memberCount')}
                    >
                      <SortIcon className='h-4 w-4' />
                    </button>
                  </div>
                </th>
                <th>{t('visibility')}</th>
                <th>{t('role')}</th>
                <th>
                  <div className='flex items-center gap-1'>
                    <BodyText variant='sm' className='font-medium'>
                      {t('created_at')}
                    </BodyText>
                    <button
                      className='neutral-button rounded-full p-1'
                      onClick={() => handleSort('createdAt')}
                    >
                      <SortIcon className='h-4 w-4' />
                    </button>
                  </div>
                </th>
              </tr>
            </thead>
            <tbody>
              {sortedCommunities?.map(
                ({ id, name, visibility, cover, role, memberCount, organization, createdAt }) => (
                  <tr key={id}>
                    <td>
                      <Link to={`/communities/${id}`} className='hover:underline'>
                        <span className='max-w-[600px] line-clamp-1'>{name}</span>
                      </Link>
                    </td>
                    <td>{organization.name}</td>
                    <td>{memberCount}</td>
                    <td className='capitalize'>{visibility}</td>
                    <td>
                      <Tag
                        type='rounded'
                        className=' bg-functional-success-light text-xs text-functional-success-dark'
                      >
                        {t(`member.role.${role}`)}
                      </Tag>
                    </td>
                    <td>
                      {' '}
                      <BodyText variant='sm'>
                        <span>{dayjs(createdAt).format('D MMM YYYY')}</span>
                      </BodyText>
                    </td>
                  </tr>
                ),
              )}
            </tbody>
          </table>
        </div>
      ) : (
        <div className='grid grid-cols-1 gap-5 md:grid-cols-2 xl:grid-cols-3 xxl:grid-cols-4'>
          {sortedCommunities?.map(
            ({ id, name, visibility, cover, role, memberCount, organization, logo }) => (
              <Card
                key={id}
                role={role}
                title={name}
                members={memberCount}
                visibility={visibility}
                coverImage={cover}
                organizationLogo={logo || organization.logo}
                to={`/communities/${id}`}
                organizationName={organization.name}
              />
            ),
          )}
        </div>
      )}

      {!user?.primaryOrganizationId && (
        <div>
          <Heading variant='h2' className='mb-4 font-semibold text-secondary-500'>
            Try Our Demo Community
          </Heading>
          <div className='mb-6 grid grid-cols-1 gap-5 md:grid-cols-2 xl:grid-cols-3 xxl:grid-cols-4'>
            <Card
              key='clfcmygn6021tuut0wfaefko5'
              title='Demo Community'
              coverImage='https://fra1.digitaloceanspaces.com/gamma/community_uploads/clfcmygn6021tuut0wfaefko5/community_cover_clfcmygn6021tuut0wfaefko5.jpg'
              members={0}
              to='/communities/clfcmygn6021tuut0wfaefko5'
              visibility='Open'
              organizationName='Demo Organization'
              organizationLogo='https://fra1.digitaloceanspaces.com/gamma/organization/logos/organization_logo_clfcmyd5501zzuut02i5murl6.jpeg'
            />
          </div>
        </div>
      )}
    </section>
  );
}

export default MyCommunities;
