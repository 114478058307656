import React from 'react';
import BottomNav from './BottomNav';
import Header from './Header';
import MobileHeader from './MobileHeader';
import Sidenav from './Sidenav';
import useIsLoggedIn from 'hooks/api/auth/useIsLoggedIn';

interface ILayout {
  children: React.ReactNode;
}

function Layout({ children }: ILayout) {
  const { data: user } = useIsLoggedIn();
  return (
    <main className='bg-neutral-100 md:flex'>
      <Sidenav orgId={user?.primaryOrganizationId} orgName={user?.primaryOrganization?.name} />

      <div className='h-full w-full flex-col md:flex '>
        <Header />
        <MobileHeader />
        <div className='flex-grow sm:pb-9 md:pb-0'>{children}</div>
        <BottomNav />
      </div>
    </main>
  );
}

export default Layout;
