import { useInvalidateQueries } from 'hooks/common/useInvalidatequeries';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { createCommunityEvent } from 'utils/api/event';
import { notifySuccess, notifyTranslatedError } from 'utils/notify';
import CreateEventForm from '../event/CreateEventForm';
import { generateCommunityLink } from 'utils/url';

const CreateCommunityEventPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { communityId } = useParams();

  const { invalidateCommunityEvents, invalidateUpcomingCommunityEvents } = useInvalidateQueries();

  const handleCreateEvent = (eventData: FormData) => {
    if (!communityId) return;

    createCommunityEvent(communityId, eventData)
      .then(() => {
        navigate(`${generateCommunityLink(communityId)}/events`);
        notifySuccess(t('success.api.event_created'));
        invalidateCommunityEvents(communityId);
        invalidateUpcomingCommunityEvents(communityId);
      })
      .catch((error) => {
        notifyTranslatedError(t, error.response.data?.message);
        notifyTranslatedError(t, error.response.data?.errorCode);
      });
  };

  return <CreateEventForm handleCreateEvent={handleCreateEvent} />;
};

export default CreateCommunityEventPage;
