import Button from 'components/button/Button';
import React from 'react';
import { useTranslation } from 'react-i18next';
import ModalContainer from './ModalContainer';
import PageSelection from '../common/PageSelection';

interface ISelectPageModal {
  isOpen: boolean;
  onClose: () => void;
  setSelectedPage: (page: { id: string; title: string }) => void;
  selectedPage?: { id: string; title: string };
  onConfirm: () => void;
}

const SelectPageModal = ({
  isOpen,
  onClose,
  onConfirm,
  setSelectedPage,
  selectedPage,
}: ISelectPageModal) => {
  const { t } = useTranslation();

  return (
    <ModalContainer title={t('select-page')} isOpen={isOpen} onClose={onClose}>
      <div className='max-h-[300px] space-y-1 overflow-y-auto px-7'>
        <PageSelection setSelectedPage={setSelectedPage} selectedPage={selectedPage} />
      </div>

      <div className='mt-5 flex items-center justify-end gap-3 border-t border-neutral-200 pt-5'>
        <Button size='default' variant='outlined' onClick={onClose}>
          {t('cancel')}
        </Button>
        <Button size='default' variant='secondary' onClick={onConfirm}>
          {t('confirm')}
        </Button>
      </div>
    </ModalContainer>
  );
};

export default SelectPageModal;
