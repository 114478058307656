import DownloadIcon from 'assets/icons/download.svg';
import DeleteIcon from 'assets/icons/delete.svg';
import EyeIcon from 'assets/icons/eye.svg';
import LinkIcon from 'assets/icons/link.svg';
import ReplyIcon from 'assets/icons/reply.svg';
import FileIcon from 'assets/icons/file.svg';
import Tag from 'components/tag/Tag';
import Avatar from 'components/memberAndProfile/Avatar';
import BodyText from 'components/typography/BodyText';
import { userAvatar } from 'constants/common';
import dayjs from 'dayjs';
import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  bytesToMegaBytes,
  convertHtmlToLink,
  editorContentToText,
  sanitizeHtml,
} from 'utils/helper';
import { ContextMenu } from 'components/common/ContextMenu';
import { removeMessage } from 'utils/api/message';
import { notifySuccess, notifyTranslatedError } from 'utils/notify';

interface IMessageBubble {
  messageId: string;
  isMine?: boolean;
  senderId: string;
  firstName: string;
  lastName: string;
  createdAt: Date;
  message: string;
  profilePicture: string | null;
  replyMessageId?: string;
  replyContent?: string;
  replySenderId?: string;
  replySenderFirstName?: string;
  replySenderLastName?: string;
  readByUserId?: string;
  readByFirstName?: string;
  isEmbedded?: boolean;
  file: null | {
    id: string;
    directory: string;
    fileName: string;
    fileSize: number;
  };
  refetchMessage: () => void;
  handleReply: (
    messageId: string,
    message: string,
    senderId: string,
    firstName: string,
    lastName: string,
  ) => void;
}

export const MessageBubble = ({
  messageId,
  isMine,
  senderId,
  firstName,
  lastName,
  createdAt,
  message,
  profilePicture,
  file,
  replyMessageId,
  replyContent,
  replySenderId,
  replySenderFirstName,
  replySenderLastName,
  readByUserId,
  readByFirstName,
  isEmbedded,
  refetchMessage,
  handleReply,
}: IMessageBubble) => {
  const { t } = useTranslation();
  const handleDeleteMessage = async () => {
    try {
      await removeMessage(messageId)
        .then(() => {
          refetchMessage();
          notifySuccess(t('action-success'));
        })
        .catch((error) => {
          notifyTranslatedError(t, error.response.data?.errorCode);
        });
    } catch (error) {
      console.error('Error deleting message:', error);
    }
  };
  const handleReplyClick = () => {
    handleReply(messageId, message, senderId, firstName, lastName);
  };
  const isImage = (fileName: string) => {
    if (!fileName) return false;
    const imageExtensions = ['.png', '.jpg', '.jpeg', '.gif', '.bmp'];
    return imageExtensions.some((ext) => fileName.trim().toLowerCase().endsWith(ext));
  };
  return (
    <div
      className={`mb-4 ${isMine && !isEmbedded ? 'ml-12 ' : 'mr-12 '} ${isEmbedded ? 'w-full' : 'max-w-[75%]'}`}
    >
      <div className={`mb-2 flex w-full gap-3 ${isMine ? ' ml-auto' : ' mr-auto'}`}>
        <span className={`flex-shrink-0 ${isMine ? 'hidden' : ''}`}>
          <Avatar
            size={40}
            src={profilePicture || userAvatar}
            className='border border-[neutral-100]'
          />
        </span>
        <div className={`flex-1 items-center ${isMine ? 'pl-4 md:pl-6' : ''}`}>
          <div className={`flex flex-1 items-center ${isMine ? 'justify-between' : ''}`}>
            <BodyText as='span' variant='sm' className='relative flex font-medium'>
              {!isMine && `${firstName} ${lastName}`}
            </BodyText>
            <div>
              <span className='text-xs font-semibold'>{isMine && t('You')}</span> &nbsp;
              <span className='text-xs'>{dayjs(new Date(createdAt)).format('DD MMM h:mm')}</span>
            </div>
          </div>
          <div className='flex-1'>
            {message && (
              <div className={`flex items-start ${isMine ? 'justify-end' : ''}`}>
                <BodyText
                  variant='sm'
                  className={`relative whitespace-pre-wrap break-all md:break-words rounded-lg px-2 py-1 ${
                    isMine
                      ? 'rounded-tr-none bg-secondary-600 pr-7 text-neutral-100'
                      : 'rounded-tl-none bg-secondary-100 pr-7 text-neutral-600'
                  }`}
                  as='div'
                >
                  {(replyMessageId || replySenderFirstName) && (
                    <div
                      className={`mb-2 flex items-start rounded-lg shadow-md ${
                        isMine ? 'bg-secondary-400' : 'bg-secondary-200'
                      }`}
                    >
                      <div className={`flex-1 p-1`}>
                        <BodyText
                          as='span'
                          variant='xs'
                          className={` ${isMine ? 'text-neutral-200' : 'text-secondary-400'}`}
                        >
                          {`${replySenderFirstName} ${replySenderLastName}`}:
                        </BodyText>
                        <BodyText
                          variant='sm'
                          className={`relative whitespace-pre-wrap break-all md:break-words`}
                          as='div'
                        >
                          <div
                            className={`mb-1 line-clamp-2 flex items-center text-xs text-gray-500 ${
                              isMine ? 'text-neutral-100' : 'text-neutral-400'
                            }`}
                          >
                            <span
                              // eslint-disable-next-line react/no-danger
                              dangerouslySetInnerHTML={{
                                __html: sanitizeHtml(
                                  convertHtmlToLink(editorContentToText(replyContent || '')),
                                ),
                              }}
                            />
                          </div>
                        </BodyText>
                      </div>
                    </div>
                  )}
                  <span
                    // eslint-disable-next-line react/no-danger
                    dangerouslySetInnerHTML={{
                      __html: sanitizeHtml(convertHtmlToLink(editorContentToText(message))),
                    }}
                  />

                  {isMine && (
                    <div className='absolute right-0 top-0 z-30'>
                      <ContextMenu
                        menuItems={[
                          {
                            key: 'reply',
                            label: t('reply'),
                            icon: ReplyIcon,
                            callback: handleReplyClick,
                          },
                          {
                            key: 'delete',
                            label: t('delete'),
                            icon: DeleteIcon,
                            callback: handleDeleteMessage,
                          },
                        ]}
                      />
                    </div>
                  )}
                  {!isMine && (
                    <div className='absolute right-0 top-0 z-30'>
                      <ContextMenu
                        menuItems={[
                          {
                            key: 'reply',
                            label: t('reply'),
                            icon: ReplyIcon,
                            callback: handleReplyClick,
                          },
                        ]}
                      />
                    </div>
                  )}
                </BodyText>
              </div>
            )}
            {file && !isImage(file.fileName) && (
              <div
                className={`flex px-3 py-2 ${
                  message ? 'mt-2' : ''
                } gap-3 break-words rounded-lg border ${
                  isMine
                    ? ' rounded-tr-none border-secondary-500'
                    : 'rounded-tl-none border-secondary-200'
                } ${isEmbedded ? '' : 'ml-auto'}`}
              >
                <div className='flex-shrink-0'>
                  <FileIcon className='h-6 w-6' />
                </div>
                <div className='flex-1 overflow-hidden break-all'>
                  <BodyText
                    variant='sm'
                    className='font-medium text-neutral-500'
                    title={file.fileName}
                  >
                    {file.fileName.length > 30
                      ? `${file.fileName.substring(0, 30)}...`
                      : file.fileName}
                  </BodyText>
                  <BodyText variant='xs' className='font-regular text-neutral-400'>
                    {bytesToMegaBytes(file.fileSize)}
                  </BodyText>
                </div>
                <div className='relative'>
                  {!isMine && (
                    <button
                      onClick={() => {
                        if (file) window.open(file.directory, '_blank');
                      }}
                      className='flex h-6 w-6 shrink-0 items-center justify-center rounded-full text-neutral-500 hover:bg-neutral-300 hover:text-functional-info-dark active:bg-neutral-400'
                    >
                      <DownloadIcon className='h-5 w-5 ' />
                    </button>
                  )}
                  {isMine && (
                    <div className='absolute right-0 top-0 z-20 rounded-full bg-secondary-200'>
                      <ContextMenu
                        menuItems={[
                          {
                            key: 'download',
                            label: t('download'),
                            icon: DownloadIcon,
                            callback: () => {
                              if (file) window.open(file.directory, '_blank');
                            },
                          },
                          {
                            key: 'delete',
                            label: t('delete'),
                            icon: DeleteIcon,
                            callback: handleDeleteMessage,
                          },
                        ]}
                      />
                    </div>
                  )}
                </div>
              </div>
            )}
            {file && isImage(file.fileName) && (
              <div className={`flex ${isMine ? 'justify-end' : ''} ${message ? 'mt-2' : ''}`}>
                <div
                  className={`flex ${isEmbedded ? 'w-full' : 'max-w-[75%]'} rounded-lg border border-neutral-300 p-1 shadow-md shadow-neutral-300 ${
                    isMine ? 'rounded-tr-none bg-secondary-500' : 'rounded-tl-none bg-secondary-100'
                  }`}
                >
                  <img
                    src={file.directory}
                    alt={file.fileName}
                    className={` ${isMine ? 'ml-auto' : ''}`}
                  />
                  <div className='relative'>
                    {!isMine && (
                      <div className='absolute right-0 top-0 z-20 mt-2 rounded-full bg-neutral-100'>
                        <ContextMenu
                          menuItems={[
                            {
                              key: 'open',
                              label: t('open'),
                              icon: LinkIcon,
                              callback: () => {
                                if (file) window.open(file.directory, '_blank');
                              },
                            },
                          ]}
                        />
                      </div>
                    )}
                    {isMine && (
                      <div className='absolute right-0 top-0 z-20 mt-2 rounded-full bg-neutral-100'>
                        <ContextMenu
                          menuItems={[
                            {
                              key: 'open',
                              label: t('open'),
                              icon: LinkIcon,
                              callback: () => {
                                if (file) window.open(file.directory, '_blank');
                              },
                            },
                            {
                              key: 'delete',
                              label: t('delete'),
                              icon: DeleteIcon,
                              callback: handleDeleteMessage,
                            },
                          ]}
                        />
                      </div>
                    )}
                  </div>
                </div>
              </div>
            )}
          </div>
          {readByFirstName && (
            <div className={`${isMine ? 'text-right' : ''}`}>
              <Tag
                type='default'
                className={`bg-functional-success-light text-functional-success-dark`}
              >
                <EyeIcon className='h-4 w-4' />
                {readByFirstName}
              </Tag>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
