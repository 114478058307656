import { useQuery } from '@tanstack/react-query';
import { mentionSearch } from 'utils/api/search';

export const useMentionSearch = (
  originType: string,
  originId: string,
  type: string,
  value: string,
  enabled = false,
  conversationId?: string,
) => {
  const { data, refetch } = useQuery({
    queryKey: ['mention-search', originType, originId, type, value, conversationId],
    queryFn: () => mentionSearch(originType, originId, type, value, conversationId),
    select: (data) => data.data,
    enabled,
  });

  return {
    users: data?.users ?? [],
    resources: data?.resources ?? [],
    communities: data?.communities ?? [],
    programs: data?.programs ?? [],
    events: data?.events ?? [],
    tasks: data?.tasks ?? [],
    refetchSearch: refetch,
  };
};
