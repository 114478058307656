import NotificationIcon from 'assets/icons/notification.svg';
import NotificationDrawer from 'components/drawer/NotificationDrawer';
import IconButton from 'components/layout/IconButton';
import { useNotifications } from 'hooks/api/notificaiton/useNotifications';
import { useUnseenNotifications } from 'hooks/api/notificaiton/useUnseenNotifications';
import React, { useState } from 'react';

const NotificationDrawerWithTrigger = () => {
  const [isOpen, setIsOpen] = useState(false);

  const { data: unseenCount = 0, refetch: refetchNotificationCount } = useUnseenNotifications();
  const { refetch: refetchNotifications } = useNotifications(isOpen);

  const handleOpenDrawer = () => {
    setIsOpen(true);
    refetchNotifications();
    refetchNotificationCount();
  };

  return (
    <>
      <NotificationDrawer isOpen={isOpen} setIsOpen={setIsOpen} />

      <IconButton
        className='neutral-button text-neutral-500'
        badgeCount={unseenCount}
        Icon={NotificationIcon}
        onClick={handleOpenDrawer}
      />
    </>
  );
};

export default NotificationDrawerWithTrigger;
