import { useQuery } from '@tanstack/react-query';
import { getMaterials } from 'utils/api/material';

export const useMaterials = (programId: string) => {
  const { data, refetch } = useQuery({
    queryKey: ['program-materials', programId],
    queryFn: () => getMaterials(programId),
    select: (res) => ({
      unifiedList: res.data.materials.unifiedList,
      totalCount: res.data.totalMaterialsCount, // Extract totalMaterialsCount from response
    }),
  });

  return {
    unifiedMaterials: data?.unifiedList ?? [], // Return unifiedList
    materialsCount: data?.totalCount ?? 0, // Return materialsCount (totalMaterialsCount)
    refetchMaterials: refetch,
  };
};
