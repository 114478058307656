import { useInfiniteQuery } from '@tanstack/react-query';
import { search } from 'utils/api/search';
import { ONE_MINUTE } from 'utils/time';

export const useSearch = (type: string, value: string) => {
  const { data, refetch, isLoading, isFetching, hasNextPage, fetchNextPage } = useInfiniteQuery({
    queryKey: ['search', type, value],
    queryFn: ({ pageParam = '' }) => search(type, value, pageParam),
    getNextPageParam: (lastPage) => lastPage.data.cursor,
    enabled: !!value && !!type,
    staleTime: ONE_MINUTE,
  });

  return {
    refetchSearch: refetch,
    isSearchLoading: isLoading,
    hasNextSearch: hasNextPage,
    isSearchFetching: isFetching,
    fetchNextSearch: fetchNextPage,
    users: data?.pages.flatMap((page) => page.data.users ?? []),
    programs: data?.pages.flatMap((page) => page.data.programs ?? []),
    communities: data?.pages.flatMap((page) => page.data.communities ?? []),
  };
};
