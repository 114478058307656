import DeleteIcon from 'assets/icons/delete.svg';
import { AxiosProgressEvent } from 'axios';
import Button from 'components/button/Button';
import UploadFileAreaButton from 'components/button/UploadFileAreaButton';
import TextInput from 'components/form/TextInput';
import BodyText from 'components/typography/BodyText';
import Uploading from 'components/Uploading';
import { RESOURCE_FILE_SIZE_LIMIT } from 'constants/common';
import { useFileUploadProgress } from 'hooks/common/useFileUploadProgress';
import React, { useRef, useState } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ResourceFormInputType } from 'types/components/form';
import { notifyError, notifyTranslatedError, notifySuccess } from 'utils/notify';
import { objToFormData } from 'utils/rest';
import ModalContainer from './ModalContainer';
import { useNavigate } from 'react-router-dom';
import PageSelection from '../common/PageSelection';
import { createPage, deletePage } from 'utils/api/pages';
import { addProgramPublicResource, addCommunityPublicResource } from 'utils/api/publicResource';

interface IAddPublicResourceModal {
  originId: string;
  originType: string;
  isOpen: boolean;
  onClose: () => void;
  refetch: () => void;
}

const AddPublicResourceModal = ({
  originId,
  originType,
  isOpen,
  onClose,
  refetch,
}: IAddPublicResourceModal) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const uploadInputRef = useRef<HTMLInputElement>(null);
  const [pageType, setPageType] = useState('new-page');
  const [pageTitle, setPageTitle] = useState('');
  const [selectedPage, setSelectedPage] = useState<{ id: string; title: string } | undefined>();
  const { isUploading, setIsUploading, progress, onUploadProgress } = useFileUploadProgress();

  const {
    reset,
    watch,
    control,
    register,
    setValue,
    handleSubmit,
    unregister,
    formState: { errors },
  } = useForm<ResourceFormInputType>({
    defaultValues: {
      resourceType: 'file',
    },
  });

  const fileValidator = (file: FileList | null) => {
    if (watch('resourceType') === 'file') {
      return file !== null ? true : t('required_error_message');
    }
    return true;
  };

  const urlValidator = (url: string) => {
    if (watch('resourceType') === 'url') {
      return url.trim() !== '' ? true : t('required_error_message');
    }
    return true;
  };

  const onSubmit: SubmitHandler<ResourceFormInputType> = async (data) => {
    const { file, url, ...rest } = data;
    const formData = objToFormData({ ...rest });

    if (file) {
      formData.append('file', file[0]);
    }
    if (url) {
      formData.append('url', url);
    }
    if (selectedPage?.id && pageType === 'my-page') formData.append('pageId', selectedPage?.id);
    if (data.resourceType === 'page' && pageType === 'new-page') {
      if (pageTitle.trim().length < 1) {
        notifyTranslatedError(t, 'Title must be at least 1 character long');
        return;
      }
      const response = await createPage(pageTitle); // Assuming createPage now accepts pageTitle as a parameter
      formData.append('pageId', response.data.pageId);
    }

    setIsUploading(true);
    if (originType === 'community') {
      addCommunityPublicResource(originId, formData, { onUploadProgress })
        .then(() => {
          refetch();
          setIsUploading(false);
          onClose();
          notifySuccess(t('success.api.resource_added'));
          //if pageType is new-page navigate to new page
          if (data.resourceType === 'page' && pageType === 'new-page') {
            navigate(`/pages/${formData.get('pageId')}`);
          }
        })
        .catch((error) => {
          setIsUploading(false);
          notifyTranslatedError(t, error.response.data?.errorCode);
          notifyTranslatedError(t, error.response.data?.message);
          if (data.resourceType === 'page' && pageType === 'new-page') {
            deletePage(formData.get('pageId') as string);
          }
        });
    }
    if (originType === 'program') {
      addProgramPublicResource(originId, formData, { onUploadProgress })
        .then(() => {
          refetch();
          setIsUploading(false);
          onClose();
          notifySuccess(t('success.api.resource_added'));
          //if pageType is new-page navigate to new page
          if (data.resourceType === 'page' && pageType === 'new-page') {
            navigate(`/pages/${formData.get('pageId')}`);
          }
        })
        .catch((error) => {
          setIsUploading(false);
          notifyTranslatedError(t, error.response.data?.errorCode);
          notifyTranslatedError(t, error.response.data?.message);
          if (data.resourceType === 'page' && pageType === 'new-page') {
            deletePage(formData.get('pageId') as string);
          }
        });
    }
  };

  const handleSelectFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files?.length) return;
    if (e.target.files[0].size > RESOURCE_FILE_SIZE_LIMIT) {
      notifyError('file-too-big');
      return;
    }
    setValue('file', e.target.files);
  };

  const handleRemoveFile = () => {
    reset({ file: undefined, resourceType: 'file' });
  };

  return (
    <ModalContainer title={t('add-item')} isOpen={isOpen} onClose={onClose}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className='space-y-5'>
          <div className='space-y-1'>
            <BodyText variant='sm' className='font-medium text-neutral-500'>
              {t('resource_type')}
            </BodyText>
            <Controller
              name='resourceType'
              control={control}
              rules={{
                required: {
                  value: true,
                  message: t('required_error_message'),
                },
              }}
              render={({ field: { value, ...props } }) => (
                <div className='flex items-center gap-2'>
                  <button
                    type='button'
                    className='flex grow items-center gap-4 rounded-xl bg-neutral-100 p-4'
                    onClick={() => setValue('resourceType', 'file')}
                  >
                    <input
                      type='radio'
                      value='file'
                      checked={watch('resourceType') === 'file'}
                      {...props}
                    />
                    <BodyText variant='sm' className='font-medium uppercase text-neutral-400'>
                      {t('file')}
                    </BodyText>
                  </button>
                  <button
                    type='button'
                    className='flex grow items-center gap-4 rounded-xl bg-neutral-100 p-4'
                    onClick={() => setValue('resourceType', 'url')}
                  >
                    <input
                      type='radio'
                      value='url'
                      checked={watch('resourceType') === 'url'}
                      {...props}
                    />
                    <BodyText variant='sm' className='font-medium text-neutral-400'>
                      {t('url')}
                    </BodyText>
                  </button>
                  <button
                    type='button'
                    className='flex grow items-center gap-4 rounded-xl bg-neutral-100 p-4'
                    onClick={() => setValue('resourceType', 'page')}
                  >
                    <input
                      type='radio'
                      value='page'
                      checked={watch('resourceType') === 'page'}
                      {...props}
                    />
                    <BodyText variant='sm' className='font-medium text-neutral-400'>
                      {t('my-pages')}
                    </BodyText>
                  </button>
                </div>
              )}
            />
            {errors.resourceType && (
              <BodyText variant='xs' className='text-functional-danger-400'>
                {errors.resourceType?.message}
              </BodyText>
            )}
          </div>
          {watch('resourceType') !== 'page' && (
            <div className='space-y-1'>
              <BodyText
                variant='sm'
                className={`font-medium ${
                  errors.title ? 'text-functional-danger-400' : 'text-neutral-500'
                }`}
              >
                {t('title')}
              </BodyText>
              <Controller
                name='title'
                control={control}
                render={({ field }) => (
                  <TextInput
                    {...field}
                    placeholder={t('title') || 'Title'}
                    className={errors.title ? 'border-functional-danger-400' : ''}
                  />
                )}
              />
            </div>
          )}
          {watch('resourceType') === 'file' && (
            <div className='space-y-1'>
              <BodyText
                variant='sm'
                className={`font-medium ${
                  errors.file ? 'text-functional-danger-400' : 'text-neutral-500'
                }`}
              >
                {t('upload-file')}
              </BodyText>

              {watch('file') ? (
                <div className='flex items-center gap-3 rounded-md border border-functional-info-dark bg-functional-info-light px-4 py-2'>
                  <div>
                    <BodyText variant='sm' className='font-semibold'>
                      {watch('file')[0].name}
                    </BodyText>
                    <BodyText variant='xs'>
                      {(watch('file')[0].size / 1024 / 1024).toFixed(2)} MB
                    </BodyText>
                  </div>

                  <button
                    className='ml-auto flex h-7 w-7 items-center justify-center rounded-full text-functional-danger-dark hover:bg-neutral-300 active:bg-neutral-400'
                    onClick={handleRemoveFile}
                  >
                    <DeleteIcon className='h-5 w-5' />
                  </button>
                </div>
              ) : (
                <UploadFileAreaButton
                  maxFileSize={500}
                  isError={!!errors.file}
                  onClick={() => uploadInputRef.current?.click()}
                />
              )}

              <input
                type='file'
                className='hidden'
                {...register('file', {
                  validate: fileValidator,
                  onChange: (e) => handleSelectFile(e),
                })}
                ref={uploadInputRef}
              />
            </div>
          )}

          {watch('resourceType') === 'url' && (
            <div className='space-y-2'>
              <BodyText
                variant='sm'
                className={`font-medium ${
                  errors.url ? 'text-functional-danger-400' : 'text-neutral-500'
                }`}
              >
                {t('url')}
              </BodyText>
              <Controller
                name='url'
                control={control}
                rules={{ validate: urlValidator }}
                render={({ field }) => (
                  <TextInput
                    {...field}
                    placeholder={t('url') || 'https://studycentral.eu'}
                    className={errors.url ? 'border-functional-danger-400' : ''}
                  />
                )}
              />
            </div>
          )}
          {watch('resourceType') === 'page' && (
            <div className='max-h-[350px] space-y-4 overflow-y-auto'>
              <div className='flex items-center gap-2'>
                <button
                  type='button'
                  className='flex grow items-center gap-4 rounded-xl bg-neutral-100 p-3'
                  onClick={() => setPageType('new-page')}
                >
                  <input type='radio' value='file' checked={pageType === 'new-page'} />
                  <BodyText variant='sm' className='font-medium text-neutral-400'>
                    {t('create-page')}
                  </BodyText>
                </button>

                <button
                  type='button'
                  className='flex grow items-center gap-4 rounded-xl bg-neutral-100 p-3'
                  onClick={() => setPageType('my-page')}
                >
                  <input type='radio' value='page' checked={pageType === 'my-page'} />
                  <BodyText variant='sm' className='font-medium text-neutral-400'>
                    {t('select-page')}
                  </BodyText>
                </button>
              </div>
              {pageType === 'new-page' && (
                <div>
                  <BodyText variant='sm' className='font-medium text-neutral-500'>
                    {t('title')}
                  </BodyText>
                  <TextInput
                    type='text'
                    placeholder={t('write-message-placeholder')}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                      setPageTitle(e.target.value)
                    }
                  />
                </div>
              )}
              {pageType === 'my-page' && (
                <PageSelection setSelectedPage={setSelectedPage} selectedPage={selectedPage} />
              )}
            </div>
          )}
        </div>

        {isUploading && <Uploading progress={progress} />}

        <div className='mt-5 flex items-center justify-end gap-3 border-t border-neutral-200 pt-5'>
          <Button type='button' size='small' variant='outlined' onClick={onClose}>
            {t('cancel')}
          </Button>
          <Button type='submit' size='small' variant='secondary'>
            {t('add-item')}
          </Button>
        </div>
      </form>
    </ModalContainer>
  );
};
export default AddPublicResourceModal;
