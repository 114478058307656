import Layout from 'components/layout/Layout';
import React from 'react';
import { Tab } from '@headlessui/react';
import { communitiesTabItems } from 'config/tabs';
import { Outlet } from 'react-router';
import { useTranslation } from 'react-i18next';
import { useNavigate, useLocation } from 'react-router-dom';
import MyCommunities from './segment/communities/MyCommunities';

function CommunitiesPage() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <Layout>
      <div className='overflow-y-auto pb-7 md:py-4 md:px-5 py-1 px-2 md:h-full'>
        <MyCommunities />
      </div>
    </Layout>
  );
}

export default CommunitiesPage;
