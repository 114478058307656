import { AxiosRequestConfig } from 'axios';
import { API_ROOT } from 'constants/config';
import { CommunityResourcesResponseType } from 'types/apis/response';
import { generateQueryParam, parseDELETE, parseGET, parsePOST, parsePUT } from 'utils/rest';

export const getCommunityPublicResources = (
  communityId: string,
  limit?: number,
  cursor?: string,
  options?: AxiosRequestConfig,
) => {
  const queryParam = generateQueryParam({ limit, cursor });

  return parseGET<CommunityResourcesResponseType>(
    `${API_ROOT}/community/${communityId}/public/resources${queryParam}`,
    {
      withCredentials: true,
      ...options,
    },
  );
};

export const getProgramPublicResources = (
  programId: string,
  limit?: number,
  cursor?: string,
  options?: AxiosRequestConfig,
) => {
  const queryParam = generateQueryParam({ limit, cursor });

  return parseGET<CommunityResourcesResponseType>(
    `${API_ROOT}/program/${programId}/public/resources${queryParam}`,
    {
      withCredentials: true,
      ...options,
    },
  );
};

export const addCommunityPublicResource = (
  communityId: string,
  body: FormData,
  options?: AxiosRequestConfig,
) =>
  parsePOST(`${API_ROOT}/community/${communityId}/public/resources`, body, {
    withCredentials: true,
    ...options,
  });

export const addProgramPublicResource = (
  programId: string,
  body: FormData,
  options?: AxiosRequestConfig,
) =>
  parsePOST(`${API_ROOT}/program/${programId}/public/resources`, body, {
    withCredentials: true,
    ...options,
  });

export const updateCommunityPublicResource = (
  communityId: string,
  resourceId: string,
  body: { title: string; url?: string },
  options?: AxiosRequestConfig,
) =>
  parsePUT(`${API_ROOT}/community/${communityId}/public/resources/${resourceId}`, body, {
    withCredentials: true,
    ...options,
  });

export const updateProgramPublicResource = (
  programId: string,
  resourceId: string,
  body: { title: string; url?: string },
  options?: AxiosRequestConfig,
) =>
  parsePUT(`${API_ROOT}/program/${programId}/public/resources/${resourceId}`, body, {
    withCredentials: true,
    ...options,
  });

export const deleteCommunityPublicResource = (
  communityId: string,
  resourceId: string,
  options?: AxiosRequestConfig,
) =>
  parseDELETE(`${API_ROOT}/community/${communityId}/public/resources/${resourceId}`, {
    withCredentials: true,
    ...options,
  });

export const deleteProgramPublicResource = (
  programId: string,
  resourceId: string,
  options?: AxiosRequestConfig,
) =>
  parseDELETE(`${API_ROOT}/program/${programId}/public/resources/${resourceId}`, {
    withCredentials: true,
    ...options,
  });

export const pinProgramPublicResource = (
  programId: string,
  resourceId: string,
  options?: AxiosRequestConfig,
) =>
  parsePUT(
    `${API_ROOT}/program/${programId}/public/resources/${resourceId}/pin`,
    {},
    {
      withCredentials: true,
      ...options,
    },
  );
