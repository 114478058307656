import { useQuery } from '@tanstack/react-query';
import { getSingleCommunityTask } from 'utils/api/task';

export const useCommunityTask = (communityId: string, taskId: string) => {
  return useQuery({
    queryKey: ['community-task', communityId, taskId],
    queryFn: () => getSingleCommunityTask(communityId, taskId),
    select: (data) => data.data,
    enabled: !!communityId && !!taskId,
  });
};
