import Button from 'components/button/Button';
import TextInput from 'components/form/TextInput';
import BodyText from 'components/typography/BodyText';
import { useInvalidateQueries } from 'hooks/common/useInvalidatequeries';
import React, { useEffect } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { createMaterialGroup, updateMaterialGroup } from 'utils/api/material';
import { notifySuccess, notifyTranslatedError } from 'utils/notify';
import ModalContainer from './ModalContainer';

interface IMaterialGroupModal {
  isOpen: boolean;
  groupName: string;
  programId: string;
  onClose: () => void;
  groupId: null | string;
}

const MaterialGroupModal = ({
  isOpen,
  onClose,
  groupId,
  programId,
  groupName,
}: IMaterialGroupModal) => {
  const { t } = useTranslation();

  const { invalidateProgramMaterials, invalidateProgramMaterialGroups } = useInvalidateQueries();

  const {
    control,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm<{ name: string }>();

  const onSubmit: SubmitHandler<{ name: string }> = (data) => {
    // if groupId exsit
    // editing group
    if (groupId) {
      updateMaterialGroup(programId, groupId, data)
        .then(() => {
          onClose();
          invalidateProgramMaterials(programId);
          invalidateProgramMaterialGroups(programId);
          notifySuccess(t('material-category-updated'));
        })
        .catch((error) => {
          notifyTranslatedError(t, error.response.data?.errorCode);
        });
    } else {
      createMaterialGroup(programId, data)
        .then(() => {
          onClose();
          invalidateProgramMaterials(programId);
          invalidateProgramMaterialGroups(programId);
          notifySuccess(t('material-category-created'));
        })
        .catch((error) => {
          notifyTranslatedError(t, error.response.data?.errorCode);
        });
    }
  };

  useEffect(() => {
    setValue('name', groupName);
  }, [groupId, groupName]);

  return (
    <ModalContainer title={t('add-category')} isOpen={isOpen} onClose={onClose}>
      <form onSubmit={handleSubmit(onSubmit)} className='space-y-5'>
        <div className='space-y-2'>
          <BodyText
            variant='sm'
            className={`font-medium ${
              errors.name ? 'text-functional-danger-400' : 'text-neutral-500'
            }`}
          >
            {t('name')}
          </BodyText>

          <Controller
            name='name'
            control={control}
            rules={{ required: true }}
            render={({ field }) => (
              <TextInput
                {...field}
                placeholder={t('category_name') || 'Category name'}
                className={errors.name ? 'border-functional-danger-400' : ''}
              />
            )}
          />
        </div>

        <div className='mt-5 flex items-center justify-end gap-3 border-t border-neutral-200 pt-5'>
          <Button type='button' size='small' variant='outlined' onClick={onClose}>
            {t('cancel')}
          </Button>
          <Button type='submit' size='small' variant='secondary'>
            {t('create')}
          </Button>
        </div>
      </form>
    </ModalContainer>
  );
};

export default MaterialGroupModal;
