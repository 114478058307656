import Button from 'components/button/Button';
import TextInput from 'components/form/TextInput';
import ImageIcon from 'assets/icons/image.svg';
import SearchIcon from 'assets/icons/search.svg';
import { unsplash_key } from 'constants/config';
import axios from 'axios';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import ModalContainer from './ModalContainer';
import { notifyError } from 'utils/notify';

interface ICreatePageModal {
  isOpen: boolean;
  onClose: () => void;
  onImageSelect: (url: string) => void;
}

const ImageSearchModal = ({ isOpen, onClose, onImageSelect }: ICreatePageModal) => {
  const { t } = useTranslation();
  const [query, setQuery] = useState('');
  const [images, setImages] = useState<any[]>([]);
  const [error, setError] = useState('');
  const [page, setPage] = useState(1);
  const imageGridRef = React.useRef(null);
  const [selectedImage, setSelectedImage] = useState<string | null>(null);

  const handleSelectImage = (url: string) => {
    setSelectedImage(url);
    onImageSelect(url);
  };

  const searchImages = async (currentPage = 1) => {
    try {
      const response = await axios.get(`https://api.unsplash.com/search/photos`, {
        params: { query, page: currentPage },
        headers: {
          Authorization: `Client-ID ${unsplash_key}`,
        },
      });
      setImages((prevImages) => [...prevImages, ...response.data.results]);
    } catch (err) {
      setError('An error occurred while fetching images!');
    }
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (query !== '') {
      setImages([]);
      setPage(1);
      searchImages(1);
    }
  };
  const handleConfirm = () => {
    if (!selectedImage) {
      // If no image is selected, set an error state
      notifyError('Please select an image before confirming.');
    } else {
      onClose();
    }
  };

  const handleScroll = (e: React.UIEvent<HTMLElement>) => {
    const { scrollTop, clientHeight, scrollHeight } = e.currentTarget;
    const threshold = 50;
    if (scrollHeight - scrollTop <= clientHeight + threshold) {
      setPage((prevPage) => prevPage + 1);
    }
  };

  React.useEffect(() => {
    if (page > 1 && query !== '') {
      searchImages(page);
    }
  }, [page]);

  return (
    <ModalContainer title={t('search-stock-image')} isOpen={isOpen} onClose={onClose}>
      <div className='w-full space-y-1 rounded-xl border-2 border-neutral-300 p-2'>
        {images.length === 0 ? (
          <div
            className='flex h-full w-full items-center justify-center'
            style={{ height: '50vh', overflowY: 'auto' }}
          >
            <ImageIcon className='h-10 w-10 text-neutral-300' />
          </div>
        ) : (
          <div
            className='image-grid w-full '
            ref={imageGridRef}
            onScroll={handleScroll}
            style={{ maxHeight: '50vh', overflowY: 'auto' }}
          >
            {images.map((img: any) => (
              <div key={img.id} className='image-container'>
                <img
                  key={img.id}
                  src={img.urls.small}
                  alt={img.alt_description}
                  onClick={() => handleSelectImage(img.urls.full)} // select the highest resolution for use
                  style={{ cursor: 'pointer' }}
                  className={img.urls.full === selectedImage ? 'selected' : ''}
                />
                <p className='text-xs text-neutral-400'>
                  Photo by{' '}
                  <a
                    href={img.user.links.html + '?utm_source=StudyCentral&utm_medium=referral'}
                    target='_blank'
                    rel='noopener noreferrer'
                  >
                    {img.user.name}
                  </a>{' '}
                  on{' '}
                  <a
                    href='https://unsplash.com?utm_source=StudyCentral&utm_medium=referral'
                    target='_blank'
                    rel='noopener noreferrer'
                  >
                    Unsplash
                  </a>
                </p>{' '}
              </div>
            ))}
          </div>
        )}
        <form onSubmit={handleSubmit} className='flex items-center gap-3'>
          <TextInput
            type='text'
            value={query}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setQuery(e.target.value)}
            placeholder='Search Unsplash...'
          />
          <Button variant='primary' type='submit' className='h-8 w-10'>
            <SearchIcon className='h-5 w-5 text-neutral-100' />
          </Button>
        </form>
      </div>

      {error && <p>{error}</p>}

      <div className='mt-2 flex items-center justify-end gap-3 border-t border-neutral-200 pt-5'>
        <Button type='button' size='small' variant='outlined' onClick={onClose}>
          {t('cancel')}
        </Button>
        <Button size='small' variant='secondary' onClick={handleConfirm}>
          {t('confirm')}
        </Button>
      </div>
    </ModalContainer>
  );
};

export default ImageSearchModal;
