import Avatar from 'components/memberAndProfile/Avatar';
import Loading from 'components/Loading';
import Heading from 'components/typography/Heading';
import BodyText from 'components/typography/BodyText';
import { useSinglePublicProgram } from 'hooks/api/program/useSingleProgram';
import { useSinglePublicCommunity } from 'hooks/api/community/useCommunity';
import React from 'react';
import { useTranslation, Trans } from 'react-i18next';
import RichTextEditor from 'components/common/RichTextEditor';

interface IInviteAuthCover {
  originId?: string | null;
  originType?: string | null;
}

function InviteAuthCover({ originId, originType }: IInviteAuthCover) {
  const { t } = useTranslation();
  const { data: program, isLoading: isProgramLoading } =
    originType === 'program'
      ? useSinglePublicProgram(originId || '')
      : { data: null, isLoading: false };
  const { data: community, isLoading: isCommunityLoading } =
    originType === 'community'
      ? useSinglePublicCommunity(originId || '')
      : { data: null, isLoading: false };
  if (isProgramLoading || isCommunityLoading) {
    return (
      <section className='col-span-3 hidden items-center justify-center bg-secondary-500 lg:flex'>
        <Loading />
      </section>
    );
  }

  const entity = originType === 'program' ? program : community;
  const description = entity?.description || '';

  return (
    <section className='col-span-3 hidden items-center justify-center bg-secondary-500 lg:flex'>
      <div className='flex h-full w-4/5 flex-col items-start gap-4 py-[80px] xl:w-3/5'>
        <Heading variant='h3' className='uppercase text-neutral-100'>
          {t('invite-signup-title')}:
        </Heading>
        <div className='relative w-full rounded-2xl border-2 border-primary-400 bg-neutral-200 shadow shadow-neutral-300'>
          {entity?.cover && (
            <div className='relative w-full'>
              <div
                key={entity.updatedAt}
                style={{
                  backgroundImage: `url(${entity.cover})`,
                }}
                className='relative h-[225px] w-full rounded-t-2xl bg-cover bg-center'
              ></div>
            </div>
          )}

          <div className='p-4'>
            <div className='flex items-center'>
              {entity?.organization.logo && (
                <Avatar className='mr-5' size={50} src={entity?.organization.logo} />
              )}
              <div>
                <BodyText variant='base' className='text-semibold'>
                  {entity?.organization.name}
                </BodyText>
                <Heading variant='h2' className='mb-4 '>
                  {entity?.name}
                </Heading>
              </div>
            </div>
            <div className='col-span-3 '>
              <BodyText
                variant='base'
                className='about-content whitespace-pre-wrap text-neutral-500'
              >
                <RichTextEditor initialContent={description} isReadOnly={true} />
              </BodyText>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default InviteAuthCover;
