import CloseIcon from 'assets/icons/close.svg';
import SearchInput from 'components/form/SearchInput';
import BodyText from 'components/typography/BodyText';
import Heading from 'components/typography/Heading';
import { useFilteredConversationList } from 'hooks/common/useFilteredConversationList';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ConversationThumb } from './ConversationThumb';

const MessageSidebar = ({ isOpen, close }: { isOpen: boolean; close: () => void }) => {
  const { t } = useTranslation();

  const [currentView, setCurrentView] = useState<'chats' | 'comments'>('chats');
  const [search, setSearch] = useState<string | undefined>();
  const { conversations } = useFilteredConversationList(isOpen, currentView === 'chats', search);

  // Filter conversations based on the current view
  const displayedConversations = conversations.filter(({ conversationType }) =>
    currentView === 'chats'
      ? ['group', 'private', 'private_chatbot'].includes(conversationType)
      : ['embed', 'embed_self', 'embed_all'].includes(conversationType),
  );

  return (
    <div className='flex h-full w-full flex-col space-y-3 overflow-hidden '>
      <div className='flex w-full items-center justify-between gap-5 bg-secondary-100 p-2'>
        <Heading variant='h3' className='font-semibold'>
          {t('chats')}{' '}
          {conversations.length && (
            <BodyText as='span' variant='sm' className='font-medium text-secondary-400'>
              ({conversations.length})
            </BodyText>
          )}
        </Heading>

        <button
          className='flex h-6 w-6 items-center justify-center rounded-full text-functional-danger-400 hover:bg-neutral-300 active:bg-neutral-400'
          onClick={close}
        >
          <CloseIcon className='h-4 w-4' />
        </button>
      </div>
      {currentView === 'chats' ? (
        <div className='h-full overflow-y-auto px-3 py-1'>
          <SearchInput
            onSearch={(value) => {
              setSearch(value);
            }}
            className='mb-4 h-7 w-full rounded-xl'
          />
          {displayedConversations.map(
            ({
              id,
              participants,
              lastMessage,
              conversationType,
              conversationFlagged,
              alias,
              program,
              community,
              unread,
              displayAvatar,
            }) => (
              <ConversationThumb
                id={id}
                key={id}
                onClick={close}
                unread={unread}
                participants={participants}
                conversationFlagged={conversationFlagged}
                alias={alias}
                program={program}
                community={community}
                lastMessage={lastMessage}
                conversationType={conversationType}
                displayAvatar={displayAvatar}
              />
            ),
          )}
        </div>
      ) : (
        <div className='h-full overflow-y-auto'></div>
      )}
    </div>
  );
};

export default MessageSidebar;
