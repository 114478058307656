import { useQuery } from '@tanstack/react-query';
import { getCalendar } from 'utils/api/calendar';

export const useCalendar = (month: number, year: number) => {
  const { data, ...rest } = useQuery({
    queryKey: ['calendar', month, year],
    queryFn: () => getCalendar(month, year),
    select: (res) => res.data.calendar,
  });

  return { events: data?.events ?? [], tasks: data?.tasks ?? [], ...rest };
};
