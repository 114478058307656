import Avatar from 'components/memberAndProfile/Avatar';
import { userAvatar } from 'constants/common';
import BodyText from 'components/typography/BodyText';
import Tag from 'components/tag/Tag';
import MessageIcon from 'assets/icons/message.svg';
import DeleteIcon from 'assets/icons/delete.svg';
import Button from 'components/button/Button';
import { SingleMemberType, MemberRoleType } from 'types/apis/common';
import { ContextMenu } from 'components/common/ContextMenu';
import AddMemberIcon from 'assets/icons/add-member.svg';
import { useTranslation } from 'react-i18next';
import { generateUserProfileLink } from 'utils/url';
import React, { useState, useEffect } from 'react';
import ProfileDrawer from 'components/drawer/ProfileDrawer';
import { startConversation } from 'utils/api/message';
import { notifyTranslatedError } from 'utils/notify';
import { useNavigate } from 'react-router-dom';
import { timeSince } from 'utils/time';
import SingleConversationDrawer from 'components/drawer/SingleConversationDrawer';

interface MemberTileProps {
  userId: string;
  headline: string;
  firstName: string;
  lastName: string;
  lastVisit?: string;
  profilePicture: string;
  role: string;
  handleOpenRemoveMemberConfirmationModal: (userId: string) => void;
  handleMakeManager: (memberId: string) => void;
  handleMakeMember: (memberId: string) => void;
  userInfo: SingleMemberType | null;
  canManage: boolean;
}

const MemberTile: React.FC<MemberTileProps> = ({
  userId,
  headline,
  firstName,
  lastName,
  lastVisit,
  profilePicture,
  role,
  handleOpenRemoveMemberConfirmationModal,
  handleMakeManager,
  handleMakeMember,
  userInfo,
  canManage,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [hoveredMemberId, setHoveredMemberId] = useState('');
  const [isMediumOrLargerScreen, setIsMediumOrLargerScreen] = useState(window.innerWidth >= 768);
  const [isProfileDrawerOpen, setIsProfileDrawerOpen] = useState(false);
  const [isConversationOpen, setIsConversationOpen] = useState(false);
  const [conversationId, setConversationId] = useState('');
  const [isContextMenuOpen, setIsContextMenuOpen] = useState(false);

  const handleClickMenu = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault(); // Optional: Prevent the default action of the element
    event.stopPropagation(); // Stop the event from propagating up to parent elements
    //open the contextmenu here
    setIsContextMenuOpen(true);
    return false;
  };

  useEffect(() => {
    function handleResize() {
      setIsMediumOrLargerScreen(window.innerWidth >= 768);
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const handleDivClick = (userId: string) => {
    if (isMediumOrLargerScreen) {
      setIsProfileDrawerOpen(true);
    } else {
      navigate(generateUserProfileLink(userId));
    }
  };

  const handleStartChat = () => {
    let conversationType;
    if (userInfo?.userType == 'chatbot') {
      conversationType = 'private_chatbot';
    } else {
      conversationType = 'private';
    }
    startConversation({
      participants: [userId],
      conversationType,
    })
      .then((data) => {
        setConversationId(data.data.conversationId);
        setIsConversationOpen(true);
      })
      .catch((error) => {
        notifyTranslatedError(t, error.response.data?.message);
      });
  };

  return (
    <>
      <div
        className='relative flex cursor-pointer items-center bg-neutral-200 justify-between rounded-2xl border border-neutral-300 p-1 hover:border-primary-500 hover:shadow-lg active:border-primary-200'
        onMouseEnter={() => setHoveredMemberId(userId)}
        onMouseLeave={() => setHoveredMemberId('')}
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          handleDivClick(userId);
        }}
      >
        <div className='flex items-center gap-2'>
          <Avatar size={58} src={profilePicture || userAvatar} />
          <div>
            <BodyText variant='lg' className='font-medium'>{`${firstName} ${lastName}`}</BodyText>
            <BodyText variant='xs' className='line-clamp-1 capitalize'>
              {headline}
            </BodyText>
            {lastVisit && (
              <BodyText variant='xs' className='line-clamp-1 text-neutral-400'>
                {t('last_visit')}: {timeSince(new Date(lastVisit))}
              </BodyText>
            )}
            <div className='mt-1 flex items-center gap-1 justify-between w-full'>
              {role === MemberRoleType.manager ? (
                <Tag
                  type='rounded'
                  className='bg-functional-success-light text-functional-success-dark -px-1'
                >
                  {t('manager')}
                </Tag>
              ) : (
                <div></div>
              )}
              {userId !== userInfo?.id && (
                <div
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    handleStartChat();
                  }}
                  className='hidden items-center lg:flex'
                >
                  {hoveredMemberId === userId ? (
                    <Tag
                      type='rounded'
                      className=' border-1 flex items-center border-neutral-400 bg-neutral-100 text-secondary-500  hover:bg-neutral-300 hover:text-functional-info-dark active:bg-neutral-400 active:text-functional-info-dark'
                    >
                      <MessageIcon className='h-4 w-4 text-secondary-500 ' /> {t('chat')}
                    </Tag>
                  ) : (
                    <Tag type='rounded' className='-ml-3'>
                      <MessageIcon className='h-4 w-4 text-secondary-500' />
                    </Tag>
                  )}
                </div>
              )}
              {userId !== userInfo?.id && (
                <div className='ml-auto'>
                  <div
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      handleStartChat();
                    }}
                    className='absolute bottom-2 right-1 flex items-center lg:hidden '
                  >
                    <Button
                      size='small'
                      variant='outlined'
                      className='border-neutral-500 bg-neutral-100 text-secondary-500 active:bg-neutral-400 active:text-functional-info-dark '
                    >
                      <MessageIcon className='h-4 w-4' />
                      {t('chat')}
                    </Button>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>

        {role === MemberRoleType.member &&
          userInfo?.userType !== 'organization_admin' &&
          canManage &&
          hoveredMemberId === userId &&
          hoveredMemberId !== userInfo?.id && (
            <div className='absolute right-2 top-2 hidden lg:flex'>
              <button
                className='flex h-6 w-6 items-center justify-center rounded-full border border-functional-danger-dark bg-neutral-200 text-functional-danger-dark hover:bg-functional-danger-light active:bg-neutral-400'
                type='button'
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  handleOpenRemoveMemberConfirmationModal(userId);
                }}
              >
                <DeleteIcon className='h-4 w-4' />
              </button>
            </div>
          )}

        {userInfo?.userType === 'organization_admin' &&
          canManage &&
          hoveredMemberId === userId &&
          hoveredMemberId !== userInfo?.id && (
            <div className='absolute right-2 top-2 z-40 hidden lg:flex ' onClick={handleClickMenu}>
              <ContextMenu
                menuItems={[
                  {
                    key: 'remove',
                    label: t('remove_member'),
                    icon: DeleteIcon,
                    callback: () => handleOpenRemoveMemberConfirmationModal(userId),
                  },
                  ...(role === MemberRoleType.member
                    ? [
                        {
                          key: 'make_manager',
                          label: t('make_manager'),
                          icon: AddMemberIcon,
                          callback: () => handleMakeManager(userId),
                        },
                      ]
                    : []),
                  ...(role === MemberRoleType.manager
                    ? [
                        {
                          key: 'make_member',
                          label: t('make_member'),
                          icon: AddMemberIcon,
                          callback: () => handleMakeMember(userId),
                        },
                      ]
                    : []),
                ]}
              />
            </div>
          )}
      </div>
      <ProfileDrawer
        memberId={userId}
        isOpen={isProfileDrawerOpen}
        setIsOpen={setIsProfileDrawerOpen}
      />
      <SingleConversationDrawer
        conversationId={conversationId}
        isOpen={isConversationOpen}
        setIsOpen={setIsConversationOpen}
      />
    </>
  );
};

export default MemberTile;
