import React from 'react';

interface IButton extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  size?: 'small' | 'default' | 'large';
  variant?: 'default' | 'outlined' | 'secondary' | 'primary' | 'secondary-outlined';
  tooltip?: string;
}

function Button({
  size = 'default',
  variant = 'default',
  children,
  className = '',
  tooltip,
  ...rest
}: IButton) {
  return (
    <button
      className={`btn  ${
        size === 'small'
          ? 'btn-size-small'
          : size === 'default'
            ? 'btn-size-default'
            : size === 'large'
              ? 'btn-size-large'
              : ''
      } ${
        variant === 'default'
          ? 'btn-default'
          : variant === 'outlined'
            ? 'btn-outlined'
            : variant === 'secondary'
              ? 'btn-secondary'
              : variant === 'primary'
                ? 'btn-primary'
                : variant === 'secondary-outlined'
                  ? 'btn-secondary-outlined'
                  : ''
      } ${tooltip ? 'group relative ' : ' '}
      ${className}`}
      {...rest}
    >
      {children}
      {tooltip && <div className='tooltip top-6 text-xs text-neutral-100'>{tooltip}</div>}
    </button>
  );
}

export default Button;
