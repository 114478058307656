import DeleteIcon from 'assets/icons/delete.svg';
import Avatar from 'components/memberAndProfile/Avatar';
import { ContextMenu } from 'components/common/ContextMenu';
import ConfirmationModal from 'components/modal/ConfirmationModal';
import BodyText from 'components/typography/BodyText';
import Heading from 'components/typography/Heading';
import { userAvatar } from 'constants/common';
import { EditorState } from 'draft-js';
import { useInvalidateQueries } from 'hooks/common/useInvalidatequeries';
import React, { useState } from 'react';
import { UseFormReset } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { PostOrigin, SingleCommentType } from 'types/apis/common';
import {
  addCommunityPostComment,
  addProgramPostComment,
  deleteCommunityPostComment,
  deleteProgramPostComment,
} from 'utils/api/post';
import { convertHtmlToLink, editorContentToText, sanitizeHtml } from 'utils/helper';
import { notifyTranslatedError } from 'utils/notify';
import { formatDistanceToNow } from 'utils/time';
import PublishComment from './PublishComment';

interface ICommonComments {
  postId: string;
  originId: string;
  originType: PostOrigin;
  hasNextComments: boolean;
  fetchNextComments: () => void;
  comments: SingleCommentType[];
}

const CommonComments = ({
  postId,
  originId,
  comments,
  originType,
  hasNextComments,
  fetchNextComments,
}: ICommonComments) => {
  const { t } = useTranslation();

  const [isCollapse, setIsCollapse] = useState(true);
  const [selectedCommentId, setSelectedCommentId] = useState('');
  const [openDeleteConfirmationModal, setOpenDeleteConfirmationModal] = useState(false);

  const { invalidatePostComments } = useInvalidateQueries();

  // publish post comment
  const handlePublishComment = (
    data: { comment: string },
    reset: UseFormReset<{ comment: string }>,
    setEditorState: React.Dispatch<React.SetStateAction<EditorState>>,
  ) => {
    if (originType === PostOrigin.community) {
      addCommunityPostComment(originId, postId, data)
        .then(() => {
          reset({ comment: '' });
          setEditorState(EditorState.createEmpty());
          invalidatePostComments(originType, originId, postId);
        })
        .catch((error) => {
          notifyTranslatedError(t, error.response.data?.errorCode);
        });
    }

    if (originType === PostOrigin.program) {
      addProgramPostComment(originId, postId, data)
        .then(() => {
          reset({ comment: '' });
          setEditorState(EditorState.createEmpty());
          invalidatePostComments(originType, originId, postId);
        })
        .catch((error) => {
          notifyTranslatedError(t, error.response.data?.errorCode);
        });
    }
  };

  // delete post comment
  const handleOpenDeleteConfirmationModal = (commentId: string) => {
    setSelectedCommentId(commentId);
    setOpenDeleteConfirmationModal(true);
  };

  const handleCloseDeleteConfirmationModal = () => {
    setSelectedCommentId('');
    setOpenDeleteConfirmationModal(false);
  };

  const handleDeleteComment = () => {
    if (originType === 'community') {
      deleteCommunityPostComment(originId, selectedCommentId)
        .then(() => {
          setOpenDeleteConfirmationModal(false);
          invalidatePostComments(originType, originId, postId);
        })
        .catch((error) => {
          notifyTranslatedError(t, error.response.data?.errorCode);
        });
    }

    if (originType === 'program') {
      deleteProgramPostComment(originId, selectedCommentId)
        .then(() => {
          setOpenDeleteConfirmationModal(false);
          invalidatePostComments(originType, originId, postId);
        })
        .catch((error) => {
          notifyTranslatedError(t, error.response.data?.errorCode);
        });
    }
  };

  return (
    <div>
      <PublishComment
        originId={originId}
        originType={originType}
        onCommentPublish={handlePublishComment}
      />

      {comments.length ? (
        <>
          <Heading variant='h3' className='my-6 font-semibold text-neutral-600'>
            {t('comments')}
          </Heading>

          <div className='space-y-4'>
            {comments.map(({ id, comment, myComment, user, createdAt }) => (
              <div key={id} className='flex gap-4'>
                <Avatar size={32} src={user.profilePicture || userAvatar} />

                <div className='grow space-y-2 overflow-hidden rounded-lg bg-neutral-100 px-4 py-2'>
                  <div className='flex items-center justify-between'>
                    <div>
                      <BodyText variant='sm' className='flex items-center gap-1 text-neutral-400'>
                        <span>{`${user.firstName} ${user.lastName}`}</span>
                        <span className='h-1 w-1 rounded-full bg-neutral-400' />
                        {user.headline && <span className='line-clamp-1 '>{user.headline}</span>}
                      </BodyText>
                      <BodyText variant='xs' className='text-neutral-400'>
                        {formatDistanceToNow(new Date(createdAt))}
                      </BodyText>
                    </div>
                    {myComment && (
                      <ContextMenu
                        menuItems={[
                          {
                            key: 'delete-comment',
                            label: t('delete-comment'),
                            icon: DeleteIcon,
                            callback: () => handleOpenDeleteConfirmationModal(id),
                          },
                        ]}
                      />
                    )}
                  </div>
                  <div>
                    <BodyText
                      variant='base'
                      className={`whitespace-pre-line break-words text-neutral-500 ${
                        isCollapse
                          ? editorContentToText(comment).length > 500
                            ? 'line-clamp-2'
                            : ''
                          : ''
                      }`}
                    >
                      <span
                        // eslint-disable-next-line react/no-danger
                        dangerouslySetInnerHTML={{
                          __html: sanitizeHtml(convertHtmlToLink(editorContentToText(comment))),
                        }}
                      />
                    </BodyText>
                    {editorContentToText(comment).length > 500 && (
                      <button onClick={() => setIsCollapse(!isCollapse)}>
                        <BodyText variant='sm' className='mt-2 cursor-pointer font-medium'>
                          {t('see')} {isCollapse ? t('more') : t('less')}
                        </BodyText>
                      </button>
                    )}
                  </div>
                </div>
              </div>
            ))}

            {hasNextComments && (
              <button
                className='text-sm text-functional-info-dark hover:underline'
                onClick={() => fetchNextComments()}
              >
                {t('more-comments')}
              </button>
            )}
          </div>
        </>
      ) : null}

      {openDeleteConfirmationModal && (
        <ConfirmationModal
          isOpen={openDeleteConfirmationModal}
          label={t('delete-comment') || 'Delete Comment'}
          title={t('delete-comment_confirmation_title')}
          onClose={handleCloseDeleteConfirmationModal}
          onConfirm={handleDeleteComment}
        />
      )}
    </div>
  );
};

export default CommonComments;
