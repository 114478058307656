import { useCommunityPostComments } from 'hooks/api/post/useCommunityPostComments';
import { useInvalidateQueries } from 'hooks/common/useInvalidatequeries';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { PostOrigin } from 'types/apis/common';
import { PostComponentType } from 'types/components/shared';
import { addCommunityPinPost, deleteCommunityPinPost } from 'utils/api/post';
import { notifyTranslatedError } from 'utils/notify';
import CommonPost from './CommonPost';

const CommunityPost = (
  props: Omit<PostComponentType, 'postOriginId' | 'postOriginType' | 'handlePinPost'> & {
    communityId: string;
  },
) => {
  const { t } = useTranslation();
  const { communityId, postId, ...rest } = props;
  const [showComments, setShowComments] = useState(false);

  const { comments, hasNextComments, fetchNextComments } = useCommunityPostComments(
    communityId,
    postId,
    showComments,
  );

  const { invalidateCommunityPosts } = useInvalidateQueries();

  const handlePinPost = (isPinned: boolean, postId: string) => {
    if (isPinned) {
      deleteCommunityPinPost(communityId, postId)
        .then(() => {
          invalidateCommunityPosts(communityId);
        })
        .catch((error) => {
          notifyTranslatedError(t, error.response.data?.errorCode);
        });
    } else {
      addCommunityPinPost(communityId, postId)
        .then(() => {
          invalidateCommunityPosts(communityId);
        })
        .catch((error) => {
          notifyTranslatedError(t, error.response.data?.errorCode);
        });
    }
  };

  return (
    <CommonPost
      {...rest}
      postId={postId}
      comments={comments}
      showComments={showComments}
      postOriginId={communityId}
      postOriginType={PostOrigin.community}
      setShowComments={setShowComments}
      fetchNextComments={fetchNextComments}
      handlePinPost={handlePinPost}
      hasNextComments={hasNextComments || false}
    />
  );
};

export default CommunityPost;
