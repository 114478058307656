import AddIcon from 'assets/icons/add.svg';
import Avatar from 'components/memberAndProfile/Avatar';
import Button from 'components/button/Button';
import AddEditAdminModal from 'components/modal/AddEditAdminModal';
import Tag from 'components/tag/Tag';
import BodyText from 'components/typography/BodyText';
import { userAvatar } from 'constants/common';
import { useAdmins } from 'hooks/api/admin/useAdmins';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

const AdminListTable = () => {
  const { t } = useTranslation();

  const [openAdminModal, setOpenAdminModal] = useState(false);

  const { data: admins } = useAdmins();

  const handleOpenAdminModal = () => {
    setOpenAdminModal(true);
  };

  const handleCloseAdminModal = () => {
    setOpenAdminModal(false);
  };

  if (!admins) return null;

  return (
    <div className='space-y-4'>
      <div className='flex justify-between items-center gap-4'>
        <BodyText variant='lg' className='font-semibold text-secondary-500'>
          {t('all_admins')}
        </BodyText>
        <BodyText variant='sm' className='mr-auto text-secondary-600'>
          {t('total')} {admins.length} {t('users')}
        </BodyText>
        <Button
          size='default'
          variant='secondary-outlined'
          className='basis-full md:basis-auto'
          onClick={handleOpenAdminModal}
        >
          {t('add_new_admin')} <AddIcon className='h-5 w-5' />
        </Button>
      </div>
      <div className='mb-10 rounded-2xl bg-neutral-200 p-4 shadow shadow-neutral-300'>
        <div className='overflow-x-auto'>
          <table className='w-full min-w-[800px] table-auto'>
            <thead className='bg-neutral-100'>
              <tr className='border-b-2 border-neutral-200'>
                <th className='rounded-tl px-4 py-3 text-left'>
                  <BodyText variant='sm' className='font-medium'>
                    {t('name')}
                  </BodyText>
                </th>
                <th className='px-4 py-3 text-left'>
                  <BodyText variant='sm' className='font-medium'>
                    {t('email')}
                  </BodyText>
                </th>
                <th className='px-4 py-3 text-left'>
                  <BodyText variant='sm' className='font-medium'>
                    {t('join_date')}
                  </BodyText>
                </th>
                <th className='rounded-tr px-4 py-3 text-left'>
                  <BodyText variant='sm' className='font-medium'>
                    {t('role')}
                  </BodyText>
                </th>
              </tr>
            </thead>
            <tbody>
              {admins.map(
                ({
                  id,
                  firstName,
                  lastName,
                  email,
                  createdAt,
                  userType,
                  headline,
                  profilePicture,
                }) => (
                  <tr key={id} className='border-b-2 border-neutral-200'>
                    <td className='px-4 py-3'>
                      <div className='flex items-center gap-3'>
                        <Avatar size={36} src={profilePicture || userAvatar} />
                        <div>
                          <BodyText variant='sm' className='whitespace-nowrap text-neutral-500'>
                            {`${firstName} ${lastName}`}
                          </BodyText>
                          <BodyText variant='xs' className='text-neutral-400'>
                            {headline}
                          </BodyText>
                        </div>
                      </div>
                    </td>
                    <td className='px-4 py-3'>
                      <BodyText variant='sm' className='whitespace-nowrap text-neutral-500'>
                        {email}
                      </BodyText>
                    </td>
                    <td className='px-4 py-3'>
                      <BodyText variant='sm' className='whitespace-nowrap text-neutral-500'>
                        {new Date(createdAt).toDateString()}
                      </BodyText>
                    </td>
                    <td className='px-4 py-3'>
                      <Tag
                        type='rounded'
                        className='whitespace-nowrap bg-functional-info-dark text-neutral-100'
                      >
                        {t(`member.type.${userType}`)}
                      </Tag>
                    </td>
                  </tr>
                ),
              )}
            </tbody>
          </table>
        </div>
      </div>

      {openAdminModal && (
        <AddEditAdminModal isOpen={openAdminModal} onClose={handleCloseAdminModal} />
      )}
    </div>
  );
};

export default AdminListTable;
