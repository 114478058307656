import { Disclosure } from '@headlessui/react';
import LinkIcon from 'assets/icons/link.svg';
import ChevronDownIcon from 'assets/icons/chevron-down.svg';
import Avatar from 'components/memberAndProfile/Avatar';
import DownloadIcon from 'assets/icons/download.svg';
import MaterialIcon from 'components/common/MaterialIcon';
import BodyText from 'components/typography/BodyText';
import Heading from 'components/typography/Heading';
import { userAvatar } from 'constants/common';
import {
  useGuestMaterials,
  useGuestCommunityResources,
  useSingleGuestProgram,
  useSingleGuestCommunity,
} from 'hooks/api/guest/guest';
import React, { useMemo, useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import debounce from 'lodash/debounce';
import { bytesToMegaBytes } from 'utils/helper';
import SearchInput from 'components/form/SearchInput';
import dayjs from 'dayjs';

const OriginResources = () => {
  const { t } = useTranslation();
  const { originType, originId } = useParams();
  const { data: program, isLoading: isProgramLoading } =
    originType === 'program' && originId
      ? useSingleGuestProgram(originId)
      : { data: null, isLoading: false };
  const { data: community, isLoading: isCommunityLoading } =
    originType === 'community' && originId
      ? useSingleGuestCommunity(originId)
      : { data: null, isLoading: false };
  const entity = originType === 'program' ? program : community;

  if (!originType && !originId && !entity?.allowGuest) return null;

  if (originType === 'program') {
    const { files, groups, refetchMaterials } = useGuestMaterials(originId || '');
    return (
      <div>
        <div className='mb-7 flex flex-col items-center gap-4 md:flex-row'>
          <Heading variant='h2' className='mr-auto font-semibold text-secondary-600'>
            {t('resources')}
          </Heading>
        </div>

        <div className='mb-7 space-y-5'>
          {groups.map(({ groupId, name, hidden, files: groupFiles }) => (
            <Disclosure key={groupId}>
              {({ open }) => (
                <div className='w-full overflow-hidden rounded-2xl bg-neutral-200 shadow-md shadow-neutral-300 shadow-neutral-300'>
                  <Disclosure.Button
                    className={`flex w-full items-center gap-2 p-5 hover:bg-neutral-300 ${
                      open ? 'bg-secondary-100' : 'bg-neutral-200'
                    }`}
                  >
                    <ChevronDownIcon
                      className={`h-5 w-5 transition-transform ${open ? '' : '-rotate-90'}`}
                    />

                    <BodyText
                      as='span'
                      variant='lg'
                      className={`mr-auto font-semibold capitalize ${
                        open ? 'text-functional-info-dark' : 'text-secondary-600'
                      }`}
                    >
                      {name}
                    </BodyText>
                  </Disclosure.Button>

                  <Disclosure.Panel className={groupFiles.length === 0 ? 'pt-5' : ''}>
                    {groupFiles.map(
                      ({
                        fileId,
                        directory,
                        materialId,
                        title,
                        description,
                        type,
                        url,
                        hidden: materialHidden,
                      }) => (
                        <div>
                          {type === 'url' && (
                            <div
                              key={materialId}
                              className='flex w-full items-center justify-between gap-4 p-5 hover:bg-neutral-100 active:text-secondary-400'
                            >
                              <a
                                href={url}
                                target='_blank'
                                rel='noreferrer'
                                className='flex items-center gap-4'
                              >
                                <LinkIcon className='h-5 w-5' />
                                <div>
                                  <BodyText as='p' variant='base' className='mr-auto font-medium'>
                                    {title}
                                  </BodyText>
                                  <BodyText
                                    as='p'
                                    variant='xs'
                                    className='mr-auto font-medium text-neutral-500'
                                  >
                                    {description}
                                  </BodyText>
                                </div>
                              </a>
                            </div>
                          )}
                          {type === 'file' && (
                            <div
                              key={fileId}
                              className='flex w-full items-center justify-between gap-4 p-5 hover:bg-neutral-100 active:text-secondary-400'
                            >
                              <a
                                href={directory}
                                target='_blank'
                                rel='noreferrer'
                                className='flex items-center gap-4'
                              >
                                <MaterialIcon
                                  fileInput={directory + ''}
                                  className='h-5 w-5 flex-shrink-0 text-neutral-500'
                                />
                                <div>
                                  <BodyText as='p' variant='base' className='mr-auto font-medium'>
                                    {title}
                                  </BodyText>
                                  <BodyText
                                    as='p'
                                    variant='xs'
                                    className='mr-auto font-medium text-neutral-500'
                                  >
                                    {description}
                                  </BodyText>
                                </div>
                              </a>
                            </div>
                          )}
                        </div>
                      ),
                    )}
                  </Disclosure.Panel>
                </div>
              )}
            </Disclosure>
          ))}

          {files.map(({ materialId, file, title, description, type, url, hidden }) => (
            <div>
              {type === 'url' && (
                <div
                  key={materialId}
                  className='flex w-full items-center justify-between gap-4 rounded-2xl bg-neutral-200 p-4 shadow-md  hover:bg-neutral-300 active:text-secondary-400'
                >
                  <a
                    href={url}
                    target='_blank'
                    rel='noreferrer'
                    className='flex items-center gap-4'
                  >
                    <LinkIcon className='h-5 w-5' />
                    <div>
                      <BodyText as='p' variant='base' className='mr-auto font-medium'>
                        {title}
                      </BodyText>
                      <BodyText
                        as='p'
                        variant='xs'
                        className='mr-auto font-medium text-neutral-500'
                      >
                        {description}
                      </BodyText>
                    </div>
                  </a>
                </div>
              )}
              {type === 'file' && (
                <div
                  key={materialId}
                  className='flex w-full items-center justify-between gap-4 rounded-2xl bg-neutral-200 p-4 shadow-md hover:bg-neutral-300 active:text-secondary-400'
                >
                  <a
                    href={file.directory}
                    target='_blank'
                    rel='noreferrer'
                    className='flex items-center gap-4'
                  >
                    <MaterialIcon
                      fileInput={file.directory + ''}
                      className='h-5 w-5 flex-shrink-0 text-neutral-500'
                    />
                    <div>
                      <BodyText as='p' variant='base' className='mr-auto font-medium'>
                        {title}
                      </BodyText>
                      <BodyText
                        as='p'
                        variant='xs'
                        className='mr-auto font-medium text-neutral-500'
                      >
                        {description}
                      </BodyText>
                    </div>
                  </a>
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
    );
  } else if (originType === 'community') {
    const { data, refetch } = useGuestCommunityResources(originId || '');
    const [searchString, setSearchString] = useState('');
    const [selectedTitle, setSelectedTitle] = useState('');
    const filteredResources = useMemo(
      () =>
        data?.resources.filter(
          (resource) =>
            resource.title.toLowerCase().includes(searchString) ||
            resource.file?.fileName.toLowerCase().includes(searchString),
        ) || [],
      [data, searchString],
    );

    const handleSearch = (value: string) => {
      setSearchString(value.toLowerCase());
    };

    const debounceSearch = useCallback(debounce(handleSearch, 300), []);

    return (
      <>
        <div className='mb-5 space-y-5 rounded-2xl bg-neutral-200 p-4 shadow shadow-neutral-300 md:p-5'>
          <div className='flex flex-col gap-4 xl:flex-row xl:items-center xl:justify-between'>
            <div className='flex items-center gap-4'>
              <Heading variant='h2' className='font-semibold text-neutral-500'>
                {t('resources')}
              </Heading>
              <BodyText variant='sm' className='mr-auto text-secondary-600'>
                {t('total')} {data?.resources.length} {t('resources')}
              </BodyText>
            </div>
            <div className='flex flex-wrap items-center gap-4 md:flex-nowrap'>
              <SearchInput onSearch={debounceSearch} className='grow' />
            </div>
          </div>

          <div className='overflow-x-auto'>
            <table className='w-full min-w-[1000px] table-auto'>
              <thead className='bg-neutral-200'>
                <tr className='border-b-2 border-neutral-200'>
                  <th className='rounded-tl px-4 py-3 text-left'>
                    <BodyText variant='sm' className='font-medium'>
                      {t('resource_name')}
                    </BodyText>
                  </th>
                  <th className='px-4 py-3 text-left'>
                    <BodyText variant='sm' className='font-medium'>
                      {t('date')}
                    </BodyText>
                  </th>
                  <th className='px-4 py-3 text-left'>
                    <BodyText variant='sm' className='font-medium'>
                      {t('created-by')}
                    </BodyText>
                  </th>
                  <th className='rounded-tr px-4 py-3 text-left'>
                    <BodyText variant='sm' className='font-medium'>
                      {t('actions')}
                    </BodyText>
                  </th>
                </tr>
              </thead>

              <tbody>
                {filteredResources.map(({ id, createdAt, file, url, title, uploader }) => (
                  <tr key={id} className='border-b-2 border-neutral-200'>
                    {file && (
                      <td className='px-4 py-3'>
                        <button
                          className='flex items-center gap-3 text-neutral-500 hover:text-secondary-400 active:text-secondary-500'
                          onClick={() => {
                            window.open(file.directory, '_blank');
                          }}
                        >
                          <MaterialIcon
                            fileInput={file.directory + ''}
                            className='h-5 w-5 flex-shrink-0'
                          />
                          <div>
                            <BodyText variant='sm' className='text-left'>
                              {title}
                            </BodyText>
                            <BodyText variant='xs' className='text-left text-neutral-400'>
                              {bytesToMegaBytes(file.fileSize)}
                            </BodyText>
                          </div>
                        </button>
                      </td>
                    )}
                    {url && (
                      <td className='px-4 py-3'>
                        <div className='flex items-center gap-3 text-neutral-500 hover:text-secondary-400 hover:underline active:text-secondary-500'>
                          <LinkIcon className='h-5 w-5' />
                          <BodyText variant='sm'>
                            <a href={url} target='blank'>
                              {title}
                            </a>
                          </BodyText>
                        </div>
                      </td>
                    )}
                    <td className='px-4 py-3'>
                      <BodyText variant='sm' className='text-neutral-500'>
                        {dayjs(createdAt).format('ddd, D MMM YYYY, h:mm A')}
                      </BodyText>
                    </td>
                    <td className='px-4 py-3'>
                      <div className='flex items-center gap-3'>
                        <Avatar size={24} src={uploader.profilePicture || userAvatar} />
                        <BodyText variant='sm' className='text-neutral-500'>
                          {`${uploader.firstName} ${uploader.lastName}`}
                        </BodyText>
                      </div>
                    </td>
                    <td className='px-4 py-3'>
                      <div className='flex items-center gap-3'>
                        {!url && (
                          <button
                            className='flex h-6 w-6 items-center justify-center rounded-full hover:bg-neutral-300 active:bg-neutral-400'
                            onClick={() => {
                              if (file) window.open(file.directory, '_blank');
                            }}
                          >
                            <DownloadIcon className='h-5 w-5 text-neutral-400 hover:text-functional-info-dark' />
                          </button>
                        )}
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </>
    );
  }
  return null;
};
export default OriginResources;
