import Card from 'components/card/Card';
import Heading from 'components/typography/Heading';
import { useRelatedPrograms } from 'hooks/api/program/useRelatedPrograms';
import React from 'react';
import { useTranslation } from 'react-i18next';

interface IRelatedPrograms {
  programId: string;
}

const RelatedPrograms = ({ programId }: IRelatedPrograms) => {
  const { data: programs } = useRelatedPrograms(programId);
  const { t } = useTranslation();

  if (!programs) return null;

  return (
    <div className='h-full overflow-y-auto pb-7 px-4'>
      <Heading variant='h3' className='mb-2 mt-5 font-bold text-secondary-500'>
        {t('related_spaces')}
      </Heading>

      <div className='mb-6 grid grid-cols-1 gap-5 md:grid-cols-2 xl:grid-cols-3 xxl:grid-cols-4'>
        {programs.map(({ id, name, memberCount, visibility, cover, organization }) => (
          <Card
            key={id}
            title={name}
            coverImage={cover}
            members={memberCount}
            to={`/programs/${id}`}
            visibility={visibility}
            organizationLogo={organization.logo}
            organizationName={organization.name}
          />
        ))}
      </div>
    </div>
  );
};

export default RelatedPrograms;
