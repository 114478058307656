import { AxiosRequestConfig } from 'axios';
import { API_ROOT } from 'constants/config';
import { ProgramGroupsResponseType } from 'types/apis/program/response';
import { AllGroupsResponseType } from 'types/apis/response';
import { generateQueryParam, parseGET, parsePOST, parsePUT } from 'utils/rest';

const PROGRAM_API_ROOT = `${API_ROOT}/program`;

export const getAllGroups = (limit?: number, cursor?: string, options?: AxiosRequestConfig) => {
  const queryParams = generateQueryParam({ limit, cursor });
  return parseGET<AllGroupsResponseType>(`${API_ROOT}/dashboard/user/groups${queryParams}`, {
    withCredentials: true,
    ...options,
  });
};

export const getProgramGroups = (
  programId: string,
  limit?: number,
  cursor?: string,
  options?: AxiosRequestConfig,
) => {
  const queryParams = generateQueryParam({ limit, cursor });
  return parseGET<ProgramGroupsResponseType>(
    `${PROGRAM_API_ROOT}/${programId}/group${queryParams}`,
    {
      withCredentials: true,
      ...options,
    },
  );
};

export const getArchivedProgramGroups = (
  programId: string,
  limit?: number,
  cursor?: string,
  options?: AxiosRequestConfig,
) => {
  const queryParams = generateQueryParam({ limit, cursor });
  return parseGET<ProgramGroupsResponseType>(
    `${PROGRAM_API_ROOT}/${programId}/group/archived${queryParams}`,
    {
      withCredentials: true,
      ...options,
    },
  );
};

export const createProgramGroup = (
  programId: string,
  body: FormData,
  options?: AxiosRequestConfig,
) => {
  return parsePOST(`${PROGRAM_API_ROOT}/${programId}/group`, body, {
    withCredentials: true,
    ...options,
  });
};

export const updateProgramGroup = (
  programId: string,
  groupId: string,
  body: FormData,
  options?: AxiosRequestConfig,
) => {
  return parsePUT(`${PROGRAM_API_ROOT}/${programId}/group/${groupId}`, body, {
    withCredentials: true,
    ...options,
  });
};

export const archiveProgramGroup = (
  programId: string,
  groupId: string,
  options?: AxiosRequestConfig,
) => {
  return parsePUT(
    `${PROGRAM_API_ROOT}/${programId}/group/${groupId}/archive`,
    {},
    {
      withCredentials: true,
      ...options,
    },
  );
};

export const restoreProgramGroup = (
  programId: string,
  groupId: string,
  options?: AxiosRequestConfig,
) => {
  return parsePUT(
    `${PROGRAM_API_ROOT}/${programId}/group/${groupId}/restore`,
    {},
    {
      withCredentials: true,
      ...options,
    },
  );
};
