import { FetchNextPageOptions, InfiniteQueryObserverResult } from '@tanstack/react-query';
import BackIcon from 'assets/icons/back.svg';
import CalendarIcon from 'assets/icons/calendar.svg';
import Button from 'components/button/Button';
import ClockIcon from 'assets/icons/clock.svg';
import ViewsIcon from 'assets/icons/eye.svg';
import CommentIcon from 'assets/icons/comment.svg';
import LikeIcon from 'assets/icons/like.svg';
import LocationIcon from 'assets/icons/location.svg';
import EventComments from 'components/common/Comments';
import Layout from 'components/layout/Layout';
import Tag from 'components/tag/Tag';
import BodyText from 'components/typography/BodyText';
import Heading from 'components/typography/Heading';
import dayjs from 'dayjs';
import { EditorState } from 'draft-js';
import RichTextEditor from 'components/common/RichTextEditorWithUpload';
import React, { useState } from 'react';
import { UseFormReset } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { SingleCommentType, UserType } from 'types/apis/common';
import { CommentsResponseType } from 'types/apis/response';
import { generateOriginLink } from 'utils/url';
import MemberButton from 'components/memberAndProfile/MemberButton';

interface IEventDetails {
  eventTitle: string;
  eventCover?: string;
  eventAbout: string;
  isEventLiked: boolean;
  handleLikeEvent: () => void;
  eventLikeCount: number;
  eventCommentCount: number;
  eventCreatorId: string;
  eventCreatorName: string;
  eventCreatorAvatar: string;
  eventCreatorProfileLink: string;
  eventCreatorUserType: UserType;
  contactUserAvatar?: string;
  contactUserId?: string;
  contactUserName?: string;
  contactUserProfileLink?: string;
  eventOriginType: 'program' | 'community';
  eventOriginId: string;
  eventOriginName: string;
  eventOriginLink: string;
  eventIsAllDay?: boolean;
  eventStartTime: string;
  eventEndTime: string;
  eventViewCount: number;
  eventType: 'online' | 'inperson';
  eventLink: null | string;
  eventLocation: null | string;
  handleAddToCalendar: () => void;
  comments: SingleCommentType[];
  hasNextComments?: boolean;
  fetchNextComments: (
    options?: FetchNextPageOptions | undefined,
  ) => Promise<InfiniteQueryObserverResult<CommentsResponseType, unknown>>;
  handleDeleteComment: (commentId: string) => void;
  handlePublishComment: (
    data: { comment: string },
    reset: UseFormReset<{ comment: string }>,
    setEditorState: React.Dispatch<React.SetStateAction<EditorState>>,
  ) => void;
}

const EventDetails = ({
  eventTitle,
  eventCover,
  eventAbout,
  isEventLiked,
  handleLikeEvent,
  eventLikeCount,
  eventCommentCount,
  eventCreatorId,
  eventCreatorName,
  eventCreatorAvatar,
  eventCreatorProfileLink,
  eventCreatorUserType,
  contactUserAvatar,
  contactUserProfileLink,
  contactUserId,
  contactUserName,
  eventOriginType,
  eventOriginId,
  eventOriginName,
  eventOriginLink,
  eventIsAllDay,
  eventStartTime,
  eventEndTime,
  eventType,
  eventLink,
  eventLocation,
  eventViewCount,
  handleAddToCalendar,
  comments,
  hasNextComments,
  fetchNextComments,
  handleDeleteComment,
  handlePublishComment,
}: IEventDetails) => {
  const { t } = useTranslation();
  const [isCopied, setIsCopied] = useState(false);
  const [showComments, setShowComments] = useState(true);

  const handleCopyEventLink = (link: string) => {
    setIsCopied(true);
    navigator.clipboard.writeText(link);
  };

  return (
    <Layout>
      <div className='md:py-4 md:px-5 py-1 px-2 w-full h-full'>
        <Link
          to={generateOriginLink(eventOriginId, eventOriginType) + '/events'}
          className='mb-2 flex items-center gap-2  justify-end'
        >
          <Button
            size='default'
            variant='outlined'
            className='text-functional-danger-400 p-[8px]'
            tooltip={t('back')}
          >
            <BackIcon className='h-4 w-4' />
          </Button>
        </Link>

        <div className='mb-6 grid grid-cols-5 gap-6'>
          <section className='order-2 col-span-5 xl:order-none xl:col-span-3'>
            <div className='space-y-5 rounded-2xl bg-neutral-200 p-4 shadow shadow-neutral-300'>
              {eventCover && (
                <img
                  alt={eventTitle}
                  src={eventCover}
                  className='h-[123px] w-full rounded-lg object-cover md:h-[268px]'
                />
              )}
              <Heading variant='h2' className='mb-3 font-semibold capitalize text-neutral-400'>
                {t('about')}
              </Heading>

              <RichTextEditor initialContent={eventAbout} isReadOnly={true} />

              <div className='flex items-center gap-7 border-t border-neutral-300 pt-4'>
                <button
                  className={`flex items-center gap-2 ${
                    isEventLiked ? 'text-functional-info-dark' : 'text-neutral-400'
                  }`}
                  onClick={handleLikeEvent}
                >
                  <LikeIcon className='h-4 w-4' />
                  <BodyText as='span' variant='xs' className='font-medium'>
                    {eventLikeCount}
                  </BodyText>
                </button>

                <button
                  className='flex items-center gap-2'
                  onClick={() => setShowComments(!showComments)}
                >
                  <CommentIcon className='h-4 w-4 text-neutral-400' />
                  <BodyText as='span' variant='xs' className='font-medium text-neutral-400'>
                    {eventCommentCount}
                  </BodyText>
                </button>
                <button className='group relative flex items-center gap-2'>
                  <ViewsIcon className='h-4 w-4 text-neutral-400' />
                  <BodyText variant='xs' className='font-medium text-neutral-400'>
                    {Math.round(eventViewCount / 1.9)}
                  </BodyText>
                  <div className='absolute top-4 hidden max-w-[100px] overflow-hidden rounded bg-neutral-500 px-1 py-1 opacity-20 transition-opacity duration-200 ease-in-out group-hover:block group-hover:opacity-100'>
                    <BodyText variant='xs' className='whitespace-nowrap text-neutral-100'>
                      {t('view_count')}
                    </BodyText>
                  </div>
                </button>
              </div>

              {showComments && (
                <EventComments
                  originId={eventOriginId}
                  originType={eventOriginType}
                  comments={comments}
                  hasNextComments={hasNextComments}
                  fetchNextComments={fetchNextComments}
                  handleDeleteComment={handleDeleteComment}
                  handlePublishComment={handlePublishComment}
                />
              )}
            </div>
          </section>

          <section className='col-span-5 xl:col-span-2'>
            <div className='space-y-5 rounded-2xl bg-neutral-200 p-4 shadow shadow-neutral-300'>
              <div className='space-y-2'>
                <MemberButton
                  userId={eventCreatorId}
                  isCreator={true}
                  profilePicture={eventCreatorAvatar}
                  userName={eventCreatorName}
                  chat={eventCreatorUserType === 'member' ? true : false}
                />

                {contactUserId && contactUserId !== eventCreatorId && (
                  <MemberButton
                    userId={contactUserId}
                    isCreator={false}
                    profilePicture={contactUserAvatar || ''}
                    userName={contactUserName || ''}
                    chat={true}
                  />
                )}
              </div>

              <hr className='border-neutral-300' />

              <Tag
                type='default'
                className='mb-2 bg-functional-info-light capitalize text-functional-info-dark'
              >
                <Link to={eventOriginLink}>{eventOriginName}</Link>
              </Tag>

              <Heading variant='h1' className='mb-3 font-semibold capitalize'>
                {eventTitle}
              </Heading>

              <div className='space-y-3'>
                <BodyText
                  variant='lg'
                  className={`flex items-center gap-2 font-medium ${
                    new Date(eventStartTime) < new Date()
                      ? 'text-neutral-400'
                      : 'text-functional-info-dark'
                  }`}
                >
                  <CalendarIcon className='h-5 w-5' />
                  {dayjs(eventStartTime).format('ddd, D MMM YYYY')}
                </BodyText>
                <div className='flex flex-wrap'>
                  <BodyText
                    variant='lg'
                    className={`flex items-center gap-2 font-medium ${
                      new Date(eventStartTime) < new Date()
                        ? 'text-neutral-400'
                        : 'text-functional-info-dark'
                    }`}
                  >
                    <ClockIcon className='h-5 w-5' />
                    {eventIsAllDay
                      ? t('all_day_event')
                      : `${dayjs(eventStartTime).format('h:mm A')} - ${dayjs(eventEndTime).format(
                          'h:mm A Z',
                        )}`}
                  </BodyText>
                  <BodyText variant='sm' className='flex items-center font-medium'>
                    &nbsp;
                    {' ('}
                    {dayjs(eventStartTime).fromNow()}
                    {')'}
                  </BodyText>
                </div>
              </div>

              <hr className='border-neutral-300' />

              {eventType === 'online' && eventLink && (
                <div className='space-y-4'>
                  <div className='flex items-center justify-between'>
                    <BodyText variant='lg' className='font-semibold'>
                      {t('event_link')}
                    </BodyText>
                    <Button
                      size='small'
                      variant='primary'
                      onClick={() => handleCopyEventLink(eventLink)}
                    >
                      {isCopied ? t('copied') : t('copy')}
                    </Button>
                  </div>
                  <a
                    href={eventLink}
                    target='_blank'
                    rel='noreferrer'
                    className='line-clamp-2 break-all text-sm font-semibold text-functional-info-dark'
                  >
                    {eventLink}
                  </a>
                </div>
              )}

              {eventType === 'inperson' && eventLocation && (
                <div className='space-y-4'>
                  <BodyText variant='lg' className='font-semibold text-neutral-400'>
                    {t('location')}
                  </BodyText>
                  <p className='flex items-center gap-2 text-sm font-semibold text-secondary-600'>
                    <LocationIcon className='h-5 w-5' /> {t('venue')}: {eventLocation}
                  </p>
                </div>
              )}

              <div className='space-y-2'>
                {eventType === 'online' && eventLink && (
                  <a
                    target='_blank'
                    rel='noreferrer'
                    href={eventLink}
                    className='btn btn-primary btn-size-default w-full'
                  >
                    {t('join-event')}
                  </a>
                )}

                <Button
                  size='default'
                  variant='default'
                  className='w-full bg-secondary-100'
                  onClick={handleAddToCalendar}
                >
                  <ClockIcon className='h-5 w-5' /> {t('add_to_calendar')}
                </Button>
              </div>
            </div>
          </section>
        </div>
      </div>
    </Layout>
  );
};

export default EventDetails;
