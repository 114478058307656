import { useQuery } from '@tanstack/react-query';
import { getSettings } from 'utils/api/settings';

export const useSettings = () => {
  return useQuery({
    queryKey: ['settings'],
    queryFn: () => getSettings(),
    select: (data) => data.data.user,
  });
};
