import { useQuery } from '@tanstack/react-query';
import { getProgramPublicResources } from 'utils/api/publicResource';

export const useProgramPublicResources = (programId: string, limit?: number) => {
  return useQuery({
    queryKey: ['program-public-resources', programId, limit],
    queryFn: () => getProgramPublicResources(programId, limit),
    select: (data) => data.data,
    enabled: !!programId,
  });
};
