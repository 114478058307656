import { useQuery } from '@tanstack/react-query';
import { getProgramSubmissionRemarks } from 'utils/api/remark';

export const useProgramRemarks = (programId: string, submissionId: string, enabled = true) => {
  return useQuery({
    queryKey: ['program-remarks', programId, submissionId],
    queryFn: () => getProgramSubmissionRemarks(programId, submissionId),
    select: (data) => data.data.remarks,
    enabled: !!programId && !!submissionId && enabled,
  });
};
