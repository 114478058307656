import Loading from 'components/Loading';
import ConfirmationModal from 'components/modal/ConfirmationModal';
import NavigateTo404 from 'components/NavigateTo404';
import { userAvatar } from 'constants/common';
import { EditorState } from 'draft-js';
import { useCommunityEvent } from 'hooks/api/event/useCommunityEvent';
import { useCommunityEventComments } from 'hooks/api/event/useCommunityEventComments';
import { useInvalidateQueries } from 'hooks/common/useInvalidatequeries';
import React, { createContext, useState } from 'react';
import { UseFormReset } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { eventAddToCalendar } from 'utils/api/calendar';
import {
  addCommunityEventComment,
  deleteCommunityEventComment,
  dislikeCommunityEvent,
  likeCommunityEvent,
} from 'utils/api/event';
import { generateIcsFile } from 'utils/helper';
import { notifySuccess, notifyTranslatedError } from 'utils/notify';
import { generateOriginLink, generateUserProfileLink } from 'utils/url';
import EventDetails from '../event/EventDetails';
import { FetchNextPageOptions, InfiniteQueryObserverResult } from '@tanstack/react-query';
import { CommentsResponseType } from 'types/apis/response';

export const CommentContext = createContext({});

const CommunityEventDetailsPage = () => {
  const { t } = useTranslation();
  const { communityId, eventId } = useParams();

  const [selectedCommentId, setSelectedCommentId] = useState<string | null>(null);
  const [openDeleteConfirmation, setOpenDeleteConfirmation] = useState(false);

  const { data: event, refetch, isLoading } = useCommunityEvent(communityId || '', eventId || '');
  const { comments, hasNextComments, fetchNextComments } = useCommunityEventComments(
    communityId || '',
    eventId || '',
  );
  const { invalidateSingleCommunityEvent, invalidateCommunityEventComments } =
    useInvalidateQueries();

  if (isLoading) return <Loading />;
  if (!event) return <NavigateTo404 />;

  const {
    id: eventUid,
    title,
    cover,
    about,
    liked,
    likeCount,
    commentCount,
    creator,
    eventContactUser,
    community: origin,
    isAllDay,
    startTime,
    endTime,
    type,
    zoomLink,
    location,
    viewCount,
  } = event;
  const { id: creatorId, firstName, lastName, profilePicture, userType } = creator;
  let eventContactUserId, contactUserFirstName, contactUserLastName, contactUserProfilePicture;
  if (eventContactUser) {
    ({
      id: eventContactUserId,
      firstName: contactUserFirstName,
      lastName: contactUserLastName,
      profilePicture: contactUserProfilePicture,
    } = eventContactUser);
  }
  const { id: originId, name: originName } = origin;

  // event details
  const handleLikeEvent = () => {
    if (liked) {
      dislikeCommunityEvent(originId, eventUid)
        .then(() => {
          refetch();
        })
        .catch((error) => {
          notifyTranslatedError(t, error.response.data?.errorCode);
        });
    } else {
      likeCommunityEvent(originId, eventUid)
        .then(() => {
          refetch();
        })
        .catch((error) => {
          notifyTranslatedError(t, error.response.data?.errorCode);
        });
    }
  };

  const handleAddToCalendar = () => {
    eventAddToCalendar(originId, eventUid)
      .then((data) => {
        generateIcsFile(data, 'event');
      })
      .catch((error) => {
        notifyTranslatedError(t, error.response.data?.errorCode);
      });
  };

  // event comments
  const handleOpenDeleteConfirmation = (commentId: string) => {
    setSelectedCommentId(commentId);
    setOpenDeleteConfirmation(true);
  };

  const handleCloseDeleteConfirmation = () => {
    setSelectedCommentId(null);
    setOpenDeleteConfirmation(false);
  };

  const handleDeleteComment = () => {
    if (!selectedCommentId) return;
    deleteCommunityEventComment(originId, selectedCommentId)
      .then(() => {
        notifySuccess(t('success.api.comment_deleted'));
        handleCloseDeleteConfirmation();
        invalidateSingleCommunityEvent(originId, eventUid);
        invalidateCommunityEventComments(originId, eventUid);
      })
      .catch((error) => {
        notifyTranslatedError(t, error.response.data?.errorCode);
      });
  };

  // comment publish
  const handlePublishComment = (
    data: { comment: string },
    reset: UseFormReset<{ comment: string }>,
    setEditorState: React.Dispatch<React.SetStateAction<EditorState>>,
  ) => {
    addCommunityEventComment(originId, eventUid, data)
      .then(() => {
        reset({ comment: '' });
        setEditorState(EditorState.createEmpty());
        invalidateSingleCommunityEvent(originId, eventUid);
        invalidateCommunityEventComments(originId, eventUid);
      })
      .catch((error) => {
        notifyTranslatedError(t, error.response.data?.errorCode);
      });
  };

  return (
    <div>
      <EventDetails
        eventTitle={title}
        eventCover={cover || ''}
        eventAbout={about}
        isEventLiked={liked}
        handleLikeEvent={handleLikeEvent}
        eventLikeCount={likeCount}
        eventCommentCount={commentCount}
        eventCreatorId={creatorId}
        eventCreatorName={`${firstName} ${lastName}`}
        eventCreatorAvatar={profilePicture || userAvatar}
        eventCreatorProfileLink={generateUserProfileLink(creatorId)}
        eventCreatorUserType={userType}
        contactUserId={eventContactUserId}
        contactUserAvatar={contactUserProfilePicture || userAvatar}
        contactUserName={`${contactUserFirstName} ${contactUserLastName}`}
        contactUserProfileLink={generateUserProfileLink(eventContactUserId || '')}
        eventOriginType='community'
        eventOriginId={originId}
        eventOriginName={originName}
        eventViewCount={viewCount}
        eventOriginLink={generateOriginLink(originId, 'community')}
        eventIsAllDay={isAllDay || false}
        eventStartTime={startTime}
        eventEndTime={endTime}
        eventType={type}
        eventLink={zoomLink}
        eventLocation={location}
        handleAddToCalendar={handleAddToCalendar}
        comments={comments}
        hasNextComments={hasNextComments}
        fetchNextComments={
          fetchNextComments as (
            options?: FetchNextPageOptions | undefined,
          ) => Promise<InfiniteQueryObserverResult<CommentsResponseType, unknown>>
        }
        handlePublishComment={handlePublishComment}
        handleDeleteComment={handleOpenDeleteConfirmation}
      />

      {openDeleteConfirmation && (
        <ConfirmationModal
          isOpen={openDeleteConfirmation}
          label={t('delete-comment')}
          title={t('delete-comment_confirmation_title')}
          onClose={handleCloseDeleteConfirmation}
          onConfirm={handleDeleteComment}
        />
      )}
    </div>
  );
};

export default CommunityEventDetailsPage;
