import Avatar from 'components/memberAndProfile/Avatar';
import LeftIcon from 'assets/icons/chevron-left.svg';
import { userAvatar } from 'constants/common';
import AddMemberIcon from 'assets/icons/add-member.svg';
import BodyText from 'components/typography/BodyText';
import { useMessages, useLastRead } from 'hooks/api/message/useMessages';
import { useConversation } from 'hooks/api/message/useConversation';
import { useChatbotId } from 'hooks/api/message/usechatbotId';
import { flagConversation } from 'utils/api/message';
import React, { useRef, useMemo, useLayoutEffect, useState } from 'react';
import { MappedMessagesResponseType } from 'types/apis/response';
import { useUser } from 'hooks/useUser';
import { useTranslation } from 'react-i18next';
import Button from 'components/button/Button';
import EditIcon from 'assets/icons/edit.svg';
import PinIcon from 'assets/icons/pin.svg';
import { MessageBubble } from './MessageBubble';
import { SendMessageForm } from './SendMessageForm';
import { generateOriginLink } from 'utils/url';
import Tag from 'components/tag/Tag';
import { Link } from 'react-router-dom';
import { ContextMenu } from 'components/common/ContextMenu';
import { notifySuccess, notifyTranslatedError } from 'utils/notify';
import RenameConversationModal from 'components/modal/renameConversationModal';
import { CreateGroupConversation } from 'components/message/CreateGroupConversation';
import { useConversationsList } from 'hooks/api/message/useConversationsList';

export const Conversation = ({ conversationId }: { conversationId: string }) => {
  const { t } = useTranslation();
  const user = useUser();
  const { refetchConversationsList } = useConversationsList(true);
  const { participants, community, program, alias, conversationType, refetch } =
    useConversation(conversationId);
  const { data: lastReadData, isLoading: isLastReadLoading } = useLastRead(conversationId);
  const chatbotId = useChatbotId(conversationId);
  const loggedInUser = participants?.find((participant) => participant.me === true);
  const chatAdmin = participants?.find((participant) => participant.isChatAdmin === true);
  const [openRenameConversationModal, setRenameConversationModal] = useState(false);
  const [isChatOpen, setIsChatOpen] = useState(false);
  const [replyMessage, setReplyMessage] = useState({
    onReplyMessageId: '',
    onReplyContent: '',
    onReplySenderId: '',
    onReplySenderFirstName: '',
    onReplySenderLastName: '',
  });
  // rename and flag conversation
  const handleEditChat = () => {
    setRenameConversationModal(true);
  };

  const handleFlagConversation = () => {
    if (!user) return;
    flagConversation(conversationId, user.id)
      .then(() => {
        refetchConversationsList();
        notifySuccess(t('action-success'));
      })
      .catch((error) => {
        notifyTranslatedError(t, error.response.data?.errorCode);
      });
  };

  const {
    messages: messagesData,
    fetchNextMessages,
    hasNextMessages,
    isMessagesLoading,
    refetchMessages,
  } = useMessages(conversationId);

  const messageContainerRef = useRef<HTMLDivElement>(null);
  const messages = useMemo(() => {
    if (!messagesData || !participants) return [];

    const lastReadDataArray = lastReadData?.lastReadMessages || [];

    const lastReadDataByMessageId = lastReadDataArray.reduce(
      (accumulator, { messageId, userId, firstName }) => {
        if (userId !== user?.id) {
          if (accumulator[messageId]) {
            accumulator[messageId].readByUserIds.push(userId);
            accumulator[messageId].readByFirstNames.push(firstName);
          } else {
            accumulator[messageId] = { readByUserIds: [userId], readByFirstNames: [firstName] };
          }
        }
        return accumulator;
      },
      {} as { [key: string]: { readByUserIds: string[]; readByFirstNames: string[] } },
    );

    return messagesData.reduce((allMessages, message) => {
      const { senderId } = message;
      const sender = participants.find(({ id }) => id === senderId);
      if (!sender) return allMessages;

      const lastRead = lastReadDataByMessageId[message.id];
      const readByUserIds = lastRead?.readByUserIds || [];
      const readByFirstNames = lastRead?.readByFirstNames || [];

      return [
        ...allMessages,
        {
          ...message,
          sender,
          isMine: senderId === user?.id,
          readByUserId: readByUserIds.join(', '),
          readByFirstName: readByFirstNames.join(', '),
        },
      ];
    }, [] as MappedMessagesResponseType);
  }, [messagesData, participants]);

  useLayoutEffect(() => {
    const messageContainer = messageContainerRef.current;
    if (messageContainer) {
      messageContainer.scrollTop = messageContainer.scrollHeight;
    }
  }, [messages]);

  const handleReply = (
    onReplyMessageId: string,
    onReplyContent: string,
    onReplySenderId: string,
    onReplySenderFirstName: string,
    onReplySenderLastName: string,
  ) => {
    setReplyMessage({
      onReplyMessageId,
      onReplyContent,
      onReplySenderId,
      onReplySenderFirstName,
      onReplySenderLastName,
    });
  };

  const handleDeleteReply = () => {
    setReplyMessage({
      onReplyMessageId: '',
      onReplyContent: '',
      onReplySenderId: '',
      onReplySenderFirstName: '',
      onReplySenderLastName: '',
    });
  };

  const handleModifyChatMembers = () => {
    setIsChatOpen(true);
  };

  const forwardMessageToChatbot = conversationType === 'private_chatbot' ? true : false;
  const groupAvatar = community?.cover ? community.cover : program?.cover;

  if (!conversationId || (!messages && !participants)) return null;

  return (
    <div className='flex h-full w-full flex-col overflow-hidden'>
      <div className='flex flex-shrink-0 items-center gap-2 border-b border-neutral-300 bg-secondary-100 p-2'>
        <div className='flex w-full grid-rows-2 items-center justify-between gap-2 '>
          <LeftIcon
            className='-mr-2 h-5 w-5 flex-shrink-0 cursor-pointer md:hidden '
            onClick={() => window.history.back()}
          />
          <div className='row-span-2 flex max-w-[25%] shrink-0'>
            {conversationType === 'group' && (
              <Avatar
                size={42}
                src={groupAvatar || userAvatar}
                className='cursor-pointer border border-[white]'
              />
            )}
            {conversationType !== 'group' &&
              participants?.slice(0, 8).map(({ id, profilePicture }, index) => (
                <span key={id} className={`${index > 0 ? '-ml-3' : ''}`}>
                  <Avatar
                    size={30}
                    src={profilePicture || userAvatar}
                    className='border border-[white]'
                  />
                </span>
              ))}
          </div>
          <div className='row-span-1 justify-start'>
            <BodyText variant='base' className='line-clamp-1'>
              {alias && alias !== ''
                ? alias
                : participants
                    ?.filter((participant) => participant.id !== user?.id)
                    ?.map(({ firstName, lastName }) => `${firstName} ${lastName}`)
                    .join(', ')}
            </BodyText>
            <div className='row-span-1 flex justify-start gap-2'>
              {community && (
                <Tag
                  type='default'
                  className='justify-left max-w-[10rem] overflow-hidden truncate bg-functional-info-light text-functional-info-dark'
                >
                  <Link to={generateOriginLink(community.id, 'community')}>
                    {' '}
                    <span className='truncate text-clip text-left text-xs'>{community.name}</span>
                  </Link>
                </Tag>
              )}
              {program && (
                <Tag
                  type='default'
                  className='justify-left max-w-[10rem] overflow-hidden truncate bg-functional-info-light text-functional-info-dark'
                >
                  <Link to={generateOriginLink(program.id, 'program')}>
                    <span className='truncate text-clip text-left text-xs'> {program.name}</span>
                  </Link>
                </Tag>
              )}
            </div>
          </div>
          <div className='z-40 flex flex-grow justify-end'>
            <div className='block md:hidden'>
              <ContextMenu
                menuItems={[
                  ...(loggedInUser?.id === chatAdmin?.id
                    ? [
                        {
                          key: 'rename',
                          label: t('rename'),
                          icon: EditIcon,
                          callback: handleEditChat,
                        },
                        ...(conversationType === 'group'
                          ? [
                              {
                                key: 'modify',
                                label: t('members'),
                                icon: AddMemberIcon,
                                callback: handleModifyChatMembers,
                              },
                            ]
                          : []),
                      ]
                    : []),
                  {
                    key: 'flag',
                    label: t('flag_conversation'),
                    icon: PinIcon,
                    callback: handleFlagConversation,
                  },
                ]}
              />
            </div>
            <div className='flex hidden min-w-[80px] flex-wrap items-center justify-end gap-1 md:flex'>
              {loggedInUser?.id === chatAdmin?.id && (
                <div className='flex flex-wrap items-center gap-1'>
                  <button
                    className='neutral-button group relative flex h-5 w-5 items-center justify-center rounded-full border border-neutral-300'
                    onClick={handleEditChat}
                  >
                    <EditIcon className='h-4 w-4' />
                    <div className=' tooltip top-6'>
                      <BodyText variant='xs' className='whitespace-nowrap text-neutral-100'>
                        {t('rename')}
                      </BodyText>
                    </div>
                  </button>
                  {conversationType === 'group' && (
                    <button
                      className='neutral-button group relative flex h-5 w-5 items-center justify-center rounded-full border border-neutral-300'
                      onClick={handleModifyChatMembers}
                    >
                      <AddMemberIcon className='h-4 w-4' />
                      <div className=' tooltip top-6'>
                        <BodyText variant='xs' className='whitespace-nowrap text-neutral-100'>
                          {t('members')}
                        </BodyText>
                      </div>
                    </button>
                  )}
                </div>
              )}
              <button
                className='neutral-button group relative flex h-5 w-5 items-center justify-center rounded-full border border-neutral-300'
                onClick={handleFlagConversation}
              >
                <PinIcon className='h-4 w-4' />
                <div className=' tooltip right-2 top-6'>
                  <BodyText variant='xs' className='whitespace-nowrap text-neutral-100'>
                    {t('flag_conversation')}
                  </BodyText>
                </div>
              </button>
            </div>
          </div>
        </div>
      </div>
      <div
        className='w-full flex-1 justify-center overflow-y-auto px-3 pb-4 pr-4 bg-neutral-200'
        ref={messageContainerRef}
      >
        <div className='mx-auto lg:max-w-[1000px]'>
          {!isMessagesLoading && hasNextMessages && (
            <Button
              className='m-auto mt-4'
              onClick={() => {
                fetchNextMessages();
              }}
            >
              {t('previous-messages')}
            </Button>
          )}
          {messages.map(
            ({
              id,
              file,
              senderId,
              sender: { firstName, lastName, profilePicture },
              message,
              createdAt,
              isMine,
              replyMessageId,
              replyContent,
              replySender,
              readByUserId,
              readByFirstName,
            }: any) => (
              <MessageBubble
                key={id}
                messageId={id}
                isMine={isMine}
                senderId={senderId}
                message={message}
                replyMessageId={replyMessageId}
                replyContent={replyContent}
                replySenderId={replySender?.id}
                replySenderFirstName={replySender?.firstName}
                replySenderLastName={replySender?.lastName}
                createdAt={createdAt}
                firstName={firstName}
                lastName={lastName}
                readByUserId={readByUserId}
                readByFirstName={readByFirstName}
                profilePicture={profilePicture}
                file={file}
                refetchMessage={refetchMessages}
                handleReply={(
                  onReplyMessageId,
                  onReplyContent,
                  onReplySenderId,
                  onReplySenderFirstName,
                  onReplySenderLastName,
                ) =>
                  handleReply(
                    onReplyMessageId,
                    onReplyContent,
                    onReplySenderId,
                    onReplySenderFirstName,
                    onReplySenderLastName,
                  )
                }
              />
            ),
          )}
        </div>
      </div>
      <div className='w-full  border-t border-neutral-300' />
      <div className='flex justify-center w-full flex-shrink-0 p-2 bg-neutral-200'>
        <SendMessageForm
          refetchMessages={refetchMessages}
          conversationId={conversationId}
          originType={community ? 'community' : program ? 'program' : 'all'}
          originId={community ? community.id : program?.id || ''}
          messageContainerRef={messageContainerRef}
          onReplyMessageId={replyMessage?.onReplyMessageId}
          onReplyContent={replyMessage?.onReplyContent}
          onReplySenderId={replyMessage?.onReplySenderId}
          onReplySenderFirstName={replyMessage?.onReplySenderFirstName}
          onReplySenderLastName={replyMessage?.onReplySenderLastName}
          handleDeleteReply={handleDeleteReply}
          forwardMessageToChatbot={forwardMessageToChatbot}
          chatbotId={chatbotId}
        />
      </div>

      <RenameConversationModal
        isOpen={openRenameConversationModal}
        onClose={() => setRenameConversationModal(false)}
        conversationId={conversationId}
        refetchConversation={refetch}
      />
      <CreateGroupConversation
        isOpen={isChatOpen}
        setIsOpen={setIsChatOpen}
        refetchConversation={refetch}
        isUpdating={true}
        conversationId={conversationId}
        participants={participants}
        originType={community ? 'community' : 'program'}
        originId={community ? community.id : program?.id || ''}
      />
    </div>
  );
};
