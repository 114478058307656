import Button from 'components/button/Button';
import TextInput from 'components/form/TextInput';
import BodyText from 'components/typography/BodyText';
import Heading from 'components/typography/Heading';
import React from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { SignupFormInputsType } from 'types/components/form';
import { signup } from 'utils/api/auth';
import { notifySuccess, notifyTranslatedError } from 'utils/notify';

interface ISignUpForm {
  token: string;
  encodedemail: string;
  originId: string;
  originType: string;
  inviteToken: string;
}

const SignUpForm = ({ token, encodedemail, originId, originType, inviteToken }: ISignUpForm) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const {
    watch,
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<SignupFormInputsType>({
    defaultValues: {
      email: encodedemail,
    },
  });

  const isEmailReadOnly = encodedemail !== '';

  const onSubmit: SubmitHandler<SignupFormInputsType> = (data) => {
    data.originId = originId || undefined;
    data.originType = originType || undefined;
    data.inviteToken = inviteToken || undefined;
    signup(data, token)
      .then(() => {
        notifySuccess(t('success.api.registration_successfull'));
        navigate('/login');
        navigate(0);
      })
      .catch((error) => {
        notifyTranslatedError(t, error.response.data?.errorCode);
        notifyTranslatedError(t, error.response.data?.message);
      });
  };

  return (
    <section className=' flex w-full flex-col'>
      <div className='grow items-center justify-center px-4 md:px-5 lg:flex xl:px-0'>
        <div className='w-full xl:w-3/5'>
          <Heading variant='h3' className='mb-1 font-bold capitalize'>
            {t('create_an_account')}
          </Heading>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className='mb-4 space-y-1'>
              <BodyText variant='sm' className='font-medium text-neutral-500'>
                {t('first-name')}
              </BodyText>
              <Controller
                name='firstName'
                control={control}
                rules={{
                  required: { value: true, message: t('required_error_message') },
                }}
                render={({ field }) => (
                  <TextInput placeholder={t('first-name') || 'First Name'} {...field} />
                )}
              />
              {errors.firstName && (
                <BodyText variant='xs' className='text-functional-danger-400'>
                  {errors.firstName?.message}
                </BodyText>
              )}
            </div>
            <div className='mb-4 space-y-1'>
              <BodyText variant='sm' className='font-medium text-neutral-500'>
                {t('last-name')}
              </BodyText>
              <Controller
                name='lastName'
                control={control}
                rules={{
                  required: { value: true, message: t('required_error_message') },
                }}
                render={({ field }) => (
                  <TextInput placeholder={t('last-name') || 'Last Name'} {...field} />
                )}
              />
              {errors.lastName && (
                <BodyText variant='xs' className='text-functional-danger-400'>
                  {errors.lastName?.message}
                </BodyText>
              )}
            </div>
            <div className='mb-4 space-y-1'>
              <BodyText variant='sm' className='font-medium text-neutral-500'>
                {t('email')}
              </BodyText>
              <Controller
                name='email'
                control={control}
                rules={{
                  required: { value: true, message: t('required_error_message') },
                }}
                render={({ field }) => (
                  <TextInput
                    type='email'
                    placeholder={t('email') || 'Email'}
                    {...field}
                    readOnly={isEmailReadOnly}
                  />
                )}
              />
              {errors.email && (
                <BodyText variant='xs' className='text-functional-danger-400'>
                  {errors.email?.message}
                </BodyText>
              )}
            </div>
            <div className='mb-4 space-y-1'>
              <BodyText variant='sm' className='font-medium text-neutral-500'>
                {t('password')}
              </BodyText>
              <Controller
                name='password'
                control={control}
                rules={{
                  required: { value: true, message: t('required_error_message') },
                  minLength: {
                    value: 6,
                    message: t('password_length_error_message', { length: 6 }),
                  },
                  pattern: {
                    value: /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{6,})/,
                    message: t('password_pattern_error_message'),
                  },
                }}
                render={({ field }) => (
                  <TextInput type='password' placeholder={t('password') || 'Password'} {...field} />
                )}
              />
              {errors.password && (
                <BodyText variant='xs' className='text-functional-danger-400'>
                  {errors.password?.message}
                </BodyText>
              )}
            </div>

            <div className='mb-4 space-y-1'>
              <BodyText variant='sm' className='font-medium text-neutral-500'>
                {t('repeat-password')}
              </BodyText>
              <Controller
                name='repeatPassword'
                control={control}
                rules={{
                  required: { value: true, message: t('required_error_message') },
                  // eslint-disable-next-line consistent-return
                  validate: (val: string) => {
                    if (watch('password') !== val) {
                      return t('ERROR.PASSWORD_MISMATCH') || 'Passwords do not match';
                    }
                  },
                }}
                render={({ field }) => (
                  <TextInput
                    type='password'
                    placeholder={t('repeat-password') || 'Repeate Password'}
                    {...field}
                  />
                )}
              />
              {errors.repeatPassword && (
                <BodyText variant='xs' className='text-functional-danger-400'>
                  {errors.repeatPassword?.message}
                </BodyText>
              )}
            </div>

            <div className='mb-6'>
              <Controller
                name='privacyPolicy'
                control={control}
                rules={{ required: true }}
                render={({ field: { value, ...field } }) => (
                  // eslint-disable-next-line jsx-a11y/label-has-associated-control
                  <label className='flex items-center gap-2'>
                    <input type='checkbox' checked={value} {...field} />
                    <span className='text-sm text-neutral-500'>
                      <Trans i18nKey='privacy-policy-agreement'>
                        I’ve read and agree to the{' '}
                        <a
                          href='https://www.studycentral.eu/privacy-policy'
                          className={`font-bold ${
                            errors.privacyPolicy
                              ? 'text-functional-danger-400'
                              : 'text-secondary-600'
                          }`}
                        >
                          Privacy Policy
                        </a>
                      </Trans>
                    </span>
                  </label>
                )}
              />
              <Controller
                name='termsAndConditions'
                control={control}
                rules={{ required: true }}
                render={({ field: { value, ...field } }) => (
                  // eslint-disable-next-line jsx-a11y/label-has-associated-control
                  <label className='flex items-center gap-2'>
                    <input type='checkbox' checked={value} {...field} />
                    <span className='text-sm text-neutral-500'>
                      <Trans i18nKey='terms-and-conditions-agreement'>
                        I’ve read and agree to the{' '}
                        <a
                          href='https://www.studycentral.eu/terms-conditions'
                          className={`font-bold ${
                            errors.termsAndConditions
                              ? 'text-functional-danger-400'
                              : 'text-secondary-600'
                          }`}
                        >
                          Terms and Conditions
                        </a>
                      </Trans>
                    </span>
                  </label>
                )}
              />
            </div>
            <Button type='submit' size='default' variant='secondary' className='w-full'>
              {t('sign-up')}
            </Button>
          </form>
        </div>
      </div>
    </section>
  );
};

export default SignUpForm;
