import AddIcon from 'assets/icons/add.svg';
import BackIcon from 'assets/icons/back.svg';
import DeleteIcon from 'assets/icons/delete.svg';
import Button from 'components/button/Button';
import MemberButton from 'components/memberAndProfile/MemberButton';
import Loading from 'components/Loading';
import AddManagerModal from 'components/modal/AddManagerModal';
import ConfirmationModal from 'components/modal/ConfirmationModal';
import NavigateTo404 from 'components/NavigateTo404';
import Tag from 'components/tag/Tag';
import BodyText from 'components/typography/BodyText';
import { useProgramManagers } from 'hooks/api/member/useProgramManagers';
import { useSingleProgram } from 'hooks/api/program/useSingleProgram';
import { useInvalidateQueries } from 'hooks/common/useInvalidatequeries';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router';
import { addProgramManager, deleteProgramManager } from 'utils/api/member';
import { notifySuccess, notifyTranslatedError } from 'utils/notify';

const ProgramManagersPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { programId } = useParams();

  const [selectedManager, setSelectedManager] = useState('');
  const [showAddManagerModal, setShowAddManagerModal] = useState(false);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);

  const { data: program, isLoading: isProgramLoading } = useSingleProgram(programId || '');
  const { data: managers, isLoading: isManagersLoading } = useProgramManagers(programId || '');
  const { invalidateProgramManagers, invalidateSingleProgram, invalidateMyPrograms } =
    useInvalidateQueries();

  if (isProgramLoading || isManagersLoading) return <Loading />;
  if (!program || !managers) return <NavigateTo404 />;

  // add program manager
  const handleAddManager = (data: { email: string }) => {
    addProgramManager(program.id, data)
      .then(() => {
        invalidateProgramManagers(program.id);
        invalidateSingleProgram(program.id);
        invalidateMyPrograms();
        setShowAddManagerModal(false);
        notifySuccess(t('success.api.add_manager'));
      })
      .catch((error) => {
        notifyTranslatedError(t, error.response.data?.errorCode);
      });
  };

  // remove program manager
  const handleOpenConfirmation = (managerId: string) => {
    setSelectedManager(managerId);
    setShowDeleteConfirmation(true);
  };

  const handleCloseConfirmation = () => {
    setSelectedManager('');
    setShowDeleteConfirmation(false);
  };

  const handleRemoveManager = () => {
    deleteProgramManager(program.id, selectedManager)
      .then(() => {
        invalidateProgramManagers(program.id);
        invalidateSingleProgram(program.id);
        invalidateMyPrograms();
        handleCloseConfirmation();
        notifySuccess(t('success.api.delete_manager'));
      })
      .catch((error) => {
        notifyTranslatedError(t, error.response.data?.errorCode);
      });
  };

  return (
    <>
      <div className='w-full overflow-x-auto overflow-y-auto space-y-4'>
        <div className='z-40 flex items-center justify-between px-1 gap-2 '>
          <BodyText variant='base' className='text-neutral-500 font-semibold'>
            {t('space_managers')}
          </BodyText>
          <div className='gap-3 flex items-center'>
            <Button
              size='default'
              variant='secondary-outlined'
              tooltip={t('add_new_manager')}
              onClick={() => setShowAddManagerModal(true)}
            >
              <AddIcon className='h-4 w-4' />
            </Button>
            <button onClick={() => navigate(-1)}>
              <Button
                size='default'
                variant='outlined'
                className='text-functional-danger-400'
                tooltip={t('back')}
              >
                <BackIcon className='h-4 w-4' />
              </Button>
            </button>
          </div>
        </div>
        <div className='table-container'>
          <table>
            <caption className='bg-secondary-100 p-1'>
              <BodyText variant='base' className='font-semibold text-neutral-500 line-clamp-1'>
                {program.name}
              </BodyText>
            </caption>
            <thead>
              <tr>
                <th>{t('name')}</th>
                <th>{t('email')}</th>
                <th>{t('join_date')}</th>
                <th>{t('role')}</th>
                <th>{t('actions')}</th>
              </tr>
            </thead>
            <tbody>
              {managers?.map(
                ({ id: userId, firstName, lastName, email, profilePicture, createdAt }) => (
                  <tr key={userId}>
                    <td>
                      <MemberButton
                        userId={userId || ''}
                        isCreator={false}
                        profilePicture={profilePicture || ''}
                        userName={firstName + ' ' + lastName}
                        chat={false}
                        size='small'
                      />
                    </td>
                    <td>
                      <BodyText variant='sm' className='text-neutral-500'>
                        {email}
                      </BodyText>
                    </td>
                    <td>
                      <BodyText variant='sm' className='text-neutral-500'>
                        {new Date(createdAt).toDateString()}
                      </BodyText>
                    </td>
                    <td>
                      <Tag
                        type='rounded'
                        className='bg-functional-info-light capitalize text-functional-info-dark'
                      >
                        {t('manager')}
                      </Tag>
                    </td>
                    <td>
                      <button onClick={() => handleOpenConfirmation(userId)}>
                        <DeleteIcon className='h-5 w-5 text-neutral-400' />
                      </button>
                    </td>
                  </tr>
                ),
              )}
            </tbody>
          </table>
        </div>
      </div>

      {showAddManagerModal && programId && (
        <AddManagerModal
          isSingle={true}
          title={t('add_manager')}
          btnText={t('add_manager')}
          isOpen={showAddManagerModal}
          onClose={() => setShowAddManagerModal(false)}
          onSubmit={handleAddManager}
        />
      )}

      {showDeleteConfirmation && (
        <ConfirmationModal
          isOpen={showDeleteConfirmation}
          label={t('remove_manager')}
          title={t('confirmation-general')}
          description={
            t('remove_manager_confirmation_description') ||
            'This manager will not be able to do any activity!'
          }
          onClose={handleCloseConfirmation}
          onConfirm={handleRemoveManager}
        />
      )}
    </>
  );
};

export default ProgramManagersPage;
