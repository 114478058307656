import React from 'react';

interface IBodyText extends React.HTMLProps<HTMLParagraphElement> {
  as?: 'p' | 'span' | 'div';
  variant: 'lg' | 'base' | 'sm' | 'xs';
}

function BodyText({ className = '', children, variant, as: Component = 'p' }: IBodyText) {
  switch (variant) {
    case 'lg':
      return <Component className={` text-lg leading-7 ${className}`}>{children}</Component>;
    case 'base':
      return <Component className={`text-base leading-[26px] ${className}`}>{children}</Component>;
    case 'sm':
      return <Component className={`text-sm leading-5 ${className}`}>{children}</Component>;
    case 'xs':
      return <Component className={`text-xs leading-[18px] ${className}`}>{children}</Component>;
    default:
      return <Component className={`text-base leading-[26px] ${className}`}>{children}</Component>;
  }
}

export default BodyText;
