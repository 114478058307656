import ErrorCard from 'components/card/ErrorCard';
import SuccessCard from 'components/card/SuccessCard';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import { verifyEmailLink } from 'utils/api/auth';

const VerifyPage = () => {
  const { t } = useTranslation();
  const [params] = useSearchParams();

  const [verified, setVerified] = useState(false);

  const token = params.get('token');

  const handleVerify = useCallback(() => {
    if (!token) return false;
    return verifyEmailLink(token)
      .then(() => {
        setVerified(true);
      })
      .catch(() => {
        setVerified(false);
      });
  }, [token, verified]);

  useEffect(() => {
    if (!token) return;
    handleVerify();
  }, []);

  if (!token) return null;

  return (
    <div className='flex h-screen items-center justify-center px-4 md:px-0 bg-neutral-200'>
      {verified ? (
        <SuccessCard
          hasCta
          ctaText={t('log-in') || 'Login'}
          ctaLink='/login'
          title={t('varification_success')}
          subtext={t('verification_success_subtitle')}
        />
      ) : (
        <ErrorCard
          hasCta
          ctaText={t('back_to_signin') || 'Back to Login'}
          ctaLink='/login'
          title={t('varification_failed')}
          subtext={t('verification_failed_subtitle')}
        />
      )}
    </div>
  );
};

export default VerifyPage;
