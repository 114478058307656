import Button from 'components/button/Button';
import SelectTag from 'components/form/SelectTag';
import BodyText from 'components/typography/BodyText';
import { useInvalidateQueries } from 'hooks/common/useInvalidatequeries';
import { TagDetails } from 'types/components/form';
import React from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { InviteFormInputType } from 'types/components/form';
import { inviteCommunityMember } from 'utils/api/member';
import { inviteProgramMember } from 'utils/api/member';
import { notifySuccess, notifyTranslatedError } from 'utils/notify';
import { objToFormData } from 'utils/rest';
import ModalContainer from './ModalContainer';

interface IAddMemberModal {
  isOpen: boolean;
  originType: string;
  originId: string;
  groupParentId?: string;
  onClose: () => void;
}

const AddMemberModal = ({
  isOpen,
  onClose,
  originId,
  originType,
  groupParentId,
}: IAddMemberModal) => {
  const { t } = useTranslation();

  const { invalidateCommunityMembers, invalidateCommunityPendingInvites } = useInvalidateQueries();
  const { invalidateProgramMembers, invalidateProgramPendingInvites } = useInvalidateQueries();

  const {
    watch,
    reset,
    setValue,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm<InviteFormInputType>();

  const handleSubmit = () => {
    const emails = watch('emails');
    const email = watch('email');

    if (emails.length === 0 && !email) {
      setError('emails', { type: 'required' });
      return;
    }

    const formData = objToFormData({ emails: JSON.stringify([...emails, email]) });

    // Check originType and call appropriate API function
    const inviteFunction = originType === 'community' ? inviteCommunityMember : inviteProgramMember;

    inviteFunction(originId, formData)
      .then(() => {
        reset();
        onClose();
        notifySuccess(t('success.api.invite_member'));
        // Invalidate appropriate data based on originType
        if (originType === 'community') {
          invalidateCommunityMembers(originId);
          invalidateCommunityPendingInvites(originId);
        } else {
          invalidateProgramMembers(originId);
          invalidateProgramPendingInvites(originId);
        }
      })
      .catch((error) => {
        notifyTranslatedError(t, error.response.data?.errorCode);
      });
  };

  const handleSelectEmail = (tags: TagDetails[]) => {
    const emails = tags.map((tag) => tag.email);
    setValue('emails', emails);
    if (emails.length !== 0) {
      clearErrors('emails');
    }
  };

  return (
    <ModalContainer title={t('add_new_member')} isOpen={isOpen} onClose={onClose}>
      <div className='space-y-2 min-h-[200px]'>
        <BodyText
          variant='sm'
          className={`font-medium ${
            errors.emails ? 'text-functional-danger-400' : 'text-neutral-500'
          }`}
        >
          {t('emails_names')}
        </BodyText>
        <SelectTag
          programId={groupParentId ? groupParentId : undefined}
          onValueChange={(value) => setValue('email', value)}
          onChange={handleSelectEmail}
          className={errors.emails ? 'border-functional-danger-400' : ''}
        />

        {errors.emails && (
          <BodyText variant='sm' className='font-medium text-functional-danger-400'>
            {t('tag_validation_error_message')}
          </BodyText>
        )}
      </div>

      <div className='mt-5 flex items-center justify-end gap-3 border-t border-neutral-200 pt-5'>
        <Button size='small' variant='outlined' onClick={onClose}>
          {t('cancel')}
        </Button>
        <Button size='small' variant='secondary' onClick={handleSubmit}>
          {t('add_member')}
        </Button>
      </div>
    </ModalContainer>
  );
};

export default AddMemberModal;
