import { useQuery } from '@tanstack/react-query';
import { getNotifications } from 'utils/api/notification';

export const useNotifications = (enabled = true) => {
  return useQuery({
    queryKey: ['notifications'],
    queryFn: () => getNotifications(),
    select: (data) => data.data,
    enabled,
  });
};
