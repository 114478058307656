import React from 'react';

type IResetPassCard = {
  children: React.ReactNode;
  className?: string;
};

function ResetPassCard({ children, className = '' }: IResetPassCard) {
  return (
    <div
      className={`w-[470px] rounded-xl border border-secondary-100 py-6 px-4 shadow shadow-neutral-300 md:px-6 ${className}`}
    >
      {children}
    </div>
  );
}

export default ResetPassCard;
