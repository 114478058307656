import BodyText from 'components/typography/BodyText';
import ClockIcon from 'assets/icons/clock.svg';
import ChevronRightIcon from 'assets/icons/chevron-right.svg';
import { Disclosure } from '@headlessui/react';
import { Link } from 'react-router-dom';
import { useCommunityTasks } from 'hooks/api/task/useCommunityTasks';
import React from 'react';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import { generateTaskLink } from 'utils/url';
import Tag from 'components/tag/Tag';

interface IUpcomingTasks {
  originId: string;
}

const UpcomingTasks = ({ originId }: IUpcomingTasks) => {
  const { t } = useTranslation();

  const { tasks } = useCommunityTasks(originId, 2, true);

  if (!tasks.length) return null;

  return (
    <div className='rounded-2xl bg-neutral-200 p-2 shadow shadow-neutral-300'>
      <Disclosure defaultOpen={true}>
        {({ open }) => (
          <>
            <Disclosure.Button className='flex w-full items-center '>
              <ChevronRightIcon className={`h-5 w-5  ${open ? 'rotate-90 transform' : ''} `} />
              <BodyText variant='base' className='font-semibold text-secondary-500'>
                {t('upcoming_tasks')}
              </BodyText>
            </Disclosure.Button>
            <Disclosure.Panel>
              <div className='px-4 mt-2'>
                {tasks.map((task) => {
                  const {
                    id: taskId,
                    title,
                    submitted,
                    organization,
                    deadline,
                    softDeadline,
                    hasDeadline,
                  } = task;
                  return (
                    <Link to={generateTaskLink('community', originId, taskId)} key={taskId}>
                      <button className='rounded-lg neutral-button w-full text-left p-1'>
                        <div className='flex items-center w-full justify-between'>
                          <BodyText variant='sm' className='font-medium'>
                            {title}
                          </BodyText>
                          <div className='flex items-center gap-3'>
                            {!hasDeadline && (
                              <Tag
                                type='default'
                                className='bg-functional-success-light text-functional-success-dark text-xs'
                              >
                                {t('open_ended_task')}
                              </Tag>
                            )}
                            {dayjs(deadline).diff(dayjs(new Date())) > 0 && hasDeadline && (
                              <Tag
                                type='default'
                                className='bg-functional-success-light text-functional-success-dark text-xs'
                              >
                                {t('open')}
                              </Tag>
                            )}
                            {dayjs(deadline).diff(dayjs(new Date())) < 0 && hasDeadline && (
                              <Tag
                                type='default'
                                className='bg-functional-danger-light text-functional-danger-dark text-xs'
                              >
                                {t('closed')}
                              </Tag>
                            )}
                            {submitted && (
                              <Tag
                                type='default'
                                className='bg-functional-success-light text-functional-success-dark text-xs'
                              >
                                {t('submitted')}
                              </Tag>
                            )}
                          </div>
                        </div>
                        {hasDeadline ? (
                          <div className='flex items-center gap-1'>
                            <ClockIcon className='h-[14px] w-[14px] text-neutral-500' />
                            <BodyText variant='sm'>
                              <span className='text-neutral-500'>{t('deadline')}:</span>{' '}
                              <span className='text-functional-danger-dark'>
                                {dayjs(hasDeadline).format('dddd, D MMM YYYY, h:mm A')}
                              </span>
                            </BodyText>
                          </div>
                        ) : (
                          <div></div>
                        )}
                      </button>
                    </Link>
                  );
                })}
              </div>
            </Disclosure.Panel>
          </>
        )}
      </Disclosure>
    </div>
  );
};

export default UpcomingTasks;
