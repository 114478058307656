import React, { FunctionComponent, useState, useRef, useEffect } from 'react';
import BodyText from 'components/typography/BodyText';
import EditorJS, { EditorConfig, OutputData } from '@editorjs/editorjs';
import { editorStyle } from 'components/common/editorStyle';
import Header from '@editorjs/header';
import { useTranslation } from 'react-i18next';
import List from '@editorjs/list';
import code from '@editorjs/code';
import Delimiter from '@editorjs/delimiter';
import Checklist from '@editorjs/checklist';
import ImageTool from '@editorjs/image';
import Paragraph from '@editorjs/paragraph';
import AlignmentBlockTune from 'editorjs-text-alignment-blocktune';
import Marker from '@editorjs/marker';
import Table from '@editorjs/table';
import TextVariantTune from '@editorjs/text-variant-tune';
import Underline from '@editorjs/underline';
import InlineCode from '@editorjs/inline-code';
import Strikethrough from '@sotaproject/strikethrough';
import YoutubeEmbed from 'editorjs-youtube-embed';
import FontFamily from 'editorjs-inline-font-family-tool';
import FontSizeTool from 'editorjs-inline-font-size-tool';
import VideoTool from '@weekwood/editorjs-video';
import AttachesTool from '@editorjs/attaches';
import editorjsNestedChecklist from '@calumk/editorjs-nested-checklist';
import { uploadFileToPage, deleteFileFromPage } from 'utils/api/pages';
import { notifySuccess, notifyError } from 'utils/notify';
import Iframe from '@hammaadhrasheedh/editorjs-iframe';

let isEditorReady = false;

export async function fileUploader(
  file: File,
): Promise<{ success: number; file?: { url: string } }> {
  let formData = new FormData();
  formData.append('file', file);
  try {
    const response = await uploadFileToPage(formData);
    return {
      success: 1,
      file: {
        url: response.data.url,
      },
    };
  } catch (error) {
    notifyError('Failed to upload file');
    return {
      success: 0,
    };
  }
}

class CustomImage extends ImageTool {
  async removed() {
    if (!isEditorReady) {
      return;
    }
    // access the image block's file data
    const { file } = this._data;
    if (file && file.url) {
      try {
        const response = await deleteFileFromPage(file.url);
        notifySuccess('File deleted successfully');
      } catch (error) {
        notifyError('Failed to delete file');
      }
    }
  }
}

class CustomVideo extends VideoTool {
  async removed() {
    if (!isEditorReady) {
      return;
    }
    // access the image block's file data
    const { file } = this._data;
    if (file && file.url) {
      try {
        const response = await deleteFileFromPage(file.url);
        notifySuccess('File deleted successfully');
      } catch (error) {
        notifyError('Failed to delete file');
      }
    }
  }
}

class CustomAttachTool extends AttachesTool {
  async removed() {
    if (!isEditorReady) {
      return;
    }
    // access the image block's file data
    const { file } = this._data;
    if (file && file.url) {
      try {
        const response = await deleteFileFromPage(file.url);
        notifySuccess('File deleted successfully');
      } catch (error) {
        notifyError('Failed to delete file');
      }
    }
  }
}

export const EditorTools = {
  header: {
    class: Header,
    inlineToolbar: true,
    config: {
      levels: [1, 2, 3, 4],
      defaultLevel: 2,
    },
    tunes: ['anyTuneName'],
  },
  textVariant: TextVariantTune,
  paragraph: {
    class: Paragraph,
    inlineToolbar: true,
    tunes: ['anyTuneName', 'textVariant'],
  },
  underline: Underline,
  inlinecode: InlineCode,
  fontFamily: FontFamily,
  fontSize: FontSizeTool,
  strikethrough: Strikethrough,
  list: {
    class: List,
    inlineToolbar: true,
    tunes: ['anyTuneName', 'textVariant'],
    config: {
      defaultStyle: 'unordered',
    },
  },
  image: {
    class: CustomImage as any,
    config: {
      uploader: {
        uploadByFile: fileUploader,
      },
    },
  },
  video: {
    class: CustomVideo as any,
    config: {
      uploader: {
        uploadByFile: fileUploader,
      },
      player: {
        controls: true,
        autoplay: false,
      },
    },
  },
  attaches: {
    class: CustomAttachTool as any,
    config: {
      uploader: {
        uploadByFile: fileUploader,
      },
    },
  },
  checklist: {
    class: Checklist,
    inlineToolbar: true,
    tunes: ['anyTuneName', 'textVariant'],
  },
  nestedchecklist: editorjsNestedChecklist,
  code: code,
  delimiter: Delimiter,
  youtubeEmbed: YoutubeEmbed,
  anyTuneName: {
    class: AlignmentBlockTune,
    inlineToolbar: true,
    config: {
      default: 'left',
    },
  },
  Marker: {
    class: Marker,
    inlineToolbar: true,
    shortcut: 'CMD+SHIFT+M',
  },
  table: {
    class: Table,
    inlineToolbar: true,
    config: {
      rows: 2,
      cols: 3,
    },
  },
  iframe: Iframe,
};

const parseInitialContent = (initialContent?: string | OutputData) => {
  let initialContentData: OutputData | any;
  if (initialContent && typeof initialContent === 'string') {
    try {
      initialContentData = JSON.parse(initialContent);
    } catch {
      initialContentData = {
        time: new Date().getTime(),
        blocks: [
          {
            type: 'paragraph',
            data: {
              text: initialContent,
            },
          },
        ],
      };
    }
  } else {
    initialContentData = initialContent;
  }
  if (!initialContentData) {
    initialContentData = {
      time: new Date().getTime(),
      blocks: [],
    };
  }
  return initialContentData;
};

interface RichTextEditorProps {
  initialContent?: string | OutputData;
  handleOnChange?: (content: OutputData) => void;
  isReadOnly?: boolean;
  userId?: string;
  resetEditor?: boolean;
}

const RichTextEditor: FunctionComponent<RichTextEditorProps> = ({
  initialContent,
  handleOnChange,
  isReadOnly,
  userId,
  resetEditor,
}) => {
  const initialContentData = parseInitialContent(initialContent);
  const [editorData, setEditorData] = useState<OutputData | undefined | any>();
  const editorContainer = useRef<HTMLDivElement | null>(null);
  const editorInstance = useRef<EditorJS | any>(null);
  const { t } = useTranslation();

  useEffect(() => {
    if (resetEditor && editorInstance.current) {
      const reset = async () => {
        await editorInstance.current.blocks.clear();
        setEditorData(undefined);
      };
      reset();
    }
  }, [resetEditor]);

  if (
    !isReadOnly &&
    initialContent &&
    initialContentData.blocks &&
    initialContentData.blocks.length === 0
  ) {
    useEffect(() => {
      const initEditor = async () => {
        if (editorInstance.current && typeof editorInstance.current.destroy === 'function') {
          await editorInstance.current.destroy();
          editorInstance.current = null;
        }

        if (editorContainer.current) {
          const editor = new EditorJS({
            holder: editorContainer.current,
            data: editorData,
            onChange: async () => {
              if (editorInstance.current) {
                const content = await editorInstance.current.save();
                setEditorData(content);
                if (handleOnChange) {
                  handleOnChange(content);
                }
              }
            },
            tools: EditorTools,
            readOnly: isReadOnly,
            minHeight: isReadOnly ? 0 : 100,
          });
          editorInstance.current = editor;
        }
      };
      initEditor();
    }, []);
  } else {
    useEffect(() => {
      const initEditor = async () => {
        if (editorInstance.current?.isReady) {
          editorInstance.current.isReady.then(() => {
            editorInstance.current.blocks.render(parseInitialContent(initialContent));
          });
        } else {
          // If not, create a new instance
          const options: EditorConfig = {
            holder: editorContainer.current as HTMLElement,
            data: parseInitialContent(initialContent),
            tools: EditorTools,
            readOnly: isReadOnly,
            minHeight: isReadOnly ? 0 : 110,
          };

          if (!isReadOnly) {
            options.onChange = async () => {
              // Check if editor instance is ready before calling save
              if (editorInstance.current?.isReady) {
                const content = await editorInstance.current.save();
                setEditorData(content);
                if (handleOnChange) {
                  handleOnChange(content);
                }
              }
            };
          }
          editorInstance.current = new EditorJS(options as any);
          isEditorReady = true;
        }
      };

      initEditor();

      return () => {
        if (editorInstance.current && typeof editorInstance.current.destroy === 'function') {
          editorInstance.current.destroy();
        }
        isEditorReady = false;
      };
    }, [initialContent, isReadOnly]);
  }

  return (
    <>
      {!isReadOnly && (
        <BodyText variant='xs' className='px-6 py-1'>
          <span className='text-neutral-400'>{t('rich_text_editor_tip')}</span>
        </BodyText>
      )}
      <div className={`about-content`} ref={editorContainer}>
        <style>{editorStyle}</style>
      </div>
    </>
  );
};

export default RichTextEditor;
