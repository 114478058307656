import AddIcon from 'assets/icons/add.svg';
import DeleteIcon from 'assets/icons/delete.svg';
import CopyIcon from 'assets/icons/copy.svg';
import EditIcon from 'assets/icons/edit.svg';
import LoadingIcon from 'assets/icons/loading.svg';
import LocationIcon from 'assets/icons/location.svg';
import OnlineIcon from 'assets/icons/video-camera.svg';
import Button from 'components/button/Button';
import Event from 'components/event/Event';
import Loading from 'components/Loading';
import ConfirmationModal from 'components/modal/ConfirmationModal';
import { Link } from 'react-router-dom';
import NavigateTo404 from 'components/NavigateTo404';
import ViewToggle from 'components/common/ItemListViewToggle';
import { eventImage } from 'constants/common';
import { useCommunity } from 'hooks/api/community/useCommunity';
import { useCommunityEvents } from 'hooks/api/event/useCommunityEvents';
import { useInvalidateQueries } from 'hooks/common/useInvalidatequeries';
import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useInView } from 'react-intersection-observer';
import { useNavigate, useParams } from 'react-router-dom';
import { deleteCommunityEvent } from 'utils/api/event';
import { notifySuccess, notifyTranslatedError } from 'utils/notify';
import { generateCommunityEventLink, generateEventLink } from 'utils/url';
import MemberButton from 'components/memberAndProfile/MemberButton';
import dayjs from 'dayjs';
import { debounce } from 'lodash';
import SearchInput from 'components/form/SearchInput';
import BodyText from 'components/typography/BodyText';

const CommunityEvents = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { communityId } = useParams();

  // list view toggle
  const [listView, setListView] = useState(false); // Initialize state
  const handleToggleView = (isListView: boolean) => {
    setListView(isListView); // Update state based on the value from ViewToggle
  };

  const [selectedEventId, setSelectedEventId] = useState<string | undefined>();
  const [openDeleteConfirmation, setOpenDeleteConfirmation] = useState(false);
  const { invalidateCommunityEvents } = useInvalidateQueries();
  const { ref: observerRef, inView } = useInView({ threshold: 0.25 });
  const { data: community, isLoading: isCommunityLoading } = useCommunity(communityId || '');
  const [pageSize, setPageSize] = useState(200);
  const { events, fetchNextEvents, hasNextEvents, isEventsLoading, isFetchingNextEvents } =
    useCommunityEvents(communityId || '', pageSize, false);

  if (isCommunityLoading) return <Loading />;
  if (!community || !events) return <NavigateTo404 />;

  useEffect(() => {
    if (inView) {
      if (!isEventsLoading && !isFetchingNextEvents && hasNextEvents) {
        fetchNextEvents();
      }
    }
  }, [inView]);

  const { id: communityUid, canManage } = community;

  // Search related code:
  const [searchString, setSearchString] = useState('');
  const filteredEvents = useMemo(() => {
    return (
      events?.filter((event) => {
        const searchLower = searchString.toLowerCase();
        // Safely check if the title exists and convert it to lowercase
        const titleMatch = event.title ? event.title.toLowerCase().includes(searchLower) : false;
        return titleMatch;
      }) || []
    );
  }, [events, searchString]);

  const handleSearch = (value: string) => {
    setSearchString(value.toLowerCase());
  };
  const debounceSearch = useCallback(debounce(handleSearch, 300), []);
  // search related code ends here

  // edit event
  const handleEditEvent = (eventId: string) => {
    navigate(`${generateCommunityEventLink(communityUid, eventId)}/edit`);
  };

  const handleDuplicateEvent = (eventId: string) => {
    navigate(`${generateCommunityEventLink(communityUid, eventId)}/duplicate`);
  };

  // delete event
  const handleOpenDeleteEventConfirmation = (eventId: string) => {
    setSelectedEventId(eventId);
    setOpenDeleteConfirmation(true);
  };

  const handleCloseDeleteEventConfiramtion = () => {
    setSelectedEventId(undefined);
    setOpenDeleteConfirmation(false);
  };

  const handleDeleteEvent = () => {
    if (!selectedEventId) return;

    deleteCommunityEvent(communityUid, selectedEventId)
      .then(() => {
        invalidateCommunityEvents(communityUid);
        notifySuccess(t('success.api.event_deleted'));
      })
      .catch((error) => {
        notifyTranslatedError(t, error.response.data?.errorCode);
      });

    handleCloseDeleteEventConfiramtion();
  };

  return (
    <div>
      <div className='mb-4 flex items-center justify-between px-1 gap-2'>
        <SearchInput onSearch={debounceSearch} />
        <BodyText
          variant='base'
          className='text-neutral-500 hidden md:flex flex items-center gap-1'
        >
          <span className='text-secondary-500 font-bold'>{events.length}</span> {t('events')}
        </BodyText>
        <div className='flex items-center gap-3 justify-end w-full'>
          <ViewToggle onToggleView={handleToggleView} />
          {(canManage || community.isGroup) && (
            <Button
              size='small'
              variant='secondary-outlined'
              tooltip={t('add_event')}
              onClick={() => navigate(`/communities/${communityUid}/events/create`)}
            >
              <AddIcon className='h-5 w-5' />
            </Button>
          )}
        </div>
      </div>
      {!listView ? (
        <div className='mb-11 grid grid-cols-1 gap-5 md:grid-cols-2 xl:grid-cols-3 xxl:grid-cols-4'>
          {filteredEvents.map((event) => {
            const {
              id: eventId,
              cover,
              location,
              startTime,
              title,
              type,
              creator,
              isAllDay,
            } = event;
            return (
              <Event
                key={eventId}
                eventType={type}
                eventId={eventId}
                eventTitle={title}
                canManage={canManage}
                createdBy={creator.id}
                eventLocation={location}
                isAllDay={isAllDay}
                eventStartTime={startTime}
                eventCover={cover || eventImage}
                handleEditEvent={handleEditEvent}
                handleDeleteEvent={handleOpenDeleteEventConfirmation}
                handleDuplicateEvent={handleDuplicateEvent}
                redirectTo={generateEventLink('community', communityUid, eventId)}
              />
            );
          })}
        </div>
      ) : (
        <div className='table-container'>
          <table>
            <thead>
              <tr>
                <th>{t('title')}</th>
                <th>{t('date')}</th>
                <th>{t('location')}</th>
                <th>{t('created-by')}</th>
                <th>{t('actions')}</th>
              </tr>
            </thead>
            <tbody>
              {filteredEvents.map((event) => {
                const {
                  id: eventId,
                  location,
                  startTime,
                  title,
                  type,
                  creator,
                  isAllDay,
                  zoomLink,
                } = event;
                return (
                  <tr key={eventId} className='group'>
                    <td>
                      <Link to={generateEventLink('community', communityUid, eventId)}>
                        <button className='flex w-full max-w-[20rem] items-center text-neutral-500 hover:underline'>
                          <span className='ml-2 line-clamp-1 text-left'> {title}</span>
                        </button>
                      </Link>
                    </td>
                    <td>
                      <span
                        className={
                          new Date().toDateString() === new Date(startTime).toDateString()
                            ? 'text-functional-success-dark'
                            : ''
                        }
                      >
                        {isAllDay
                          ? dayjs(startTime).format('dddd, D MMM YYYY')
                          : dayjs(startTime).format('dddd, D MMM YYYY, h:mm A')}
                      </span>
                    </td>
                    <td className='max-w-[8rem] '>
                      {type === 'online' ? (
                        <a href={zoomLink}>
                          <div className='flex items-center gap-2 '>
                            <OnlineIcon className='h-5 w-5 flex-shrink-0' />
                            <span className='line-clamp-1'>{zoomLink}</span>
                          </div>
                        </a>
                      ) : (
                        <div className='flex items-center gap-2 line-clamp-1'>
                          <LocationIcon className='h-5 w-5 flex-shrink-0' />
                          <span className='line-clamp-1'>{location}</span>
                        </div>
                      )}
                    </td>
                    <td className='text-left'>
                      <MemberButton
                        userId={creator.id}
                        isCreator={false}
                        profilePicture={creator.profilePicture || ''}
                        userName={creator.firstName + ' ' + creator.lastName}
                        chat={false}
                        size='small'
                      />
                    </td>
                    <td className='text-left'>
                      {canManage && (
                        <div className='flex items-center gap-3 lg:opacity-0 lg:group-hover:opacity-100'>
                          <button
                            className='flex h-6 w-6 items-center justify-center rounded-full hover:bg-neutral-300 active:bg-neutral-400'
                            onClick={() => handleEditEvent(eventId)}
                          >
                            <EditIcon className='h-5 w-5 text-neutral-400 hover:text-functional-info-dark active:text-functional-info-dark' />
                          </button>
                          <button
                            className='flex h-6 w-6 items-center justify-center rounded-full hover:bg-neutral-300 active:bg-neutral-400'
                            onClick={() => handleDuplicateEvent(eventId)}
                          >
                            <CopyIcon className='h-5 w-5 text-neutral-400 hover:text-functional-info-dark active:text-functional-info-dark' />
                          </button>
                          <button
                            className='flex h-6 w-6 items-center justify-center rounded-full hover:bg-neutral-300 active:bg-neutral-400'
                            onClick={() => handleOpenDeleteEventConfirmation(eventId)}
                          >
                            <DeleteIcon className='h-5 w-5 text-neutral-400 hover:text-functional-danger-dark active:text-functional-danger-dark' />
                          </button>
                        </div>
                      )}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      )}

      {hasNextEvents && (
        <div className='mb-11 flex justify-center'>
          <Button variant='primary' onClick={() => fetchNextEvents()}>
            {isFetchingNextEvents ? <LoadingIcon className='h-5 w-5' /> : t('show_more')}
          </Button>
        </div>
      )}
      {openDeleteConfirmation && (
        <ConfirmationModal
          label={t('delete_event')}
          onConfirm={handleDeleteEvent}
          isOpen={openDeleteConfirmation}
          onClose={handleCloseDeleteEventConfiramtion}
          title={t('delete_event_confirmation_title')}
          description={
            t('delete_event_confirmation_description') ||
            'This event will be permanently removed and unrecoverable.'
          }
        />
      )}
    </div>
  );
};

export default CommunityEvents;
