import ArchiveIcon from 'assets/icons/archive.svg';
import DuplicateIcon from 'assets/icons/copy.svg';
import PrivateIcon from 'assets/icons/eye-off.svg';
import PublicIcon from 'assets/icons/eye.svg';
import EditIcon from 'assets/icons/edit.svg';
import BodyText from 'components/typography/BodyText';
import OpenIcon from 'assets/icons/open.svg';
import { commonCover } from 'constants/common';
import { ContextMenu } from 'components/common/ContextMenu';
import ConfirmationModal from 'components/modal/ConfirmationModal';
import DuplicateProgramModal from 'components/modal/DuplicateProgramModal';
import AddProgramModalWithTrigger from 'components/modal/AddProgramModalWithTrigger';
import EditProgramModal from 'components/modal/EditProgramModal';
import { useMyPrograms } from 'hooks/api/program/useMyPrograms';
import { useInvalidateQueries } from 'hooks/common/useInvalidatequeries';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { archiveProgram } from 'utils/api/program';
import { notifySuccess, notifyTranslatedError } from 'utils/notify';
import { generateOriginLink } from 'utils/url';
import dayjs from 'dayjs';

const OrgPrograms = () => {
  const { t } = useTranslation();

  const [programId, setProgramId] = useState('');
  const [selectedId, setSelectedId] = useState('');
  const [openEditModal, setOpenEditModal] = useState(false);
  const [openDuplicateModal, setOpenDuplicateModal] = useState(false);
  const [openArchivedConfirmation, setOpenArchivedConfirmation] = useState(false);

  const { programs } = useMyPrograms();
  const { invalidateSingleProgram, invalidateMyPrograms, invalidateArchivedPrograms } =
    useInvalidateQueries();

  // program archive methods
  const handleOpenArchivedConfirmation = (programId: string) => {
    setSelectedId(programId);
    setOpenArchivedConfirmation(true);
  };

  const handleCloseArchivedConfirmation = () => {
    setSelectedId('');
    setOpenArchivedConfirmation(false);
  };

  const handleArchivedProgram = () => {
    archiveProgram(selectedId)
      .then(() => {
        invalidateSingleProgram(selectedId);
        invalidateMyPrograms();
        invalidateArchivedPrograms();
        handleCloseArchivedConfirmation();
        notifySuccess(t('success.api.space_archived'));
      })
      .catch((error) => {
        notifyTranslatedError(t, error.response.data?.errorCode);
      });
  };

  return (
    <>
      <div className='flex items-center justify-between mb-4'>
        <BodyText variant='lg' className='font-semibold text-secondary-500'>
          {t('programs')}
        </BodyText>
        <AddProgramModalWithTrigger />
      </div>
      <div className='mb-10 flex space-y-2 '>
        <div className=' overflow-x-auto rounded-2xl bg-neutral-200 px-4 py-5 pb-[120px] shadow'>
          <table className='min-w-[600px] flex-grow table-auto '>
            <thead className='bg-neutral-100'>
              <tr className='border-b-2 border-neutral-200'>
                <th className='px-4 py-2 text-left'>
                  <BodyText variant='sm' className='font-medium'>
                    {t('about')}
                  </BodyText>
                </th>
                <th className='px-4 py-2  text-left'>
                  <BodyText variant='sm' className='font-medium'>
                    {t('visibility')}
                  </BodyText>
                </th>
                <th className='px-4 py-2  text-left'>
                  <BodyText variant='sm' className=' font-medium'>
                    {t('members')}
                  </BodyText>
                </th>
                <th className='px-4 py-2  text-left'>
                  <BodyText variant='sm' className=' font-medium'>
                    {t('created_at')}
                  </BodyText>
                </th>
                <th className='px-4 py-2  text-left'>
                  <BodyText variant='sm' className=' font-medium'>
                    {t('actions')}
                  </BodyText>
                </th>
              </tr>
            </thead>

            <tbody>
              {programs.map(
                ({
                  id,
                  name,
                  visibility,
                  cover,
                  role,
                  memberCount,
                  organization,
                  createdAt,
                  logo,
                }) => (
                  <tr key={id} className='border-b-2 border-neutral-200'>
                    <td className='p-2'>
                      <a href={generateOriginLink(id, 'program')}>
                        <button className='neutral-button flex w-full max-w-[40rem] items-center rounded-xl p-2 text-neutral-500'>
                          <img
                            src={cover || logo || organization.logo || commonCover}
                            alt={`Cover for ${name}`}
                            className='h-8 w-8 rounded-full object-cover'
                          />{' '}
                          <span className='ml-2 line-clamp-3 text-left'>{name}</span>
                        </button>
                      </a>
                    </td>
                    <td className='p-2'>
                      {' '}
                      {visibility && (
                        <div className='flex items-center gap-2 rounded bg-neutral-200 px-2 py-1'>
                          {visibility === 'open' ? (
                            <OpenIcon className='h-4 w-4 text-neutral-500' />
                          ) : visibility === 'private' ? (
                            <PrivateIcon className='h-4 w-4 text-neutral-500' />
                          ) : (
                            <PublicIcon className='h-4 w-4 text-neutral-500' />
                          )}
                          <BodyText
                            variant='sm'
                            className='font-medium capitalize text-neutral-500'
                          >
                            {t(visibility)}
                          </BodyText>
                        </div>
                      )}
                    </td>
                    <td className='p-2 px-4 text-center'>{memberCount}</td>
                    <td className='p-2 px-4 text-center'>
                      {' '}
                      <span className='text-sm text-neutral-500'>
                        {dayjs(createdAt).format(' D MMM YYYY')}
                      </span>
                    </td>
                    <td className=' p-2 px-4 text-center'>
                      <ContextMenu
                        menuItems={[
                          {
                            key: 'edit',
                            label: t('edit'),
                            icon: EditIcon,
                            callback: () => {
                              setProgramId(id);
                              setOpenEditModal(true);
                            },
                          },
                          {
                            key: 'duplicate',
                            label: t('duplicate'),
                            icon: DuplicateIcon,
                            callback: () => {
                              setProgramId(id);
                              setOpenDuplicateModal(true);
                            },
                          },
                          {
                            key: 'archive',
                            label: t('archive'),
                            icon: ArchiveIcon,
                            callback: () => handleOpenArchivedConfirmation(id),
                          },
                        ]}
                      />
                    </td>
                  </tr>
                ),
              )}
            </tbody>
          </table>
        </div>
        <EditProgramModal programId={programId} open={openEditModal} setOpen={setOpenEditModal} />
        <DuplicateProgramModal
          programId={programId}
          open={openDuplicateModal}
          setOpen={setOpenDuplicateModal}
        />
        {openArchivedConfirmation && (
          <ConfirmationModal
            isOpen={openArchivedConfirmation}
            label={t('archive_space')}
            title={t('confirmation-general')}
            description={
              t('archive_space_confirmation_description') ||
              'This program will invisible for all members.'
            }
            onClose={handleCloseArchivedConfirmation}
            onConfirm={handleArchivedProgram}
          />
        )}
      </div>
    </>
  );
};

export default OrgPrograms;
