import { useConversationsList } from 'hooks/api/message/useConversationsList';
import { useMemo } from 'react';

export const useFilteredConversationList = (
  enabled = false,
  showGroup?: boolean,
  search?: string,
) => {
  const { conversations } = useConversationsList(enabled);
  const filteredConversations = useMemo(() => {
    if (!search && !showGroup) return conversations;

    let response = [...conversations];

    if (search)
      response = response.filter(
        (conversation) =>
          !!conversation.participants.find(({ firstName, lastName }) =>
            `${firstName.toLowerCase()} ${lastName.toLowerCase()}`.includes(search.toLowerCase()),
          ),
      );

    if (showGroup) {
      response = response.filter((conversation) => conversation.conversationType === 'group');
    }

    return response;
  }, [search, conversations, showGroup]);
  return { conversations, filteredConversations };
};
