import { useQuery } from '@tanstack/react-query';
import { getSingleProgram, getSinglePublicProgram } from 'utils/api/program';

export const useSingleProgram = (programId: string) => {
  return useQuery({
    queryKey: ['single-program', programId],
    queryFn: () => getSingleProgram(programId),
    select: (data) => data.data.program,
    enabled: !!programId,
  });
};

export const useSinglePublicProgram = (programId: string, inviteToken?: string) => {
  return useQuery({
    queryKey: ['single-program', programId, inviteToken],
    queryFn: () => getSinglePublicProgram(programId, inviteToken),
    select: (data) => data.data.program,
    enabled: !!programId,
  });
};
