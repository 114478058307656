import Avatar from 'components/memberAndProfile/Avatar';
import SelectMonth from 'components/form/SelectMonth';
import SelectYear from 'components/form/SelectYear';
import Layout from 'components/layout/Layout';
import Tag from 'components/tag/Tag';
import BodyText from 'components/typography/BodyText';
import Heading from 'components/typography/Heading';
import { userAvatar } from 'constants/common';
import dayjs from 'dayjs';
import { useCalendar } from 'hooks/api/calendar/useCalendar';
import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import {
  generateCommunityEventLink,
  generateCommunityTaskLink,
  generateProgramEventLink,
  generateProgramTaskLink,
  generateUserProfileLink,
} from 'utils/url';

const CalenderPage = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [selectedMonth, setSelectedMonth] = useState(new Date().getMonth());
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
  const { events, tasks } = useCalendar(selectedMonth + 1, selectedYear);
  const day = dayjs(new Date(`${selectedYear}-${selectedMonth + 1}-01`));
  const firstDay = day.startOf('month').day();
  const totalDays = day.daysInMonth();

  const eventAndTaskWithDates = useMemo(() => {
    const arr: (
      | ((typeof events)[0] & { _type: 'event' })
      | ((typeof tasks)[0] & { _type: 'task' })
    )[][] = new Array(totalDays + 1);

    events.forEach((event) => {
      const parsedDay = dayjs(event.startTime).date();
      const currentData = arr[parsedDay];
      if (!currentData) arr[parsedDay] = [];
      arr[parsedDay].push({ ...event, _type: 'event' });
    });

    tasks.forEach((task) => {
      const parsedDay = dayjs(task.deadline).date();
      const currentData = arr[parsedDay];
      if (!currentData) arr[parsedDay] = [];
      arr[parsedDay].push({ ...task, _type: 'task' });
    });

    return arr;
  }, [events, tasks, totalDays]);

  return (
    <Layout>
      <div className='md:py-4 md:px-5 py-1 px-2 grid h-full grid-cols-5 overflow-y-auto'>
        <div className='col-span-5'>
          <div className='mb-4 flex items-center gap-4'>
            <Heading variant='h3' className='mr-auto font-semibold text-secondary-600'>
              {t('calendar')}
            </Heading>
            <SelectMonth onSelect={(value) => setSelectedMonth(value)} />
            <SelectYear
              from={new Date().toJSON()}
              onChange={(e: React.ChangeEvent<HTMLSelectElement>) =>
                setSelectedYear(Number(e.target.value))
              }
            />
          </div>

          <div className='overflow-x-auto rounded-2xl bg-neutral-100 p-4'>
            <div className='min-w-[1000px]'>
              <div className='grid grid-cols-7'>
                <div className='p-2 pt-0 text-left'>{t('day.sunday')}</div>
                <div className='p-2 pt-0 text-left'>{t('day.monday')}</div>
                <div className='p-2 pt-0 text-left'>{t('day.tuesday')}</div>
                <div className='p-2 pt-0 text-left'>{t('day.wednesday')}</div>
                <div className='p-2 pt-0 text-left'>{t('day.thursday')}</div>
                <div className='p-2 pt-0 text-left'>{t('day.friday')}</div>
                <div className='p-2 pt-0 text-left'>{t('day.saturday')}</div>
              </div>
              <div className='grid grid-cols-7'>
                {new Array(37).fill(1).map((_, index) => {
                  const shouldDisplayContent =
                    index >= firstDay && totalDays - index + firstDay > 0;
                  const currentDay = index - firstDay + 1;
                  return (
                    <div
                      key={index}
                      className={`sv-scrollbar aspect-square overflow-y-auto border border-t-2 border-neutral-300 p-2 text-neutral-500 ${
                        shouldDisplayContent && currentDay === new Date().getDate()
                          ? 'border-t-functional-info-dark'
                          : ''
                      }`}
                    >
                      <BodyText variant='lg' className='mb-2'>
                        {shouldDisplayContent && currentDay}
                      </BodyText>
                      {shouldDisplayContent && (
                        <div className='space-y-3'>
                          {eventAndTaskWithDates[currentDay]?.map(
                            ({ id, title, _type, creator, community, program }) => (
                              <div key={id} className='space-y-2'>
                                <Tag
                                  type='rounded'
                                  className={`line-clamp-1 w-fit capitalize
                                  ${
                                    _type === 'event'
                                      ? 'bg-functional-info-light text-functional-info-dark'
                                      : 'bg-functional-success-light text-functional-success-dark'
                                  }`}
                                >
                                  {program?.name || community?.name || ''}
                                </Tag>
                                <button
                                  className='block'
                                  onClick={() => {
                                    let link;
                                    if (_type === 'event') {
                                      link =
                                        program && !community
                                          ? generateProgramEventLink(program.id, id)
                                          : generateCommunityEventLink(community?.id || '', id);
                                    } else {
                                      link =
                                        program && !community
                                          ? generateProgramTaskLink(program.id, id)
                                          : generateCommunityTaskLink(community?.id || '', id);
                                    }
                                    navigate(link);
                                  }}
                                >
                                  <BodyText
                                    variant='sm'
                                    className='line-clamp-1 font-medium hover:underline'
                                  >
                                    {title}
                                  </BodyText>
                                </button>
                                <button
                                  className='flex items-center gap-2 text-left'
                                  onClick={() => navigate(generateUserProfileLink(creator.id))}
                                >
                                  <Avatar
                                    size={16}
                                    alt={`${creator.firstName} ${creator.lastName}`}
                                    src={creator.profilePicture || userAvatar}
                                  />
                                  <BodyText
                                    variant='sm'
                                    className='line-clamp-1 font-semibold text-neutral-500'
                                  >
                                    {`${creator.firstName} ${creator.lastName}`}
                                  </BodyText>
                                </button>
                              </div>
                            ),
                          )}
                        </div>
                      )}
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default CalenderPage;
