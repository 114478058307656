import Logo from 'assets/images/logo.svg';
import BodyText from 'components/typography/BodyText';
import useIsLoggedIn from 'hooks/api/auth/useIsLoggedIn';
import React from 'react';
import { NavLink } from 'react-router-dom';
import { UserType } from 'types/apis/common';
import { useTranslation } from 'react-i18next';
import { getSideNavLinks } from 'config/sidenavLinks';
import { getSideNavLogo } from 'config/sidenavLogos';
import { useSettings } from 'hooks/api/settings/useSettings';

interface iLayout {
  orgName?: string;
  orgId?: string;
}

function Sidenav({ orgId, orgName }: iLayout) {
  const { t } = useTranslation();
  const { data } = useIsLoggedIn();
  const { data: settingsInfo, isLoading } = useSettings();
  const sidenavLinks = getSideNavLinks(t);

  const links =
    data?.userType === UserType.organization_admin
      ? sidenavLinks
      : sidenavLinks.filter(({ label }) => label !== t('admin-panel'));

  return (
    <nav className='sticky top-0 hidden h-screen flex-col bg-neutral-100 md:flex border-r border-neutral-300 w-[12rem]'>
      <section className='flex max-h-[5rem] items-center justify-center px-2 pt-3'>
        {!orgName ? (
          <div className='flex items-center gap-2 md:flex'>
            <Logo className='h-[38px] w-[38px]' />
            <a href='https://www.studycentral.eu/'>
              <div className=''>
                <BodyText variant='base' className='text-neutral-600'>
                  STUDY<span className='font-bold'>CENTRAL</span>
                </BodyText>
              </div>
            </a>
          </div>
        ) : (
          <div className='flex items-center justify-center w-full'>
            {getSideNavLogo(orgId || '', settingsInfo?.settings?.darkTheme || false) ? (
              <img
                src={getSideNavLogo(orgId || '', settingsInfo?.settings?.darkTheme || false) || ''}
                alt='logo'
                className='max-h-[60px] max-w-full object-center'
              />
            ) : (
              <BodyText
                variant='base'
                className='line-clamp-3 hidden text-neutral-500 md:block font-bold'
              >
                {orgName}
              </BodyText>
            )}
          </div>
        )}
      </section>

      <section className='flex grow flex-col mt-4 px-2 overflow-y-auto'>
        {links.map(({ id, to, label, Icon, subLinks }) => {
          let isActiveLink;
          if (label === t('spaces')) {
            isActiveLink =
              location.pathname.startsWith('/communities/') ||
              location.pathname.startsWith('/programs');
          } else {
            isActiveLink =
              (location.pathname === to || location.pathname.includes(to)) &&
              !location.pathname.startsWith('/communities/') &&
              !location.pathname.startsWith('/programs');
          }

          return (
            <React.Fragment key={id}>
              <div
                className={`w-full rounded-xl py-1 ${isActiveLink ? 'bg-secondary-200 text-secondary-500' : 'bg-neutral-100 text-neutral-500'}`}
              >
                <NavLink to={to}>
                  <div className={`pl-1 pr-2 py-1 rounded-xl my-2`}>
                    <button
                      className={`flex w-full items-center px-1 transition-transform duration-150 hover:scale-110`}
                    >
                      <Icon className={`mr-2 h-5 w-5 `} />
                      <BodyText variant='base' className='hidden lg:flex line-clamp-1'>
                        {label}
                      </BodyText>
                    </button>
                  </div>
                </NavLink>
                {subLinks &&
                  isActiveLink &&
                  subLinks.map((subLink) => (
                    <NavLink key={subLink.id} to={subLink.to}>
                      <div
                        className={`ml-7 hover:text-secondary-600 hover:scale-105 transition-transform duration-150`}
                      >
                        {subLink.label && (
                          <BodyText
                            variant={subLink.id.includes('group') ? 'xs' : 'sm'}
                            className='pb-2  whitespace-nowrap overflow-hidden line-clamp-1'
                          >
                            {subLink.label}
                          </BodyText>
                        )}
                      </div>
                    </NavLink>
                  ))}
              </div>
            </React.Fragment>
          );
        })}
        <div className='w-full grow bg-neutral-100' />
        {orgName && (
          <div className='mb-4 px-1 flex items-center'>
            <div className=' flex items-center bg-neutral-100 p-2'>
              <Logo className='h-[30px] w-[30px]' />
            </div>
            <a href='https://www.studycentral.eu/'>
              <div className='hidden lg:block'>
                <BodyText variant='xs' className='text-neutral-600'>
                  Powered by
                </BodyText>
                <BodyText variant='sm' className='text-neutral-600'>
                  STUDY<span className='font-bold'>CENTRAL</span>
                </BodyText>
              </div>
            </a>
          </div>
        )}
      </section>
    </nav>
  );
}

export default Sidenav;
