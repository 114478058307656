import React from 'react';

type ITextInput = React.HTMLProps<HTMLInputElement>;

function TextInput({ className = '', ...props }: ITextInput) {
  return (
    <input
      className={`w-full bg-neutral-100 text-base text-neutral-600 border border-neutral-300 rounded-lg py-[11px] px-[14px] outline-none ${className}`}
      {...props}
    />
  );
}

export default TextInput;
