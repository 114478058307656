import LoadingIcon from 'assets/icons/loading.svg';
import CommunityPost from 'components/post/CommunityPost';
import Heading from 'components/typography/Heading';
import { userAvatar } from 'constants/common';
import useCommunityPosts from 'hooks/api/post/useCommunityPosts';
import { useCommunitySavedPosts } from 'hooks/api/post/useCommunitySavedPosts';
import React, { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useInView } from 'react-intersection-observer';
import { MemberRoleType, SingleCommunityType } from 'types/apis/common';
import { handleLikePost } from 'utils/post';

interface ICommunityPosts {
  community: SingleCommunityType & {
    canManage: boolean;
    communityRole: MemberRoleType;
  };
}

function CommunityPosts({ community }: ICommunityPosts) {
  const { t } = useTranslation();

  const { id: communityId } = community;
  const {
    data: postsData,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    isFeedLoading,
  } = useCommunityPosts(communityId);
  const { data: savedPosts = [] } = useCommunitySavedPosts(communityId);

  const posts = useMemo(() => {
    return postsData.map((post) => ({
      ...post,
      savedPostId: savedPosts.find((savedPost) => savedPost.post.id === post.id)?.id,
    }));
  }, [postsData, savedPosts]);

  const { ref: observerRef, inView } = useInView({
    threshold: 0.25,
  });

  useEffect(() => {
    if (inView) {
      if (!isFeedLoading && !isFetchingNextPage && hasNextPage) {
        fetchNextPage();
      }
    }
  }, [inView]);

  return (
    <section className='space-y-6'>
      <Heading variant='h2' className='font-semibold text-neutral-600'>
        {t('recent_posts')}
      </Heading>

      {posts.map(
        ({
          id,
          authorType,
          user,
          commentCount,
          content,
          createdAt,
          embedType,
          embedId,
          embedMeta,
          file,
          isAuthor,
          isPinned,
          likeCount,
          liked,
          viewCount,
          savedPostId,
        }) => (
          <CommunityPost
            key={id}
            postId={id}
            file={file}
            communityId={communityId}
            isAuthor={isAuthor}
            authorId={user.id}
            authorLogo={user.profilePicture || userAvatar}
            authorName={`${user.firstName} ${user.lastName}`}
            authorHeadline={user.headline || undefined}
            authorRole={authorType}
            createdAt={createdAt}
            isPostLiked={liked}
            postCommentCount={commentCount}
            postContent={content}
            postLikeCount={likeCount}
            postViewCount={viewCount}
            embedType={embedType}
            embedId={embedId}
            embedMeta={embedMeta}
            canManage={community.canManage}
            isPostPinned={isPinned}
            handleLikePost={handleLikePost}
            savedPostId={savedPostId}
          />
        ),
      )}

      <div ref={observerRef}>
        <LoadingIcon
          className={`h-6 w-6 ${isFetchingNextPage || isFeedLoading ? 'block' : 'hidden'}`}
        />
      </div>
    </section>
  );
}

export default CommunityPosts;
