import { useInfiniteQuery } from '@tanstack/react-query';
import { getCommunityEventComments } from 'utils/api/event';

export const useCommunityEventComments = (communityId: string, eventId: string) => {
  const {
    data: comments,
    hasNextPage,
    fetchNextPage,
    refetch,
  } = useInfiniteQuery({
    queryKey: ['community-event-comments', communityId, eventId],
    queryFn: ({ pageParam = '' }) => getCommunityEventComments(communityId, eventId, 2, pageParam),
    getNextPageParam: (data) => data.data.cursor,
    enabled: !!communityId && !!eventId,
  });

  return {
    comments: comments?.pages.flatMap((item) => item.data.comments) ?? [],
    hasNextComments: hasNextPage,
    fetchNextComments: fetchNextPage,
    refetchComments: refetch,
  };
};
