import Avatar from 'components/memberAndProfile/Avatar';
import { userAvatar } from 'constants/common';
import BodyText from 'components/typography/BodyText';
import MessageIcon from 'assets/icons/message.svg';
import Button from 'components/button/Button';
import { useTranslation } from 'react-i18next';
import { generateUserProfileLink } from 'utils/url';
import React, { useState, useEffect } from 'react';
import ProfileDrawer from 'components/drawer/ProfileDrawer';
import { startConversation } from 'utils/api/message';
import { notifyTranslatedError } from 'utils/notify';
import { useNavigate } from 'react-router-dom';
import SingleConversationDrawer from 'components/drawer/SingleConversationDrawer';

interface MemberButtonProps {
  userId: string;
  userName: string;
  profilePicture: string;
  isCreator?: boolean;
  size?: 'small' | 'default' | 'large';
  chat: boolean;
}

const MemberButton: React.FC<MemberButtonProps> = ({
  userId,
  userName,
  profilePicture,
  isCreator,
  size = 'default',
  chat,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [isMediumOrLargerScreen, setIsMediumOrLargerScreen] = useState(window.innerWidth >= 768);
  const [isProfileDrawerOpen, setIsProfileDrawerOpen] = useState(false);
  const [isConversationOpen, setIsConversationOpen] = useState(false);
  const [conversationId, setConversationId] = useState('');

  useEffect(() => {
    function handleResize() {
      setIsMediumOrLargerScreen(window.innerWidth >= 768);
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const handleDivClick = (userId: string) => {
    if (isMediumOrLargerScreen) {
      setIsProfileDrawerOpen(true);
    } else {
      navigate(generateUserProfileLink(userId));
    }
  };

  const handleStartChat = () => {
    let conversationType = 'private';
    startConversation({
      participants: [userId],
      conversationType,
    })
      .then((data) => {
        setConversationId(data.data.conversationId);
        setIsConversationOpen(true);
      })
      .catch((error) => {
        notifyTranslatedError(t, error.response.data?.message);
      });
  };

  return (
    <>
      {size === 'default' && (
        <div className='flex w-full items-center justify-between space-y-1 p-1'>
          <button
            className='neutral-button flex items-center gap-2 rounded-2xl p-1 text-left'
            onClick={() => handleDivClick(userId)}
          >
            <Avatar size={40} src={profilePicture || userAvatar} />
            <div>
              <BodyText variant='sm' className=' text-neutral-400'>
                {isCreator ? t('created-by') : t('your-contact')}
              </BodyText>
              <BodyText variant='base' className='font-medium'>
                {userName || ''}
              </BodyText>
            </div>
          </button>
          {chat ? (
            <Button
              className='hover:bg-neutral-300 hover:text-functional-info-dark active:bg-neutral-400 active:text-functional-info-dark'
              onClick={(e) => {
                e.preventDefault();
                handleStartChat();
              }}
              size='small'
              variant='outlined'
            >
              <span className='hidden md:block'>{t('chat')}</span>
              <MessageIcon className='h-4 w-4 ' />
            </Button>
          ) : null}
        </div>
      )}
      {size === 'small' && (
        <button
          className='neutral-button flex items-center gap-1 rounded-2xl p-1 text-left'
          onClick={() => handleDivClick(userId)}
        >
          <Avatar size={24} src={profilePicture || userAvatar} />
          <div>
            <BodyText variant='sm'>{userName || ''}</BodyText>
          </div>
        </button>
      )}

      <ProfileDrawer
        memberId={userId}
        isOpen={isProfileDrawerOpen}
        setIsOpen={setIsProfileDrawerOpen}
      />
      <SingleConversationDrawer
        conversationId={conversationId}
        isOpen={isConversationOpen}
        setIsOpen={setIsConversationOpen}
      />
    </>
  );
};

export default MemberButton;
