import SavedPostIcon from 'assets/icons/book-mark.svg';
import CommunitySavedPosts from 'pages/segment/community/communityFeed/CommunitySavedPosts';
import ProgramSavedPosts from 'pages/segment/programs/programFeed/ProgramSavedPosts';
import React from 'react';
import Popover from './Popover';

interface ISavedPostsDropdown {
  originId: string;
  originType: 'program' | 'community';
}

const SavedPostsDropdown = ({ originId, originType }: ISavedPostsDropdown) => {
  return (
    <Popover
      icon={SavedPostIcon}
      className='xl:hidden'
      iconButtonClassName='outline-0'
      iconClassName='w-5 h-5 text-functional-info-dark'
      containerClassName='w-[300px] max-h-[300px] overflow-y-auto'
    >
      {originType === 'program' && <ProgramSavedPosts programId={originId} />}
      {originType === 'community' && <CommunitySavedPosts communityId={originId} />}
    </Popover>
  );
};

export default SavedPostsDropdown;
