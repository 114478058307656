import Card from 'components/card/Card';
import Heading from 'components/typography/Heading';
import { useRelatedCommunities } from 'hooks/api/community/useRelatedCommunities';
import React from 'react';
import { useTranslation } from 'react-i18next';

interface IRelatedCommunities {
  communityId: string;
}

const RelatedCommunities = ({ communityId }: IRelatedCommunities) => {
  const { data } = useRelatedCommunities(communityId);
  const { t } = useTranslation();

  if (!data) return null;

  return (
    <section>
      <Heading variant='h3' className='mb-2 mt-5 font-bold text-secondary-500 px-4'>
        {t('related_communities')}
      </Heading>

      <div className='mb-6 grid grid-cols-1 gap-5 md:grid-cols-2 xl:grid-cols-3 xxl:grid-cols-4'>
        {data.map(({ id, name, memberCount, visibility, cover, organization }) => (
          <Card
            key={id}
            title={name}
            members={memberCount}
            visibility={visibility}
            coverImage={cover}
            organizationLogo={organization.logo}
            organizationName={organization.name}
            to={`/communities/${id}`}
          />
        ))}
      </div>
    </section>
  );
};

export default RelatedCommunities;
