import { Switch } from '@headlessui/react';
import { useQueryClient } from '@tanstack/react-query';
import LogoutIcon from 'assets/icons/logout.svg';
import Button from 'components/button/Button';
import TextInput from 'components/form/TextInput';
import Layout from 'components/layout/Layout';
import Loading from 'components/Loading';
import NavigateTo404 from 'components/NavigateTo404';
import BodyText from 'components/typography/BodyText';
import Heading from 'components/typography/Heading';
import dayjs from 'dayjs';
import { useSettings } from 'hooks/api/settings/useSettings';
import React, { useState } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { ChangePasswordFormType } from 'types/components/form';
import { changePassword, logout } from 'utils/api/auth';
import { updateSetting } from 'utils/api/settings';
import { notifySuccess, notifyTranslatedError } from 'utils/notify';

const SettingsPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const { data: settingsInfo, refetch, isLoading } = useSettings();
  const [emailNotification, setEmailNotification] = useState(false);
  const [darkTheme, setDarkTheme] = useState(false);

  const {
    watch,
    reset,
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<ChangePasswordFormType>();

  const onSubmit: SubmitHandler<ChangePasswordFormType> = (data) => {
    const { oldPassword, newPassword } = data;

    changePassword({ oldPassword, newPassword })
      .then(() => {
        reset({ oldPassword: '', newPassword: '', confirmPassword: '' });
        // reset();
        notifySuccess(t('success.api.password_updated'));
      })
      .catch((error) => {
        notifyTranslatedError(t, error.response.data?.errorCode);
      });
  };

  const handleLogout = async () => {
    try {
      await logout();
      queryClient.removeQueries();
      navigate('/login');
    } catch (error: $TSFix) {
      notifyTranslatedError(t, error.response.data?.errorCode);
    }
  };

  const handleEmailNotificationChange = (value: boolean) => {
    setEmailNotification(value);
    updateSetting({ emailNotification: value, darkTheme: settings?.darkTheme || false })
      .then(() => {
        refetch();
      })
      .catch((error) => {
        notifyTranslatedError(t, error.response.data?.errorCode);
      });
  };

  if (isLoading) return <Loading />;
  if (!settingsInfo) return <NavigateTo404 />;

  const { email, createdAt, userType, primaryOrganization, settings } = settingsInfo;

  return (
    <Layout>
      <div className='py-1 px-1 md:py-2 md:px-3 lg:py-4 lg:px-5'>
        <div className='mb-4 flex items-center justify-between'>
          <Heading variant='h1' className='font-semibold'>
            {t('account_settings')}
          </Heading>

          <button
            onClick={handleLogout}
            className='flex items-center gap-3 text-functional-info-dark'
          >
            <LogoutIcon className='h-5 w-5' />
            <Heading variant='h3' className='hidden font-semibold md:block'>
              {t('logout')}
            </Heading>
          </button>
        </div>

        {/* account settings */}
        <section className='mb-4 space-y-6 bg-neutral-200 p-5 shadow shadow-neutral-300 rounded-xl'>
          <div className='space-y-4'>
            <div className='spacy-y-1'>
              <BodyText variant='sm' className='font-medium text-neutral-500'>
                {t('email')}
              </BodyText>
              <TextInput
                disabled
                type='email'
                value={email}
                placeholder={t('email') || 'example@gmail.com'}
              />
            </div>

            <div className='spacy-y-1'>
              <BodyText variant='sm' className='font-medium text-neutral-500'>
                {t('member_since')}
              </BodyText>
              <TextInput
                disabled
                type='text'
                value={dayjs(createdAt).format('YYYY-MM-DD')}
                placeholder={t('member_since') || '2023-01-05'}
              />
            </div>

            <div className='spacy-y-1'>
              <BodyText variant='sm' className='font-medium text-neutral-500'>
                {t('user-type')}
              </BodyText>
              <TextInput
                disabled
                type='text'
                value={t(`member.type.${userType}`) || 'Member'}
                placeholder={t('user-type') || 'Member'}
              />
            </div>

            <div className='spacy-y-1'>
              <BodyText variant='sm' className='font-medium text-neutral-500'>
                {t('primary-organization')}
              </BodyText>
              <TextInput
                disabled
                type='text'
                value={primaryOrganization?.name}
                placeholder={t('primary-organization') || 'Primary Organization'}
              />
            </div>
          </div>
        </section>

        {/* change password */}
        <section className='mb-6 space-y-6 bg-neutral-200 p-5 shadow shadow-neutral-300 rounded-xl'>
          <Heading variant='h2' className='font-semibold'>
            {t('change_password')}
          </Heading>

          <form className='space-y-4' onSubmit={handleSubmit(onSubmit)}>
            <div className='space-y-1'>
              <BodyText variant='sm' className='font-medium text-neutral-500'>
                {t('old-password')}
              </BodyText>
              <Controller
                name='oldPassword'
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <TextInput
                    {...field}
                    type='password'
                    autoComplete=''
                    placeholder={t('old-password') || 'Old Password'}
                    className={errors.newPassword ? 'border-functional-danger-400' : ''}
                  />
                )}
              />
            </div>

            <div className='space-y-1'>
              <BodyText variant='sm' className='font-medium text-neutral-500'>
                {t('new-password')}
              </BodyText>
              <Controller
                name='newPassword'
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <TextInput
                    {...field}
                    type='password'
                    autoComplete=''
                    placeholder={t('new-password') || 'New Password'}
                    className={errors.newPassword ? 'border-functional-danger-400' : ''}
                  />
                )}
              />
            </div>

            <div className='space-y-1'>
              <BodyText variant='sm' className='font-medium text-neutral-500'>
                {t('repeat-password')}
              </BodyText>
              <Controller
                control={control}
                name='confirmPassword'
                rules={{
                  required: true,
                  // eslint-disable-next-line consistent-return
                  validate: (val: string) => {
                    if (watch('newPassword') !== val) {
                      return t('ERROR.PASSWORD_MISMATCH') || 'Passwords do not match';
                    }
                  },
                }}
                render={({ field }) => (
                  <TextInput
                    {...field}
                    type='password'
                    autoComplete=''
                    placeholder={t('repeat-password') || 'Repeat Password'}
                    className={errors.confirmPassword ? 'border-functional-danger-400' : ''}
                  />
                )}
              />
              {errors.confirmPassword && (
                <BodyText variant='sm' className='text-sm text-functional-danger-400'>
                  {errors.confirmPassword.message}
                </BodyText>
              )}
            </div>

            <div className='flex items-center justify-end gap-4'>
              <Button type='button' variant='outlined'>
                {t('cancel')}
              </Button>
              <Button type='submit' variant='secondary'>
                {t('save')}
              </Button>
            </div>
          </form>
        </section>

        {/* notification */}
        <section className='mb-4 space-y-6 bg-neutral-200 p-5 shadow shadow-neutral-300 rounded-xl'>
          <Heading variant='h2' className='font-semibold'>
            {t('notification-setting-title')}
          </Heading>

          <div className='divide-y'>
            <div className='flex items-center justify-between py-3'>
              <BodyText variant='base' className='font-medium'>
                {t('email')}
              </BodyText>
              <Switch
                checked={settings?.emailNotification}
                onChange={(value: boolean) => {
                  handleEmailNotificationChange(value);
                }}
                className={`${
                  settings?.emailNotification
                    ? 'bg-functional-success-dark'
                    : 'bg-functional-danger-dark'
                } relative inline-flex h-5 w-[44px] items-center rounded-full`}
              >
                <span
                  className={`${
                    settings?.emailNotification
                      ? 'translate-x-[3px] bg-functional-success-light'
                      : 'translate-x-[21px] bg-functional-danger-light'
                  } inline-block h-[20px] w-[20px] transform rounded-full transition`}
                />
              </Switch>
            </div>
          </div>
        </section>
      </div>
    </Layout>
  );
};

export default SettingsPage;
