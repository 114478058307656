import { useInfiniteQuery } from '@tanstack/react-query';
import { getProgramPostComments } from 'utils/api/post';
import { inMilliseconds } from 'utils/helper';

export const useProgramPostComments = (programId: string, postId: string, enabled = true) => {
  const { data, hasNextPage, fetchNextPage, refetch } = useInfiniteQuery({
    queryKey: ['program-post-comments', programId, postId],
    queryFn: ({ pageParam = '' }) => getProgramPostComments(programId, postId, pageParam),
    getNextPageParam: (lastPage) => lastPage.data.cursor,
    staleTime: inMilliseconds(5),
    enabled,
  });
  return {
    comments: data?.pages.flatMap((item) => item.data.comments) ?? [],
    commentsCount: data?.pages.flatMap((item) => item.data.comments.length),
    hasNextComments: hasNextPage,
    fetchNextComments: fetchNextPage,
    refetchComments: refetch,
  };
};
