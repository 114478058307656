import { useQuery } from '@tanstack/react-query';
import { getCommunitySavedPosts } from 'utils/api/post';

export const useCommunitySavedPosts = (communityId: string) => {
  return useQuery({
    queryKey: ['saved-posts', communityId],
    queryFn: () => getCommunitySavedPosts(communityId),
    select: (data) => data.data.savedPosts,
  });
};
