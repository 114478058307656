import { useInfiniteQuery } from '@tanstack/react-query';
import { getCommunityPosts } from 'utils/api/post';
import { inMilliseconds } from 'utils/helper';

export const getKey = (communityId: string) =>  ['community-posts', communityId] as const;

const useCommunityPosts = (communityId: string) => {
  const { data, hasNextPage, fetchNextPage, refetch, isFetchingNextPage, isLoading } =
    useInfiniteQuery({
      queryKey: getKey(communityId),
      queryFn: ({ pageParam = '' }) => getCommunityPosts(communityId, 5, pageParam),
      getNextPageParam: (lastPage) => lastPage.data.cursor,
      staleTime: inMilliseconds(5),
    });

  return {
    data: data?.pages.flatMap((item) => item.data.posts) ?? [],
    hasNextPage,
    fetchNextPage,
    refetch,
    isFetchingNextPage,
    isFeedLoading: isLoading,
  };
};

export default useCommunityPosts;
