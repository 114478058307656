import { Tab } from '@headlessui/react';
import DetailsBanner from 'components/common/DetailsBanner';
import Layout from 'components/layout/Layout';
import Loading from 'components/Loading';
import NavigateTo404 from 'components/NavigateTo404';
import { programTabItems } from 'config/tabs';
import { useSingleProgram } from 'hooks/api/program/useSingleProgram';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Outlet, useLocation, useNavigate, useParams } from 'react-router';
import { generateProgramLink } from 'utils/url';
import RelatedPrograms from './segment/programs/RelatedPrograms';

const ProgramPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const { programId } = useParams();

  let tabName = location.pathname.split('/').pop();
  if (location.pathname.includes('tasks/submissions')) {
    tabName = 'tasks';
  }
  const currentTabIndexFromRouter = programTabItems.findIndex((item) => item.path === tabName);

  const [showShortBanner, setShowShortBanner] = useState(currentTabIndexFromRouter > 0);

  const { data: program, isLoading: isProgramLoading } = useSingleProgram(programId || '');

  if (isProgramLoading) return <Loading />;
  if (!program) return <NavigateTo404 />;

  return (
    <Layout>
      <div>
        <DetailsBanner
          id={program.id}
          variant='program'
          name={program.name}
          cover={program.cover}
          role={program.programRole}
          archived={program.archived}
          isMember={program.isMember}
          visibility={program.visibility}
          memberCount={program.memberCount}
          description={program.description}
          archiveDate={program.archiveDate}
          joinRequested={program.joinRequested}
          updatedAt={program.updatedAt}
          organizationName={program.organization.name}
          organizationLogo={program.logo || program.organization.logo}
          organizationId={program.organization.id}
          allowGuest={program.allowGuest}
          guestViewCount={program.guestViewCount}
        />

        {program.isMember ? (
          <Tab.Group
            onChange={(value) => {
              navigate(`${generateProgramLink(program.id)}/${programTabItems[value].path}`);
              setShowShortBanner(value !== 0);
            }}
            selectedIndex={tabName ? currentTabIndexFromRouter : 0}
          >
            <Tab.List
              className={`tab-list-parent z-20 shrink-0 overflow-x-auto overflow-y-hidden bg-secondary-100 lg:sticky lg:top-[85px]`}
            >
              {programTabItems.map(({ label }) => (
                <Tab key={label} className='shrink-0 outline-0'>
                  {({ selected }) => (
                    <div
                      className={`nonactive-tab ${
                        selected ? 'active-tab text-secondary-500' : 'border-neutral-300'
                      } `}
                    >
                      {t(label)}
                    </div>
                  )}
                </Tab>
              ))}
            </Tab.List>
            <Tab.Panels>
              {programTabItems.map(({ path }) => (
                <Tab.Panel key={path} className='outline-none p-2 lg:py-4 lg:px-5'>
                  <Outlet />
                </Tab.Panel>
              ))}
            </Tab.Panels>
          </Tab.Group>
        ) : (
          <RelatedPrograms programId={program.id} />
        )}
      </div>
    </Layout>
  );
};

export default ProgramPage;
