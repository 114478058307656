import EyeIcon from 'assets/icons/eye.svg';
import SearchInput from 'components/form/SearchInput';
import Button from 'components/button/Button';
import Tag from 'components/tag/Tag';
import BodyText from 'components/typography/BodyText';
import BackIcon from 'assets/icons/back.svg';
import { generateOriginLink } from 'utils/url';
import dayjs from 'dayjs';
import { useProgramTasks } from 'hooks/api/task/useProgramTasks';
import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { generateTaskSubmissionLink } from 'utils/url';
import { Link } from 'react-router-dom';

interface IMembersSubmissions {
  originId: string;
}

const MembersSubmissions = ({ originId }: IMembersSubmissions) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [searchString, setSearchString] = useState('');

  const { tasks } = useProgramTasks(originId, undefined);

  const filteredTasks = useMemo(
    () => tasks.filter((submission) => submission.title.toLowerCase().includes(searchString)) || [],
    [tasks, searchString],
  );

  return (
    <div className='space-y-4'>
      <div className='flex flex-row items-center gap-2 px-1 justify-between'>
        <div className='flex items-center gap-2'>
          <SearchInput
            className='basis-full lg:basis-auto'
            onSearch={(value) => setSearchString(value)}
          />
          <BodyText variant='base' className='mr-auto text-neutral-500 hidden md:block'>
            {t('total')} {tasks.length}
          </BodyText>
        </div>
        <Link to={generateOriginLink(originId, 'program') + '/tasks'}>
          <Button
            size='default'
            variant='outlined'
            className='text-functional-danger-400 p-[8px]'
            tooltip={t('back')}
          >
            <BackIcon className='h-4 w-4' />
          </Button>
        </Link>
      </div>

      <div className='table-container'>
        <table>
          <thead>
            <tr>
              <th>
                <BodyText variant='sm' className='font-medium'>
                  {t('task_name')}
                </BodyText>
              </th>
              <th>
                <BodyText variant='sm' className='font-medium'>
                  {t('deadline')}
                </BodyText>
              </th>
              <th>
                <BodyText variant='sm' className='font-medium'>
                  {t('status')}
                </BodyText>
              </th>
              <th>
                <BodyText variant='sm' className='font-medium'>
                  {t('actions')}
                </BodyText>
              </th>
            </tr>
          </thead>
          <tbody>
            {filteredTasks.map(({ id, submitted, title, deadline, hasDeadline }) => (
              <tr key={id}>
                <td>
                  <button
                    className='hover:underline'
                    onClick={() =>
                      navigate(generateTaskSubmissionLink('program', originId, id, title, deadline))
                    }
                  >
                    <BodyText variant='sm' className='text-neutral-500'>
                      {title}
                    </BodyText>
                  </button>
                </td>
                <td>
                  {!hasDeadline ? (
                    <BodyText variant='xs'> {t('open_ended_task')}</BodyText>
                  ) : (
                    <BodyText variant='sm' className='text-neutral-500'>
                      {dayjs(deadline).format('ddd, MMM D, YYYY h:mm A')}
                    </BodyText>
                  )}
                </td>
                <td>
                  <div className='flex items-center gap-3'>
                    {submitted ? (
                      <Tag
                        type='rounded'
                        className='bg-functional-success-light text-functional-success-dark'
                      >
                        {t('submitted')}
                      </Tag>
                    ) : (
                      <Tag
                        type='rounded'
                        className='bg-functional-warning-light text-functional-warning-dark'
                      >
                        {t('pending')}
                      </Tag>
                    )}
                    {dayjs(deadline).diff(dayjs(new Date())) < 0 && (
                      <Tag
                        type='rounded'
                        className='bg-functional-danger-light text-functional-danger-dark'
                      >
                        {t('closed')}
                      </Tag>
                    )}
                  </div>
                </td>
                <td>
                  <button
                    className='flex items-center gap-3 text-neutral-400 hover:text-functional-info-dark'
                    onClick={() =>
                      navigate(generateTaskSubmissionLink('program', originId, id, title, deadline))
                    }
                  >
                    <BodyText as='span' variant='sm' className='font-medium'>
                      {t('view')}
                    </BodyText>{' '}
                    <EyeIcon className='h-5 w-5' />
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default MembersSubmissions;
