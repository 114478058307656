import { AxiosRequestConfig } from 'axios';
import { API_ROOT } from 'constants/config';
import { TaskRequestType } from 'types/apis/request';
import {
  CommentsResponseType,
  CommunityTaskResponseType,
  CommunityTasksResponseType,
  ProgramTaskResponseType,
  ProgramTasksResponseType,
  TasksSubmissionsResponseType,
  TaskSubmissionsResponseType,
} from 'types/apis/response';
import { generateQueryParam, parseDELETE, parseGET, parsePOST, parsePUT } from 'utils/rest';

export const getMyUpcomingTasks = (options?: AxiosRequestConfig) => {
  return parseGET<CommunityTasksResponseType>(`${API_ROOT}/tasks/my-upcoming-tasks?limit=3`, {
    withCredentials: true,
    ...options,
  });
};

export const getMyUpcomingProgramTasks = (options?: AxiosRequestConfig) => {
  return parseGET<ProgramTasksResponseType>(
    `${API_ROOT}/program/tasks/my-upcoming-program-tasks?limit=3`,
    {
      withCredentials: true,
      ...options,
    },
  );
};

export const getCommunityTasks = (
  communityId: string,
  limit?: number,
  cursor?: string,
  upcoming = false,
  options?: AxiosRequestConfig,
) => {
  const queryParams = generateQueryParam({ limit, cursor, upcoming });
  return parseGET<CommunityTasksResponseType>(
    `${API_ROOT}/community/${communityId}/tasks${queryParams}`,
    {
      withCredentials: true,
      ...options,
    },
  );
};

export const getProgramTasks = (
  programId: string,
  limit?: number,
  cursor?: string,
  upcoming = false,
  options?: AxiosRequestConfig,
) => {
  const queryParams = generateQueryParam({ limit, cursor, upcoming });
  return parseGET<ProgramTasksResponseType>(
    `${API_ROOT}/program/${programId}/tasks${queryParams}`,
    {
      withCredentials: true,
      ...options,
    },
  );
};

export const getSingleCommunityTask = (
  communityId: string,
  taskId: string,
  options?: AxiosRequestConfig,
) =>
  parseGET<CommunityTaskResponseType>(
    `${API_ROOT}/community/${communityId}/tasks/${taskId}/single`,
    {
      ...options,
      withCredentials: true,
    },
  );

export const getSingleProgramTask = (
  programId: string,
  taskId: string,
  options?: AxiosRequestConfig,
) =>
  parseGET<ProgramTaskResponseType>(`${API_ROOT}/program/${programId}/tasks/${taskId}/single`, {
    ...options,
    withCredentials: true,
  });

export const createCommunityTask = (
  communityId: string,
  body: TaskRequestType,
  options?: AxiosRequestConfig,
) =>
  parsePOST(`${API_ROOT}/community/${communityId}/tasks`, body, {
    ...options,
    withCredentials: true,
  });

export const createProgramTask = (
  programId: string,
  body: TaskRequestType,
  options?: AxiosRequestConfig,
) =>
  parsePOST(`${API_ROOT}/program/${programId}/tasks`, body, {
    ...options,
    withCredentials: true,
  });

export const updateCommunityTask = (
  communityId: string,
  taskId: string,
  body: TaskRequestType,
  options?: AxiosRequestConfig,
) =>
  parsePUT(`${API_ROOT}/community/${communityId}/tasks/${taskId}`, body, {
    ...options,
    withCredentials: true,
  });

export const updateProgramTask = (
  programId: string,
  taskId: string,
  body: TaskRequestType,
  options?: AxiosRequestConfig,
) =>
  parsePUT(`${API_ROOT}/program/${programId}/tasks/${taskId}`, body, {
    ...options,
    withCredentials: true,
  });

export const deleteCommunityTask = (
  communityId: string,
  taskId: string,
  options?: AxiosRequestConfig,
) =>
  parseDELETE(`${API_ROOT}/community/${communityId}/tasks/${taskId}`, {
    ...options,
    withCredentials: true,
  });

export const deleteProgramTask = (
  programId: string,
  taskId: string,
  options?: AxiosRequestConfig,
) =>
  parseDELETE(`${API_ROOT}/program/${programId}/tasks/${taskId}`, {
    ...options,
    withCredentials: true,
  });

export const likeCommunityTask = (
  communityId: string,
  taskId: string,
  options?: AxiosRequestConfig,
) =>
  parsePUT(
    `${API_ROOT}/community/${communityId}/tasks/${taskId}/like`,
    {},
    { ...options, withCredentials: true },
  );

export const likeProgramTask = (programId: string, taskId: string, options?: AxiosRequestConfig) =>
  parsePUT(
    `${API_ROOT}/program/${programId}/tasks/${taskId}/like`,
    {},
    { ...options, withCredentials: true },
  );

export const dislikeCommunityTask = (
  communityId: string,
  taskId: string,
  options?: AxiosRequestConfig,
) =>
  parsePUT(
    `${API_ROOT}/community/${communityId}/tasks/${taskId}/unlike`,
    {},
    { ...options, withCredentials: true },
  );

export const dislikeProgramTask = (
  programId: string,
  taskId: string,
  options?: AxiosRequestConfig,
) =>
  parsePUT(
    `${API_ROOT}/program/${programId}/tasks/${taskId}/unlike`,
    {},
    { ...options, withCredentials: true },
  );

export const getCommunityTaskComments = (
  communityId: string,
  taskId: string,
  limit = 2,
  cursor?: string,
  options?: AxiosRequestConfig,
) => {
  const queryParam = generateQueryParam({ limit, cursor });
  return parseGET<CommentsResponseType>(
    `${API_ROOT}/community/${communityId}/tasks/${taskId}/comments${queryParam}`,
    {
      withCredentials: true,
      ...options,
    },
  );
};

export const getProgramTaskComments = (
  programId: string,
  taskId: string,
  limit = 2,
  cursor?: string,
  options?: AxiosRequestConfig,
) => {
  const queryParam = generateQueryParam({ limit, cursor });
  return parseGET<CommentsResponseType>(
    `${API_ROOT}/program/${programId}/tasks/${taskId}/comments${queryParam}`,
    {
      withCredentials: true,
      ...options,
    },
  );
};

export const addCommunityTaskComment = (
  communityId: string,
  taskId: string,
  body: { comment: string },
  options?: AxiosRequestConfig,
) =>
  parsePOST(`${API_ROOT}/community/${communityId}/tasks/${taskId}/comments`, body, {
    withCredentials: true,
    ...options,
  });

export const addProgramTaskComment = (
  programId: string,
  taskId: string,
  body: { comment: string },
  options?: AxiosRequestConfig,
) =>
  parsePOST(`${API_ROOT}/program/${programId}/tasks/${taskId}/comments`, body, {
    withCredentials: true,
    ...options,
  });

export const deleteCommunityTaskComment = (
  communityId: string,
  commentId: string,
  options?: AxiosRequestConfig,
) =>
  parseDELETE(`${API_ROOT}/community/${communityId}/comments/${commentId}`, {
    withCredentials: true,
    ...options,
  });

export const deleteProgramTaskComment = (
  programId: string,
  commentId: string,
  options?: AxiosRequestConfig,
) =>
  parseDELETE(`${API_ROOT}/program/${programId}/comments/${commentId}`, {
    withCredentials: true,
    ...options,
  });

export const getcommunityTaskSubmissions = (
  communityId: string,
  taskId: string,
  options?: AxiosRequestConfig,
) =>
  parseGET<TaskSubmissionsResponseType>(
    `${API_ROOT}/community/${communityId}/tasks/${taskId}/submission`,
    {
      withCredentials: true,
      ...options,
    },
  );

export const getProgramTaskSubmissions = (
  programId: string,
  taskId: string,
  options?: AxiosRequestConfig,
) =>
  parseGET<TaskSubmissionsResponseType>(
    `${API_ROOT}/program/${programId}/tasks/${taskId}/submission`,
    {
      withCredentials: true,
      ...options,
    },
  );

export const submitCommunityTask = (
  communityId: string,
  taskId: string,
  body: FormData,
  options?: AxiosRequestConfig,
) =>
  parsePOST(`${API_ROOT}/community/${communityId}/tasks/${taskId}/submission`, body, {
    withCredentials: true,
    ...options,
  });

export const submitProgramTask = (
  programId: string,
  taskId: string,
  body: FormData,
  options?: AxiosRequestConfig,
) =>
  parsePOST(`${API_ROOT}/program/${programId}/tasks/${taskId}/submission`, body, {
    withCredentials: true,
    ...options,
  });

export const updateCommunityTaskSubmission = (
  communityId: string,
  submissionId: string,
  body: { title: string; note: string },
  options?: AxiosRequestConfig,
) =>
  parsePUT(`${API_ROOT}/community/${communityId}/tasks/submission/${submissionId}`, body, {
    withCredentials: true,
    ...options,
  });

export const updateProgramTaskSubmission = (
  programId: string,
  submissionId: string,
  body: { title: string; note: string },
  options?: AxiosRequestConfig,
) =>
  parsePUT(`${API_ROOT}/program/${programId}/tasks/submission/${submissionId}`, body, {
    withCredentials: true,
    ...options,
  });

export const deleteCommunityTaskSubmission = (
  communityId: string,
  submissionId: string,
  options?: AxiosRequestConfig,
) =>
  parseDELETE(`${API_ROOT}/community/${communityId}/tasks/submission/${submissionId}`, {
    withCredentials: true,
    ...options,
  });

export const deleteProgramTaskSubmission = (
  programId: string,
  submissionId: string,
  options?: AxiosRequestConfig,
) =>
  parseDELETE(`${API_ROOT}/program/${programId}/tasks/submission/${submissionId}`, {
    withCredentials: true,
    ...options,
  });

export const getCommunityTasksSubmissions = (communityId: string, options?: AxiosRequestConfig) =>
  parseGET<TasksSubmissionsResponseType>(`${API_ROOT}/community/${communityId}/submissions`, {
    withCredentials: true,
    ...options,
  });

export const getProgramTasksSubmissions = (programId: string, options?: AxiosRequestConfig) =>
  parseGET<TasksSubmissionsResponseType>(`${API_ROOT}/program/${programId}/submissions`, {
    withCredentials: true,
    ...options,
  });
