import AddIcon from 'assets/icons/add.svg';
import BackIcon from 'assets/icons/back.svg';
import NoteIcon from 'assets/icons/note-filled.svg';
import DeleteIcon from 'assets/icons/delete.svg';
import DownloadIcon from 'assets/icons/download.svg';
import EditIcon from 'assets/icons/edit.svg';
import LoadingIcon from 'assets/icons/loading.svg';
import Button from 'components/button/Button';
import Layout from 'components/layout/Layout';
import BodyText from 'components/typography/BodyText';
import dayjs from 'dayjs';
import React, { useCallback, useMemo, useState } from 'react';
import SearchInput from 'components/form/SearchInput';
import debounce from 'lodash/debounce';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { SingleSubmissionType, SingleUserDetailsType } from 'types/apis/common';
import { bytesToMegaBytes } from 'utils/helper';
import ManagerSubmissionViewRow from './ManagerSubmissionViewRow';
import Tag from 'components/tag/Tag';
import { downloadZip } from 'utils/downloadzip';
import RichTextModal from 'components/modal/RichTextModal';
import RichTextEditor from 'components/common/RichTextEditorWithUpload';
import ResourceViewerDrawer from 'components/drawer/ResourceViewerDrawer';
import { isViewableFile } from 'utils/helper';

interface ITaskSubmissions {
  submissions: {
    userDetails: SingleUserDetailsType;
    userSubmissions: SingleSubmissionType[];
  }[];
  canManage: boolean;
  taskName?: string | null | undefined;
  deadline?: string | null | undefined;
  isGroup?: boolean | undefined;
  currentUserId?: string | undefined;
  handleAddSubmission: () => void;
  handleEditSubmission: (
    submissionId: string,
    submissionTitle: string,
    submissionNote?: string,
  ) => void;
  handleOpenSubmissionRemarks: (submissionId: string) => void;
  handleDeleteSubmission: (submissionId: string) => void;
}

const TaskSubmissions = ({
  submissions,
  canManage,
  isGroup,
  currentUserId,
  taskName,
  deadline,
  handleAddSubmission,
  handleEditSubmission,
  handleDeleteSubmission,
  handleOpenSubmissionRemarks,
}: ITaskSubmissions) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [isRichTextModalOpen, setIsRichTextModalOpen] = useState(false);
  const [content, setContent] = useState('');
  const [showResourceViewer, setShowResourceViewer] = useState(false);
  const [fileUrl, setFileUrl] = useState('');
  const [uploaderName, setUploaderName] = useState('');
  const [uploadedBy, setUploadedBy] = useState('');
  const [originId, setOriginId] = useState('');
  const [originType, setOriginType] = useState('');
  const [isDownLoading, setIsDownLoading] = useState(false);
  const [searchString, setSearchString] = useState('');

  // Search related code:
  const filteredSubmissions = useMemo(() => {
    if (!searchString) return submissions;

    return submissions.filter(({ userDetails, userSubmissions }) =>
      userSubmissions.some((submission) => {
        const searchLower = searchString.toLowerCase();
        const nameMatch =
          userDetails.firstName && userDetails.lastName
            ? userDetails.firstName.toLowerCase().includes(searchLower) ||
              userDetails.lastName.toLowerCase().includes(searchLower)
            : false;
        const titleMatch = submission.title
          ? submission.title.toLowerCase().includes(searchLower)
          : false;
        const fileNameMatch = submission.file?.fileName
          ? submission.file.fileName.toLowerCase().includes(searchLower)
          : false;
        const pageTitleMatch = submission.page?.title
          ? submission.page.title.toLowerCase().includes(searchLower)
          : false;
        return titleMatch || fileNameMatch || pageTitleMatch || nameMatch;
      }),
    );
  }, [submissions, searchString]);

  const handleSearch = (value: string) => {
    setSearchString(value.toLowerCase());
  };
  const debounceSearch = useCallback(debounce(handleSearch, 300), []);
  // search related code ends here

  const handleDownloadZip = () => {
    setIsDownLoading(true);
    setTimeout(async () => {
      const fileDetails = submissions.map(({ userSubmissions }) => {
        return userSubmissions.map(({ file }) => {
          return {
            url: file?.directory || '',
            filename: file?.fileName || '',
          };
        });
      });
      await downloadZip(taskName || 'download', fileDetails.flat());
      setIsDownLoading(false);
    }, 1000);
  };
  function isValidJSON(str = '') {
    try {
      JSON.parse(str);
      return true;
    } catch (e) {
      return false;
    }
  }
  return (
    <Layout>
      <div className='p-4 space-y-4'>
        <div className='flex flex-row items-center gap-2 px-1 justify-between'>
          <div className='flex items-center gap-2'>
            <SearchInput onSearch={debounceSearch} />
            <BodyText variant='base' className='mr-auto text-neutral-500 hidden md:block'>
              {canManage
                ? t('submitted_member_count', { count: submissions.length })
                : t('submitted_files_count', {
                    count: submissions[0]?.userSubmissions.length ?? 0,
                  })}
            </BodyText>
          </div>
          <div className='flex items-center gap-3'>
            <Button
              size='small'
              variant='secondary-outlined'
              tooltip={t('submit_task')}
              onClick={handleAddSubmission}
            >
              <AddIcon className='h-5 w-5' />
            </Button>
            <Button
              size='default'
              variant='outlined'
              onClick={() => navigate(-1)}
              className='text-functional-danger-400 p-[8px]'
              tooltip={t('back')}
            >
              <BackIcon className='h-4 w-4' />
            </Button>
          </div>
        </div>
        <div className='table-container'>
          <table>
            <caption className='bg-secondary-100 p-1'>
              <BodyText variant='base' className='font-semibold text-neutral-500 line-clamp-1'>
                {taskName ?? t('submitted_files')}
              </BodyText>
            </caption>
            <thead>
              <tr>
                <th>{t('file')}</th>
                <th>{t('submission_info')}</th>
                <th>{t('upload_date')}</th>
                <th>{t('created-by')}</th>
                <th>{t('manager-comments')}</th>
                <th>{t('actions')}</th>
              </tr>
            </thead>
            <tbody>
              {canManage || isGroup
                ? // all members submissionw
                  filteredSubmissions.map(({ userDetails, userSubmissions }, index) => (
                    <ManagerSubmissionViewRow
                      userDetails={userDetails}
                      currentUserId={currentUserId}
                      isGroup={isGroup}
                      taskDeadline={deadline}
                      userSubmissions={userSubmissions}
                      key={`${new Date().getMilliseconds() + index}`}
                      handleEditSubmission={handleEditSubmission}
                      handleDeleteSubmission={handleDeleteSubmission}
                      handleOpenSubmissionRemarks={handleOpenSubmissionRemarks}
                    />
                  ))
                : // single member submissions
                  filteredSubmissions[0]?.userSubmissions.map(
                    ({ id, title, note, file, remarks, page, createdAt, userId }) => (
                      <tr key={id} className='group'>
                        <td>
                          {file?.fileName && file.directory !== '' && (
                            <button
                              className='hover:underline line-clamp-3 flex w-full max-w-[12rem] items-center break-all rounded-lg p-1 text-left text-neutral-500'
                              onClick={() => {
                                if (isViewableFile(file?.directory || '')) {
                                  setFileUrl(file?.directory || '');
                                  setOriginId(id);
                                  setOriginType('task_submission');
                                  setUploaderName(' ');
                                  setUploadedBy(userId);
                                  setShowResourceViewer(true);
                                } else {
                                  window.location.href = file?.directory || '';
                                }
                              }}
                            >
                              <div>
                                <BodyText variant='sm' className='font-medium'>
                                  {file.fileName}
                                </BodyText>

                                <BodyText variant='xs' className=''>
                                  {bytesToMegaBytes(file.fileSize)}
                                </BodyText>
                              </div>
                            </button>
                          )}
                          {page && page.id && (
                            <button
                              className='hover:underline line-clamp-3 flex w-full max-w-[12rem] items-center break-all rounded-lg p-1 text-left text-neutral-500'
                              onClick={() => {
                                setFileUrl('');
                                setOriginId(page?.id || '');
                                setUploaderName(' ');
                                setUploadedBy(userId);
                                setOriginType('page');
                                setShowResourceViewer(true);
                              }}
                            >
                              <NoteIcon className='h-4 w-4 shrink-0' />
                              <BodyText variant='sm' className='font-medium '>
                                {page.title}
                              </BodyText>
                            </button>
                          )}
                        </td>

                        <td>
                          <BodyText variant='sm' className='font-medium text-neutral-500'>
                            {title}
                          </BodyText>
                          {note && (
                            <button
                              className='rounded-md bg-neutral-300 p-1 text-xs hover:bg-neutral-400 active:bg-neutral-500 hover:underline'
                              onClick={() => {
                                setIsRichTextModalOpen(true);
                                setContent(note);
                              }}
                            >
                              <div>
                                <div className='max-h-8 w-[12rem] overflow-hidden'>
                                  <RichTextEditor
                                    initialContent={
                                      isValidJSON(note)
                                        ? JSON.stringify({
                                            ...JSON.parse(note),
                                            blocks: [JSON.parse(note).blocks[0]],
                                          })
                                        : note
                                    }
                                    isReadOnly={true}
                                  />
                                </div>
                                <BodyText
                                  variant='xs'
                                  className='text-left text-functional-info-400'
                                >
                                  {t('show_more')}...
                                </BodyText>
                              </div>
                            </button>
                          )}
                        </td>

                        <td>
                          <BodyText variant='sm' className='text-neutral-500'>
                            {dayjs(createdAt).format('ddd, D MMM YYYY, h:mm A')}
                          </BodyText>

                          {dayjs(deadline).isBefore(dayjs(createdAt)) && (
                            <Tag
                              type='default'
                              className='w-10 bg-functional-danger-light capitalize text-functional-danger-dark'
                            >
                              {t('late')}
                            </Tag>
                          )}
                        </td>

                        <td>{'-'}</td>
                        <td>
                          {remarks.length > 0 ? (
                            <Button
                              size='small'
                              variant='outlined'
                              className='group relative flex items-center justify-center rounded-full bg-neutral-200  text-secondary-400'
                              onClick={() => handleOpenSubmissionRemarks(id)}
                            >
                              <span className='text-sm font-semibold text-secondary-500'>
                                ({remarks.length})
                              </span>
                              <span className='text-xs'> {t('comments')}</span>
                            </Button>
                          ) : (
                            <Button
                              size='small'
                              variant='outlined'
                              className='group relative flex items-center justify-center rounded-full bg-neutral-200  text-xs text-secondary-400'
                              onClick={() => handleOpenSubmissionRemarks(id)}
                            >
                              <AddIcon className='h-3 w-3' />
                              {t('comment')}
                            </Button>
                          )}
                        </td>

                        <td>
                          <div className='flex items-center gap-3 lg:opacity-0 lg:group-hover:opacity-100'>
                            <button
                              className='flex h-6 w-6 items-center justify-center rounded-full hover:bg-neutral-300 active:bg-neutral-400'
                              onClick={() => handleEditSubmission(id, title, note)}
                            >
                              <EditIcon className='h-5 w-5 text-neutral-400 hover:text-functional-info-dark active:text-functional-info-dark' />
                            </button>
                            <button
                              className='flex h-6 w-6 items-center justify-center rounded-full hover:bg-neutral-300 active:bg-neutral-400'
                              onClick={() => handleDeleteSubmission(id)}
                            >
                              <DeleteIcon className='h-5 w-5 text-neutral-400 hover:text-functional-danger-dark active:text-functional-danger-dark' />
                            </button>
                            {file && file.directory !== '' && (
                              <button
                                className='flex h-6 w-6 items-center justify-center rounded-full hover:bg-neutral-300 active:bg-neutral-400'
                                onClick={() => {
                                  if (file) window.open(file.directory, '_blank');
                                }}
                              >
                                <DownloadIcon className='h-5 w-5 text-neutral-400 hover:text-functional-info-dark' />
                              </button>
                            )}
                          </div>
                        </td>
                      </tr>
                    ),
                  )}
            </tbody>
          </table>
          {submissions.length > 0 && (
            <div className='m-2 flex justify-end'>
              <Button
                size='small'
                variant='outlined'
                onClick={handleDownloadZip}
                className='w-[8rem]'
              >
                {isDownLoading ? <LoadingIcon className='h-4 w-4' /> : t('download_all')}
              </Button>
            </div>
          )}
        </div>
        <RichTextModal
          isOpen={isRichTextModalOpen}
          label={t('note')}
          description={content}
          onClose={() => setIsRichTextModalOpen(false)}
        />
        {(isViewableFile(fileUrl) || originType === 'page') && (
          <ResourceViewerDrawer
            isOpen={showResourceViewer}
            setIsOpen={setShowResourceViewer}
            fileUrl={fileUrl}
            uploaderId={uploadedBy}
            uploadedBy={uploaderName}
            originId={originId}
            originType={originType}
          />
        )}
      </div>
    </Layout>
  );
};

export default TaskSubmissions;
