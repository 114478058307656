import { AxiosRequestConfig } from 'axios';
import { API_ROOT } from 'constants/config';
import { NotificationRequestType } from 'types/apis/request';
import { NotificationsResponseType, UnseenNotificationResponseType } from 'types/apis/response';
import { parseDELETE, parseGET, parsePOST } from 'utils/rest';

export const getUnseenNotificatoins = (options?: AxiosRequestConfig) => {
  return parseGET<UnseenNotificationResponseType>(`${API_ROOT}/notifications/unseen`, {
    withCredentials: true,
    ...options,
  });
};

export const getNotifications = (options?: AxiosRequestConfig) => {
  return parseGET<NotificationsResponseType>(`${API_ROOT}/notifications`, {
    withCredentials: true,
    ...options,
  });
};

export const createNotification = (body: NotificationRequestType, options?: AxiosRequestConfig) => {
  return parsePOST(`${API_ROOT}/notifications`, body, { withCredentials: true, ...options });
};

export const deleteNotification = (notificationId: string, options?: AxiosRequestConfig) => {
  return parseDELETE(`${API_ROOT}/notification/${notificationId}`, {
    withCredentials: true,
    ...options,
  });
};
