import { useQuery } from '@tanstack/react-query';
import { getProgramTasksSubmissions } from 'utils/api/task';

export const useProgramTasksSubmissions = (programId: string) => {
  return useQuery({
    queryKey: ['program-tasks-submissions', programId],
    queryFn: () => getProgramTasksSubmissions(programId),
    select: (data) => data.data.tasks,
  });
};
