import React from 'react';

interface ITag {
  className?: string;
  children: React.ReactNode;
  type: 'default' | 'rounded';
  as?: 'div' | 'span';
}

const Tag = ({ children, type, className = '', as: As = 'div' }: ITag) => (
  <As
    className={`tag ${type === 'rounded' ? 'tag-type-rounded' : 'tag-type-default'}  ${className}`}
  >
    {children}
  </As>
);

export default Tag;
