import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import useIsLoggedIn from 'hooks/api/auth/useIsLoggedIn';
import { UserType } from 'types/apis/common';
import LoadingIcon from '../assets/icons/loading.svg';

interface IPrivateRoutes {
  allowedRoles: Array<keyof typeof UserType>;
}

function PrivateRoutes({ allowedRoles }: IPrivateRoutes) {
  const { data, isLoading } = useIsLoggedIn();

  if (isLoading)
    return (
      <div className='flex h-screen w-screen items-center justify-center bg-neutral-200 bg-opacity-65'>
        <LoadingIcon className='h-10 w-10 text-secondary-500' />
      </div>
    );

  return allowedRoles.find((role) => role === data?.userType) ? (
    <Outlet />
  ) : data?.id ? (
    <Navigate to='/page-not-found' />
  ) : (
    <Navigate to='/login' />
  );
}

export default PrivateRoutes;
