import Layout from 'components/layout/Layout';
import CommonPost from 'components/post/CommonPost';
import { userAvatar } from 'constants/common';
import { useProgramPostComments } from 'hooks/api/post/useProgramPostComments';
import { useProgramSinglePost } from 'hooks/api/post/useProgramSinglePost';
import { useProgramSavedPosts } from 'hooks/api/post/userProgramSavedPosts';
import { useSingleProgram } from 'hooks/api/program/useSingleProgram';
import React, { useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { PostOrigin } from 'types/apis/common';
import { handleLikePost } from 'utils/post';

const SingleProgramPostPage = () => {
  const { programId, postId } = useParams();

  const [showComments, setShowComments] = useState(true);

  const { data: program } = useSingleProgram(programId || '');
  const { data: savedPosts = [] } = useProgramSavedPosts(programId || '');
  const { data: post } = useProgramSinglePost(programId || '', postId || '');
  const { comments, hasNextComments, fetchNextComments } = useProgramPostComments(
    programId || '',
    postId || '',
  );

  const singlePost = useMemo(() => {
    if (!post) return null;
    return {
      ...post,
      savedPostId: savedPosts.find((savedPost) => savedPost.post.id === post.id)?.id,
    };
  }, [program, postId, post, savedPosts]);

  if (!singlePost || !programId) return null;

  const {
    id,
    content,
    authorType,
    commentCount,
    createdAt,
    user,
    embedType,
    file,
    isAuthor,
    isPinned,
    likeCount,
    liked,
    viewCount,
    savedPostId,
  } = singlePost;

  return (
    <Layout>
      <div className='mb-6 mt-4 grid grid-cols-5 gap-5'>
        <div className='col-span-5 space-y-6 lg:col-span-3'>
          <CommonPost
            postId={id}
            file={file}
            postOriginId={programId}
            isAuthor={isAuthor}
            authorId={user.id}
            authorLogo={user.profilePicture || userAvatar}
            authorName={`${user.firstName} ${user.lastName}`}
            authorRole={authorType}
            createdAt={createdAt}
            isPostLiked={liked}
            postCommentCount={commentCount}
            postContent={content}
            postLikeCount={likeCount}
            postViewCount={viewCount}
            embedType={embedType}
            canManage={false}
            isPostPinned={isPinned}
            handleLikePost={handleLikePost}
            handlePinPost={() => {}}
            savedPostId={savedPostId}
            comments={comments}
            hasNextComments={hasNextComments || false}
            fetchNextComments={fetchNextComments}
            postOriginType={PostOrigin.program}
            showComments={showComments}
            setShowComments={setShowComments}
          />
        </div>
      </div>
    </Layout>
  );
};

export default SingleProgramPostPage;
