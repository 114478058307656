import DocumentIcon from 'assets/icons/document.svg';
import OnlineIcon from 'assets/icons/video-camera.svg';
import PdfIcon from 'assets/icons/file-pdf.svg';
import WordIcon from 'assets/icons/ms-word.svg';
import PowerPointIcon from 'assets/icons/ms-powerpoint.svg';
import ExcelIcon from 'assets/icons/ms-excel.svg';
import React from 'react';

type Props = {
  fileInput: string;
  className?: string;
};

function MaterialIcon({ fileInput, className }: Props) {
  const suffix = (fileInput + '').split('.').pop().toLowerCase();
  if (['doc', 'docx', 'txt'].some((s) => suffix.includes(s))) {
    return <WordIcon className={` ${className}`} />;
  } else if (['pdf'].some((s) => suffix.includes(s))) {
    return <PdfIcon className={` ${className}`} />;
  } else if (['ppt', 'pptx'].some((s) => suffix.includes(s))) {
    return <PowerPointIcon className={` ${className}`} />;
  } else if (['xls', 'xlsx'].some((s) => suffix.includes(s))) {
    return <ExcelIcon className={` ${className}`} />;
  } else if (['mp4', 'avi', 'mov', 'wmv'].some((s) => suffix.includes(s))) {
    return <OnlineIcon className={` ${className}`} />;
  } else {
    // default icon
    return <DocumentIcon className={` ${className}`} />;
  }
}

export default MaterialIcon;
