import ArchiveIcon from 'assets/icons/archive.svg';
import PrivateIcon from 'assets/icons/eye-off.svg';
import EyeIcon from 'assets/icons/eye.svg';
import { Link } from 'react-router-dom';
import { generateOriginLink } from 'utils/url';
import PublicIcon from 'assets/icons/eye.svg';
import OpenIcon from 'assets/icons/open.svg';
import { commonCover } from 'constants/common';
import dayjs from 'dayjs';
import BodyText from 'components/typography/BodyText';
import ConfirmationModal from 'components/modal/ConfirmationModal';
import { useArchivedPrograms } from 'hooks/api/program/useArchivedPrograms';
import { useInvalidateQueries } from 'hooks/common/useInvalidatequeries';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { restoreProgram } from 'utils/api/program';
import { notifySuccess, notifyTranslatedError } from 'utils/notify';

const ArchivedPrograms = () => {
  const { t } = useTranslation();

  const [selectedId, setSelectedId] = useState('');
  const [openRestoreConfirmation, setOpenRestoreConfirmation] = useState(false);

  const { programs } = useArchivedPrograms();
  const { invalidateSingleProgram, invalidateMyPrograms, invalidateArchivedPrograms } =
    useInvalidateQueries();

  const handleOpenRestoreConfirmation = (programId: string) => {
    setSelectedId(programId);
    setOpenRestoreConfirmation(true);
  };

  const handleCloseRestoreConfirmation = () => {
    setSelectedId('');
    setOpenRestoreConfirmation(false);
  };

  const handleRestoreProgram = () => {
    restoreProgram(selectedId)
      .then(() => {
        invalidateSingleProgram(selectedId);
        invalidateMyPrograms();
        invalidateArchivedPrograms();
        handleCloseRestoreConfirmation();
        notifySuccess(t('success.api.space_restored'));
      })
      .catch((error) => {
        notifyTranslatedError(t, error.response.data?.errorCode);
      });
  };

  return (
    <div className='mb-10 flex space-y-2 '>
      <div className='overflow-x-auto rounded-2xl bg-neutral-200 px-4 py-5 pb-[120px] shadow shadow-neutral-300'>
        <table className='min-w-[800px] table-auto'>
          <thead className='bg-neutral-100'>
            <tr className='border-b-2 border-neutral-200'>
              <th className='px-4 py-2 text-left'>
                <BodyText variant='sm' className='font-medium'>
                  {t('about')}
                </BodyText>
              </th>
              <th className='px-4 py-2  text-left'>
                <BodyText variant='sm' className='font-medium'>
                  {t('visibility')}
                </BodyText>
              </th>
              <th className='px-4 py-2  text-left'>
                <BodyText variant='sm' className=' font-medium'>
                  {t('members')}
                </BodyText>
              </th>
              <th className='px-4 py-2  text-left'>
                <BodyText variant='sm' className=' font-medium'>
                  {t('created_at')}
                </BodyText>
              </th>
              <th className='px-4 py-2  text-left'>
                <BodyText variant='sm' className=' font-medium'>
                  {t('archived_on')}
                </BodyText>
              </th>
              <th className='px-4 py-2  text-left'>
                <BodyText variant='sm' className=' font-medium'>
                  {t('actions')}
                </BodyText>
              </th>
            </tr>
          </thead>

          <tbody>
            {programs?.map(
              ({
                id,
                name,
                visibility,
                cover,
                logo,
                createdAt,
                memberCount,
                organization,
                archiveDate,
              }) => (
                <tr key={id} className='border-b-2 border-neutral-200'>
                  <td className='p-2'>
                    <a href={`/admin-dashboard/programs/${id}/managers`}>
                      <button className='neutral-button flex w-full max-w-[30rem] items-center rounded-md p-1 text-neutral-500'>
                        <img
                          src={cover || logo || organization.logo || commonCover}
                          alt={`Cover for ${name}`}
                          className='h-8 w-8 rounded-full object-cover'
                        />{' '}
                        <span className='ml-2 line-clamp-3 text-left'>{name}</span>
                      </button>
                    </a>
                  </td>
                  <td className='p-2'>
                    {' '}
                    {visibility && (
                      <div className='flex items-center gap-2 rounded bg-neutral-200 px-2 py-1'>
                        {visibility === 'open' ? (
                          <OpenIcon className='h-4 w-4 text-neutral-500' />
                        ) : visibility === 'private' ? (
                          <PrivateIcon className='h-4 w-4 text-neutral-500' />
                        ) : (
                          <PublicIcon className='h-4 w-4 text-neutral-500' />
                        )}
                        <BodyText variant='sm' className='font-medium capitalize text-neutral-500'>
                          {t(visibility)}
                        </BodyText>
                      </div>
                    )}
                  </td>
                  <td className='p-2 px-4 text-center'>{memberCount}</td>
                  <td className='p-2 px-4 text-left'>
                    <span className='text-sm text-neutral-500'>
                      {dayjs(createdAt).format(' D MMM YYYY')}
                    </span>
                  </td>
                  <td className='p-2 px-4 text-left'>
                    <span className='text-sm text-neutral-500'>
                      {dayjs(archiveDate).format(' D MMM YYYY')}
                    </span>
                  </td>
                  <td className='p-2 px-4 text-center'>
                    <div className='flex items-center gap-4'>
                      <Link
                        to={generateOriginLink(id, 'program')}
                        className='text-sm hover:underline hover:text-functional-info-400 flex items-center gap-1'
                      >
                        <EyeIcon className='h-4 w-4' />
                        {t('view')}
                      </Link>
                      <button
                        className='text-sm hover:underline hover:text-functional-info-400 flex items-center gap-1'
                        onClick={() => handleOpenRestoreConfirmation(id)}
                      >
                        <ArchiveIcon className='h-4 w-4' /> {t('restore')}
                      </button>
                    </div>
                  </td>
                </tr>
              ),
            )}
          </tbody>
        </table>
      </div>

      {openRestoreConfirmation && (
        <ConfirmationModal
          isOpen={openRestoreConfirmation}
          label={t('restore_space')}
          title={t('confirmation-general')}
          description={
            t('restore_space_confirmation_description') ||
            'This program will be visible for all members.'
          }
          onClose={handleCloseRestoreConfirmation}
          onConfirm={handleRestoreProgram}
        />
      )}
    </div>
  );
};

export default ArchivedPrograms;
