import React from 'react';
import Linkify from 'react-linkify';
import parse from 'html-react-parser';
import DOMPurify from 'dompurify';

const LinkifiedText = ({ text }: { text: string | null | undefined }) => {
  if (!text) {
    return null;
  }

  // Sanitize the text to prevent XSS and parse errors
  const sanitizedText = DOMPurify.sanitize(text);

  // Wrap the sanitized text in a span to preserve HTML tags
  const htmlText = `<span>${sanitizedText}</span>`;

  return (
    <Linkify
      componentDecorator={(decoratedHref, decoratedText, key) => (
        <a
          href={decoratedHref}
          key={key}
          target='_blank'
          rel='noopener noreferrer'
          className='active:text-semibold break-all hover:underline'
        >
          {decoratedText}
        </a>
      )}
    >
      {parse(htmlText)}
    </Linkify>
  );
};

export default LinkifiedText;
