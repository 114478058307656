import { useInfiniteQuery } from '@tanstack/react-query';
import { getCommunityEvents } from 'utils/api/event';

export const useCommunityEvents = (communityId: string, limit = 200, isUpcoming = false) => {
  const { data, refetch, hasNextPage, fetchNextPage, isLoading, isFetchingNextPage } =
    useInfiniteQuery({
      queryKey: ['community-events', communityId, isUpcoming],
      queryFn: ({ pageParam = '' }) =>
        getCommunityEvents(communityId, limit, pageParam, isUpcoming),
      getNextPageParam: (res) => res.data.cursor,
      enabled: !!communityId,
    });

  return {
    events: data?.pages.flatMap((item) => item.data.events) ?? [],
    refetchEvents: refetch,
    hasNextEvents: hasNextPage,
    fetchNextEvents: fetchNextPage,
    isEventsLoading: isLoading,
    isFetchingNextEvents: isFetchingNextPage,
  };
};
