import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Cookies from 'js-cookie';
import ListIcon from 'assets/icons/list.svg';
import GridIcon from 'assets/icons/grid.svg';

interface ViewToggleProps {
  onToggleView: (isListView: boolean) => void; // Callback function
}

const ViewToggle: React.FC<ViewToggleProps> = ({ onToggleView }) => {
  const { t } = useTranslation();

  const getListViewFromCookie = (): boolean => {
    const listViewCookie = Cookies.get('listView');
    return listViewCookie !== undefined ? listViewCookie === 'true' : true;
  };

  const [isListView, setIsListView] = useState<boolean>(getListViewFromCookie());

  useEffect(() => {
    Cookies.set('listView', String(isListView));
    onToggleView(isListView); // Inform the parent component of the state
  }, [isListView, onToggleView]);

  const handleToggle = () => {
    setIsListView(!isListView);
  };

  return (
    <div className='flex items-center'>
      <button
        className={`neutral-button rounded-l-lg border border-secondary-500 group relative p-[7px] bg-neutral-200 
          ${
            !isListView ? 'bg-secondary-500 text-neutral-100' : 'bg-neutral-100 text-secondary-500'
          } 
          `}
        onClick={handleToggle}
      >
        <GridIcon className='h-4 w-4' />
        <div className='tooltip top-6 left-0 text-xs text-neutral-100'>{t('grid-view')}</div>
      </button>

      <button
        className={`neutral-button rounded-r-lg border border-secondary-500 group relative p-[7px] bg-neutral-200 
          ${isListView ? 'bg-secondary-500 text-neutral-100' : 'bg-neutral-100 text-secondary-500'} 
          `}
        onClick={handleToggle}
      >
        <ListIcon className='h-4 w-4' />
        <div className='tooltip top-6 right-0 text-xs text-neutral-100'>{t('list-view')}</div>
      </button>
    </div>
  );
};

export default ViewToggle;
