import { useQuery } from '@tanstack/react-query';
import { getMyResources, getSharedResources } from 'utils/api/resource';

export const useMyResources = () => {
  return useQuery({
    queryKey: ['my-resources'],
    queryFn: () => getMyResources(),
    select: (data) => data.data,
  });
};

export const useSharedResources = () => {
  return useQuery({
    queryKey: ['shared-resources'],
    queryFn: () => getSharedResources(),
    select: (data) => data.data,
  });
};
