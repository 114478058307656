import { useInfiniteQuery } from '@tanstack/react-query';
import { getCommunityTasks } from 'utils/api/task';

export const useCommunityTasks = (communityId: string, limit = 9, isUpcoming = false) => {
  const { data, refetch, hasNextPage, fetchNextPage, isLoading, isFetchingNextPage } =
    useInfiniteQuery({
      queryKey: ['community-tasks', communityId, isUpcoming],
      queryFn: ({ pageParam = '' }) => getCommunityTasks(communityId, limit, pageParam, isUpcoming),
      getNextPageParam: (res) => res.data.cursor,
    });

  return {
    tasks: data?.pages.flatMap((item) => item.data.tasks) ?? [],
    refetchTasks: refetch,
    hasNextTasks: hasNextPage,
    fetchNextTasks: fetchNextPage,
    isTasksLoading: isLoading,
    isFetchingNextTasks: isFetchingNextPage,
  };
};
