import Loading from 'components/Loading';
import NavigateTo404 from 'components/NavigateTo404';
import { useCommunityEvent } from 'hooks/api/event/useCommunityEvent';
import { useInvalidateQueries } from 'hooks/common/useInvalidatequeries';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { createCommunityEvent } from 'utils/api/event';
import { notifySuccess, notifyTranslatedError } from 'utils/notify';
import { generateCommunityLink } from 'utils/url';
import EditEventForm from '../event/EditEventForm';

const DuplicateCommunityEventPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { communityId, eventId } = useParams();

  const { data: event, isLoading: isEventLoading } = useCommunityEvent(
    communityId || '',
    eventId || '',
  );
  const { invalidateCommunityEvents, invalidateUpcomingCommunityEvents } = useInvalidateQueries();

  if (isEventLoading) return <Loading />;
  if (!event) return <NavigateTo404 />;

  const {
    id: eventUid,
    title,
    about,
    cover,
    isAllDay,
    startTime,
    endTime,
    location,
    type,
    zoomLink,
    timezone,
  } = event;

  const handleDuplicateEvent = (eventData: FormData) => {
    if (!communityId) return;

    createCommunityEvent(communityId, eventData)
      .then(() => {
        navigate(`${generateCommunityLink(communityId)}/events`);
        notifySuccess(t('success.api.event_created'));
        invalidateCommunityEvents(communityId);
        invalidateUpcomingCommunityEvents(communityId);
      })
      .catch((error) => {
        notifyTranslatedError(t, error.response.data?.message);
        notifyTranslatedError(t, error.response.data?.errorCode);
      });
  };
  return (
    <EditEventForm
      eventType={type}
      eventCover={cover}
      eventTitle={title}
      eventIsAllDay={isAllDay || false}
      eventEndTime={endTime}
      eventDescription={about}
      eventTimezone={timezone}
      eventLink={zoomLink || ''}
      eventStartTime={startTime}
      eventLocation={location || ''}
      handleEditEvent={handleDuplicateEvent}
    />
  );
};

export default DuplicateCommunityEventPage;
