import { AxiosRequestConfig } from 'axios';
import { API_ROOT } from 'constants/config';
import { type } from 'os';
import { MaterialGroupRequestType } from 'types/apis/program/request';
import { MaterialGroupsResponseType, MaterialsResponseType } from 'types/apis/program/response';
import { generateQueryParam, parseDELETE, parseGET, parsePOST, parsePUT } from 'utils/rest';

const API_PROGRAM_ROOT = `${API_ROOT}/program`;

// material group apis

export const getMaterialGroups = (
  programId: string,
  limit?: number,
  cursor?: string,
  options?: AxiosRequestConfig,
) => {
  const queryParams = generateQueryParam({ limit, cursor });
  return parseGET<MaterialGroupsResponseType>(
    `${API_PROGRAM_ROOT}/${programId}/materials/groups${queryParams}`,
    {
      withCredentials: true,
      ...options,
    },
  );
};

export const createMaterialGroup = (
  programId: string,
  body: MaterialGroupRequestType,
  options?: AxiosRequestConfig,
) => {
  return parsePOST(`${API_PROGRAM_ROOT}/${programId}/materials/groups`, body, {
    withCredentials: true,
    ...options,
  });
};

export const updateMaterialGroup = (
  programId: string,
  groupId: string,
  body: MaterialGroupRequestType,
  options?: AxiosRequestConfig,
) => {
  return parsePUT(`${API_PROGRAM_ROOT}/${programId}/materials/groups/${groupId}`, body, {
    withCredentials: true,
    ...options,
  });
};

export const deleteMaterialGroup = (
  programId: string,
  groupId: string,
  options?: AxiosRequestConfig,
) => {
  return parseDELETE(`${API_PROGRAM_ROOT}/${programId}/materials/groups/${groupId}`, {
    withCredentials: true,
    ...options,
  });
};

export const hideMaterialGroup = (
  programId: string,
  groupId: string,
  options?: AxiosRequestConfig,
) => {
  return parsePUT(`${API_PROGRAM_ROOT}/${programId}/materials/groups/${groupId}/hide`, null, {
    withCredentials: true,
    ...options,
  });
};

export const unhideMaterialGroup = (
  programId: string,
  groupId: string,
  options?: AxiosRequestConfig,
) => {
  return parsePUT(`${API_PROGRAM_ROOT}/${programId}/materials/groups/${groupId}/unhide`, null, {
    withCredentials: true,
    ...options,
  });
};

// material apis
export const getMaterials = (programId: string, options?: AxiosRequestConfig) => {
  return parseGET<MaterialsResponseType>(`${API_PROGRAM_ROOT}/${programId}/materials`, {
    withCredentials: true,
    ...options,
  });
};

export const addMaterial = (programId: string, body: FormData, options?: AxiosRequestConfig) => {
  return parsePOST(`${API_PROGRAM_ROOT}/${programId}/materials`, body, {
    withCredentials: true,
    ...options,
  });
};

export const addMaterialToGroup = (
  programId: string,
  groupId: string,
  body: FormData,
  options?: AxiosRequestConfig,
) => {
  return parsePOST(`${API_PROGRAM_ROOT}/${programId}/materials/groups/${groupId}`, body, {
    withCredentials: true,
    ...options,
  });
};

export const deleteMaterial = (
  programId: string,
  materialId: string,
  options?: AxiosRequestConfig,
) => {
  return parseDELETE(`${API_PROGRAM_ROOT}/${programId}/materials/${materialId}`, {
    withCredentials: true,
    ...options,
  });
};

export const hideMaterial = (
  programId: string,
  materialId: string,
  options?: AxiosRequestConfig,
) => {
  return parsePUT(`${API_PROGRAM_ROOT}/${programId}/materials/${materialId}/hide`, null, {
    withCredentials: true,
    ...options,
  });
};

export const unhideMaterial = (
  programId: string,
  materialId: string,
  options?: AxiosRequestConfig,
) => {
  return parsePUT(`${API_PROGRAM_ROOT}/${programId}/materials/${materialId}/unhide`, null, {
    withCredentials: true,
    ...options,
  });
};

export const changeOverallMaterialOrder = (
  id: string,
  programId: string,
  body: { direction: 'up' | 'down'; type: 'material' | 'group' },
  options?: AxiosRequestConfig,
) => {
  return parsePUT(`${API_PROGRAM_ROOT}/${programId}/materials/${id}/changeOrder`, body, {
    withCredentials: true,
    ...options,
  });
};

export const changeMaterialOrder = (
  id: string,
  programId: string,
  groupId: string,
  body: { direction: 'up' | 'down' },
  options?: AxiosRequestConfig,
) => {
  return parsePUT(
    `${API_PROGRAM_ROOT}/${programId}/materials/groups/${groupId}/${id}/changeOrder`,
    body,
    {
      withCredentials: true,
      ...options,
    },
  );
};

export const updateSingleMaterial = (
  id: string,
  programId: string,
  body: { title: string; description: string; groupId: string },
  options?: AxiosRequestConfig,
) => {
  return parsePUT(`${API_PROGRAM_ROOT}/${programId}/materials/${id}/update`, body, {
    withCredentials: true,
    ...options,
  });
};
