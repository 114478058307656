import { Menu } from '@headlessui/react';
import React, { ReactElement, SVGProps, useState } from 'react';
import Button from 'components/button/Button';

export const DropdownMenu = ({
  menuButton,
  menuItems,
  upDirection,
  rightDirection,
}: {
  upDirection?: boolean;
  rightDirection?: boolean;
  menuButton: ReactElement;
  menuItems: {
    key: string;
    label: string;
    icon?: (props: SVGProps<SVGElement>) => ReactElement;
    callback: () => void;
    show?: boolean;
  }[];
}) => {
  const [customOpen, setCustomOpen] = useState(false);
  return (
    <Menu as='div' className='relative'>
      <Menu.Button className='flex items-center justify-center'>{menuButton}</Menu.Button>
      <Menu.Items
        className={`z-40 absolute ${upDirection ? 'bottom-[100%]' : ''} ${
          rightDirection ? 'left-0' : 'right-0'
        }  `}
      >
        <div className='min-w-[150px] rounded-lg bg-neutral-100 shadow-lg shadow-neutral-300 overflow-hidden'>
          <div className='space-y-2'>
            {menuItems.map(({ key, label, callback, icon: Icon }) => {
              return (
                <Menu.Item key={key}>
                  <button
                    onClick={(e) => {
                      e.preventDefault();
                      callback();
                    }}
                    className='flex w-full items-center justify-between gap-4 whitespace-nowrap px-4 py-2 hover:bg-neutral-200'
                  >
                    <span className='text-neutral-600'>{label}</span>
                    {Icon && <Icon className='h-5 w-5 text-neutral-400' />}
                  </button>
                </Menu.Item>
              );
            })}
          </div>
        </div>
      </Menu.Items>
    </Menu>
  );
};
