import { useQuery } from '@tanstack/react-query';
import { getRelatedCommunities } from 'utils/api/community';

export const useRelatedCommunities = (communityId: string) => {
  return useQuery({
    queryKey: ['related-communities', communityId],
    queryFn: () => getRelatedCommunities(communityId),
    select: (data) => data.data.communities,
  });
};
