import React from 'react';
import { Tab } from '@headlessui/react';
import Layout from 'components/layout/Layout';
import { membersAdminTabItems, membersMemberTabItems } from 'config/tabs';
import { Outlet } from 'react-router';
import { useTranslation } from 'react-i18next';
import { useNavigate, useLocation } from 'react-router-dom';
import useIsLoggedIn from 'hooks/api/auth/useIsLoggedIn';

const MembersPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const { data: userInfo, isLoading: isUserInfoLoading } = useIsLoggedIn();

  // Determine which set of tab items to use based on the user type
  const membersTabItems =
    userInfo?.userType === 'organization_admin' ? membersAdminTabItems : membersMemberTabItems;

  const currentTabIndexFromRouter = membersTabItems.findIndex((item) => {
    return location.pathname.includes(item.path);
  });

  const tabName = location.pathname.split('/').pop();

  // Ensure you're not trying to render tabs before userInfo is available
  if (isUserInfoLoading) return <Layout>Loading...</Layout>; // or any other loading state you prefer

  return (
    <Layout>
      <Tab.Group
        onChange={(index) => {
          navigate(`${membersTabItems[index].path}`);
        }}
        selectedIndex={tabName ? currentTabIndexFromRouter : 0}
      >
        <Tab.List className='tab-list-parent sticky top-[0px] z-20 shrink-0 overflow-x-auto overflow-y-hidden bg-secondary-100'>
          {membersTabItems.map(({ label }) => (
            <Tab key={label} className='shrink-0 outline-0'>
              {({ selected }) => (
                <div
                  className={`nonactive-tab ${selected ? 'active-tab text-secondary-500' : 'border-neutral-300'}`}
                >
                  {t(label)}
                </div>
              )}
            </Tab>
          ))}
        </Tab.List>

        <Tab.Panels>
          {membersTabItems.map(({ path, element: Element }) => (
            <Tab.Panel
              key={path}
              className='outline-none py-1 px-1 md:py-2 md:px-3 lg:py-4 lg:px-5'
            >
              <Outlet />
            </Tab.Panel>
          ))}
        </Tab.Panels>
      </Tab.Group>
    </Layout>
  );
};

export default MembersPage;
