import { AxiosRequestConfig } from 'axios';
import { API_ROOT } from 'constants/config';
import {
  GlobalSearchResponseType,
  MentionSearchResponseType,
  SearchUsersResponseType,
} from 'types/apis/response';
import { generateQueryParam, parseGET } from 'utils/rest';

export const search = (
  type: string,
  value: string,
  cursor?: string,
  opitons?: AxiosRequestConfig,
) => {
  const queryParam = generateQueryParam({ type, value, cursor });
  return parseGET<GlobalSearchResponseType>(`${API_ROOT}/search${queryParam}`, {
    withCredentials: true,
    ...opitons,
  });
};

export const searchUser = (
  value: string,
  communityId?: string,
  programId?: string,
  options?: AxiosRequestConfig,
) => {
  const params = { value, communityId, programId };
  const filteredParams = Object.fromEntries(Object.entries(params).filter(([_, v]) => v != null));

  const queryParam = generateQueryParam(filteredParams);
  return parseGET<SearchUsersResponseType>(`${API_ROOT}/users/search${queryParam}`, {
    withCredentials: true,
    ...options,
  });
};

export const mentionSearch = (
  originType: string,
  originId: string,
  type: string,
  value: string,
  conversationId?: string,
  options?: AxiosRequestConfig,
) => {
  const queryParam = generateQueryParam({ type, value, conversationId });

  return parseGET<MentionSearchResponseType>(
    `${API_ROOT}/mention/search/${originType}/${originId}${queryParam}`,
    {
      withCredentials: true,
      ...options,
    },
  );
};
