import AddIcon from 'assets/icons/add.svg';
import ArchivedIcon from 'assets/icons/archive.svg';
import EditIcon from 'assets/icons/edit.svg';
import Button from 'components/button/Button';
import GroupCard from 'components/card/GroupCard';
import { ContextMenu } from 'components/common/ContextMenu';
import AddEditGroupModal from 'components/modal/AddEditGroupModal';
import ConfirmationModal from 'components/modal/ConfirmationModal';
import { useProgramGroups } from 'hooks/api/group/useProgramGroups';
import { useSingleProgram } from 'hooks/api/program/useSingleProgram';
import { useInvalidateQueries } from 'hooks/common/useInvalidatequeries';
import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { archiveProgramGroup } from 'utils/api/group';
import ArchivedGroups from './ArchivedGroups';
import { debounce } from 'lodash';
import SearchInput from 'components/form/SearchInput';
import BodyText from 'components/typography/BodyText';

const ProgramGroups = () => {
  const { t } = useTranslation();
  const { programId } = useParams();

  const [isEditing, setIsEditing] = useState(false);
  const [showGroupModal, setShowGroupModal] = useState(false);
  const [showArchivedGroups, setShowArchivedGroups] = useState(false);
  const [showArchiveConfirmation, setShowArchiveConfirmation] = useState(false);
  const [selectedGroup, setSelectedGroup] = useState<{
    id: string;
    name: string;
    description: string;
    cover: null | string;
    visibility: 'open' | 'public' | 'private';
    allowGuest: 'false';
  }>({
    id: '',
    name: '',
    cover: null,
    description: '',
    visibility: 'private',
    allowGuest: 'false',
  });

  const { data: program } = useSingleProgram(programId || '');

  if (!program) return null;

  const { groups } = useProgramGroups(program.id);
  const { invalidateSingleCommunity, invalidateProgramGroups, invalidateArchivedProgramGroups } =
    useInvalidateQueries();

  // Search related code:
  const [searchString, setSearchString] = useState('');
  const filteredGroups = useMemo(() => {
    return (
      groups?.filter((group) => {
        const searchLower = searchString.toLowerCase();
        // Safely check if the title exists and convert it to lowercase
        const titleMatch = group.name ? group.name.toLowerCase().includes(searchLower) : false;
        return titleMatch;
      }) || []
    );
  }, [groups, searchString]);

  const handleSearch = (value: string) => {
    setSearchString(value.toLowerCase());
  };
  const debounceSearch = useCallback(debounce(handleSearch, 300), []);
  // search related code ends here

  const handleEditGroup = (group: {
    id: string;
    name: string;
    description: string;
    cover: null | string;
    visibility: 'open' | 'public' | 'private';
    allowGuest: string;
  }) => {
    setIsEditing(true);
    setSelectedGroup(
      group as {
        id: string;
        name: string;
        description: string;
        cover: string | null;
        visibility: 'open' | 'public' | 'private';
        allowGuest: 'false';
      },
    );
    setShowGroupModal(true);
  };

  const handleCloseGroupModal = () => {
    setIsEditing(false);
    setShowGroupModal(false);
    setSelectedGroup({
      id: '',
      name: '',
      cover: null,
      description: '',
      visibility: 'private',
      allowGuest: 'false',
    });
  };

  // archived methods
  const handleOpenArchiveConfirmation = (groupId: string) => {
    setShowArchiveConfirmation(true);
    setSelectedGroup({ ...selectedGroup, id: groupId });
  };

  const handleCloseArchiveConfirmation = () => {
    setShowArchiveConfirmation(false);
    setSelectedGroup({ ...selectedGroup, id: '' });
  };

  const handleArchiveGroup = () => {
    archiveProgramGroup(program.id, selectedGroup.id).then(() => {
      invalidateSingleCommunity(selectedGroup.id);
      invalidateProgramGroups(program.id);
      invalidateArchivedProgramGroups(program.id);
      handleCloseArchiveConfirmation();
    });
  };

  return (
    <div>
      <div className='mb-4 flex items-center justify-between px-1 gap-2'>
        <div className='flex items-center gap-2'>
          <SearchInput onSearch={debounceSearch} />
          <BodyText
            variant='base'
            className='text-neutral-500 hidden md:flex flex items-center gap-1'
          >
            <span className='text-secondary-500 font-bold'>{groups.length}</span> {t('groups')}
          </BodyText>
        </div>
        <div className='flex items-center gap-3 justify-end w-full'>
          {program.canManage && (
            <Button
              size='small'
              variant={showArchivedGroups ? 'secondary' : 'outlined'}
              onClick={() => setShowArchivedGroups(!showArchivedGroups)}
              tooltip={showArchivedGroups ? t('show_active') : t('archived')}
            >
              <ArchivedIcon className='h-5 w-5' />
            </Button>
          )}
          {program.canManage && (
            <Button
              size='small'
              variant='secondary-outlined'
              tooltip={t('create-group')}
              onClick={() => setShowGroupModal(true)}
            >
              <AddIcon className='h-5 w-5' />
            </Button>
          )}
        </div>
      </div>

      <div className='mb-6 grid grid-cols-1 gap-5 md:grid-cols-2 xl:grid-cols-3 xxl:grid-cols-4'>
        {showArchivedGroups ? (
          <ArchivedGroups programId={program.id} />
        ) : (
          filteredGroups.map(({ id, name, description, memberCount, role, cover }) => (
            <GroupCard
              key={id}
              groupId={id}
              groupName={name}
              groupRole={role}
              groupMembers={memberCount}
              groupDescription={description}
              menuItems={
                <ContextMenu
                  menuItems={[
                    {
                      key: 'edit',
                      label: t('edit'),
                      icon: EditIcon,
                      callback: () =>
                        handleEditGroup({
                          id,
                          name,
                          cover,
                          description,
                          visibility: 'private',
                          allowGuest: 'false',
                        }),
                    },
                    {
                      key: 'archive',
                      label: t('archive'),
                      icon: ArchivedIcon,
                      callback: () => handleOpenArchiveConfirmation(id),
                    },
                  ]}
                />
              }
            />
          ))
        )}
      </div>

      {showGroupModal && (
        <AddEditGroupModal
          programId={program.id}
          isEditing={isEditing}
          isOpen={showGroupModal}
          selectedValues={selectedGroup}
          onClose={handleCloseGroupModal}
        />
      )}

      {showArchiveConfirmation && (
        <ConfirmationModal
          isOpen={showArchiveConfirmation}
          onClose={handleCloseArchiveConfirmation}
          onConfirm={handleArchiveGroup}
          label={t('archive-group')}
          title={t('archive-confirmation-title')}
        />
      )}
    </div>
  );
};

export default ProgramGroups;
