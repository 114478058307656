import { useQuery } from '@tanstack/react-query';
import { getCommunityMembers } from 'utils/api/member';

const useCommunityMembers = (id: string, enabled = true) =>
  useQuery({
    queryKey: ['community-members', id],
    queryFn: () => getCommunityMembers(id),
    select: (data) => data.data.communityMembers,
    enabled,
  });

export default useCommunityMembers;
