import { useQuery } from '@tanstack/react-query';
import { getMyProfileInfo } from 'utils/api/profile';

export const useProfileInfo = () => {
  return useQuery({
    queryKey: ['profile-info'],
    queryFn: () => getMyProfileInfo(),
    select: (data) => data.data.profile,
  });
};
