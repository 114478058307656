import { WEB_ROOT } from 'constants/config';
import { convertFromRaw, convertToRaw, EditorState, Modifier } from 'draft-js';
import { MemberRoleType } from 'types/apis/common';
import * as DOMPurify from 'dompurify';

export const canManageCommunity = (role: MemberRoleType) =>
  [MemberRoleType.admin, MemberRoleType.manager].includes(role);

export const onEmojiClick = (
  emoji: string,
  inputRef: React.RefObject<HTMLTextAreaElement>,
): string => {
  if (!inputRef.current) return '';
  const currentInputRef = inputRef.current;
  let newValue = currentInputRef.value;

  if (currentInputRef.selectionStart || currentInputRef.selectionStart === 0) {
    const startPos = currentInputRef.selectionStart;
    const endPos = currentInputRef.selectionEnd;
    newValue =
      newValue.substring(0, startPos) + emoji + newValue.substring(endPos, newValue.length);
  } else {
    newValue += emoji;
  }

  return newValue;
};

export const insertTextareaValue = (
  value: string,
  inputRef: React.RefObject<HTMLTextAreaElement>,
): string => {
  if (!inputRef.current) return '';
  const currentInputRef = inputRef.current;
  let newValue = currentInputRef.value;

  if (currentInputRef.selectionStart || currentInputRef.selectionStart === 0) {
    const startPos = currentInputRef.selectionStart;
    const endPos = currentInputRef.selectionEnd;
    newValue =
      newValue.substring(0, startPos - 1) + value + newValue.substring(endPos, newValue.length);
  } else {
    newValue += value;
  }

  return newValue;
};

export const inMilliseconds = (minutes: number): number => minutes * 1000 * 60;

export const handleCopyText = (text: string) => {
  navigator.clipboard.writeText(text);
};

export const bytesToMegaBytes = (size: number) => {
  return `${(size / 1024 / 1024).toFixed(2)} MB`;
};

export const generateIcsFile = (data: string, fileName: string) => {
  const url = window.URL.createObjectURL(new Blob([data]));
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', `${fileName}.ics`);
  document.body.appendChild(link);
  link.click();
  link.parentNode?.removeChild(link);
};

export const downloadURI = (uri: string, name: string) => {
  fetch(uri)
    .then((response) => response.blob())
    .then((blob) => {
      const link = document.createElement('a');
      link.href = URL.createObjectURL(blob);
      link.download = name;
      link.click();
    })
    .catch(() => {});
};

// this is for draft-js only
export const insertTextInCurrentPosition = (editorState: EditorState, text: string) => {
  const selection = editorState.getSelection();
  const newContent = Modifier.insertText(editorState.getCurrentContent(), selection, text);
  const newEditorState = EditorState.push(editorState, newContent, 'insert-characters');
  return newEditorState;
};

export const isJSON = (str: string) => {
  if (
    str &&
    /^[\],:{}\s]*$/.test(
      str
        .replace(/\\["\\\/bfnrtu]/g, '@')
        .replace(/"[^"\\\n\r]*"|true|false|null|-?\d+(?:\.\d*)?(?:[eE][+\-]?\d+)?/g, ']')
        .replace(/(?:^|:|,)(?:\s*\[)+/g, ''),
    )
  )
    return true;
  return false;
};

export const editorContentToText = (content: string) => {
  // checking if the content is json
  // if not return the content as it is
  if (isJSON(content)) {
    const rawContent = convertFromRaw(JSON.parse(content));

    const { entityMap, blocks } = convertToRaw(rawContent);

    const mentionArray = Object.entries(entityMap).reduce(
      (acc, [key, entity]) => {
        if (entity.type === 'mention') {
          const { value, name }: { value: string; name: string } = entity.data.mention;
          acc.push({ value, name });
        }

        return acc;
      },
      [] as { value: string; name: string }[],
    );

    let latestPlainText = blocks.map((block) => block.text).join('\n');

    mentionArray.forEach(({ value, name }) => {
      if (latestPlainText.includes(name)) {
        latestPlainText = latestPlainText.replace(
          name,
          `<a href='${WEB_ROOT}${value}' target='_blank' class='text-functional-info-400 hover:underline rounded'>@${name}</a>`,
        );
      }
    });

    return latestPlainText;
  }
  return content;
};

export const sanitizeHtml = (html: string) => {
  return DOMPurify.sanitize(html, { ADD_ATTR: ['target'] });
};

// export const convertHtmlToLink = (str: string) => {
//   let content = str;
//   // convert link without href tags to <a href
//   const regex = /((http|https|ftp):\/\/)?(www\.)?[a-zA-Z0-9\-\.]+\.[a-zA-Z]{2,3}(\/\S*)?/g;
//   const matches = content.match(regex);
//   if (matches) {
//     matches.forEach((match) => {
//       if (!match.includes('href')) {
//         const newMatch = match.replace(
//           match,
//           `<a href='${match}' target='_blank' class='text-functional-info-dark'>${match}</a>`,
//         );
//         content = content.replace(match, newMatch);
//       }
//     });
//   }
//   return content;
// };

export const convertHtmlToLink = (html: string) => {
  let replacedText = html;

  const replaceTxtNotInA = (value: string, regex: RegExp, replace: string) => {
    let html = value;
    // just to make the txt parse easily, without (start) or (ends) issue
    html = `>${html}<`;

    // parse txt between > and < but not follow with</a
    html = html.replace(/>([^<>]+)(?!<\/a)</g, (match, txt) => {
      // now replace the txt
      return `>${txt.replace(regex, replace)}<`;
    });

    // remove the head > and tail <
    return html.substring(1, html.length - 1);
  };

  const exp =
    /(\b(https?|ftp|file):\/\/[-A-Z0-9+&amp;&@#\/%?=~_|!:,.;]*[-A-Z0-9+&amp;&@#\/%=~_|])/gi;

  replacedText = replaceTxtNotInA(
    replacedText,
    exp,
    '<a href="$1" target="_blank" class="text-functional-info-400 hover:underline">$1</a>',
  );

  // URLs starting with "www."
  const exp2 = /\b(www\.[\S]+)\b/gi;
  replacedText = replaceTxtNotInA(replacedText, exp2, '<a href="http://$1" target="_blank">$1</a>');

  return replacedText;
};

export const isViewableFile = (fileName: string) => {
  if (!fileName) return false;

  const allowedExtensions = [
    'bmp',
    'csv',
    'odt',
    'doc',
    'docx',
    'gif',
    'htm',
    'html',
    'jpg',
    'jpeg',
    'pdf',
    'png',
    'ppt',
    'pptx',
    'tiff',
    'txt',
    'xls',
    'xlsx',
    'mp4',
    'webm',
    'ogv',
    'mp3',
    'm3u8',
    'mpd',
    'mov',
  ];

  const decodedFileName = decodeURIComponent(fileName);
  const cleanedFileName = decodedFileName.replace(/["]/g, '');
  const parts = cleanedFileName.split('.');
  const ext = parts.length > 1 ? parts.pop()?.toLowerCase() : null;

  return ext ? allowedExtensions.includes(ext) : false;
};
