import React from 'react';

interface ISelect extends React.SelectHTMLAttributes<HTMLSelectElement> {
  cSize?: 'small' | 'default';
  options: { value: string | number; label: string }[];
}

function Select({ cSize = 'default', options, className = '', ...rest }: ISelect) {
  return (
    <select
      className={`w-full bg-neutral-200 text-base text-neutral-500 font-medium border border-neutral-300 rounded-lg cursor-pointer outline-none ${
        cSize === 'small' ? 'px-3 py-1' : 'p-3'
      } ${className}`}
      {...rest}
    >
      {options.map(({ value, label }) => (
        <option key={value} value={value}>
          {label}
        </option>
      ))}
    </select>
  );
}

export default Select;
