import { useInfiniteQuery } from '@tanstack/react-query';
import { getArchivedPrograms } from 'utils/api/program';

export const useArchivedPrograms = (limit?: number) => {
  const { data, refetch, hasNextPage, fetchNextPage, isLoading, isFetching } = useInfiniteQuery({
    queryKey: ['archived-programs'],
    queryFn: ({ pageParam = '' }) => getArchivedPrograms(limit, pageParam),
    getNextPageParam: (res) => res.data.cursor,
  });

  return {
    programs: data?.pages.flatMap((page) => page.data.programs) ?? [],
    refetchPrograms: refetch,
    hasNextPrograms: hasNextPage,
    fetchNextPrograms: fetchNextPage,
    isProgramsLoading: isLoading,
    isProgramsFetching: isFetching,
  };
};
