import FolderIcon from 'assets/icons/folder.svg';
import CalendarIcon from 'assets/icons/calendar.svg';
import ClassIcon from 'assets/icons/class.svg';
import MemberIcon from 'assets/icons/members.svg';
import DashboardIcon from 'assets/icons/dashboard.svg';
import { TFunction } from 'i18next';
import { useHashtags } from 'hooks/api/program/useMyPrograms';
import { useParams } from 'react-router-dom';
import { useCommunity } from 'hooks/api/community/useCommunity';
import { useSingleProgram } from 'hooks/api/program/useSingleProgram';
import { useProgramGroups } from 'hooks/api/group/useProgramGroups';
import { generateOriginLink } from 'utils/url';

export const getSideNavLinks = (t: TFunction) => {
  //determine if we are under space or group (progranm / community) then get program data
  //though community or program data itself, so we can get the sublinks of the space / group
  //if we are not under program or community, then we use hashtages
  const { communityId, programId } = useParams();

  let groupParentId = '';
  if (communityId && !programId) {
    const { data: community, isLoading: isCommuntiyLoading } = useCommunity(communityId || '');
    if (!isCommuntiyLoading && community?.groupParent) {
      groupParentId = community.groupParent?.id;
    }
  }
  const { data: program, isLoading: isProgramLoading } = useSingleProgram(
    programId || groupParentId,
  );

  const { groups } = useProgramGroups(programId || groupParentId);

  const { hashtags, refetchHashtags } = useHashtags();

  let subLinks = [];
  if (!communityId && !programId) {
    subLinks = hashtags.map((hashtag, index) => ({
      id: `hashtag-${index}`,
      label: hashtag,
      to: `/programs?hashtag=${encodeURIComponent(hashtag)}`,
    }));
  } else if (!isProgramLoading && program) {
    const programlinks = {
      id: `program-${program.id}`,
      label: program.name,
      to: `${generateOriginLink(program.id, 'program')}`,
    };
    subLinks.push(programlinks);

    if (groups) {
      groups.forEach((group) => {
        const link = {
          id: `group-${group.id}`,
          label: '/' + group.name,
          to: `${generateOriginLink(group.id, 'community')}`,
        };
        subLinks.push(link);
      });
    }
  }
  return [
    {
      id: 0,
      to: '/programs',
      label: t('spaces'),
      Icon: ClassIcon,
      subLinks,
    },
    {
      id: 1,
      to: '/calendar',
      label: t('calendar'),
      Icon: CalendarIcon,
    },
    {
      id: 2,
      to: '/resources',
      label: t('resources'),
      Icon: FolderIcon,
    },
    {
      id: 3,
      to: '/members',
      label: t('members'),
      Icon: MemberIcon,
    },
    {
      id: 4,
      to: '/admin-dashboard',
      label: t('admin-panel'),
      Icon: DashboardIcon,
    },
  ];
};
