import { Tab } from '@headlessui/react';
import BackIcon from 'assets/icons/back.svg';
import CalendarIcon from 'assets/icons/calendar.svg';
import ImageIcon from 'assets/icons/image.svg';
import LocationIcon from 'assets/icons/location.svg';
import OnlineIcon from 'assets/icons/video-camera.svg';
import FileIcon from 'assets/icons/upload.svg';
import SearchIcon from 'assets/icons/search.svg';
import Button from 'components/button/Button';
import DateTimeInput from 'components/form/DateTimeInput';
import Select from 'components/form/Select';
import SearchInput from 'components/layout/SearchInput';
import { userAvatar } from 'constants/common';
import { useSearch } from 'hooks/api/search/useSearch';
import TextInput from 'components/form/TextInput';
import Layout from 'components/layout/Layout';
import BodyText from 'components/typography/BodyText';
import { eventImage } from 'constants/common';
import RichTextEditor from 'components/common/RichTextEditorWithUpload';
import dayjs from 'dayjs';
import dayjstimezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import React, { useEffect, useRef, useState, useMemo } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { EventFormInputType } from 'types/components/form';
import { objToFormData } from 'utils/rest';
import { timezones } from 'utils/timezones';
import { OutputData } from '@editorjs/editorjs';
import ImageSearchModal from 'components/modal/ImageSearchModal';
import { DropdownMenu } from 'components/common/DropdownMenu';

dayjs.extend(utc);
dayjs.extend(dayjstimezone);

interface IEditEventForm {
  eventLink: string;
  eventTitle: string;
  eventEndTime: string;
  eventLocation: string;
  eventTimezone: string;
  eventIsAllDay: boolean;
  eventStartTime: string;
  eventDescription: string;
  eventContactUserAvatar?: string;
  eventContactUserId?: string;
  eventContactUserFirstName?: string;
  eventContactUserLastName?: string;
  eventCover: null | string;
  eventType: 'inperson' | 'online';
  handleEditEvent: (data: FormData) => void;
}

const EditEventForm = ({
  eventType,
  eventLink,
  eventTitle,
  eventCover,
  eventIsAllDay,
  eventEndTime,
  eventTimezone,
  eventLocation,
  eventStartTime,
  eventContactUserAvatar,
  eventContactUserId,
  eventContactUserFirstName,
  eventContactUserLastName,
  handleEditEvent,
  eventDescription,
}: IEditEventForm) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const coverInputRef = useRef<HTMLInputElement>(null);
  const [allDayEvent, setAllDayEvent] = useState(eventIsAllDay);
  const [aboutText, setAboutText] = useState<OutputData | null>(null);
  const [showImageSearchModal, setShowImageSearchModal] = useState(false);
  const [onSelectImage, setOnSelectImage] = useState<string | null>(eventCover);
  const [showTimezone, setShowTimezone] = useState(false);
  const [resetEditor, setResetEditor] = useState(false);
  const {
    watch,
    control,
    register,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm<EventFormInputType>();

  const handleEventType = (value: number) => {
    if (value === 0) {
      setValue('type', 'online');
    } else {
      setValue('type', 'inperson');
    }
  };

  const onSubmit: SubmitHandler<EventFormInputType> = (data) => {
    const { date, startTime, endTime, cover, timeZone, ...rest } = data;

    if (!date || !startTime || !endTime) return;
    let completeStartTime;
    let completeEndTime;
    if (allDayEvent) {
      completeStartTime = timeZone
        ? dayjs.tz(`${date} 00:00`, 'YYYY-MM-DD HH:mm', timeZone)
        : dayjs(`${date} 00:00`);
    } else {
      completeStartTime = timeZone
        ? dayjs.tz(`${date} ${startTime}`, 'YYYY-MM-DD HH:mm', timeZone)
        : dayjs(`${date} ${startTime}`);
    }

    if (allDayEvent) {
      completeEndTime = timeZone
        ? dayjs.tz(`${date} 23:59:00`, 'YYYY-MM-DD HH:mm', timeZone)
        : dayjs(`${date} 23:59:00`);
    } else {
      completeEndTime = timeZone
        ? dayjs.tz(`${date} ${endTime}`, 'YYYY-MM-DD HH:mm', timeZone)
        : dayjs(`${date} ${endTime}`);
    }

    const newData = {
      ...rest,
      timeZone,
      startTime: completeStartTime.toDate().toISOString(),
      endTime: completeEndTime.toDate().toISOString(),
      isAllDay: allDayEvent.toString(),
    };
    if (addContactMember && contactUserId) {
      newData.contactUserId = contactUserId;
    }
    newData.about = aboutText ? JSON.stringify(aboutText) : eventDescription;
    const formData = objToFormData(newData);

    if (cover) {
      formData.append('cover', cover[0]);
    }
    if (onSelectImage) {
      formData.append('coverImage', onSelectImage);
    }

    handleEditEvent(formData);
  };

  const handleSelectCover = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files?.length) return;
    setValue('cover', e.target.files);
  };

  /*search member code */
  const [searchString, setSearchString] = useState('');
  const [contactUserFirstName, setContactUserFirstName] = useState<string | null>(
    eventContactUserFirstName || null,
  );
  const [contactUserLastName, setContactUserLastName] = useState<string | null>(
    eventContactUserLastName || null,
  );
  const [contactUserAvatar, setContactUserAvatar] = useState<string | null>(
    eventContactUserAvatar || null,
  );
  const [contactUserId, setContactUserId] = useState<string | null>(eventContactUserId || null);
  const [addContactMember, setAddContactMember] = useState(contactUserId !== null);
  const { users } = useSearch('user', searchString);
  const typeOptions = useMemo(() => [{ value: 'user', label: t('user') }], [t]);
  const handleSearch = ({ value }: { value: string }) => {
    setSearchString(value);
  };
  const onSearchResultClick = (id: string) => {
    setContactUserId(id);
    setContactUserFirstName(users?.find((user) => user.userId === id)?.firstName || null);
    setContactUserLastName(users?.find((user) => user.userId === id)?.lastName || null);
    setContactUserAvatar(users?.find((user) => user.userId === id)?.profilePicture || null);
    setSearchString('');
  };
  const searchResult = useMemo(() => {
    if (searchString === '') return [];
    if (users && users.length > 0) {
      return users.map(({ userId, firstName, lastName, profilePicture }) => ({
        id: userId,
        title: `${firstName} ${lastName}`,
        subTitle: 'Member',
        logo: profilePicture || userAvatar,
      }));
    }
    return [];
  }, [users, searchString]);

  useEffect(() => {
    setValue('title', eventTitle);
    setValue('type', eventType);
    setValue('zoomLink', eventLink);
    setValue('location', eventLocation);
    if (eventCover) {
      setValue('cover', eventCover);
    }
    setValue(
      'date',
      dayjs(eventStartTime)
        .tz(eventTimezone || Intl.DateTimeFormat().resolvedOptions().timeZone)
        .format('YYYY-MM-DD'),
    );
    setValue(
      'startTime',
      dayjs(eventStartTime)
        .tz(eventTimezone || Intl.DateTimeFormat().resolvedOptions().timeZone)
        .format('HH:mm'),
    );
    setValue(
      'endTime',
      dayjs(eventEndTime)
        .tz(eventTimezone || Intl.DateTimeFormat().resolvedOptions().timeZone)
        .format('HH:mm'),
    );
    setValue('timeZone', eventTimezone || Intl.DateTimeFormat().resolvedOptions().timeZone);
  }, []);

  return (
    <Layout>
      <div className='w-full md:py-4 md:px-5 py-1 px-2 h-full overflow-y-auto'>
        <div className='mb-2 flex justify-between px-3'>
          <div></div>
          <Button
            size='default'
            onClick={() => {
              navigate(-1);
            }}
            variant='outlined'
            className='text-functional-danger-400 p-[8px]'
            tooltip={t('back')}
          >
            <BackIcon className='h-4 w-4' />
          </Button>
        </div>

        <div className='mb-5 rounded-[20px] bg-neutral-200 shadow-lg shadow-neutral-300'>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className='rounded-[20px] p-5 '>
              <div className='relative'>
                {watch('cover') ? (
                  // already has a cover
                  typeof watch('cover') === 'string' ? (
                    <img
                      alt=''
                      src={watch('cover') as string}
                      className='h-[226px] w-full rounded-lg object-cover'
                    />
                  ) : (
                    // locally selected cover
                    <img
                      alt=''
                      className='h-[226px] w-full rounded-lg object-cover'
                      src={URL.createObjectURL((watch('cover') as FileList)[0])}
                    />
                  )
                ) : (
                  // stock or default event cover
                  <img
                    src={onSelectImage ? onSelectImage : eventImage}
                    alt=''
                    className='h-[226px] w-full rounded-lg object-cover'
                  />
                )}

                <DropdownMenu
                  menuButton={
                    <Button
                      size='small'
                      type='button'
                      variant='primary'
                      className='absolute bottom-4 right-4'
                    >
                      <ImageIcon className='h-4 w-4' /> {t('change_cover')}
                    </Button>
                  }
                  menuItems={[
                    {
                      key: 'upload_cover',
                      label: t('upload-image'),
                      icon: FileIcon,
                      callback: () => coverInputRef.current?.click(),
                    },
                    {
                      key: 'share_to_web',
                      label: t('search-stock-image'),
                      icon: SearchIcon,
                      callback: () => setShowImageSearchModal(true),
                    },
                  ]}
                />

                <input
                  type='file'
                  className='hidden'
                  {...register('cover', {
                    onChange: (e) => handleSelectCover(e),
                  })}
                  ref={coverInputRef}
                  accept='image/png, image/jpeg'
                />
              </div>
            </div>

            <div className='space-y-4 px-5 py-5'>
              <div className='space-y-1'>
                <BodyText
                  variant='sm'
                  className={`font-medium ${
                    errors.title ? 'text-functional-danger-400' : 'text-neutral-500'
                  } `}
                >
                  {t('title')}
                </BodyText>
                <Controller
                  name='title'
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <TextInput
                      {...field}
                      type='text'
                      placeholder={t('title') || 'Title'}
                      className={errors.title && 'border-functional-danger-400'}
                    />
                  )}
                />
              </div>

              <div className='space-y-1'>
                <BodyText variant='sm' className={`font-medium  `}>
                  {t('about')}
                </BodyText>
                <div className='ml-7'>
                  <RichTextEditor
                    initialContent={eventDescription}
                    handleOnChange={(content) => setAboutText(content)}
                    isReadOnly={false}
                    resetEditor={resetEditor}
                  />
                </div>
              </div>
              <div className='mb-5 space-y-3'>
                <div className='flex items-center'>
                  <input
                    type='checkbox'
                    id='addContactMember'
                    checked={addContactMember}
                    onChange={(e) => {
                      setAddContactMember(e.target.checked);
                    }}
                    className='h-5 w-5 rounded border-neutral-300 text-primary-500 focus:ring-primary-500'
                  />
                  <BodyText variant='sm' className={`ml-3 font-medium `}>
                    {t('add-contact-member')}
                  </BodyText>
                </div>
                {addContactMember && (
                  <div>
                    <SearchInput
                      onSearch={handleSearch}
                      typeOptions={typeOptions}
                      searchResult={searchResult}
                      onOptionClick={onSearchResultClick}
                    />
                  </div>
                )}
                {contactUserId && addContactMember && (
                  <div className='flex items-center gap-3'>
                    <img
                      src={contactUserAvatar || userAvatar}
                      alt=''
                      className='h-5 w-5 rounded-full'
                    />
                    <div>
                      <BodyText variant='sm' className='font-medium'>
                        {contactUserFirstName} {contactUserLastName}
                      </BodyText>
                      <BodyText variant='xs' className='text-neutral-500'>
                        {t('member.type.member')}
                      </BodyText>
                    </div>
                  </div>
                )}
              </div>
              <br />
              <BodyText
                variant='lg'
                className='mb-4 flex items-center gap-2 font-medium text-neutral-500'
              >
                <OnlineIcon className='h-5 w-5' /> {t('event_type_title')}
              </BodyText>

              <Tab.Group
                selectedIndex={watch('type') === 'online' ? 0 : 1}
                onChange={(value) => handleEventType(value)}
              >
                <Tab.List className='w-fit rounded-md bg-secondary-200 p-[2px]'>
                  <Tab className='outline-none'>
                    {({ selected }) => (
                      <Button
                        type='button'
                        size='default'
                        variant={selected ? 'secondary' : 'default'}
                        className='w-full md:w-[200px]'
                      >
                        <OnlineIcon className='h-5 w-5' /> {t('online')}
                      </Button>
                    )}
                  </Tab>
                  <Tab className='outline-none'>
                    {({ selected }) => (
                      <Button
                        type='button'
                        size='default'
                        variant={selected ? 'secondary' : 'default'}
                        className='w-full md:w-[200px]'
                      >
                        <LocationIcon className='h-5 w-5' /> {t('in_person')}
                      </Button>
                    )}
                  </Tab>
                </Tab.List>
              </Tab.Group>

              <BodyText
                variant='lg'
                className='mb-4 flex items-center gap-2 font-medium text-neutral-500'
              >
                <CalendarIcon className='h-5 w-5' /> {t('event_date_title')}
              </BodyText>

              {watch('type') === 'online' && (
                <div className='space-y-1'>
                  <BodyText
                    variant='sm'
                    className={`font-medium ${
                      errors.zoomLink ? 'text-functional-danger-400' : 'text-neutral-500'
                    } `}
                  >
                    {t('event_link')}
                  </BodyText>
                  <Controller
                    name='zoomLink'
                    control={control}
                    rules={{ required: true }}
                    render={({ field }) => (
                      <TextInput
                        {...field}
                        type='text'
                        placeholder='https://meet.google.com/jdo-yhem-uyg'
                        className={errors.zoomLink && 'border-functional-danger-400'}
                      />
                    )}
                  />
                </div>
              )}

              {watch('type') === 'inperson' && (
                <div className='space-y-1'>
                  <BodyText
                    variant='sm'
                    className={`font-medium ${
                      errors.location ? 'text-functional-danger-400' : 'text-neutral-500'
                    } `}
                  >
                    {t('address')}
                  </BodyText>
                  <Controller
                    name='location'
                    control={control}
                    rules={{ required: true }}
                    render={({ field }) => (
                      <TextInput
                        {...field}
                        type='text'
                        placeholder='Potsdamer Platz Street, Berlin, Germany'
                        className={errors.location && 'border-functional-danger-400'}
                      />
                    )}
                  />
                </div>
              )}

              <div className='space-y-1'>
                <BodyText
                  variant='sm'
                  className={`font-medium ${
                    errors.date ? 'text-functional-danger-400' : 'text-neutral-500'
                  } `}
                >
                  {t('date')}
                </BodyText>
                <Controller
                  name='date'
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <DateTimeInput
                      {...field}
                      type='date'
                      className={errors.date && 'border-functional-danger-400'}
                    />
                  )}
                />
              </div>
              <div className='flex items-center gap-3'>
                <input
                  type='checkbox'
                  name='allDayEvent'
                  checked={allDayEvent}
                  onChange={(e) => {
                    setAllDayEvent(e.target.checked);
                  }}
                  className='h-5 w-5 rounded border-neutral-300 text-primary-500 focus:ring-primary-500'
                />
                <BodyText variant='sm' className='font-medium text-neutral-500'>
                  {t('all_day_event')}
                </BodyText>
              </div>
              {!allDayEvent && (
                <div className='grid grid-cols-2 gap-4'>
                  <div className='space-y-1'>
                    <BodyText
                      variant='sm'
                      className={`font-medium ${
                        errors.startTime ? 'text-functional-danger-400' : 'text-neutral-500'
                      } `}
                    >
                      {t('start_time')}
                    </BodyText>
                    <Controller
                      name='startTime'
                      control={control}
                      rules={{ required: true }}
                      render={({ field }) => (
                        <DateTimeInput
                          {...field}
                          type='time'
                          className={errors.startTime && 'border-functional-danger-400'}
                        />
                      )}
                    />
                  </div>

                  <div className='space-y-1'>
                    <BodyText
                      variant='sm'
                      className={`font-medium ${
                        errors.endTime ? 'text-functional-danger-400' : 'text-neutral-500'
                      } `}
                    >
                      {t('end_time')}
                    </BodyText>
                    <Controller
                      name='endTime'
                      control={control}
                      rules={{
                        required: true,
                        // eslint-disable-next-line consistent-return
                        validate: (val: string) => {
                          const endTime = new Date(`1970-01-02 ${val}`);
                          const startTime = new Date(`1970-01-02 ${watch('startTime')}`);

                          if (startTime > endTime)
                            return 'End time must be greater then Start Time';
                        },
                      }}
                      render={({ field }) => (
                        <DateTimeInput
                          {...field}
                          type='time'
                          className={errors.endTime && 'border-functional-danger-400'}
                        />
                      )}
                    />
                  </div>
                </div>
              )}
              <div>
                {watch('timeZone') ? (
                  <BodyText as='span' variant='sm'>
                    {watch('timeZone')}
                  </BodyText>
                ) : (
                  <BodyText as='span' variant='sm'>
                    {Intl.DateTimeFormat().resolvedOptions().timeZone}
                  </BodyText>
                )}{' '}
                --{' '}
                <button
                  type='button'
                  className='text-secondary-400'
                  onClick={() => setShowTimezone(true)}
                >
                  <BodyText as='span' variant='sm'>
                    {t('change')}
                  </BodyText>
                </button>
              </div>

              {showTimezone && (
                <div className='space-y-1'>
                  <BodyText
                    variant='sm'
                    className={`font-medium ${
                      errors.timeZone ? 'text-functional-danger-400' : 'text-neutral-500'
                    } `}
                  >
                    {t('timezone')}
                  </BodyText>
                  <Controller
                    name='timeZone'
                    control={control}
                    rules={{ required: true }}
                    render={({ field }) => (
                      <Select
                        {...field}
                        options={timezones}
                        className={errors.timeZone && 'border-functional-danger-400'}
                      />
                    )}
                  />
                </div>
              )}
            </div>

            <div className='flex items-center justify-end gap-4 p-5 pb-8 pt-4'>
              <Button
                type='button'
                size='default'
                variant='outlined'
                onClick={() => {
                  setResetEditor(true);
                  navigate(-1);
                }}
              >
                {t('cancel')}
              </Button>
              <Button type='submit' size='default' variant='secondary'>
                {t('save')}
              </Button>
            </div>
          </form>
        </div>
      </div>
      <ImageSearchModal
        isOpen={showImageSearchModal}
        onClose={() => {
          setShowImageSearchModal(false);
        }}
        onImageSelect={(url) => {
          setValue('cover', '');
          setOnSelectImage(url);
        }}
      />
    </Layout>
  );
};

export default EditEventForm;
