import { useInfiniteQuery } from '@tanstack/react-query';
import { getProgramPosts } from 'utils/api/post';
import { inMilliseconds } from 'utils/helper';

export const getKey = (programId: string) => ['program-posts', programId] as const;

const useProgramPosts = (programId: string, limit = 5) => {
  const { data, hasNextPage, fetchNextPage, refetch, isFetchingNextPage, isLoading } =
    useInfiniteQuery({
      queryKey: getKey(programId),
      queryFn: ({ pageParam = '' }) => getProgramPosts(programId, limit, pageParam),
      getNextPageParam: (lastPage) => lastPage.data.cursor,
      staleTime: inMilliseconds(5),
    });

  return {
    posts: data?.pages.flatMap((item) => item.data.posts) ?? [],
    hasNextPosts: hasNextPage,
    fetchNextPosts: fetchNextPage,
    refetchPosts: refetch,
    isFetchingNextPosts: isFetchingNextPage,
    isPostsLoading: isLoading,
  };
};

export default useProgramPosts;
