import Layout from 'components/layout/Layout';
import useIsLoggedIn from 'hooks/api/auth/useIsLoggedIn';
import BackIcon from 'assets/icons/back.svg';
import Heading from 'components/typography/Heading';
import React from 'react';
import Button from 'components/button/Button';
import { useParams, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useMemberProfileInfo } from 'hooks/api/profile/useMemberProfileInfo';
import Loading from 'components/Loading';
import NavigateTo404 from 'components/NavigateTo404';
import MyProfile from './segment/profile/MyProfile';
import MemberProfile from './segment/profile/MemberProfile';

const ProfilePage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { memberId } = useParams();
  const { data: userInfo, isLoading: isUserInfoLoading } = useIsLoggedIn();
  const { data: memberInfo, isLoading: isMemberInfoLoading } = useMemberProfileInfo(memberId || '');

  if (isUserInfoLoading || isMemberInfoLoading) return <Loading />;
  if (!userInfo && !memberInfo) return <NavigateTo404 />;

  return (
    <Layout>
      <div className='mb-5 p-4'>
        <div className='flex items-center justify-between pb-3'>
          <Heading variant='h3' className='font-semibold capitalize'>
            {t('profile')}
          </Heading>
          <Button
            size='default'
            onClick={() => {
              navigate(-1);
            }}
            variant='outlined'
            className='text-functional-danger-400 p-[8px]'
            tooltip={t('back')}
          >
            <BackIcon className='h-4 w-4' />
          </Button>
        </div>
        {memberId && userInfo?.id !== memberId ? (
          <MemberProfile memberId={memberId} />
        ) : (
          <MyProfile />
        )}
      </div>
    </Layout>
  );
};

export default ProfilePage;
