import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import ModalContainer from './ModalContainer';
import { useProgramInviteLinks } from 'hooks/api/member/useProgramInviteLinks';
import { useCommunityInviteLinks } from 'hooks/api/member/useCommunityInviteLinks';
import BodyText from 'components/typography/BodyText';
import Button from 'components/button/Button';
import { notifySuccess } from 'utils/notify';
import { generateInviteLink } from 'utils/url';

interface IInviteLinkModal {
  isOpen: boolean;
  onClose: () => void;
  originId: string;
  originType: string;
  orgId?: string;
}

const InviteLinkModal = ({ isOpen, originId, originType, orgId, onClose }: IInviteLinkModal) => {
  const { t } = useTranslation();

  const programInviteLinks = useProgramInviteLinks(originId || '', originType);
  const communityInviteLinks = useCommunityInviteLinks(originId || '', originType);
  const isLoading = programInviteLinks.isLoading || communityInviteLinks.isLoading;
  const inviteLinks =
    originType === 'program' ? programInviteLinks.data : communityInviteLinks.data;
  const [copiedState, setCopiedState] = useState<Record<string, boolean>>({});
  const copyToClipboard = (text: string, id: string) => {
    setCopiedState({ ...copiedState, [id]: true });
    navigator.clipboard.writeText(text);
    notifySuccess(t('invite-copied'));
  };
  const formatDate = (date: string) => {
    const expiresAtDate = new Date(date);
    const today = new Date();
    const diff = Math.ceil((expiresAtDate.getTime() - today.getTime()) / (1000 * 3600 * 24));
    return diff;
  };

  return (
    <ModalContainer title={t('invitation-link')} isOpen={isOpen} onClose={onClose}>
      <table className='w-full table-auto items-center justify-center '>
        <thead>
          <tr>
            <th className='min-w-[100px] border-b px-4 py-2'>{t('url')}</th>
            <th className='border-b py-2'>{t('view_count')}</th>
            <th className='border-b py-2'>{t('accepted_count')}</th>
            <th className='border-b px-4 py-2'></th>
          </tr>
        </thead>
        <tbody>
          {!isLoading &&
            inviteLinks &&
            inviteLinks.map((inviteLink, index) => {
              const isProgram = 'programId' in inviteLink;
              const targetType = isProgram ? 'program' : 'community';
              const targetId = isProgram ? inviteLink.programId : inviteLink.communityId;
              const { id, token, expiresAt, clicks, acceptCount, isOpenEnded } = inviteLink;
              const inviteUrl = generateInviteLink(
                token,
                targetId,
                targetType,
                orgId ? orgId : undefined,
              );

              return (
                <tr key={id}>
                  <td className='px-4 py-2'>
                    <div className='flex flex-col '>
                      <BodyText variant='sm' className='font-bold text-secondary-500'>
                        <a href={inviteUrl} target='_blank' rel='noopener noreferrer'>
                          Link {index + 1}
                        </a>
                      </BodyText>
                      <BodyText variant='xs' className='font-medium text-neutral-500'>
                        {isOpenEnded ? (
                          <span>No expiration</span>
                        ) : (
                          <span>Expires in {formatDate(expiresAt)} days</span>
                        )}
                      </BodyText>
                    </div>
                  </td>
                  <td className='px-4 py-2'>{Math.round(clicks / 1.8)}</td>
                  <td className='px-4 py-2'>{acceptCount}</td>
                  <td className=' px-4 py-2'>
                    <Button
                      size='small'
                      variant='primary'
                      onClick={() => copyToClipboard(inviteUrl, id)}
                    >
                      {copiedState[id] ? t('copied') : t('copy')}
                    </Button>
                  </td>
                </tr>
              );
            })}
        </tbody>
      </table>

      <div className='mt-5 flex justify-end gap-3 border-t border-neutral-200 pt-5'>
        <Button type='button' size='small' variant='secondary' onClick={onClose}>
          {t('close')}
        </Button>
      </div>
    </ModalContainer>
  );
};

export default InviteLinkModal;
