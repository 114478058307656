import React, { useState } from 'react';
import IconButton from 'components/layout/IconButton';
import MessageIcon from 'assets/icons/message.svg';
import Drawer from 'components/Drawer';
import { useHasUnread } from 'hooks/api/message/useHasUnread';
import MessageSidebar from 'components/message/MessageSidebar';

export const MessageSidebarWithTrigger = () => {
  const [isOpen, setIsOpen] = useState(false);
  const { unreadCount } = useHasUnread();

  return (
    <>
      <Drawer isOpen={isOpen} setIsOpen={setIsOpen}>
        <MessageSidebar
          isOpen={isOpen}
          close={() => {
            setIsOpen(false);
          }}
        />
      </Drawer>
      <IconButton
        className='neutral-button text-neutral-500'
        onClick={() => {
          setIsOpen(true);
        }}
        badgeCount={unreadCount}
        Icon={MessageIcon}
      />
    </>
  );
};
