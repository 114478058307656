/* eslint-disable import/no-duplicates */
import Button from 'components/button/Button';
import DateTimeInput from 'components/form/DateTimeInput';
import RichTextEditor from 'components/common/RichTextEditorWithUpload';
import TextInput from 'components/form/TextInput';
import BodyText from 'components/typography/BodyText';
import dayjs from 'dayjs';
import React, { useEffect, useState, useMemo } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { TaskFormInputType } from 'types/components/form';
import ModalContainer from './ModalContainer';
import SearchInput from 'components/layout/SearchInput';
import { userAvatar } from 'constants/common';
import { useSearch } from 'hooks/api/search/useSearch';
import { OutputData } from '@editorjs/editorjs';
import SelectTag from 'components/form/SelectTag';
import { TagDetails } from 'types/components/form';

interface IDuplicateTaskModal {
  isOpen: boolean;
  onClose: () => void;
  originType: string;
  originId: string;
  taskTitle: string;
  taskDeadline: string;
  taskDescription: string;
  taskHasDeadline: boolean;
  taskSoftDeadline: boolean;
  handleAddTask: (taskData: {
    title: string;
    about: string;
    deadline: string;
    contactUserId: any;
    assignee: string | string[] | undefined;
  }) => void;
}

const DuplicateTaskModal = ({
  isOpen,
  onClose,
  originType,
  originId,
  taskTitle,
  taskHasDeadline,
  taskDeadline,
  taskDescription,
  taskSoftDeadline,
  handleAddTask,
}: IDuplicateTaskModal) => {
  const { t } = useTranslation();
  const [resetEditor, setResetEditor] = useState(false);
  const handleClose = async () => {
    setResetEditor(true);
    await new Promise((resolve) => setTimeout(resolve, 10));
    onClose();
  };
  const {
    control,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm<TaskFormInputType>();
  const [softDeadline, setSoftDeadline] = useState(true);
  const [aboutText, setAboutText] = useState<OutputData | null>(null);
  const [hasDeadline, setHasDeadline] = useState(true);
  const [selectedUserIds, setSelectedUserIds] = useState<string[]>([]);
  const handleSelectUsers = (userIds: string[]) => {
    setSelectedUserIds(userIds);
  };

  const [assigneeType, setAssigneeType] = useState('all');
  const [assigneeError, setAssigneeError] = useState(false);

  const onSubmit: SubmitHandler<TaskFormInputType> = (data) => {
    // Reset assignee error state on each submission attempt
    setAssigneeError(false);
    const { date, time, ...rest } = data;
    let deadline;
    if (hasDeadline) {
      const mergedDate = `${date}T${time}`;
      deadline = new Date(mergedDate).toJSON();
    } else {
      deadline = new Date('9999-12-31T23:59:59Z').toJSON();
    }
    const taskData = {
      ...rest,
      deadline,
      hasDeadline,
      softDeadline,
      assignee:
        assigneeType === 'custom' && selectedUserIds.length > 0
          ? JSON.stringify(selectedUserIds)
          : 'all',
    };
    taskData.about = aboutText ? JSON.stringify(aboutText) : taskDescription;
    if (addContactMember && contactUserId) {
      taskData.contactUserId = contactUserId;
    }
    handleAddTask(taskData);
  };

  useEffect(() => {
    setValue('title', taskTitle);
    setValue('date', dayjs(taskDeadline).format('YYYY-MM-DD'));
    setValue('time', dayjs(taskDeadline).format('HH:mm'));
    setSoftDeadline(taskSoftDeadline);
    setHasDeadline(taskHasDeadline);
  }, []);

  /*search member code */
  const [searchString, setSearchString] = useState('');
  const [contactUserFirstName, setContactUserFirstName] = useState<string | null>(null);
  const [contactUserLastName, setContactUserLastName] = useState<string | null>(null);
  const [contactUserAvatar, setContactUserAvatar] = useState<string | null>(null);
  const [contactUserId, setContactUserId] = useState<string | null>(null);
  const [addContactMember, setAddContactMember] = useState(false);
  const { users } = useSearch('user', searchString);
  const typeOptions = useMemo(() => [{ value: 'user', label: t('user') }], [t]);
  const handleSearch = ({ value }: { value: string }) => {
    setSearchString(value);
  };
  const onSearchResultClick = (id: string) => {
    setContactUserId(id);
    setContactUserFirstName(users?.find((user) => user.userId === id)?.firstName || null);
    setContactUserLastName(users?.find((user) => user.userId === id)?.lastName || null);
    setContactUserAvatar(users?.find((user) => user.userId === id)?.profilePicture || null);
    setSearchString('');
  };
  const searchResult = useMemo(() => {
    if (searchString === '') return [];
    if (users && users.length > 0) {
      return users.map(({ userId, firstName, lastName, profilePicture }) => ({
        id: userId,
        title: `${firstName} ${lastName}`,
        subTitle: 'Member',
        logo: profilePicture || userAvatar,
      }));
    }
    return [];
  }, [users, searchString]);

  //code for assignee
  const [inputValue, setInputValue] = useState(''); // Track the current input value

  const handleInputChange = (value: string) => {
    setInputValue(value); // Update the input value state
    // Additional logic based on the current input value can go here
  };
  const handleSelectTag = (tags: TagDetails[]) => {
    // Extract user IDs from the tags
    const userIds = tags.map((tag) => tag.userId).filter((id) => id);
    setSelectedUserIds(userIds);
  };

  return (
    <ModalContainer title={t('duplicate_task')} isOpen={isOpen} onClose={handleClose}>
      <form onSubmit={handleSubmit(onSubmit)} className='space-y-5'>
        <div className='space-y-1'>
          <BodyText
            variant='sm'
            className={`font-medium ${
              errors.title ? 'text-functional-danger-400' : 'text-neutral-500'
            }`}
          >
            {t('title')}
          </BodyText>
          <Controller
            name='title'
            control={control}
            rules={{ required: true }}
            render={({ field }) => (
              <TextInput
                {...field}
                placeholder={t('title') || 'Enter Task Name'}
                className={errors.title && 'border-functional-danger-400'}
              />
            )}
          />
        </div>

        <div className='space-y-1'>
          <BodyText variant='sm' className={`font-medium `}>
            {t('description')}
          </BodyText>
          <div className='px-7'>
            <RichTextEditor
              initialContent={taskDescription}
              handleOnChange={(content) => setAboutText(content)}
              resetEditor={resetEditor}
            />
          </div>
        </div>
        <div className='space-y-1'>
          <div className='flex items-center gap-1'>
            <BodyText variant='sm' className={`font-medium`}>
              {t('assigned_to')}:
            </BodyText>
            <select
              value={assigneeType}
              onChange={(e) => setAssigneeType(e.target.value)}
              className='rounded-md neutral-button p-1 border-neutral-300 border bg-neutral-100'
            >
              <option value='all'>{t('all_members')}</option>
              <option value='custom'>{t('customize_member')}</option>
            </select>
          </div>
          {assigneeType === 'custom' && (
            <SelectTag
              {...(originType === 'community'
                ? { communityId: originId }
                : { programId: originId })}
              onValueChange={handleInputChange}
              onChange={handleSelectTag}
              className={`${assigneeError && 'border-functional-danger-400'}`}
            />
          )}
        </div>

        <div className='flex items-center gap-3'>
          <input
            type='checkbox'
            id='hasDeadline'
            checked={hasDeadline}
            onChange={(e) => {
              setHasDeadline(e.target.checked);
            }}
            className='h-5 w-5 rounded border-neutral-300 text-primary-500 focus:ring-primary-500'
          />
          <BodyText variant='sm' className='font-medium text-neutral-500'>
            {t('has_deadline')}
          </BodyText>
        </div>
        {hasDeadline && (
          <div className='space-y-2'>
            <div className='space-y-1'>
              <BodyText
                variant='sm'
                className={`font-medium ${
                  errors.date || errors.time ? 'text-functional-danger-400' : 'text-neutral-500'
                }`}
              >
                {t('deadline')}
              </BodyText>
              <div className='grid grid-cols-1 gap-5 xl:grid-cols-2'>
                <Controller
                  name='date'
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <DateTimeInput
                      {...field}
                      type='date'
                      className={errors.date && 'border-functional-danger-400'}
                    />
                  )}
                />
                <Controller
                  name='time'
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <DateTimeInput
                      {...field}
                      type='time'
                      className={errors.time && 'border-functional-danger-400'}
                    />
                  )}
                />
              </div>
            </div>
            <div className='flex items-center gap-3'>
              <input
                type='checkbox'
                id='softDeadline'
                checked={softDeadline}
                onChange={(e) => {
                  setSoftDeadline(e.target.checked);
                }}
                className='h-5 w-5 rounded border-neutral-300 text-primary-500 focus:ring-primary-500'
              />
              <BodyText variant='sm' className='font-medium text-neutral-500'>
                {' '}
                {t('soft_deadline')}
              </BodyText>
            </div>
          </div>
        )}
        <div className='mb-5 space-y-3'>
          <div className='flex items-center'>
            <input
              type='checkbox'
              id='addContactMember'
              checked={addContactMember}
              onChange={(e) => {
                setAddContactMember(e.target.checked);
              }}
              className='h-5 w-5 rounded border-neutral-300 text-primary-500 focus:ring-primary-500'
            />
            <BodyText
              variant='sm'
              className={`ml-3 font-medium ${
                errors.about ? 'text-functional-danger-400' : 'text-neutral-500'
              } `}
            >
              {t('add-contact-member')}
            </BodyText>
          </div>
          {addContactMember && (
            <div>
              <SearchInput
                onSearch={handleSearch}
                typeOptions={typeOptions}
                searchResult={searchResult}
                onOptionClick={onSearchResultClick}
              />
            </div>
          )}
          {contactUserId && addContactMember && (
            <div className='flex items-center gap-3'>
              <img src={contactUserAvatar || userAvatar} alt='' className='h-5 w-5 rounded-full' />
              <div>
                <BodyText variant='sm' className='font-medium'>
                  {contactUserFirstName} {contactUserLastName}
                </BodyText>
                <BodyText variant='xs' className='text-neutral-500'>
                  {t('member.type.member')}
                </BodyText>
              </div>
            </div>
          )}
        </div>
        <div className='mt-5 flex items-center justify-end gap-3 border-t border-neutral-200 pt-5'>
          <Button type='button' size='small' variant='outlined' onClick={handleClose}>
            {t('cancel')}
          </Button>
          <Button type='submit' size='small' variant='secondary'>
            {t('save')}
          </Button>
        </div>
      </form>
    </ModalContainer>
  );
};

export default DuplicateTaskModal;
