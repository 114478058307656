import DeleteIcon from 'assets/icons/delete.svg';
import { AxiosProgressEvent } from 'axios';
import Button from 'components/button/Button';
import UploadFileAreaButton from 'components/button/UploadFileAreaButton';
import TextInput from 'components/form/TextInput';
import BodyText from 'components/typography/BodyText';
import Uploading from 'components/Uploading';
import RichTextEditor from 'components/common/RichTextEditor';
import { OutputData } from '@editorjs/editorjs';
import { COMMON_FILE_SIZE_LIMIT } from 'constants/common';
import { useFileUploadProgress } from 'hooks/common/useFileUploadProgress';
import React, { useEffect, useRef, useState } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { TaskSubmitFormInputType } from 'types/components/form';
import { notifyError } from 'utils/notify';
import { objToFormData } from 'utils/rest';
import ModalContainer from './ModalContainer';
import PageSelection from '../common/PageSelection';

interface ISubmitTaskModal {
  isOpen: boolean;
  onClose: () => void;
  isEditing?: boolean;
  title?: string;
  note?: null | string;
  pageId?: string;
  handleSubmitTask: (
    data: FormData | { title: string; note: string; type: string },
    onUploadProgress: (progressEvent: AxiosProgressEvent) => void,
    setIsUploading: React.Dispatch<React.SetStateAction<boolean>>,
  ) => void;
}

const SubmitTaskModal = ({
  isOpen,
  onClose,
  isEditing,
  title,
  note,
  handleSubmitTask,
}: ISubmitTaskModal) => {
  const { t } = useTranslation();
  const [resetEditor, setResetEditor] = useState(false);
  const handleClose = async () => {
    setResetEditor(true);
    await new Promise((resolve) => setTimeout(resolve, 10));
    onClose();
  };
  const [selectedPage, setSelectedPage] = useState<{ id: string; title: string } | undefined>();
  const uploadInputRef = useRef<HTMLInputElement>(null);
  const [aboutText, setAboutText] = useState<OutputData | null>(null);
  const { isUploading, setIsUploading, progress, onUploadProgress } = useFileUploadProgress();

  const {
    watch,
    control,
    setValue,
    register,
    handleSubmit,
    unregister,
    formState: { errors },
  } = useForm<TaskSubmitFormInputType>({ defaultValues: { title: '', note: '', type: 'file' } });

  const onSubmit: SubmitHandler<TaskSubmitFormInputType> = (data) => {
    const type = watch('type');
    data.note = aboutText ? JSON.stringify(aboutText) : note || '';
    if (isEditing) {
      handleSubmitTask(data, onUploadProgress, setIsUploading);
      return;
    }
    const { file, ...rest } = data;
    const formData = objToFormData({ ...rest });
    if (file && file.length > 0) {
      formData.append('file', file[0]);
    }
    if (type === 'page' && selectedPage?.id) formData.append('pageId', selectedPage?.id);
    setIsUploading(true);

    handleSubmitTask(formData, onUploadProgress, setIsUploading);
  };

  const handleSelectFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files?.length) return;
    if (e.target.files[0].size > COMMON_FILE_SIZE_LIMIT) {
      notifyError('file-too-big');
      return;
    }
    setValue('file', e.target.files);
  };

  const handleRemoveFile = () => {
    unregister('file');
  };

  // if editing submission, prefill the form
  useEffect(() => {
    if (!isEditing) return;
    setValue('title', title || '');
  }, [isEditing]);

  return (
    <ModalContainer title={t('submit_task')} isOpen={isOpen} onClose={handleClose}>
      <form onSubmit={handleSubmit(onSubmit)} className='space-y-5'>
        {!isEditing && (
          <div className='space-y-2'>
            <div className='space-y-1'>
              <BodyText variant='sm' className='font-medium text-neutral-500'>
                {t('resource_type')}
              </BodyText>
              <Controller
                name='type'
                control={control}
                rules={{
                  required: {
                    value: true,
                    message: t('required_error_message'),
                  },
                }}
                render={({ field: { value, ...props } }) => (
                  <div className='flex items-center gap-2'>
                    <button
                      type='button'
                      className='flex grow items-center gap-4 rounded-xl bg-neutral-100 p-4'
                      onClick={() => setValue('type', 'file')}
                    >
                      <input
                        type='radio'
                        value='file'
                        checked={watch('type') === 'file'}
                        {...props}
                      />
                      <BodyText variant='sm' className='font-medium uppercase text-neutral-400'>
                        {t('file')}
                      </BodyText>
                    </button>
                    <button
                      type='button'
                      className='flex grow items-center gap-4 rounded-xl bg-neutral-100 p-4'
                      onClick={() => setValue('type', 'page')}
                    >
                      <input
                        type='radio'
                        value='page'
                        checked={watch('type') === 'page'}
                        {...props}
                      />
                      <BodyText variant='sm' className='font-medium text-neutral-400'>
                        {t('my-pages')}
                      </BodyText>
                    </button>
                  </div>
                )}
              />
            </div>
            {watch('type') === 'file' && (
              <div>
                <BodyText
                  variant='sm'
                  className={`font-medium ${
                    errors.file ? 'text-functional-danger-400' : 'text-neutral-500'
                  }`}
                >
                  {t('upload-file')}
                </BodyText>

                {watch('file') ? (
                  <div className='flex items-center gap-3 rounded-md border border-functional-info-400 bg-functional-info-light px-4 py-2'>
                    <div>
                      <BodyText variant='sm' className='font-semibold'>
                        {watch('file')[0].name}
                      </BodyText>
                      <BodyText variant='xs'>
                        {(watch('file')[0].size / 1024 / 1024).toFixed(2)} MB
                      </BodyText>
                    </div>

                    <button
                      className='ml-auto flex h-6 w-6 items-center justify-center rounded-full text-functional-danger-dark'
                      onClick={handleRemoveFile}
                    >
                      <DeleteIcon className='h-5 w-5' />
                    </button>
                  </div>
                ) : (
                  <UploadFileAreaButton
                    maxFileSize={100}
                    isError={!!errors.file}
                    onClick={() => uploadInputRef.current?.click()}
                  />
                )}

                <input
                  type='file'
                  className='hidden'
                  {...register('file', {
                    onChange: (e) => handleSelectFile(e),
                  })}
                  ref={uploadInputRef}
                />
              </div>
            )}
          </div>
        )}
        {watch('type') === 'page' && (
          <div className='max-h-[300px] space-y-1 overflow-y-auto'>
            <PageSelection setSelectedPage={setSelectedPage} selectedPage={selectedPage} />
          </div>
        )}

        <div className='space-y-2'>
          <BodyText
            variant='sm'
            className={`font-medium ${
              errors.title ? 'text-functional-danger-400' : 'text-neutral-500'
            }`}
          >
            {t('title')}
          </BodyText>

          <Controller
            name='title'
            control={control}
            rules={{ required: true }}
            render={({ field }) => (
              <TextInput
                {...field}
                placeholder={t('title') || 'Title here'}
                className={errors.title ? 'border-functional-danger-400' : ''}
              />
            )}
          />
        </div>

        <div className='space-y-2'>
          <BodyText
            variant='sm'
            className={`font-medium ${
              errors.note ? 'text-functional-danger-400' : 'text-neutral-500'
            }`}
          >
            {t('note')}
          </BodyText>

          <div className='px-6'>
            <RichTextEditor
              initialContent={note || ''}
              handleOnChange={(content) => setAboutText(content)}
              resetEditor={resetEditor}
            />
          </div>
        </div>

        {isUploading && <Uploading progress={progress} />}

        <div className='mt-5 flex items-center justify-end gap-3 border-t border-neutral-200 pt-5'>
          <Button type='button' size='small' variant='outlined' onClick={onClose}>
            {t('cancel')}
          </Button>
          <Button type='submit' size='small' variant='secondary' disabled={isUploading}>
            {isEditing ? t('save') : t('submit')}
          </Button>
        </div>
      </form>
    </ModalContainer>
  );
};

export default SubmitTaskModal;
