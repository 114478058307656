import DeleteIcon from 'assets/icons/delete.svg';
import Button from 'components/button/Button';
import UploadFileAreaButton from 'components/button/UploadFileAreaButton';
import BodyText from 'components/typography/BodyText';
import { useInvalidateQueries } from 'hooks/common/useInvalidatequeries';
import React, { useRef } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { inviteCommunityMember, inviteProgramMember } from 'utils/api/member';
import { notifySuccess, notifyTranslatedError } from 'utils/notify';
import { objToFormData } from 'utils/rest';
import ModalContainer from './ModalContainer';

interface ICsvAddMemberModal {
  id: string;
  isOpen: boolean;
  onClose: () => void;
  variant: 'community' | 'program';
}

const CsvAddMemberModal = ({ id, isOpen, onClose, variant }: ICsvAddMemberModal) => {
  const { t } = useTranslation();

  const uploadInputRef = useRef<HTMLInputElement>(null);

  const {
    invalidateProgramMembers,
    invalidateCommunityMembers,
    invalidateProgramPendingInvites,
    invalidateCommunityPendingInvites,
  } = useInvalidateQueries();

  const {
    watch,
    register,
    setValue,
    unregister,
    handleSubmit,
    formState: { errors },
  } = useForm<{ file: FileList }>();

  const onSubmit: SubmitHandler<{ file: FileList }> = (data) => {
    const { file } = data;
    const formData = objToFormData({});
    formData.append('file', file[0]);

    if (variant === 'community') {
      inviteCommunityMember(id, formData)
        .then(() => {
          onClose();
          invalidateCommunityMembers(id);
          invalidateCommunityPendingInvites(id);
          notifySuccess(t('success.api.invite_member'));
        })
        .catch((error) => {
          notifyTranslatedError(t, error.response.data?.errorCode);
        });
    }

    if (variant === 'program') {
      inviteProgramMember(id, formData)
        .then(() => {
          onClose();
          invalidateProgramMembers(id);
          invalidateProgramPendingInvites(id);
          notifySuccess(t('success.api.invite_member'));
        })
        .catch((error) => {
          notifyTranslatedError(t, error.response.data?.errorCode);
          notifyTranslatedError(t, error.response.data?.message);
        });
    }
  };

  const handleSelectFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files?.length) return;
    setValue('file', e.target.files);
  };

  const handleRemoveFile = () => {
    unregister('file');
  };

  return (
    <ModalContainer title={t('add_member')} isOpen={isOpen} onClose={onClose}>
      <form onSubmit={handleSubmit(onSubmit)} className='space-y-5'>
        <div className='space-y-2'>
          <BodyText
            variant='sm'
            className={`font-medium ${
              errors.file ? 'text-functional-danger-400' : 'text-neutral-500'
            }`}
          >
            {t('upload-file')}
          </BodyText>

          {watch('file') ? (
            <div className='flex items-center gap-3 rounded-md border border-functional-info-dark bg-functional-info-light px-4 py-2'>
              <div>
                <BodyText variant='sm' className='font-semibold'>
                  {watch('file')[0].name}
                </BodyText>
                <BodyText variant='xs'>
                  {(watch('file')[0].size / 1024 / 1024).toFixed(2)} MB
                </BodyText>
              </div>

              <button
                className='ml-auto flex h-7 w-7 items-center justify-center rounded-full text-functional-danger-dark hover:bg-neutral-300 active:bg-neutral-400'
                onClick={handleRemoveFile}
              >
                <DeleteIcon className='h-5 w-5' />
              </button>
            </div>
          ) : (
            <UploadFileAreaButton
              maxFileSize={100}
              isError={!!errors.file}
              onClick={() => uploadInputRef.current?.click()}
            />
          )}

          <input
            type='file'
            className='hidden'
            {...register('file', {
              required: true,
              onChange: (e) => handleSelectFile(e),
            })}
            ref={uploadInputRef}
            accept='.xlsx, .xls, .csv'
          />
        </div>

        <div className='mt-5 flex items-center justify-between border-t border-neutral-200 pt-5'>
          <div className='flex items-center'>
            <Button
              type='button'
              size='small'
              variant='outlined'
              color='gray'
              onClick={() => window.open('https://studycentral.eu/csv-import-guide/')}
            >
              {t('instructions')}
            </Button>
          </div>
          <div className='flex items-center justify-end gap-3'>
            <Button type='button' size='small' variant='outlined' onClick={onClose}>
              {t('cancel')}
            </Button>
            <Button type='submit' size='small' variant='secondary'>
              {t('add_member')}
            </Button>
          </div>
        </div>
      </form>
    </ModalContainer>
  );
};

export default CsvAddMemberModal;
