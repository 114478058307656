import { useInfiniteQuery } from '@tanstack/react-query';
import { getProgramJoinRequests } from 'utils/api/member';

export const useProgramJoinRequests = (programId: string) => {
  const { data, hasNextPage, fetchNextPage, refetch } = useInfiniteQuery({
    queryKey: ['program-join-requests', programId],
    queryFn: ({ pageParam = '' }) => getProgramJoinRequests(programId, 3, pageParam),
    getNextPageParam: (lastPage) => lastPage.data.cursor,
  });

  return {
    requests: data?.pages.flatMap((item) => item.data.requests) ?? [],
    hasNextRequests: hasNextPage,
    fetchNextRequests: fetchNextPage,
    refetchRequests: refetch,
  };
};
