import { AxiosRequestConfig } from 'axios';
import { API_ROOT } from 'constants/config';
import {
  HomeBlockResponseType,
  CreatePageResponseType,
  PageResponseType,
  AllPageResponseType,
} from 'types/apis/pages/response';
import { parsePUT, parseDELETE, parseGET, parsePOST } from 'utils/rest';
import { AxiosResponse } from 'axios';

export const addCommunityHomeBlock = (
  communityId: string,
  body: FormData,
  options?: AxiosRequestConfig,
) => {
  return parsePOST(`${API_ROOT}/community/${communityId}/addhomeblock`, body, {
    withCredentials: true,
    ...options,
  });
};

export const addProgramHomeBlock = (
  programId: string,
  body: FormData,
  options?: AxiosRequestConfig,
) => {
  return parsePOST(`${API_ROOT}/program/${programId}/addhomeblock`, body, {
    withCredentials: true,
    ...options,
  });
};

export const deleteCommunityHomeBlock = (
  homeBlockId: string,
  communityId: string,
  options?: AxiosRequestConfig,
) => {
  return parseDELETE(`${API_ROOT}/community/${communityId}/deletehomeblock/${homeBlockId}`, {
    withCredentials: true,
    ...options,
  });
};

export const deleteProgramHomeBlock = (
  homeBlockId: string,
  programId: string,
  options?: AxiosRequestConfig,
) => {
  return parseDELETE(`${API_ROOT}/program/${programId}/deletehomeblock/${homeBlockId}`, {
    withCredentials: true,
    ...options,
  });
};

export const getCommunityHomeBlock = (communityId: string, options?: AxiosRequestConfig) => {
  return parseGET<HomeBlockResponseType>(`${API_ROOT}/community/${communityId}/homeblock`, {
    withCredentials: true,
    ...options,
  });
};

export const getProgramHomeBlock = (programId: string, options?: AxiosRequestConfig) => {
  return parseGET<HomeBlockResponseType>(`${API_ROOT}/program/${programId}/homeblock`, {
    withCredentials: true,
    ...options,
  });
};

export const uploadFileToPage = (
  body: FormData,
  options?: AxiosRequestConfig,
): Promise<AxiosResponse<{ url: string }>> => {
  return parsePOST(`${API_ROOT}/pages/uploadfile`, body, {
    withCredentials: true,
    ...options,
  });
};

export const deleteFileFromPage = (
  url: string,
  options?: AxiosRequestConfig,
): Promise<AxiosResponse<{ message: string }>> => {
  return parseDELETE(`${API_ROOT}/pages/deletefile`, {
    params: { url },
    withCredentials: true,
    ...options,
  });
};

export const createPage = (title: string, options?: AxiosRequestConfig) => {
  return parseGET<CreatePageResponseType>(
    `${API_ROOT}/pages/createpage?title=${encodeURIComponent(title)}`,
    {
      withCredentials: true,
      ...options,
    },
  );
};
export const getPage = (pageId: string, options?: AxiosRequestConfig) => {
  return parseGET<PageResponseType>(`${API_ROOT}/pages/getpage/${pageId}`, {
    withCredentials: true,
    ...options,
  });
};

export const getAllPages = (options?: AxiosRequestConfig) => {
  return parseGET<AllPageResponseType>(`${API_ROOT}/pages/getallpages`, {
    withCredentials: true,
    ...options,
  });
};

export const getSharedPages = (options?: AxiosRequestConfig) => {
  return parseGET<AllPageResponseType>(`${API_ROOT}/pages/getsharedpages`, {
    withCredentials: true,
    ...options,
  });
};

export const updatePage = (pageId: string, body: FormData, options?: AxiosRequestConfig) => {
  return parsePOST(`${API_ROOT}/pages/updatepage/${pageId}`, body, {
    withCredentials: true,
    ...options,
  });
};

export const deletePage = (pageId: string, options?: AxiosRequestConfig) => {
  return parseDELETE(`${API_ROOT}/pages/deletepage/${pageId}`, {
    withCredentials: true,
    ...options,
  });
};

export const updatePageAcess = (
  pageId: string,
  body: { allowGuest: boolean },
  options?: AxiosRequestConfig,
) => {
  return parsePUT(`${API_ROOT}/pages/updateaccess/${pageId}`, body, {
    withCredentials: true,
    ...options,
  });
};

export const updatePageComment = (
  pageId: string,
  body: { allowComment: boolean },
  options?: AxiosRequestConfig,
) => {
  return parsePUT(`${API_ROOT}/pages/updatecomment/${pageId}`, body, {
    withCredentials: true,
    ...options,
  });
};
