import { useQuery } from '@tanstack/react-query';
import { getMyPrograms, getHashtags } from 'utils/api/program';

export const useMyPrograms = (hashtag?: string) => {
  const {
    data: response,
    refetch,
    isLoading,
    isFetching,
  } = useQuery({
    queryKey: ['my-programs', hashtag],
    queryFn: () => getMyPrograms(hashtag),
  });

  // Accessing programs from the response correctly
  const programs = response?.data.programs ?? [];

  return {
    programs,
    refetchPrograms: refetch,
    isProgramsLoading: isLoading,
    isProgramsFetching: isFetching,
  };
};

export const useHashtags = () => {
  const {
    data: response,
    refetch,
    isLoading,
    isFetching,
  } = useQuery({
    queryKey: ['hashtags'],
    queryFn: () => getHashtags(),
  });
  return {
    hashtags: response?.data.hashtags ?? [],
    refetchHashtags: refetch,
    isHashtagsLoading: isLoading,
    isHashtagsFetching: isFetching,
  };
};
