import Layout from 'components/layout/Layout';
import { Conversation } from 'components/message/Conversation';
import ConversationList from 'components/message/ConversationList';
import React from 'react';
import { useParams } from 'react-router-dom';

function MessagesPage() {
  const { conversationId } = useParams();

  return (
    <Layout>
      <div className='flex h-[calc(100vh-148px)] w-full overflow-hidden md:h-[calc(100vh-50px)]'>
        <ConversationList currentConversationId={conversationId} />
        <div className='h-full w-full '>
          <div className='h-full w-full flex-1 overflow-hidden '>
            {conversationId && <Conversation conversationId={conversationId} />}
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default MessagesPage;
