import AddMemberIcon from 'assets/icons/add-member.svg';
import ImportIcon from 'assets/icons/drop-docs.svg';
import AddIcon from 'assets/icons/add.svg';
import SortIcon from 'assets/icons/sort.svg';
import BodyText from 'components/typography/BodyText';
import DeleteIcon from 'assets/icons/delete.svg';
import ShareIcon from 'assets/icons/share-button.svg';
import Button from 'components/button/Button';
import SearchInput from 'components/form/SearchInput';
import { DropdownMenu } from 'components/common/DropdownMenu';
import { MemberRoleType } from 'types/apis/common';
import AddMemberModal from 'components/modal/AddMemberModal';
import AddManagerModal from 'components/modal/AddManagerModal';
import ConfirmationModal from 'components/modal/ConfirmationModal';
import CsvAddMemberModal from 'components/modal/CsvAddMemberModal';
import InviteLinkModal from 'components/modal/InviteLinkModal';
import { userAvatar } from 'constants/common';
import { useProgramMembers } from 'hooks/api/member/useProgramMembers';
import { useSingleProgram } from 'hooks/api/program/useSingleProgram';
import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { removeProgramMember } from 'utils/api/member';
import { notifySuccess, notifyTranslatedError } from 'utils/notify';
import useIsLoggedIn from 'hooks/api/auth/useIsLoggedIn';
import { changeMemberRole } from 'utils/api/auth';
import MemberTile from 'components/memberAndProfile/MemberTile';
import ViewToggle from 'components/common/ItemListViewToggle';
import MemberButton from 'components/memberAndProfile/MemberButton';
import { ContextMenu } from 'components/common/ContextMenu';
import { addProgramManager } from 'utils/api/member';
import { useInvalidateQueries } from 'hooks/common/useInvalidatequeries';
import { timeSince } from 'utils/time';

const ProgramMembers = () => {
  const { t } = useTranslation();
  const { programId } = useParams();
  const [selectedMemberId, setSelectedMemberId] = useState('');
  const [showAddMemberModal, setShowAddMemberModal] = useState(false);
  const [showAddManagerModal, setShowAddManagerModal] = useState(false);
  const [openCsvAddMemberModal, setOpenCsvAddMemberModal] = useState(false);
  const [openInviteLinksModal, setOpenInviteLinksModal] = useState(false);
  const [openRemoveMemberConfirmation, setOpenRemoveMemberConfirmation] = useState(false);
  const { data: userInfo, isLoading: isUserInfoLoading } = useIsLoggedIn();
  const { data: program } = useSingleProgram(programId || '');

  // list view toggle
  const [listView, setListView] = useState(false); // Initialize state
  const handleToggleView = (isListView: boolean) => {
    setListView(isListView); // Update state based on the value from ViewToggle
  };

  if (!program) return null;
  const { data: members, refetch: refetchMembers } = useProgramMembers(program.id);

  //filter code here
  const [searchString, setSearchString] = useState('');
  const filteredMembers = useMemo(
    () =>
      members
        ?.sort((a) => (a.role === 'manager' ? -1 : 0))
        .filter((member) =>
          `${member.firstName} ${member.lastName}`
            .toLowerCase()
            .includes(searchString.toLowerCase()),
        ) || [],
    [members, searchString],
  );
  //filter end here

  // sorting related code:
  const [sortConfig, setSortConfig] = useState<{
    key: 'createdAt' | 'firstName' | 'lastName' | 'role';
    direction: 'ascending' | 'descending';
  }>({ key: 'role', direction: 'ascending' });

  const sortedMembers = useMemo(() => {
    let filtered = filteredMembers;
    if (sortConfig.key) {
      filtered = [...filteredMembers].sort((a, b) => {
        let aValue, bValue;
        if (sortConfig.key === 'createdAt') {
          aValue = new Date(a.createdAt).getTime();
          bValue = new Date(b.createdAt).getTime();
        } else if (sortConfig.key === 'firstName') {
          aValue = a.firstName;
          bValue = b.firstName;
        } else if (sortConfig.key === 'lastName') {
          aValue = a.lastName;
          bValue = b.lastName;
        } else if (sortConfig.key === 'role') {
          aValue = a.role;
          bValue = b.role;
        } else {
          aValue = a[sortConfig.key];
          bValue = b[sortConfig.key];
        }
        if (aValue && bValue && aValue < bValue) {
          return sortConfig.direction === 'ascending' ? -1 : 1;
        }
        if (aValue && bValue && aValue > bValue) {
          return sortConfig.direction === 'ascending' ? 1 : -1;
        }
        return 0;
      });
    }
    return filtered;
  }, [filteredMembers, sortConfig]);

  const handleSort = (key: 'firstName' | 'createdAt' | 'lastName' | 'role') => {
    let direction: 'ascending' | 'descending' = 'ascending';
    if (sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }
    setSortConfig({ key, direction });
  };
  // sorting related code ends here

  // add program manager or member
  const { invalidateProgramManagers, invalidateSingleProgram, invalidateMyPrograms } =
    useInvalidateQueries();
  const handleAddManager = (data: { email: string }) => {
    addProgramManager(program.id, data)
      .then(() => {
        invalidateProgramManagers(program.id);
        invalidateSingleProgram(program.id);
        invalidateMyPrograms();
        setShowAddManagerModal(false);
        notifySuccess(t('success.api.add_manager'));
      })
      .catch((error) => {
        notifyTranslatedError(t, error.response.data?.errorCode);
      });
  };

  const handleOpenRemoveMemberConfirmation = (memberId: string) => {
    setSelectedMemberId(memberId);
    setOpenRemoveMemberConfirmation(true);
  };

  const handleCloseRemoveMemberConfirmation = () => {
    setSelectedMemberId('');
    setOpenRemoveMemberConfirmation(false);
  };

  const handleRemoveMember = () => {
    removeProgramMember(program.id, selectedMemberId)
      .then(() => {
        refetchMembers();
        notifySuccess(t('success.api.member_removed'));
      })
      .catch((error) => {
        notifyTranslatedError(t, error.response.data?.errorCode);
      });
    setOpenRemoveMemberConfirmation(false);
  };

  const handleMakeManager = (memberId: string) => {
    changeMemberRole({
      memberId,
      role: 'manager',
      originId: program.id,
      originType: 'program',
    })
      .then(() => {
        refetchMembers();
        notifySuccess(t('action-success'));
      })
      .catch((error) => {
        notifyTranslatedError(t, error.response.data?.errorCode);
      });
  };

  const handleMakeMember = (memberId: string) => {
    changeMemberRole({
      memberId,
      role: 'member',
      originId: program.id,
      originType: 'program',
    })
      .then(() => {
        notifySuccess(t('action-success'));
        refetchMembers();
      })
      .catch((error) => {
        notifyTranslatedError(t, error.response.data?.errorCode);
      });
  };

  return (
    <>
      <div className='mb-4 flex items-center justify-between px-1 gap-2 '>
        <SearchInput onSearch={(value) => setSearchString(value)} />
        <BodyText variant='base' className='text-neutral-500 hidden md:flex gap-1 w-10'>
          <span className='text-secondary-500 font-bold'>{members?.length}</span> {t('members')}
        </BodyText>
        <div className='flex items-center justify-end w-full gap-3 z-20'>
          <ViewToggle onToggleView={handleToggleView} />
          {program.canManage && (
            <div className='z-10 '>
              <DropdownMenu
                menuButton={
                  <Button size='small' variant='secondary-outlined' tooltip={t('add_member')}>
                    <AddIcon className='h-5 w-5' />
                  </Button>
                }
                menuItems={[
                  {
                    key: 'add_member',
                    label: t('add_member'),
                    icon: AddMemberIcon,
                    callback: () => setShowAddMemberModal(true),
                  },
                  ...(userInfo?.userType === 'organization_admin'
                    ? [
                        {
                          key: 'add_manager',
                          label: t('add_new_manager'),
                          icon: AddMemberIcon,
                          callback: () => setShowAddManagerModal(true),
                        },
                      ]
                    : []),
                  {
                    key: 'import_csv',
                    label: t('import_csv'),
                    icon: ImportIcon,
                    callback: () => setOpenCsvAddMemberModal(true),
                  },
                  {
                    key: 'invitation-link',
                    label: t('invitation-link'),
                    icon: ShareIcon,
                    callback: () => setOpenInviteLinksModal(true),
                  },
                ]}
              />
            </div>
          )}
        </div>
      </div>
      <div className='mb-5'>
        {!listView ? (
          <div className='grid grid-cols-1 gap-3 lg:grid-cols-2 xl:grid-cols-3 xxl:grid-cols-4'>
            {filteredMembers.map(
              ({ id: userId, headline, firstName, lastName, profilePicture, role, lastVisit }) => (
                <MemberTile
                  key={userId}
                  userId={userId}
                  headline={headline || ''}
                  firstName={firstName}
                  lastName={lastName}
                  lastVisit={lastVisit}
                  profilePicture={profilePicture || userAvatar}
                  role={role || 'member'}
                  handleOpenRemoveMemberConfirmationModal={handleOpenRemoveMemberConfirmation}
                  handleMakeManager={handleMakeManager}
                  handleMakeMember={handleMakeMember}
                  userInfo={userInfo || null}
                  canManage={program.canManage}
                />
              ),
            )}
          </div>
        ) : (
          <div className='table-container'>
            <table>
              <thead>
                <tr>
                  <th>
                    <div className='flex items-center gap-1 text-left'>
                      {t('name')}
                      <button
                        className='neutral-button rounded-full p-1'
                        onClick={() => handleSort('firstName')}
                      >
                        <SortIcon className='h-4 w-4' />
                      </button>
                    </div>
                  </th>
                  <th>
                    <div className='flex items-center gap-1 text-left'>
                      {t('role')}
                      <button
                        className='neutral-button rounded-full p-1'
                        onClick={() => handleSort('role')}
                      >
                        <SortIcon className='h-4 w-4' />
                      </button>
                    </div>
                  </th>
                  <th>{t('headline')}</th>
                  <th>{t('last_visit')}</th>
                  <th>{t('actions')}</th>
                </tr>
              </thead>
              <tbody>
                {sortedMembers.map(
                  ({
                    id: userId,
                    headline,
                    firstName,
                    lastName,
                    profilePicture,
                    role,
                    lastVisit,
                  }) => (
                    <tr key={userId} className='group'>
                      <td>
                        <MemberButton
                          userId={userId}
                          isCreator={false}
                          profilePicture={profilePicture || ''}
                          userName={firstName + ' ' + lastName}
                          chat={false}
                          size='small'
                        />
                      </td>
                      <td>
                        <BodyText
                          variant='base'
                          className={`capitalize ${
                            role === 'member' ? 'text-neutral-500' : 'text-functional-success-400'
                          }`}
                        >
                          {' '}
                          {t(`member.role.${role}`)}
                        </BodyText>
                      </td>
                      <td>
                        <BodyText variant='base' classID='line-clamp-1 max-w-[90px]'>
                          {headline}
                        </BodyText>
                      </td>
                      <td>
                        {lastVisit ? (
                          <BodyText variant='base' className='line-clamp-1'>
                            {timeSince(new Date(lastVisit))}
                          </BodyText>
                        ) : (
                          <div>-</div>
                        )}
                      </td>
                      <td className='text-left flex items-center gap-3 lg:opacity-0 lg:group-hover:opacity-100'>
                        {userInfo?.userType === 'organization_admin' && program.canManage && (
                          <ContextMenu
                            menuItems={[
                              {
                                key: 'remove',
                                label: t('remove_member'),
                                icon: DeleteIcon,
                                callback: () => handleOpenRemoveMemberConfirmation(userId),
                              },
                              ...(role === MemberRoleType.member
                                ? [
                                    {
                                      key: 'make_manager',
                                      label: t('make_manager'),
                                      icon: AddMemberIcon,
                                      callback: () => handleMakeManager(userId),
                                    },
                                  ]
                                : []),
                              ...(role === MemberRoleType.manager
                                ? [
                                    {
                                      key: 'make_member',
                                      label: t('make_member'),
                                      icon: AddMemberIcon,
                                      callback: () => handleMakeMember(userId),
                                    },
                                  ]
                                : []),
                            ]}
                          />
                        )}
                        {userInfo?.userType !== 'organization_admin' && program.canManage && (
                          <button
                            className='flex h-6 w-6 items-center justify-center rounded-full hover:bg-neutral-300 active:bg-neutral-400'
                            onClick={() => handleOpenRemoveMemberConfirmation(userId)}
                          >
                            <DeleteIcon className='h-5 w-5 text-neutral-400 hover:text-functional-danger-dark active:text-functional-danger-dark' />
                          </button>
                        )}
                      </td>
                    </tr>
                  ),
                )}
              </tbody>
            </table>
          </div>
        )}
      </div>
      <div>
        {showAddMemberModal && (
          <AddMemberModal
            originId={program.id}
            originType='program'
            isOpen={showAddMemberModal}
            onClose={() => setShowAddMemberModal(false)}
          />
        )}
        {showAddManagerModal && (
          <AddManagerModal
            isSingle={true}
            title={t('add_manager')}
            btnText={t('add_manager')}
            isOpen={showAddManagerModal}
            onClose={() => setShowAddManagerModal(false)}
            onSubmit={handleAddManager}
          />
        )}
        {openCsvAddMemberModal && (
          <CsvAddMemberModal
            id={program.id}
            variant='program'
            isOpen={openCsvAddMemberModal}
            onClose={() => setOpenCsvAddMemberModal(false)}
          />
        )}

        {openInviteLinksModal && (
          <InviteLinkModal
            originId={program.id}
            originType='program'
            orgId={program.organization.id}
            isOpen={openInviteLinksModal}
            onClose={() => setOpenInviteLinksModal(false)}
          />
        )}
        {openRemoveMemberConfirmation && (
          <ConfirmationModal
            isOpen={openRemoveMemberConfirmation}
            label={t('remove_member')}
            title={t('remove_member_confirmation_title')}
            description={
              t('remove_member_confirmation_description') ||
              'This member can not interact in this community anymore!'
            }
            onClose={handleCloseRemoveMemberConfirmation}
            onConfirm={handleRemoveMember}
          />
        )}
      </div>
    </>
  );
};

export default ProgramMembers;
