import CloseIcon from 'assets/icons/close.svg';
import Drawer from 'components/Drawer';
import Heading from 'components/typography/Heading';
import React from 'react';
import { useTranslation } from 'react-i18next';
import NotificationComponent from 'components/notification/NotificationComponent';

interface INotificationDrawer {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const NotificationDrawer = ({ isOpen, setIsOpen }: INotificationDrawer) => {
  const { t } = useTranslation();

  return (
    <Drawer isOpen={isOpen} setIsOpen={setIsOpen} size='max-w-xl'>
      <div className='flex items-center justify-between bg-secondary-100 p-2'>
        <Heading variant='h3' className='font-semibold text-secondary-500'>
          {t('notifications')}
        </Heading>

        <button
          className='relative flex h-6 w-6 items-center justify-center rounded-full text-functional-danger-400 hover:bg-neutral-300 active:bg-neutral-400'
          onClick={() => setIsOpen(false)}
        >
          <CloseIcon className='h-4 w-4 ' />
        </button>
      </div>

      <NotificationComponent />
    </Drawer>
  );
};

export default NotificationDrawer;
