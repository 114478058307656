import BodyText from 'components/typography/BodyText';
import Logo from 'assets/images/logo.svg';
import { getSideNavLogo } from 'config/sidenavLogos';
import React from 'react';
import { NavLink } from 'react-router-dom';
import { TFunction } from 'i18next';
import ProgramsIcon from 'assets/icons/add-docs.svg';
import CompassIcon from 'assets/icons/compass.svg';
import { useTranslation } from 'react-i18next';
import { useSettings } from 'hooks/api/settings/useSettings';

type SideNavLink = {
  id: number;
  to: string;
  label: string;
  Icon: any;
};

interface iGuestLayout {
  originLink: string;
  originName: string;
  orgName?: string;
  orgId: string;
}
function GuestLayout({ originName, originLink, orgId, orgName }: iGuestLayout) {
  const { t } = useTranslation();
  const { data: settingsInfo, isLoading } = useSettings();

  const sideNavLinks = (t: TFunction): SideNavLink[] => [
    {
      id: 0,
      to: '/guest/' + originLink + '?orgId=' + orgId,
      label: originName,
      Icon: ProgramsIcon,
    },
    {
      id: 1,
      to: '/discover/' + originLink + '?orgId=' + orgId,
      label: t('discover'),
      Icon: CompassIcon,
    },
  ];

  const links = sideNavLinks(t);

  return (
    <nav className='sticky top-0 hidden h-screen flex-col bg-neutral-100 md:flex border-r border-neutral-300'>
      <section className='flex max-h-[5rem] w-[11rem] items-center justify-center px-2 pt-3'>
        {!orgName ? (
          <div className='flex items-center gap-3 md:block'>
            <Logo className='h-[40px] w-[40px]' />
            <a href='https://www.studycentral.eu/'>
              <div className=''>
                <BodyText variant='base' className='text-neutral-600'>
                  STUDY<span className='font-bold'>CENTRAL</span>
                </BodyText>
              </div>
            </a>
          </div>
        ) : (
          <div className='flex items-center justify-center w-full'>
            {getSideNavLogo(orgId || '', settingsInfo?.settings?.darkTheme || false) ? (
              <img
                src={getSideNavLogo(orgId || '', false) || ''}
                alt='logo'
                className='max-h-[60px] max-w-full object-center'
              />
            ) : (
              <BodyText
                variant='base'
                className='line-clamp-3 hidden text-neutral-500 md:block font-bold'
              >
                {orgName}
              </BodyText>
            )}
          </div>
        )}
      </section>

      <section className='flex grow flex-col pt-5'>
        {links.map(({ id, to, label, Icon }, index) => (
          <NavLink key={id} to={to}>
            {({ isActive }) => (
              <div className={`w-full bg-neutral-100 text-neutral-500 px-1 py-1`}>
                <div
                  className={`overflow-hidden pl-1 pr-2 rounded-xl ${
                    isActive ? 'bg-secondary-200 text-secondary-500' : ''
                  }
                    `}
                >
                  <button
                    className={`flex w-full items-center px-1 py-3 transition-transform duration-150 hover:scale-110`}
                  >
                    <Icon className={`mr-2 h-5 w-5 `} />
                    <BodyText variant='base' className='hidden lg:block'>
                      {label}
                    </BodyText>
                  </button>
                </div>
              </div>
            )}
          </NavLink>
        ))}

        <div className='w-full grow bg-neutral-100' />
        {orgName && (
          <div className='mb-4 px-1 flex items-center'>
            <div className=' flex items-center bg-neutral-100 p-2'>
              <Logo className='h-[30px] w-[30px]' />
            </div>
            <a href='https://www.studycentral.eu/'>
              <div className='hidden lg:block'>
                <BodyText variant='xs' className='text-neutral-600'>
                  Powered by
                </BodyText>
                <BodyText variant='sm' className='text-neutral-600'>
                  STUDY<span className='font-bold'>CENTRAL</span>
                </BodyText>
              </div>
            </a>
          </div>
        )}
      </section>
    </nav>
  );
}

export default GuestLayout;
