import { useQueryClient } from '@tanstack/react-query';
import { getKey as getCommunityPostsKey } from 'hooks/api/post/useCommunityPosts';
import { getKey as getProgramPostsKey } from 'hooks/api/post/useProgramPosts';
import { useCallback } from 'react';
import { PostOrigin } from 'types/apis/common';

export const useInvalidateQueries = () => {
  const queryClient = useQueryClient();

  // auth invalidations
  const invalidateMe = useCallback(() => {
    queryClient.invalidateQueries(['is-logged-in']);
  }, [queryClient]);

  // profile invalidations
  const invalidateProfileInfo = useCallback(() => {
    queryClient.invalidateQueries(['profile-info']);
  }, [queryClient]);

  const invalidateExpertise = useCallback(() => {
    queryClient.invalidateQueries(['expertise']);
  }, [queryClient]);

  // admin invalidations
  const invalidateAdmins = useCallback(() => {
    queryClient.invalidateQueries(['admins']);
  }, [queryClient]);

  // manager invalidations
  const invalidateCommunityManagers = useCallback(
    (communityId: string) => {
      queryClient.invalidateQueries(['community-managers', communityId]);
    },
    [queryClient],
  );

  const invalidateProgramManagers = useCallback(
    (programId: string) => {
      queryClient.invalidateQueries(['program-managers', programId]);
    },
    [queryClient],
  );

  // community invalidations
  const invalidateMyCommunities = useCallback(() => {
    queryClient.invalidateQueries(['my-communities']);
  }, [queryClient]);

  const invalidateOrgCommunities = useCallback(() => {
    queryClient.invalidateQueries(['org-communities']);
  }, [queryClient]);

  const invalidateOtherCommunities = useCallback(() => {
    queryClient.invalidateQueries(['other-communities']);
  }, [queryClient]);

  const invalidateArchivedCommunities = useCallback(() => {
    queryClient.invalidateQueries(['archived-communities']);
  }, [queryClient]);

  const invalidateSingleCommunity = useCallback(
    (communityId: string) => {
      queryClient.invalidateQueries(['single-community', communityId]);
    },
    [queryClient],
  );

  const invalidateRelatedCommunities = useCallback(
    (communityId: string) => {
      queryClient.invalidateQueries(['related-communities', communityId]);
    },
    [queryClient],
  );

  // community event invalidations
  const invalidateCommunityEvents = useCallback(
    (communityId: string) => {
      queryClient.invalidateQueries(['community-events', communityId, false]);
    },
    [queryClient],
  );

  const invalidateUpcomingCommunityEvents = useCallback(
    (communityId: string) => {
      queryClient.invalidateQueries(['community-events', communityId, true]);
    },

    [queryClient],
  );

  const invalidateSingleCommunityEvent = useCallback(
    (communityId: string, eventId: string) => {
      queryClient.invalidateQueries(['community-event', communityId, eventId]);
    },
    [queryClient],
  );

  const invalidateCommunityEventComments = useCallback(
    (communityId: string, eventId: string) => {
      queryClient.invalidateQueries(['community-event-comments', communityId, eventId]);
    },
    [queryClient],
  );

  // community task invalidations
  const invalidateCommunityTasks = useCallback(
    (communityId: string) => {
      queryClient.invalidateQueries(['community-tasks', communityId, false]);
    },
    [queryClient],
  );

  const invalidateCommunityUpcomingTasks = useCallback(
    (communityId: string) => {
      queryClient.invalidateQueries(['community-tasks', communityId, true]);
    },
    [queryClient],
  );

  const invalidateSingleCommunityTask = useCallback(
    (communityId: string, taskId: string) => {
      queryClient.invalidateQueries(['community-task', communityId, taskId]);
    },
    [queryClient],
  );

  const invalidateCommunityTaskComments = useCallback(
    (communityId: string, taskId: string) => {
      queryClient.invalidateQueries(['community-task-comments', communityId, taskId]);
    },
    [queryClient],
  );

  const invalidateCommunityTaskSubmissions = useCallback(
    (communityId: string, taskId: string) => {
      queryClient.invalidateQueries(['community-task-submissions', communityId, taskId]);
    },
    [queryClient],
  );

  const invalidateCommunityTaskRemarks = useCallback(
    (communityId: string, submissionId: string) => {
      queryClient.invalidateQueries(['community-remarks', communityId, submissionId]);
    },
    [queryClient],
  );

  // community members invalidations
  const invalidateCommunityMembers = useCallback(
    (communityId: string) => {
      queryClient.invalidateQueries(['community-members', communityId]);
    },
    [queryClient],
  );

  const invalidateCommunityPendingInvites = useCallback(
    (communityId: string) => {
      queryClient.invalidateQueries(['pending-invites', communityId]);
    },
    [queryClient],
  );

  const invalidateCommunityJoinRequests = useCallback(
    (communityId: string) => {
      queryClient.invalidateQueries(['join-requests', communityId]);
    },
    [queryClient],
  );

  // program invalidations
  const invalidateMyPrograms = useCallback(() => {
    queryClient.invalidateQueries(['my-programs']);
  }, [queryClient]);

  const invalidateOtherPrograms = useCallback(() => {
    queryClient.invalidateQueries(['other-programs']);
  }, [queryClient]);

  const invalidateArchivedPrograms = useCallback(() => {
    queryClient.invalidateQueries(['archived-programs']);
  }, [queryClient]);

  const invalidateSingleProgram = useCallback(
    (programId: string) => {
      queryClient.invalidateQueries(['single-program', programId]);
    },
    [queryClient],
  );

  const invalidateRelatedPrograms = useCallback(
    (programId: string) => {
      queryClient.invalidateQueries(['related-programs', programId]);
    },
    [queryClient],
  );

  // program materials invalidations
  const invalidateProgramMaterials = useCallback(
    (programId: string) => {
      queryClient.invalidateQueries(['program-materials', programId]);
    },
    [queryClient],
  );

  const invalidateProgramMaterialGroups = useCallback(
    (programId: string) => {
      queryClient.invalidateQueries(['program-material-groups', programId]);
    },
    [queryClient],
  );

  // program event invalidations
  const invalidateProgramEvents = useCallback(
    (programId: string) => {
      queryClient.invalidateQueries(['program-events', programId, false]);
    },
    [queryClient],
  );

  const invalidateUpcomingProgramEvents = useCallback(
    (programId: string) => {
      queryClient.invalidateQueries(['program-events', programId, true]);
    },

    [queryClient],
  );

  const invalidateSingleProgramEvent = useCallback(
    (programId: string, eventId: string) => {
      queryClient.invalidateQueries(['program-event', programId, eventId]);
    },
    [queryClient],
  );

  const invalidateProgramEventComments = useCallback(
    (programId: string, eventId: string) => {
      queryClient.invalidateQueries(['program-event-comments', programId, eventId]);
    },
    [queryClient],
  );

  // program task invalidations
  const invalidateProgramTasks = useCallback(
    (programId: string) => {
      queryClient.invalidateQueries(['program-tasks', programId, false]);
    },
    [queryClient],
  );

  const invalidateProgramUpcomingTasks = useCallback(
    (programId: string) => {
      queryClient.invalidateQueries(['program-tasks', programId, true]);
    },
    [queryClient],
  );

  const invalidateSingleProgramTask = useCallback(
    (programId: string, taskId: string) => {
      queryClient.invalidateQueries(['program-task', programId, taskId]);
    },
    [queryClient],
  );

  const invalidateProgramTaskComments = useCallback(
    (programId: string, taskId: string) => {
      queryClient.invalidateQueries(['program-task-comments', programId, taskId]);
    },
    [queryClient],
  );

  const invalidateProgramTaskSubmissions = useCallback(
    (programId: string, taskId: string) => {
      queryClient.invalidateQueries(['program-task-submissions', programId, taskId]);
    },
    [queryClient],
  );

  const invalidateProgramTaskRemarks = useCallback(
    (programId: string, submissionId: string) => {
      queryClient.invalidateQueries(['program-remarks', programId, submissionId]);
    },
    [queryClient],
  );

  // program member invalidations
  const invalidateProgramMembers = useCallback(
    (programId: string) => {
      queryClient.invalidateQueries(['program-members', programId]);
    },
    [queryClient],
  );

  const invalidateProgramPendingInvites = useCallback(
    (programId: string) => {
      queryClient.invalidateQueries(['program-pending-invites', programId]);
    },
    [queryClient],
  );

  const invalidateProgramJoinRequests = useCallback(
    (programId: string) => {
      queryClient.invalidateQueries(['program-join-requests', programId]);
    },
    [queryClient],
  );

  // program group invalidations
  const invalidateProgramGroups = useCallback(
    (programId: string) => {
      queryClient.invalidateQueries(['program-groups', programId]);
    },
    [queryClient],
  );

  const invalidateArchivedProgramGroups = useCallback(
    (programId: string) => {
      queryClient.invalidateQueries(['archived-program-groups', programId]);
    },
    [queryClient],
  );

  // post invalidations
  const invalidateAllPosts = useCallback(() => {
    queryClient.invalidateQueries(['all-posts']);
  }, [queryClient]);

  const invalidateCommunityPosts = useCallback(
    (communityId: string) => {
      queryClient.invalidateQueries(getCommunityPostsKey(communityId));
    },
    [queryClient],
  );

  const invalidateProgramPosts = useCallback(
    (programId: string) => {
      queryClient.invalidateQueries(getProgramPostsKey(programId));
    },
    [queryClient],
  );

  const invalidateCommunitySavedPosts = useCallback(
    (communityId: string) => {
      queryClient.invalidateQueries(['saved-posts', communityId]);
    },
    [queryClient],
  );

  const invalidateProgramSavedPosts = useCallback(
    (programId: string) => {
      queryClient.invalidateQueries(['program-saved-posts', programId]);
    },
    [queryClient],
  );

  const invalidateSinglePost = useCallback(
    (postOrigin: PostOrigin, originId: string, postId: string) => {
      if (postOrigin === 'community') {
        queryClient.invalidateQueries(['community-post', originId, postId]);
      }
      if (postOrigin === 'program') {
        queryClient.invalidateQueries(['program-post', originId, postId]);
      }
    },
    [queryClient],
  );

  const invalidatePostComments = useCallback(
    (postOrigin: PostOrigin, originId: string, postId: string) => {
      if (postOrigin === 'community') {
        queryClient.invalidateQueries(['community-post-comments', originId, postId]);
      }
      if (postOrigin === 'program') {
        queryClient.invalidateQueries(['program-post-comments', originId, postId]);
      }
    },
    [queryClient],
  );

  return {
    invalidateMe,
    invalidateAdmins,
    invalidateMyCommunities,
    invalidateOrgCommunities,
    invalidateOtherCommunities,
    invalidateArchivedCommunities,
    invalidateSingleCommunity,
    invalidateRelatedCommunities,
    invalidateMyPrograms,
    invalidateOtherPrograms,
    invalidateArchivedPrograms,
    invalidateSingleProgram,
    invalidateRelatedPrograms,
    invalidateAllPosts,
    invalidateCommunityPosts,
    invalidateProgramPosts,
    invalidatePostComments,
    invalidateCommunitySavedPosts,
    invalidateProgramSavedPosts,
    invalidateCommunityManagers,
    invalidateProgramManagers,
    invalidateSinglePost,
    invalidateProfileInfo,
    invalidateCommunityEvents,
    invalidateUpcomingCommunityEvents,
    invalidateSingleCommunityEvent,
    invalidateCommunityEventComments,
    invalidateCommunityMembers,
    invalidateCommunityPendingInvites,
    invalidateProgramGroups,
    invalidateCommunityTaskRemarks,
    invalidateProgramMaterials,
    invalidateProgramMembers,
    invalidateProgramPendingInvites,
    invalidateCommunityTasks,
    invalidateCommunityUpcomingTasks,
    invalidateProgramMaterialGroups,
    invalidateSingleCommunityTask,
    invalidateCommunityTaskSubmissions,
    invalidateCommunityTaskComments,
    invalidateCommunityJoinRequests,
    invalidateExpertise,
    invalidateProgramJoinRequests,
    invalidateArchivedProgramGroups,
    invalidateProgramEvents,
    invalidateUpcomingProgramEvents,
    invalidateSingleProgramEvent,
    invalidateProgramEventComments,
    invalidateProgramTasks,
    invalidateProgramUpcomingTasks,
    invalidateSingleProgramTask,
    invalidateProgramTaskComments,
    invalidateProgramTaskSubmissions,
    invalidateProgramTaskRemarks,
  };
};
