import AddIcon from 'assets/icons/add.svg';
import Button from 'components/button/Button';
import { AxiosProgressEvent } from 'axios';
import { useInvalidateQueries } from 'hooks/common/useInvalidatequeries';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Visibility } from 'types/apis/common';
import { createProgram } from 'utils/api/program';
import { notifySuccess, notifyTranslatedError } from 'utils/notify';
import CreationModal from './CreationModal';
import useIsLoggedIn from 'hooks/api/auth/useIsLoggedIn';
import { useHashtags } from 'hooks/api/program/useMyPrograms';

const AddProgramModalWithTrigger = () => {
  const { t } = useTranslation();
  const { refetchHashtags } = useHashtags();
  const [open, setOpen] = useState(false);
  const { data: userInfo, isLoading: isUserInfoLoading } = useIsLoggedIn();
  const { invalidateMyPrograms } = useInvalidateQueries();

  const defaultValues = {
    name: '',
    cover: null,
    description: '',
    visibility: 'open' as Visibility,
    allowGuest: 'false',
    logo: userInfo?.primaryOrganization?.logo || undefined,
  };

  const onFormSubmit = (
    data: FormData,
    onUploadProgress: (progressEvent: AxiosProgressEvent) => void,
    setIsUploading: React.Dispatch<React.SetStateAction<boolean>>,
  ) => {
    createProgram(data)
      .then(() => {
        setOpen(false);
        invalidateMyPrograms();
        notifySuccess(t('success.api.space_created'));
        refetchHashtags();
        setIsUploading(false);
      })
      .catch((error) => {
        notifyTranslatedError(t, error.response.data?.errorCode);
        notifyTranslatedError(t, error.response.data?.message);
      });
  };

  return (
    <>
      <CreationModal
        isOpen={open}
        onFormSubmit={onFormSubmit}
        defaultValues={defaultValues}
        onClose={() => setOpen(false)}
        ctaText={t('create') || 'Create'}
        modalTitle={t('add-new-space')}
      />

      <Button
        size='default'
        variant='secondary-outlined'
        className='w-full md:w-auto'
        onClick={() => setOpen(true)}
      >
        {t('add-new-space')} <AddIcon className='h-4 w-4' />
      </Button>
    </>
  );
};

export default AddProgramModalWithTrigger;
