import { MessageSidebarWithTrigger } from 'components/message/MessageSidebarWithTrigger';
import QuestionIcon from 'assets/icons/question.svg';
import NotificationDrawerWithTrigger from 'components/notification/NotificationDrawerWithTrigger';
import { orgLogo, userAvatar } from 'constants/common';
import { useSearch } from 'hooks/api/search/useSearch';
import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { getSearchMenuOptions } from 'utils/layout';
import { generateCommunityLink, generateProgramLink, generateUserProfileLink } from 'utils/url';
import ProfileDropdown from './ProfileDropdown';
import Button from 'components/button/Button';
import { startConversation } from 'utils/api/message';
import { generateConversationLink } from 'utils/url';
import { notifyTranslatedError } from 'utils/notify';
import SingleConversationDrawer from 'components/drawer/SingleConversationDrawer';

const Header = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [isConversationOpen, setIsConversationOpen] = useState(false);
  const [conversationId, setConversationId] = useState('');
  const handleStartChat = () => {
    const conversationType = 'private';
    startConversation({
      /* UserID with hello@studycentral.eu*/
      participants: ['clfcmy3vq0006uut0v4vws8cg'],
      conversationType,
    })
      .then((data) => {
        setConversationId(data.data.conversationId);
        setIsConversationOpen(true);
      })
      .catch((error) => {
        notifyTranslatedError(t, error.response.data?.errorCode);
      });
  };

  const typeOptions = getSearchMenuOptions(t);

  const [searchString, setSearchString] = useState('');
  const [selectedSearchType, setSelectedSearchType] = useState(typeOptions[0].value);

  const { users, programs, communities } = useSearch(selectedSearchType, searchString);

  const handleSearch = ({ type, value }: { type: string; value: string }) => {
    setSearchString(value);
    setSelectedSearchType(type);
  };

  const onSearchResultClick = (id: string) => {
    if (selectedSearchType === 'user') {
      navigate(generateUserProfileLink(id));
    }
    if (selectedSearchType === 'community') {
      navigate(generateCommunityLink(id));
    }
    if (selectedSearchType === 'program') {
      navigate(generateProgramLink(id));
    }
  };

  const searchResult = useMemo(() => {
    if (!searchString) return [];

    if (users && users.length > 0) {
      return users.map(({ userId, firstName, lastName, profilePicture }) => ({
        id: userId,
        title: `${firstName} ${lastName}`,
        subTitle: 'Member',
        logo: profilePicture || userAvatar,
      }));
    }

    if (programs && programs.length > 0) {
      return programs.map(({ id, name, organization }) => ({
        id,
        title: name,
        subTitle: 'Program',
        logo: organization.logo || orgLogo,
      }));
    }

    if (communities && communities.length > 0) {
      return communities.map(({ id, name, organization }) => ({
        id,
        title: name,
        subTitle: 'Community',
        logo: organization.logo || orgLogo,
      }));
    }

    return [];
  }, [users, programs, communities, searchString]);

  return (
    <header className='sticky top-0 z-50 hidden bg-neutral-200 md:block'>
      <div className='flex items-center bg-neutral-100 py-1 md:px-4 lg:px-7 shadow-sm border-b border-neutral-300'>
        <div className='flex shrink-0 grow items-center justify-end gap-4'>
          <div className='flex items-center gap-5 '>
            <Button
              className='text-neutral-500 rounded-full neutral-button w-6 h-6 bg-neutral-200'
              tooltip={t('platform-help-chat')}
              onClick={handleStartChat}
            >
              <QuestionIcon className='h-4 w-5  ' />
            </Button>
            <NotificationDrawerWithTrigger />
            <MessageSidebarWithTrigger />
          </div>
          <div className='h-5 w-[1px] bg-neutral-300' />
          <ProfileDropdown />
        </div>
      </div>
      <SingleConversationDrawer
        conversationId={conversationId}
        isOpen={isConversationOpen}
        setIsOpen={setIsConversationOpen}
      />
    </header>
  );
};

export default Header;
