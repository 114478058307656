import CloseIcon from 'assets/icons/close.svg';
import Button from 'components/button/Button';
import TextInput from 'components/form/TextInput';
import BodyText from 'components/typography/BodyText';
import Heading from 'components/typography/Heading';
import { useInvalidateQueries } from 'hooks/common/useInvalidatequeries';
import React from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { AddAdminFormInputType } from 'types/components/form';
import { addAdmin } from 'utils/api/admin';
import { notifySuccess, notifyTranslatedError } from 'utils/notify';
import ModalContainer from './ModalContainer';

interface IAddEditAdminModal {
  isOpen: boolean;
  onClose: () => void;
}

const AddEditAdminModal = ({ isOpen, onClose }: IAddEditAdminModal) => {
  const { t } = useTranslation();

  const { invalidateAdmins } = useInvalidateQueries();

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<AddAdminFormInputType>();

  const onSubmit: SubmitHandler<AddAdminFormInputType> = (data) => {
    addAdmin(data)
      .then(() => {
        onClose();
        invalidateAdmins();
        notifySuccess(t('success.api.add_admin'));
      })
      .catch((error) => {
        notifyTranslatedError(t, error.response.data?.errorCode);
      });
  };

  return (
    <ModalContainer title={t('add_new_admin')} isOpen={isOpen} onClose={onClose}>
      <form onSubmit={handleSubmit(onSubmit)} className='space-y-5'>
        <div className='space-y-1'>
          <BodyText variant='sm' className='font-medium text-neutral-500'>
            {t('first-name')}
          </BodyText>
          <Controller
            name='firstName'
            control={control}
            rules={{ required: true }}
            render={({ field }) => (
              <TextInput
                type='text'
                placeholder={t('first-name') || 'John'}
                className={errors.firstName ? 'border-functional-danger-400' : ''}
                {...field}
              />
            )}
          />
        </div>

        <div className='space-y-1'>
          <BodyText variant='sm' className='font-medium text-neutral-500'>
            {t('last-name')}
          </BodyText>
          <Controller
            name='lastName'
            control={control}
            rules={{ required: true }}
            render={({ field }) => (
              <TextInput
                type='text'
                placeholder={t('last-name') || 'Doe'}
                className={errors.firstName ? 'border-functional-danger-400' : ''}
                {...field}
              />
            )}
          />
        </div>

        <div className='space-y-1'>
          <BodyText variant='sm' className='font-medium text-neutral-500'>
            {t('email')}
          </BodyText>
          <Controller
            name='email'
            control={control}
            rules={{ required: true }}
            render={({ field }) => (
              <TextInput
                type='text'
                placeholder={t('email_placeholder') || 'org-admin@mail.com'}
                className={errors.firstName ? 'border-functional-danger-400' : ''}
                {...field}
              />
            )}
          />
        </div>

        <div className='mt-5 flex items-center justify-end gap-3 border-t border-neutral-200 pt-5'>
          <Button type='button' size='small' variant='outlined' onClick={onClose}>
            {t('cancel')}
          </Button>
          <Button type='submit' size='small' variant='secondary'>
            {t('add_new_admin')}
          </Button>
        </div>
      </form>
    </ModalContainer>
  );
};

export default AddEditAdminModal;
