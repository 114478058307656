import { FetchNextPageOptions, InfiniteQueryObserverResult } from '@tanstack/react-query';
import SubmitIcon from 'assets/icons/add-docs.svg';
import BackIcon from 'assets/icons/back.svg';
import CheckedIcon from 'assets/icons/checked.svg';
import ViewsIcon from 'assets/icons/eye.svg';
import ClockIcon from 'assets/icons/clock.svg';
import CommentIcon from 'assets/icons/comment.svg';
import SubmissionsIcon from 'assets/icons/documents.svg';
import InfoIcon from 'assets/icons/info.svg';
import LikeIcon from 'assets/icons/like.svg';
import Button from 'components/button/Button';
import Comments from 'components/common/Comments';
import Layout from 'components/layout/Layout';
import NavigateTo404 from 'components/NavigateTo404';
import Tag from 'components/tag/Tag';
import { AssigneeInfoType } from 'types/apis/common';
import BodyText from 'components/typography/BodyText';
import Heading from 'components/typography/Heading';
import dayjs from 'dayjs';
import { EditorState } from 'draft-js';
import RichTextEditor from 'components/common/RichTextEditorWithUpload';
import useIsLoggedIn from 'hooks/api/auth/useIsLoggedIn';
import React, { useState } from 'react';
import { UseFormReset } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import { SingleCommentType, UserType } from 'types/apis/common';
import { CommentsResponseType } from 'types/apis/response';
import { generateOriginLink } from 'utils/url';
import MemberButton from 'components/memberAndProfile/MemberButton';

interface ITaskDetails {
  taskCreatorProfileLink: string;
  taskCreatorAvatar: string;
  taskCreatorName: string;
  taskCreatorId: string;
  taskCreatedAt: string;
  taskCreatorUserType: UserType;
  contactUserAvatar?: string;
  contactUserId?: string;
  contactUserName?: string;
  contactUserProfileLink?: string;
  taskAbout: string;
  taskAssignee: AssigneeInfoType;
  isTaskLiked: boolean;
  handleLikeTask: () => void;
  taskHasDeadline: boolean;
  taskDeadline: string;
  taskSoftDeadline: boolean;
  taskLikeCount: number;
  taskCommentCount: number;
  taskTitle: string;
  taskOriginType: 'program' | 'community';
  taskOriginId: string;
  taskOriginName: string;
  taskOriginLink: string;
  taskViewCount: number;
  isTaskSubmitted: boolean;
  handleAddToCalendar: () => void;
  taskSubmissionsLink: string;
  comments: SingleCommentType[];
  hasNextComments?: boolean;
  fetchNextComments: (
    options?: FetchNextPageOptions | undefined,
  ) => Promise<InfiniteQueryObserverResult<CommentsResponseType, unknown>>;
  handleDeleteComment: (commentId: string) => void;
  handlePublishComment: (
    data: { comment: string },
    reset: UseFormReset<{ comment: string }>,
    setEditorState: React.Dispatch<React.SetStateAction<EditorState>>,
  ) => void;
  setOpenSubmitTaskModal: React.Dispatch<React.SetStateAction<boolean>>;
}

const TaskDetails = ({
  taskCreatorAvatar,
  taskCreatorProfileLink,
  taskCreatorName,
  taskCreatorId,
  taskCreatorUserType,
  contactUserAvatar,
  contactUserId,
  contactUserName,
  contactUserProfileLink,
  handleLikeTask,
  taskAbout,
  taskAssignee,
  isTaskLiked,
  taskDeadline,
  taskCreatedAt,
  taskHasDeadline,
  taskSoftDeadline,
  taskLikeCount,
  taskCommentCount,
  taskTitle,
  taskOriginType,
  taskOriginId,
  taskOriginName,
  taskOriginLink,
  taskViewCount,
  isTaskSubmitted,
  handleAddToCalendar,
  taskSubmissionsLink,
  comments,
  hasNextComments,
  fetchNextComments,
  handleDeleteComment,
  handlePublishComment,
  setOpenSubmitTaskModal,
}: ITaskDetails) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [showComments, setShowComments] = useState(true);

  const { data: user } = useIsLoggedIn();

  if (!user) return <NavigateTo404 />;

  return (
    <Layout>
      <div className='py-1 px-1 md:py-2 md:px-3 lg:py-4 lg:px-5'>
        <Link
          to={generateOriginLink(taskOriginId, taskOriginType) + '/tasks'}
          className='mb-2 flex items-center justify-end'
        >
          <Button
            size='default'
            variant='outlined'
            className='text-functional-danger-400 p-[8px]'
            tooltip={t('back')}
          >
            <BackIcon className='h-4 w-4' />
          </Button>
        </Link>

        <div className='mb-4 grid grid-cols-5 gap-6'>
          <section className='order-1 col-span-5 xl:order-none xl:col-span-3'>
            <div className='space-y-5 rounded-2xl bg-neutral-200 p-4 shadow shadow-neutral-300'>
              <div className='space-y-2'>
                <MemberButton
                  userId={taskCreatorId}
                  isCreator={true}
                  profilePicture={taskCreatorAvatar}
                  userName={taskCreatorName}
                  chat={taskCreatorUserType === 'member' ? true : false}
                />

                {contactUserId && contactUserId !== taskCreatorId && (
                  <MemberButton
                    userId={contactUserId}
                    profilePicture={contactUserAvatar || ''}
                    userName={contactUserName || ''}
                    chat={true}
                  />
                )}
              </div>

              <hr className='border-neutral-300' />

              <Heading variant='h2' className='mb-3 font-semibold capitalize text-neutral-400'>
                {t('about')}
              </Heading>

              <RichTextEditor initialContent={taskAbout} isReadOnly={true} />

              <div className='flex items-center gap-7 border-t border-neutral-300 pt-4'>
                <button
                  onClick={handleLikeTask}
                  className={`flex items-center gap-2 ${
                    isTaskLiked ? 'text-functional-info-dark' : 'text-neutral-400'
                  }`}
                >
                  <LikeIcon className='h-4 w-4' />
                  <BodyText variant='xs' className='font-medium'>
                    {taskLikeCount}
                  </BodyText>
                </button>
                <button
                  className='flex items-center gap-2'
                  onClick={() => setShowComments(!showComments)}
                >
                  <CommentIcon className='h-4 w-4 text-neutral-400' />
                  <BodyText variant='xs' className='font-medium text-neutral-400'>
                    {taskCommentCount}
                  </BodyText>
                </button>
                <button className='group relative flex items-center gap-2'>
                  <ViewsIcon className='h-4 w-4 text-neutral-400' />
                  <BodyText variant='xs' className='font-medium text-neutral-400'>
                    {Math.round(taskViewCount / 1.9)}
                  </BodyText>
                  <div className='absolute top-4 hidden max-w-[100px] overflow-hidden rounded bg-neutral-500 px-1 py-1 opacity-20 transition-opacity duration-200 ease-in-out group-hover:block group-hover:opacity-100'>
                    <BodyText variant='xs' className='whitespace-nowrap text-neutral-100'>
                      {t('view_count')}
                    </BodyText>
                  </div>
                </button>
              </div>

              {showComments && (
                <Comments
                  originId={taskOriginId}
                  originType={taskOriginType}
                  comments={comments}
                  hasNextComments={hasNextComments}
                  fetchNextComments={fetchNextComments}
                  handleDeleteComment={handleDeleteComment}
                  handlePublishComment={handlePublishComment}
                />
              )}
            </div>
          </section>

          <section className='col-span-5 xl:col-span-2'>
            <div className='space-y-5 rounded-2xl bg-neutral-200 p-4 shadow shadow-neutral-300'>
              <div className='space-y-4'>
                <div className='flex items-center gap-2'>
                  {taskHasDeadline ? (
                    <div className='flex items-center gap-2'>
                      <Tag
                        type='default'
                        className='bg-functional-danger-light text-functional-danger-dark'
                      >
                        {dayjs(taskDeadline).format('ddd, D MMM YYYY, h:mm A')}
                      </Tag>
                      {dayjs(taskDeadline).isBefore(dayjs(new Date())) ? (
                        <BodyText variant='sm' className='flex items-center font-medium'>
                          {t('deadline_passed')}
                        </BodyText>
                      ) : (
                        <BodyText variant='sm' className='flex items-center font-medium'>
                          {'('} {t('deadline')} {dayjs(taskDeadline).fromNow()} {')'}
                        </BodyText>
                      )}
                    </div>
                  ) : (
                    <BodyText variant='sm'>
                      <span className='text-neutral-400'>{t('created_at')}:</span>{' '}
                      <span className='text-neutral-400'>
                        {dayjs(taskCreatedAt).format('dddd, D MMM YYYY')}
                      </span>
                    </BodyText>
                  )}
                </div>
                <div className='space-y-2'>
                  <Tag
                    type='default'
                    className='bg-functional-info-light capitalize text-functional-info-dark'
                  >
                    <Link to={taskOriginLink}>{taskOriginName}</Link>
                  </Tag>

                  <Heading variant='h1' className='font-semibold'>
                    {taskTitle}
                  </Heading>
                </div>
                <hr className='border-neutral-300' />
                <div className='space-y-2'>
                  <Heading variant='h3' className='font-semibold text-neutral-400'>
                    {t('assigned_to')}
                  </Heading>
                  {taskAssignee == 'all' ? (
                    <div>
                      <BodyText variant='base' className='font-semibold'>
                        {t('all_members')}
                      </BodyText>
                    </div>
                  ) : (
                    <div>
                      <div className='flex flex-wrap gap-3'>
                        {taskAssignee.map((assignee) => (
                          <MemberButton
                            userId={assignee.id}
                            isCreator={false}
                            profilePicture={assignee.profilePicture || ''}
                            userName={assignee.firstName + ' ' + assignee.lastName}
                            chat={false}
                            size='small'
                          />
                        ))}
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <hr className='border-neutral-300' />
              <Heading variant='h3' className='font-semibold text-neutral-400'>
                {t('actions')}
              </Heading>
              <div className='flex flex-col items-center gap-4 md:flex-row'>
                {(new Date() < new Date(taskDeadline) || taskSoftDeadline) &&
                  (isTaskSubmitted ? (
                    <button
                      disabled
                      className='flex w-full cursor-not-allowed items-center justify-center gap-2 rounded-[7px] bg-functional-success-dark px-[20px] py-[10px] text-sm font-medium leading-5 text-neutral-100'
                    >
                      <CheckedIcon className='h-4 w-4' /> {t('submitted')}
                    </button>
                  ) : (
                    <Button
                      size='default'
                      variant='primary'
                      className='w-full'
                      onClick={() => setOpenSubmitTaskModal(true)}
                    >
                      <SubmitIcon className='h-4 w-4' /> {t('submit_task')}
                    </Button>
                  ))}
                <Button
                  size='default'
                  variant='secondary'
                  className='w-full'
                  onClick={() => navigate(taskSubmissionsLink)}
                >
                  <SubmissionsIcon className='h-4 w-4' /> {t('view_submissions')}
                </Button>
              </div>
              <Button
                size='default'
                variant='default'
                className='w-full bg-secondary-100'
                onClick={handleAddToCalendar}
              >
                <ClockIcon className='h-5 w-5' /> {t('add_to_calendar')}
              </Button>
              {isTaskSubmitted && (
                <BodyText variant='sm' className='flex items-center gap-2 font-medium'>
                  <InfoIcon className='h-4 w-4' />
                  {t('submission_guide')}
                </BodyText>
              )}
            </div>
          </section>
        </div>
      </div>
    </Layout>
  );
};

export default TaskDetails;
