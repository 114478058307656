import { useQuery } from '@tanstack/react-query';
import { getCommunityPublicResources } from 'utils/api/publicResource';

export const useCommunityPublicResources = (communityId: string, limit?: number) => {
  return useQuery({
    queryKey: ['community-public-resources', communityId, limit],
    queryFn: () => getCommunityPublicResources(communityId, limit),
    select: (data) => data.data,
  });
};
