// enums
export enum UserType {
  admin = 'admin',
  member = 'member',
  chatbot = 'chatbot',
  organization_admin = 'organization_admin',
}
export enum MemberRoleType {
  member = 'member',
  manager = 'manager',
  admin = 'admin',
}

export enum EmbedType {
  null,
  youtube = 'youtube',
  image = 'image',
  file = 'file',
  page = 'page',
}

export enum PostOrigin {
  community = 'community',
  program = 'program',
}

// types
export type CommonResponseType = {
  status: string;
  message: string;
  errorCode: string;
};

export type Visibility = 'open' | 'public' | 'private';

export type SingleCommunityType = {
  id: string;
  name: string;
  archived: boolean;
  archiveDate: null | string;
  visibility: 'open' | 'public' | 'private';
  isGroup: boolean;
  description: string;
  cover: null | string;
  logo: null | string;
  createdAt: string;
  memberCount: number;
  allowGuest?: boolean;
  guestViewCount?: number;
  groupParent:
    | null
    | {
        id: string;
        name: string;
      }
    | undefined;
  organization: {
    id: string;
    name: string;
    logo: string | null;
  };
  updatedAt: string;
  events: [];
  homeBlock?: { id: string; content: string };
};

export type SingleCommunityPostType = {
  id: string;
  authorType: MemberRoleType;
  content: string;
  embedId: string;
  embedLink: string;
  embedMeta: string;
  embedType: EmbedType;
  isPinned: boolean;
  file: {
    id: string;
    title: null | string;
    fileName: string;
    directory: string;
    note: null | string;
    createdAt: string;
    fileSize: number;
    url: string;
  };
  createdAt: string;
  viewCount: number;
  user: {
    id: string;
    firstName: string;
    lastName: string;
    profilePicture: null | string;
    headline?: null | string;
    userType: UserType;
  };
  likeCount: number;
  commentCount: number;
  liked: boolean;
  isAuthor: boolean;
};

export type SingleMemberType = {
  id: string;
  email: string;
  userType: UserType;
  firstName: string;
  lastName: string;
  createdAt: string;
  profilePicture: null | string;
  verified: boolean;
  primaryOrganizationId?: string;
  primaryOrganization?: {
    name: string;
    logo: null | string;
  };
};

export type SingleSpaceMemberType = {
  id: string;
  email?: string;
  userType: UserType;
  firstName: string;
  lastName: string;
  createdAt: string;
  lastVisit?: string;
  role?: MemberRoleType;
  profilePicture: null | string;
  headline: null | string;
  orgName?: null | string;
};

export type SingleAdminDetailsType = {
  id: string;
  email: string;
  firstName: string;
  lastName: string;
  createdAt: string;
  headline: null | string;
  profilePicture: null | string;
  userType: 'organization_admin';
};

export type SingleCommunityEventType = {
  id: string;
  title: string;
  about: string;
  isAllDay: boolean | undefined;
  startTime: string;
  endTime: string;
  type: 'inperson' | 'online';
  cover: null | string;
  zoomLink: null | string;
  location: null | string;
  createdAt: string;
  timezone: string;
  viewCount: number;
  creator: {
    id: string;
    firstName: string;
    lastName: string;
    profilePicture: null | string;
    userType: UserType;
  };
  eventContactUser: {
    id: string;
    firstName: string;
    lastName: string;
    profilePicture: null | string;
  };
  community: {
    id: string;
    name: string;
    cover: null | string;
  };
  program: {
    id: string;
    name: string;
    cover: null | string;
  };
  organization: {
    id: string;
    name: string;
    cover: null | string;
  };
  comments: [];
  liked: boolean;
  likeCount: number;
  commentCount: number;
};

export type SingleProgramEventType = {
  id: string;
  title: string;
  about: string;
  isAllDay: boolean | undefined;
  startTime: string;
  endTime: string;
  type: 'inperson' | 'online';
  cover: null | string;
  zoomLink: null | string;
  location: null | string;
  createdAt: string;
  timezone: string;
  viewCount: number;
  creator: {
    id: string;
    firstName: string;
    lastName: string;
    profilePicture: null | string;
    userType: UserType;
  };
  eventContactUser: {
    id: string;
    firstName: string;
    lastName: string;
    profilePicture: null | string;
  };
  program: {
    id: string;
    name: string;
    cover: null | string;
  };
  organization: {
    id: string;
    name: string;
    cover: null | string;
  };
  comments: [];
  liked: boolean;
  likeCount: number;
  commentCount: number;
};

export type SingleCommunityTaskType = {
  id: string;
  title: string;
  about: string;
  deadline: string;
  hasDeadline: boolean;
  assignee: string;
  softDeadline: boolean;
  memberVisibility: boolean;
  createdAt: string;
  active: boolean;
  submitted?: boolean;
  viewCount: number;
  community: {
    id: string;
    name: string;
    cover: null | string;
  };
  program?: {
    id: string;
    name: string;
    cover: null | string;
  };
  creator: {
    id: string;
    firstName: string;
    lastName: string;
    profilePicture: null | string;
    userType: UserType;
  };
  taskContactUser: {
    id: string;
    firstName: string;
    lastName: string;
    profilePicture: null | string;
  };
  organization: {
    id: string;
    name: string;
    logo: null | string;
  };
  assigneeInfo: AssigneeInfoType;
};

export type SingleProgramTaskType = {
  id: string;
  title: string;
  about: string;
  deadline: string;
  memberVisibility: boolean;
  hasDeadline: boolean;
  softDeadline: boolean;
  createdAt: string;
  active: boolean;
  submitted?: boolean;
  viewCount: number;
  program: {
    id: string;
    name: string;
    cover: null | string;
  };
  creator: {
    id: string;
    firstName: string;
    lastName: string;
    profilePicture: null | string;
    userType: UserType;
  };
  taskContactUser: {
    id: string;
    firstName: string;
    lastName: string;
    profilePicture: null | string;
  };
  organization: {
    id: string;
    name: string;
    logo: null | string;
  };
  assigneeInfo: AssigneeInfoType;
};

export type SingleCommentType = {
  id: string;
  comment: string;
  createdAt: string;
  myComment: boolean;
  user: {
    id: string;
    firstName: string;
    lastName: string;
    profilePicture: null | string;
    headline?: null | string;
  };
};

export type SingleCommunityPendingInvitesType = {
  id: string;
  communityId: string;
  manager: boolean;
  email: string;
  createdAt: string;
};

export type SingleProgramPendingInvitesType = {
  id: string;
  programId: string;
  manager: boolean;
  email: string;
  createdAt: string;
};

export type SingleJoinRequestType = {
  id: string;
  createdAt: string;
  user: {
    id: string;
    firstName: string;
    lastName: string;
    profilePicture: null | string;
  };
};

export type SingleCommunityResourceType = {
  id: string;
  isPinned?: boolean;
  uploadId: string;
  type: string;
  title: string;
  url: null | string;
  createdAt: string;
  isMine: boolean;
  file: null | {
    id: string;
    directory: string;
    fileName: string;
    fileSize: number;
    url: string;
  };
  page: null | {
    id: string;
    title: string;
  };
  uploader: {
    id: string;
    firstName: string;
    lastName: string;
    profilePicture: null | string;
  };
};

export type SingleMyResourcesType = {
  title: string;
  itemId: string;
  createdAt: string; // or Date, depending on how you handle dates in your frontend
  originId: string;
  originType: string;
  directory?: string; // Optional, only for files
  fileSize?: number | null; // Optional, only for files, assuming fileSize can be null if not applicable/available
  locationId: string;
  locationType: string;
  locationName: string;
};

export type SingleSharedResourcesType = {
  title: string;
  itemId: string;
  createdAt: string; // or Date, depending on how you handle dates in your frontend
  userId: string;
  userName: string;
  profilePicture: null | string;
  originId: string;
  originType: string;
  directory?: string; // Optional, only for files
  fileSize?: number | null; // Optional, only for files, assuming fileSize can be null if not applicable/available
  locationId: string;
  locationType: string;
  locationName: string;
};

export type SingleSubmissionType = {
  id: string;
  userId: string;
  note: string;
  title: string;
  createdAt: string;
  page?: {
    id: string;
    title: string;
  };
  file?: {
    id: string;
    directory: string;
    fileName: string;
    fileSize: number;
  };
  remarks: {
    id: string;
    remark: string;
    createdAt: string;
    user: {
      id: string;
      firstName: string;
      lastName: string;
      profilePicture: null | string;
    };
  }[];
};

export type SingleUserDetailsType = {
  id: string;
  firstName: string;
  lastName: string;
  profilePicture: null | string;
};

export type SingleTasksSubmissionsType = {
  id: string;
  title: string;
  deadline: string;
  hasDeadline: boolean;
  createdAt: string;
  totalSubmissions: number;
  submittedUserCount: number;
  submittedUsers: SingleUserDetailsType[];
};

export type SingleRemarkType = {
  id: string;
  remark: string;
  createdAt: string;
  user: SingleUserDetailsType;
};

export type SingleMaterialGroup = {
  id: string;
  name: string;
  description: string;
  createdAt: string;
};

export type SingleFileType = {
  materialId: string;
  title: string;
  description: string;
  type: string;
  url: string;
  fileId: string;
  page: { id: string; title: string };
  fileName: string;
  fileSize: number;
  directory: string;
  createdAt: string;
  hidden: boolean;
  uploader: { id: string; firstName: string; lastName: string };
};

export type SingleGroupType = {
  groupId: string;
  name: string;
  createdAt: string;
  hidden: boolean;
  files: SingleFileType[];
};

export type SingleCommunityInviteLinksType = {
  id: string;
  createdAt: string;
  expiryDays: number;
  expiresAt: string;
  communityId: string;
  token: string;
  isOpenEnded: boolean;
  clicks: number;
  acceptCount: number;
};

export type AssigneeUserType = {
  id: string;
  firstName: string;
  lastName: string;
  profilePicture: string | null;
};

// Define a type for assignee information which can be either an array of user objects or a string 'all'
export type AssigneeInfoType = AssigneeUserType[] | 'all';
