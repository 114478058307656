import CloseIcon from 'assets/icons/close.svg';
import Drawer from 'components/Drawer';
import Heading from 'components/typography/Heading';
import useIsLoggedIn from 'hooks/api/auth/useIsLoggedIn';
import React from 'react';
import { useTranslation } from 'react-i18next';
import MyProfile from 'pages/segment/profile/MyProfile';
import MemberProfile from 'pages/segment/profile/MemberProfile';

type IProfileDrawer = {
  memberId: string;
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

const ProfileDrawer = ({ memberId, isOpen, setIsOpen }: IProfileDrawer) => {
  const { t } = useTranslation();

  const { data: userInfo, isLoading: isUserInfoLoading } = useIsLoggedIn();

  return (
    <Drawer isOpen={isOpen} setIsOpen={setIsOpen} size={`max-w-xl`}>
      <div className='flex items-center justify-between bg-secondary-100 p-2'>
        <Heading variant='h3' className='font-semibold capitalize'>
          {t('profile')}
        </Heading>
        <button
          onClick={() => {
            setIsOpen(false);
          }}
          className='relative flex h-6 w-6 items-center justify-center rounded-full text-functional-danger-400 hover:bg-neutral-300 active:bg-neutral-400'
        >
          <CloseIcon className='h-4 w-4 ' />
        </button>
      </div>
      <div>
        {memberId && userInfo?.id !== memberId ? (
          <MemberProfile memberId={memberId} />
        ) : (
          <MyProfile />
        )}
      </div>
    </Drawer>
  );
};

export default ProfileDrawer;
