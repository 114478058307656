import React, { useEffect, useRef } from 'react';
import EmojiPicker from 'emoji-picker-react';
import ReactDOM from 'react-dom';
import { useOuterClick } from 'hooks/common/useOuterClick';

interface IEmojiSelector {
  open: boolean;
  close: () => void;
  element: React.ReactNode;
  elementClassName: string;
  setOpen: React.Dispatch<boolean>;
  onEmojiSelect: (emoji: string) => void;
}

const EmojiSelector = ({
  open,
  close,
  setOpen,
  element,
  elementClassName,
  onEmojiSelect,
}: IEmojiSelector) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const absoluteRef = useRef<HTMLDivElement>(null);
  const emojiContainer = document.getElementById('emoji');

  useOuterClick(absoluteRef, close);

  useEffect(() => {
    if (open) {
      if (containerRef.current) {
        const { top, left } = containerRef.current.getBoundingClientRect();
        const emojiBox = absoluteRef.current;
        if (emojiBox) {
          const windowWidth = window.innerWidth;
          const windowHeight = window.innerHeight;
          const width = emojiBox.clientWidth;
          const height = emojiBox.clientHeight;
          let boxLeft = left;
          let boxTop = top;

          // Outside bottom
          if (top + height > windowHeight) {
            boxTop = top - height;
            if (boxTop < 0) boxTop = 0;
          }

          // Outside Top
          if (top < 0) {
            boxTop = 0;
          }

          if (top - height < 0) {
            boxTop = top;
          }

          // Outside left
          if (left < 0) {
            boxLeft = 0;
          }

          if (left - width < 0) {
            boxLeft = left;
          }

          // Outside Right
          if (left + width > windowWidth) {
            boxLeft = left - width;
            if (boxLeft < 0) boxLeft = 0;
          }

          emojiBox.style.left = `${boxLeft}px`;
          emojiBox.style.top = `${boxTop}px`;
        }
      }
    }
  }, [open]);

  return (
    <div ref={containerRef} className='relative'>
      <button type='button' className={elementClassName} onClick={() => setOpen(!open)}>
        {element}
      </button>
      <div className='absolute right-0'>
        {open &&
          emojiContainer &&
          ReactDOM.createPortal(
            <div className='fixed z-40' ref={absoluteRef}>
              <EmojiPicker
                height={450}
                searchDisabled
                skinTonesDisabled
                lazyLoadEmojis
                onEmojiClick={(emojiData) => onEmojiSelect(emojiData.emoji)}
              />
            </div>,
            emojiContainer,
          )}
        {/* {open && (
          <EmojiPicker
            searchDisabled
            skinTonesDisabled
            onEmojiClick={(emojiData) => onEmojiSelect(emojiData.emoji)}
          />
        )} */}
      </div>
    </div>
  );
};

export default EmojiSelector;
