import { AxiosProgressEvent } from 'axios';
import Loading from 'components/Loading';
import AddEditRemarkModal from 'components/modal/AddEditRemarkModal';
import ConfirmationModal from 'components/modal/ConfirmationModal';
import RemarksModal from 'components/modal/RemarksModal';
import SubmitTaskModal from 'components/modal/SubmitTaskModal';
import NavigateTo404 from 'components/NavigateTo404';
import { useCommunityRemarks } from 'hooks/api/remark/useCommunityRemarks';
import { useCommunityTaskSubmissions } from 'hooks/api/task/useCommunityTaskSubmissions';
import { useInvalidateQueries } from 'hooks/common/useInvalidatequeries';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  addCommunitySubmissionRemark,
  deleteCommunitySubmissionRemark,
  updateCommunitySubmissionRemark,
} from 'utils/api/remark';
import {
  deleteCommunityTaskSubmission,
  submitCommunityTask,
  updateCommunityTaskSubmission,
} from 'utils/api/task';
import { notifySuccess, notifyTranslatedError } from 'utils/notify';
import TaskSubmissions from './segment/task/TaskSubmissions';

const TestPage = () => {
  const originId = 'cldilmvt30007wbqcvrno2z6w';
  const taskId = 'clf790xcv001lrkahtnjk4h76';
  const { t } = useTranslation();

  // states
  const [openAddSubmissionModal, setOpenAddSubmissionModal] = useState(false);
  const [openEditSubmissionModal, setOpenEditSubmissionModal] = useState(false);
  const [selectedSubmissionId, setSelectedSubmissionId] = useState<string | null>(null);
  const [selectedSubmissionTitle, setSelectedSubmissionTitle] = useState<string | null>(null);
  const [selectedSubmissionNote, setSelectedSubmissionNote] = useState<string | null>(null);
  const [deleteSubmissionConfirmation, setDeleteSubmissionConfirmation] = useState(false);
  const [openSubmissionRemarks, setOpenSubmissionRemarks] = useState(false);
  const [selectedRemarkId, setSelectedRemarkId] = useState<string | null>(null);
  const [selectedRemarkValue, setSelectedRemarkValue] = useState<string | null>(null);
  const [openAddRemarkModal, setOpenAddRemarkModal] = useState(false);
  const [openEditRemarkModal, setOpenEditRemarkModal] = useState(false);
  const [openDeleteRemarkConfirmation, setOpenDeleteRemarkConfirmation] = useState(false);

  // custom hooks
  const { submissions, canManage, isSubmissionsLoading } = useCommunityTaskSubmissions(
    originId || '',
    taskId || '',
  );
  const { data: remarks } = useCommunityRemarks(
    originId || '',
    selectedSubmissionId || '',
    openSubmissionRemarks,
  );
  const {
    invalidateSingleCommunityTask,
    invalidateCommunityTaskSubmissions,
    invalidateCommunityTaskRemarks,
  } = useInvalidateQueries();

  if (isSubmissionsLoading) return <Loading />;
  if (!submissions) return <NavigateTo404 />;

  // add submission handler
  const handleOpenAddSubmissionModal = () => {
    setOpenAddSubmissionModal(true);
  };

  const handleCloseAddSubmissionModal = () => {
    setOpenAddSubmissionModal(false);
  };

  const handleAddSubmission = (
    data: FormData,
    onUploadProgress: (progressEvent: AxiosProgressEvent) => void,
    setIsUploading: React.Dispatch<React.SetStateAction<boolean>>,
  ) => {
    // for community task submission
    submitCommunityTask(originId, taskId, data, { onUploadProgress })
      .then(() => {
        setIsUploading(false);
        handleCloseAddSubmissionModal();
        notifySuccess(t('success.api.submitted_task'));
        invalidateSingleCommunityTask(originId, taskId);
        invalidateCommunityTaskSubmissions(originId, taskId);
      })
      .catch((error) => {
        setIsUploading(false);
        notifyTranslatedError(t, error.response.data?.errorCode);
      });
  };

  // edit submission handler
  const handleOpenEditSubmissionModal = (
    submissionId: string,
    submissionTilte: string,
    submissionNote?: string,
  ) => {
    setOpenEditSubmissionModal(true);
    setSelectedSubmissionId(submissionId);
    setSelectedSubmissionTitle(submissionTilte);
    setSelectedSubmissionNote(submissionNote || null);
  };

  const handleCloseEditSubmissionModal = () => {
    setOpenEditSubmissionModal(false);
    setSelectedSubmissionId(null);
    setSelectedSubmissionTitle(null);
    setSelectedSubmissionNote(null);
  };

  const handleEditSubmission = (
    data: { title: string; note: string },
    onUploadProgress: (progressEvent: AxiosProgressEvent) => void,
    setIsUploading: React.Dispatch<React.SetStateAction<boolean>>,
  ) => {
    // for community task submission
    if (!selectedSubmissionId) return;
    updateCommunityTaskSubmission(originId, selectedSubmissionId, data, { onUploadProgress })
      .then(() => {
        setIsUploading(false);
        handleCloseEditSubmissionModal();
        notifySuccess(t('success.api.updated_task'));
        invalidateSingleCommunityTask(originId, taskId);
        invalidateCommunityTaskSubmissions(originId, taskId);
      })
      .catch((error) => {
        setIsUploading(false);
        notifyTranslatedError(t, error.response.data?.errorCode);
      });
  };

  // delete submission handler
  const handleOpenDeleteSubmissionConfirmation = (submissionId: string) => {
    setSelectedSubmissionId(submissionId);
    setDeleteSubmissionConfirmation(true);
  };

  const handleCloseDeleteSubmissionConfirmation = () => {
    setSelectedSubmissionId(null);
    setDeleteSubmissionConfirmation(false);
  };

  const handleDeleteSubmission = () => {
    // for community task submission
    if (!selectedSubmissionId) return;
    deleteCommunityTaskSubmission(originId, selectedSubmissionId)
      .then(() => {
        handleCloseDeleteSubmissionConfirmation();
        invalidateSingleCommunityTask(originId, taskId);
        invalidateCommunityTaskSubmissions(originId, taskId);
      })
      .catch((error) => {
        notifyTranslatedError(t, error.response.data?.errorCode);
      });
  };

  // remarks handler
  const handleOpenSubmissionRemarks = (submissionId: string) => {
    setSelectedSubmissionId(submissionId);
    setOpenSubmissionRemarks(true);
  };

  const handleCloseSubmissionRemarks = () => {
    setSelectedSubmissionId(null);
    setOpenSubmissionRemarks(false);
  };

  // add remark handler
  const handleOpenAddRemarkModal = (submissionId: string) => {
    setSelectedSubmissionId(submissionId);
    setOpenAddRemarkModal(true);
  };

  const handleCloseAddRemarkModal = () => {
    setSelectedSubmissionId(null);
    setOpenAddRemarkModal(false);
  };

  const handleAddSubmissionRemark = (data: { remark: string }) => {
    // for community task submission
    if (!originId || !selectedSubmissionId) return;
    addCommunitySubmissionRemark(originId, selectedSubmissionId, data)
      .then(() => {
        handleCloseAddRemarkModal();
        invalidateCommunityTaskSubmissions(originId, taskId);
        invalidateCommunityTaskRemarks(originId, selectedSubmissionId);
        notifySuccess(t('success.api.remark_updated'));
      })
      .catch((error) => {
        notifyTranslatedError(t, error.response.data?.errorCode);
      });
  };

  // edit remark handler
  const handleOpenEditRemarkModal = (remarkId: string, remarkValue: string) => {
    setSelectedRemarkId(remarkId);
    setSelectedRemarkValue(remarkValue);
    setOpenEditRemarkModal(true);
  };

  const handleCloseEditRemarkModal = () => {
    setSelectedRemarkId(null);
    setSelectedRemarkValue(null);
    setOpenEditRemarkModal(false);
  };

  const handleEditSubmissionRemark = (data: { remark: string }) => {
    // for community task submission
    if (!originId || !selectedRemarkId || !selectedSubmissionId) return;
    updateCommunitySubmissionRemark(originId, selectedRemarkId, data)
      .then(() => {
        handleCloseEditRemarkModal();
        invalidateCommunityTaskSubmissions(originId, taskId);
        invalidateCommunityTaskRemarks(originId, selectedSubmissionId);
        notifySuccess(t('success.api.remark_added'));
      })
      .catch((error) => {
        notifyTranslatedError(t, error.response.data?.errorCode);
      });
  };

  // delete remark handler
  const handleOpenDeleteRemarkConfirmation = (remarkId: string) => {
    setSelectedRemarkId(remarkId);
    setOpenDeleteRemarkConfirmation(true);
  };

  const handleCloseDeleteRemarkConfirmation = () => {
    setSelectedRemarkId(null);
    setOpenDeleteRemarkConfirmation(false);
  };

  const handleDeleteSubmissionRemark = () => {
    // for community task submission
    if (!selectedRemarkId || !selectedSubmissionId) return;
    deleteCommunitySubmissionRemark(originId, selectedRemarkId)
      .then(() => {
        invalidateCommunityTaskRemarks(originId, selectedSubmissionId);
        invalidateCommunityTaskSubmissions(originId, taskId);
        handleCloseDeleteRemarkConfirmation();
      })
      .catch((error) => {
        notifyTranslatedError(t, error.response.data?.errorCode);
      });
  };

  return (
    <div>
      <TaskSubmissions
        canManage={canManage}
        submissions={submissions}
        handleAddSubmission={handleOpenAddSubmissionModal}
        handleEditSubmission={handleOpenEditSubmissionModal}
        handleDeleteSubmission={handleOpenDeleteSubmissionConfirmation}
        handleOpenSubmissionRemarks={handleOpenSubmissionRemarks}
        handleAddSubmissionRemark={handleOpenAddRemarkModal}
      />

      {/* add submission modal */}
      {openAddSubmissionModal && (
        <SubmitTaskModal
          isOpen={openAddSubmissionModal}
          onClose={handleCloseAddSubmissionModal}
          handleSubmitTask={handleAddSubmission as $TSFix}
        />
      )}

      {/* edit submission modal */}
      {openEditSubmissionModal && selectedSubmissionTitle && (
        <SubmitTaskModal
          isEditing
          note={selectedSubmissionNote}
          title={selectedSubmissionTitle}
          isOpen={openEditSubmissionModal}
          onClose={handleCloseEditSubmissionModal}
          handleSubmitTask={handleEditSubmission as $TSFix}
        />
      )}

      {/* delete submission confiramtion */}
      {deleteSubmissionConfirmation && (
        <ConfirmationModal
          label={t('delete_submission')}
          onConfirm={handleDeleteSubmission}
          isOpen={deleteSubmissionConfirmation}
          title={t('delete_submission_confirmation_title')}
          onClose={handleCloseDeleteSubmissionConfirmation}
          description={
            t('delete_submission_confirmation_description') ||
            'This submission will be deleted permanently!'
          }
        />
      )}

      {/* submission remarks */}
      {openSubmissionRemarks && (
        <RemarksModal
          canManage={canManage}
          remarks={remarks ?? []}
          isOpen={openSubmissionRemarks}
          onClose={handleCloseSubmissionRemarks}
          handleEditRemark={handleOpenEditRemarkModal}
          handleDeleteRemark={handleOpenDeleteRemarkConfirmation}
        />
      )}

      {/* add submission remark */}
      {openAddRemarkModal && (
        <AddEditRemarkModal
          isOpen={openAddRemarkModal}
          onClose={handleCloseAddRemarkModal}
          handleSubmitRemark={handleAddSubmissionRemark}
        />
      )}

      {/* edit submission remark */}
      {openEditRemarkModal && selectedRemarkValue && (
        <AddEditRemarkModal
          isEditing
          remark={selectedRemarkValue}
          isOpen={openEditRemarkModal}
          onClose={handleCloseEditRemarkModal}
          handleSubmitRemark={handleEditSubmissionRemark}
        />
      )}

      {/* delete submission remark */}
      {openDeleteRemarkConfirmation && (
        <ConfirmationModal
          label={t('delete_remark')}
          isOpen={openDeleteRemarkConfirmation}
          onConfirm={handleDeleteSubmissionRemark}
          onClose={handleCloseDeleteRemarkConfirmation}
          description={
            t('delete_remark_confirmation_description') ||
            'This remark will be paramanently removed!'
          }
          title={t('delete_remark_confirmation_title')}
        />
      )}
    </div>
  );
};

export default TestPage;
