import MembersIcon from 'assets/icons/members.svg';
import BodyText from 'components/typography/BodyText';
import Heading from 'components/typography/Heading';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { MemberRoleType } from 'types/apis/common';
import { canManageCommunity } from 'utils/helper';
import { generateCommunityLink } from 'utils/url';
import RichTextEditor from 'components/common/RichTextEditor';

interface IGroupCard {
  groupId: string;
  groupName: string;
  groupMembers: number;
  groupDescription: string;
  groupRole: MemberRoleType;
  menuItems: React.ReactNode;
  canManage?: boolean;
}

const GroupCard = ({
  groupId,
  groupRole,
  groupName,
  menuItems,
  canManage,
  groupMembers,
  groupDescription,
}: IGroupCard) => {
  const { t } = useTranslation();

  return (
    <Link
      to={generateCommunityLink(groupId)}
      className='rounded-xl border border-neutral-100 bg-neutral-200 p-2 shadow shadow-neutral-300 hover:shadow-lg hover:border-primary-500 active:border-primary-200'
    >
      <div className='mb-2 flex items-center justify-between'>
        <Heading variant='h3' className='font-semibold'>
          {groupName}
        </Heading>

        {(canManageCommunity(groupRole) || canManage) && <div className='z-40'>{menuItems}</div>}
      </div>

      <div className='mb-2 max-h-8 overflow-hidden'>
        <RichTextEditor initialContent={groupDescription} isReadOnly={true} />
      </div>

      <div className='flex items-center gap-2'>
        <MembersIcon className='h-4 w-4 text-neutral-500' />
        <BodyText variant='sm' className='text-neutral-500'>
          {groupMembers} {t('members')}
        </BodyText>
      </div>
    </Link>
  );
};

export default GroupCard;
