import Loading from 'components/Loading';
import NavigateTo404 from 'components/NavigateTo404';
import { useProgramEvent } from 'hooks/api/event/useProgramEvent';
import { useInvalidateQueries } from 'hooks/common/useInvalidatequeries';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { updateProgramEvent } from 'utils/api/event';
import { notifySuccess, notifyTranslatedError } from 'utils/notify';
import { generateProgramEventLink } from 'utils/url';
import EditEventForm from '../event/EditEventForm';

const EditProgramEventPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { programId, eventId } = useParams();

  const { data: event, isLoading: isEventLoading } = useProgramEvent(
    programId || '',
    eventId || '',
  );
  const { invalidateProgramEvents, invalidateSingleProgramEvent, invalidateUpcomingProgramEvents } =
    useInvalidateQueries();

  if (isEventLoading) return <Loading />;
  if (!event) return <NavigateTo404 />;

  const {
    id: eventUid,
    title,
    about,
    cover,
    isAllDay,
    startTime,
    endTime,
    location,
    type,
    zoomLink,
    timezone,
    eventContactUser,
  } = event;
  let eventContactUserId, contactUserFirstName, contactUserLastName, contactUserProfilePicture;
  if (eventContactUser) {
    ({
      id: eventContactUserId,
      firstName: contactUserFirstName,
      lastName: contactUserLastName,
      profilePicture: contactUserProfilePicture,
    } = eventContactUser);
  }
  const handleEditEvent = (eventData: FormData) => {
    if (!programId) return;

    updateProgramEvent(programId, eventUid, eventData)
      .then(() => {
        invalidateProgramEvents(programId);
        invalidateUpcomingProgramEvents(programId);
        invalidateSingleProgramEvent(programId, eventUid);
        notifySuccess(t('success.api.event_updated'));
        navigate(generateProgramEventLink(programId, eventUid));
      })
      .catch((error) => {
        notifyTranslatedError(t, error.response.data?.errorCode);
      });
  };

  return (
    <EditEventForm
      eventType={type}
      eventCover={cover}
      eventTitle={title}
      eventEndTime={endTime}
      eventTimezone={timezone}
      eventDescription={about}
      eventLink={zoomLink || ''}
      eventIsAllDay={isAllDay || false}
      eventStartTime={startTime}
      eventLocation={location || ''}
      eventContactUserAvatar={contactUserProfilePicture || ''}
      eventContactUserId={eventContactUserId || ''}
      eventContactUserFirstName={contactUserFirstName}
      eventContactUserLastName={contactUserLastName}
      handleEditEvent={handleEditEvent}
    />
  );
};

export default EditProgramEventPage;
