import { useQuery } from '@tanstack/react-query';
import { getSingleCommunity, getSinglePublicCommunity } from 'utils/api/community';

export const useCommunity = (communityId: string) =>
  useQuery({
    queryKey: ['single-community', communityId],
    queryFn: () => getSingleCommunity(communityId),
    select: (data) => data.data.community,
    enabled: !!communityId,
  });

export const useSinglePublicCommunity = (communityId: string, inviteToken?: string) => {
  return useQuery({
    queryKey: ['single-public', communityId, inviteToken],
    queryFn: () => getSinglePublicCommunity(communityId, inviteToken),
    select: (data) => data.data.community,
    enabled: !!communityId,
  });
};
