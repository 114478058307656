import React from 'react';
import LoadingIcon from 'assets/icons/loading.svg';
import { useTranslation } from 'react-i18next';
import BodyText from './typography/BodyText';

const Uploading = ({ progress }: { progress: number }) => {
  const { t } = useTranslation();

  return (
    <div className='mt-2'>
      <div className='mb-2 flex items-center'>
        <LoadingIcon className='h-5 w-5' />
        <BodyText as='span' variant='sm'>
          {t('sending-file')}
        </BodyText>
      </div>
      {progress === 100 && (
        <div className='flex items-center w-full justify-center'>
          <LoadingIcon className='h-5 w-5' />
          <BodyText as='span' variant='sm'>
            {t('uploading-to-server')}
          </BodyText>
        </div>
      )}
    </div>
  );
};

export default Uploading;
