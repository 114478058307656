import CloseIcon from 'assets/icons/close.svg';
import Button from 'components/button/Button';
import TextInput from 'components/form/TextInput';
import BodyText from 'components/typography/BodyText';
import Heading from 'components/typography/Heading';
import React, { useEffect } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import ModalContainer from './ModalContainer';

interface IAddEditRemarkModal {
  isOpen: boolean;
  remark?: string;
  isEditing?: boolean;
  onClose: () => void;
  handleSubmitRemark: (data: { remark: string }) => void;
}

const AddEditRemarkModal = ({
  isOpen,
  remark,
  onClose,
  isEditing,
  handleSubmitRemark,
}: IAddEditRemarkModal) => {
  const { t } = useTranslation();

  const {
    control,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm<{ remark: string }>();

  const onSubmit: SubmitHandler<{ remark: string }> = (data) => {
    handleSubmitRemark(data);
  };

  useEffect(() => {
    if (!isEditing || !remark) return;
    setValue('remark', remark);
  }, []);

  return (
    <ModalContainer
      title={isEditing ? t('edit_remark') : t('add_remark')}
      isOpen={isOpen}
      onClose={onClose}
    >
      <form onSubmit={handleSubmit(onSubmit)} className='space-y-5'>
        <div className='space-y-2'>
          <BodyText
            variant='sm'
            className={`font-medium ${
              errors.remark ? 'text-functional-danger-400' : 'text-neutral-500'
            }`}
          >
            {t('remark')}
          </BodyText>

          <Controller
            name='remark'
            control={control}
            rules={{ required: true }}
            render={({ field }) => (
              <TextInput
                autoFocus
                {...field}
                placeholder={t('remark') || 'Add remark'}
                className={errors.remark ? 'border-functional-danger-400' : ''}
              />
            )}
          />
        </div>

        <div className='mt-5 flex items-center justify-end gap-3 border-t border-neutral-200 pt-5'>
          <Button type='button' size='small' variant='outlined' onClick={onClose}>
            {t('cancel')}
          </Button>
          <Button type='submit' size='small' variant='secondary'>
            {isEditing ? t('save') : t('add_remark')}
          </Button>
        </div>
      </form>
    </ModalContainer>
  );
};

export default AddEditRemarkModal;
