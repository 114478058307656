import React, { useCallback, useState } from 'react';
import SearchIcon from 'assets/icons/search.svg';
import debounce from 'lodash/debounce';
import { useTranslation } from 'react-i18next';

interface ISearchInput {
  className?: string;
  onSearch: (value: string) => void;
}

const SearchInput = ({ className = '', onSearch }: ISearchInput) => {
  const [searchValue, setSearchValue] = useState('');
  const { t } = useTranslation();

  const handleSearch = (value: string) => {
    onSearch(value);
  };

  const debounceSearch = useCallback(debounce(handleSearch, 300), []);

  const handleInputValue = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(e.target.value);
    debounceSearch(e.target.value);
  };

  return (
    <div
      className={`flex items-center gap-2 rounded-lg border border-neutral-300 bg-neutral-200 p-1 ${className}`}
    >
      <SearchIcon className='h-4 w-4 text-neutral-600' />
      <input
        type='text'
        className='grow bg-[transparent] text-sm outline-none'
        value={searchValue}
        placeholder={t('search') || 'Search'}
        onChange={handleInputValue}
      />
    </div>
  );
};

export default SearchInput;
