import { useQuery } from '@tanstack/react-query';
import { getUnseenNotificatoins } from 'utils/api/notification';
import { SIXTY_SECONDS } from 'utils/time';

export const useUnseenNotifications = () => {
  return useQuery({
    queryKey: ['unseen-notifications'],
    queryFn: () => getUnseenNotificatoins(),
    select: (data) => data.data.unseen,
    refetchInterval: SIXTY_SECONDS,
    staleTime: 0,
  });
};
