import { useQuery } from '@tanstack/react-query';
import { getConversation } from 'utils/api/message';

export const useChatbotId = (conversationId: string) => {
  const { data } = useQuery({
    queryKey: ['conversation', conversationId],
    queryFn: () => getConversation(conversationId),
    select: (data) => data.data,
  });
  const participants = data?.participants;

  let chatbotId;
  if (participants) {
    const chatbotsInConversation = participants.filter((user) => {
      if (user?.userType == 'chatbot') {
        return true;
      }
      return false;
    });
    if (chatbotsInConversation.length >= 1) {
      // TODO: decide what to do in this situation
      chatbotId = chatbotsInConversation[0].id;
    }
    return chatbotId;
  } else {
    return;
  }
};
