import Logo from 'assets/images/logo.svg';
import LikeIcon from 'assets/icons/like.svg';
import Avatar from 'components/memberAndProfile/Avatar';
import CloseIcon from 'assets/icons/close.svg';
import LanguageSelector from 'components/layout/LanguageSelector';
import BodyText from 'components/typography/BodyText';
import Heading from 'components/typography/Heading';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';
import InviteAuthCover from './segment/InviteAuthCover';
import { useSinglePublicProgram } from 'hooks/api/program/useSingleProgram';
import { useSinglePublicCommunity } from 'hooks/api/community/useCommunity';
import SignUpForm from 'pages/segment/inviteLanding/signUp';
import LoginForm from 'pages/segment/inviteLanding/login';
import useIsLoggedIn from 'hooks/api/auth/useIsLoggedIn';
import Button from 'components/button/Button';
import { acceptInvitation } from 'utils/api/community';
import { AcceptInvitationRequestType } from 'types/apis/request';
import { notifySuccess, notifyTranslatedError } from 'utils/notify';

const InviteLandingPage = () => {
  const { t } = useTranslation();
  const { data: user } = useIsLoggedIn();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const token = decodeURIComponent(searchParams.get('token') || '');
  const encodedemail = decodeURIComponent(searchParams.get('email') || '');
  const originId = searchParams.get('originId') || '';
  const originType = searchParams.get('originType') || '';
  const inviteToken = searchParams.get('inviteToken') || '';
  const { data: program, isLoading: isProgramLoading } =
    originType === 'program'
      ? useSinglePublicProgram(originId || '', inviteToken)
      : { data: null, isLoading: false };
  const { data: community, isLoading: isCommunityLoading } =
    originType === 'community'
      ? useSinglePublicCommunity(originId || '', inviteToken)
      : { data: null, isLoading: false };
  const entity = originType === 'program' ? program : community;
  const [formType, setFormType] = useState<'signup' | 'login'>('signup');

  const handleAcceptInvite = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.preventDefault();
    const data: AcceptInvitationRequestType = {
      originId: originId,
      originType: originType,
      inviteToken: inviteToken,
      userId: user?.id,
    };
    acceptInvitation(data)
      .then(() => {
        notifySuccess(t('success.api.invite_accepted'));
        navigate('/');
        navigate(0);
      })
      .catch((error) => {
        notifyTranslatedError(t, error.response.data?.errorCode);
        notifyTranslatedError(t, error.response.data?.message);
      });
  };

  return (
    <main className='grid h-screen grid-cols-5'>
      <InviteAuthCover originId={originId} originType={originType} />
      <section className='col-span-5 flex flex-col pb-10 lg:col-span-2 bg-neutral-100'>
        <div className='flex items-center justify-between px-5 py-3 lg:mb-0'>
          <div className='flex items-center gap-1'>
            <div className='flex items-center rounded p-1'>
              <Logo className='h-[20px] w-[20px]' />
            </div>
            <a href='https://www.studycentral.eu/'>
              <BodyText variant='sm' className='text-neutral-600'>
                STUDY<span className='font-bold'>CENTRAL</span>
              </BodyText>
            </a>
          </div>
          <LanguageSelector />
        </div>
        <BodyText variant='base' className='mx-4 lg:hidden'>
          {t('invite-signup-title')}:
        </BodyText>
        <div className='mx-4 flex items-start items-center rounded-lg bg-neutral-200 p-2 shadow-lg shadow-neutral-300 lg:hidden'>
          {entity?.organization.logo && (
            <Avatar className='mr-3' size={48} src={entity?.organization.logo} />
          )}
          <div>
            <BodyText variant='base' className='text-semibold'>
              {entity?.organization.name}
            </BodyText>
            <Heading variant='h2' className='mb-2 '>
              {entity?.name}
            </Heading>
          </div>
        </div>

        <div className='mt-[70px] flex w-full flex-col justify-center px-4 md:px-5 lg:flex xl:px-0'>
          {user ? (
            <div className='mb-6 flex flex-col items-center'>
              <Heading variant='h1' className='text-semibold mb-6 mt-6 text-secondary-500'>
                {t('welcome-back')}
              </Heading>
              <div className='mb-6 flex w-full items-center justify-center gap-2'>
                {user.profilePicture && (
                  <Avatar className='mr-5' size={50} src={user.profilePicture} />
                )}
                <div>
                  <Heading variant='h2' className='mb-2 '>
                    {user.firstName}
                  </Heading>
                </div>
              </div>
              <Button
                size='large'
                variant='outlined'
                className='w-[200px]'
                onClick={() => navigate('/')}
              >
                <CloseIcon className='h-4 w-4' />{' '}
                <span className='md:hidden xl:block'>{t('cancel')}</span>
              </Button>
              <Button
                size='large'
                variant='secondary'
                className='mt-6 w-[200px]'
                onClick={handleAcceptInvite}
              >
                <LikeIcon className='h-5 w-5' />{' '}
                <span className='md:hidden xl:block'>{t('accept-invitation')}</span>
              </Button>
            </div>
          ) : (
            <div className='mb-6 flex w-full items-center justify-center gap-2'>
              <button
                type='button'
                className={`flex max-w-[200px] grow items-center gap-4 rounded-xl border-2  bg-neutral-200 p-4 hover:bg-neutral-200 ${
                  formType === 'signup' ? 'border-secondary-500' : 'border-neutral-400'
                }`}
                onClick={() => setFormType('signup')}
              >
                <input type='radio' value='new' checked={formType === 'signup'} />
                <BodyText variant='sm' className='font-medium text-neutral-500'>
                  {t('new-user')}
                </BodyText>
              </button>
              <button
                type='button'
                className={`flex max-w-[200px] grow items-center gap-4 rounded-xl border-2  bg-neutral-200 p-4 hover:bg-neutral-200 ${
                  formType === 'login' ? 'border-secondary-500' : 'border-neutral-400'
                }`}
                onClick={() => setFormType('login')}
              >
                <input type='radio' value='existing' checked={formType === 'login'} />
                <BodyText variant='sm' className='font-medium text-neutral-500 '>
                  {t('existing-user')}
                </BodyText>
              </button>
            </div>
          )}
          {!user && formType === 'signup' && (
            <SignUpForm
              token={token}
              encodedemail={encodedemail}
              inviteToken={inviteToken}
              originId={originId}
              originType={originType}
            />
          )}
          {!user && formType === 'login' && (
            <LoginForm inviteToken={inviteToken} originId={originId} originType={originType} />
          )}
        </div>
      </section>
    </main>
  );
};

export default InviteLandingPage;
