import { API_ROOT } from 'constants/config';
import { AxiosRequestConfig } from 'axios';
import { generateQueryParam, parseDELETE, parseGET, parsePOST, parsePUT } from 'utils/rest';
import {
  JoinRequestsResponseType,
  CommunityManagersResponseType,
  CommunityMembersResponseType,
  CommunityPendingInvitesResponseType,
  ProgramManagersResponseType,
  ProgramPendingInvitesResponseType,
  ProgramMembersResponseType,
  getAllMembersResponseType,
} from 'types/apis/response';

export const getCommunityMembers = (communityId: string, options?: AxiosRequestConfig) =>
  parseGET<CommunityMembersResponseType>(`${API_ROOT}/community/${communityId}/members`, {
    withCredentials: true,
    ...options,
  });

export const getProgramMembers = (programId: string, options?: AxiosRequestConfig) =>
  parseGET<ProgramMembersResponseType>(`${API_ROOT}/program/${programId}/members`, {
    withCredentials: true,
    ...options,
  });

export const getCommunityManagers = (communityId: string, options?: AxiosRequestConfig) =>
  parseGET<CommunityManagersResponseType>(`${API_ROOT}/community/${communityId}/managers`, {
    withCredentials: true,
    ...options,
  });

export const getProgramManagers = (programId: string, options?: AxiosRequestConfig) =>
  parseGET<ProgramManagersResponseType>(`${API_ROOT}/program/${programId}/managers`, {
    withCredentials: true,
    ...options,
  });

export const inviteCommunityMember = (
  communityId: string,
  body: FormData | { email: string },
  options?: AxiosRequestConfig,
) =>
  parsePOST(`${API_ROOT}/community/${communityId}/members`, body, {
    withCredentials: true,
    ...options,
  });

export const inviteProgramMember = (
  programId: string,
  body: FormData,
  options?: AxiosRequestConfig,
) =>
  parsePOST(`${API_ROOT}/program/${programId}/members/invites`, body, {
    withCredentials: true,
    ...options,
  });

export const addCommunityManager = (
  communityId: string,
  body: { email: string },
  options?: AxiosRequestConfig,
) =>
  parsePOST(`${API_ROOT}/community/${communityId}/managers`, body, {
    withCredentials: true,
    ...options,
  });

export const addProgramManager = (
  programId: string,
  body: { email: string },
  options?: AxiosRequestConfig,
) =>
  parsePOST(`${API_ROOT}/program/${programId}/managers`, body, {
    withCredentials: true,
    ...options,
  });

export const removeCommunityMember = (
  communityId: string,
  memberId: string,
  options?: AxiosRequestConfig,
) =>
  parseDELETE(`${API_ROOT}/community/${communityId}/remove/members/${memberId}`, {
    withCredentials: true,
    ...options,
  });

export const removeProgramMember = (
  programId: string,
  memberId: string,
  options?: AxiosRequestConfig,
) =>
  parseDELETE(`${API_ROOT}/program/${programId}/remove/members/${memberId}`, {
    withCredentials: true,
    ...options,
  });

export const joinCommunity = (communityId: string, options?: AxiosRequestConfig) =>
  parsePOST(
    `${API_ROOT}/community/${communityId}/join-request`,
    {},
    { ...options, withCredentials: true },
  );

export const joinProgram = (programId: string, options?: AxiosRequestConfig) =>
  parsePOST(
    `${API_ROOT}/program/${programId}/join-request`,
    {},
    { ...options, withCredentials: true },
  );

export const leaveCommunity = (communityId: string, options?: AxiosRequestConfig) =>
  parseDELETE(`${API_ROOT}/community/${communityId}/leave`, {
    withCredentials: true,
    ...options,
  });

export const leaveProgram = (programId: string, options?: AxiosRequestConfig) =>
  parseDELETE(`${API_ROOT}/program/${programId}/leave`, {
    withCredentials: true,
    ...options,
  });

export const deleteCommunityManager = (
  communityId: string,
  managerId: string,
  options?: AxiosRequestConfig,
) =>
  parseDELETE(`${API_ROOT}/community/${communityId}/managers/${managerId}`, {
    withCredentials: true,
    ...options,
  });

export const deleteProgramManager = (
  programId: string,
  managerId: string,
  options?: AxiosRequestConfig,
) =>
  parseDELETE(`${API_ROOT}/program/${programId}/remove/members/${managerId}`, {
    withCredentials: true,
    ...options,
  });

export const getPendingInvites = (
  communityId: string,
  limit = 20,
  cursor?: string,
  options?: AxiosRequestConfig,
) => {
  const queryParam = generateQueryParam({ limit, cursor });
  return parseGET<CommunityPendingInvitesResponseType>(
    `${API_ROOT}/community/${communityId}/invites${queryParam}`,
    {
      ...options,
      withCredentials: true,
    },
  );
};

export const getProgramPendingInvites = (
  programId: string,
  limit?: number,
  cursor?: string,
  options?: AxiosRequestConfig,
) => {
  const queryParam = generateQueryParam({ limit, cursor });
  return parseGET<ProgramPendingInvitesResponseType>(
    `${API_ROOT}/program/${programId}/invites${queryParam}`,
    {
      ...options,
      withCredentials: true,
    },
  );
};

export const deletePendingInvite = (
  communityId: string,
  inviteId: string,
  options?: AxiosRequestConfig,
) =>
  parseDELETE(`${API_ROOT}/community/${communityId}/invites/${inviteId}`, {
    ...options,
    withCredentials: true,
  });

export const deleteProgramPendingInvite = (
  programId: string,
  inviteId: string,
  options?: AxiosRequestConfig,
) =>
  parseDELETE(`${API_ROOT}/program/${programId}/invites/${inviteId}`, {
    ...options,
    withCredentials: true,
  });

export const getJoinRequests = (
  communityId: string,
  limit = 20,
  cursor?: string,
  options?: AxiosRequestConfig,
) => {
  const queryParam = generateQueryParam({ limit, cursor });
  return parseGET<JoinRequestsResponseType>(
    `${API_ROOT}/community/${communityId}/join-request${queryParam}`,
    {
      ...options,
      withCredentials: true,
    },
  );
};

export const getProgramJoinRequests = (
  programId: string,
  limit?: number,
  cursor?: string,
  options?: AxiosRequestConfig,
) => {
  const queryParam = generateQueryParam({ limit, cursor });
  return parseGET<JoinRequestsResponseType>(
    `${API_ROOT}/program/${programId}/join-request${queryParam}`,
    {
      ...options,
      withCredentials: true,
    },
  );
};

export const acceptCommunityJoinRequest = (
  communityId: string,
  requestId: string,
  options?: AxiosRequestConfig,
) =>
  parsePUT(
    `${API_ROOT}/community/${communityId}/join-request/${requestId}/accept`,
    {},
    {
      ...options,
      withCredentials: true,
    },
  );

export const acceptProgramJoinRequest = (
  programId: string,
  requestId: string,
  options?: AxiosRequestConfig,
) =>
  parsePUT(
    `${API_ROOT}/program/${programId}/join-request/${requestId}/accept`,
    {},
    {
      ...options,
      withCredentials: true,
    },
  );

export const rejectCommunityJoinRequest = (
  communityId: string,
  requestId: string,
  options?: AxiosRequestConfig,
) =>
  parsePUT(
    `${API_ROOT}/community/${communityId}/join-request/${requestId}/reject`,
    {},
    {
      ...options,
      withCredentials: true,
    },
  );

export const rejectProgramJoinRequest = (
  programId: string,
  requestId: string,
  options?: AxiosRequestConfig,
) =>
  parsePUT(
    `${API_ROOT}/program/${programId}/join-request/${requestId}/reject`,
    {},
    {
      ...options,
      withCredentials: true,
    },
  );

export const getActiveMembers = (options?: AxiosRequestConfig) => {
  return parseGET<getAllMembersResponseType>(`${API_ROOT}/members/active`, {
    ...options,
    withCredentials: true,
  });
};

export const getArchivedMembers = (options?: AxiosRequestConfig) => {
  return parseGET<getAllMembersResponseType>(`${API_ROOT}/members/archived`, {
    ...options,
    withCredentials: true,
  });
};
