import { useInfiniteQuery, useQuery } from '@tanstack/react-query';
import { getMessages, getLastRead } from 'utils/api/message';
import { THIRTY_SECONDS } from 'utils/time';

export const useMessages = (conversationId: string, limit = 20) => {
  const { data, isLoading, isFetchingNextPage, isRefetching, hasNextPage, fetchNextPage, refetch } =
    useInfiniteQuery({
      queryKey: ['messages', conversationId],
      queryFn: ({ pageParam = '' }) => getMessages(conversationId, limit, pageParam),
      getNextPageParam: (lastPage) => lastPage.data.cursor,
      refetchInterval: THIRTY_SECONDS,
      staleTime: THIRTY_SECONDS,
    });

  return {
    messages:
      data?.pages
        .slice()
        .reverse()
        .flatMap((item) => item.data.messages) ?? [],
    hasNextMessages: hasNextPage,
    fetchNextMessages: fetchNextPage,
    refetchMessages: refetch,
    isMessagesLoading: isLoading || isRefetching || isFetchingNextPage,
  };
};

export const useLastRead = (conversationId: string) =>
  useQuery({
    queryKey: ['lastread', conversationId],
    queryFn: () => getLastRead(conversationId),
    select: (data) => data.data,
    enabled: !!conversationId,
  });
