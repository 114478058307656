import CloseIcon from 'assets/icons/close.svg';
import { useExpertise } from 'hooks/api/profile/useExpertise';
import { useOuterClick } from 'hooks/common/useOuterClick';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { notifyError } from 'utils/notify';

interface IExpertiseMultiSelect {
  tags: string[];
  className?: string;
  setTags: (values: string[]) => void;
}

const ExpertiseMultiSelect = ({ tags, setTags, className = '' }: IExpertiseMultiSelect) => {
  const { t } = useTranslation();
  const listContainerRef = React.useRef<HTMLDivElement>(null);

  const [value, setValue] = useState('');
  const [showExpertise, setShowExpertise] = useState(false);

  const { data: expertise } = useExpertise();
  useOuterClick(listContainerRef, () => setShowExpertise(false));

  const handleAddTag = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      if (value === '') return;

      const alreadyExists = tags.find((tag) => tag.toLowerCase() === value.toLowerCase());

      if (alreadyExists) {
        notifyError(t('ui.error.tag_already_exists'));
      } else {
        setTags([...tags, value]);
        setValue('');
      }
    }
  };

  const handleAddExpertise = (expertise: string) => {
    const alreadyExists = tags.includes(expertise);

    if (alreadyExists) {
      setTags(tags.filter((tag) => tag !== expertise));
    } else {
      setTags([...tags, expertise]);
    }
  };

  const handleRemoveTag = (tag: string) => {
    setTags(tags.filter((existingTag) => existingTag !== tag));
  };

  return (
    <div ref={listContainerRef} className='relative w-full'>
      <div
        className={`flex w-full flex-col gap-3 rounded-lg border  border-neutral-300 bg-neutral-200 py-[11px] px-[14px] text-base ${className}`}
      >
        {tags.length > 0 && (
          <ul className='flex flex-wrap items-center gap-3'>
            {tags.map((tag) => (
              <li
                key={tag}
                className='flex items-center gap-2 rounded-md border border-neutral-300 bg-neutral-100 p-1'
              >
                {tag}
                <button
                  className='relative flex h-4 w-4 items-center justify-center rounded-full leading-4 text-neutral-400 hover:bg-neutral-300 active:bg-neutral-400 active:text-neutral-100'
                  onClick={() => handleRemoveTag(tag)}
                >
                  <CloseIcon className='h-2 w-2' />
                </button>
              </li>
            ))}
          </ul>
        )}

        <input
          type='text'
          value={value}
          onKeyUp={handleAddTag}
          placeholder={t('insert_tag_placeholder') || 'Press enter to add...'}
          onChange={(e) => setValue(e.target.value)}
          className='w-full bg-transparent text-neutral-600 outline-none'
          onFocus={() => setShowExpertise(true)}
        />
      </div>

      {showExpertise && expertise && (
        <div className='absolute z-10 max-h-[200px] w-full space-y-1 overflow-y-auto rounded-md border border-neutral-200 bg-neutral-100 p-[6px]'>
          {expertise.map(({ name }) => (
            <button
              key={name}
              className={`flex w-full items-center gap-4 rounded-md px-3 py-2 text-left hover:bg-neutral-300 ${
                tags.includes(name) ? 'bg-neutral-300' : ''
              }`}
              onClick={() => handleAddExpertise(name)}
            >
              {name}
            </button>
          ))}
        </div>
      )}
    </div>
  );
};

export default ExpertiseMultiSelect;
