import { Menu } from '@headlessui/react';
import MoreIcon from 'assets/icons/option.svg';
import React, { ReactElement, SVGProps } from 'react';

export const ContextMenu = ({
  menuItems,
}: {
  menuItems: {
    key: string;
    label: string;
    icon: (props: SVGProps<SVGElement>) => ReactElement;
    callback: () => void;
    show?: boolean;
  }[];
}) => {
  return (
    <Menu as='div' className='relative'>
      <Menu.Button className='neutral-button flex h-6 w-6 items-center justify-center rounded-full'>
        <MoreIcon className='h-4 w-4 ' />
      </Menu.Button>

      <Menu.Items className='absolute right-0 z-40 shadow-lg shadow-neutral-300'>
        <div className='min-w-[150px] space-y-5 rounded bg-neutral-100 py-2 shadow shadow-neutral-300'>
          <div className='space-y-2'>
            {menuItems.map(({ key, label, callback, icon: Icon, show = true }) => {
              if (!show) return null;
              return (
                <Menu.Item key={key}>
                  <button
                    onClick={(e) => {
                      e.preventDefault();
                      callback();
                    }}
                    className='flex w-full items-center justify-between gap-4 whitespace-nowrap px-4 py-2 hover:bg-neutral-200'
                  >
                    <span className='text-neutral-600'>{label}</span>
                    <Icon className='h-5 w-5 text-neutral-400' />
                  </button>
                </Menu.Item>
              );
            })}
          </div>
        </div>
      </Menu.Items>
    </Menu>
  );
};
