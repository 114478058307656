import EditIcon from 'assets/icons/edit.svg';
import Avatar from 'components/memberAndProfile/Avatar';
import FileIcon from 'assets/icons/upload.svg';
import Button from 'components/button/Button';
import SearchIcon from 'assets/icons/search.svg';
import ImageIcon from 'assets/icons/image.svg';
import Textarea from 'components/form/Textarea';
import TextInput from 'components/form/TextInput';
import BodyText from 'components/typography/BodyText';
import { profileBanner, userAvatar } from 'constants/common';
import { useProfileInfo } from 'hooks/api/profile/useProfileInfo';
import { useInvalidateQueries } from 'hooks/common/useInvalidatequeries';
import React, { useEffect, useState } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { EditProfileFormInputType } from 'types/components/form';
import { updateMyProfileInfo } from 'utils/api/profile';
import { notifySuccess, notifyTranslatedError } from 'utils/notify';
import { objToFormData } from 'utils/rest';
import ImageSearchModal from 'components/modal/ImageSearchModal';
import { DropdownMenu } from 'components/common/DropdownMenu';

type EditProfileProps = {
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

const EditProfile = ({ setIsOpen }: EditProfileProps) => {
  const { t } = useTranslation();
  const coverInputRef = React.useRef<HTMLInputElement>(null);
  const profilePictureInputRef = React.useRef<HTMLInputElement>(null);
  const [showImageSearchModal, setShowImageSearchModal] = useState(false);
  const [onSelectImage, setOnSelectImage] = useState<string | null>(null);

  const { data, refetch } = useProfileInfo();
  const { invalidateMe } = useInvalidateQueries();

  const {
    watch,
    control,
    setValue,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<EditProfileFormInputType>();

  const onSubmit: SubmitHandler<EditProfileFormInputType> = (data) => {
    const { cover, profilePicture, ...rest } = data;
    let newData = { ...rest };

    const formData = objToFormData(newData);

    if (cover && typeof cover !== 'string') {
      formData.append('cover', cover[0]);
    } else if (onSelectImage) {
      formData.append('coverImage', onSelectImage);
    }

    if (profilePicture && typeof profilePicture !== 'string') {
      formData.append('profilePic', profilePicture[0]);
    }

    updateMyProfileInfo(formData)
      .then(() => {
        refetch();
        invalidateMe();
        notifySuccess(t('success.api.profile_updated'));
        setIsOpen(false);
      })
      .catch((error) => {
        notifyTranslatedError(t, error.response.data?.errorCode);
      });
  };

  const handleSelectCoverFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files?.length) return;
    setValue('cover', e.target.files);
  };

  const handleSelectProfilePictureFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files?.length) return;
    setValue('profilePicture', e.target.files);
  };

  const handleRemoveCover = () => {
    setValue('cover', undefined);
  };

  useEffect(() => {
    if (!data) return;
    const { firstName, lastName, cover, profilePicture, headline, about } = data;

    setValue('firstName', firstName);
    setValue('lastName', lastName);
    setValue('headline', headline || '');
    setValue('about', about || '');
    setValue('cover', cover);
    setValue('profilePicture', profilePicture);
  }, [data]);

  return (
    <div className='space-y-3'>
      <form onSubmit={handleSubmit(onSubmit)} className='space-y-3'>
        <div className='h-fit rounded-2xl bg-neutral-200 shadow shadow-neutral-300 '>
          <div className='relative'>
            {/* cover already exist */}
            {watch('cover') && typeof watch('cover') !== 'string' && (
              <img
                src={URL.createObjectURL((watch('cover') as FileList)[0])}
                alt=''
                className='h-[200px] w-full rounded-lg object-cover'
              />
            )}
            {watch('cover') && typeof watch('cover') === 'string' && (
              <img
                src={watch('cover') as string}
                alt=''
                className='h-[200px] w-full rounded-lg object-cover'
              />
            )}
            {!watch('cover') && (
              <img
                src={onSelectImage ? onSelectImage : profileBanner}
                alt=''
                className='h-[200px] w-full rounded-lg object-cover'
              />
            )}

            <div className='absolute right-5 top-4 flex items-center gap-4'>
              {watch('cover') && (
                <button
                  type='button'
                  className='btn btn-size-small bg-neutral-300'
                  onClick={handleRemoveCover}
                >
                  {t('remove_cover')}
                </button>
              )}
              <div className='z-40'>
                <DropdownMenu
                  menuButton={
                    <Button size='small' type='button' variant='primary' className=''>
                      <ImageIcon className='h-4 w-4' /> {t('change_cover')}
                    </Button>
                  }
                  menuItems={[
                    {
                      key: 'upload_cover',
                      label: t('upload-image'),
                      icon: FileIcon,
                      callback: () => coverInputRef.current?.click(),
                    },
                    {
                      key: 'share_to_web',
                      label: t('search-stock-image'),
                      icon: SearchIcon,
                      callback: () => setShowImageSearchModal(true),
                    },
                  ]}
                />
              </div>
            </div>

            <input
              type='file'
              className='hidden'
              {...register('cover', {
                onChange: (e) => handleSelectCoverFile(e),
              })}
              ref={coverInputRef}
              accept='image/png, image/jpeg'
            />
          </div>

          <div className='relative -mt-13 px-6 py-4'>
            <div className='relative w-fit rounded-full border-4 border-white'>
              {/* no profile picture */}
              {!watch('profilePicture') && (
                <Avatar
                  className='z-10'
                  size={100}
                  src={userAvatar}
                  alt={`${watch('firstName')} ${watch('lastName')}`}
                />
              )}

              {/* profile picture already exist */}
              {watch('profilePicture') && typeof watch('profilePicture') === 'string' && (
                <Avatar
                  className='z-10'
                  size={100}
                  alt={`${watch('firstName')} ${watch('lastName')}`}
                  src={watch('profilePicture') as string}
                />
              )}

              {/* locally selected profile picture */}
              {watch('profilePicture') && typeof watch('profilePicture') !== 'string' && (
                <Avatar
                  className='z-10'
                  size={100}
                  alt={`${watch('firstName')} ${watch('lastName')}`}
                  src={URL.createObjectURL((watch('profilePicture') as FileList)[0])}
                />
              )}

              <button
                type='button'
                className='absolute bottom-1 right-0 rounded-full bg-neutral-600 p-1 '
                onClick={() => profilePictureInputRef.current?.click()}
              >
                <EditIcon className='h-4 w-4 text-neutral-100' />
              </button>
            </div>

            <input
              type='file'
              className='hidden'
              {...register('profilePicture', {
                onChange: (e) => handleSelectProfilePictureFile(e),
              })}
              ref={profilePictureInputRef}
              accept='image/png, image/jpeg'
            />
          </div>
        </div>

        <div className='grid grid-cols-2 gap-4'>
          <div className='space-y-2'>
            <BodyText
              variant='sm'
              className={`font-medium ${
                errors.firstName ? 'text-functional-danger-400' : 'text-neutral-500'
              }`}
            >
              {t('first-name')}
            </BodyText>

            <Controller
              name='firstName'
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <TextInput
                  {...field}
                  placeholder={t('first-name') || 'First Name'}
                  className={errors.firstName ? 'border-functional-danger-400' : ''}
                />
              )}
            />
          </div>

          <div className='space-y-2'>
            <BodyText
              variant='sm'
              className={`font-medium ${
                errors.lastName ? 'text-functional-danger-400' : 'text-neutral-500'
              }`}
            >
              {t('last-name')}
            </BodyText>

            <Controller
              name='lastName'
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <TextInput
                  {...field}
                  placeholder={t('last-name') || 'Last Name'}
                  className={errors.lastName ? 'border-functional-danger-400' : ''}
                />
              )}
            />
          </div>
        </div>

        <div className='space-y-2'>
          <BodyText
            variant='sm'
            className={`font-medium ${
              errors.headline ? 'text-functional-danger-400' : 'text-neutral-500'
            }`}
          >
            {t('headline')}
          </BodyText>

          <Controller
            name='headline'
            control={control}
            render={({ field }) => (
              <TextInput
                {...field}
                placeholder={t('headline') || 'Headline'}
                className={errors.headline ? 'border-functional-danger-400' : ''}
              />
            )}
          />
        </div>

        <div className='space-y-2'>
          <BodyText
            variant='sm'
            className={`font-medium ${
              errors.about ? 'text-functional-danger-400' : 'text-neutral-500'
            }`}
          >
            {t('about')}
          </BodyText>

          <Controller
            name='about'
            control={control}
            render={({ field }) => (
              <Textarea
                {...field}
                rows={4}
                placeholder={t('about') || 'About'}
                className={errors.about ? 'border-functional-danger-400' : ''}
              />
            )}
          />
        </div>
        <div className='flex items-center justify-end gap-4'>
          <Button
            size='default'
            variant='outlined'
            onClick={() => {
              setIsOpen(false);
            }}
          >
            {t('cancel')}
          </Button>
          <Button type='submit' size='default' variant='secondary'>
            {t('save')}
          </Button>
        </div>
      </form>
      <ImageSearchModal
        isOpen={showImageSearchModal}
        onClose={() => {
          setShowImageSearchModal(false);
        }}
        onImageSelect={(url) => {
          setValue('cover', '');
          setOnSelectImage(url);
        }}
      />
    </div>
  );
};

export default EditProfile;
