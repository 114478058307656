import React from 'react';
import BodyText from 'components/typography/BodyText';
import { useAllPages } from 'hooks/api/pages/usePages';
import { SinglePageType } from 'types/apis/pages/response';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

interface IPageSelection {
  setSelectedPage: (page: { id: string; title: string }) => void;
  selectedPage?: { id: string; title: string };
  onConfirm?: () => void;
}

const PageSelection = ({ setSelectedPage, selectedPage }: IPageSelection) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { data: pages, isLoading, isError } = useAllPages();
  return (
    <div>
      {pages && pages.length > 0 ? (
        <div className='space-y-2'>
          {pages.map((page: SinglePageType) => (
            <div
              key={page.id}
              onClick={() => setSelectedPage({ id: page.id, title: page.title })}
              className={`cursor-pointer rounded-md p-2 ${
                selectedPage?.id === page.id ? 'bg-primary-300' : 'hover:bg-neutral-300'
              }`}
            >
              <BodyText variant='sm'>{page.title}</BodyText>
            </div>
          ))}
        </div>
      ) : (
        <div className='flex flex-col items-center justify-center space-y-2'>
          <BodyText variant='sm' className='font-medium text-neutral-500'>
            {t('no-page-tip')}
          </BodyText>
        </div>
      )}
    </div>
  );
};

export default PageSelection;
