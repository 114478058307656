import Button from 'components/button/Button';
import SelectTag from 'components/form/SelectTag';
import BodyText from 'components/typography/BodyText';
import React from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { InviteFormInputType } from 'types/components/form';
import ModalContainer from './ModalContainer';

interface IAddManagerModal {
  isSingle?: boolean;
  title: string;
  btnText: string;
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (data: { email: string }) => void;
}

const AddManagerModal = ({
  title,
  isOpen,
  btnText,
  onClose,
  onSubmit,
  isSingle,
}: IAddManagerModal) => {
  const { t } = useTranslation();

  const {
    watch,
    setValue,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm<InviteFormInputType>();

  const handleSubmit = () => {
    const emails = watch('emails');
    const email = watch('email');

    if (emails.length === 0 && !email) {
      setError('emails', { type: 'required' });
      return;
    }

    onSubmit({ email: emails[0] || email });
  };

  const handleSelectEmail = (values: { label: React.ReactNode; value: string }[]) => {
    const emails = values.map((value) => value.value);
    setValue('emails', emails);
    if (values.length !== 0) clearErrors('emails');
  };

  return (
    <ModalContainer title={title} isOpen={isOpen} onClose={onClose}>
      <div className='space-y-2'>
        <BodyText
          variant='sm'
          className={`font-medium ${
            errors.emails ? 'text-functional-danger-400' : 'text-neutral-500'
          }`}
        >
          {t('emails_names')}
        </BodyText>
        <SelectTag
          isSingle={isSingle}
          onChange={(values) => handleSelectEmail(values)}
          onValueChange={(value) => setValue('email', value)}
          className={errors.emails ? 'border-functional-danger-400' : ''}
        />
        {errors.emails && (
          <BodyText variant='sm' className='font-medium text-functional-danger-400'>
            {t('tag_validation_error_message')}
          </BodyText>
        )}
      </div>

      <div className='mt-5 flex items-center justify-end gap-3 border-t border-neutral-200 pt-5'>
        <Button size='small' variant='outlined' onClick={onClose}>
          {t('cancel')}
        </Button>
        <Button size='small' variant='secondary' onClick={handleSubmit}>
          {btnText}
        </Button>
      </div>
    </ModalContainer>
  );
};

export default AddManagerModal;
