import { useQuery } from '@tanstack/react-query';
import { getProgramMembers } from 'utils/api/member';

export const useProgramMembers = (programId: string, enabled = true) => {
  return useQuery({
    queryKey: ['program-members', programId],
    queryFn: () => getProgramMembers(programId),
    select: (data) => data.data.programMembers,
    enabled,
  });
};
