import { useQuery } from '@tanstack/react-query';
import {
  getSingleGuestCommunity,
  getSingleGuestProgram,
  getGuestMaterials,
  getGuestResources,
  getGuestDiscover,
  getGuestPage,
} from 'utils/api/guest';

export const useSingleGuestCommunity = (communityId: string) => {
  return useQuery({
    queryKey: ['single-public', communityId],
    queryFn: () => getSingleGuestCommunity(communityId),
    select: (data) => data.data.community,
    enabled: !!communityId,
  });
};

export const useSingleGuestProgram = (programId: string) => {
  return useQuery({
    queryKey: ['single-public', programId],
    queryFn: () => getSingleGuestProgram(programId),
    select: (data) => data.data.program,
    enabled: !!programId,
  });
};

export const useGuestMaterials = (programId: string) => {
  const { data, refetch } = useQuery({
    queryKey: ['public-materials', programId],
    queryFn: () => getGuestMaterials(programId),
    select: (res) => res.data.materials,
  });
  return {
    files: data?.files ?? [],
    groups: data?.groups ?? [],
    refetchMaterials: refetch,
  };
};

export const useGuestCommunityResources = (communityId: string, limit = 30) => {
  return useQuery({
    queryKey: ['public-resources', communityId],
    queryFn: () => getGuestResources(communityId, limit),
    select: (data) => data.data,
    enabled: !!communityId,
  });
};

export const useGuestDiscover = (originType: string, originId: string) => {
  return useQuery({
    queryKey: ['discover', originType, originId],
    queryFn: () => getGuestDiscover(originType, originId),
    select: (data) => data.data,
    enabled: !!originType && !!originId,
  });
};

export const useGuestPage = (pageId: string) => {
  return useQuery({
    queryKey: ['page', pageId],
    queryFn: () => getGuestPage(pageId),
    select: (data) => data?.data.page ?? {},
    enabled: !!pageId,
  });
};
