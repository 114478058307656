import { useQuery } from '@tanstack/react-query';
import { getCommunityTasksSubmissions } from 'utils/api/task';

export const useCommunityTasksSubmissions = (communityId: string) => {
  return useQuery({
    queryKey: ['community-tasks-submissions', communityId],
    queryFn: () => getCommunityTasksSubmissions(communityId),
    select: (data) => data.data.tasks,
  });
};
