import CloseIcon from 'assets/icons/close.svg';
import Avatar from 'components/memberAndProfile/Avatar';
import BodyText from 'components/typography/BodyText';
import { userAvatar } from 'constants/common';
import { useInvalidateQueries } from 'hooks/common/useInvalidatequeries';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import { SingleCommunityPostType } from 'types/apis/common';
import { deleteCommunitySavedPost, deleteProgramSavedPost } from 'utils/api/post';
import { convertHtmlToLink, editorContentToText, sanitizeHtml } from 'utils/helper';
import { notifySuccess, notifyTranslatedError } from 'utils/notify';
import { generateSinglePostLink } from 'utils/url';

interface ISavedPost {
  id: string;
  savedPostid: string;
  post: SingleCommunityPostType;
  variant: 'community' | 'program';
}

function SavedPost({ savedPostid, post, id, variant }: ISavedPost) {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { invalidateProgramSavedPosts, invalidateCommunitySavedPosts } = useInvalidateQueries();

  const handleRemoveSavedPost = () => {
    if (variant === 'community') {
      deleteCommunitySavedPost(id, savedPostid)
        .then(() => {
          invalidateCommunitySavedPosts(id);
          notifySuccess(t('success.api.removed_save_post'));
        })
        .catch((error) => {
          notifyTranslatedError(t, error.response.data?.errorCode);
        });
    }
    if (variant === 'program') {
      deleteProgramSavedPost(id, savedPostid)
        .then(() => {
          invalidateProgramSavedPosts(id);
          notifySuccess(t('success.api.removed_save_post'));
        })
        .catch((error) => {
          notifyTranslatedError(t, error.response.data?.errorCode);
        });
    }
  };

  return (
    <div className='flex gap-3 rounded-lg bg-secondary-100 p-3'>
      <div className='space-y-2 overflow-hidden'>
        <BodyText variant='base' className='line-clamp-1 break-words font-medium hover:underline'>
          <Link to={generateSinglePostLink(post.id)}>
            <span
              // eslint-disable-next-line react/no-danger
              dangerouslySetInnerHTML={{
                __html: sanitizeHtml(convertHtmlToLink(editorContentToText(post.content))),
              }}
            />
          </Link>
        </BodyText>

        <button
          className='flex items-center gap-2'
          onClick={() => navigate(`/profile/${post.user.id}`)}
        >
          <Avatar size={20} src={post.user.profilePicture || userAvatar} />
          <BodyText variant='xs'>{`${post.user.firstName} ${post.user.lastName}`}</BodyText>
        </button>
      </div>

      <button className='ml-auto' onClick={handleRemoveSavedPost}>
        <CloseIcon className='h-4 w-4 text-functional-danger-dark' />
      </button>
    </div>
  );
}

export default SavedPost;
