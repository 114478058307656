import Logo from 'assets/images/logo.svg';
import React from 'react';
import MenuWithTrigger from './MenuWithTrigger';
import ProfileDropdown from './ProfileDropdown';

const MobileHeader = () => {
  return (
    <div className='sticky top-0 z-50 md:hidden'>
      <div className='flex items-center justify-between bg-neutral-100 px-4 py-1 shadow-sm'>
        <div className='group flex items-center gap-1 pt-2'>
          <Logo className='-mt-2 h-[20px] w-[20px]' />
          <MenuWithTrigger />
        </div>
        <ProfileDropdown />
      </div>
    </div>
  );
};

export default MobileHeader;
