import { AxiosRequestConfig } from 'axios';
import { API_ROOT } from 'constants/config';
import {
  ConversationCreationResponse,
  GetConversationResponseType,
  GetConversationsListResponseType,
  GetMessagesResponseType,
  UnreadMessagesResponseType,
  LastReadMessageResponseType,
  flagConversationResponse,
} from 'types/apis/response';
import { parseGET, parsePOST, generateQueryParam, parsePUT, parseDELETE } from 'utils/rest';

export const getConversationsList = (options?: AxiosRequestConfig) =>
  parseGET<GetConversationsListResponseType>(`${API_ROOT}/conversation/list`, {
    withCredentials: true,
    ...options,
  });

export const getConversation = (conversationId: string, options?: AxiosRequestConfig) => {
  return parseGET<GetConversationResponseType>(`${API_ROOT}/conversation/${conversationId}`, {
    withCredentials: true,
    ...options,
  });
};

export const renameConversation = (
  conversationId: string,
  data: { newAlias: string },
  options?: AxiosRequestConfig,
) => {
  return parsePUT(`${API_ROOT}/conversation/${conversationId}`, JSON.stringify(data), {
    withCredentials: true,
    headers: { 'Content-Type': 'application/json' },
    ...options,
  });
};

export const getMessages = (
  conversationId: string,
  limit = 20,
  cursor?: string,
  options?: AxiosRequestConfig,
) => {
  const queryParam = generateQueryParam({ limit, cursor });
  return parseGET<GetMessagesResponseType>(
    `${API_ROOT}/conversation/${conversationId}/messages${queryParam}`,
    {
      withCredentials: true,
      ...options,
    },
  );
};

export const getLastRead = (conversationId: string, options?: AxiosRequestConfig) => {
  return parseGET<LastReadMessageResponseType>(
    `${API_ROOT}/conversation/${conversationId}/lastread`,
    {
      withCredentials: true,
      ...options,
    },
  );
};

export const sendMessage = (
  conversationId: string,
  body: FormData,
  options?: AxiosRequestConfig,
) => {
  return parsePOST(`${API_ROOT}/conversation/${conversationId}/message`, body, {
    withCredentials: true,
    ...options,
  });
};

export const sendMessageToChatbot = (
  conversationId: string,
  body: { message: string; chatbotId: string },
  options?: AxiosRequestConfig,
) => {
  return parsePOST(`${API_ROOT}/conversation/${conversationId}/messagechatbot`, body, {
    withCredentials: true,
    ...options,
  });
};

export const removeMessage = (messageId: string, options?: AxiosRequestConfig) => {
  return parseDELETE(`${API_ROOT}/message/${messageId}`, {
    withCredentials: true,
    ...options,
  });
};

export const getUnread = (options?: AxiosRequestConfig) => {
  return parseGET<UnreadMessagesResponseType>(`${API_ROOT}/conversation/unread`, {
    withCredentials: true,
    ...options,
  });
};

export const startConversation = (
  body: {
    participants: string[];
    conversationType: string;
    communityId?: string;
    programId?: string;
  },
  options?: AxiosRequestConfig,
) => {
  return parsePOST<typeof body, ConversationCreationResponse>(`${API_ROOT}/conversation`, body, {
    withCredentials: true,
    ...options,
  });
};

export const flagConversation = (
  conversationId: string,
  userId: string,
  options?: AxiosRequestConfig,
) =>
  parseGET<flagConversationResponse>(`${API_ROOT}/conversation/${conversationId}/${userId}/flag`, {
    withCredentials: true,
    ...options,
  });

export const updateConversation = (
  conversationId: string,
  body: {
    participants: string[];
    conversationType: string;
    communityId?: string;
    programId?: string;
  },
  options?: AxiosRequestConfig,
) => {
  return parsePUT(`${API_ROOT}/conversation/${conversationId}/update`, body, {
    withCredentials: true,
    ...options,
  });
};
