import Button from 'components/button/Button';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import ModalContainer from './ModalContainer';
import { OutputData } from '@editorjs/editorjs';
import RichTextEditor from 'components/common/RichTextEditorWithUpload';

interface IRichTextModal {
  isOpen: boolean;
  label: string;
  title?: string;
  description?: string;
  onClose: () => void;
}

const RichTextModal = ({ isOpen, onClose, label, title, description }: IRichTextModal) => {
  const { t } = useTranslation();
  const [aboutText, setAboutText] = useState<OutputData | null>(null);
  return (
    <ModalContainer title={label} isOpen={isOpen} onClose={onClose}>
      <div className='space-y-4'>
        {description && <RichTextEditor initialContent={description} isReadOnly={true} />}
      </div>
      <div className='mt-5 flex items-center justify-end gap-3 border-t border-neutral-200 pt-5'>
        <Button size='default' variant='outlined' onClick={onClose}>
          {t('cancel')}
        </Button>
      </div>
    </ModalContainer>
  );
};

export default RichTextModal;
