import { useQuery } from '@tanstack/react-query';
import { getProgramManagers } from 'utils/api/member';

export const useProgramManagers = (programId: string) => {
  return useQuery({
    queryKey: ['program-managers', programId],
    queryFn: () => getProgramManagers(programId),
    select: (data) => data.data.programManagers,
  });
};
