import AttachIcon from 'assets/icons/attachment.svg';
import FileIcon from 'assets/icons/add-docs.svg';
import EmojiIcon from 'assets/icons/emoji.svg';
import ImageIcon from 'assets/icons/image.svg';
import DeleteIcon from 'assets/icons/delete.svg';
import SendIcon from 'assets/icons/send-message.svg';
import ReplyIcon from 'assets/icons/reply.svg';
import EmojiSelector from 'components/common/EmojiSelector';
import MentionTextarea from 'components/common/MentionTextarea';
import Uploading from 'components/Uploading';
import { useFileUploadProgress } from 'hooks/common/useFileUploadProgress';
import React, { useRef, useState, useEffect } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { SendMessageFormInputType } from 'types/components/form';
import { sendMessage, sendMessageToChatbot } from 'utils/api/message';
import { sendEmbedMessage } from 'utils/api/embedConversation';
import {
  bytesToMegaBytes,
  convertHtmlToLink,
  editorContentToText,
  sanitizeHtml,
  insertTextInCurrentPosition,
} from 'utils/helper';
import { notifyError, notifyTranslatedError } from 'utils/notify';
import { objToFormData } from 'utils/rest';
import BodyText from 'components/typography/BodyText';
import { COMMON_FILE_SIZE_LIMIT } from 'constants/common';
import { DropdownMenu } from 'components/common/DropdownMenu';
import Button from 'components/button/Button';
import { convertToRaw, EditorState } from 'draft-js';

export const SendMessageForm = ({
  originType,
  originId,
  conversationId,
  onReplyMessageId,
  onReplySenderId,
  onReplySenderFirstName,
  onReplySenderLastName,
  onReplyContent,
  refetchMessages,
  messageContainerRef,
  handleDeleteReply,
  forwardMessageToChatbot,
  chatbotId,
  isEmbedConversation,
}: {
  originType?: string;
  originId?: string;
  conversationId: string;
  onReplyMessageId?: string;
  onReplySenderId?: string;
  onReplyContent?: string;
  onReplySenderFirstName?: string;
  onReplySenderLastName?: string;
  refetchMessages: () => Promise<unknown>;
  messageContainerRef: React.RefObject<HTMLDivElement>;
  handleDeleteReply: () => void;
  forwardMessageToChatbot?: boolean;
  chatbotId?: string;
  isEmbedConversation?: boolean;
}) => {
  const { t } = useTranslation();
  const [showEmoji, setShowEmoji] = useState(false);
  const emojiContainerRef = useRef<HTMLDivElement>(null);
  const [sendingMessage, setSendingMessage] = useState(false);
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const imageInputRef = useRef<HTMLInputElement | null>(null);
  const { isUploading, setIsUploading, progress, onUploadProgress } = useFileUploadProgress();
  const [editorState, setEditorState] = useState(() => EditorState.createEmpty());

  useEffect(() => {
    clearErrors('message');
    const currentContent = editorState.getCurrentContent();
    const rawContent = convertToRaw(currentContent);
    const content = JSON.stringify(rawContent);
    setValue('message', content);
  }, [editorState]);

  const {
    watch,
    reset,
    control,
    setValue,
    setError,
    register,
    unregister,
    clearErrors,
    handleSubmit,
    formState: { errors },
  } = useForm<SendMessageFormInputType>({ defaultValues: { message: '' } });

  const onSubmit: SubmitHandler<SendMessageFormInputType> = (data) => {
    const currentContent = editorState.getCurrentContent();
    const rawContent = convertToRaw(currentContent);
    const flatText = rawContent.blocks.map((block) => block.text).join('');

    const { message, file } = data;

    if (!message && !file && flatText.length === 0) {
      setError('message', { type: 'minLength' });
      return;
    }
    const adjustedMessage = flatText.length === 0 ? '' : message;

    const formData = objToFormData({ message: adjustedMessage });

    if (file?.[0]) {
      setIsUploading(true);
      formData.append('file', file[0]);
    }
    formData.append('onReplyMessageId', onReplyMessageId || '');
    formData.append('onReplyContent', onReplyContent || '');
    formData.append('onReplySenderId', onReplySenderId || '');
    setSendingMessage(true);
    const sendFunction = isEmbedConversation ? sendEmbedMessage : sendMessage;

    sendFunction(conversationId, formData, { onUploadProgress })
      .then(() => {
        reset();
        refetchMessages().then(() => {
          const messageContainer = messageContainerRef.current;
          handleDeleteReply();
          if (messageContainer) {
            setTimeout(() =>
              messageContainer.scrollTo({
                left: 0,
                top: messageContainer.scrollHeight,
                behavior: 'smooth',
              }),
            );
          }
          // send request to BE API to get response from TextCortex and forward that to sendMessage
          if (forwardMessageToChatbot) {
            sendMessageToChatbot(conversationId, {
              message: message,
              chatbotId: chatbotId || '',
            }).then(() => {
              reset();
              refetchMessages().then(() => {
                const messageContainer = messageContainerRef.current;
                handleDeleteReply();
                if (messageContainer) {
                  setTimeout(() =>
                    messageContainer.scrollTo({
                      left: 0,
                      top: messageContainer.scrollHeight,
                      behavior: 'smooth',
                    }),
                  );
                }
              });
            });
          }
        });
        setIsUploading(false);
        setSendingMessage(false);
        setEditorState(EditorState.createEmpty());
      })
      .catch((err) => {
        setIsUploading(false);
        setSendingMessage(false);
        notifyTranslatedError(t, err.response.data?.errorCode);
        notifyTranslatedError(t, err.response.data?.message);
      });
  };

  const handleSelectFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files?.length) return;
    if (e.target.files[0].size > COMMON_FILE_SIZE_LIMIT) {
      notifyError('file-too-big');
      return;
    }
    setValue('file', e.target.files);
    clearErrors('message');
  };

  const handleAtSymbolInsert = () => {
    // Create a new editor state with the "@" symbol inserted
    const newEditorState = insertTextInCurrentPosition(editorState, '@');

    // Update the editor state
    setEditorState(newEditorState);
  };

  return (
    <div className='items-center space-y-3 lg:max-w-[900px] w-full pb-3'>
      {isUploading && <Uploading progress={progress} />}
      {watch('file')?.length > 0 && (
        <div className='flex  items-center gap-3  rounded-md border border-functional-info-dark bg-functional-info-light px-4 py-2'>
          <AttachIcon className='h-5 w-5 shrink-0 text-black' />{' '}
          <div className='overflow-hidden'>
            <BodyText variant='sm' className='line-clamp-1 break-words font-semibold text-black'>
              {watch('file')[0].name}
            </BodyText>
            <BodyText variant='sm' className='line-clamp-1 break-words font-medium text-black'>
              {bytesToMegaBytes(watch('file')[0].size)}
            </BodyText>
          </div>
          <button
            type='button'
            onClick={() => unregister('file')}
            className='ml-auto flex h-7 w-7 shrink-0 items-center justify-center rounded-full text-functional-danger-dark hover:bg-neutral-300 active:bg-neutral-400'
          >
            <DeleteIcon className='h-5 w-5' />
          </button>
        </div>
      )}
      {onReplyMessageId && (
        <div className='flex items-center justify-between gap-3 rounded-md border border-functional-info-dark bg-functional-info-light px-4 py-2'>
          <div>
            <div className='flex items-center'>
              <span className='mr-1'>
                <ReplyIcon className='h-4 w-4' />
              </span>
              <span className='text-xs text-black'>
                {`${onReplySenderFirstName} ${onReplySenderLastName}`}:
              </span>
            </div>
            <BodyText variant='sm' className='line-clamp-1 break-words font-semibold text-black'>
              <span
                // eslint-disable-next-line react/no-danger
                dangerouslySetInnerHTML={{
                  __html: sanitizeHtml(
                    convertHtmlToLink(editorContentToText(onReplyContent || '')),
                  ),
                }}
              />
            </BodyText>
          </div>
          <button
            type='button'
            onClick={() => {
              handleDeleteReply();
            }}
            className='flex h-7 w-7 shrink-0 items-center justify-center rounded-full text-functional-danger-dark hover:bg-neutral-300 active:bg-neutral-400'
          >
            <DeleteIcon className='h-5 w-5' />
          </button>
        </div>
      )}

      <form className='' onSubmit={handleSubmit(onSubmit)}>
        <MentionTextarea
          originId={originId || 'all'}
          originType={originType === 'program' || originType === 'community' ? originType : 'all'}
          editorState={editorState}
          setEditorState={setEditorState}
          isTopPosition={true}
          conversationId={conversationId}
        />
        <div className='flex items-center justify-between mt-1'>
          <div className='flex items-center gap-2'>
            <button
              type='button'
              onClick={handleAtSymbolInsert}
              className='neutral-button flex h-5 w-5 items-center justify-center rounded-full text-neutral-500'
            >
              <BodyText variant='sm'> @ </BodyText>
            </button>
            <span ref={emojiContainerRef}>
              <EmojiSelector
                open={showEmoji}
                close={() => {
                  setShowEmoji(false);
                }}
                setOpen={setShowEmoji}
                element={
                  <div className='neutral-button flex h-5 w-5 items-center justify-center rounded-full text-neutral-500'>
                    <EmojiIcon className='h-4 w-4' />
                  </div>
                }
                elementClassName='btn p-0'
                onEmojiSelect={(emoji) =>
                  setEditorState(insertTextInCurrentPosition(editorState, emoji))
                }
              />
            </span>
            <div className='relative z-20'>
              <DropdownMenu
                upDirection={true}
                rightDirection={true}
                menuButton={
                  <div className='neutral-button flex h-5 w-5 items-center justify-center rounded-full text-neutral-500'>
                    <AttachIcon className='h-4 w-4' />
                  </div>
                }
                menuItems={[
                  {
                    key: 'file',
                    label: t('upload-file'),
                    icon: FileIcon,
                    callback: () => {
                      fileInputRef.current?.click();
                    },
                  },
                  {
                    key: 'image',
                    label: t('upload-image'),
                    icon: ImageIcon,
                    callback: () => {
                      imageInputRef.current?.click();
                    },
                  },
                ]}
              />
              <input
                type='file'
                accept='*/*'
                className='hidden'
                {...register('file', {
                  onChange: (e) => handleSelectFile(e),
                })}
                ref={fileInputRef}
              />
              <input
                type='file'
                accept='image/*'
                className='hidden'
                {...register('file', {
                  onChange: (e) => handleSelectFile(e),
                })}
                ref={imageInputRef}
              />
            </div>
          </div>
          <Button
            variant='primary'
            size='small'
            type='submit'
            disabled={isUploading && sendingMessage}
            className='px-3'
          >
            <SendIcon className='h-4 w-4' />
          </Button>
        </div>
      </form>
    </div>
  );
};
