import Loading from 'components/Loading';
import NavigateTo404 from 'components/NavigateTo404';
import { useCommunityEvent } from 'hooks/api/event/useCommunityEvent';
import { useInvalidateQueries } from 'hooks/common/useInvalidatequeries';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { updateCommunityEvent } from 'utils/api/event';
import { notifySuccess, notifyTranslatedError } from 'utils/notify';
import { generateCommunityEventLink } from 'utils/url';
import EditEventForm from '../event/EditEventForm';

const EditCommunityEventPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { communityId, eventId } = useParams();

  const { data: event, isLoading: isEventLoading } = useCommunityEvent(
    communityId || '',
    eventId || '',
  );
  const {
    invalidateCommunityEvents,
    invalidateSingleCommunityEvent,
    invalidateUpcomingCommunityEvents,
  } = useInvalidateQueries();

  if (isEventLoading) return <Loading />;
  if (!event) return <NavigateTo404 />;

  const {
    id: eventUid,
    title,
    about,
    cover,
    isAllDay,
    startTime,
    endTime,
    location,
    type,
    zoomLink,
    timezone,
    eventContactUser,
  } = event;
  let eventContactUserId, contactUserFirstName, contactUserLastName, contactUserProfilePicture;
  if (eventContactUser) {
    ({
      id: eventContactUserId,
      firstName: contactUserFirstName,
      lastName: contactUserLastName,
      profilePicture: contactUserProfilePicture,
    } = eventContactUser);
  }
  const handleEditEvent = (eventData: FormData) => {
    if (!communityId) return;

    updateCommunityEvent(communityId, eventUid, eventData)
      .then(() => {
        invalidateCommunityEvents(communityId);
        invalidateUpcomingCommunityEvents(communityId);
        invalidateSingleCommunityEvent(communityId, eventUid);
        notifySuccess(t('success.api.event_updated'));
        navigate(generateCommunityEventLink(communityId, eventUid));
      })
      .catch((error) => {
        notifyTranslatedError(t, error.response.data?.errorCode);
        notifyTranslatedError(t, error.response.data?.message);
      });
  };
  return (
    <EditEventForm
      eventType={type}
      eventCover={cover}
      eventTitle={title}
      eventIsAllDay={isAllDay || false}
      eventEndTime={endTime}
      eventDescription={about}
      eventTimezone={timezone}
      eventLink={zoomLink || ''}
      eventStartTime={startTime}
      eventLocation={location || ''}
      eventContactUserAvatar={contactUserProfilePicture || ''}
      eventContactUserId={eventContactUserId || ''}
      eventContactUserFirstName={contactUserFirstName}
      eventContactUserLastName={contactUserLastName}
      handleEditEvent={handleEditEvent}
    />
  );
};

export default EditCommunityEventPage;
