import { useInvalidateQueries } from 'hooks/common/useInvalidatequeries';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { createProgramGroup, updateProgramGroup } from 'utils/api/group';
import { notifySuccess, notifyTranslatedError } from 'utils/notify';
import CreationModal from './CreationModal';

interface IAddEditGroupModal {
  isOpen: boolean;
  programId: string;
  isEditing: boolean;
  onClose: () => void;
  selectedValues: {
    id: string;
    name: string;
    description: string;
    cover: null | string;
    visibility: 'open' | 'public' | 'private';
    allowGuest: 'false';
  };
}

const AddEditGroupModal = ({
  isOpen,
  onClose,
  programId,
  isEditing,
  selectedValues,
}: IAddEditGroupModal) => {
  const { t } = useTranslation();

  const { invalidateProgramGroups, invalidateSingleCommunity } = useInvalidateQueries();

  const onSubmit = (data: FormData) => {
    if (isEditing && selectedValues.id) {
      updateProgramGroup(programId, selectedValues.id, data)
        .then(() => {
          onClose();
          invalidateProgramGroups(programId);
          invalidateSingleCommunity(selectedValues.id);
          notifySuccess(t('group-updated'));
        })
        .catch((error) => {
          notifyTranslatedError(t, error.response.data?.errorCode);
        });
    }
    if (!isEditing) {
      createProgramGroup(programId, data)
        .then(() => {
          onClose();
          invalidateProgramGroups(programId);
          notifySuccess(t('group-created'));
        })
        .catch((error) => {
          notifyTranslatedError(t, error.response.data?.errorCode);
        });
    }
  };

  return (
    <CreationModal
      isGroup
      isOpen={isOpen}
      onClose={onClose}
      onFormSubmit={onSubmit}
      defaultValues={selectedValues}
      ctaText={isEditing ? t('save') || 'Save' : t('create') || 'Save'}
      modalTitle={isEditing ? t('update_group') : t('create-group')}
    />
  );
};

export default AddEditGroupModal;
