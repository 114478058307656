import { Menu } from '@headlessui/react';
import ChevronDownIcon from 'assets/icons/chevron-down.svg';
import BodyText from 'components/typography/BodyText';
import { languages } from 'config/languages';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

function LanguageSelector() {
  const { i18n } = useTranslation();

  const [selectedLanguage, setSelectedLanguage] = useState('en');

  const handleLanguageSelect = (code: string, close: () => void) => {
    i18n.changeLanguage(code);
    setSelectedLanguage(code);
    localStorage.setItem('lang', code);
    close();
  };

  useEffect(() => {
    const initLang = (() => {
      const lang = localStorage.getItem('lang');
      return lang || 'en';
    })();

    i18n.changeLanguage(initLang);
    setSelectedLanguage(initLang);
  }, []);

  return (
    <Menu>
      <div className='relative'>
        <Menu.Button>
          <div className='neutral-button flex items-center gap-2 rounded-[10px] bg-neutral-200 p-1 border border-neutral-300'>
            {languages.find((lang) => lang.code === selectedLanguage)?.label}
            <ChevronDownIcon className='h-[20px] w-[20px]' />
          </div>
        </Menu.Button>
        <Menu.Items className='absolute right-0 top-6'>
          <div className=' space-y-2 rounded bg-neutral-200 shadow shadow-neutral-300'>
            {languages.map(({ code, label, icon }) => (
              <Menu.Item key={code}>
                {({ close }) => (
                  <button
                    key={code}
                    className='neutral-button flex w-14 w-full items-center gap-2 rounded-md p-1'
                    onClick={() => handleLanguageSelect(code, close)}
                  >
                    {icon}
                    <BodyText variant='base' className='w-10'>
                      {label}
                    </BodyText>
                  </button>
                )}
              </Menu.Item>
            ))}
          </div>
        </Menu.Items>
      </div>
    </Menu>
  );
}

export default LanguageSelector;
