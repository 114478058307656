import { AxiosRequestConfig } from 'axios';
import { API_ROOT } from 'constants/config';
import { AddAdminRequestType } from 'types/apis/request';
import { AdminsResponseType } from 'types/apis/response';
import { parsePOST, parseGET } from 'utils/rest';

export const getAdmins = (options?: AxiosRequestConfig) =>
  parseGET<AdminsResponseType>(`${API_ROOT}/organization/admin`, {
    withCredentials: true,
    ...options,
  });

export const addAdmin = (body: AddAdminRequestType, options?: AxiosRequestConfig) =>
  parsePOST(`${API_ROOT}/organization/admin`, body, { withCredentials: true, ...options });
