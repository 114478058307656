import Loading from 'components/Loading';
import NavigateTo404 from 'components/NavigateTo404';
import Heading from 'components/typography/Heading';
import { useSingleGuestCommunity, useSingleGuestProgram } from 'hooks/api/guest/guest';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import RichTextEditor from 'components/common/RichTextEditor';
import { eventImage } from 'constants/common';

const OriginHome = () => {
  const { t } = useTranslation();
  const { originType, originId } = useParams();
  const { data: program, isLoading: isProgramLoading } =
    originType === 'program' && originId
      ? useSingleGuestProgram(originId)
      : { data: null, isLoading: false };
  const { data: community, isLoading: isCommunityLoading } =
    originType === 'community' && originId
      ? useSingleGuestCommunity(originId)
      : { data: null, isLoading: false };
  const entity = originType === 'program' ? program : community;
  if (isCommunityLoading || isProgramLoading) return <Loading />;
  if (!entity) return <NavigateTo404 />;
  return (
    <div className='relative mb-6 grid grid-cols-5 items-start gap-3'>
      <div className='col-span-5  space-y-3 xl:col-span-3'>
        <div className='rounded-xl bg-neutral-200 p-3 shadow shadow-neutral-300'>
          <Heading variant='h2' className='text-semibold mb-1'>
            {t('about')}: {entity.name}
          </Heading>
          <RichTextEditor initialContent={entity.description} isReadOnly={true} />
        </div>
        {entity.homeBlock?.content && (
          <div className='mb-3 flex flex-col rounded-xl bg-neutral-200 p-3 shadow shadow-neutral-300'>
            <RichTextEditor initialContent={entity.homeBlock.content} isReadOnly={true} />
          </div>
        )}

        {entity.id === 'clfgpoj0a00dws6cm7gyoh0qz' && (
          <div className='gap-y-3'>
            <div className='mt-3 rounded-xl bg-neutral-200 p-3 shadow'>
              <Heading variant='h2' className='text-semibold mb-1'>
                Q&A
              </Heading>
              <iframe
                className='rounded-xl bg-neutral-200 shadow'
                width='100%'
                height='750'
                src='https://app.textcortex.com/'
              ></iframe>
            </div>
            <div className='mt-3 rounded-xl bg-neutral-200 p-3 shadow'>
              <Heading variant='h2' className='text-semibold mb-1'>
                Schedule a call
              </Heading>
              <iframe
                className='rounded-xl bg-neutral-200 shadow'
                width='100%'
                height='100%'
                src='https://calendly.com/hello-studycentral/30min'
              ></iframe>
            </div>

            <div className='mt-3 rounded-xl bg-neutral-200 p-3 shadow'>
              <Heading variant='h2' className='text-semibold mb-1'>
                How to find us
              </Heading>
              <iframe
                className='rounded-xl bg-neutral-200 shadow'
                width='100%'
                height='450'
                src='https://maps.google.com/maps?width=100%25&amp;height=450&amp;hl=en&amp;q=Warschauer%20Pl.%2011-13,%2010245%20Berlin+(Find%20us)&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed'
              ></iframe>
            </div>
          </div>
        )}
      </div>

      <div className='sticky top-0 col-span-5 space-y-6 xl:col-span-2'>
        {entity.id === 'clfgpoj0a00dws6cm7gyoh0qz' && (
          <div className='mt-3 rounded-xl bg-neutral-200 p-3 shadow'>
            <iframe
              width='100%'
              height='1000'
              src='https://rss.app/embed/v1/list/ykPNvGO7rlkEo6RV'
            ></iframe>
          </div>
        )}
      </div>
    </div>
  );
};

export default OriginHome;
