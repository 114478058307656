import { Dialog, Transition } from '@headlessui/react';
import CloseIcon from 'assets/icons/close.svg';
import React, { Fragment } from 'react';

interface IModalContainer {
  title?: string;
  isOpen: boolean;
  onClose: () => void;
  children: React.ReactNode;
}

const ModalContainer = ({ title, children, isOpen, onClose }: IModalContainer) => {
  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog as='div' className='relative z-50' onClose={onClose}>
        <Transition.Child
          as={Fragment}
          enter='ease-out duration-300'
          enterFrom='opacity-0'
          enterTo='opacity-100'
          leave='ease-in duration-200'
          leaveFrom='opacity-100'
          leaveTo='opacity-0'
        >
          <div className='fixed inset-0 bg-black bg-opacity-70' />
        </Transition.Child>

        <div className='fixed inset-0 overflow-y-auto'>
          <div className='flex min-h-full items-center justify-center text-center'>
            <Transition.Child
              as={Fragment}
              enter='ease-out duration-300'
              enterFrom='opacity-0 scale-95'
              enterTo='opacity-100 scale-100'
              leave='ease-in duration-200'
              leaveFrom='opacity-100 scale-100'
              leaveTo='opacity-0 scale-95'
            >
              <Dialog.Panel className='w-full max-w-[1000px] overflow-x-hidden transform rounded-xl bg-neutral-200 text-left align-middle shadow-lg shadow-neutral-300 transition-all'>
                <div className=' max-h-[90vh] overflow-y-auto'>
                  <div className='py-2 px-3 mb-4 flex justify-between items-between bg-secondary-100 rounded-t-xl'>
                    {title ? (
                      <Dialog.Title as='h3' className='font-semibold text-secondary-500'>
                        {title}
                      </Dialog.Title>
                    ) : (
                      <div></div>
                    )}
                    <button
                      className='relative flex h-6 w-6 items-center justify-center rounded-full text-functional-danger-400 hover:bg-neutral-300 active:bg-neutral-400'
                      onClick={onClose}
                    >
                      <CloseIcon className='h-4 w-4' />
                    </button>
                  </div>
                  <div className='px-4 pb-4'>{children}</div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

export default ModalContainer;
