import BodyText from 'components/typography/BodyText';
import SearchInput from 'components/form/SearchInput';
import { useActiveMembers } from 'hooks/api/member/userAllMembers';
import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import SortIcon from 'assets/icons/sort.svg';
import MemberButton from 'components/memberAndProfile/MemberButton';
import dayjs from 'dayjs';

const ActiveMembers = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { data: data, refetch: refetchMembers } = useActiveMembers();

  const [searchString, setSearchString] = useState('');

  // Search related code:
  const filteredMembers = useMemo(
    () =>
      data?.members
        ?.sort((a) => (a.role === 'manager' ? -1 : 0))
        .filter((member) =>
          `${member.firstName} ${member.lastName}`
            .toLowerCase()
            .includes(searchString.toLowerCase()),
        ) || [],
    [data?.members, searchString],
  );
  // search end here

  // sorting related code:
  const [sortConfig, setSortConfig] = useState<{
    key: 'createdAt' | 'firstName' | 'lastName';
    direction: 'ascending' | 'descending';
  }>({ key: 'createdAt', direction: 'descending' });

  const sortedMembers = useMemo(() => {
    let filtered = filteredMembers;
    if (sortConfig.key) {
      filtered = [...filteredMembers].sort((a, b) => {
        let aValue, bValue;
        if (sortConfig.key === 'createdAt') {
          aValue = new Date(a.createdAt).getTime();
          bValue = new Date(b.createdAt).getTime();
        } else if (sortConfig.key === 'firstName') {
          aValue = a.firstName;
          bValue = b.firstName;
        } else if (sortConfig.key === 'lastName') {
          aValue = a.lastName;
          bValue = b.lastName;
        } else {
          aValue = a[sortConfig.key];
          bValue = b[sortConfig.key];
        }
        if (aValue < bValue) {
          return sortConfig.direction === 'ascending' ? -1 : 1;
        }
        if (aValue > bValue) {
          return sortConfig.direction === 'ascending' ? 1 : -1;
        }
        return 0;
      });
    }
    return filtered;
  }, [filteredMembers, sortConfig]);

  const handleSort = (key: 'firstName' | 'createdAt' | 'lastName') => {
    let direction: 'ascending' | 'descending' = 'ascending';
    if (sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }
    setSortConfig({ key, direction });
  };
  // sorting related code ends here

  return (
    <div className='space-y-4'>
      <div className='flex items-center justify-between px-1 gap-2 '>
        <div className='flex flex-row items-center gap-2 px-1 justify-between'>
          <SearchInput onSearch={(value) => setSearchString(value)} />
          <BodyText variant='base' className='mr-auto text-neutral-500 hidden md:block'>
            <span className='text-secondary-500 font-bold'>{data?.totalMembers}</span>{' '}
            {t('members')}
          </BodyText>
        </div>
      </div>
      <div className='mb-5'>
        <div className='table-container'>
          <table>
            <thead>
              <tr>
                <th>
                  <div className='flex items-center gap-1 text-left'>
                    {t('name')}
                    <button
                      className='neutral-button rounded-full p-1'
                      onClick={() => handleSort('firstName')}
                    >
                      <SortIcon className='h-4 w-4' />
                    </button>
                  </div>
                </th>
                <th>{t('headline')}</th>
                <th>{t('organization')}</th>
                <th>
                  <div className='flex items-center gap-1 text-left'>
                    {t('member_since')}
                    <button
                      className='neutral-button rounded-full p-1'
                      onClick={() => handleSort('createdAt')}
                    >
                      <SortIcon className='h-4 w-4' />
                    </button>
                  </div>
                </th>
              </tr>
            </thead>
            <tbody>
              {sortedMembers.map(
                ({
                  id: userId,
                  headline,
                  firstName,
                  lastName,
                  profilePicture,
                  orgName,
                  createdAt,
                }) => (
                  <tr key={userId} className='group'>
                    <td>
                      <MemberButton
                        userId={userId}
                        isCreator={false}
                        profilePicture={profilePicture || ''}
                        userName={firstName + ' ' + lastName}
                        chat={false}
                        size='small'
                      />
                    </td>

                    <td>
                      <BodyText variant='base' classID='line-clamp-1 max-w-[90px]'>
                        {headline}
                      </BodyText>
                    </td>
                    <td>
                      <BodyText variant='base' classID='line-clamp-1 max-w-[90px]'>
                        {orgName}
                      </BodyText>
                    </td>
                    <td>
                      {createdAt ? (
                        <BodyText variant='base' className='line-clamp-1'>
                          {dayjs(createdAt).format('YYYY-MM-DD')}
                        </BodyText>
                      ) : (
                        <div>-</div>
                      )}
                    </td>
                  </tr>
                ),
              )}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default ActiveMembers;
