import ArchiveIcon from 'assets/icons/archive.svg';
import PrivateIcon from 'assets/icons/eye-off.svg';
import PublicIcon from 'assets/icons/eye.svg';
import OpenIcon from 'assets/icons/open.svg';
import dayjs from 'dayjs';
import { commonCover } from 'constants/common';
import LoadingIcon from 'assets/icons/loading.svg';
import BodyText from 'components/typography/BodyText';
import { ContextMenu } from 'components/common/ContextMenu';
import ConfirmationModal from 'components/modal/ConfirmationModal';
import { useArchivedCommunities } from 'hooks/api/community/useArchivedCommunities';
import { useInvalidateQueries } from 'hooks/common/useInvalidatequeries';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useInView } from 'react-intersection-observer';
import { restoreCommunity } from 'utils/api/community';
import { notifySuccess, notifyTranslatedError } from 'utils/notify';

const ArchivedCommunities = () => {
  const { t } = useTranslation();

  const [selectedCommunityId, setSelectedCommunityId] = useState('');
  const [openRestoreConfirmationModal, setOpenRestoreConfirmationModal] = useState(false);

  const { ref: observerRef, inView } = useInView({
    threshold: 0.25,
  });

  const {
    data,
    isArchivedCommunitiesLoading,
    isFetchingNextArchivedCommunites,
    hasNextArchivedCommunities,
    fetchNextArchivedCommunities,
  } = useArchivedCommunities();
  const {
    invalidateSingleCommunity,
    invalidateMyCommunities,
    invalidateOrgCommunities,
    invalidateArchivedCommunities,
  } = useInvalidateQueries();

  const handleOpenRestoreConfirmationModal = (communityId: string) => {
    setSelectedCommunityId(communityId);
    setOpenRestoreConfirmationModal(true);
  };

  const handleCloseRestoreConfirmationModal = () => {
    setSelectedCommunityId('');
    setOpenRestoreConfirmationModal(false);
  };

  const handleRestoreCommunity = () => {
    restoreCommunity(selectedCommunityId)
      .then(() => {
        invalidateSingleCommunity(selectedCommunityId);
        invalidateMyCommunities();
        invalidateOrgCommunities();
        invalidateArchivedCommunities();
        notifySuccess(t('success.api.restored_community'));
      })
      .catch((error) => {
        notifyTranslatedError(t, error.response.data?.errorCode);
      });
    handleCloseRestoreConfirmationModal();
  };

  useEffect(() => {
    if (inView) {
      if (
        hasNextArchivedCommunities &&
        !isArchivedCommunitiesLoading &&
        !isFetchingNextArchivedCommunites
      ) {
        fetchNextArchivedCommunities();
      }
    }
  }, [inView]);

  return (
    <div className='mb-10 flex space-y-2 '>
      <div className='overflow-x-auto rounded-2xl bg-neutral-200 px-4 py-5 pb-[120px] shadow shadow-neutral-300'>
        <table className='min-w-[800px] table-auto'>
          <thead className='bg-neutral-100'>
            <tr className='border-b-2 border-neutral-200'>
              <th className='px-4 py-2 text-left'>
                <BodyText variant='sm' className='font-medium'>
                  {t('about')}
                </BodyText>
              </th>
              <th className='px-4 py-2  text-left'>
                <BodyText variant='sm' className='font-medium'>
                  {t('visibility')}
                </BodyText>
              </th>
              <th className='px-4 py-2  text-left'>
                <BodyText variant='sm' className=' font-medium'>
                  {t('members')}
                </BodyText>
              </th>
              <th className='px-4 py-2  text-left'>
                <BodyText variant='sm' className=' font-medium'>
                  {t('created_at')}
                </BodyText>
              </th>
              <th className='px-4 py-2  text-left'>
                <BodyText variant='sm' className=' font-medium'>
                  {t('archived_on')}
                </BodyText>
              </th>
              <th className='px-4 py-2  text-left'>
                <BodyText variant='sm' className=' font-medium'>
                  {t('actions')}
                </BodyText>
              </th>
            </tr>
          </thead>

          <tbody>
            {data?.map(
              ({
                id,
                name,
                visibility,
                cover,
                logo,
                createdAt,
                memberCount,
                organization,
                archiveDate,
              }) => (
                <tr key={id} className='border-b-2 border-neutral-200'>
                  <td className='p-2'>
                    <a href={`/admin-dashboard/communities/${id}/members`}>
                      <button className='neutral-button flex w-full max-w-[30rem] items-center rounded-md p-2 text-neutral-500'>
                        <img
                          src={cover || logo || organization.logo || commonCover}
                          alt={`Cover for ${name}`}
                          className='h-8 w-8 rounded-full object-cover'
                        />{' '}
                        <span className='ml-2 line-clamp-3 text-left'>{name}</span>
                      </button>
                    </a>
                  </td>
                  <td className='p-2'>
                    {' '}
                    {visibility && (
                      <div className='flex items-center gap-2 rounded bg-neutral-200 px-2 py-1'>
                        {visibility === 'open' ? (
                          <OpenIcon className='h-4 w-4 text-neutral-500' />
                        ) : visibility === 'private' ? (
                          <PrivateIcon className='h-4 w-4 text-neutral-500' />
                        ) : (
                          <PublicIcon className='h-4 w-4 text-neutral-500' />
                        )}
                        <BodyText variant='sm' className='font-medium capitalize text-neutral-500'>
                          {t(visibility)}
                        </BodyText>
                      </div>
                    )}
                  </td>
                  <td className='p-2 px-4 text-center'>{memberCount}</td>
                  <td className='p-2 px-4 text-left'>
                    <span className='text-sm text-neutral-500'>
                      {dayjs(createdAt).format(' D MMM YYYY')}
                    </span>
                  </td>
                  <td className='p-2 px-4 text-left'>
                    <span className='text-sm text-neutral-500'>
                      {dayjs(archiveDate).format(' D MMM YYYY')}
                    </span>
                  </td>
                  <td className='p-2 px-4 text-center'>
                    <ContextMenu
                      menuItems={[
                        {
                          key: 'restore',
                          label: t('restore'),
                          icon: ArchiveIcon,
                          callback: () => handleOpenRestoreConfirmationModal(id),
                        },
                      ]}
                    />
                  </td>
                </tr>
              ),
            )}
          </tbody>
        </table>

        <div ref={observerRef}>
          <LoadingIcon
            className={`h-6 w-6 ${
              isFetchingNextArchivedCommunites || isArchivedCommunitiesLoading ? 'block' : 'hidden'
            }`}
          />
        </div>
      </div>

      {openRestoreConfirmationModal && (
        <ConfirmationModal
          isOpen={openRestoreConfirmationModal}
          label={t('restore_community')}
          title={t('restore_community_confirmation_title')}
          description={
            t('restore_community_confirmation_description') ||
            'This community will be visible for all members.'
          }
          onClose={handleCloseRestoreConfirmationModal}
          onConfirm={handleRestoreCommunity}
        />
      )}
    </div>
  );
};

export default ArchivedCommunities;
