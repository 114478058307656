import React, { createContext, useContext, useState, useEffect } from 'react';
import { useSettings } from 'hooks/api/settings/useSettings';
import { getThemeByOrganizationId, ThemeColors } from './ThemeColors'; // Adjust the path as necessary

function useQueryParams() {
  if (typeof window !== 'undefined') {
    return new URLSearchParams(window.location.search);
  }
  return new URLSearchParams();
}

interface ThemeProviderProps {
  children: React.ReactNode;
}

type ThemeContextType = {
  theme: string;
  setTheme: (theme: string) => void;
};

const defaultThemeValue: ThemeContextType = {
  theme: 'default', // This can be any default theme name
  setTheme: () => {}, // An empty function as a placeholder
};
const ThemeContext = createContext(defaultThemeValue);

export const useTheme = () => {
  return useContext(ThemeContext);
};

export const ThemeProvider: React.FC<ThemeProviderProps> = ({ children }) => {
  const { data: settingsInfo, isLoading } = useSettings();
  const queryParams = useQueryParams();

  const [theme, setTheme] = useState<string>('default');

  useEffect(() => {
    const organizationId =
      settingsInfo?.primaryOrganization?.id || decodeURIComponent(queryParams.get('orgId') || '');
    const isDarkTheme = settingsInfo?.settings?.darkTheme === true;

    // Get the appropriate theme colors
    const themeColors: ThemeColors = getThemeByOrganizationId(organizationId, isDarkTheme);

    // Apply the selected theme
    for (const colorName in themeColors) {
      document.documentElement.style.setProperty(colorName, themeColors[colorName]);
    }

    // Update the theme state
    setTheme(isDarkTheme ? 'dark' : 'default'); // Adjust if you have specific names for your themes
  }, [settingsInfo, isLoading]);

  return <ThemeContext.Provider value={{ theme, setTheme }}>{children}</ThemeContext.Provider>;
};
