import { useQuery } from '@tanstack/react-query';
import { getConversation } from 'utils/api/message';

export const useConversation = (conversationId: string) => {
  const { data, isLoading, refetch } = useQuery({
    queryKey: ['conversation', conversationId],
    queryFn: () => getConversation(conversationId),
    select: (data) => data.data,
  });

  return {
    participants: data?.participants,
    community: data?.community, // Add community
    program: data?.program, // Add program
    alias: data?.alias,
    conversationType: data?.conversationType,
    isLoading,
    refetch,
  };
};
