import { AxiosRequestConfig } from 'axios';
import { API_ROOT } from 'constants/config';
import {
  CommunityResourcesResponseType,
  MyResourcesResponseType,
  SharedResourcesResponseType,
} from 'types/apis/response';
import { generateQueryParam, parseDELETE, parseGET, parsePOST, parsePUT } from 'utils/rest';

export const getCommunityResources = (
  communityId: string,
  limit = 10,
  cursor?: string,
  options?: AxiosRequestConfig,
) => {
  const queryParam = generateQueryParam({ limit, cursor });

  return parseGET<CommunityResourcesResponseType>(
    `${API_ROOT}/community/${communityId}/resources${queryParam}`,
    {
      withCredentials: true,
      ...options,
    },
  );
};

export const addCommunityResource = (
  communityId: string,
  body: FormData,
  options?: AxiosRequestConfig,
) =>
  parsePOST(`${API_ROOT}/community/${communityId}/resources`, body, {
    withCredentials: true,
    ...options,
  });

export const updateCommunityResource = (
  communityId: string,
  resourceId: string,
  body: { title: string; url?: string },
  options?: AxiosRequestConfig,
) =>
  parsePUT(`${API_ROOT}/community/${communityId}/resources/${resourceId}`, body, {
    withCredentials: true,
    ...options,
  });

export const deleteCommunityResource = (
  communityId: string,
  resourceId: string,
  options?: AxiosRequestConfig,
) =>
  parseDELETE(`${API_ROOT}/community/${communityId}/resources/${resourceId}`, {
    withCredentials: true,
    ...options,
  });

export const hideCommunityResource = (
  communityId: string,
  resourceId: string,
  options?: AxiosRequestConfig,
) =>
  parsePUT(`${API_ROOT}/community/${communityId}/resources/${resourceId}/hide`, {
    withCredentials: true,
    ...options,
  });

export const unhideCommunityResource = (
  communityId: string,
  resourceId: string,
  options?: AxiosRequestConfig,
) =>
  parsePUT(`${API_ROOT}/community/${communityId}/resources/${resourceId}/unhide`, {
    withCredentials: true,
    ...options,
  });

export const pinCommunityResource = (
  communityId: string,
  resourceId: string,
  options?: AxiosRequestConfig,
) =>
  parsePUT(`${API_ROOT}/community/${communityId}/resources/${resourceId}/pin`, {
    withCredentials: true,
    ...options,
  });

export const getMyResources = (options?: AxiosRequestConfig) =>
  parseGET<MyResourcesResponseType>(`${API_ROOT}/resource/myresources`, {
    withCredentials: true,
    ...options,
  });

export const getSharedResources = (options?: AxiosRequestConfig) =>
  parseGET<SharedResourcesResponseType>(`${API_ROOT}/resource/sharedresources`, {
    withCredentials: true,
    ...options,
  });
