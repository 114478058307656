import Loading from 'components/Loading';
import ConfirmationModal from 'components/modal/ConfirmationModal';
import NavigateTo404 from 'components/NavigateTo404';
import { userAvatar } from 'constants/common';
import { EditorState } from 'draft-js';
import { useProgramEvent } from 'hooks/api/event/useProgramEvent';
import { useProgramEventComments } from 'hooks/api/event/useProgramEventComments';
import { useInvalidateQueries } from 'hooks/common/useInvalidatequeries';
import React, { createContext, useState } from 'react';
import { UseFormReset } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { programEventAddToCalendar } from 'utils/api/calendar';
import {
  addProgramEventComment,
  deleteProgramEventComment,
  dislikeProgramEvent,
  likeProgramEvent,
} from 'utils/api/event';
import { generateIcsFile } from 'utils/helper';
import { notifySuccess, notifyTranslatedError } from 'utils/notify';
import { generateOriginLink, generateUserProfileLink } from 'utils/url';
import EventDetails from '../event/EventDetails';
import { FetchNextPageOptions, InfiniteQueryObserverResult } from '@tanstack/react-query';
import { CommentsResponseType } from 'types/apis/response';

export const CommentContext = createContext({});

const ProgramEventDetailsPage = () => {
  const { t } = useTranslation();
  const { programId, eventId } = useParams();

  const [selectedCommentId, setSelectedCommentId] = useState<string | null>(null);
  const [openDeleteConfirmation, setOpenDeleteConfirmation] = useState(false);

  const { data: event, refetch, isLoading } = useProgramEvent(programId || '', eventId || '');
  const { comments, hasNextComments, fetchNextComments } = useProgramEventComments(
    programId || '',
    eventId || '',
  );
  const { invalidateSingleProgramEvent, invalidateProgramEventComments } = useInvalidateQueries();

  if (isLoading) return <Loading />;
  if (!event) return <NavigateTo404 />;

  const {
    id: eventUid,
    title,
    cover,
    about,
    liked,
    likeCount,
    commentCount,
    creator,
    program: origin,
    isAllDay,
    startTime,
    endTime,
    type,
    zoomLink,
    location,
    viewCount,
    eventContactUser,
  } = event;
  const { id: creatorId, firstName, lastName, profilePicture, userType } = creator;
  const { id: originId, name: originName } = origin;
  let eventContactUserId, contactUserFirstName, contactUserLastName, contactUserProfilePicture;
  if (eventContactUser) {
    ({
      id: eventContactUserId,
      firstName: contactUserFirstName,
      lastName: contactUserLastName,
      profilePicture: contactUserProfilePicture,
    } = eventContactUser);
  }
  // event details
  const handleLikeEvent = () => {
    if (liked) {
      dislikeProgramEvent(originId, eventUid)
        .then(() => {
          refetch();
        })
        .catch((error) => {
          notifyTranslatedError(t, error.response.data?.errorCode);
        });
    } else {
      likeProgramEvent(originId, eventUid)
        .then(() => {
          refetch();
        })
        .catch((error) => {
          notifyTranslatedError(t, error.response.data?.errorCode);
        });
    }
  };

  const handleAddToCalendar = () => {
    programEventAddToCalendar(originId, eventUid)
      .then((data) => {
        generateIcsFile(data, 'event');
      })
      .catch((error) => {
        notifyTranslatedError(t, error.response.data?.errorCode);
      });
  };

  // event comments
  const handleOpenDeleteConfirmation = (commentId: string) => {
    setSelectedCommentId(commentId);
    setOpenDeleteConfirmation(true);
  };

  const handleCloseDeleteConfirmation = () => {
    setSelectedCommentId(null);
    setOpenDeleteConfirmation(false);
  };

  const handleDeleteComment = () => {
    if (!selectedCommentId) return;
    deleteProgramEventComment(originId, selectedCommentId)
      .then(() => {
        notifySuccess(t('success.api.comment_deleted'));
        handleCloseDeleteConfirmation();
        invalidateSingleProgramEvent(originId, eventUid);
        invalidateProgramEventComments(originId, eventUid);
      })
      .catch((error) => {
        notifyTranslatedError(t, error.response.data?.errorCode);
      });
  };

  // comment publish
  const handlePublishComment = (
    data: { comment: string },
    reset: UseFormReset<{ comment: string }>,
    setEditorState: React.Dispatch<React.SetStateAction<EditorState>>,
  ) => {
    addProgramEventComment(originId, eventUid, data)
      .then(() => {
        reset({ comment: '' });
        setEditorState(EditorState.createEmpty());
        invalidateSingleProgramEvent(originId, eventUid);
        invalidateProgramEventComments(originId, eventUid);
      })
      .catch((error) => {
        notifyTranslatedError(t, error.response.data?.errorCode);
      });
  };

  return (
    <div>
      <EventDetails
        eventTitle={title}
        eventCover={cover || ''}
        eventAbout={about}
        isEventLiked={liked}
        handleLikeEvent={handleLikeEvent}
        eventLikeCount={likeCount}
        eventCommentCount={commentCount}
        eventCreatorName={`${firstName} ${lastName}`}
        eventCreatorAvatar={profilePicture || userAvatar}
        eventCreatorProfileLink={generateUserProfileLink(creatorId)}
        eventCreatorUserType={userType}
        eventCreatorId={creatorId}
        contactUserId={eventContactUserId}
        contactUserAvatar={contactUserProfilePicture || userAvatar}
        contactUserName={`${contactUserFirstName} ${contactUserLastName}`}
        contactUserProfileLink={generateUserProfileLink(eventContactUserId || '')}
        eventOriginType='program'
        eventOriginId={originId}
        eventOriginName={originName}
        eventOriginLink={generateOriginLink(originId, 'program')}
        eventIsAllDay={isAllDay}
        eventStartTime={startTime}
        eventEndTime={endTime}
        eventType={type}
        eventLink={zoomLink}
        eventLocation={location}
        eventViewCount={viewCount}
        handleAddToCalendar={handleAddToCalendar}
        comments={comments}
        hasNextComments={hasNextComments}
        fetchNextComments={
          fetchNextComments as (
            options?: FetchNextPageOptions | undefined,
          ) => Promise<InfiniteQueryObserverResult<CommentsResponseType, unknown>>
        }
        handlePublishComment={handlePublishComment}
        handleDeleteComment={handleOpenDeleteConfirmation}
      />

      {openDeleteConfirmation && (
        <ConfirmationModal
          isOpen={openDeleteConfirmation}
          label={t('delete-comment')}
          title={t('delete-comment_confirmation_title')}
          onClose={handleCloseDeleteConfirmation}
          onConfirm={handleDeleteComment}
        />
      )}
    </div>
  );
};

export default ProgramEventDetailsPage;
