import CommentIcon from 'assets/icons/comment.svg';
import DownloadIcon from 'assets/icons/download.svg';
import ViewsIcon from 'assets/icons/eye.svg';
import LikeIcon from 'assets/icons/like.svg';
import NoteIcon from 'assets/icons/note-filled.svg';
import PinIcon from 'assets/icons/pin.svg';
import Avatar from 'components/memberAndProfile/Avatar';
import Tag from 'components/tag/Tag';
import BodyText from 'components/typography/BodyText';
import { useInvalidateQueries } from 'hooks/common/useInvalidatequeries';
import { TFunction } from 'i18next';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { EmbedType, MemberRoleType, PostOrigin, SingleCommentType } from 'types/apis/common';
import {
  bytesToMegaBytes,
  convertHtmlToLink,
  editorContentToText,
  sanitizeHtml,
} from 'utils/helper';
import { timeSince } from 'utils/time';
import { generateOriginLink, generateUserProfileLink } from 'utils/url';
import CommonComments from './CommonComments';
import CommonPostContextMenu from './CommonPostContextMenu';
import ResourceViewerDrawer from 'components/drawer/ResourceViewerDrawer';
import { isViewableFile } from 'utils/helper';

interface ICommonPost {
  isHome?: boolean;
  postId: string;
  isAuthor: boolean;
  authorId: string;
  authorLogo: string;
  authorName: string;
  authorHeadline?: string;
  authorRole: MemberRoleType;
  postOriginName?: string;
  createdAt: string;
  embedType: EmbedType;
  embedId?: string;
  embedMeta?: string;
  file: null | {
    id: string;
    fileName: string;
    fileSize: number;
    directory: string;
    createdAt: string;
  };
  postContent: string;
  postLikeCount: number;
  postCommentCount: number;
  postViewCount: number;
  isPostLiked: boolean;
  canManage: boolean;
  isPostPinned: boolean;
  postOriginId: string;
  postOriginType: PostOrigin;
  handleLikePost: (
    isLiked: boolean,
    postId: string,
    originId: string,
    originType: PostOrigin,
    inValidateQueries: () => void,
    t: TFunction,
  ) => void;
  handlePinPost: (isPinned: boolean, postId: string) => void;
  comments: SingleCommentType[];
  hasNextComments: boolean;
  fetchNextComments: () => void;
  showComments: boolean;
  setShowComments: React.Dispatch<React.SetStateAction<boolean>>;
  savedPostId?: string;
}

const CommonPost = ({
  comments,
  isHome,
  postId,
  authorLogo,
  authorName,
  authorId,
  authorRole,
  authorHeadline,
  postOriginName,
  createdAt,
  embedType,
  embedId,
  embedMeta,
  file,
  postContent,
  postLikeCount,
  postCommentCount,
  postViewCount,
  isPostLiked,
  canManage,
  isAuthor,
  isPostPinned,
  handleLikePost,
  handlePinPost,
  postOriginId,
  postOriginType,
  hasNextComments,
  fetchNextComments,
  showComments,
  setShowComments,
  savedPostId,
}: ICommonPost) => {
  const { t } = useTranslation();
  const [showResourceViewer, setShowResourceViewer] = useState(false);
  const [isCollapse, setIsCollapse] = useState(true);
  const [fileUrl, setFileUrl] = useState('');
  const [uploaderName, setUploaderName] = useState('');
  const [uploadedBy, setUploadedBy] = useState('');
  const [originId, setOriginId] = useState('');
  const [originType, setOriginType] = useState('');

  const {
    invalidateAllPosts,
    invalidateProgramPosts,
    invalidateCommunityPosts,
    invalidateSinglePost,
  } = useInvalidateQueries();

  return (
    <div className='space-y-4 rounded-xl bg-neutral-200 p-4 shadow shadow-neutral-300'>
      <div className='flex items-center gap-4 border-b border-neutral-300 pb-5'>
        <Link to={generateUserProfileLink(authorId)} className='shrink-0'>
          <Avatar size={48} alt={authorName} src={authorLogo} />
        </Link>

        <div className='mr-auto grow'>
          <div className='flex items-center gap-4'>
            <BodyText
              variant='base'
              className='line-clamp-1 font-medium capitalize text-neutral-600'
            >
              <Link to={generateUserProfileLink(authorId)}>{authorName}</Link>
            </BodyText>
            <Tag
              type='rounded'
              className='bg-functional-success-light text-functional-success-dark'
            >
              {t(`member.type.${authorRole}`)}
            </Tag>
          </div>
          {authorHeadline && (
            <BodyText as='span' variant='sm' className='line-clamp-1 text-neutral-400'>
              {authorHeadline}
            </BodyText>
          )}

          <BodyText variant='sm' className='flex items-center gap-2 text-neutral-400'>
            {postOriginName && (
              <>
                <BodyText
                  as='span'
                  variant='sm'
                  className='line-clamp-1 capitalize hover:underline'
                >
                  <Link to={generateOriginLink(postOriginId, postOriginType)}>
                    {postOriginName}
                  </Link>
                </BodyText>
                <span className='h-1 w-1 shrink-0 rounded-full bg-neutral-400' />
              </>
            )}
            <BodyText as='span' variant='sm' className='line-clamp-1'>
              {timeSince(new Date(createdAt))}
            </BodyText>
          </BodyText>
        </div>

        <CommonPostContextMenu
          isHome={isHome}
          postId={postId}
          canManage={canManage || isAuthor}
          originId={postOriginId}
          originType={postOriginType}
          savedPostId={savedPostId}
        />

        {canManage && (
          <button
            className={`flex h-6 w-6  items-center justify-center rounded-full hover:bg-neutral-200 ${
              isPostPinned
                ? 'bg-functional-info-light text-functional-info-dark'
                : 'text-neutral-400'
            }`}
            onClick={() => handlePinPost(isPostPinned, postId)}
          >
            <PinIcon className='h-4 w-4' />
          </button>
        )}

        {!canManage && isPostPinned && (
          <div className='flex h-6 w-6 items-center justify-center rounded-full bg-functional-info-light text-functional-info-dark'>
            <PinIcon className='h-4 w-4' />
          </div>
        )}
      </div>

      <div className='space-y-5'>
        {embedType === 'image' && file && (
          <img src={file.directory} alt='' className='mx-auto h-[300px] rounded object-cover' />
        )}

        {embedType === 'file' && file && (
          <div className='flex items-center justify-between gap-3 rounded-xl border border-neutral-300 p-2'>
            <button
              onClick={() => {
                if (isViewableFile(file.directory)) {
                  setFileUrl(file.directory);
                  setOriginId(postOriginId);
                  setOriginType(postOriginType);
                  setUploaderName(authorName);
                  setUploadedBy(authorId);
                  setShowResourceViewer(true);
                } else {
                  window.location.href = file.directory;
                }
              }}
              className={`neutral-button w-full rounded-xl p-2 text-left`}
            >
              <BodyText variant='lg' className='break-all font-medium'>
                {file.fileName}
              </BodyText>
              <BodyText variant='xs' className='line-clamp-1 break-words text-neutral-400'>
                {bytesToMegaBytes(file.fileSize)}
              </BodyText>
            </button>

            <button
              className='flex h-8 w-8 shrink-0 items-center justify-center rounded-full bg-functional-info-light hover:bg-neutral-300 active:bg-neutral-400'
              onClick={() => {
                window.open(file.directory, '_blank');
              }}
            >
              <DownloadIcon className='h-5 w-5 text-functional-info-dark' />
            </button>
          </div>
        )}

        {embedType === 'page' && embedId && (
          <button
            onClick={() => {
              setFileUrl('');
              setOriginId(embedId);
              setOriginType('page');
              setUploaderName(authorName);
              setUploadedBy(authorId);
              setShowResourceViewer(true);
            }}
            className={`neutral-button w-full rounded-xl p-2 text-left`}
          >
            <div className='neutral-button flex items-center gap-3 rounded-xl border border-neutral-300 p-4 text-neutral-500'>
              <NoteIcon className='h-6 w-6' />
              <BodyText variant='lg' className='break-words font-medium'>
                {embedMeta}
              </BodyText>
            </div>
          </button>
        )}

        <div>
          <BodyText
            variant='base'
            className={`whitespace-pre-line break-words text-neutral-500 ${
              embedType
                ? isCollapse
                  ? editorContentToText(postContent).length > 500
                    ? 'line-clamp-2'
                    : ''
                  : ''
                : isCollapse
                  ? editorContentToText(postContent).length > 700
                    ? 'line-clamp-4'
                    : ''
                  : ''
            }`}
          >
            <span
              // eslint-disable-next-line react/no-danger
              dangerouslySetInnerHTML={{
                __html: sanitizeHtml(convertHtmlToLink(editorContentToText(postContent))),
              }}
            />
          </BodyText>
          {embedType
            ? editorContentToText(postContent).length > 500 && (
                <button onClick={() => setIsCollapse(!isCollapse)}>
                  <BodyText variant='sm' className='mt-2 cursor-pointer font-medium'>
                    {isCollapse ? t('show_more') : t('show_less')}
                  </BodyText>
                </button>
              )
            : editorContentToText(postContent).length > 700 && (
                <button onClick={() => setIsCollapse(!isCollapse)}>
                  <BodyText variant='sm' className='mt-2 cursor-pointer font-medium'>
                    {isCollapse ? t('show_more') : t('show_less')}
                  </BodyText>
                </button>
              )}
        </div>
      </div>

      <div className='flex items-center gap-7 border-t border-neutral-300 pt-4'>
        <button
          onClick={() =>
            handleLikePost(
              isPostLiked,
              postId,
              postOriginId,
              postOriginType,
              () => {
                invalidateAllPosts();
                if (postOriginType === PostOrigin.program) {
                  invalidateProgramPosts(postOriginId);
                }
                if (postOriginType === PostOrigin.community) {
                  invalidateCommunityPosts(postOriginId);
                }
                invalidateSinglePost(postOriginType, postOriginId, postId);
              },
              t,
            )
          }
          className={`flex items-center gap-2 ${
            isPostLiked ? 'text-functional-info-400' : 'text-neutral-400'
          }`}
        >
          <LikeIcon className='h-4 w-4' />
          <BodyText variant='xs' className='font-medium'>
            {postLikeCount}
          </BodyText>
        </button>
        <button
          className={`group relative flex items-center gap-2 ${
            postCommentCount > 0 ? 'text-functional-info-400' : 'text-neutral-400'
          }`}
          onClick={() => setShowComments(!showComments)}
        >
          <CommentIcon className='h-4 w-4' />
          <BodyText variant='xs' className='font-bold'>
            {postCommentCount}
          </BodyText>
          <div className='tooltip top-5'>
            <BodyText variant='xs' className='whitespace-nowrap text-neutral-100'>
              {t('comments')}
            </BodyText>
          </div>
        </button>
        <button className='group relative flex items-center gap-2'>
          <ViewsIcon className='h-4 w-4 text-neutral-400' />
          <BodyText variant='xs' className='font-medium text-neutral-400'>
            {postViewCount}
          </BodyText>
          <div className='tooltip top-4'>
            <BodyText variant='xs' className='whitespace-nowrap text-neutral-100'>
              {t('view_count')}
            </BodyText>
          </div>
        </button>
      </div>
      {(isViewableFile(fileUrl) || originType === 'page') && (
        <ResourceViewerDrawer
          isOpen={showResourceViewer}
          setIsOpen={setShowResourceViewer}
          fileUrl={fileUrl}
          uploadedBy={uploaderName}
          uploaderId={uploadedBy}
          originId={originId}
          originType={originType}
          allowSideBar={false}
        />
      )}
      {showComments && (
        <CommonComments
          postId={postId}
          comments={comments}
          originId={postOriginId}
          originType={postOriginType}
          hasNextComments={hasNextComments}
          fetchNextComments={fetchNextComments}
        />
      )}
    </div>
  );
};

export default CommonPost;
