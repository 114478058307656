import MessageIcon from 'assets/icons/message.svg';
import MutualIcon from 'assets/icons/mutual.svg';
import Loading from 'components/Loading';
import Avatar from 'components/memberAndProfile/Avatar';
import Button from 'components/button/Button';
import UrlIcon from 'assets/icons/link.svg';
import NavigateTo404 from 'components/NavigateTo404';
import Tag from 'components/tag/Tag';
import BodyText from 'components/typography/BodyText';
import Heading from 'components/typography/Heading';
import { profileBanner, userAvatar } from 'constants/common';
import SingleConversationDrawer from 'components/drawer/SingleConversationDrawer';
import { useMemberProfileInfo } from 'hooks/api/profile/useMemberProfileInfo';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import LinkifiedText from 'components/common/LinkifiedText';
import { startConversation } from 'utils/api/message';
import { notifyTranslatedError } from 'utils/notify';
import { generateOriginLink } from 'utils/url';

type MemberProfileProps = {
  memberId: string;
};
interface Link {
  title: string;
  url: string;
}

const MemberProfile = ({ memberId }: MemberProfileProps) => {
  const { t } = useTranslation();
  const [isConversationOpen, setIsConversationOpen] = useState(false);
  const [conversationId, setConversationId] = useState('');

  const { data: memberInfo, isLoading: isMemberInfoLoading } = useMemberProfileInfo(memberId);
  if (isMemberInfoLoading) return <Loading />;
  if (!isMemberInfoLoading && !memberInfo) return <NavigateTo404 />;

  const handleStartChat = () => {
    let conversationType;
    if (memberInfo?.userType == 'chatbot') {
      conversationType = 'private_chatbot';
    } else {
      conversationType = 'private';
    }
    startConversation({
      participants: [memberInfo?.id || ''],
      conversationType,
    })
      .then((data) => {
        setConversationId(data.data.conversationId);
        setIsConversationOpen(true);
      })
      .catch((error) => {
        notifyTranslatedError(t, error.response.data?.errorCode);
      });
  };

  if (!memberInfo) return null;

  const {
    firstName,
    lastName,
    cover,
    profilePicture,
    headline,
    about,
    links,
    interests,
    expertise,
    sharedCommunity,
    sharedProgram,
  } = memberInfo;

  return (
    <div className='space-y-3 p-3'>
      <div className='rounded-2xl bg-neutral-200 shadow shadow-neutral-300'>
        <div className='relative'>
          <img
            alt=''
            className='h-[200px] w-full rounded-lg object-cover'
            src={cover || profileBanner}
          />
        </div>

        <div className='relative -mt-13 px-5 py-4'>
          <div className='w-fit rounded-full border-4 border-white'>
            <Avatar
              className='z-10'
              size={100}
              alt={`${firstName} ${lastName}`}
              src={profilePicture || userAvatar}
            />
          </div>

          <div className='flex items-center justify-between'>
            <div>
              <Heading
                variant='h2'
                className='font-semibold text-neutral-600'
              >{`${firstName} ${lastName}`}</Heading>
            </div>

            <Button
              className='hover:bg-neutral-300 hover:text-functional-info-dark active:bg-neutral-400 active:text-functional-info-dark'
              onClick={handleStartChat}
              size='default'
              variant='outlined'
            >
              <span className='hidden md:block'>{t('chat')}</span>
              <MessageIcon className='h-4 w-4 ' />
            </Button>
          </div>
          <BodyText variant='lg' className='line-clamp-2'>
            {headline}
          </BodyText>
        </div>
      </div>

      <div className='space-y-2 rounded-2xl bg-neutral-200 p-3'>
        <Heading variant='h3' className=' text-neutral-400'>
          {t('about')}
        </Heading>

        {about && (
          <BodyText variant='base' className='about-content whitespace-pre-line'>
            <LinkifiedText text={about} />
          </BodyText>
        )}
      </div>
      {links && (
        <div className='space-y-3 p-3'>
          <Heading variant='h3' className=' text-neutral-400'>
            {t('links')}
          </Heading>
          <div className='flex flex-wrap items-center gap-3'>
            {JSON.parse(links).map((link: Link) => (
              <Button
                variant='secondary-outlined'
                size='small'
                onClick={() => {
                  window.open(link.url, '_blank');
                }}
                className='flex items-center gap-2'
              >
                <UrlIcon className='h-4 w-4' />
                {link.title}
              </Button>
            ))}
          </div>
        </div>
      )}
      {(sharedCommunity || sharedProgram) && (
        <div className='space-y-2 rounded-2xl bg-neutral-200 p-3'>
          <Heading variant='h3' className='text-neutral-400'>
            {t('mutual')}
          </Heading>
          <div className='flex flex-wrap items-center gap-3'>
            {sharedCommunity &&
              sharedCommunity.length > 0 &&
              sharedCommunity.map((community) => {
                // Check if community is an object and has a communityId property
                if (typeof community === 'object' && 'communityId' in community) {
                  return (
                    <div key={community.communityId} className='group relative'>
                      <Tag
                        type='default'
                        className='mb-2 bg-functional-info-light capitalize text-functional-info-dark'
                      >
                        <Link to={generateOriginLink(community.communityId, 'community')}>
                          {community.name}
                        </Link>
                      </Tag>
                    </div>
                  );
                }
                return null;
              })}
            {sharedProgram &&
              sharedProgram.length > 0 &&
              sharedProgram.map((program) => {
                if (typeof program === 'object' && 'programId' in program) {
                  return (
                    <div key={program.programId} className='group relative'>
                      <Tag
                        type='default'
                        className='mb-2 bg-functional-info-light capitalize text-functional-info-dark'
                      >
                        <Link to={generateOriginLink(program.programId, 'program')}>
                          {program.name}
                        </Link>
                      </Tag>
                    </div>
                  );
                }
                return null;
              })}
          </div>
        </div>
      )}
      {/* expertise */}
      <div className='space-y-2 rounded-2xl bg-neutral-200 p-3'>
        <Heading variant='h3' className='text-neutral-400'>
          {t('expertise')}
        </Heading>

        <div className='flex flex-wrap items-center gap-3'>
          {expertise?.map(({ id, name, mutual }) => (
            <div key={id} className='group relative'>
              <div
                className={`rounded-md border  px-2 py-1  ${
                  mutual ? 'border-functional-info-dark' : 'border-neutral-500'
                }`}
              >
                <BodyText
                  variant='base'
                  className={`flex items-center gap-2 font-medium capitalize ${
                    mutual ? 'text-functional-info-dark' : 'text-neutral-500'
                  }`}
                >
                  {mutual && <MutualIcon className='h-3 w-3 text-functional-info-dark' />} {name}
                </BodyText>
              </div>
              {mutual && (
                <div className='absolute left-2/4 my-2 hidden -translate-x-2/4 rounded-lg bg-functional-info-light px-3 py-1 text-xs font-medium text-functional-info-dark group-hover:block'>
                  {t('mutual')}
                </div>
              )}
            </div>
          ))}
        </div>
      </div>

      {/* interests */}
      <div className='space-y-2 rounded-2xl bg-neutral-200 p-3'>
        <Heading variant='h3' className='text-neutral-400'>
          {t('interests')}
        </Heading>

        <div className='flex flex-wrap items-center gap-3'>
          {interests?.map(({ id, name, mutual }) => (
            <div key={id} className='group relative'>
              <div className='rounded-md border border-neutral-300 px-2 py-1'>
                <BodyText
                  variant='base'
                  className='flex items-center gap-2 font-medium capitalize text-neutral-500'
                >
                  {mutual && <MutualIcon className='h-3 w-3 text-functional-info-dark' />} {name}
                </BodyText>
              </div>

              {mutual && (
                <div className='absolute left-2/4 my-2 hidden -translate-x-2/4 rounded-lg bg-functional-info-light px-3 py-1 text-xs font-medium text-functional-info-dark group-hover:block'>
                  {t('mutual')}
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
      <SingleConversationDrawer
        conversationId={conversationId}
        isOpen={isConversationOpen}
        setIsOpen={setIsConversationOpen}
      />
    </div>
  );
};

export default MemberProfile;
