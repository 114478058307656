import Button from 'components/button/Button';
import ResetPassCard from 'components/card/ResetPassCard';
import TextInput from 'components/form/TextInput';
import BodyText from 'components/typography/BodyText';
import Heading from 'components/typography/Heading';
import React from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import { ForgotPasswordFormType } from 'types/components/form';
import { resendVerificationEmail } from 'utils/api/auth';
import { notifySuccess, notifyTranslatedError } from 'utils/notify';

function ResendVerificationPage() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<ForgotPasswordFormType>();

  const onSubmit: SubmitHandler<ForgotPasswordFormType> = (data) => {
    resendVerificationEmail(data)
      .then(() => {
        notifySuccess(t('success.api.varification_code_sent'));
      })
      .catch((error) => {
        notifyTranslatedError(t, error.response.data?.errorCode);
        notifyTranslatedError(t, error.response.data?.message);
      });
  };

  return (
    <main className='flex h-screen items-center justify-center px-4 md:px-0 bg-neutral-200'>
      <ResetPassCard>
        <Heading variant='h2' className='mb-3 text-center font-bold'>
          {t('resend_verification_title')}
        </Heading>
        <BodyText variant='base' className='mb-6 text-center text-neutral-400'>
          {t('forgot_password_subtitle')}
        </BodyText>

        <form onSubmit={handleSubmit(onSubmit)} className='mb-6 space-y-6'>
          <div>
            <Controller
              name='email'
              control={control}
              rules={{
                required: { value: true, message: t('required_error_message') },
              }}
              render={({ field }) => (
                <TextInput
                  {...field}
                  type='email'
                  placeholder={t('email_placeholder') || 'example@mail.com'}
                  className={errors.email ? 'border-functional-danger-400' : ''}
                />
              )}
            />
            {errors.email && (
              <BodyText variant='xs' className='text-functional-danger-400'>
                {errors.email?.message}
              </BodyText>
            )}
          </div>

          <Button size='default' variant='secondary' className='w-full'>
            {t('submit')}
          </Button>
        </form>
        <BodyText variant='xs' className='mb-4 text-functional-danger-dark'>
          {t('email-tip')}
        </BodyText>

        <BodyText variant='base' className='text-center font-medium text-functional-info-dark'>
          <Link to='/login'>{t('back_to_signin')}</Link>
        </BodyText>
      </ResetPassCard>
    </main>
  );
}

export default ResendVerificationPage;
