import { AxiosRequestConfig } from 'axios';
import { API_ROOT } from 'constants/config';
import { CommonResponseType } from 'types/apis/common';
import {
  ForgotPasswordRequestType,
  LoginRequestType,
  ResetPasswordRequestType,
  SignupRequestType,
  VerifyEmailRequestType,
  VerifyResetCodeRequestType,
} from 'types/apis/request';
import { IsLoggedInResponseType } from 'types/apis/response';
import { parseGET, parsePOST, parsePUT } from 'utils/rest';

export const login = (body: LoginRequestType, options?: AxiosRequestConfig) =>
  parsePOST<LoginRequestType, CommonResponseType>(`${API_ROOT}/auth/login`, body, {
    withCredentials: true,
    ...options,
  });

export const verifyEmailLink = (token: string, options?: AxiosRequestConfig) =>
  parseGET(`${API_ROOT}/auth/verify-email-link?token=${token}`, {
    withCredentials: true,
    ...options,
  });

export const signup = (body: SignupRequestType, token: string, options?: AxiosRequestConfig) => {
  let url = `${API_ROOT}/auth/registration?token=${token}`;
  return parsePOST(url, body, {
    withCredentials: true,
    ...options,
  });
};

export const verifyEmail = (body?: VerifyEmailRequestType, options?: AxiosRequestConfig) =>
  parsePUT(`${API_ROOT}/auth/verify-email`, body, {
    withCredentials: true,
    ...options,
  });

export const forgotPassword = (body: ForgotPasswordRequestType, options?: AxiosRequestConfig) =>
  parsePOST(`${API_ROOT}/auth/forgot-password`, body, {
    withCredentials: true,
    ...options,
  });

export const resendVerificationEmail = (
  body: ForgotPasswordRequestType,
  options?: AxiosRequestConfig,
) =>
  parsePOST(`${API_ROOT}/auth/resend-verification-email`, body, {
    withCredentials: true,
    ...options,
  });

export const verifyResetCode = (body: VerifyResetCodeRequestType, options?: AxiosRequestConfig) =>
  parsePOST(`${API_ROOT}/auth/verify-reset-code`, body, {
    withCredentials: true,
    ...options,
  });

export const resetPassword = (body: ResetPasswordRequestType, options?: AxiosRequestConfig) =>
  parsePUT(`${API_ROOT}/auth/reset-password`, body, {
    withCredentials: true,
    ...options,
  });

export const isLoggedIn = (options?: AxiosRequestConfig) =>
  parseGET<IsLoggedInResponseType>(`${API_ROOT}/auth/me`, {
    withCredentials: true,
    ...options,
  });

export const logout = (options?: AxiosRequestConfig) =>
  parseGET(`${API_ROOT}/auth/logout`, {
    withCredentials: true,
    ...options,
  });

export const changePassword = (
  body: { oldPassword: string; newPassword: string },
  options?: AxiosRequestConfig,
) => {
  return parsePUT(`${API_ROOT}/auth/change-password`, body, {
    withCredentials: true,
    ...options,
  });
};

export const changeMemberRole = (
  body: { memberId: string; role: string; originType: string; originId: string },
  options?: AxiosRequestConfig,
) => {
  return parsePUT(`${API_ROOT}/auth/change-role`, body, {
    withCredentials: true,
    ...options,
  });
};
