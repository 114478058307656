import Button from 'components/button/Button';
import TextInput from 'components/form/TextInput';
import BodyText from 'components/typography/BodyText';
import React, { useEffect } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { updateCommunityResource } from 'utils/api/resource';
import { notifySuccess, notifyTranslatedError } from 'utils/notify';
import ModalContainer from './ModalContainer';

interface IEditResourceModal {
  isOpen: boolean;
  onClose: () => void;
  communityId: string;
  resourceId: string;
  refetch: () => void;
  title: string;
  url: string | null;
}

const EditResourceModal = ({
  url,
  title,
  isOpen,
  onClose,
  refetch,
  resourceId,
  communityId,
}: IEditResourceModal) => {
  const { t } = useTranslation();
  const {
    control,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm<{ title: string; url: string }>();

  const onSubmit: SubmitHandler<{ title: string; url: string }> = (data) => {
    updateCommunityResource(communityId, resourceId, data)
      .then(() => {
        refetch();
        onClose();
        notifySuccess(t('success.api.resource_updated'));
      })
      .catch((error) => {
        notifyTranslatedError(t, error.response.data?.errorCode);
      });
  };

  useEffect(() => {
    setValue('title', title);

    if (url) {
      setValue('url', url);
    }
  }, [resourceId]);

  return (
    <ModalContainer title={t('edit-resource')} isOpen={isOpen} onClose={onClose}>
      <form onSubmit={handleSubmit(onSubmit)} className='space-y-5'>
        <div className='space-y-1'>
          <BodyText
            variant='sm'
            className={`font-medium ${
              errors.title ? 'text-functional-danger-400' : 'text-neutral-500'
            }`}
          >
            {t('title')}
          </BodyText>
          <Controller
            name='title'
            control={control}
            rules={{ required: true }}
            render={({ field }) => (
              <TextInput
                {...field}
                placeholder={t('title') || 'Title'}
                className={errors.title ? 'border-functional-danger-400' : ''}
              />
            )}
          />
        </div>

        {url && (
          <div className='space-y-2'>
            <BodyText
              variant='sm'
              className={`font-medium ${
                errors.url ? 'text-functional-danger-400' : 'text-neutral-500'
              }`}
            >
              {t('url')}
            </BodyText>
            <Controller
              name='url'
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <TextInput
                  {...field}
                  placeholder={t('url') || 'https://drive.google.com/1dKyuPqaWcXQemrxL'}
                  className={errors.url ? 'border-functional-danger-400' : ''}
                />
              )}
            />
          </div>
        )}

        <div className='mt-5 flex items-center justify-end gap-3 border-t border-neutral-200 pt-5'>
          <Button type='button' size='small' variant='outlined' onClick={onClose}>
            {t('cancel')}
          </Button>
          <Button type='submit' size='small' variant='secondary'>
            {t('save')}
          </Button>
        </div>
      </form>
    </ModalContainer>
  );
};

export default EditResourceModal;
