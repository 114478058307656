import { useInfiniteQuery } from '@tanstack/react-query';
import { getProgramPendingInvites } from 'utils/api/member';

export const useProgramPendingInvites = (programId: string) => {
  const { data, hasNextPage, fetchNextPage, refetch } = useInfiniteQuery({
    queryKey: ['program-pending-invites', programId],
    queryFn: ({ pageParam = '' }) => getProgramPendingInvites(programId, 3, pageParam),
    getNextPageParam: (lastPage) => lastPage.data.cursor,
  });

  return {
    invites: data?.pages.flatMap((item) => item.data.invites) ?? [],
    hasNextInvites: hasNextPage,
    fetchNextInvites: fetchNextPage,
    refetchInvites: refetch,
  };
};
