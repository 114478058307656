import SavedPost from 'components/post/SavedPost';
import Heading from 'components/typography/Heading';
import { useCommunitySavedPosts } from 'hooks/api/post/useCommunitySavedPosts';
import React from 'react';
import { useTranslation } from 'react-i18next';

interface ICommunitySavedPosts {
  communityId: string;
}

const CommunitySavedPosts = ({ communityId }: ICommunitySavedPosts) => {
  const { t } = useTranslation();

  const { data: savedPost } = useCommunitySavedPosts(communityId);

  if (!savedPost || savedPost.length < 1) return null;

  return (
    <div className='rounded-xl bg-neutral-200 px-4 py-5'>
      <Heading variant='h2' className='mb-5 font-semibold'>
        {t('saved_posts')}
      </Heading>

      <div className='space-y-4'>
        {savedPost.map(({ id, post }) => (
          <SavedPost key={id} savedPostid={id} post={post} id={communityId} variant='community' />
        ))}
      </div>
    </div>
  );
};

export default CommunitySavedPosts;
