import React from 'react';

type ITextarea = React.HTMLProps<HTMLTextAreaElement>;

function Textarea({ className = '', ...rest }: ITextarea) {
  return (
    <textarea
      className={`w-full bg-neutral-100 text-base text-neutral-600 border border-neutral-300 rounded-lg py-[11px] px-[14px] outline-none ${className}`}
      {...rest}
    />
  );
}

export default Textarea;
