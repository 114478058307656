import { useQuery } from '@tanstack/react-query';
import { getPage, getAllPages, getSharedPages } from 'utils/api/pages';

export const useSingelPage = (pageId: string) => {
  return useQuery({
    queryKey: ['getpage', pageId],
    queryFn: () => getPage(pageId),
    select: (data) => data?.data.page ?? {},
    enabled: !!pageId,
  });
};

export const useAllPages = () => {
  return useQuery({
    queryKey: ['getallpages'],
    queryFn: () => getAllPages(),
    select: (data) => data.data.pages,
  });
};

export const useSharedPages = () => {
  return useQuery({
    queryKey: ['getsharedpages'],
    queryFn: () => getSharedPages(),
    select: (data) => data.data.pages,
  });
};
