import BodyText from 'components/typography/BodyText';
import React from 'react';

interface IIconButton extends React.HTMLAttributes<HTMLButtonElement> {
  badgeCount: number;
  Icon: React.ComponentType<React.ComponentProps<'svg'>>;
}

function IconButton({ badgeCount, Icon, className, ...props }: IIconButton) {
  return (
    <div className='relative'>
      <button
        {...props}
        className={`flex h-6 w-6 items-center justify-center rounded-full bg-neutral-200  ${className} 
        ${badgeCount > 0 ? 'animate-pulse' : ''} 
        `}
      >
        <Icon className='h-5 w-5' />
      </button>

      {badgeCount > 0 && (
        <div className='absolute right-[-4px] top-[-4px] flex h-5 w-5 items-center justify-center rounded-full bg-functional-danger-400 text-white'>
          <BodyText variant='sm'>{badgeCount}</BodyText>
        </div>
      )}
    </div>
  );
}

export default IconButton;
