export function getSideNavLogo(organizationId: string, darkMode: boolean) {
  // konstanz
  if (organizationId === 'clfcmye5v020kuut0iopzgxzr') {
    if (darkMode) {
      return 'https://gamma.fra1.cdn.digitaloceanspaces.com/organization/konstanz/logo_dark.png';
    } else {
      return 'https://gamma.fra1.cdn.digitaloceanspaces.com/organization/konstanz/logo_light.png';
    }
  } else return null;
}
