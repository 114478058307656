import CloseIcon from 'assets/icons/close.svg';
import Avatar from 'components/memberAndProfile/Avatar';
import UrlIcon from 'assets/icons/link.svg';
import EditIcon from 'assets/icons/edit.svg';
import Loading from 'components/Loading';
import InfoIcon from 'assets/icons/info.svg';
import NavigateTo404 from 'components/NavigateTo404';
import BodyText from 'components/typography/BodyText';
import Heading from 'components/typography/Heading';
import Button from 'components/button/Button';
import { profileBanner, userAvatar } from 'constants/common';
import { useProfileInfo } from 'hooks/api/profile/useProfileInfo';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { deleteMyExpertise, deleteMyInterest } from 'utils/api/profile';
import { notifyTranslatedError } from 'utils/notify';
import AddNewExpertise from './AddNewExpertise';
import AddNewInterests from './AddNewInterests';
import EditProfile from './EditProfile';
import { addProfileLinks } from 'utils/api/profile';

interface Link {
  title: string;
  url: string;
}

const MyProfile = () => {
  const { t } = useTranslation();
  const { data, refetch, isLoading } = useProfileInfo();
  const [isEditProfileOpen, setIsEditProfileOpen] = useState(false);
  const [isAddLink, setIsAddLink] = useState(false);
  const [newLink, setNewLink] = useState({ title: '', url: '' });

  if (isLoading) return <Loading />;

  if (!data) return <NavigateTo404 />;

  const {
    firstName,
    lastName,
    cover,
    profilePicture,
    headline,
    about,
    links,
    expertise,
    interests,
  } = data;

  const handleDeleteInterest = (interestId: string) => {
    deleteMyInterest(interestId)
      .then(() => {
        refetch();
      })
      .catch((error) => {
        notifyTranslatedError(t, error.response.data?.errorCode);
      });
  };

  const handleDeleteExpertise = (expertiseId: string) => {
    deleteMyExpertise(expertiseId)
      .then(() => {
        refetch();
      })
      .catch((error) => {
        notifyTranslatedError(t, error.response.data?.errorCode);
      });
  };

  const deleteLink = (linkToDelete: Link) => {
    // Parse the existing links
    let linksArray: Link[] = links ? JSON.parse(links) : [];
    // Filter out the link to delete
    linksArray = linksArray.filter(
      (link: Link) => link.title !== linkToDelete.title && link.url !== linkToDelete.url,
    );
    // Update the links in the state and backend
    const updatedLinksJson = JSON.stringify(linksArray);
    addProfileLinks({ links: updatedLinksJson })
      .then(() => {
        refetch(); // Refetch data to reflect changes
      })
      .catch((error) => {
        notifyTranslatedError(t, error.response.data?.errorCode);
      });
  };

  const submitLink = () => {
    let updatedLinksArray;
    // Check if 'links' exists and is a valid JSON array
    if (links) {
      try {
        const existingLinksArray = JSON.parse(links);
        // Ensure that existingLinksArray is an array
        if (Array.isArray(existingLinksArray)) {
          // Merge newLink into existingLinksArray
          updatedLinksArray = [...existingLinksArray, newLink];
        } else {
          // If existingLinksArray is not an array, create a new array with newLink
          updatedLinksArray = [newLink];
        }
      } catch (error) {
        console.error('Error parsing links: ', error);
        // Handle parsing error - fallback to just using newLink
        updatedLinksArray = [newLink];
      }
    } else {
      // If 'links' does not exist, start with an array containing just newLink
      updatedLinksArray = [newLink];
    }
    // Submit the updated links array
    addProfileLinks({ links: JSON.stringify(updatedLinksArray) })
      .then(() => {
        refetch(); // Refetch data to reflect changes
        setNewLink({ title: '', url: '' }); // Reset new link input
      })
      .catch((error) => {
        notifyTranslatedError(t, error.response.data?.errorCode);
      });
  };

  return (
    <div className='p-3'>
      {!isEditProfileOpen ? (
        <div className='space-y-3'>
          <div className='rounded-2xl bg-neutral-200 shadow shadow-neutral-300'>
            <div className='relative'>
              <img
                src={cover || profileBanner}
                alt=''
                className='h-[200px] w-full rounded-lg object-cover'
              />
            </div>

            <div className='relative -mt-13 px-5 py-4'>
              <div className='relative w-fit rounded-full border-4 border-white'>
                <Avatar
                  className='z-10'
                  size={100}
                  src={profilePicture || userAvatar}
                  alt={`${firstName} ${lastName}`}
                />
              </div>

              <div className='mt-2 flex items-center justify-between'>
                <div>
                  <Heading
                    variant='h2'
                    className='font-semibold text-neutral-600'
                  >{`${firstName} ${lastName}`}</Heading>
                </div>

                <Button variant='secondary' size='small' onClick={() => setIsEditProfileOpen(true)}>
                  <span className='hidden md:block'>{t('edit-profile')}</span>
                  <EditIcon className='h-4 w-4' />
                </Button>
              </div>
              <BodyText variant='lg'>{headline}</BodyText>
            </div>
          </div>

          <div className='space-y-2 rounded-2xl bg-neutral-200 p-3'>
            <Heading variant='h3' className=' text-neutral-400'>
              {t('about')}
            </Heading>

            {about && (
              <BodyText variant='base' className='whitespace-pre-line'>
                {about}
              </BodyText>
            )}
          </div>

          <div className='space-y-2 rounded-2xl bg-neutral-200 p-3'>
            <div className='flex items-center justify-between'>
              <div className='flex items-center gap-2'>
                <Heading variant='h3' className=' text-neutral-400'>
                  {t('links')}
                </Heading>
              </div>
              {!isAddLink ? (
                <Button variant='secondary' size='small' onClick={() => setIsAddLink(true)}>
                  {t('edit')}
                </Button>
              ) : (
                <Button variant='secondary' size='small' onClick={() => setIsAddLink(false)}>
                  {t('close')}
                </Button>
              )}
            </div>

            {links && !isAddLink && (
              <div className='flex flex-wrap items-center gap-3'>
                {JSON.parse(links).map((link: Link) => (
                  <Button
                    variant='secondary-outlined'
                    size='small'
                    onClick={() => {
                      window.open(link.url, '_blank');
                    }}
                    className='flex items-center gap-2'
                  >
                    <UrlIcon className='h-4 w-4' />
                    {link.title}
                  </Button>
                ))}
              </div>
            )}
            {links && isAddLink && (
              <div className='flex flex-wrap items-center gap-3'>
                {JSON.parse(links).map((link: Link) => (
                  <div className='flex items-center gap-2 border border-neutral-300 px-2 py-1 bg-neutral-200 rounded-md'>
                    <UrlIcon className='h-4 w-4' />
                    {link.title}
                    <button
                      onClick={() => deleteLink(link)}
                      className='h-5 w-5 flex items-center justify-center rounded-full text-functional-danger-400 neutral-button'
                    >
                      <CloseIcon className='h-4 w-4' />
                    </button>
                  </div>
                ))}
              </div>
            )}
            {isAddLink && (
              <div className='space-y-2  bg-neutral-200'>
                <div className='w-full gap-2 flex items-center'>
                  <div className='w-full'>
                    <input
                      type='text'
                      placeholder={t('title')}
                      className='border border-neutral-300 px-2 py-1 bg-neutral-200 rounded-md w-full'
                      value={newLink.title}
                      onChange={(e) => setNewLink({ ...newLink, title: e.target.value })}
                    />
                  </div>
                  <input
                    type='url'
                    placeholder={t('url')}
                    value={newLink.url}
                    className='border border-neutral-300 px-2 py-1 bg-neutral-200 rounded-md'
                    onChange={(e) => setNewLink({ ...newLink, url: e.target.value })}
                  />
                </div>
                <div className='flex items-center justify-end'>
                  <Button
                    variant='secondary'
                    size='small'
                    disabled={!newLink.title.trim() || !newLink.url.trim()} // Disable if title or url is empty
                    onClick={() => submitLink()}
                  >
                    {t('save')}
                  </Button>
                </div>
              </div>
            )}
            <div className='flex items-center text-neutral-400 gap-1'>
              <InfoIcon className='h-4 w-4 ' />
              <BodyText variant='sm'>{t('add-links-tip')}</BodyText>
            </div>
          </div>

          <div className='space-y-2 rounded-2xl bg-neutral-200 p-3'>
            <Heading variant='h3' className='text-neutral-400'>
              {t('expertise')}
            </Heading>

            <div className='flex flex-wrap items-center gap-4'>
              {expertise?.map(({ id, name }) => (
                <div
                  key={id}
                  className='flex items-center gap-2 rounded-md border border-neutral-300 px-2 py-1'
                >
                  <BodyText variant='base' className='font-medium capitalize text-neutral-500'>
                    {name}
                  </BodyText>

                  <button onClick={() => handleDeleteExpertise(id)}>
                    <CloseIcon className='h-[10px] w-[10px] text-neutral-400' />
                  </button>
                </div>
              ))}
            </div>

            <AddNewExpertise />
          </div>

          <div className='space-y-2 rounded-2xl bg-neutral-200 p-3'>
            <Heading variant='h3' className='text-neutral-400'>
              {t('interests')}
            </Heading>

            <div className='flex flex-wrap items-center gap-5'>
              {interests?.map(({ id, name }) => (
                <div
                  key={id}
                  className='flex items-center gap-2 rounded-md border border-neutral-300 px-2 py-1'
                >
                  <BodyText variant='base' className='font-medium capitalize text-neutral-500'>
                    {name}
                  </BodyText>

                  <button onClick={() => handleDeleteInterest(id)}>
                    <CloseIcon className='h-[10px] w-[10px] text-neutral-400' />
                  </button>
                </div>
              ))}
            </div>

            <AddNewInterests />
          </div>
        </div>
      ) : (
        <EditProfile setIsOpen={setIsEditProfileOpen} />
      )}
    </div>
  );
};

export default MyProfile;
