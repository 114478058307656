import CloseIcon from 'assets/icons/close.svg';
import MenuIcon from 'assets/icons/menu.svg';
import Logo from 'assets/images/logo.svg';
import Heading from 'components/typography/Heading';
import BodyText from 'components/typography/BodyText';
import { getSideNavLinks } from 'config/sidenavLinks';
import useIsLoggedIn from 'hooks/api/auth/useIsLoggedIn';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import { UserType } from 'types/apis/common';

const MenuWithTrigger = () => {
  const { t } = useTranslation();

  const [isOpen, setIsOpen] = useState(false);

  const { data } = useIsLoggedIn();

  const sidenavLinks = getSideNavLinks(t);

  const links =
    data?.userType === UserType.member
      ? sidenavLinks.filter(({ label }) => label !== 'Admin')
      : sidenavLinks;

  return (
    <div>
      {isOpen && (
        <nav className='fixed inset-0 z-10 flex h-full flex-col bg-neutral-100'>
          <div className='flex items-center justify-between px-4'>
            <section className='flex items-center gap-5 border-b border-neutral-200 py-5'>
              {!data?.primaryOrganization?.name ? (
                <div className='flex items-center gap-3 '>
                  <Logo className='h-[40px] w-[40px]' />
                  <a href='https://www.studycentral.eu/'>
                    <div className='hidden lg:block'>
                      <BodyText variant='base' className='text-neutral-600'>
                        STUDY<span className='font-bold'>CENTRAL</span>
                      </BodyText>
                    </div>
                  </a>
                </div>
              ) : (
                <Heading variant='h2' className='line-clamp-3 font-bold text-neutral-500'>
                  {data?.primaryOrganization?.name}
                </Heading>
              )}
            </section>

            <button onClick={() => setIsOpen(false)}>
              <CloseIcon className='h-4 w-4' />
            </button>
          </div>

          <section className='flex grow flex-col'>
            {links.map(({ id, to, label, Icon }) => (
              <NavLink key={id} to={to} onClick={() => setIsOpen(false)}>
                {({ isActive }) => (
                  <div className='flex items-center gap-3'>
                    <div className={`${isActive ? 'bg-neutral-100' : 'bg-secondary-500'} p-5`}>
                      <Icon
                        className={`h-5 w-5 ${
                          isActive ? 'text-secondary-500' : 'text-neutral-100'
                        }`}
                      />
                    </div>
                    <BodyText variant='base' className='text-neutral-600'>
                      {label}
                    </BodyText>
                  </div>
                )}
              </NavLink>
            ))}

            <div className='w-[72px] grow bg-secondary-500' />
          </section>
        </nav>
      )}

      <button onClick={() => setIsOpen(true)}>
        <MenuIcon className='h-5 w-5' />
      </button>
    </div>
  );
};

export default MenuWithTrigger;
