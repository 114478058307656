import { AxiosRequestConfig } from 'axios';
import { API_ROOT } from 'constants/config';
import { parseGET, parsePUT } from 'utils/rest';
import { SettingsResponseType } from 'types/apis/response';

export const getSettings = (options?: AxiosRequestConfig) => {
  return parseGET<SettingsResponseType>(`${API_ROOT}/settings`, {
    withCredentials: true,
    ...options,
  });
};

export const updateSetting = (
  body: { emailNotification: boolean, darkTheme: boolean },
  options?: AxiosRequestConfig,
) => {
  return parsePUT(`${API_ROOT}/settings`, body, { withCredentials: true, ...options });
};
