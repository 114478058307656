import CalendarIcon from 'assets/icons/calendar.svg';
import ClockIcon from 'assets/icons/clock.svg';
import DeleteIcon from 'assets/icons/delete.svg';
import DuplicateIcon from 'assets/icons/copy.svg';
import EditIcon from 'assets/icons/edit.svg';
import LocationIcon from 'assets/icons/location.svg';
import OnlineIcon from 'assets/icons/video-camera.svg';
import { ContextMenu } from 'components/common/ContextMenu';
import BodyText from 'components/typography/BodyText';
import dayjs from 'dayjs';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import useIsLoggedIn from 'hooks/api/auth/useIsLoggedIn';

interface IEvent {
  eventId: string;
  redirectTo: string;
  eventCover: string;
  eventTitle: string;
  canManage: boolean;
  createdBy?: string;
  isAllDay?: boolean;
  eventStartTime: string;
  eventLocation: null | string;
  eventType: 'online' | 'inperson';
  handleEditEvent?: (eventId: string) => void;
  handleDuplicateEvent?: (eventId: string) => void;
  handleDeleteEvent?: (eventId: string) => void;
}

const Event = ({
  eventId,
  eventType,
  canManage,
  redirectTo,
  eventCover,
  eventTitle,
  eventLocation,
  eventStartTime,
  isAllDay,
  createdBy,
  handleEditEvent,
  handleDuplicateEvent,
  handleDeleteEvent,
}: IEvent) => {
  const { t } = useTranslation();
  const { data: userInfo, isLoading: isUserInfoLoading } = useIsLoggedIn();
  const isUserEventCreator = !isUserInfoLoading && userInfo && userInfo.id === createdBy;
  const canUserManage = canManage || isUserEventCreator;

  return (
    <Link
      to={redirectTo}
      className='group block rounded-2xl border border-neutral-300 bg-neutral-200 p-3 hover:border-primary-200 hover:shadow-xl active:border-primary-200'
    >
      <div className='mb-3 overflow-hidden rounded-xl'>
        <img
          src={eventCover}
          alt={eventTitle}
          className=' h-[148px] w-full rounded-xl object-cover transition-all duration-500 group-hover:scale-110 '
        />
      </div>
      <BodyText variant='sm' className='mb-3 font-medium capitalize'>
        {eventTitle}
      </BodyText>

      <BodyText
        variant='xs'
        className={`mb-2 flex items-center gap-2 ${
          new Date(eventStartTime) < new Date() ? 'text-neutral-400' : 'text-functional-info-dark'
        }`}
      >
        <CalendarIcon className='h-4 w-4 flex-shrink-0' />
        {isAllDay
          ? dayjs(eventStartTime).format('dddd, DD MMM YYYY') + ' ' + t('all_day_event')
          : dayjs(eventStartTime).format('dddd, DD MMM YYYY, h:mm A (UTC Z)')}
      </BodyText>
      {new Date(eventStartTime) > new Date() && (
        <BodyText variant='xs' className='mb-2 flex items-center gap-2 text-neutral-500'>
          <ClockIcon className='h-4 w-4' />
          {t('starts_in')}: {dayjs(eventStartTime).fromNow()}
        </BodyText>
      )}

      <div className='flex items-center justify-between'>
        <div>
          {eventType === 'online' && (
            <BodyText variant='xs' className='flex items-center gap-2 text-neutral-500'>
              <OnlineIcon className='h-4 w-4 flex-shrink-0' />
              {t('online')}
            </BodyText>
          )}
          {eventType === 'inperson' && eventLocation && (
            <BodyText variant='xs' className='flex items-center gap-2 text-neutral-500'>
              <LocationIcon className='h-4 w-4 flex-shrink-0' />
              {eventLocation}
            </BodyText>
          )}
        </div>

        {canUserManage && (
          <ContextMenu
            menuItems={[
              {
                key: 'edit',
                label: t('edit'),
                icon: EditIcon,
                callback: () => handleEditEvent?.(eventId),
              },
              {
                key: 'duplicate',
                label: t('duplicate'),
                icon: DuplicateIcon,
                callback: () => handleDuplicateEvent?.(eventId),
              },
              {
                key: 'delete',
                label: t('delete'),
                icon: DeleteIcon,
                callback: () => handleDeleteEvent?.(eventId),
              },
            ]}
          />
        )}
      </div>
    </Link>
  );
};
export default Event;
