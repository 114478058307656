import { useQuery } from '@tanstack/react-query';
import { getCommunityResources } from 'utils/api/resource';

export const useCommunityResources = (communityId: string, limit = 10) => {
  return useQuery({
    queryKey: ['community-resources', communityId],
    queryFn: () => getCommunityResources(communityId, limit),
    select: (data) => data.data,
    enabled: !!communityId,
  });
};
