import CalendarIcon from 'assets/icons/calendar.svg';
import DeleteIcon from 'assets/icons/delete.svg';
import EditIcon from 'assets/icons/edit.svg';
import DuplicateIcon from 'assets/icons/copy.svg';
import Avatar from 'components/memberAndProfile/Avatar';
import { ContextMenu } from 'components/common/ContextMenu';
import Tag from 'components/tag/Tag';
import BodyText from 'components/typography/BodyText';
import dayjs from 'dayjs';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import useIsLoggedIn from 'hooks/api/auth/useIsLoggedIn';

interface ITask {
  taskId: string;
  taskTitle: string;
  canManage: boolean;
  createdBy?: string;
  redirectTo: string;
  taskDeadline: string;
  hasDeadline: boolean;
  taskSoftDeadline: boolean;
  isTaskSubmitted: boolean;
  displayAvator: string;
  displayName: string;
  createdAt?: string;
  handleUpdateTask?: (taskId: string) => void;
  handleDuplicateTask?: (taskId: string) => void;
  handleDeleteTask?: (taskId: string) => void;
}

const Task = ({
  taskId,
  taskTitle,
  canManage,
  createdBy,
  redirectTo,
  hasDeadline,
  taskDeadline,
  isTaskSubmitted,
  taskSoftDeadline,
  displayAvator,
  displayName,
  createdAt,
  handleUpdateTask,
  handleDuplicateTask,
  handleDeleteTask,
}: ITask) => {
  const { t } = useTranslation();
  const { data: userInfo, isLoading: isUserInfoLoading } = useIsLoggedIn();
  const isUserEventCreator = !isUserInfoLoading && userInfo && userInfo.id === createdBy;
  const canUserManage = canManage || isUserEventCreator;
  return (
    <Link
      to={redirectTo}
      className='block rounded-2xl border border-neutral-300 bg-neutral-100 p-4 hover:border-primary-500 hover:shadow-lg active:border-primary-200'
    >
      <div className='mb-2 flex items-center gap-3'>
        {!hasDeadline && (
          <Tag type='default' className='bg-functional-success-light text-functional-success-dark'>
            {t('open_ended_task')}
          </Tag>
        )}
        {dayjs(taskDeadline).diff(dayjs(new Date())) > 0 && hasDeadline && (
          <Tag type='default' className='bg-functional-success-light text-functional-success-dark'>
            {t('open')}
          </Tag>
        )}
        {dayjs(taskDeadline).diff(dayjs(new Date())) < 0 && hasDeadline && (
          <Tag type='default' className='bg-functional-danger-light text-functional-danger-dark'>
            {t('closed')}
          </Tag>
        )}
        {isTaskSubmitted && (
          <Tag type='default' className='bg-functional-success-light text-functional-success-dark'>
            {t('submitted')}
          </Tag>
        )}
      </div>

      <div className='mb-3 space-y-3'>
        <BodyText variant='sm' className='font-medium'>
          {taskTitle}
        </BodyText>
        {hasDeadline ? (
          <div className='flex items-center gap-1'>
            <CalendarIcon className='h-[14px] w-[14px] text-neutral-500' />
            <BodyText variant='sm'>
              <span className='text-neutral-500'>{t('deadline')}:</span>{' '}
              <span className='text-functional-danger-dark'>
                {dayjs(taskDeadline).format('dddd, D MMM YYYY, h:mm A')}
              </span>
            </BodyText>
          </div>
        ) : (
          <div></div>
        )}
      </div>

      <div className='flex items-center justify-between gap-[6px]'>
        {displayAvator && displayName && (
          <div className='flex items-center gap-[6px]'>
            <Avatar size={16} src={displayAvator} />
            <BodyText variant='sm' className='mr-auto text-neutral-500'>
              {displayName}
            </BodyText>
          </div>
        )}

        {canUserManage && (
          <ContextMenu
            menuItems={[
              {
                key: 'edit',
                label: t('edit'),
                icon: EditIcon,
                callback: () => handleUpdateTask?.(taskId),
              },
              {
                key: 'duplicate',
                label: t('duplicate'),
                icon: DuplicateIcon,
                callback: () => handleDuplicateTask?.(taskId),
              },
              {
                key: 'delete',
                label: t('delete'),
                icon: DeleteIcon,
                callback: () => handleDeleteTask?.(taskId),
              },
            ]}
          />
        )}
      </div>
    </Link>
  );
};

export default Task;
