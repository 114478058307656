import { AxiosRequestConfig } from 'axios';
import { API_ROOT } from 'constants/config';
import {
  ExpertiseResponseType,
  InterestResponseType,
  MyExpertiseResponseType,
  ProfileInfoResponseType,
} from 'types/apis/response';
import { parseDELETE, parseGET, parsePOST, parsePUT } from 'utils/rest';

export const getMyProfileInfo = (options?: AxiosRequestConfig) => {
  return parseGET<ProfileInfoResponseType>(`${API_ROOT}/profile`, {
    withCredentials: true,
    ...options,
  });
};

export const getMemberProfileInfo = (memberId: string, options?: AxiosRequestConfig) => {
  return parseGET<ProfileInfoResponseType>(`${API_ROOT}/profile/${memberId}`, {
    withCredentials: true,
    ...options,
  });
};

export const updateMyProfileInfo = (body: FormData, options?: AxiosRequestConfig) => {
  return parsePUT(`${API_ROOT}/profile`, body, { withCredentials: true, ...options });
};

// intestes apis
export const getInterests = (options?: AxiosRequestConfig) => {
  return parseGET<InterestResponseType>(`${API_ROOT}/interests`, {
    withCredentials: true,
    ...options,
  });
};

export const getMyInterests = (options?: AxiosRequestConfig) => {
  return parseGET<InterestResponseType>(`${API_ROOT}/interests/my-interests`, {
    withCredentials: true,
    ...options,
  });
};

export const addInterests = (body: { names: string[] }, options?: AxiosRequestConfig) => {
  return parsePOST(`${API_ROOT}/interests/my-interests`, body, {
    withCredentials: true,
    ...options,
  });
};

export const deleteMyInterest = (interestId: string, options?: AxiosRequestConfig) => {
  return parseDELETE(`${API_ROOT}/interests/my-interests/${interestId}`, {
    withCredentials: true,
    ...options,
  });
};

// expertise apis
export const getExpertises = (options?: AxiosRequestConfig) => {
  return parseGET<ExpertiseResponseType>(`${API_ROOT}/expertise`, {
    withCredentials: true,
    ...options,
  });
};

export const getMyExpertises = (options?: AxiosRequestConfig) => {
  return parseGET<MyExpertiseResponseType>(`${API_ROOT}/expertise/my-expertise`, {
    withCredentials: true,
    ...options,
  });
};

export const addExpertises = (body: { names: string[] }, options?: AxiosRequestConfig) => {
  return parsePOST(`${API_ROOT}/expertise/my-expertise`, body, {
    withCredentials: true,
    ...options,
  });
};

export const deleteMyExpertise = (expertiseId: string, options?: AxiosRequestConfig) => {
  return parseDELETE(`${API_ROOT}/expertise/my-expertise/${expertiseId}`, {
    withCredentials: true,
    ...options,
  });
};

export const addProfileLinks = (body: { links: string }, options?: AxiosRequestConfig) => {
  return parsePOST(`${API_ROOT}/profile/links`, body, {
    withCredentials: true,
    ...options,
  });
};
