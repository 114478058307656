import { AxiosProgressEvent } from 'axios';
import Loading from 'components/Loading';
import ConfirmationModal from 'components/modal/ConfirmationModal';
import SubmitTaskModal from 'components/modal/SubmitTaskModal';
import NavigateTo404 from 'components/NavigateTo404';
import { orgLogo, userAvatar } from 'constants/common';
import { EditorState } from 'draft-js';
import { useCommunityTask } from 'hooks/api/task/useCommunityTask';
import { useCommunityTaskComments } from 'hooks/api/task/useCommunityTaskComments';
import { useInvalidateQueries } from 'hooks/common/useInvalidatequeries';
import React, { useState } from 'react';
import { UseFormReset } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { taskAddToCalendar } from 'utils/api/calendar';
import {
  addCommunityTaskComment,
  deleteCommunityTaskComment,
  dislikeCommunityTask,
  likeCommunityTask,
  submitCommunityTask,
} from 'utils/api/task';
import { generateIcsFile } from 'utils/helper';
import { notifySuccess, notifyTranslatedError } from 'utils/notify';
import { generateOriginLink, generateTaskSubmissionLink, generateUserProfileLink } from 'utils/url';
import TaskDetails from '../task/TaskDetails';

const CommunityTaskDetailsPage = () => {
  const { t } = useTranslation();
  const { communityId, taskId } = useParams();

  const [selectedCommentId, setSelectedCommentId] = useState<null | string>(null);
  const [openDeleteConfirmation, setOpenDeleteConfirmation] = useState(false);
  const [openSubmitTaskModal, setOpenSubmitTaskModal] = useState(false);

  const {
    data: task,
    refetch: refetchSingleTask,
    isLoading: isCommunityTaskLoading,
  } = useCommunityTask(communityId || '', taskId || '');
  const { comments, hasNextComments, fetchNextComments } = useCommunityTaskComments(
    communityId || '',
    taskId || '',
  );
  const {
    invalidateSingleCommunityTask,
    invalidateCommunityTaskComments,
    invalidateCommunityTaskSubmissions,
  } = useInvalidateQueries();

  if (isCommunityTaskLoading) return <Loading />;
  if (!task) return <NavigateTo404 />;

  const { liked, totalLikes, totalComments, assigneeInfo } = task;

  const {
    id: taskUid,
    title,
    about,
    assignee,
    community: origin,
    creator,
    createdAt,
    taskContactUser,
    hasDeadline,
    deadline,
    softDeadline,
    organization,
    submitted,
    viewCount,
  } = task.task;

  const { id: originId, name: originName } = origin;
  const { id: creatorId, firstName, lastName, profilePicture, userType } = creator;
  const { name: organizationName, logo } = organization;
  let taskContactUserId, contactUserFirstName, contactUserLastName, contactUserProfilePicture;
  if (taskContactUser) {
    ({
      id: taskContactUserId,
      firstName: contactUserFirstName,
      lastName: contactUserLastName,
      profilePicture: contactUserProfilePicture,
    } = taskContactUser);
  }
  // task handlers
  const handleLikeTask = () => {
    if (liked) {
      dislikeCommunityTask(originId, taskUid)
        .then(() => {
          refetchSingleTask();
        })
        .catch((error) => {
          notifyTranslatedError(t, error.response.data?.errorCode);
        });
    } else {
      likeCommunityTask(originId, taskUid)
        .then(() => {
          refetchSingleTask();
        })
        .catch((error) => {
          notifyTranslatedError(t, error.response.data?.errorCode);
        });
    }
  };

  const handleAddToCalendar = () => {
    taskAddToCalendar(originId, taskUid)
      .then((data) => {
        generateIcsFile(data, 'event');
      })
      .catch((error) => {
        notifyTranslatedError(t, error.response.data?.errorCode);
      });
  };

  const handleSubmitTask = (
    data: FormData | any,
    onUploadProgress: (progressEvent: AxiosProgressEvent) => void,
    setIsUploading: React.Dispatch<React.SetStateAction<boolean>>,
  ) => {
    submitCommunityTask(originId, taskUid, data, { onUploadProgress })
      .then(() => {
        setIsUploading(false);
        setOpenSubmitTaskModal(false);
        notifySuccess(t('success.api.submitted_task'));
        invalidateSingleCommunityTask(originId, taskUid);
        invalidateCommunityTaskSubmissions(originId, taskUid);
      })
      .catch((error) => {
        setIsUploading(false);
        notifyTranslatedError(t, error.response.data?.errorCode);
        notifyTranslatedError(t, error.response.data?.message);
      });
  };

  // task comment handlers
  const handlePublishComment = (
    data: { comment: string },
    reset: UseFormReset<{ comment: string }>,
    setEditorState: React.Dispatch<React.SetStateAction<EditorState>>,
  ) => {
    addCommunityTaskComment(originId, taskUid, data)
      .then(() => {
        reset({ comment: '' });
        setEditorState(EditorState.createEmpty());
        invalidateSingleCommunityTask(originId, taskUid);
        invalidateCommunityTaskComments(originId, taskUid);
      })
      .catch((error) => {
        notifyTranslatedError(t, error.response.data?.errorCode);
      });
  };

  const handleOpenDeleteConfirmation = (commentId: string) => {
    setSelectedCommentId(commentId);
    setOpenDeleteConfirmation(true);
  };

  const handleCloseDeleteConfirmation = () => {
    setSelectedCommentId('');
    setOpenDeleteConfirmation(false);
  };

  const handleDeleteComment = () => {
    if (!selectedCommentId) return;
    deleteCommunityTaskComment(originId, selectedCommentId)
      .then(() => {
        notifySuccess(t('success.api.comment_deleted'));
        handleCloseDeleteConfirmation();
        invalidateSingleCommunityTask(originId, taskUid);
        invalidateCommunityTaskComments(originId, taskUid);
      })
      .catch((error) => {
        notifyTranslatedError(t, error.response.data?.errorCode);
      });
  };

  return (
    <div>
      <TaskDetails
        taskCreatorProfileLink={generateUserProfileLink(creatorId)}
        taskCreatorAvatar={profilePicture || userAvatar}
        taskCreatorName={`${firstName} ${lastName}`}
        taskCreatorId={creatorId}
        taskCreatedAt={createdAt}
        taskCreatorUserType={userType}
        contactUserId={taskContactUserId}
        contactUserAvatar={contactUserProfilePicture || userAvatar}
        contactUserName={`${contactUserFirstName} ${contactUserLastName}`}
        contactUserProfileLink={generateUserProfileLink(taskContactUserId || '')}
        taskAbout={about}
        taskAssignee={assigneeInfo}
        taskTitle={title}
        taskHasDeadline={hasDeadline}
        taskDeadline={deadline}
        taskSoftDeadline={softDeadline}
        taskLikeCount={totalLikes}
        taskCommentCount={totalComments}
        taskOriginId={originId}
        taskOriginName={originName}
        taskViewCount={viewCount}
        isTaskSubmitted={!!submitted}
        handleLikeTask={handleLikeTask}
        handleAddToCalendar={handleAddToCalendar}
        isTaskLiked={liked}
        taskOriginType='community'
        taskSubmissionsLink={generateTaskSubmissionLink(
          'community',
          originId,
          taskUid,
          title,
          deadline,
        )}
        taskOriginLink={generateOriginLink(originId, 'community')}
        handleDeleteComment={handleOpenDeleteConfirmation}
        handlePublishComment={handlePublishComment}
        comments={comments}
        hasNextComments={hasNextComments}
        fetchNextComments={fetchNextComments}
        setOpenSubmitTaskModal={setOpenSubmitTaskModal}
      />

      {openDeleteConfirmation && (
        <ConfirmationModal
          isOpen={openDeleteConfirmation}
          label={t('delete-comment')}
          title={t('delete-comment_confirmation_title')}
          onClose={handleCloseDeleteConfirmation}
          onConfirm={handleDeleteComment}
        />
      )}

      {openSubmitTaskModal && (
        <SubmitTaskModal
          isOpen={openSubmitTaskModal}
          handleSubmitTask={handleSubmitTask}
          onClose={() => setOpenSubmitTaskModal(false)}
        />
      )}
    </div>
  );
};

export default CommunityTaskDetailsPage;
