import { useQuery } from '@tanstack/react-query';
import { getCommunityInviteLinks } from 'utils/api/community';

export const useCommunityInviteLinks = (originId: string, originType: string, enabled = true) => {
  return useQuery({
    queryKey: ['invite-links', originId],
    queryFn: () => getCommunityInviteLinks(originId),
    select: (data) => data.data.invitelinks,
    enabled,
  });
};
