export const editorStyle = `
:root {
    --inlineSelectionColor: var(--color-primary-500);
    --selectionColor: var(--color-primary-500);
}
.ce-block__content,
.ce-toolbar__content {
  max-width: unset;
}
.image-tool__caption {
  display: none;
}
.inline-image__caption {
  display: none;
}
.cdx-attaches {
  --color-line: var(--color-neutral-300);
  --color-bg: var(--color-neutral-200);
  --color-bg-secondary: var(--color-primary-500);
  --color-bg-secondary--hover: var(--color-primary-400);
  --color-text-secondary: var(--color-neutral-400);
}

.ce-inline-toolbar {
  background-color: var(--color-secondary-100);
}
.ce-inline-tool {
  color: var(--color-neutral-500);
  background-color: var(--color-secondary-100);
}
.ce-inline-tool:hover {
  background-color: var(--color-secondary-200);
}
.ce-inline-tool-input {
  background-color: var(--color-neutral-200);
}
.ce-conversion-tool {
  background-color: var(--color-neutral-200);
}
.ce-conversion-tool:hover {
  background-color: var(--color-neutral-300);
}
.ce-conversion-toolbar {
  background-color: var(--color-neutral-200);
}
.ce-toolbar__plus,
.ce-toolbar__settings-btn,
.ce-popover__search {
  background-color: var(--color-neutral-200);
  color: var(--color-neutral-600);
}
.ce-toolbar__plus:hover,
.ce-toolbar__settings-btn:hover,
.cdx-loader,
.cdx-button {
  color: var(--color-neutral-600);
  background-color: var(--color-neutral-100);
}
.cdx-button:hover {
  background-color: var(--color-neutral-100);
}
.ce-popover,
.ce-popover-item,
.ce-popover-item__icon,
.ce-popover-items,
.ce-popover-item:hover {
  --color-background-item-hover: var(--color-secondary-400);
  background-color: var(--color-secondary-100);
  color: var(--color-neutral-600);
  border: var(--color-neutral-300);
}
.selection-list-option {
  background-color: var(--color-neutral-200);
}
.selection-list-option-active {
  color: var(--color-neutral-200);
}
.selection-list-option:hover {
  color: var(--color-neutral-200);
}
`;
