import MentionEditor from 'components/form/MentionEditor';
import { orgLogo, userAvatar } from 'constants/common';
import { EditorState } from 'draft-js';
import { useMentionSearch } from 'hooks/api/search/useMentionSearch';
import React, { useMemo, useState } from 'react';
import {
  generateCommunityLink,
  generateProgramLink,
  generateResourceMaterialPageLink,
  generateUserProfileLink,
  generateEventLink,
  generateTaskLink,
} from 'utils/url';

interface IMentionTextarea {
  originId: string;
  editorState: EditorState;
  originType: 'community' | 'program' | 'all' | 'conversation';
  setEditorState: React.Dispatch<React.SetStateAction<EditorState>>;
  isTopPosition?: boolean;
  conversationId?: string;
}

const MentionTextarea = ({
  originId,
  originType,
  editorState,
  setEditorState,
  isTopPosition,
  conversationId,
}: IMentionTextarea) => {
  const [searchString, setSearchString] = useState('');
  const [openMentionBox, setOpenMentionBox] = useState(false);
  const [selectedTypeIndex, setSelectedTypeIndex] = useState(0);

  // Dynamically setting the tabs based on originType
  const mentionTabs = useMemo(() => {
    if (originType === 'all') {
      return [
        { tabIndex: 0, label: 'users' },
        { tabIndex: 1, label: 'spaces' },
      ];
    } else {
      return [
        { tabIndex: 0, label: 'users' },
        { tabIndex: 1, label: 'resources' },
        { tabIndex: 2, label: 'events' },
        { tabIndex: 3, label: 'tasks' },
      ];
    }
  }, [originType]);

  // Adjust the selectedType logic based on the updated tab structure
  const selectedType = useMemo(() => {
    if (originType === 'all') {
      switch (selectedTypeIndex) {
        case 0:
          return 'user';
        case 1:
          return 'program';
        default:
          return 'user';
      }
    } else {
      switch (selectedTypeIndex) {
        case 0:
          return 'user';
        case 1:
          return 'resource';
        case 2:
          return 'event';
        case 3:
          return 'task';
        default:
          return 'user';
      }
    }
  }, [selectedTypeIndex, originType]);

  const { users, resources, communities, programs, events, tasks } = useMentionSearch(
    originType,
    originId,
    selectedType,
    searchString,
    openMentionBox,
    conversationId,
  );

  const mentions = useMemo(() => {
    if (users?.length > 0) {
      return users.map((user) => ({
        id: user.id,
        type: 'user',
        logo: user.profilePicture || userAvatar,
        name: `${user.firstName} ${user.lastName}`,
        subtitle: user.headline || null,
        value: generateUserProfileLink(user.id),
      }));
    }
    if ((originType === 'community' || originType === 'program') && resources?.length > 0) {
      return resources.map((resource) => ({
        id: resource.id,
        type: 'resource',
        logo: null,
        name: resource.page ? resource.page?.title || resource.title : resource.title,
        subtitle: resource.description || null,
        value: generateResourceMaterialPageLink(originType, originId),
      }));
    }

    if (communities?.length > 0) {
      return communities.map((community) => ({
        id: community.id,
        type: 'community',
        logo: community.organization.logo || orgLogo,
        name: community.name,
        subtitle: community.organization.name,
        value: generateCommunityLink(community.id),
      }));
    }
    if (programs?.length > 0) {
      return programs.map((program) => ({
        id: program.id,
        type: 'space',
        logo: program.organization.logo || orgLogo,
        name: program.name,
        subtitle: program.organization.name,
        value: generateProgramLink(program.id),
      }));
    }
    if ((originType === 'community' || originType === 'program') && events?.length > 0) {
      return events.map((event) => ({
        id: event.id,
        type: 'event',
        logo: event.cover || orgLogo,
        name: event.title,
        value: generateEventLink(
          originType === 'community' ? 'community' : 'program',
          originId,
          event.id,
        ),
      }));
    }
    if ((originType === 'community' || originType === 'program') && tasks?.length > 0) {
      return tasks.map((task) => ({
        id: task.id,
        type: 'task',
        logo: task.cover || orgLogo,
        name: task.title,
        value: generateTaskLink(
          originType === 'community' ? 'community' : 'program',
          originId,
          task.id,
        ),
      }));
    }
    return [];
  }, [users, resources, communities, programs, searchString, openMentionBox]);

  return (
    <MentionEditor
      mentions={mentions}
      open={openMentionBox}
      mentionTabs={mentionTabs}
      editorState={editorState}
      searchString={searchString}
      setOpen={setOpenMentionBox}
      setEditorState={setEditorState}
      setSearchString={setSearchString}
      selectedTypeIndex={selectedTypeIndex}
      setSelectedTypeIndex={setSelectedTypeIndex}
      isTopPosition={isTopPosition}
    />
  );
};

export default MentionTextarea;
