import {
  communityTabItems,
  programTabItems,
  guestTabItems,
  adminTabItems,
  resourceTabItems,
  membersAdminTabItems,
} from 'config/tabs';
import CommunityMembersPage from 'pages/admin/CommunityManagersPage';
import DashboardPage from 'pages/admin/DashboardPage';
import ProgramManagersPage from 'pages/admin/ProgramManagersPage';
import CalenderPage from 'pages/CalenderPage';
import ResourcesHomePage from 'pages/ResourcesHomePage';
import MembersPage from 'pages/MembersPage';
import SinglePagePage from 'pages/segment/pages/SinglePagePage';
import CommunitiesPage from 'pages/CommunitiesPage';
import CommunityPage from 'pages/CommunityPage';
import LoginPage from 'pages/LoginPage';
import MessagesPage from 'pages/MessagesPage';
import MobileMessagePage from 'components/message/MobileMessagePage';
import MobileNotificationPage from 'components/notification/MobileNotificationPage';
import NewPasswordPage from 'pages/NewPasswordPage';
import NotFoundPage from 'pages/NotFoundPage';
import ProfilePage from 'pages/ProfilePage';
import ProgramPage from 'pages/ProgramPage';
import ProgramsPage from 'pages/ProgramsPage';
import ResetPasswordPage from 'pages/ResetPasswordPage';
import ResetSuccessPage from 'pages/ResetSuccessPage';
import ResendVerificationPage from 'pages/ResendVerificationPage';
import CommunityEventDetailsPage from 'pages/segment/community/CommunityEventDetailsPage';
import CommunityTaskDetailsPage from 'pages/segment/community/CommunityTaskDetailsPage';
import CommunityTaskSubmissionsPage from 'pages/segment/community/CommunityTaskSubmissionsPage';
import CreateCommunityEventPage from 'pages/segment/community/CreateCommunityEventPage';
import EditCommunityEventPage from 'pages/segment/community/EditCommunityEventPage';
import DuplicateCommunityEventPage from 'pages/segment/community/DuplicateCommunityEventPage';
import CreateProgramEventPage from 'pages/segment/programs/CreateProgramEventPage';
import EditProgramEventPage from 'pages/segment/programs/EditProgramEventPage';
import DuplicateProgramEventPage from 'pages/segment/programs/DuplicateProgramEventPage';
import ProgramEventDetailsPage from 'pages/segment/programs/ProgramEventDetailsPage';
import ProgramTaskDetailsPage from 'pages/segment/programs/ProgramTaskDetailsPage';
import ProgramTaskSubmissionsPage from 'pages/segment/programs/ProgramTaskSubmissionsPage';
import SettingsPage from 'pages/SettingsPage';
import SignupPage from 'pages/SignupPage';
import InviteLandingPage from 'pages/InviteLandingPage';
import SinglePostPage from 'pages/SinglePostPage';
import SingleProgramPostPage from 'pages/SingleProgramPostPage';
import TestPage from 'pages/TestPage';
import VerifyEmailPage from 'pages/VerifyEmailPage';
import VerifyPage from 'pages/VerifyPage';
import React from 'react';
import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import PrivateRoutes from 'utils/PrivateRoutes';
import RedirectIfAuthed from 'utils/RedirectIfAuthed';
import GuestLandingPage from 'pages/guest/GuestLandingPage';
import GuestDiscoverPage from 'pages/guest/GuestDiscoverPage';
import GuestPageLandingPage from 'pages/guest/GuestPageLandingPage';
import AdminListTable from 'pages/admin/AdminListTable';
import MyResources from 'pages/segment/resources/MyResources';
import ActiveMembers from 'pages/segment/members/ActiveMembers';

export const AppRoutes = () => (
  <BrowserRouter>
    <Routes>
      <Route path='/' element={<Navigate to='/programs' />} />
      <Route path='/test' element={<TestPage />} />
      <Route path='/invite' element={<InviteLandingPage />} />
      <Route path='/guest'>
        <Route path=':originType/:originId' element={<GuestLandingPage />}>
          {guestTabItems.map(({ element, path, index }) => (
            <Route index={index} key={`${path}`} element={element} path={path} />
          ))}
        </Route>
        <Route path='pages/:pageId' element={<GuestPageLandingPage />} />
      </Route>
      <Route path='/discover'>
        <Route path=':originType/:originId' element={<GuestDiscoverPage />} />
      </Route>
      <Route element={<RedirectIfAuthed />}>
        <Route path='/login' element={<LoginPage />} />
        <Route path='/signup' element={<SignupPage />} />
        <Route path='/reset_password' element={<ResetPasswordPage />} />
        <Route path='/verify-email' element={<VerifyEmailPage />} />
        <Route path='/reset-password' element={<NewPasswordPage />} />
        <Route path='/verify' element={<VerifyPage />} />
        <Route path='/reset-success' element={<ResetSuccessPage />} />
        <Route path='/resend-verification-email' element={<ResendVerificationPage />} />
      </Route>

      <Route element={<PrivateRoutes allowedRoles={['member', 'organization_admin']} />}>
        <Route path='/calendar' element={<CalenderPage />} />
        <Route path='/resources' element={<ResourcesHomePage />}>
          <Route index element={<MyResources />} />
          {resourceTabItems.map(({ element, path, index }) => (
            <Route key={`resources-${path}`} path={path} element={element} index={index} />
          ))}
        </Route>
        <Route path='/members' element={<MembersPage />}>
          <Route index element={<ActiveMembers />} />
          {membersAdminTabItems.map(({ element, path, index }) => (
            <Route key={`members-${path}`} path={path} element={element} index={index} />
          ))}
        </Route>
        <Route path='/pages/:pageId' element={<SinglePagePage />} />
        <Route path='/programs'>
          <Route index element={<ProgramsPage />} />
          <Route path=':programId' element={<ProgramPage />}>
            {programTabItems.map(({ element, path, index, children }) => (
              <Route key={`program-${path}`} path={path}>
                <Route index element={element} />
                {children &&
                  children.map(({ path: childPath, element: childElement }) => (
                    <Route key={`program-${childPath}`} path={childPath} element={childElement} />
                  ))}
              </Route>
            ))}
          </Route>
          <Route path=':programId/events/:eventId' element={<ProgramEventDetailsPage />} />
          <Route path=':programId/events/create' element={<CreateProgramEventPage />} />
          <Route path=':programId/events/:eventId/edit' element={<EditProgramEventPage />} />
          <Route
            path=':programId/events/:eventId/duplicate'
            element={<DuplicateProgramEventPage />}
          />
          <Route path=':programId/tasks/:taskId'>
            <Route index element={<ProgramTaskDetailsPage />} />
            <Route path='submissions' element={<ProgramTaskSubmissionsPage />} />
          </Route>
          <Route path=':programId/feed/posts/:postId' element={<SingleProgramPostPage />} />
        </Route>
        <Route path='/communities'>
          <Route index element={<CommunitiesPage />} />
          <Route path=':communityId' element={<CommunityPage />}>
            {communityTabItems.map(({ element, path, index, children }) => (
              <Route key={`community-${path}`} path={path}>
                <Route index element={element} />
                {children &&
                  children.map(({ path: childPath, element: childElement }) => (
                    <Route key={`community-${childPath}`} path={childPath} element={childElement} />
                  ))}
              </Route>
            ))}
          </Route>
          <Route path=':communityId/events/:eventId' element={<CommunityEventDetailsPage />} />
          <Route path=':communityId/events/create' element={<CreateCommunityEventPage />} />
          <Route path=':communityId/events/:eventId/edit' element={<EditCommunityEventPage />} />
          <Route
            path=':communityId/events/:eventId/duplicate'
            element={<DuplicateCommunityEventPage />}
          />
          <Route path=':communityId/tasks/:taskId'>
            <Route index element={<CommunityTaskDetailsPage />} />
            <Route path='submissions' element={<CommunityTaskSubmissionsPage />} />
          </Route>
          <Route path=':communityId/feed/posts/:postId' element={<SinglePostPage />} />
        </Route>
        <Route path='/mobilemessages' element={<MobileMessagePage />} />
        <Route path='/mobilenotifications' element={<MobileNotificationPage />} />
        <Route path='/messages' element={<MessagesPage />} />
        <Route path='/messages/:conversationId' element={<MessagesPage />} />
        <Route path='/profile'>
          <Route index element={<ProfilePage />} />
          <Route path=':memberId' element={<ProfilePage />} />
        </Route>
        <Route path='/settings' element={<SettingsPage />} />
      </Route>

      <Route element={<PrivateRoutes allowedRoles={['organization_admin']} />}>
        <Route path='/admin-dashboard' element={<DashboardPage />}>
          <Route index element={<AdminListTable />} />
          {adminTabItems.map(({ element, path, index }) => (
            <Route key={`admin-${path}`} path={path} element={element} index={index} />
          ))}
          <Route path='communities/:id/members' element={<CommunityMembersPage />} />
          <Route path='programs/:programId/managers' element={<ProgramManagersPage />} />
        </Route>
      </Route>

      <Route path='*' element={<NotFoundPage />} />
    </Routes>
  </BrowserRouter>
);
