import CloseIcon from 'assets/icons/close.svg';
import Avatar from 'components/memberAndProfile/Avatar';
import BodyText from 'components/typography/BodyText';
import { userAvatar } from 'constants/common';
import { useNotifications } from 'hooks/api/notificaiton/useNotifications';
import React, { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { deleteNotification } from 'utils/api/notification';
import { timeSince } from 'utils/time';
import ResourceViewerDrawer from 'components/drawer/ResourceViewerDrawer';

const NotificationComponent = () => {
  const { t } = useTranslation();
  const [fileUrl, setFileUrl] = useState('');
  const [showResourceViewer, setShowResourceViewer] = useState(false);
  const [originId, setOriginId] = useState('');
  const [originType, setOriginType] = useState('');
  const [uploaderId, setUploaderId] = useState('');

  const { data: { notifications = [], lastRead = null } = {}, refetch: refetchNotifications } =
    useNotifications();

  const handleRemoveNotification = (notificationId: string) => {
    deleteNotification(notificationId).then(() => {
      refetchNotifications();
    });
  };

  return (
    <>
      <div className='h-full w-full space-y-2 p-4'>
        {notifications?.map(({ id, type, link, createdAt, sender, meta }) => {
          const isNew = new Date(createdAt) > (lastRead ? new Date(lastRead) : new Date());
          return (
            <div key={id} className='relative '>
              {type === 'NOTIFICATION_MENTION_EMBEDCONVERSATION' &&
              JSON.parse(meta).name === 'resource' ? (
                <button
                  onClick={() => {
                    let originIdTest;
                    let originTypeTest;
                    let uploaderTest;
                    const url = new URL(link);
                    const params = new URLSearchParams(url.search);
                    originIdTest = params.get('originId' || '');
                    originTypeTest = params.get('originType' || '');
                    uploaderTest = params.get('uploaderId' || '');
                    setFileUrl(link);
                    setOriginId(originIdTest || '');
                    setOriginType(originTypeTest || '');
                    setUploaderId(uploaderTest || '');
                    setShowResourceViewer(true);
                  }}
                  className={`flex w-full items-center gap-3 rounded-xl p-2 pr-7 text-left shadow shadow-neutral-300
                                            ${
                                              isNew
                                                ? 'bg-primary-200 font-semibold text-secondary-600'
                                                : 'bg-neutral-200'
                                            } 
                                            hover:bg-neutral-300 active:bg-neutral-400`}
                >
                  <Avatar size={40} alt='' src={sender.profilePicture || userAvatar} />
                  <div>
                    <BodyText variant='sm'>
                      <Trans
                        i18nKey={type}
                        values={{
                          source: `${sender.firstName} ${sender.lastName}`,
                          name: meta ? JSON.parse(meta).name : '',
                        }}
                      >
                        <strong>User</strong> has created a new Event.
                      </Trans>
                    </BodyText>
                    <BodyText
                      variant='xs'
                      className={`whitespace-nowrap ${isNew ? 'font-bold' : ''} text-neutral-400`}
                    >
                      {timeSince(new Date(createdAt))}
                    </BodyText>
                  </div>
                </button>
              ) : (
                <a
                  href={link}
                  className={`flex items-center gap-4 rounded-xl p-2 pr-7 text-left shadow shadow-neutral-300
                                ${
                                  isNew
                                    ? 'bg-primary-200 font-semibold text-secondary-600'
                                    : 'bg-neutral-200'
                                } 
                                hover:bg-neutral-300 active:bg-neutral-400`}
                >
                  <Avatar size={40} alt='' src={sender.profilePicture || userAvatar} />
                  <div>
                    <BodyText variant='sm'>
                      <Trans
                        i18nKey={type}
                        values={{
                          source: `${sender.firstName} ${sender.lastName}`,
                          name: meta ? JSON.parse(meta).name : '',
                        }}
                      >
                        <strong>User</strong> has created a new Event.
                      </Trans>
                    </BodyText>
                    <BodyText
                      variant='sm'
                      className={`whitespace-nowrap ${isNew ? 'font-bold' : ''} text-neutral-400`}
                    >
                      {timeSince(new Date(createdAt))}
                    </BodyText>
                  </div>
                </a>
              )}
              <button
                className='absolute right-4 top-2/4 flex h-6 w-6 -translate-y-2/4 items-center justify-center rounded-full text-functional-danger-dark hover:bg-neutral-300 active:bg-neutral-400'
                onClick={() => handleRemoveNotification(id)}
              >
                <CloseIcon className='h-3 w-3' />
              </button>
            </div>
          );
        })}
      </div>

      <ResourceViewerDrawer
        isOpen={showResourceViewer}
        setIsOpen={setShowResourceViewer}
        fileUrl={originType !== 'page' ? fileUrl : ''}
        uploaderId={uploaderId}
        originId={originId}
        originType={originType}
      />
    </>
  );
};

export default NotificationComponent;
