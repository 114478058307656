import Button from 'components/button/Button';
import ResetPassCard from 'components/card/ResetPassCard';
import BodyText from 'components/typography/BodyText';
import Heading from 'components/typography/Heading';
import React, { useEffect, useRef, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { PinFormType } from 'types/components/form';
import { verifyEmail, verifyResetCode } from 'utils/api/auth';
import { notifySuccess, notifyTranslatedError } from 'utils/notify';

let currentOTPIndex = 0;

function VerifyEmailPage() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { state } = useLocation();
  const inputRef = useRef<HTMLInputElement>(null);

  const [otp, setOtp] = useState(new Array(4).fill(''));
  const [activeOTPIndex, setActiveOTPIndex] = useState(0);

  const {
    register,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm<PinFormType>();

  const onSubmit: SubmitHandler<PinFormType> = (data) => {
    const { type, email } = state;
    if (!email || !type) return;

    const code = data.pin;

    if (type === 'registration') {
      verifyEmail({ email, code })
        .then(() => {
          notifySuccess(t('success.api.email_varified'));
          navigate('/login');
        })
        .catch((error) => {
          notifyTranslatedError(t, error.response.data?.errorCode);
        });
    } else if (type === 'forgot_password') {
      verifyResetCode({ email, code })
        .then(() => {
          navigate('/reset-password', { state: { type, email, code } });
        })
        .catch((error) => {
          notifyTranslatedError(t, error.response.data?.errorCode);
        });
    }
  };

  const handleChange = ({ target }: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = target;
    const newOTP: string[] = [...otp];
    newOTP[currentOTPIndex] = value.substring(value.length - 1);

    if (!value) setActiveOTPIndex(currentOTPIndex - 1);
    else setActiveOTPIndex(currentOTPIndex + 1);
    setOtp(newOTP);
    setValue('pin', newOTP.join(''));
  };

  const handlePresBack = ({ key }: React.KeyboardEvent<HTMLInputElement>, index: number) => {
    currentOTPIndex = index;
    if (key === 'Backspace') setActiveOTPIndex(index - 1);
  };

  useEffect(() => {
    inputRef.current?.focus();
  }, [activeOTPIndex]);

  return (
    <main className='flex h-screen items-center justify-center px-4 md:px-0'>
      <ResetPassCard>
        <Heading variant='h2' className='mb-3 text-center font-bold'>
          {t('email_varification')}
        </Heading>
        <BodyText variant='base' className='mb-6 text-center text-neutral-400'>
          {t('email_varification_subtitle')}
        </BodyText>

        <form onSubmit={handleSubmit(onSubmit)} className='mb-6 space-y-6'>
          <input
            type='number'
            className='hidden'
            {...register('pin', { required: true, minLength: 4 })}
          />
          <div className='flex gap-5'>
            {otp.map((_, index) => (
              <input
                type='number'
                maxLength={1}
                className='w-full rounded-2xl border border-neutral-300 bg-neutral-200 md:py-4 md:px-5 py-1 px-2 text-center text-[22px] text-secondary-600 outline-none'
                value={otp[index]}
                onChange={(e) => handleChange(e)}
                onKeyDown={(e) => handlePresBack(e, index)}
                ref={index === activeOTPIndex ? inputRef : null}
              />
            ))}
          </div>

          {errors.pin && (
            <BodyText variant='sm' className='text-center text-functional-danger-400'>
              {t('pin_validation_error')}
            </BodyText>
          )}

          <Button size='default' variant='secondary' className='w-full'>
            {t('submit')}
          </Button>
        </form>

        <BodyText variant='base' className='text-center font-medium text-functional-info-dark'>
          <Link to='/login'>{t('back_to_signin')}</Link>
        </BodyText>
      </ResetPassCard>
    </main>
  );
}

export default VerifyEmailPage;
