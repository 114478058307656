import { useQuery } from '@tanstack/react-query';
import { getOtherPublicPrograms } from 'utils/api/program';

export const useOtherPrograms = (hashtag?: string) => {
  const {
    data: response,
    refetch,
    isLoading,
    isFetching,
  } = useQuery({
    queryKey: ['other-programs', hashtag],
    queryFn: () => getOtherPublicPrograms(hashtag),
  });

  // Accessing programs from the response correctly
  const otherPrograms = response?.data.programs ?? [];

  return {
    otherPrograms,
    refetchPrograms: refetch,
    isProgramsLoading: isLoading,
    isProgramsFetching: isFetching,
  };
};
