import organizationLogo from '../assets/images/default/organization_logo.png';
import defaultUserAvatar from '../assets/images/default/user_avatar.png';
import defaultCommonCover from '../assets/images/default/cover.jpg';
import defaultEventCover from '../assets/images/default/event_cover.jpg';
import defaultProfileBanner from '../assets/images/default/profile_banner.jpeg';

export const orgLogo = organizationLogo;
export const userAvatar = defaultUserAvatar;
export const commonCover = defaultCommonCover;
export const eventImage = defaultEventCover;
export const profileBanner = defaultProfileBanner;

export const COMMON_FILE_SIZE_LIMIT = 100 * 1024 * 1024; // 100MB
export const RESOURCE_FILE_SIZE_LIMIT = 500 * 1024 * 1024; // 100MB
