import Layout from 'components/layout/Layout';
import React from 'react';
import { useTranslation } from 'react-i18next';
import MyPrograms from './segment/programs/MyPrograms';

const ProgramsPage = () => {
  const { t } = useTranslation();

  return (
    <Layout>
      <div className='overflow-y-auto pb-7 md:py-4 md:px-5 py-1 px-2 md:h-full'>
        <MyPrograms />
      </div>
    </Layout>
  );
};

export default ProgramsPage;
