import React from 'react';

interface IAvatar {
  src: string;
  size: number;
  alt?: string;
  className?: string;
}

function Avatar({ src, alt = '', size, className = '' }: IAvatar) {
  return (
    <img
      src={src}
      alt={alt}
      className={`rounded-full object-cover ${className}`}
      style={{ width: size, height: size }}
    />
  );
}

export default Avatar;
