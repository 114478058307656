import CopyIcon from 'assets/icons/copy.svg';
import DeleteIcon from 'assets/icons/delete.svg';
import EditIcon from 'assets/icons/edit.svg';
import { ContextMenu } from 'components/common/ContextMenu';
import ConfirmationModal from 'components/modal/ConfirmationModal';
import UpdatePostModal from 'components/modal/UpdatePostModal';
import { useInvalidateQueries } from 'hooks/common/useInvalidatequeries';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  addCommunitySavedPost,
  addProgramSavedPost,
  deleteCommunityPost,
  deleteCommunitySavedPost,
  deleteProgramPost,
  deleteProgramSavedPost,
} from 'utils/api/post';
import { handleCopyText } from 'utils/helper';
import { notifySuccess, notifyTranslatedError } from 'utils/notify';
import { generatePostLink } from 'utils/url';

interface ICommonPostContextMenu {
  postId: string;
  originId: string;
  originType: 'program' | 'community';
  canManage: boolean;
  isHome?: boolean;
  savedPostId?: string;
}

const CommonPostContextMenu = ({
  postId,
  originId,
  originType,
  canManage,
  isHome,
  savedPostId,
}: ICommonPostContextMenu) => {
  const { t } = useTranslation();

  const {
    invalidateAllPosts,
    invalidateCommunityPosts,
    invalidateProgramPosts,
    invalidateCommunitySavedPosts,
    invalidateProgramSavedPosts,
  } = useInvalidateQueries();
  const [openEditPostModal, setOpenEditPostModal] = useState(false);
  const [openDeleteConfirmationModal, setOpenDeleteConfirmationModal] = useState(false);

  const handleDeletePost = () => {
    if (originType === 'community') {
      deleteCommunityPost(originId, postId)
        .then(() => {
          invalidateAllPosts();
          invalidateCommunityPosts(originId);
          notifySuccess(t('post_deleted'));
        })
        .catch((error) => {
          notifyTranslatedError(t, error.response.data?.errorCode);
        });

      setOpenDeleteConfirmationModal(false);
    }
    if (originType === 'program') {
      deleteProgramPost(originId, postId)
        .then(() => {
          invalidateAllPosts();
          invalidateProgramPosts(originId);
          notifySuccess(t('post_deleted'));
        })
        .catch((error) => {
          notifyTranslatedError(t, error.response.data?.errorCode);
        });

      setOpenDeleteConfirmationModal(false);
    }
  };

  const handleSavePost = () => {
    if (savedPostId) {
      if (originType === 'community') {
        deleteCommunitySavedPost(originId, savedPostId)
          .then(() => {
            invalidateCommunitySavedPosts(originId);
          })
          .catch((error) => {
            notifyTranslatedError(t, error.response.data?.errorCode);
          });
      }
      if (originType === 'program') {
        deleteProgramSavedPost(originId, savedPostId)
          .then(() => {
            invalidateProgramSavedPosts(originId);
          })
          .catch((error) => {
            notifyTranslatedError(t, error.response.data?.errorCode);
          });
      }
    } else {
      if (originType === 'community') {
        addCommunitySavedPost(originId, { postId })
          .then(() => {
            invalidateCommunitySavedPosts(originId);
          })
          .catch((error) => {
            notifyTranslatedError(t, error.response.data?.errorCode);
          });
      }
      if (originType === 'program') {
        addProgramSavedPost(originId, { postId })
          .then(() => {
            invalidateProgramSavedPosts(originId);
          })
          .catch((error) => {
            notifyTranslatedError(t, error.response.data?.errorCode);
          });
      }
    }
  };

  const menuItems = [
    {
      key: 'copy-link',
      label: t('copy-link'),
      icon: CopyIcon,
      callback: () => {
        handleCopyText(generatePostLink(originId, originType, postId));
        notifySuccess(t('link-copied'));
      },
    },
    {
      key: 'save-post',
      label: savedPostId ? t('unsave_post') : t('save_post'),
      icon: CopyIcon,
      callback: () => handleSavePost(),
      show: !isHome,
    },
    {
      key: 'edit-post',
      label: t('edit_post'),
      icon: EditIcon,
      callback: () => setOpenEditPostModal(true),
      show: canManage,
    },
    {
      key: 'delete-post',
      label: t('delete-post'),
      icon: DeleteIcon,
      callback: () => setOpenDeleteConfirmationModal(true),
      show: canManage,
    },
  ];

  return (
    <>
      <ContextMenu menuItems={menuItems} />

      <UpdatePostModal
        postId={postId}
        originId={originId}
        originType={originType}
        isOpen={openEditPostModal}
        onClose={() => setOpenEditPostModal(false)}
      />

      {openDeleteConfirmationModal && (
        <ConfirmationModal
          isOpen={openDeleteConfirmationModal}
          label={t('delete-post')}
          title={t('confirmation-delete-post')}
          description={
            t('delete-confirmation-modal-title') ||
            'This post will be permanently removed and unrecoverable.'
          }
          onClose={() => setOpenDeleteConfirmationModal(false)}
          onConfirm={handleDeletePost}
        />
      )}
    </>
  );
};

export default CommonPostContextMenu;
