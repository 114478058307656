import { useQuery } from '@tanstack/react-query';
import { getCommunityManagers } from 'utils/api/member';

const useCommunityManagers = (communityId: string) =>
  useQuery({
    queryKey: ['community-managers', communityId],
    queryFn: () => getCommunityManagers(communityId),
    select: (data) => data.data.communityManagers,
  });

export default useCommunityManagers;
