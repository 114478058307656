import { useProgramPostComments } from 'hooks/api/post/useProgramPostComments';
import { useInvalidateQueries } from 'hooks/common/useInvalidatequeries';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { PostOrigin } from 'types/apis/common';
import { PostComponentType } from 'types/components/shared';
import { addProgramPinPost, deleteProgramPinPost } from 'utils/api/post';
import { notifyTranslatedError } from 'utils/notify';
import CommonPost from './CommonPost';

const ProgramPost = (
  props: Omit<PostComponentType, 'postOriginId' | 'postOriginType' | 'handlePinPost'> & {
    programId: string;
  },
) => {
  const { t } = useTranslation();
  const { programId, postId, ...rest } = props;

  const [showComments, setShowComments] = useState(false);

  const { comments, hasNextComments, fetchNextComments } = useProgramPostComments(
    programId,
    postId,
    showComments,
  );

  const { invalidateProgramPosts } = useInvalidateQueries();

  const handlePinPost = (isPinned: boolean, postId: string) => {
    if (isPinned) {
      deleteProgramPinPost(programId, postId)
        .then(() => {
          invalidateProgramPosts(programId);
        })
        .catch((error) => {
          notifyTranslatedError(t, error.response.data?.errorCode);
        });
    } else {
      addProgramPinPost(programId, postId)
        .then(() => {
          invalidateProgramPosts(programId);
        })
        .catch((error) => {
          notifyTranslatedError(t, error.response.data?.errorCode);
        });
    }
  };

  return (
    <CommonPost
      postId={postId}
      showComments={showComments}
      setShowComments={setShowComments}
      comments={comments}
      hasNextComments={hasNextComments || false}
      fetchNextComments={fetchNextComments}
      postOriginId={programId}
      postOriginType={PostOrigin.program}
      handlePinPost={handlePinPost}
      {...rest}
    />
  );
};

export default ProgramPost;
