import Button from 'components/button/Button';
import BodyText from 'components/typography/BodyText';
import BackIcon from 'assets/icons/back.svg';
import Avatar from 'components/memberAndProfile/Avatar';
import EyeIcon from 'assets/icons/eye.svg';
import EyeOffIcon from 'assets/icons/eye-off.svg';
import PinIcon from 'assets/icons/pin.svg';
import DeleteIcon from 'assets/icons/delete.svg';
import EditIcon from 'assets/icons/edit.svg';
import FilterIcon from 'assets/icons/filter.svg';
import { Link } from 'react-router-dom';
import Loading from 'components/Loading';
import ConfirmationModal from 'components/modal/ConfirmationModal';
import { userAvatar } from 'constants/common';
import { convertFromRaw, convertToRaw, EditorState, ContentState } from 'draft-js';
import MentionTextarea from 'components/common/MentionTextarea';
import React, { useEffect, useState, useRef, useMemo, ChangeEvent } from 'react';
import { useTranslation } from 'react-i18next';
import {
  startEmbedConversation,
  deleteEmbedConversation,
  editEmbedConversation,
} from 'utils/api/embedConversation';
import { notifyTranslatedError, notifySuccess } from 'utils/notify';
import { format } from 'date-fns';
import { useUser } from 'hooks/useUser';
import { ContextMenu } from 'components/common/ContextMenu';
import { DropdownMenu } from './DropdownMenu';
import {
  useEmbedMessages,
  useSingleEmbedConversation,
} from 'hooks/api/general/useEmbedConversation';
import { MessageBubble } from 'components/message/MessageBubble';
import { flagConversation } from 'utils/api/message';
import { convertHtmlToLink, editorContentToText, sanitizeHtml, isJSON } from 'utils/helper';
import { SingleEmbedConversationType } from 'types/apis/response';
import { SendMessageForm } from 'components/message/SendMessageForm';
import { MappedEmbedMessagesResponseType } from 'types/apis/response';
import { generateUserProfileLink } from 'utils/url';

interface IEmbedConversation {
  onOpen: () => void;
  onClose: () => void;
  originType: string;
  originId: string;
  allowComment?: boolean;
  isOwner?: boolean;
  conversationList: SingleEmbedConversationType[];
  refetchConversationList: () => void;
  isConversationListLoading: boolean;
}

const EmbedConversation = ({
  onOpen,
  onClose,
  originType,
  originId,
  allowComment,
  isOwner,
  conversationList,
  refetchConversationList,
  isConversationListLoading,
}: IEmbedConversation) => {
  const { t } = useTranslation();
  const user = useUser();
  const [singleConversationId, setSingleConversationId] = useState(conversationList[0]?.id || '');
  const [editorState, setEditorState] = useState(() => EditorState.createEmpty());
  const [showStartEmbedConversation, setShowStartEmbedConversation] = useState(false);
  const [showSingleConversation, setShowSingleConversation] = useState(false);
  const [editingConversationContent, setEditingConversationContent] = useState(false);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [conversationType, setConversationType] = useState('embed_all');
  const [selectedFilter, setSelectedFilter] = useState('all');
  const { singleConversation: singleConversationData, refetchSingleConversation } =
    useSingleEmbedConversation(singleConversationId || '');
  const [editConversationType, setEditConversationType] = useState(
    singleConversationData?.conversationType || 'embed',
  );

  useEffect(() => {
    setEditConversationType(singleConversationData?.conversationType || 'embed_all');
  }, [singleConversationData?.conversationType]);

  useEffect(() => {
    const currentContent = editorState.getCurrentContent();
    const rawContent = convertToRaw(currentContent);
    const content = JSON.stringify(rawContent);
  }, [editorState]);

  useEffect(() => {
    if (isJSON(singleConversationData?.alias || '')) {
      setEditorState(() =>
        EditorState.createWithContent(
          convertFromRaw(JSON.parse(singleConversationData?.alias || '')),
        ),
      );
    } else {
      setEditorState(() =>
        EditorState.createWithContent(
          ContentState.createFromText(singleConversationData?.alias || ''),
        ),
      );
    }
  }, [editingConversationContent]);

  const handleVisibilityChange = (e: ChangeEvent<HTMLSelectElement>) => {
    const newVisibility = e.target.value as 'embed' | 'embed_self' | 'embed_all';
    setConversationType(newVisibility);
  };
  const handleEditVisibilityChange = (e: ChangeEvent<HTMLSelectElement>) => {
    const newEditVisibility = e.target.value as 'embed' | 'embed_self' | 'embed_all';
    setEditConversationType(newEditVisibility);
  };

  const filterConversations = (conversations: any) => {
    if (selectedFilter === 'all') return conversations;
    return conversations.filter(
      (conversation: SingleEmbedConversationType) =>
        conversation.conversationType === selectedFilter,
    );
  };

  const handleStartChat = () => {
    const currentContent = editorState.getCurrentContent();
    const rawContent = convertToRaw(currentContent);
    const flatText = rawContent.blocks.map((block) => block.text).join('');
    const content = JSON.stringify(rawContent);
    startEmbedConversation({
      conversationType: conversationType,
      alias: content || 'Sidebar Conversation',
      originType: originType,
      originId: originId,
    })
      .then((data) => {
        setEditorState(EditorState.createEmpty());
        setShowStartEmbedConversation(false);
        refetchConversationList();
      })
      .catch((error) => {
        notifyTranslatedError(t, error.response.data?.errorCode);
        notifyTranslatedError(t, error.response.data?.message);
      });
  };

  const handleEditConversation = () => {
    const currentContent = editorState.getCurrentContent();
    const rawContent = convertToRaw(currentContent);
    const flatText = rawContent.blocks.map((block) => block.text).join('');
    const newContent = JSON.stringify(rawContent);

    editEmbedConversation(singleConversationData?.id || '', {
      newAlias: newContent,
      newConversationType:
        editConversationType || singleConversationData?.conversationType || 'embed',
    })
      .then((data) => {
        refetchSingleConversation();
        setEditingConversationContent(false);
      })
      .catch((error) => {
        notifyTranslatedError(t, error.response.data?.errorCode);
        notifyTranslatedError(t, error.response.data?.message);
      });
  };

  const handleDeleteConversation = (conversationId: string) => {
    deleteEmbedConversation(conversationId)
      .then((data) => {
        setShowSingleConversation(false);
        refetchConversationList();
        notifySuccess(t('conversation_deleted'));
      })
      .catch((error) => {
        notifyTranslatedError(t, error.response.data?.errorCode);
        notifyTranslatedError(t, error.response.data?.message);
      });
  };

  const handleFlagConversation = (conversationId: string) => {
    if (!user) return;
    flagConversation(conversationId, user.id)
      .then(() => {
        refetchSingleConversation();
        notifySuccess(t('action-success'));
      })
      .catch((error) => {
        notifyTranslatedError(t, error.response.data?.errorCode);
        notifyTranslatedError(t, error.response.data?.message);
      });
  };

  // Message send and reply code:
  const {
    messages: messagesData,
    fetchNextMessages,
    hasNextMessages,
    isMessagesLoading,
    refetchMessages,
  } = useEmbedMessages(singleConversationData?.id || '');

  const messageContainerRef = useRef<HTMLDivElement>(null);
  const messages = useMemo(() => {
    if (!messagesData) return [];

    return messagesData.reduce((allMessages, message) => {
      const {
        sender: { id: senderId },
      } = message;
      if (!senderId) return allMessages;

      return [
        ...allMessages,
        {
          ...message,
          isMine: senderId === user?.id,
        },
      ];
    }, [] as MappedEmbedMessagesResponseType);
  }, [messagesData]);

  const [replyMessage, setReplyMessage] = useState({
    onReplyMessageId: '',
    onReplyContent: '',
    onReplySenderId: '',
    onReplySenderFirstName: '',
    onReplySenderLastName: '',
  });
  const handleReply = (
    onReplyMessageId: string,
    onReplyContent: string,
    onReplySenderId: string,
    onReplySenderFirstName: string,
    onReplySenderLastName: string,
  ) => {
    setReplyMessage({
      onReplyMessageId,
      onReplyContent,
      onReplySenderId,
      onReplySenderFirstName,
      onReplySenderLastName,
    });
  };

  const handleDeleteReply = () => {
    setReplyMessage({
      onReplyMessageId: '',
      onReplyContent: '',
      onReplySenderId: '',
      onReplySenderFirstName: '',
      onReplySenderLastName: '',
    });
  };

  return (
    <div className='w-full h-full'>
      <div className='flex justify-between'>
        {!showSingleConversation ? (
          <div className='z-30'>
            <DropdownMenu
              rightDirection={true}
              menuButton={
                <div className='group relative flex items-center'>
                  <button className='neutral-button rounded-full p-2'>
                    <FilterIcon className='h-4 w-4' />
                  </button>
                  <div className='tooltip top-6'>
                    <BodyText variant='xs' className='break-words text-neutral-100'>
                      {t('filter_conversation')}
                    </BodyText>
                  </div>
                </div>
              }
              menuItems={[
                {
                  key: 'all',
                  label: t('select-all'),
                  callback: () => setSelectedFilter('all'),
                },
                {
                  key: 'embed',
                  label: t('visibility_normal'),
                  callback: () => setSelectedFilter('embed'),
                },
                {
                  key: 'embed_self',
                  label: t('visibility_myself'),
                  callback: () => setSelectedFilter('embed_self'),
                },
                {
                  key: 'embed_all',
                  label: t('visibility_all'),
                  callback: () => setSelectedFilter('embed_all'),
                },
              ]}
            />
          </div>
        ) : (
          <div>{''}</div>
        )}
        {showSingleConversation ? (
          <button
            className='relative flex h-6 w-6 items-center justify-center rounded-full text-functional-danger-dark hover:bg-neutral-300 active:bg-neutral-400'
            onClick={() => {
              if (showSingleConversation) {
                setShowSingleConversation(false);
                setEditingConversationContent(false);
                refetchConversationList();
              }
            }}
          >
            <BackIcon className='h-4 w-4 text-functional-danger-400' />
          </button>
        ) : (
          <div>{''}</div>
        )}
      </div>
      {!showSingleConversation ? (
        <div className=' space-y-2'>
          {(allowComment || isOwner) && (
            <Button
              size='small'
              variant='secondary'
              className='w-full px-4'
              onClick={() => setShowStartEmbedConversation(true)}
            >
              {t('start_comment_thread')}
            </Button>
          )}
          {showStartEmbedConversation && (
            <div>
              <div className='mb-3 space-y-1'>
                <MentionTextarea
                  originId={'all'}
                  originType={'all'}
                  editorState={editorState}
                  setEditorState={setEditorState}
                />
              </div>

              <div className='mb-3 flex items-center justify-between gap-2 border-t border-neutral-200'>
                <div className='w-full rounded-lg border border-neutral-400 p-1'>
                  <div className='group relative flex items-center'>
                    <EyeIcon className='h-3 w-4' />
                    <BodyText variant='xs' className='break-words text-neutral-500'>
                      {t('visibility')}
                    </BodyText>
                    <div className='absolute top-5 hidden max-w-[150px] rounded bg-neutral-500 px-1 py-1 opacity-20 transition-opacity duration-100 ease-in-out group-hover:block group-hover:opacity-100'>
                      <BodyText variant='xs' className='break-words text-neutral-100'>
                        {t('embed-conversation-visibility-tip')}
                      </BodyText>
                    </div>
                  </div>
                  <select
                    value={conversationType}
                    onChange={handleVisibilityChange}
                    className='w-full rounded-md bg-neutral-400 text-neutral-100 text-xs'
                  >
                    <option value='embed_self' className='text-sm'>
                      {t('visibility_myself')}
                    </option>
                    <option value='embed' className='text-sm'>
                      {t('visibility_normal')}
                    </option>
                    <option value='embed_all' className='text-sm'>
                      {t('visibility_all')}
                    </option>
                  </select>
                </div>
                <div className='flex items-center gap-1'>
                  <Button
                    size='small'
                    variant='outlined'
                    className='h-8'
                    onClick={() => setShowStartEmbedConversation(false)}
                  >
                    {t('cancel')}
                  </Button>
                  <Button
                    size='small'
                    variant='secondary'
                    onClick={handleStartChat}
                    className='h-8'
                  >
                    {t('confirm')}
                  </Button>
                </div>
              </div>
            </div>
          )}
          {isConversationListLoading ? (
            <div className='flex items-center justify-center'>
              <Loading />
            </div>
          ) : (
            <div className='space-y-2'>
              {filterConversations(conversationList)?.map(
                (conversation: SingleEmbedConversationType) => (
                  <button
                    className={`w-full rounded-md border bg-neutral-200 p-1 text-xs hover:bg-neutral-400 active:bg-neutral-500 ${
                      conversation.conversationType === 'embed_self'
                        ? ' border-functional-danger-dark'
                        : conversation.conversationType === 'embed_all'
                          ? ' border-functional-success-dark'
                          : ' border-functional-info-dark'
                    }`}
                    onClick={() => {
                      setSingleConversationId(conversation.id);
                      refetchSingleConversation();
                      setShowSingleConversation(true);
                    }}
                  >
                    <div>
                      <div className={`max-h-14 w-full overflow-hidden `}>
                        {conversation.conversationCreatedBy && (
                          <div className='flex items-center justify-between pb-2'>
                            <div className='flex items-center'>
                              <Avatar
                                size={14}
                                src={
                                  conversation.conversationCreatedBy.profilePicture || userAvatar
                                }
                              />
                              <BodyText variant='xs' className='ml-1'>
                                {conversation.conversationCreatedBy.firstName}&nbsp;
                                {conversation.conversationCreatedBy.lastName}:
                              </BodyText>
                            </div>
                            <div className='flex items-center gap-1'>
                              {conversation.conversationType === 'embed_self' && (
                                <div className='group relative flex items-center'>
                                  <EyeOffIcon className='h-3 w-3 text-functional-danger-400' />
                                  <div className='absolute right-3 top-1 hidden max-w-[150px] rounded bg-neutral-500 px-1 py-1 opacity-20 transition-opacity duration-100 ease-in-out group-hover:block group-hover:opacity-100'>
                                    <BodyText variant='xs' className='min-w-6 text-neutral-100 '>
                                      {t('visibility_myself')}
                                    </BodyText>
                                  </div>
                                </div>
                              )}
                              {conversation.conversationType === 'embed_all' && (
                                <div className='group relative flex items-center'>
                                  <EyeIcon className='h-3 w-3 text-functional-success-400' />
                                  <div className='absolute right-3 top-1 hidden max-w-[150px] rounded bg-neutral-500 px-1 py-1 opacity-20 transition-opacity duration-100 ease-in-out group-hover:block group-hover:opacity-100'>
                                    <BodyText variant='xs' className='min-w-6 text-neutral-100 '>
                                      {t('visibility_all')}
                                    </BodyText>
                                  </div>
                                </div>
                              )}
                              {conversation.conversationType === 'embed' && (
                                <div className='group relative flex items-center'>
                                  <EyeIcon className='h-3 w-3 text-functional-info-400' />
                                  <div className='absolute right-3 top-1 hidden max-w-[150px] rounded bg-neutral-500 px-1 py-1 opacity-20 transition-opacity duration-100 ease-in-out group-hover:block group-hover:opacity-100'>
                                    <BodyText variant='xs' className='min-w-6  text-neutral-100'>
                                      {t('visibility_normal')}
                                    </BodyText>
                                  </div>
                                </div>
                              )}
                              {conversation.unread && (
                                <span className='relative flex h-2 w-2'>
                                  <span className='absolute inline-flex h-full w-full animate-ping rounded-full bg-functional-danger-400 opacity-75'></span>
                                  <span className='relative inline-flex h-2 w-2 rounded-full bg-functional-danger-400'></span>
                                </span>
                              )}
                            </div>
                          </div>
                        )}
                        <BodyText
                          variant='xs'
                          className={`whitespace-pre-line break-words text-left text-neutral-600`}
                        >
                          <span
                            // eslint-disable-next-line react/no-danger
                            dangerouslySetInnerHTML={{
                              __html: sanitizeHtml(
                                convertHtmlToLink(editorContentToText(conversation.alias)),
                              ),
                            }}
                          />
                        </BodyText>
                      </div>
                      <div className='flex justify-between'>
                        <BodyText variant='xs' className='text-left text-functional-info-400'>
                          {t('show_more')}...
                        </BodyText>
                        {conversation.conversationFlagged ? (
                          <PinIcon className='h-3 w-3 ' />
                        ) : (
                          <div></div>
                        )}
                      </div>
                    </div>
                  </button>
                ),
              )}
            </div>
          )}
        </div>
      ) : (
        <div className='flex flex-col' style={{ height: 'calc(100vh - 125px)' }}>
          <div className=' flex flex-1 flex-col space-y-2 overflow-y-scroll whitespace-normal break-words  bg-neutral-100'>
            <div>
              <div className='w-full rounded-md bg-neutral-200 p-1 text-neutral-600 border border-neutral-300'>
                {singleConversationData?.conversationCreatedBy && (
                  <div className='flex items-center justify-between pb-2'>
                    <div className='flex items-center pb-2'>
                      <Avatar
                        size={30}
                        src={
                          singleConversationData?.conversationCreatedBy.profilePicture || userAvatar
                        }
                      />
                      <div>
                        <Link
                          to={generateUserProfileLink(
                            singleConversationData?.conversationCreatedBy.id || '',
                          )}
                          className='hover:underline'
                        >
                          <BodyText variant='xs' className='ml-1'>
                            {singleConversationData?.conversationCreatedBy.firstName}&nbsp;
                            {singleConversationData?.conversationCreatedBy.lastName}:
                          </BodyText>
                        </Link>
                        <BodyText variant='xs' className='ml-1 text-neutral-500'>
                          {format(
                            new Date(singleConversationData?.createdAt),
                            'dd MMM yyyy, h:mm:ss a',
                          )}
                        </BodyText>
                      </div>
                    </div>
                    {singleConversationData?.conversationCreatedBy.id === user?.id && (
                      <div className='z-40 flex flex-grow justify-end'>
                        <ContextMenu
                          menuItems={[
                            {
                              key: 'edit',
                              label: t('edit'),
                              icon: EditIcon,
                              callback: () => {
                                setEditingConversationContent(true);
                              },
                            },
                            {
                              key: 'flag',
                              label: t('flag_conversation'),
                              icon: PinIcon,
                              callback: () => {
                                handleFlagConversation(singleConversationData?.id || '');
                              },
                            },
                            {
                              key: 'delete',
                              label: t('delete_conversation'),
                              icon: DeleteIcon,
                              callback: () => {
                                setShowDeleteConfirmation(true);
                              },
                            },
                          ]}
                        />
                      </div>
                    )}
                  </div>
                )}
                {editingConversationContent ? (
                  <div className='rounded bg-neutral-200'>
                    <MentionTextarea
                      originId={'all'}
                      originType={'all'}
                      editorState={editorState}
                      setEditorState={setEditorState}
                    />
                    <div className='flex items-center justify-end gap-2'>
                      <div className='w-full'>
                        <div className='group relative flex items-center'>
                          <EyeIcon className='h-3 w-4' />
                          <BodyText variant='xs' className='break-words text-neutral-500'>
                            {t('visibility')}
                          </BodyText>
                          <div className='absolute top-5 hidden max-w-[150px] rounded bg-neutral-500 px-1 py-1 opacity-20 transition-opacity duration-100 ease-in-out group-hover:block group-hover:opacity-100'>
                            <BodyText variant='xs' className='break-words text-neutral-100'>
                              {t('embed-conversation-visibility-tip')}
                            </BodyText>
                          </div>
                        </div>
                        <select
                          value={editConversationType}
                          onChange={handleEditVisibilityChange}
                          className='w-full rounded-md bg-neutral-300 text-xs'
                        >
                          <option value='embed_self' className='text-sm'>
                            {t('visibility_myself')}
                          </option>
                          <option value='embed' className='text-sm'>
                            {t('visibility_normal')}
                          </option>
                          <option value='embed_all' className='text-sm'>
                            {t('visibility_all')}
                          </option>
                        </select>
                      </div>
                      <Button
                        className='text-neutral-500'
                        onClick={() => setEditingConversationContent(false)}
                      >
                        {t('cancel')}
                      </Button>
                      <Button onClick={handleEditConversation}>{t('confirm')}</Button>
                    </div>
                  </div>
                ) : (
                  <BodyText
                    variant='sm'
                    className={`whitespace-pre-line break-words text-left text-neutral-600 bg-neutral-200`}
                  >
                    {singleConversationData?.alias && (
                      <span
                        // eslint-disable-next-line react/no-danger
                        dangerouslySetInnerHTML={{
                          __html: sanitizeHtml(
                            convertHtmlToLink(editorContentToText(singleConversationData?.alias)),
                          ),
                        }}
                      />
                    )}
                  </BodyText>
                )}
              </div>
            </div>
            <div className='flex-1 p-1' ref={messageContainerRef}>
              {!isMessagesLoading && hasNextMessages && (
                <Button
                  className='m-auto'
                  onClick={() => {
                    fetchNextMessages();
                  }}
                >
                  {t('previous-messages')}
                </Button>
              )}
              {messages.map(
                (
                  {
                    id,
                    file,
                    sender: { id: senderId, firstName, lastName, profilePicture },
                    message,
                    createdAt,
                    isMine,
                    replyMessageId,
                    replyContent,
                    replySender,
                    readByUserId,
                    readByFirstName,
                  } = {} as any,
                ) => (
                  <MessageBubble
                    key={id}
                    messageId={id}
                    isMine={isMine}
                    senderId={senderId}
                    message={message}
                    replyMessageId={replyMessageId}
                    replyContent={replyContent}
                    replySenderId={replySender?.id}
                    replySenderFirstName={replySender?.firstName}
                    replySenderLastName={replySender?.lastName}
                    createdAt={createdAt}
                    firstName={firstName}
                    lastName={lastName}
                    readByUserId={readByUserId}
                    readByFirstName={readByFirstName}
                    profilePicture={profilePicture}
                    isEmbedded={true}
                    file={file}
                    refetchMessage={refetchMessages}
                    handleReply={(
                      onReplyMessageId,
                      onReplyContent,
                      onReplySenderId,
                      onReplySenderFirstName,
                      onReplySenderLastName,
                    ) =>
                      handleReply(
                        onReplyMessageId,
                        onReplyContent,
                        onReplySenderId,
                        onReplySenderFirstName,
                        onReplySenderLastName,
                      )
                    }
                  />
                ),
              )}
            </div>
          </div>
          <div className='flex-shrink-0 border-t border-neutral-300'>
            <SendMessageForm
              refetchMessages={refetchMessages}
              conversationId={singleConversationData?.id || ''}
              messageContainerRef={messageContainerRef}
              onReplyMessageId={replyMessage?.onReplyMessageId}
              onReplyContent={replyMessage?.onReplyContent}
              onReplySenderId={replyMessage?.onReplySenderId}
              onReplySenderFirstName={replyMessage?.onReplySenderFirstName}
              onReplySenderLastName={replyMessage?.onReplySenderLastName}
              handleDeleteReply={handleDeleteReply}
              isEmbedConversation={true}
            />
          </div>
        </div>
      )}
      <ConfirmationModal
        isOpen={showDeleteConfirmation}
        label={t('delete_conversation')}
        title={t('delete_conversation')}
        onClose={() => {
          setShowDeleteConfirmation(false);
        }}
        onConfirm={() => {
          handleDeleteConversation(singleConversationData?.id || '');
          setShowDeleteConfirmation(false);
        }}
      />
    </div>
  );
};

export default EmbedConversation;
