import {
  getPageEmbedConversationList,
  getEmbedMessages,
  getLastRead,
  getSingleEmbedConversation,
} from 'utils/api/embedConversation';
import { useQuery, useInfiniteQuery } from '@tanstack/react-query';
import { THIRTY_SECONDS } from 'utils/time';

export const useEmbedConversation = (originType: string, originId: string) => {
  const { data, refetch, isLoading } = useQuery({
    queryKey: ['comment-threads', originType, originId],
    queryFn: () => getPageEmbedConversationList(originType, originId),
    select: (res) => res.data.conversations,
  });
  return {
    conversationList: data ?? [],
    refetchConversationList: refetch,
    isConversationListLoading: isLoading,
  };
};

export const useEmbedMessages = (conversationId: string, limit = 20) => {
  const { data, isLoading, isFetchingNextPage, isRefetching, hasNextPage, fetchNextPage, refetch } =
    useInfiniteQuery({
      queryKey: ['messages', conversationId],
      queryFn: ({ pageParam = '' }) => getEmbedMessages(conversationId, limit, pageParam),
      getNextPageParam: (lastPage) => lastPage.data.cursor,
      refetchInterval: THIRTY_SECONDS,
      staleTime: THIRTY_SECONDS,
    });

  return {
    messages:
      data?.pages
        .slice()
        .reverse()
        .flatMap((item) => item.data.messages) ?? [],
    hasNextMessages: hasNextPage,
    fetchNextMessages: fetchNextPage,
    refetchMessages: refetch,
    isMessagesLoading: isLoading || isRefetching || isFetchingNextPage,
  };
};

export const useLastRead = (conversationId: string) =>
  useQuery({
    queryKey: ['lastread', conversationId],
    queryFn: () => getLastRead(conversationId),
    select: (data) => data.data,
    enabled: !!conversationId,
  });

export const useSingleEmbedConversation = (conversationId: string) => {
  const { data, refetch, isLoading } = useQuery({
    queryKey: ['single-embed-conversation', conversationId],
    queryFn: () => getSingleEmbedConversation(conversationId),
    select: (res) => res.data.conversation,
    enabled: !!conversationId,
  });
  return {
    singleConversation: data,
    refetchSingleConversation: refetch,
    isSingleConversationLoading: isLoading,
  };
};
