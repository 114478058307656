import { AxiosRequestConfig } from 'axios';
import { API_ROOT } from 'constants/config';
import {
  MyProgramsResponseType,
  SingleProgramResponseType,
  ProgramInviteLinksResponseType,
  HashtagsResponseType,
} from 'types/apis/program/response';
import { generateQueryParam, parseGET, parsePOST, parsePUT } from 'utils/rest';

export const getMyPrograms = (hashtag?: string, options?: AxiosRequestConfig) => {
  const queryParams = generateQueryParam({ hashtag });

  return parseGET<MyProgramsResponseType>(`${API_ROOT}/program/my-programs${queryParams}`, {
    withCredentials: true,
    ...options,
  });
};

export const getRelatedPrograms = (programId: string, options?: AxiosRequestConfig) =>
  parseGET<MyProgramsResponseType>(`${API_ROOT}/program/${programId}/related-programs`, {
    withCredentials: true,
    ...options,
  });

export const getOtherPublicPrograms = (
  hashtag?: string,
  options?: AxiosRequestConfig,
): Promise<MyProgramsResponseType> => {
  const queryParams = generateQueryParam({ hashtag });
  return parseGET<MyProgramsResponseType>(`${API_ROOT}/program/other-programs${queryParams}`, {
    withCredentials: true,
    ...options,
  });
};

export const getArchivedPrograms = (
  limit?: number,
  cursor?: string,
  options?: AxiosRequestConfig,
) => {
  const queryParams = generateQueryParam({ limit, cursor });

  return parseGET<MyProgramsResponseType>(`${API_ROOT}/org-admin/program/archived${queryParams}`, {
    withCredentials: true,
    ...options,
  });
};

export const getSingleProgram = (programId: string, options?: AxiosRequestConfig) =>
  parseGET<SingleProgramResponseType>(`${API_ROOT}/program/${programId}/single`, {
    withCredentials: true,
    ...options,
  });

export const getSinglePublicProgram = (
  programId: string,
  inviteToken?: string,
  options?: AxiosRequestConfig,
) =>
  parseGET<SingleProgramResponseType>(
    `${API_ROOT}/program/${programId}/single-public${
      inviteToken ? `?inviteToken=${inviteToken}` : ''
    }`,
    {
      withCredentials: true,
      ...options,
    },
  );

export const createProgram = (body: FormData, options?: AxiosRequestConfig) =>
  parsePOST(`${API_ROOT}/org-admin/program`, body, { withCredentials: true, ...options });

export const updateProgram = (programId: string, body: FormData, options?: AxiosRequestConfig) =>
  parsePUT(`${API_ROOT}/program/${programId}`, body, { withCredentials: true, ...options });

export const archiveProgram = (programId: string, options?: AxiosRequestConfig) =>
  parsePUT(
    `${API_ROOT}/org-admin/program/${programId}/archive`,
    {},
    { withCredentials: true, ...options },
  );

export const restoreProgram = (programId: string, options?: AxiosRequestConfig) =>
  parsePUT(
    `${API_ROOT}/org-admin/program/${programId}/restore`,
    {},
    { withCredentials: true, ...options },
  );

export const duplicateProgram = (
  programId: string,
  body: FormData,
  options?: AxiosRequestConfig,
) => {
  return parsePOST(`${API_ROOT}/program/${programId}/duplicate`, body, {
    withCredentials: true,
    ...options,
  });
};

export const getProgramInviteLinks = (programId: string, options?: AxiosRequestConfig) =>
  parseGET<ProgramInviteLinksResponseType>(`${API_ROOT}/program/${programId}/invite-links`, {
    withCredentials: true,
    ...options,
  });

export const getHashtags = (options?: AxiosRequestConfig) =>
  parseGET<HashtagsResponseType>(`${API_ROOT}/gethashtags`, {
    withCredentials: true,
    ...options,
  });
