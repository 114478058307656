import { useQuery } from '@tanstack/react-query';
import { searchUser } from 'utils/api/search';

export const useSearchUser = (searchString: string, communityId?: string, programId?: string) => {
  return useQuery({
    enabled: !!searchString,
    queryKey: ['search-user', searchString, communityId, programId],
    queryFn: () => searchUser(searchString, communityId, programId),
    select: (data) => data.data.users,
  });
};
