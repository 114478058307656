import { useInfiniteQuery } from '@tanstack/react-query';
import { getProgramGroups } from 'utils/api/group';

export const useProgramGroups = (programId: string, limit?: number) => {
  const { data, isLoading, isFetching, hasNextPage, fetchNextPage, refetch } = useInfiniteQuery({
    queryKey: ['program-groups', programId],
    queryFn: ({ pageParam = '' }) => getProgramGroups(programId, limit, pageParam),
    getNextPageParam: (res) => res.data.cursor,
  });

  return {
    groups: data?.pages.flatMap((page) => page.data.groups) ?? [],
    isGroupsLoading: isLoading,
    isGroupsFetching: isFetching,
    hasNextGroups: hasNextPage,
    fetchNextGroups: fetchNextPage,
    refetchGroups: refetch,
  };
};
