import { useQuery } from '@tanstack/react-query';
import { getInterests } from 'utils/api/profile';

export const useInterest = () => {
  return useQuery({
    queryKey: ['interest'],
    queryFn: () => getInterests(),
    select: (data) => data.data.interest,
  });
};
