import Button from 'components/button/Button';
import ExpertiseMultiSelect from 'components/form/ExpertiseMultiSelect';
import { useInvalidateQueries } from 'hooks/common/useInvalidatequeries';
import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { addExpertises } from 'utils/api/profile';

const AddNewExpertise = () => {
  const { t } = useTranslation();

  const { invalidateProfileInfo, invalidateExpertise } = useInvalidateQueries();

  const {
    watch,
    reset,
    setValue,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm<{ names: string[] }>({ defaultValues: { names: [] } });

  const names = watch('names');

  const handleSubmit = () => {
    if (names.length === 0) {
      setError('names', { type: 'required' });
      return;
    }

    addExpertises({ names }).then(() => {
      reset({ names: [] });
      invalidateExpertise();
      invalidateProfileInfo();
    });
  };

  useEffect(() => {
    if (names.length !== 0) clearErrors('names');
  }, [names]);

  return (
    <div className='flex items-center gap-5'>
      <ExpertiseMultiSelect
        tags={names}
        setTags={(values) => {
          setValue('names', values);
        }}
        className={errors.names ? 'border-functional-danger-400' : ''}
      />
      <Button size='default' variant='secondary' onClick={handleSubmit}>
        {t('add')}
      </Button>
    </div>
  );
};

export default AddNewExpertise;
