import Avatar from 'components/memberAndProfile/Avatar';
import Button from 'components/button/Button';
import ConfirmationModal from 'components/modal/ConfirmationModal';
import BodyText from 'components/typography/BodyText';
import { Disclosure } from '@headlessui/react';
import { userAvatar } from 'constants/common';
import ChevronRightIcon from 'assets/icons/chevron-right.svg';
import { useJoinRequests } from 'hooks/api/member/useJoinRequests';
import { useInvalidateQueries } from 'hooks/common/useInvalidatequeries';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { acceptCommunityJoinRequest, rejectCommunityJoinRequest } from 'utils/api/member';
import { notifyTranslatedError } from 'utils/notify';

interface IJoinRequests {
  communityId: string;
}

const JoinRequests = ({ communityId }: IJoinRequests) => {
  const { t } = useTranslation();

  const [selectedJoinRequestId, setselectedJoinRequestId] = useState('');
  const [openAcceptConfirmationModal, setOpenAcceptConfirmationModal] = useState(false);
  const [openRejectConfirmationModal, setOpenRejectConfirmationModal] = useState(false);

  const { data, hasNextRequests, fetchNextRequests } = useJoinRequests(communityId);
  const { invalidateCommunityJoinRequests } = useInvalidateQueries();

  const handleOpenAcceptConfirmationModal = (requestId: string) => {
    setselectedJoinRequestId(requestId);
    setOpenAcceptConfirmationModal(true);
  };

  const handleCloseAcceptConfirmationModal = () => {
    setselectedJoinRequestId('');
    setOpenAcceptConfirmationModal(false);
  };

  const handleOpenRejectConfirmationModal = (requestId: string) => {
    setselectedJoinRequestId(requestId);
    setOpenRejectConfirmationModal(true);
  };

  const handleCloseRejectConfirmationModal = () => {
    setselectedJoinRequestId('');
    setOpenRejectConfirmationModal(false);
  };

  const handleAcceptJoinRequest = () => {
    acceptCommunityJoinRequest(communityId, selectedJoinRequestId)
      .then(() => {
        invalidateCommunityJoinRequests(communityId);
      })
      .catch((error) => {
        notifyTranslatedError(t, error.response.data?.errorCode);
      });
    setOpenAcceptConfirmationModal(false);
  };

  const handleRejectJoinRequest = () => {
    rejectCommunityJoinRequest(communityId, selectedJoinRequestId)
      .then(() => {
        invalidateCommunityJoinRequests(communityId);
      })
      .catch((error) => {
        notifyTranslatedError(t, error.response.data?.errorCode);
      });
    setOpenRejectConfirmationModal(false);
  };

  if (!data.length) return null;

  return (
    <div className='rounded-2xl bg-neutral-200 p-2 shadow shadow-neutral-300'>
      <Disclosure defaultOpen={true}>
        {({ open }) => (
          <>
            <Disclosure.Button className='flex w-full items-center '>
              <ChevronRightIcon className={`h-5 w-5  ${open ? 'rotate-90 transform' : ''} `} />
              <BodyText variant='base' className='font-semibold text-secondary-500'>
                {t('join_requests')}
              </BodyText>
            </Disclosure.Button>
            <Disclosure.Panel>
              <div className='space-y-2 px-4 mt-2'>
                {data.map(({ id, user }) => (
                  <div key={id} className='flex items-center justify-between'>
                    <div className='flex items-center gap-4'>
                      <Avatar size={40} src={user.profilePicture || userAvatar} />
                      <div>
                        <BodyText variant='sm' className='font-medium'>
                          {`${user.firstName} ${user.lastName}`}
                        </BodyText>
                      </div>
                    </div>
                    <div className='flex items-center gap-3'>
                      <Button
                        size='small'
                        variant='outlined'
                        onClick={() => handleOpenRejectConfirmationModal(id)}
                      >
                        {t('reject')}
                      </Button>
                      <Button
                        size='small'
                        variant='secondary'
                        onClick={() => handleOpenAcceptConfirmationModal(id)}
                      >
                        {t('accept')}
                      </Button>
                    </div>
                  </div>
                ))}
              </div>

              {hasNextRequests && (
                <button className='relative'>
                  <Avatar size={40} src={userAvatar} alt='' />
                  <div className='absolute inset-0 rounded-full bg-neutral-600 bg-opacity-60' />
                  <BodyText
                    variant='base'
                    className='absolute inset-x-0 top-2/4 -translate-y-2/4 text-neutral-100'
                  >
                    2+
                  </BodyText>
                </button>
              )}
            </Disclosure.Panel>
          </>
        )}
      </Disclosure>

      {openAcceptConfirmationModal && (
        <ConfirmationModal
          isOpen={openAcceptConfirmationModal}
          label={t('accept_request')}
          title={t('accept_request_confirmation_title')}
          onClose={handleCloseAcceptConfirmationModal}
          onConfirm={handleAcceptJoinRequest}
        />
      )}

      {openRejectConfirmationModal && (
        <ConfirmationModal
          isOpen={openRejectConfirmationModal}
          label={t('reject_request')}
          title={t('reject_request_confirmation_title')}
          onClose={handleCloseRejectConfirmationModal}
          onConfirm={handleRejectJoinRequest}
        />
      )}
    </div>
  );
};

export default JoinRequests;
