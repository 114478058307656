import { useInfiniteQuery } from '@tanstack/react-query';
import { getProgramEventComments } from 'utils/api/event';

export const useProgramEventComments = (programId: string, eventId: string) => {
  const {
    data: comments,
    hasNextPage,
    fetchNextPage,
    refetch,
  } = useInfiniteQuery({
    queryKey: ['program-event-comments', programId, eventId],
    queryFn: ({ pageParam = '' }) => getProgramEventComments(programId, eventId, 2, pageParam),
    getNextPageParam: (data) => data.data.cursor,
  });

  return {
    comments: comments?.pages.flatMap((item) => item.data.comments) ?? [],
    hasNextComments: hasNextPage,
    fetchNextComments: fetchNextPage,
    refetchComments: refetch,
  };
};
