import EyeIcon from 'assets/icons/eye.svg';
import SearchInput from 'components/form/SearchInput';
import BodyText from 'components/typography/BodyText';
import Button from 'components/button/Button';
import { Link } from 'react-router-dom';
import { generateOriginLink } from 'utils/url';
import dayjs from 'dayjs';
import { useCommunityTasksSubmissions } from 'hooks/api/task/useCommunityTasksSubmissons';
import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { SingleCommunityType } from 'types/apis/common';
import BackIcon from 'assets/icons/back.svg';
import { generateCommunityTaskSubmissionLink } from 'utils/url';

interface IManagersSubmissions {
  community: SingleCommunityType;
}

const ManagersSubmissions = ({ community }: IManagersSubmissions) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [searchString, setSearchString] = useState('');

  const { data: submissions } = useCommunityTasksSubmissions(community.id);

  const filteredSubmissions = useMemo(
    () =>
      submissions?.filter((submission) => submission.title.toLowerCase().includes(searchString)) ||
      [],
    [submissions, searchString],
  );

  return (
    <div className='space-y-4'>
      <div className='flex flex-row items-center gap-2 px-1 justify-between'>
        <div className='flex items-center gap-2'>
          <SearchInput onSearch={(value) => setSearchString(value)} />
          <BodyText variant='base' className='mr-auto text-neutral-500 hidden md:block'>
            {t('total')} {submissions?.length}
          </BodyText>
        </div>
        <Link to={generateOriginLink(community.id, 'community') + '/tasks'}>
          <Button
            size='default'
            variant='outlined'
            className='text-functional-danger-400 p-[8px]'
            tooltip={t('back')}
          >
            <BackIcon className='h-4 w-4' />
          </Button>
        </Link>
      </div>

      <div className='table-container'>
        <table>
          <thead>
            <tr>
              <th>
                <BodyText variant='sm' className='font-medium'>
                  {t('task_name')}
                </BodyText>
              </th>
              <th>
                <BodyText variant='sm' className='font-medium'>
                  {t('deadline')}
                </BodyText>
              </th>
              <th>
                <BodyText variant='sm' className='font-medium'>
                  {t('created-by')}
                </BodyText>
              </th>
              <th>
                <BodyText variant='sm' className='font-medium'>
                  {t('actions')}
                </BodyText>
              </th>
            </tr>
          </thead>
          <tbody>
            {filteredSubmissions.map(({ id, title, deadline, submittedUserCount, hasDeadline }) => (
              <tr key={id}>
                <td>
                  <button
                    className='hover:underline w-full rounded-xl p-1  text-left text-neutral-500'
                    onClick={() =>
                      navigate(
                        generateCommunityTaskSubmissionLink(community.id, id, title, deadline),
                      )
                    }
                  >
                    <BodyText variant='sm'>{title}</BodyText>
                  </button>
                </td>
                <td>
                  {hasDeadline ? (
                    <BodyText variant='sm' className='text-neutral-500'>
                      {dayjs(deadline).format('ddd, MMM D YYYY, h:mm A')}
                    </BodyText>
                  ) : (
                    <BodyText variant='xs' className='text-neutral-500'>
                      {t('open_ended_task')}
                    </BodyText>
                  )}
                </td>
                <td>
                  <BodyText variant='sm' className='text-neutral-500'>
                    {submittedUserCount} {t('members')}
                  </BodyText>
                </td>
                <td>
                  <button
                    className='flex items-center gap-3 text-neutral-400 hover:text-functional-info-dark'
                    onClick={() =>
                      navigate(
                        generateCommunityTaskSubmissionLink(community.id, id, title, deadline),
                      )
                    }
                  >
                    <BodyText as='span' variant='sm' className='font-medium'>
                      {t('view')}
                    </BodyText>{' '}
                    <EyeIcon className='h-5 w-5' />
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default ManagersSubmissions;
