import React from 'react';
import CommunityHome from 'pages/segment/community/communityHome/CommunityHome';
import CommunityFeed from 'pages/segment/community/communityFeed/CommunityFeed';
import CommunityResources from 'pages/segment/community/communityResources/CommunityResources';
import CommunityEvents from 'pages/segment/community/communityEventsTasks/CommunityEvents';
import CommunityTasks from 'pages/segment/community/communityEventsTasks/CommunityTasks';
import CommunityPublicFolder from 'pages/segment/community/communityPublicFolder/CommunityPublicFolder';
import CommunitySubmissions from 'pages/segment/community/communitySubmissions/CommunitySubmissions';
import CommunityMembers from 'pages/segment/community/communityMembers/CommunityMembers';
import ProgramHome from 'pages/segment/programs/programHome/ProgramHome';
import ProgramFeed from 'pages/segment/programs/programFeed/ProgramFeed';
import ProgramMaterials from 'pages/segment/programs/programMaterials/ProgramMaterials';
import ProgramMembers from 'pages/segment/programs/programMembers/ProgramMembers';
import ProgramGroups from 'pages/segment/programs/programGroups/ProgramGroups';
import ProgramEvents from 'pages/segment/programs/programEventsTasks/ProgramEvents';
import ProgramTasks from 'pages/segment/programs/programEventsTasks/ProgramTasks';
import ProgramPublicFolder from 'pages/segment/programs/programPublicFolder/ProgramPublicFolder';
import ProgramSubmissions from 'pages/segment/programs/programSubmissions/ProgramSubmissions';
import OriginHome from 'pages/segment/guest/GuestOriginHomePage';
import OriginResources from 'pages/segment/guest/GuestOriginResources';
import OrgCommunities from 'pages/admin/OrgCommunities';
import OrgPrograms from 'pages/admin/OrgPrograms';
import AdminListTable from 'pages/admin/AdminListTable';
import ArchivedCommunities from 'pages/admin/ArchivedCommunities';
import ArchivedPrograms from 'pages/admin/ArchivedPrograms';
import MyResources from 'pages/segment/resources/MyResources';
import SharedResources from 'pages/segment/resources/SharedResources';
import ActiveMembers from 'pages/segment/members/ActiveMembers';
import ArchivedMembers from 'pages/segment/members/ArchivedMembers';

export const communityTabItems = [
  {
    index: true,
    path: '',
    label: 'home',
    element: <CommunityHome />,
  },
  {
    path: 'feed',
    label: 'feed',
    element: <CommunityFeed />,
  },
  {
    path: 'resources',
    label: 'resources',
    element: <CommunityResources />,
  },
  {
    path: 'public-folder',
    label: 'public-folder',
    element: <CommunityPublicFolder />,
  },
  {
    path: 'events',
    label: 'events',
    element: <CommunityEvents />,
  },
  {
    path: 'tasks',
    label: 'tasks',
    element: <CommunityTasks />,
    children: [
      {
        path: 'submissions',
        label: 'submissions',
        element: <CommunitySubmissions />,
      },
    ],
  },
  {
    path: 'members',
    label: 'members',
    element: <CommunityMembers />,
  },
];

export const programTabItems = [
  { index: true, path: '', label: 'home', element: <ProgramHome /> },
  { path: 'feed', label: 'feed', element: <ProgramFeed /> },
  { path: 'resources', label: 'resources', element: <ProgramMaterials /> },
  {
    path: 'public-folder',
    label: 'public-folder',
    element: <ProgramPublicFolder />,
  },
  { path: 'events', label: 'events', element: <ProgramEvents /> },
  {
    path: 'tasks',
    label: 'tasks',
    element: <ProgramTasks />,
    children: [
      {
        path: 'submissions',
        label: 'submissions',
        element: <ProgramSubmissions />,
      },
    ],
  },
  { path: 'members', label: 'members', element: <ProgramMembers /> },
  { path: 'groups', label: 'groups', element: <ProgramGroups /> },
];

export const guestTabItems = [
  {
    index: true,
    path: '',
    label: 'home',
    element: <OriginHome />,
  },
  {
    path: 'resources',
    label: 'resources&file',
    element: <OriginResources />,
  },
];
export const adminTabItems = [
  {
    index: true,
    path: 'users',
    label: 'admin_users',
    element: <AdminListTable />,
  },
  {
    path: 'programs',
    label: 'spaces',
    element: <OrgPrograms />,
  },
  {
    path: 'archived-pgms',
    label: 'archived-spaces',
    element: <ArchivedPrograms />,
  },
  {
    path: 'communities',
    label: 'Communities (Legacy)',
    element: <OrgCommunities />,
  },
  {
    path: 'archived-cmts',
    label: 'Archived Communities (Legacy)',
    element: <ArchivedCommunities />,
  },
];

export const resourceTabItems = [
  { index: true, path: 'mine', label: 'my_resources', element: <MyResources /> },
  { path: 'shared', label: 'share-with-me', element: <SharedResources /> },
];

export const membersAdminTabItems = [
  { index: true, path: 'mine', label: 'active_members', element: <ActiveMembers /> },
  { path: 'shared', label: 'archived_members', element: <ArchivedMembers /> },
];

export const membersMemberTabItems = [
  { index: true, path: 'mine', label: 'all_members', element: <ActiveMembers /> },
];
