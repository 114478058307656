import { AxiosRequestConfig } from 'axios';
import { API_ROOT } from 'constants/config';
import { AddSavedPostRequestType, CommunityPostRequestType } from 'types/apis/request';
import {
  CommunitySavedPostsResponseType,
  CommunityPostsResponseType,
  SingleCommunityPostResponseType,
  CommunityPostCommentsResponseType,
  AllPostsResponseType,
} from 'types/apis/response';
import { generateQueryParam, parseDELETE, parseGET, parsePOST, parsePUT } from 'utils/rest';

const API_COMMUNITY_ROOT = `${API_ROOT}/community`;
const API_PROGRAM_ROOT = `${API_ROOT}/program`;

export const getAllPosts = (limit?: number, cursor?: string, options?: AxiosRequestConfig) => {
  const queryParam = generateQueryParam({ limit, cursor });
  return parseGET<AllPostsResponseType>(`${API_ROOT}/dashboard/user/posts${queryParam}`, {
    withCredentials: true,
    ...options,
  });
};

export const getCommunityPosts = (
  communityId: string,
  limit = 20,
  cursor?: string,
  options?: AxiosRequestConfig,
) => {
  const queryParam = generateQueryParam({ limit, cursor });
  return parseGET<CommunityPostsResponseType>(
    `${API_COMMUNITY_ROOT}/${communityId}/posts${queryParam}`,
    {
      withCredentials: true,
      ...options,
    },
  );
};

export const getProgramPosts = (
  programId: string,
  limit?: number,
  cursor?: string,
  options?: AxiosRequestConfig,
) => {
  const queryParam = generateQueryParam({ limit, cursor });
  return parseGET<CommunityPostsResponseType>(
    `${API_PROGRAM_ROOT}/${programId}/posts${queryParam}`,
    {
      withCredentials: true,
      ...options,
    },
  );
};

export const getSingleCommunityPost = (
  communityId: string,
  postId: string,
  options?: AxiosRequestConfig,
) =>
  parseGET<SingleCommunityPostResponseType>(
    `${API_COMMUNITY_ROOT}/${communityId}/posts/${postId}/post-details`,
    {
      withCredentials: true,
      ...options,
    },
  );

export const getSingleProgramPost = (
  programId: string,
  postId: string,
  options?: AxiosRequestConfig,
) =>
  parseGET<SingleCommunityPostResponseType>(
    `${API_PROGRAM_ROOT}/${programId}/posts/${postId}/post-details`,
    {
      withCredentials: true,
      ...options,
    },
  );

export const createCommunityPost = (
  communityId: string,
  body: FormData,
  options?: AxiosRequestConfig,
) =>
  parsePOST(`${API_COMMUNITY_ROOT}/${communityId}/posts`, body, {
    withCredentials: true,
    ...options,
  });

export const createProgramPost = (
  programId: string,
  body: FormData,
  options?: AxiosRequestConfig,
) =>
  parsePOST(`${API_PROGRAM_ROOT}/${programId}/posts`, body, {
    withCredentials: true,
    ...options,
  });

export const updateCommunityPost = (
  communityId: string,
  postId: string,
  body: CommunityPostRequestType,
  options?: AxiosRequestConfig,
) =>
  parsePUT(`${API_COMMUNITY_ROOT}/${communityId}/posts/${postId}`, body, {
    withCredentials: true,
    ...options,
  });

export const updateProgramPost = (
  programId: string,
  postId: string,
  body: { content: string },
  options?: AxiosRequestConfig,
) =>
  parsePUT(`${API_PROGRAM_ROOT}/${programId}/posts/${postId}`, body, {
    withCredentials: true,
    ...options,
  });

export const deleteCommunityPost = (
  communityId: string,
  postId: string,
  options?: AxiosRequestConfig,
) =>
  parseDELETE(`${API_COMMUNITY_ROOT}/${communityId}/posts/${postId}`, {
    withCredentials: true,
    ...options,
  });

export const deleteProgramPost = (
  programId: string,
  postId: string,
  options?: AxiosRequestConfig,
) =>
  parseDELETE(`${API_PROGRAM_ROOT}/${programId}/posts/${postId}`, {
    withCredentials: true,
    ...options,
  });

export const likeCommunityPost = (
  communityId: string,
  postId: string,
  options?: AxiosRequestConfig,
) =>
  parsePUT(
    `${API_COMMUNITY_ROOT}/${communityId}/posts/${postId}/like`,
    {},
    { ...options, withCredentials: true },
  );

export const likeProgramPost = (programId: string, postId: string, options?: AxiosRequestConfig) =>
  parsePUT(
    `${API_PROGRAM_ROOT}/${programId}/posts/${postId}/like`,
    {},
    { ...options, withCredentials: true },
  );

export const dislikeCommunityPost = (
  communityId: string,
  postId: string,
  options?: AxiosRequestConfig,
) =>
  parsePUT(
    `${API_COMMUNITY_ROOT}/${communityId}/posts/${postId}/unlike`,
    {},
    { ...options, withCredentials: true },
  );

export const dislikeProgramPost = (
  programId: string,
  postId: string,
  options?: AxiosRequestConfig,
) =>
  parsePUT(
    `${API_PROGRAM_ROOT}/${programId}/posts/${postId}/unlike`,
    {},
    { ...options, withCredentials: true },
  );

export const getCommunityPostComments = (
  communityId: string,
  postId: string,
  cursor: string,
  options?: AxiosRequestConfig,
) =>
  parseGET<CommunityPostCommentsResponseType>(
    `${API_COMMUNITY_ROOT}/${communityId}/posts/${postId}/comments?limit=4&cursor=${cursor}`,
    {
      ...options,
      withCredentials: true,
    },
  );

export const getProgramPostComments = (
  programId: string,
  postId: string,
  cursor: string,
  options?: AxiosRequestConfig,
) =>
  parseGET<CommunityPostCommentsResponseType>(
    `${API_PROGRAM_ROOT}/${programId}/posts/${postId}/comments?limit=4&cursor=${cursor}`,
    {
      ...options,
      withCredentials: true,
    },
  );

export const addCommunityPostComment = (
  communityId: string,
  postId: string,
  body: { comment: string },
  options?: AxiosRequestConfig,
) =>
  parsePOST(`${API_COMMUNITY_ROOT}/${communityId}/posts/${postId}/comments`, body, {
    ...options,
    withCredentials: true,
  });

export const addProgramPostComment = (
  programId: string,
  postId: string,
  body: { comment: string },
  options?: AxiosRequestConfig,
) =>
  parsePOST(`${API_PROGRAM_ROOT}/${programId}/posts/${postId}/comments`, body, {
    ...options,
    withCredentials: true,
  });

export const deleteCommunityPostComment = (
  communityId: string,
  commentId: string,
  options?: AxiosRequestConfig,
) =>
  parseDELETE(`${API_COMMUNITY_ROOT}/${communityId}/posts/comments/${commentId}`, {
    ...options,
    withCredentials: true,
  });

export const deleteProgramPostComment = (
  programId: string,
  commentId: string,
  options?: AxiosRequestConfig,
) =>
  parseDELETE(`${API_PROGRAM_ROOT}/${programId}/posts/comments/${commentId}`, {
    ...options,
    withCredentials: true,
  });

export const getCommunitySavedPosts = (communityId: string, options?: AxiosRequestConfig) =>
  parseGET<CommunitySavedPostsResponseType>(`${API_COMMUNITY_ROOT}/${communityId}/saved-posts`, {
    withCredentials: true,
    ...options,
  });

export const getProgramSavedPosts = (programId: string, options?: AxiosRequestConfig) =>
  parseGET<CommunitySavedPostsResponseType>(`${API_PROGRAM_ROOT}/${programId}/saved-posts`, {
    withCredentials: true,
    ...options,
  });

export const addCommunitySavedPost = (
  communityId: string,
  body: AddSavedPostRequestType,
  options?: AxiosRequestConfig,
) =>
  parsePOST(`${API_COMMUNITY_ROOT}/${communityId}/saved-posts`, body, {
    withCredentials: true,
    ...options,
  });

export const addProgramSavedPost = (
  programId: string,
  body: AddSavedPostRequestType,
  options?: AxiosRequestConfig,
) =>
  parsePOST(`${API_PROGRAM_ROOT}/${programId}/saved-posts`, body, {
    withCredentials: true,
    ...options,
  });

export const deleteCommunitySavedPost = (
  communityId: string,
  postId: string,
  options?: AxiosRequestConfig,
) =>
  parseDELETE(`${API_COMMUNITY_ROOT}/${communityId}/saved-posts/${postId}`, {
    withCredentials: true,
    ...options,
  });

export const deleteProgramSavedPost = (
  programId: string,
  postId: string,
  options?: AxiosRequestConfig,
) =>
  parseDELETE(`${API_PROGRAM_ROOT}/${programId}/saved-posts/${postId}`, {
    withCredentials: true,
    ...options,
  });

export const addCommunityPinPost = (
  communityId: string,
  postId: string,
  options?: AxiosRequestConfig,
) =>
  parsePUT(
    `${API_COMMUNITY_ROOT}/${communityId}/posts/${postId}/pin`,
    {},
    { ...options, withCredentials: true },
  );

export const addProgramPinPost = (
  programId: string,
  postId: string,
  options?: AxiosRequestConfig,
) =>
  parsePUT(
    `${API_PROGRAM_ROOT}/${programId}/posts/${postId}/pin`,
    {},
    { ...options, withCredentials: true },
  );

export const deleteCommunityPinPost = (
  communityId: string,
  postId: string,
  options?: AxiosRequestConfig,
) =>
  parsePUT(
    `${API_COMMUNITY_ROOT}/${communityId}/posts/${postId}/unpin`,
    {},
    { ...options, withCredentials: true },
  );

export const deleteProgramPinPost = (
  programId: string,
  postId: string,
  options?: AxiosRequestConfig,
) =>
  parsePUT(
    `${API_PROGRAM_ROOT}/${programId}/posts/${postId}/unpin`,
    {},
    { ...options, withCredentials: true },
  );
