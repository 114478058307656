import SendIcon from 'assets/icons/send.svg';
import Avatar from 'components/memberAndProfile/Avatar';
import Button from 'components/button/Button';
import MentionTextarea from 'components/common/MentionTextarea';
import BodyText from 'components/typography/BodyText';
import { userAvatar } from 'constants/common';
import { convertToRaw, EditorState } from 'draft-js';
import useIsLoggedIn from 'hooks/api/auth/useIsLoggedIn';
import React, { useState, useEffect } from 'react';
import { SubmitHandler, useForm, UseFormReset } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { PostCommentFormInputType } from 'types/components/form';

interface IPublishComment {
  originId: string;
  originType: 'program' | 'community';
  onCommentPublish: (
    data: { comment: string },
    reset: UseFormReset<PostCommentFormInputType>,
    setEditorState: React.Dispatch<React.SetStateAction<EditorState>>,
  ) => void;
}

const PublishComment = ({ originId, originType, onCommentPublish }: IPublishComment) => {
  const { t } = useTranslation();

  const [editorState, setEditorState] = useState(() => EditorState.createEmpty());

  const { data: user } = useIsLoggedIn();

  const {
    reset,
    setValue,
    setError,
    clearErrors,
    handleSubmit,
    formState: { errors },
  } = useForm<PostCommentFormInputType>();

  const onSubmit: SubmitHandler<PostCommentFormInputType> = (data) => {
    const currentContent = editorState.getCurrentContent();
    const rawContent = convertToRaw(currentContent);
    const flatText = rawContent.blocks.map((block) => block.text).join('');

    if (flatText.length === 0) {
      setError('comment', { type: 'minLength' });
      return;
    }

    onCommentPublish(data, reset, setEditorState);
  };

  useEffect(() => {
    clearErrors('comment');
    const currentContent = editorState.getCurrentContent();
    const rawContent = convertToRaw(currentContent);
    const content = JSON.stringify(rawContent);
    setValue('comment', content);
  }, [editorState]);

  return (
    <div className='flex items-start gap-2 md:gap-6'>
      <Avatar
        size={56}
        className='hidden shrink-0 md:block'
        src={user?.profilePicture || userAvatar}
        alt={`${user?.firstName} ${user?.lastName}`}
      />

      <form onSubmit={handleSubmit(onSubmit)} className='grow overflow-hidden'>
        <div className='flex items-center gap-2 md:gap-6'>
          <div className='grow overflow-hidden'>
            <MentionTextarea
              originId={originId}
              originType={originType}
              editorState={editorState}
              setEditorState={setEditorState}
            />
            {errors.comment && (
              <BodyText variant='xs' className='text-functional-danger-400'>
                {t('required_error_message')}
              </BodyText>
            )}
          </div>

          <Button size='default' variant='primary' className='shrink-0'>
            <SendIcon className='h-5 w-5' />
          </Button>
        </div>
      </form>
    </div>
  );
};

export default PublishComment;
