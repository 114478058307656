import Avatar from 'components/memberAndProfile/Avatar';
import Button from 'components/button/Button';
import ChevronRightIcon from 'assets/icons/chevron-right.svg';
import ConfirmationModal from 'components/modal/ConfirmationModal';
import BodyText from 'components/typography/BodyText';
import { Disclosure } from '@headlessui/react';
import { userAvatar } from 'constants/common';
import { useProgramPendingInvites } from 'hooks/api/member/useProgramPendingInvites';
import { useInvalidateQueries } from 'hooks/common/useInvalidatequeries';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { deleteProgramPendingInvite } from 'utils/api/member';
import { notifyTranslatedError } from 'utils/notify';

interface IPendingInvites {
  programId: string;
}

const PendingInvites = ({ programId }: IPendingInvites) => {
  const { t } = useTranslation();

  const [selectedInviteId, setSelectedInviteId] = useState('');
  const [openCancelConfirmation, setOpenCancelConfirmation] = useState(false);

  const { invites, hasNextInvites, fetchNextInvites } = useProgramPendingInvites(programId);
  const { invalidateProgramPendingInvites } = useInvalidateQueries();

  const handleOpenConfirmation = (inviteId: string) => {
    setSelectedInviteId(inviteId);
    setOpenCancelConfirmation(true);
  };

  const handleCloseConfirmation = () => {
    setSelectedInviteId('');
    setOpenCancelConfirmation(false);
  };

  const handleCancelInvite = () => {
    deleteProgramPendingInvite(programId, selectedInviteId)
      .then(() => {
        invalidateProgramPendingInvites(programId);
      })
      .catch((error) => {
        notifyTranslatedError(t, error.response.data?.errorCode);
      });
    setOpenCancelConfirmation(false);
  };

  if (!invites.length) return null;

  return (
    <div className='rounded-2xl bg-neutral-200 p-2 shadow shadow-neutral-300'>
      <Disclosure defaultOpen={true}>
        {({ open }) => (
          <>
            <Disclosure.Button className='flex w-full items-center '>
              <ChevronRightIcon className={`h-5 w-5  ${open ? 'rotate-90 transform' : ''} `} />
              <BodyText variant='base' className='font-semibold text-secondary-500'>
                {t('pending_invites')}
              </BodyText>
            </Disclosure.Button>
            <Disclosure.Panel>
              <div className='space-y-2 px-4 mt-2'>
                {invites.map(({ id, email, manager }) => (
                  <div key={id} className='flex items-center justify-between'>
                    <div>
                      <BodyText variant='sm' className='font-medium'>
                        {email}
                      </BodyText>
                      <BodyText variant='xs'>{manager ? 'Manager' : 'Member'}</BodyText>
                    </div>
                    <Button
                      size='small'
                      variant='secondary'
                      className='bg-functional-danger-400 text-white'
                      onClick={() => handleOpenConfirmation(id)}
                    >
                      {t('cancel_invite')}
                    </Button>
                  </div>
                ))}
              </div>
              {hasNextInvites && (
                <button className='relative' onClick={() => fetchNextInvites()}>
                  <Avatar size={40} src={userAvatar} alt='' />
                  <div className='absolute inset-0 rounded-full bg-neutral-600 bg-opacity-60' />
                  <BodyText
                    variant='base'
                    className='absolute inset-x-0 top-2/4 -translate-y-2/4 text-neutral-100'
                  >
                    2+
                  </BodyText>
                </button>
              )}
            </Disclosure.Panel>
          </>
        )}
      </Disclosure>

      {openCancelConfirmation && (
        <ConfirmationModal
          isOpen={openCancelConfirmation}
          label={t('cancel_invite')}
          title={t('cancel_invite_confirmation_title')}
          onClose={handleCloseConfirmation}
          onConfirm={handleCancelInvite}
        />
      )}
    </div>
  );
};

export default PendingInvites;
