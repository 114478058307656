import GuestLayOut from 'components/layout/GuestLayout';
import Heading from 'components/typography/Heading';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useGuestDiscover } from 'hooks/api/guest/guest';
import { useParams } from 'react-router';
import Card from 'components/card/Card';
import Loading from 'components/Loading';
import NavigateTo404 from 'components/NavigateTo404';
import { generateGuestOriginLink } from 'utils/url';

const GuestDiscoverPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { originType, originId } = useParams();
  if (!originType || !originId) return <NavigateTo404 />;

  const { data: discover, isLoading: isDiscoverLoading } = useGuestDiscover(originType, originId);

  if (isDiscoverLoading) return <Loading />;
  if (!discover) return <NavigateTo404 />;

  return (
    <>
      <GuestLayOut
        originName={discover.organization.name}
        originLink={`${originType}/${originId}`}
        orgName={discover.organization.organization.name}
        orgId={discover.organization.organization.id}
      >
        <div className='ml-3 h-full overflow-y-auto'>
          <Heading variant='h1' className='mb-5 mt-5 font-bold text-secondary-500'>
            {t('discover_info')}
          </Heading>
          <section>
            <div className='mb-6 grid grid-cols-1 gap-5 md:grid-cols-2 xl:grid-cols-3 xxl:grid-cols-4'>
              {discover.programs.map(({ id, name, visibility, cover, organization }) => (
                <Card
                  key={id}
                  title={name}
                  visibility={visibility}
                  coverImage={cover}
                  organizationLogo={organization.logo}
                  to={`${generateGuestOriginLink(id, 'program' || '', organization.id)}`}
                  organizationName={organization.name}
                  guestMode={true}
                />
              ))}
            </div>
            <div className='mb-6 grid grid-cols-1 gap-5 md:grid-cols-2 xl:grid-cols-3 xxl:grid-cols-4'>
              {discover.communities.map(({ id, name, visibility, cover, organization }) => (
                <Card
                  key={id}
                  title={name}
                  visibility={visibility}
                  coverImage={cover}
                  organizationLogo={organization.logo}
                  to={`${generateGuestOriginLink(id, 'community' || '', organization.id)}`}
                  organizationName={organization.name}
                  guestMode={true}
                />
              ))}
            </div>
          </section>
        </div>
      </GuestLayOut>
    </>
  );
};

export default GuestDiscoverPage;
