import { Tab } from '@headlessui/react';
import Layout from 'components/layout/Layout';
import { resourceTabItems } from 'config/tabs';
import { Outlet } from 'react-router';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useLocation } from 'react-router-dom';

const ResourceHomePage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();

  const currentTabIndexFromRouter = resourceTabItems.findIndex((item) => {
    return location.pathname.includes(item.path);
  });

  const tabName = location.pathname.split('/').pop();

  return (
    <Layout>
      <div>
        <Tab.Group
          onChange={(value) => {
            navigate(`${resourceTabItems[value].path}`);
          }}
          selectedIndex={tabName ? currentTabIndexFromRouter : 0}
        >
          <Tab.List
            className={`tab-list-parent sticky top-[0px] z-20 shrink-0 overflow-x-auto overflow-y-hidden bg-secondary-100`}
          >
            {resourceTabItems.map(({ label }) => (
              <Tab key={label} className='shrink-0 outline-0'>
                {({ selected }) => (
                  <div
                    className={`nonactive-tab  ${
                      selected ? 'active-tab text-secondary-500' : 'border-neutral-300'
                    }`}
                  >
                    {t(label)}
                  </div>
                )}
              </Tab>
            ))}
          </Tab.List>

          <Tab.Panels>
            {resourceTabItems.map(({ path }) => (
              <Tab.Panel
                key={path}
                className='outline-none py-1 px-1 md:py-2 md:px-3 lg:py-4 lg:px-5'
              >
                <Outlet />
              </Tab.Panel>
            ))}
          </Tab.Panels>
        </Tab.Group>
      </div>
    </Layout>
  );
};

export default ResourceHomePage;
