import BackIcon from 'assets/icons/back.svg';
import LoadingIcon from 'assets/icons/loading.svg';
import MessageIcon from 'assets/icons/message.svg';
import GlobeIcon from 'assets/icons/globe.svg';
import FileIcon from 'assets/icons/upload.svg';
import SearchIcon from 'assets/icons/search.svg';
import DeleteIcon from 'assets/icons/delete.svg';
import SendIcon from 'assets/icons/send.svg';
import Avatar from 'components/memberAndProfile/Avatar';
import BodyText from 'components/typography/BodyText';
import NavigateTo404 from 'components/NavigateTo404';
import Button from 'components/button/Button';
import Layout from 'components/layout/Layout';
import Heading from 'components/typography/Heading';
import Loading from 'components/Loading';
import TextInput from 'components/form/TextInput';
import { OutputData } from '@editorjs/editorjs';
import RichTextEditor from 'components/common/RichTextEditorWithUpload';
import { DropdownMenu } from 'components/common/DropdownMenu';
import React, { useState, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { useSingelPage, useAllPages } from 'hooks/api/pages/usePages';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { objToFormData } from 'utils/rest';
import { useForm, SubmitHandler } from 'react-hook-form';
import { updatePage, deletePage } from 'utils/api/pages';
import ConfirmationModal from 'components/modal/ConfirmationModal';
import useIsLoggedIn from 'hooks/api/auth/useIsLoggedIn';
import { generateUserProfileLink } from 'utils/url';
import { notifySuccess, notifyTranslatedError } from 'utils/notify';
import PageGuestAccessModal from 'components/modal/PageGuestAccessModal';
import ImageSearchModal from 'components/modal/ImageSearchModal';
import EmbedConversationSettingModal from 'components/modal/EmbedConversationSettingModal';

interface SinglePagePage {}

const SinglePagePage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { pageId } = useParams();
  if (!pageId) return <NavigateTo404 />;

  const { data: page, isLoading, refetch } = useSingelPage(pageId.trim());
  const { data: allPages, refetch: refetchPages } = useAllPages();
  const { data: user } = useIsLoggedIn();
  const bannerInputRef = useRef<HTMLInputElement>(null);
  const [banner, setBanner] = useState<FileList | null | string>(page?.banner || '');
  const [showTextEditor, setShowTextEditor] = useState(user?.id === page?.user.id ? true : false);
  const [title, setTitle] = useState('');
  const [deleteBannerValue, setDeleteBannerValue] = useState(false);
  const [showGuestAccessModal, setShowGuestAccessModal] = useState(false);
  const [showEmbedConversationSettingModal, setShowEmbedConversationSettingModal] = useState(false);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [content, setContent] = useState<OutputData | null>(null);
  const [isPageSaving, setIsPageSaving] = useState(false);
  const [showImageSearchModal, setShowImageSearchModal] = useState(false);
  const [onSelectImage, setOnSelectImage] = useState<string | null>(null);

  useEffect(() => {
    if (page) {
      setTitle(page.title || '');
      setValue('banner', page.banner || '');
    }
  }, [page]);

  const {
    watch,
    register,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm<{
    title: string;
    content: string;
    banner: FileList | null | string;
    coverImage: string | null;
    deleteBanner: string;
  }>();
  const onSubmit: SubmitHandler<{
    title: string;
    content: string;
    banner: FileList | null | string;
    coverImage: string | null;
    deleteBanner: string;
  }> = (data) => {
    const { banner } = data;
    const newData = {
      title: title ? title : page?.title ? page?.title : '',
      content: content ? JSON.stringify(content) : page?.content ? page?.content : '',
      deleteBanner: deleteBannerValue ? 'true' : 'false',
    };

    const formData = objToFormData(newData);

    if (banner && typeof banner !== 'string') {
      formData.append('banner', banner[0]);
    } else if (onSelectImage) {
      formData.append('coverImage', onSelectImage);
    }

    setIsPageSaving(true);
    updatePage(pageId, formData)
      .then(() => {
        notifySuccess(t('success.api.content_saved'));
        refetch();
        refetchPages();
        setShowTextEditor(false);
        setIsPageSaving(false);
      })
      .catch((error) => {
        notifyTranslatedError(t, error.response.data?.message);
        notifyTranslatedError(t, error.response.data?.errorCode);
        setIsPageSaving(false);
      });
  };

  const handleDeletePage = () => {
    deletePage(pageId)
      .then(() => {
        notifySuccess(t('page-deleted'));
        refetchPages();
        navigate('/pages');
      })
      .catch((error) => {
        notifyTranslatedError(t, error.response.data?.message);
        notifyTranslatedError(t, error.response.data?.errorCode);
      });
  };

  const handleShare = async () => {
    try {
      await navigator.clipboard.writeText(window.location.href);
      notifySuccess(t('invite-copied'));
    } catch (error) {
      console.error('Failed to copy URL:', error);
    }
  };

  const handleSelectCover = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files?.length) return;
    setValue('banner', e.target.files);
    setBanner(e.target.files);
    setDeleteBannerValue(false);
  };

  return (
    <Layout>
      <div className='z-20 flex w-full items-center justify-between py-1 lg:sticky lg:top-[48px] px-6 py-1 mb-3'>
        <div className='flex items-center gap-2'>
          {user?.id !== page?.user.id ? (
            <button
              className='flex items-center gap-4 text-left'
              onClick={() => navigate(generateUserProfileLink(page?.user.id || ''))}
            >
              {page?.user.profilePicture && <Avatar size={30} src={page?.user.profilePicture} />}
              <div>
                <BodyText variant='xs' className=' text-neutral-500'>
                  {t('created-by')}:
                </BodyText>
                <BodyText variant='sm' className='font-medium'>
                  {page?.user.firstName} {page?.user.lastName}
                </BodyText>
              </div>
            </button>
          ) : (
            <div className='flex items-center gap-2'>
              <DropdownMenu
                menuButton={
                  <Button size='default' variant='secondary-outlined' className='w-full md:w-auto'>
                    <span className=''>{t('settings')}</span>
                  </Button>
                }
                menuItems={[
                  {
                    key: 'share_to_member',
                    label: t('share_to_member'),
                    icon: SendIcon,
                    callback: () => handleShare(),
                  },
                  {
                    key: 'share_to_web',
                    label: t('share_to_web'),
                    icon: GlobeIcon,
                    callback: () => setShowGuestAccessModal(true),
                  },
                  {
                    key: 'comment_thread',
                    label: t('comment_thread_settings'),
                    icon: MessageIcon,
                    callback: () => setShowEmbedConversationSettingModal(true),
                  },
                ]}
              />
            </div>
          )}

          {showTextEditor && user?.id == page?.user.id && (
            <div className='flex items-center gap-2 '>
              <div className=''>
                <DropdownMenu
                  menuButton={
                    <Button
                      size='default'
                      variant='primary'
                      className='border border-functional-danger-dark bg-neutral-200 text-functional-danger-dark hover:bg-functional-danger-light active:bg-neutral-400'
                    >
                      <DeleteIcon className='h-4 w-4' />
                    </Button>
                  }
                  menuItems={[
                    {
                      key: 'delete',
                      label: t('delete-page'),
                      icon: DeleteIcon,
                      callback: () => setShowDeleteConfirmation(true),
                    },
                  ]}
                />
              </div>
              <div>
                <DropdownMenu
                  menuButton={
                    <Button size='default' variant='secondary'>
                      {t('change_cover')}
                    </Button>
                  }
                  menuItems={[
                    {
                      key: 'upload_cover',
                      label: t('upload-image'),
                      icon: FileIcon,
                      callback: () => {
                        setOnSelectImage(null);
                        bannerInputRef.current?.click();
                      },
                    },
                    {
                      key: 'share_to_web',
                      label: t('search-stock-image'),
                      icon: SearchIcon,
                      callback: () => setShowImageSearchModal(true),
                    },
                  ]}
                />
                <input
                  type='file'
                  className='hidden'
                  {...register('banner', {
                    onChange: (e) => handleSelectCover(e),
                  })}
                  ref={bannerInputRef}
                  accept='image/png, image/jpeg, , image/jpg, image/webp'
                />
              </div>
              <Button size='default' variant='secondary' type='submit' form='edit-page-form'>
                {isPageSaving ? <LoadingIcon className='h-4 w-5' /> : <span>{t('save')}</span>}
              </Button>
            </div>
          )}
          {!showTextEditor && user?.id == page?.user.id && (
            <Button size='default' variant='secondary' onClick={() => setShowTextEditor(true)}>
              {t('edit')}
            </Button>
          )}
        </div>
        <Button
          size='default'
          variant='outlined'
          onClick={() => {
            if (showTextEditor) {
              handleSubmit(onSubmit)();
            }
            navigate(-1);
          }}
          className='text-functional-danger-400 p-[8px]'
          tooltip={t('back')}
        >
          <BackIcon className='h-4 w-4' />
        </Button>
      </div>
      <div className={`flex w-full`}>
        <div
          className={`mx-auto w-full max-w-[1200px] flex-col rounded-2xl bg-neutral-200 shadow-md shadow-neutral-300`}
        >
          {isLoading ? (
            <div className='flex items-center justify-center'>
              <Loading />
            </div>
          ) : (
            <div className='flex flex-col justify-center'>
              <div className='relative w-full'>
                <div className='relative'>
                  {watch('banner') ? (
                    // already has a cover
                    typeof watch('banner') === 'string' ? (
                      <img
                        alt=''
                        src={watch('banner') as string}
                        className='h-[145px] w-full rounded-t-2xl bg-center object-cover object-cover md:h-[250px]'
                      />
                    ) : (
                      <img
                        alt=''
                        className='h-[145px] w-full rounded-t-2xl bg-center object-cover object-cover md:h-[250px]'
                        src={URL.createObjectURL((watch('banner') as FileList)[0])}
                      />
                    )
                  ) : (
                    <div></div>
                  )}
                  {!watch('banner') && onSelectImage && (
                    <img
                      alt=''
                      src={onSelectImage}
                      className='h-[145px] w-full rounded-t-2xl bg-center object-cover object-cover md:h-[250px]'
                    />
                  )}
                </div>
                {showTextEditor && (watch('banner') || onSelectImage) && (
                  <Button
                    size='small'
                    type='button'
                    variant='outlined'
                    className='absolute bottom-4 right-4 border border-functional-danger-dark bg-neutral-200 text-functional-danger-dark hover:bg-functional-danger-light active:bg-neutral-400'
                    onClick={() => {
                      setValue('banner', null);
                      setBanner(null);
                      setOnSelectImage(null);
                      setDeleteBannerValue(true);
                    }}
                  >
                    <DeleteIcon className='h-4 w-4' /> {t('delete')}
                  </Button>
                )}
              </div>
              <div className='mb-1'>
                {!showTextEditor && (
                  <div>
                    <div className='px-10'>
                      <Heading
                        variant='h3'
                        className='p-2 text-center font-semibold text-secondary-600'
                      >
                        {page?.title}
                      </Heading>
                    </div>
                    <div className='px-6 py-2'>
                      <RichTextEditor initialContent={page?.content} isReadOnly={true} />
                    </div>
                  </div>
                )}
                {showTextEditor && (
                  <form id='edit-page-form' onSubmit={handleSubmit(onSubmit)}>
                    <TextInput
                      type='text'
                      value={title}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        setTitle(e.target.value)
                      }
                      className='p-2 text-center text-xl font-semibold text-secondary-600'
                    />
                    <div className='ml-7 px-4'>
                      <RichTextEditor
                        initialContent={page?.content}
                        handleOnChange={(content) => setContent(content)}
                        isReadOnly={false}
                      />
                    </div>
                  </form>
                )}
                {page &&
                  (page.content === '' || page.content === null || page.content === undefined) &&
                  !showTextEditor && (
                    <div className='h-96 flex items-center justify-center'>
                      <button onClick={() => setShowTextEditor(true)}>
                        <h2 className='text-xl font-semibold text-neutral-500'>
                          + {t('start-editing')}
                        </h2>
                      </button>
                    </div>
                  )}
              </div>
            </div>
          )}
        </div>
      </div>
      {showDeleteConfirmation && (
        <ConfirmationModal
          isOpen={showDeleteConfirmation}
          title={t('delete-confirmation-modal-title')}
          label={t('delete-page')}
          description={t('')}
          onClose={() => setShowDeleteConfirmation(false)}
          onConfirm={handleDeletePage}
        />
      )}
      {showGuestAccessModal && (
        <PageGuestAccessModal
          isOpen={showGuestAccessModal}
          onClose={() => setShowGuestAccessModal(false)}
          pageId={pageId}
          guestViewCount={page?.guestViewCount}
          allowGuest={page?.allowGuest}
        />
      )}
      <ImageSearchModal
        isOpen={showImageSearchModal}
        onClose={() => {
          setShowImageSearchModal(false);
        }}
        onImageSelect={(url) => {
          setValue('banner', '');
          setDeleteBannerValue(false);
          setOnSelectImage(url);
        }}
      />
      <EmbedConversationSettingModal
        isOpen={showEmbedConversationSettingModal}
        onClose={() => {
          setShowEmbedConversationSettingModal(false);
        }}
        pageId={pageId}
      />
    </Layout>
  );
};

export default SinglePagePage;
