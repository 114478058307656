import React from 'react';

interface IHeading {
  className?: string;
  children: React.ReactNode;
  variant: 'h1' | 'h2' | 'h3';
}

function Heading({ className = '', children, variant }: IHeading) {
  switch (variant) {
    case 'h1':
      return <h1 className={`text-[28px] leading-9 ${className}`}>{children}</h1>;
    case 'h2':
      return <h2 className={`text-2xl leading-[30px] ${className}`}>{children}</h2>;
    case 'h3':
      return <h3 className={`text-xl leading-7 ${className}`}>{children}</h3>;
    default:
      return <h1 className={`text-[28px] leading-9 ${className}`}>{children}</h1>;
  }
}

export default Heading;
